import { error as notifyError } from '@lumapps/notifications';
import { batch } from '@lumapps/redux/react';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

import { like, unlike } from '../../api/contents';
import { contentSelector } from '../selectors';
import { actions } from '../slice';

/**
 * Like or unlike a content.
 */
export const onLike = () => async (dispatch: Dispatch, getState: GetBaseState) => {
    dispatch(actions.setLikeState('loading'));
    const { id, liked, likes } = contentSelector(getState());
    // We optimistically consider that the request will work so we compute the value as it should be after the request
    // However, we keep the button as deactivated as long as the request is not finished
    dispatch(
        actions.like({
            liked: !liked,
            likes: (likes || 0) + (liked ? -1 : 1),
        }),
    );
    try {
        const response = await (liked ? unlike : like)(id);
        batch(() => {
            // We update the store with the return of the request to be sure to have the correct data
            dispatch(
                actions.like({
                    liked: response.data.liked,
                    likes: response.data.likes,
                }),
            );
            dispatch(actions.setLikeState('loaded'));
        });
    } catch (e) {
        batch(() => {
            // If the request fails, we put back the data as it was before sending the request
            dispatch(
                actions.like({
                    liked,
                    likes,
                }),
            );
            dispatch(actions.setLikeState('error'));
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        });
    }
};
