import React from 'react';

import { Text, Alignment, ColorPalette, FlexBox, Size, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_SHARE } from '../../keys';
import { ClientComputedShareProps, ShareTypes } from '../../types';
import { ShareLink } from '../ShareBlock';

export const CLASSNAME = 'share-link';

export const ClientComputedShare: React.FC<ClientComputedShareProps> = ({ properties }) => {
    const { style, shareOn } = properties || {};
    const theme = style?.content?.theme || Theme.light;
    const { translateKey } = useTranslate();
    const fields = shareOn || [];

    return (
        <FlexBox orientation="horizontal" hAlign={Alignment.center} gap={Size.medium}>
            <Text
                as="span"
                className="lumx-spacing-margin-right-huge"
                typography="body1"
                color={theme === Theme.dark ? ColorPalette.light : undefined}
            >
                {translateKey(WIDGET_SHARE.SHARE_ON)}
            </Text>

            {fields
                .filter((field) => field.enable && ShareTypes.includes(field.id))
                .map((field) => (
                    <ShareLink key={field.id} theme={theme} id={field.id} />
                ))}
        </FlexBox>
    );
};
