import React, { useState } from 'react';

import { classnames } from '@lumapps/classnames';
import { Checkbox, List, ListItem, Select, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_PLAYLIST } from '../../../keys';
import { WidgetPlaylistProperties } from '../../../types';

export type PlaylistOptionsProperties = Pick<WidgetPlaylistProperties, 'itemsPerLine' | 'slideshowEnabled'>;

export interface PlaylistOptionsProps {
    className?: string;
    properties: PlaylistOptionsProperties;
    onChange: (properties: PlaylistOptionsProperties) => void;
}

const CLASSNAME = 'playlist-options';

/**
 *
 * @param PlaylistOptionsProps
 * @returns PlaylistOptions
 */
export const PlaylistOptions = ({ className, properties, onChange }: PlaylistOptionsProps) => {
    const { translateKey } = useTranslate();
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const { slideshowEnabled, itemsPerLine } = properties;

    return (
        <div className={classnames(className, CLASSNAME)}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <h4 className="lumx-typography-subtitle1 lumx-spacing-margin-bottom-huge">
                {translateKey(GLOBAL.OPTIONS)}
            </h4>
            <Checkbox
                className="lumx-spacing-margin-bottom-regular"
                role="checkbox"
                isChecked={slideshowEnabled}
                label={translateKey(WIDGET_PLAYLIST.SLIDESHOW_ENABLED)}
                onChange={(value) => onChange({ ...properties, slideshowEnabled: value })}
            />
            <Select
                isOpen={isSelectOpen}
                value={itemsPerLine.toString() || '3'}
                label={translateKey(WIDGET_PLAYLIST.SETTINGS_MAX_VIDEOS_PER_LINE)}
                onInputClick={() => setIsSelectOpen(true)}
                onDropdownClose={() => setIsSelectOpen(false)}
                helper={translateKey(WIDGET_PLAYLIST.SETTINGS_MAX_VIDEOS_PER_LINE_HELPER)}
            >
                <List>
                    {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
                        <ListItem
                            isSelected={itemsPerLine === value}
                            key={value}
                            onItemSelected={() => onChange({ ...properties, itemsPerLine: value })}
                            size={Size.tiny}
                        >
                            {value}
                        </ListItem>
                    ))}
                </List>
            </Select>
        </div>
    );
};
