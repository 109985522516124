export enum MOBILE_GENERAL_SETTINGS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21476110
     */
    MOBILE_COMMUNITY_INSTANCES_SELECT = 'ADMIN.MOBILE_COMMUNITY_INSTANCES_SELECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21476110
     */
    MOBILE_COMMUNITY_INSTANCES_SELECT_HELPER = 'ADMIN.MOBILE_COMMUNITY_INSTANCES_SELECT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21476199
     */
    MOBILE_SEARCH_INSTANCES_SELECT = 'ADMIN.MOBILE_SEARCH_INSTANCES_SELECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21476199
     */
    MOBILE_SEARCH_INSTANCES_SELECT_HELPER = 'ADMIN.MOBILE_SEARCH_INSTANCES_SELECT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21476010
     */
    TITLE = 'ADMIN.NAV.MOBILE_GENERAL_SETTINGS',
}
