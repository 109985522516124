import React from 'react';

import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';

import { SelectFieldMultiple } from '@lumapps/combobox/components/SelectFieldMultiple';
import { renderGroupedChoice } from '@lumapps/combobox/components/SelectFieldMultiple/renderChoicesFactories';
import { getCustomContentTypeTagsByID } from '@lumapps/content-types/ducks/selectors';
import { useCustomContentTypes } from '@lumapps/content-types/hooks/useCustomContentTypes';
import { Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { usePaginatedFrontendSearch } from '@lumapps/utils/hooks/usePaginatedFrontendSearch';

import { useUpdateContentTypeTags } from '../../hooks/useUpdateContentTypeTags';
import { WIDGET_CONTENT_FILTER } from '../../keys';
import { FormattedSiteFilter, FormattedTagFilter } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';
import { SkeletonFilter } from '../SkeletonFilter';

export interface CustomContentTypeTagsFilterProps {
    theme: Theme;
    scope: string;
    /** Whether the filter should be hidden if there is no available tags. */
    shouldBeHiddenIfEmpty?: boolean;
    /**
     * Whether the cct should be fetch on mount.
     *
     * NB: We recommend to not fetch on mount if you want to fetch ALL CCTs (no id provided).
     * */
    shouldFetchOnMount?: boolean;
    /** Content type ids the tags will be taken from. */
    contentTypeIds?: string[];
    /** The list of selected tags */
    selectedTags?: FormattedTagFilter[];
    /** The list of sites related to the content types. */
    sites?: FormattedSiteFilter[];
    /** Number of items per page */
    itemsPerPage?: number;
    /** The callback that will be trigger on each value change of the selecct */
    onChange: (tags: FormattedTagFilter[]) => void;
    hideSubheader: boolean;
}

const getTagName = (t: FormattedTagFilter) => t.name;

/**
 * A select field to select multiple content type tags,
 * filtered by content type ids.
 * The list of tags will be grouped by ccts.
 *
 * @param CustomContentTypeTagsFilterProps
 * @returns CustomContentTypeTagsFilter
 */
export const CustomContentTypeTagsFilter: React.FC<CustomContentTypeTagsFilterProps> = ({
    scope,
    contentTypeIds,
    sites,
    selectedTags = [],
    shouldBeHiddenIfEmpty,
    shouldFetchOnMount,
    onChange,
    hideSubheader,
    theme = Theme.light,
    itemsPerPage = 20,
}) => {
    const { translateObject, translateKey } = useTranslate();

    const sitesDictionary = React.useMemo(() => keyBy(sites, 'id'), [sites]);

    const {
        fetch,
        customContentTypes = {},
        isLoading,
        isIdle,
    } = useCustomContentTypes({
        shouldFetch: shouldFetchOnMount,
        ids: contentTypeIds,
    });

    const tagDictionnary = pickBy(
        useSelector(getCustomContentTypeTagsByID),
        (tag) => contentTypeIds?.includes(tag.contentTypeId),
    );

    const formattedTags: FormattedTagFilter[] = React.useMemo(
        () =>
            Object.values(tagDictionnary)
                .filter((t) => Object.keys(sitesDictionary).includes(t.instanceId))
                .map((t) => ({
                    name: translateObject(t.name) || '',
                    id: t.id,
                    cct: {
                        name: translateObject(customContentTypes[t.contentTypeId]?.name) || '',
                        id: t.contentTypeId,
                    },
                    site: { name: sitesDictionary[t.instanceId]?.name, id: t.instanceId },
                })),
        [customContentTypes, sitesDictionary, tagDictionnary, translateObject],
    );

    const { getMoreItems, items, searchQuery, onSearch } = usePaginatedFrontendSearch({
        itemList: formattedTags,
        perPage: itemsPerPage,
        getItemName: getTagName,
    });

    useUpdateContentTypeTags({
        formattedTags,
        selectedTags,
        handleTagFilter: onChange,
        ready: isIdle,
    });

    /**
     * Group tags by their custom content types.
     * Tags are always linked to a CCT so tag options should be contextualised
     * because some tags can share the same names, and the only way to differentiate
     * them is to know their parents.
     *
     * We are formatting the data to use the renderGroupChoice function that can
     * create select options grouped by sections.
     * */
    const groups = React.useMemo(() => {
        const groupedItems = groupBy(items, (c) => `${c.site?.id}_${c.cct?.id}`);

        return Object.values(groupedItems).map((tags) => {
            const [firstItem] = tags;

            const siteName = firstItem.site?.name;
            const cctName = firstItem.cct?.name;

            return { group: { siteName, cctName }, children: tags };
        });
    }, [items]);

    const [isOpen, setOpen] = React.useState(false);

    const toggleOpen = React.useCallback(
        (status) => {
            if (contentTypeIds?.length) {
                fetch();
            }
            setOpen(status);
        },
        [contentTypeIds?.length, fetch],
    );

    // Get up to date tag name from redux store.
    const getValueName = React.useCallback(
        (tag) => {
            return tag.name || translateObject(tagDictionnary[tag.id]?.name);
        },
        [tagDictionnary, translateObject],
    );

    if (isLoading) {
        return <SkeletonFilter />;
    }

    if (
        shouldBeHiddenIfEmpty &&
        (!contentTypeIds || contentTypeIds.length === 0 || (!isLoading && isEmpty(tagDictionnary)))
    ) {
        return null;
    }

    return (
        <>
            {!hideSubheader && (
                <FilterSubheader label={translateKey(WIDGET_CONTENT_FILTER.CONTENT_TYPE_SUBHEADER)} theme={theme} />
            )}
            <SelectFieldMultiple<FormattedTagFilter>
                theme={theme}
                scope={scope}
                isLoading={isLoading}
                onInfiniteScroll={getMoreItems}
                onSearch={onSearch}
                getValueId={(tag) => tag.id}
                getValueName={getValueName}
                searchFieldProps={{
                    isCompact: true,
                }}
                searchText={searchQuery}
                onChange={onChange}
                label={translateKey(GLOBAL.TAGS)}
                value={selectedTags}
                isOpen={isOpen}
                setOpen={toggleOpen}
                choices={renderGroupedChoice<
                    { group: { cctName?: string; siteName?: string }; children: FormattedTagFilter[] },
                    FormattedTagFilter,
                    { cctName?: string; siteName?: string }
                >({
                    groups,
                    getGroupName: (g) => {
                        return `${g.cctName} - ${g.siteName}`;
                    },
                    getValueName: (tag) => tag.name,
                    getValueId: (tag) => tag.id,
                })}
            />
        </>
    );
};
