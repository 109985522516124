import React from 'react';

import { cancelGetMainNavigationBreadcrumb, getMainNavigationBreadCrumb } from '@lumapps/navigation/api';
import { Navigation } from '@lumapps/navigation/types';
import { getNavigationItemBySlug } from '@lumapps/navigation/utils/navigationMemStore';
import { FetchCallback, useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { BreadcrumbProps } from '../components/ClientComputedBreadcrumb/Breadcrumb';

interface UseBreadcrumbProps {
    uuid: string;
    url?: string;
    lang: string;
    alternativeLang: string;
    customer: string;
    instance: string;
    pageId?: string;
    fetchCallback: FetchCallback<Navigation>;
}

interface UseBreadcrumbApi {
    breadcrumb: BreadcrumbProps['breadcrumb'];
    status: BaseLoadingStatus;
}

const USE_BREADCRUMB_FETCH_KEY = 'USE_BREADCRUMB_FETCH_KEY';

/**
 * Breadcrumb widget hook to manage the fetch to backend.
 * Depending on whether the url path is available, it will either fetch the breadcrumb of the corresponding node,
 * Or if no enough information is given, it will fetch the first node of the pageId found by the backend.
 */
export const useBreadcrumb = ({
    uuid,
    url,
    lang,
    alternativeLang,
    customer,
    instance,
    pageId,
    fetchCallback,
}: UseBreadcrumbProps): UseBreadcrumbApi => {
    const {
        response: fetchResponse,
        status: fetchStatus,
        fetch: fetchBreadCrumb,
        cancelFetch: cancelFetchBreadCrumb,
    } = useFetchWithStatus<Navigation>({
        onFetch: getMainNavigationBreadCrumb,
        onFetchCancel: cancelGetMainNavigationBreadcrumb,
    });

    React.useEffect(() => {
        // have a separate fetch key for each instance of widget so cancels won't interfere
        const breadcrumbFetchKeyById = `${USE_BREADCRUMB_FETCH_KEY}_${uuid}`;

        if (pageId) {
            // if url is given, check whether we can find the specific node id in the visible navigation
            const navigationItemBySlug = getNavigationItemBySlug(pageId, url);

            const params = {
                // If a navigation node is found, fetch by node id
                nodeId: navigationItemBySlug?.id,
                // If there is no url, no navigation item is found by url, fetch by page id
                pageId: !url || !navigationItemBySlug?.id ? pageId : undefined,
                lang,
                alternativeLang,
                customer,
                instance,
            };

            fetchBreadCrumb({
                params: [params, breadcrumbFetchKeyById],
                callback: fetchCallback,
            });
        }

        // If page is unloaded, cancel any ongoing call
        return () => {
            cancelFetchBreadCrumb({ params: [{ instance }, breadcrumbFetchKeyById] });
        };
    }, [
        fetchBreadCrumb,
        fetchCallback,
        cancelFetchBreadCrumb,
        pageId,
        url,
        lang,
        alternativeLang,
        customer,
        instance,
        uuid,
    ]);

    return { breadcrumb: fetchResponse, status: fetchStatus };
};
