import React from 'react';

import type { DistributeResourceDialogProps } from '@lumapps/broadcasts/components/DistributeResourceDialog';
import { canAccessBroadcast, isDistributeContentEnabled } from '@lumapps/broadcasts/ducks/selectors';
import { ExternalLinkResource, Resource } from '@lumapps/journeys/types';
import { useSelector } from '@lumapps/redux/react';
import { removeParamFromUrl } from '@lumapps/router/utils';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

export interface UseDistributeDialog {
    /** The distribute dialog props */
    formDialogProps: DistributeResourceDialogProps;
    /** Open the distribute dialog */
    openFormDialog: () => void;
    /** Whether the contextual button should be displayed */
    isDistributeResourceEnabled: boolean;
    /** Whether the component is mounted */
    isMounted: boolean;
}

export interface UseDistributeDialogOptions {
    /** The resource to distribute */
    resource: Exclude<Resource, ExternalLinkResource>;
}

export const useDistributeDialog = ({ resource }: UseDistributeDialogOptions): UseDistributeDialog => {
    const isDistributeActionEnabled = useSelector(isDistributeContentEnabled);
    const canUserAccessBroadcast = useSelector(canAccessBroadcast);

    const isDistributeResourceEnabled = isDistributeActionEnabled && Boolean(canUserAccessBroadcast);
    const [isMounted, setIsMounted] = React.useState(false);
    const [isFormDialogOpen, , closeFormDialog, openFormDialog] = useBooleanState(false);

    const distributeResourceDialogCreateProps: DistributeResourceDialogProps = {
        isOpen: isFormDialogOpen,
        onClose: () => {
            closeFormDialog();
            window.history.replaceState(null, '', removeParamFromUrl('distribute', window.location.href));
        },
        resource,
        onVisibilityChange: setIsMounted,
    };

    React.useEffect(() => {
        if (isFormDialogOpen) {
            setIsMounted(true);
        }
    }, [isFormDialogOpen]);

    return {
        formDialogProps: distributeResourceDialogCreateProps,
        openFormDialog,
        isDistributeResourceEnabled,
        isMounted,
    };
};
