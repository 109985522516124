import React, { useCallback, useEffect, useState } from 'react';

import { AuthenticatedIframe } from '@lumapps/lumx-layouts/components/AuthenticatedIframe';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Alignment, FlexBox, ProgressCircular } from '@lumapps/lumx/react';
import { useParams } from '@lumapps/router';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import './index.scss';

import { getTeamKpiUrl } from '../../api';

export interface TeamKpiProps {
    onLoad?: (data: string | undefined) => void;
    sameCluster: boolean;
}

const CLASSNAME = 'learning-team-kpi-iframe';

/**
 *  widget that contains an iframe to teach on mars for the Manager TeamKpi
 * @returns Manager TeamKpi
 */
export const TeamKpi: React.FC<TeamKpiProps> = ({ onLoad, sameCluster }) => {
    const [url, setUrl] = useState('');
    const { identifier } = useParams<{ identifier: string }>();
    const [status, setStatus] = useState(BaseLoadingStatus.initial);

    const fetchTeamKpiUrl = useCallback(async () => {
        try {
            setStatus(BaseLoadingStatus.loading);
            const response = await getTeamKpiUrl(sameCluster);

            // Avoids displaying the same page in the iframe if a misconfigured
            // back returns only the URL params
            if (response.data.launchUrl.startsWith('http')) {
                setUrl(response.data.launchUrl);
                if (onLoad) {
                    onLoad(response?.data?.launchUrl);
                }
                setStatus(BaseLoadingStatus.idle);
            } else {
                setStatus(BaseLoadingStatus.error);
            }
        } catch (e) {
            setStatus(BaseLoadingStatus.error);
        }
    }, [sameCluster, onLoad]);
    useEffect(() => {
        if (status === BaseLoadingStatus.initial) {
            fetchTeamKpiUrl();
        }
    }, [identifier, status, fetchTeamKpiUrl]);

    return (
        <FlexBox className={CLASSNAME} orientation="vertical">
            {status === BaseLoadingStatus.loading && (
                <FlexBox hAlign={Alignment.center} vAlign={Alignment.center} fillSpace>
                    <ProgressCircular />
                </FlexBox>
            )}
            {status === BaseLoadingStatus.error && <ServiceNotAvailableState onRetry={fetchTeamKpiUrl} />}
            {status === BaseLoadingStatus.idle && (
                <AuthenticatedIframe
                    iframeProps={{ id: 'learningDashboardManager' }}
                    loadingType="src"
                    src={url}
                    title={identifier}
                />
            )}
        </FlexBox>
    );
};
