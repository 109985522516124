(function IIFE() {
    'use strict';

    /////////////////////////////

    function TimePickerController(Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * A list of the available values for the time selectors (i.e. on event posts).
         *
         * @type {Array}
         */
        vm.times = [];

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            // Init the time choices.
            for (var i = 0, nbHours = 24; i < nbHours; i++) {
                var hour = moment().startOf('hour').hours(i);

                vm.times.push(hour.format('LT'));
                vm.times.push(hour.minutes(30).format('LT'));
            }

            vm.allowClear = vm.allowClear || false;
            vm.label = (angular.isDefinedAndFilled(vm.label)) ? vm.label : Translation.translate('TIME');
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive to select a time value.
     *
     * @param {boolean} [allowClear=false] Indicates if we want to allow the user to clear the select value.
     * @param {string}  [label]            The text for the select label.
     *                                     Defaults to the translation of 'TIME'.
     * @param {string}  ngModel            The selected time value.
     */

    function TimePickerDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: TimePickerController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                allowClear: '<?lsAllowClear',
                label: '@?lsLabel',
                ngModel: '=',
                ngDisabled: '=',
            },
            templateUrl: '/client/common/modules/time-picker/views/time-picker.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsTimePicker', TimePickerDirective);
})();
