/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { GetBaseUrl } from '@lumapps/learning-utils/getBaseUrl';

import { CatalogData, CategoryData } from '../types';

const createCatalogApi = (baseURL: string) =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL,
    });
const paths = {
    catalog: 'catalog/v2/categories/all',
    categories: 'catalog/v2/categories/titles',
};

export async function getCatalog(language: string, learningAlphaEnabled: boolean): Promise<CatalogData> {
    const baseURL = GetBaseUrl(learningAlphaEnabled);
    const catalogApi = createCatalogApi(baseURL);
    const { data } = await catalogApi.get<CatalogData>(paths.catalog, { params: { language } });

    return data;
}

export async function getCategories(language: string, learningAlphaEnabled: boolean): Promise<CategoryData[]> {
    const baseURL = GetBaseUrl(learningAlphaEnabled);
    const catalogApi = createCatalogApi(baseURL);
    const { data } = await catalogApi.get<CategoryData[]>(paths.categories, { params: { language } });

    return data;
}

export const learningCatalogQueryKeys = (locale: string, LearningAlphaEnabled: boolean) => [
    'learning',
    'catalog',
    locale,
    LearningAlphaEnabled,
];
