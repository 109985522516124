function WidgetSummaryController($element, $injector, $scope, $timeout, Content, Features, Translation, Utils, Widget) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay before showing the app filter.
     *
     * @type {number}
     * @constant
     * @readonly
     */

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if we are currently editing the content of the Summary widget.
     *
     * @type {boolean}
     */
    vm.isEditingContent = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Translation = Translation;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Toggle drag and drop allowed for the Summary widget.
     * When it is in edit mode, disable drag'n'drop.
     */
    function _toggleDragAndDrop() {
        if (Widget.isDragDisabled()) {
            Widget.isDragDisabled(false);

            $element.parents('.component-cell').removeClass('component-cell--hover-disabled');
        } else {
            Widget.isDragDisabled(true);

            $element.parents('.component-cell').addClass('component-cell--hover-disabled');

            // [Fix IE/Edge]: prevent cell drag & drop to take over from the text selection.
            const stopPropagation = (evt) => evt.stopPropagation();
            Utils.waitForAndExecute('.widget-summary .fr-element', (el) => {
                el.on('dragstart', stopPropagation).on('selectstart', stopPropagation);
            });
        }
    }

    /**
     * Read the content.
     */
    function _readContent() {
        vm.isEditingContent = false;
        _toggleDragAndDrop();
    }

    /**
     * Edit the content.
     */
    function _editContent() {
        vm.isEditingContent = true;
        _toggleDragAndDrop();
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the widget classes.
     *
     * @return {Array} The widget classes.
     */
    function getWidgetClass() {
        const widgetClass = ['widget-summary'];

        vm.parentCtrl.getWidgetClass(widgetClass);

        widgetClass.push('widget-editable');

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        if (vm.isEditingContent) {
            widgetClass.push('widget-summary--is-editing');
            widgetClass.push('widget-editable--is-editing');
        }

        return widgetClass;
    }

    /**
     * Check if the widget is empty.
     * This is used in the designer.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isEmpty() {
        const inputLanguage = Translation.inputLanguage;
        const isTextDefined = Boolean(vm.widget.properties.text);

        if(!isTextDefined){
            return true;
        }

        const emptyContent =
            vm.widget.properties.text.translations[inputLanguage] === ''
            || vm.widget.properties.text.translations[inputLanguage] === undefined;

        return emptyContent;
    }

    /**
     * In designer mode, when the widget is empty, it is visible and displays
     * the widget type.
     * Used by the designer mode.
     */
    function isWidgetEmpty() {
        return !vm.isEditingContent && isEmpty();
    }

    /**
     * In reader mode, when the widget is empty, it is not displayed at all
     */
    function isWidgetHidden() {
        return !vm.parentCtrl.designerMode() && isEmpty();
    }

    let _viewMode;

    /**
     * Switch to edition mode to allow user to the content of the summary widget.
     */
    function switchToEditMode() {
        if (!vm.parentCtrl.designerMode() || vm.isEditingContent || Content.getViewMode() === 'basic') {
            return;
        }

        _editContent();

        _viewMode = Content.getViewMode();
        Content.setViewMode('locked');

    }

    /**
     * Switch to edition mode to allow user to the content of the summary widget when in basic mode.
     */
    function switchToBasicEditMode() {
        if ((Features.hasFeature('new-simple-template-experience') && Content.getViewMode() === 'basic') || !vm.parentCtrl.designerMode() || vm.isEditingContent || Content.getViewMode() !== 'basic') {
            return;
        }

        _editContent();
    }

    /**
     * Switch the content of the summary widget to reading mode.
     */
    function switchToReadMode() {
        if (!vm.isEditingContent || (Features.hasFeature('new-simple-template-experience') && Content.getViewMode() === 'basic')) return;

        _readContent();

        if (_viewMode) {
            Content.setViewMode(_viewMode);
        }
    }

    function saveTextInWidgetProperties(newText) {
        const properties = vm.widget.properties;
        properties.text = newText;
    }

    /////////////////////////////
    vm.switchToEditMode = switchToEditMode;
    vm.switchToReadMode = switchToReadMode;
    vm.switchToBasicEditMode = switchToBasicEditMode;
    vm.saveTextInWidgetProperties = saveTextInWidgetProperties;
    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.contentTitle = Content.getCurrent()?.title;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $scope.$on('contribution-mode', (evt, contributionMode) => {
        if (contributionMode === 'writer') {
            _editContent();
        } else {
            _readContent();
        }
    });

    /**
     * Initialize the widget properties and the controller parameters.
     */
    function _initProperties() {
        const properties = vm.widget.properties;
        properties.text = properties.text || { translations: {} };
    }

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _initProperties();

        if (Features.hasFeature('new-simple-template-experience') && Content.getViewMode() === 'basic') {
            _editContent();
        }
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        init();
    };
}

/////////////////////////////

function WidgetSummaryDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetSummaryController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetSummary', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-summary/views/widget-summary.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetSummary', WidgetSummaryDirective);

/////////////////////////////

export { WidgetSummaryController, WidgetSummaryDirective };
