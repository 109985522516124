import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import shuffle from 'lodash/shuffle';

import { isAutoTestModeEnabled } from 'components/utils';
import { USER_SPACE_FEATURE_TOKEN } from '@lumapps/user-profile/constants';

const MAIN_NAV_TYPES = {
    CHILD: 'child',
    CURRENT: 'current',
    PARENT: 'parent',
};

function HeaderController(
    $element,
    $interval,
    $location,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $window,
    Cell,
    Community,
    Config,
    ConfigTheme,
    Content,
    Customer,
    Features,
    Header,
    InitialSettings,
    Instance,
    Layout,
    Media,
    Notification,
    Row,
    SocialAdvocacy,
    Style,
    Translation,
    User,
    UserAccess,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /* eslint-disable one-var */

    /**
     * A list of state names that should always use the default header.
     *
     * @type {Array}
     */
    const _DEFAULT_HEADER_STATES = ['app.front.instance-style', 'app.front.profile', 'app.front.search'];

    /**
     * Define whether we want to bypass the `$stateChangeSuccess` event callback.
     *
     * @type {boolean}
     */
    let _bypassStateChangeEvent = false;

    /**
     * The id returned by the interval set on the slideshow.
     *
     * @type {number}
     */
    let _slideshowIntervalId;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The type of navigation.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.MAIN_NAV_TYPES = MAIN_NAV_TYPES;

    /**
     * The index of the active slide of the slideshow.
     *
     * @type {number}
     */
    vm.activeIndex = 0;

    /**
     * The style object used by the header.
     *
     * @type {Object}
     */
    vm.headerStyle = undefined;

    /**
     * Whether or not the user can contribute in at least one community.
     *
     * @type {boolean}
     */
    vm.userCanContributeInACommunity = undefined;

    /**
     * The id of the create new post dialog.
     *
     * @type {string}
     */
    vm.newPostDialogId = 'community-new-post-dialog';

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.Features = Features;
    vm.Header = Header;
    vm.Instance = Instance;
    vm.Layout = Layout;
    vm.Notification = Notification;
    vm.SocialAdvocacy = SocialAdvocacy;
    vm.Style = Style;
    vm.Translation = Translation;
    vm.User = User;
    vm.UserAccess = UserAccess;

    vm.currentView = $state.current.name;
    vm.currentParams = $state.params;

    $scope.$on('$stateChangeSuccess', function onStateChangeStart(evt, toState) {
        vm.currentView = $state.current.name;
        vm.currentParams = $state.params;
        vm.isSocialProfile =
            Features.hasFeature(USER_SPACE_FEATURE_TOKEN) && $state.current.name === 'app.front.profile';
    });

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Animate slide wrapper.
     */
    function _animateSlide() {
        const value = `translateX(${100 * vm.activeIndex * -1}%)`;

        angular.element('.header-slideshow__images').css({
            'moz-transform': value,
            'ms-transform': value,
            'o-transform': value,
            transform: value,
            'webkit-transform': value,
        });
    }

    /**
     * Cancel autoplay on the slideshow.
     */
    function _cancelAutoplay() {
        if (angular.isDefinedAndFilled(_slideshowIntervalId)) {
            $interval.cancel(_slideshowIntervalId);
        }
        _slideshowIntervalId = undefined;
    }

    /**
     * Get the media content from a given media.
     * Todo [Arnaud]: use method from media service instead?
     *
     * @param  {Object} media A media object to get the content of.
     * @return {Object} The translated media content.
     */
    function _getMediaContent(media) {
        return vm.Translation.translate(Media.getMediaContent(media));
    }

    /**
     * Sets the initial condition for displaying the create post button.
     * It depends on whether or not the user has the right to create content in at least one community.
     */
    function _setInitialCreatePostButtonVisibility() {
        // Only do it once (first page load).
        if (angular.isUndefined(vm.userCanContributeInACommunity) && Features.hasFeature('community') && User.isConnected()) {
            const projection = 'items(id)';

            // Do a call to see what communities the current user can create pages in.
            Community.filterize(
                {
                    action: 'COMMUNITY_PUBLISH',
                    instanceId: [Instance.getCurrentInstanceId()],
                    maxResults: 1,
                    status: [Config.CONTENT_STATUS.LIVE.value],
                },
                function onContentFilterizeSuccess(response) {
                    vm.userCanContributeInACommunity = angular.isDefinedAndFilled(response);
                },
                function onContentFilterizeError() {
                    vm.userCanContributeInACommunity = false;
                },
                'header-create-post-visibility',
                projection,
            );
        }
    }

    /**
     * Set default values of the current header.
     *
     * @param {Object} currentHeader The current header object.
     * @param {Object} defaultHeader The default header object to fallback to if some props are not set on current.
     */
    function _setDefaultValues(currentHeader, defaultHeader) {
        if (angular.isUndefined(currentHeader.height)) {
            currentHeader.height = defaultHeader && has(defaultHeader, 'height') ? defaultHeader.height : 270;
        }

        if (angular.isUndefined(currentHeader.properties)) {
            currentHeader.properties = {};
        }

        if (angular.isUndefined(get(currentHeader, 'properties.wrapperHeight'))) {
            currentHeader.properties.wrapperHeight = get(defaultHeader, 'properties.wrapperHeight', 170);
        }

        if (angular.isUndefined(get(currentHeader, 'properties.layoutPosition'))) {
            const defaultLayoutPosition = Features.hasMainNavInheritance() ? 24 : -50;
            currentHeader.properties.layoutPosition = get(
                defaultHeader,
                'properties.layoutPosition',
                defaultLayoutPosition,
            );
        }

        if (angular.isUndefined(get(currentHeader, 'properties.media'))) {
            currentHeader.properties.media = [];
        }

        if (angular.isUndefined(get(currentHeader, 'properties.mediaClass'))) {
            currentHeader.properties.mediaClass = {};
        }

        currentHeader.properties.interval = Math.floor(currentHeader.properties.interval) || 5;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Opens the create new post dialog.
     */
    function addNewPost() {
        const community = Community.getCurrent();
        $rootScope.$broadcast('create-new-post__open', undefined, community);
    }

    /**
     * Get media class names.
     *
     * @param  {Object} mediaId The id of the media to get the class names of.
     * @return {Array}  A list of class names to apply to the media in the slideshow.
     */
    function getMediaClass(mediaId) {
        let mediaClassSplit;
        const mediaClassToReturn = [];
        const headerProperties = vm.Header.getCurrent().properties;

        if (
            angular.isDefinedAndFilled(get(headerProperties, 'mediaClass')) &&
            angular.isDefinedAndFilled(headerProperties.mediaClass[mediaId])
        ) {
            mediaClassSplit = headerProperties.mediaClass[mediaId].split(',');
        }

        if (angular.isDefinedAndFilled(mediaClassSplit)) {
            angular.forEach(mediaClassSplit, (mediaClass) => {
                mediaClassToReturn.push(`slide--${mediaClass.trim()}`);
            });
        }

        return mediaClassToReturn;
    }

    /**
     * Check if the current user can customize the current layout.
     *
     * @return {boolean} If the user can customize the layout.
     */
    function canCustomizeLayout() {
        return (
            get(Content.getCurrent(), 'isCustomizableLayout', false) &&
            Content.getAction() === 'get' &&
            User.isConnected()
        );
    }

    /**
     * Get header top classes.
     *
     * @return {string} The header top classes.
     */
    function getHeaderTopClasses() {
        if (vm.Features.hasMainNavInheritance()) {
            return get(vm.headerStyle, 'properties.top.theme') === 'dark'
                ? 'header-top--theme-dark'
                : 'header-top--theme-light';
        }

        return get(vm.headerStyle, 'properties.top.theme') === 'dark'
            ? 'header-top--theme-dark'
            : 'header-top--theme-light';
    }

    /**
     * Get the media description from a given media.
     * Todo [Arnaud]: use method from media service instead?
     *
     * @param  {Object} media A media object to get the description of.
     * @return {string} The translated description of the media.
     */
    function getMediaDescription(media) {
        if (
            angular.isDefined(get(media, 'override.description')) &&
            angular.isDefinedAndFilled(vm.Translation.translate(media.override.description))
        ) {
            return vm.Translation.translate(media.override.description);
        }

        return '';
    }

    /**
     * Get the media image from a given media.
     * Todo [Arnaud]: use method from media service instead?
     *
     * @param  {Object} media A media object to get the image of.
     * @return {Object} An object made of css properties.
     */
    function getMediaImage(media) {
        const mediaContent = _getMediaContent(media);

        if (angular.isDefinedAndFilled(get(mediaContent, 'servingUrl'))) {
            return {
                'background-image': `url(${Utils.resizeImage(
                    mediaContent.servingUrl,
                    InitialSettings.MAX_IMAGE_SIZE,
                )})`,
                height: Header.getCurrent().height,
            };
        }

        return {};
    }

    /**
     * Get the media name from a given media.
     * Todo [Arnaud]: use method from media service instead?
     *
     * @param  {Object} media A media object to get the name of.
     * @return {string} The translated media name.
     */
    function getMediaName(media) {
        if (
            angular.isDefinedAndFilled(get(media, 'override.name')) &&
            angular.isDefinedAndFilled(vm.Translation.translate(media.override.name))
        ) {
            return vm.Translation.translate(media.override.name);
        }

        return '';
    }

    /**
     * Get slide content class names.
     *
     * @param  {Object} mediaId The id of the media to get the classnames of.
     * @param  {number} index   The index of the slide to get the class names of.
     * @return {Array}  A list of css class names.
     */
    function getSlideContentClass(mediaId, index) {
        const slideContentClass = [];

        if (vm.activeIndex === index) {
            slideContentClass.push('header-slideshow__content--is-active');
        }

        slideContentClass.push(getMediaClass(mediaId));

        return slideContentClass;
    }

    /**
     * Get slideshow style.
     *
     * @return {string} A class name for the slideshow.
     */
    function getSlideshowStyle() {
        const headerStyle = vm.Header.getCurrent().properties.style;

        if (angular.isDefinedAndFilled(headerStyle)) {
            return `header-slideshow__container--${headerStyle}`;
        }

        return '';
    }

    /**
     * Go back to the home page.
     */
    function goToHome() {
        if (get(Content.getCurrent(), 'isHomepage')) {
            location.reload();
        }

        let path = '';

        if ($location.path().indexOf('/a/') === 0) {
            path = `/a/${Customer.getCustomerSlug()}`;
        }

        path += `/${vm.Instance.getInstance().slug}`;

        $location.path(path);
    }

    /**
     * Go to a given slide.
     * Todo [Arnaud]: All the slideshow stuff should be in its own directive / controller so we can reuse it.
     *
     * @param {number} index The index of the slide to go to.
     */
    function goToSlide(index) {
        vm.activeIndex = index;

        _animateSlide();
    }

    /**
     * Display the next slide in the slideshow.
     * Todo [Arnaud]: All the slideshow stuff should be in its own directive / controller so we can reuse it.
     *
     * @param {boolean} autoPlay Whether to keep autoplaying the slideshow or not.
     */
    function nextSlide(autoPlay) {
        if (angular.isUndefined(autoPlay) || !autoPlay) {
            _cancelAutoplay();
        }

        if (vm.activeIndex + 1 === get(vm.Header.getCurrent(), 'properties.media.length', 0)) {
            vm.activeIndex = 0;
        } else {
            vm.activeIndex++;
        }

        _animateSlide();
    }

    /**
     * Display the previous slide in the slideshow.
     * Todo [Arnaud]: All the slideshow stuff should be in its own directive / controller so we can reuse it.
     *
     */
    function previousSlide() {
        _cancelAutoplay();

        vm.activeIndex--;

        _animateSlide();
    }

    /**
     * If in a community context, checks if the user can contribute so it unlocks the quick post creation button.
     * If in another context, always return true.
     *
     * @return {boolean} whether the user can create a post in the current context or not.
     */
    function checkCreatePostRightsInContext() {
        const _currentContentType = get(Content.getCurrent(), 'type');
        return _currentContentType !== InitialSettings.CONTENT_TYPES.COMMUNITY || Community.isWriteable();
    }

    /////////////////////////////

    vm.addNewPost = addNewPost;
    vm.canCustomizeLayout = canCustomizeLayout;
    vm.getHeaderTopClasses = getHeaderTopClasses;
    vm.getMediaClass = getMediaClass;
    vm.getMediaDescription = getMediaDescription;
    vm.getMediaImage = getMediaImage;
    vm.getMediaName = getMediaName;
    vm.getSlideContentClass = getSlideContentClass;
    vm.getSlideshowStyle = getSlideshowStyle;
    vm.goToHome = goToHome;
    vm.goToSlide = goToSlide;
    vm.nextSlide = nextSlide;
    vm.isHeadlessModeOn = Utils.isHeadlessModeOn;
    vm.previousSlide = previousSlide;
    vm.checkCreatePostRightsInContext = checkCreatePostRightsInContext;
    vm.getHeaderHeight = Utils.getHeaderHeight;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Open the create new post dialog.
     */
    vm.unregisterNewPostOpen = $rootScope.$on('create-new-post__open', (evt, post, community) => {
        if(!community) {
            Utils.waitForAndExecute(`#${vm.newPostDialogId}`);
        }
    });

    /**
     * Cleanup on destroy.
     */
    $scope.$on('$destroy', function onDestroy() {
        if (angular.isDefined(vm.unregisterNewPostOpen) && angular.isFunction(vm.unregisterNewPostOpen)) {
            vm.unregisterNewPostOpen();

            delete vm.unregisterNewPostOpen;
        }
    });

    /**
     * Reset the `_bypassStateChangeEvent` status each time we change routes.
     */
    $scope.$on('$stateChangeStart', () => {
        _bypassStateChangeEvent = false;
    });

    /**
     * Set the current header when the state is changed successfully.
     *
     * @param {Event}  evt     The original event triggering this method.
     * @param {Object} toState The state we just navigated to.
     */
    $scope.$on('$stateChangeSuccess', (evt, toState) => {
        if (_bypassStateChangeEvent) {
            _bypassStateChangeEvent = false;

            return;
        }

        const contentHeader = get(Content.getCurrent(), 'headerDetails');

        if (includes(_DEFAULT_HEADER_STATES, toState.name) || angular.isUndefinedOrEmpty(contentHeader)) {
            Header.setCurrent(Header.getDefaultHeader());
        } else {
            Header.setCurrent(contentHeader);
        }

        vm.init();
    });

    /**
     * Cancel the slideshow autoplay.
     */
    $scope.$on('cancel-header-autoplay', _cancelAutoplay);

    /**
     * Re-initialize when the style is changed.
     */
    $scope.$on('instance-style-settings', () => {
        vm.init();
    });

    /**
     * Re-initialize when header settings are reverted.
     */
    $scope.$on('header-init', () => {
        vm.init();
    });

    /**
     * Re-initialize the header once the media picker is closed.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The id of the dialog that is being closed.
     */
    $scope.$on('abstract-picker__close-end', (evt, dialogId) => {
        if (dialogId === 'media-picker-header') {
            vm.init();
        }
    });

    /**
     * Re-initialize when we get a new header.
     */
    $scope.$on('template__get-header', () => {
        _bypassStateChangeEvent = true;
        vm.init();
    });

    /**
     * Re-initialize when autoplay settings are changed.
     *
     * @param {Event}   evt          The reload header event.
     * @param {boolean} [reset=true] Indicates if we want to reset the current selected header.
     */
    $scope.$on('template__reload-header', (evt, reset) => {
        reset = angular.isUndefined(reset) ? true : reset;

        _cancelAutoplay();

        if (reset) {
            const currentContent = Content.getCurrent();

            if (
                angular.isDefinedAndFilled(get(currentContent, 'headerDetails.media')) ||
                angular.isDefinedAndFilled(get(currentContent, 'headerDetails.images'))
            ) {
                Header.setCurrent(currentContent.headerDetails);
            } else {
                Header.setCurrent(Header.getDefaultHeader());
            }
        }

        vm.init();
    });

    /**
     * On click the header if in the designer, open Header settings
     */
    $element.bind('click', (evt) => {
        if (vm.Content.getAction() !== 'get' && !Community.isSpace() && includes(['default', 'simple'], vm.Content.getViewMode())) {
            $scope.$apply(() => {
                Row.resetRow();
                Cell.resetCell();
                Widget.resetWidget();
                Header.isActive = true;

                $rootScope.$broadcast('designer-component__set', 'header');
            });
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        const currentHeader = Header.getCurrent() || {};
        const defaultHeader = Header.getDefaultHeader();

        // If we heritate the navigation from the parent instance, the header top uses the parent style.
        if (vm.Features.hasMainNavInheritance()) {
            vm.headerStyle = Style.getInstanceParentStyle();
        } else {
            // We get the header style by this way to be aware of any style update.
            vm.headerStyle = vm.Style.getCurrent('global');
        }

        // The current nav always need the current style.
        vm.currentMainNavStyle = vm.Style.getCurrent('global');

        _cancelAutoplay();

        _setDefaultValues(currentHeader, defaultHeader);

        // Start slideshow autoplay.
        if (angular.isDefined(Content.getCurrent()) || $state.current.name === 'app.front.404') {
            if (get(currentHeader, 'properties.autoplay', false) && !isAutoTestModeEnabled()) {
                _slideshowIntervalId = $interval(() => {
                    nextSlide(true);
                }, currentHeader.properties.interval * 1000);
            }

            if (vm.activeIndex + 1 > currentHeader.properties.media.length) {
                goToSlide(0);
            }
        }

        const random = get(currentHeader, 'properties.random', false) && !isAutoTestModeEnabled();

        // Shuffle media.
        if (random) {
            vm.medias = shuffle(currentHeader.properties.media);
        } else {
            vm.medias = currentHeader.properties.media;
        }

        // add an isSpace flag to remove the default header/slideshow for spaces
        const currentContent = Content.getCurrent();

        const community = currentContent.contentType === 'community' ? Community.getCurrent() : undefined;
        vm.isSpace = Community.isSpace(community);

        $timeout(_setInitialCreatePostButtonVisibility, 1000);
    };
}

/////////////////////////////

angular.module('Controllers').controller('HeaderController', HeaderController);

/////////////////////////////

export { HeaderController };
