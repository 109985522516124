import React from 'react';

import { FlexBox, Size, SkeletonCircle, SkeletonTypography, Typography } from '@lumapps/lumx/react';

export interface LearnerStatsSkeletonProps {
    smallLayout: boolean;
}

/**
 *
 * @param LearnerStatsSkeletonProps
 * @returns LearnerStatsSkeleton
 */
export const LearnerStatsSkeleton: React.FC<LearnerStatsSkeletonProps> = ({ smallLayout }) => {
    return smallLayout ? (
        <FlexBox orientation="vertical" gap="regular" vAlign="center">
            <SkeletonCircle size={Size.l} />
            <FlexBox orientation="vertical">
                <SkeletonTypography
                    className="lumx-spacing-margin-top-regular"
                    typography={Typography.body1}
                    width="50px"
                />
                <SkeletonTypography
                    className="lumx-spacing-margin-top-regular"
                    typography={Typography.body1}
                    width="50px"
                />
            </FlexBox>
        </FlexBox>
    ) : (
        <FlexBox orientation="horizontal" gap="big">
            <SkeletonCircle size={Size.l} />
            <FlexBox orientation="vertical">
                <SkeletonTypography
                    className="lumx-spacing-margin-top-regular"
                    typography={Typography.body1}
                    width="110px"
                />
                <SkeletonTypography
                    className="lumx-spacing-margin-top-regular"
                    typography={Typography.body1}
                    width="110px"
                />
            </FlexBox>
        </FlexBox>
    );
};
