import React from 'react';

import { Theme } from '@lumapps/lumx/react';

import { useForm, UseFormOptions } from '../../hooks/useForm';
import { Form as UI, FormProps as UIProps } from './Form';

export interface FormProps extends Omit<UIProps, 'methods'> {
    /** data related to the form setup and configuration */
    form?: UseFormOptions['form'];
}

/**
 * A component that renders a form that uses react-hook-form in order to manage the internal state.
 * @family Forms
 * @param FormProps
 * @returns Form
 */
export const Form: React.FC<FormProps> = ({
    children,
    onSubmit,
    onCancel,
    form,
    formProps,
    wrapperProps,
    toolbarProps,
    useExternalActions = false,
    CustomFormActions,
    formActionProps,
    status,
    scope,
    hasDiscard,
    theme = Theme.light,
}) => {
    const {
        methods,
        onSubmit: onFormSubmit,
        ...restOfMethods
    } = useForm({
        form,
        onSubmit,
        status,
    });

    return (
        <UI
            onSubmit={onFormSubmit}
            methods={methods}
            onCancel={onCancel}
            formProps={formProps}
            wrapperProps={wrapperProps}
            toolbarProps={toolbarProps}
            useExternalActions={useExternalActions}
            CustomFormActions={CustomFormActions}
            formActionProps={formActionProps}
            status={status}
            scope={scope}
            hasDiscard={hasDiscard}
            theme={theme}
            {...restOfMethods}
        >
            {children}
        </UI>
    );
};
