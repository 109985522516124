import { NodeEntry, ReactEditor } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';

import { ImageEditor, ImageEditorOptions } from '../../types';
import { isAlignedImageWrapper } from '../../utils/isAlignedImageWrapper';
import { isImageGroup } from '../../utils/isImageGroup';
import { uploadExternalImage } from '../../utils/uploadExternalImage';

export function normalizeImage(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    [node, path]: NodeEntry,
) {
    if (!isImageGroup(node)) {
        return false;
    }
    // TODO: Make this call async to only upload the image one time
    if (node.external) {
        uploadExternalImage(options, editor, [node, path]);
    }
    const parentAlignmentWrapper = findParent(editor, path, isAlignedImageWrapper);
    if (!parentAlignmentWrapper && path.length > 1) {
        normalizeLiftToRootNode(editor, [node, path], isImageGroup);
    }
    return false;
}
