import React, { useCallback, useEffect } from 'react';

import loget from 'lodash/get';

import { getConnector, getAuthorizeUrl } from '@lumapps/connectors/api/connectorsApi';
import { mdiSlack } from '@lumapps/lumx/icons';
import { Button, Emphasis } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { SLACK_DEFAULT_APP_ID, SLACK_PROVIDER } from '../../constants';
import { CHAT } from '../../keys';
import { UserTokenStatusProvider, UserTokenStatusQuery } from '../../types';

export interface SlackLoginButtonProps {
    spaceId: string;
    userTokenStatus: UserTokenStatusProvider;
    fetchUserTokenStatus: ({ provider, spaceId }: UserTokenStatusQuery) => void;
    fetchAuthorizeLink: ({ provider, spaceId }: UserTokenStatusQuery) => Promise<string>;
    disconnectUserToken: ({ provider, spaceId }: UserTokenStatusQuery) => Promise<void>;
    slackProvider: string;
    loggedInCallback?: () => void;
    shareToSlack?: boolean;
    isSlackDefaultAppEnabled: boolean;
}

const SlackLoginButton: React.FC<SlackLoginButtonProps> = ({
    spaceId,
    userTokenStatus,
    fetchUserTokenStatus,
    fetchAuthorizeLink,
    disconnectUserToken,
    slackProvider,
    loggedInCallback,
    shareToSlack,
    isSlackDefaultAppEnabled,
}) => {
    const { translateKey } = useTranslate();
    const [isConnected, setIsConnected] = React.useState(false);
    const [isConnectClick, setIsConnectClick] = React.useState(false);

    /**
     * In the case we select a userSpace,
     * we need to know if the user is connected to this user space.
     */
    useEffect(() => {
        if (!spaceId) {
            return;
        }

        fetchUserTokenStatus({ provider: SLACK_PROVIDER, spaceId });
    }, [fetchUserTokenStatus, spaceId, isSlackDefaultAppEnabled]);

    /**
     * When we get the user Status, display the correct button
     */
    useEffect(() => {
        if (!spaceId) {
            return;
        }

        if (!userTokenStatus || !(spaceId in userTokenStatus)) {
            return;
        }

        setIsConnected(loget(userTokenStatus[spaceId], 'is_connected', false) as boolean);
        if (isConnected && typeof loggedInCallback === 'function') {
            loggedInCallback();
        }
    }, [spaceId, userTokenStatus, isConnected, loggedInCallback]);

    const getUserToken = useCallback(
        (response: string, closeKey: string) => {
            const authWin = window.open(response, '_blank', 'width=600, height=600');

            return new Promise(() => {
                const onClose = async () => {
                    fetchUserTokenStatus({ provider: slackProvider, spaceId });
                };

                const intervalRef = setInterval(() => {
                    if (authWin) {
                        if (authWin.closed) {
                            clearInterval(intervalRef);
                            onClose();
                        } else {
                            try {
                                const url = authWin.location.href;
                                if (url.includes(closeKey)) {
                                    clearInterval(intervalRef);
                                    authWin.close();
                                    onClose();
                                }
                            } catch (exception) {
                                return undefined;
                            }
                        }
                    }

                    return undefined;
                }, 1000);
            });
        },
        [fetchUserTokenStatus, slackProvider, spaceId],
    );

    async function onClickConnectSlackButton() {
        if (isSlackDefaultAppEnabled) {
            try {
                const slackDefaultConnector = await getConnector(SLACK_DEFAULT_APP_ID);
                const { data: authorize } = await getAuthorizeUrl(slackDefaultConnector.id);
                const { link } = authorize;

                if (link) {
                    await getUserToken(link, '/v2/oauth2/callback');
                    return;
                }
            } catch (e) {
                // todo: Handle request errors
            }
        }

        if (!spaceId) {
            return;
        }

        setIsConnectClick(true);
    }

    async function onClickDisconnectSlackButton() {
        if (!spaceId) {
            return;
        }

        disconnectUserToken({ provider: slackProvider, spaceId }).then(() => {
            fetchUserTokenStatus({
                provider: slackProvider,
                spaceId,
            });
        });
    }

    useEffect(() => {
        if (!isConnectClick) {
            return;
        }

        setIsConnectClick(false);

        fetchAuthorizeLink({ provider: slackProvider, spaceId }).then((response: string) => {
            return getUserToken(response, 'slackcallback');
        });
    }, [fetchAuthorizeLink, spaceId, setIsConnectClick, isConnectClick, slackProvider, getUserToken]);

    return (
        <>
            {(spaceId || isSlackDefaultAppEnabled) && !isConnected && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {shareToSlack ? (
                        <Button id="slack-login-button" onClick={onClickConnectSlackButton} emphasis={Emphasis.high}>
                            {translateKey(CHAT.SELECT_WORKSPACE)}
                        </Button>
                    ) : (
                        <Button
                            id="slack-login-button"
                            className="slack-settings__connect slack-settings__connect--slack"
                            onClick={onClickConnectSlackButton}
                            leftIcon={mdiSlack}
                        >
                            {translateKey(CHAT.CONNECT)}
                        </Button>
                    )}
                </>
            )}
            {spaceId && isConnected && (
                <Button
                    id="slack-login-button"
                    onClick={onClickDisconnectSlackButton}
                    className="slack-settings__disconnect slack-settings__disconnect--slack"
                    leftIcon={mdiSlack}
                >
                    {translateKey(CHAT.DISCONNECT)}
                </Button>
            )}
        </>
    );
};

export { SlackLoginButton };
