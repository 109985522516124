/* eslint-disable react/forbid-elements */
import React from 'react';

import { classnames } from '@lumapps/classnames';
import { ContentHeaderDisplayType } from '@lumapps/contents/types';
import { FlexBox, Orientation, Alignment, FlexBoxProps } from '@lumapps/lumx/react';

import './index.scss';

export interface ContentHeaderOverlayProps extends FlexBoxProps {
    title?: string;
    description?: string;
    displayType?: ContentHeaderDisplayType;
    wrapperHeight?: number;
}

export const CLASSNAME = 'content-header-overlay';

export const ContentHeaderOverlay: React.FC<ContentHeaderOverlayProps> = ({
    title,
    description,
    displayType,
    wrapperHeight,
    children,
    ...rest
}) => {
    const { className, ...wrapperProps } = rest;

    return (
        <div
            className={classnames(CLASSNAME, className, {
                [`${CLASSNAME}--${displayType}`]: displayType,
            })}
            {...wrapperProps}
        >
            <FlexBox
                className={`${CLASSNAME}__wrapper lumx-color-font-light-N`}
                orientation={Orientation.vertical}
                vAlign={Alignment.left}
            >
                <FlexBox
                    className={`${CLASSNAME}__wrapper lumx-color-font-light-N`}
                    orientation={Orientation.vertical}
                    hAlign={Alignment.bottom}
                    vAlign={Alignment.left}
                    style={{ height: wrapperHeight }}
                >
                    {title && <h1 className={`${CLASSNAME}__title`}>{title}</h1>}
                    {description && (
                        <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                            className={`${CLASSNAME}__description lumx-typography-healine`}
                        />
                    )}
                </FlexBox>

                {children}
            </FlexBox>
        </div>
    );
};
