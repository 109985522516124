export enum ROLES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265983375
     */
    MENU_PARTIAL_EDIT_ACTION = 'ROLES.MENU_PARTIAL_EDIT_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244404
     */
    ROLES = 'ADMIN.NAV.ROLES',
    AUTHORIZATIONS = 'ADMIN_ROLE_TAB_AUTHORIZATIONS',
    DELETE_ROLE = 'ADMIN_ROLE_DELETE',
    DELETE_ROLE_DESCRIPTION = 'ADMIN_ROLE_DELETE_DESCRIPTION',
    DELETE_ROLE_SUCCESS = 'ADMIN_ROLE_DELETE_SUCCESS',
    ROLE_SAVE_SUCCESS = 'ADMIN_ROLE_SAVE_SUCCESS',
}
