import get from 'lodash/fp/get';

import { selectSpaceOrCommunityId } from '@lumapps/communities/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';

import { EventPreviewState, EventsState } from '../types';

export const EVENTS_FF = 'events';

const eventSelector = ({ events }: { events: EventsState }) => events;
const eventPreviewSelector = ({ eventPreview }: { eventPreview: EventPreviewState }) => eventPreview;

/** Event dialog is open. */
export const isEventDialogOpenSelector = createSelector(eventSelector, (eventState) =>
    Boolean(eventState.isEventDialogOpen),
);

/** Event notification dialog is open. */
export const isEventNotificationDialogOpenSelector = createSelector(eventSelector, (eventState) =>
    Boolean(eventState?.notificationDialog?.isOpen),
);

/** Event notification dialog is loading. */
export const isEventNotificationDialogLoadingSelector = createSelector(eventSelector, (eventState) =>
    Boolean(eventState?.notificationDialog?.isLoading),
);

/** Event id to notify about. */
export const eventNotificationDialogIdSelector = createSelector(
    eventSelector,
    (eventState) => eventState?.notificationDialog?.eventId,
);

/** Event feature enabled. */
export const isEventFeatureEnabled = createSelector(
    isFeatureEnabled(EVENTS_FF),
    (isEventFFEnabled) => isEventFFEnabled,
);

/** Check if the event dialog is in the publishing state. */
export const isEventDialogPublishingSelector = createSelector(eventSelector, get('isEventDialogPublishing'));

/** Get the currently edited event. */
export const editedEventSelector = createSelector(eventSelector, get('editedEvent'));

/** Get the current event. */
export const currentEventPageSelector = createSelector(eventSelector, get('current'));

/** Get the current event title. */
export const currentEventTitleSelector = createSelector(eventSelector, get('current.title'));

/** Get the container id of the current event (new or edited). */
export const eventContainerIdSelector = createSelector(
    [editedEventSelector, selectSpaceOrCommunityId],
    (editedEvent, parentContainerId) => {
        if (editedEvent) {
            return editedEvent?.shares?.[0].container.containerId;
        }
        return parentContainerId;
    },
);

/** Get entities for event. */
export const eventEntitiesSelector = createSelector(eventPreviewSelector, get('entities'));

export const eventEntitySelector = (id: string) =>
    createSelector([eventEntitiesSelector], (entities) => (entities ? entities[id] : undefined));

/** Get batch of event id's. */
export const eventBatchIdsSelector = createSelector(eventPreviewSelector, get('eventBatchIds'));
