import React from 'react';

import { SelectFieldMultiple, SelectFieldMultipleProps } from '@lumapps/combobox/components/SelectFieldMultiple';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';
import { DefaultValueWrapper } from '../DefaultValueWrapper';

export type FormSelectMultipleProps<T> = Omit<
    SelectFieldMultipleProps<T>,
    'onChange' | 'value' | 'isOpen' | 'setOpen'
> &
    BaseInput<T>;

/**
 * Component to be used for selecting multiple options inside a form
 * @family Forms
 * @param FormSelectMultipleProps
 * @returns FormSelectMultiple
 */
export const FormSelectMultiple = <T,>({
    name,
    isRequired,
    controllerProps,
    label,
    additionalProps,
    ...props
}: FormSelectMultipleProps<T>) => {
    const { translateKey } = useTranslate();
    const { field, fieldState, defaulValueWrapperProps, isFieldDisabled, isOpen, setIsOpen, valueToUse, scope } =
        useFormField<T[]>({
            name,
            isRequired,
            controllerProps,
            label,
        });

    const hasError = Boolean(fieldState.error);

    return (
        <DefaultValueWrapper {...defaulValueWrapperProps}>
            <SelectFieldMultiple
                aria-invalid={hasError}
                scope={`${scope}-select-field-multiple`}
                {...props}
                label={label}
                isOpen={isOpen}
                setOpen={setIsOpen}
                value={valueToUse || []}
                onChange={field.onChange}
                additionalProps={{
                    ref: field.ref,
                    name: field.name,
                    'aria-invalid': hasError,
                    hasError,
                    isRequired,
                    error: translateKey(GLOBAL.FIELD_IS_REQUIRED),
                    onBlur: field.onBlur,
                    isDisabled: isFieldDisabled,
                    ...additionalProps,
                }}
            />
        </DefaultValueWrapper>
    );
};
