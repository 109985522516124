import { membersOnlyContribution } from '@lumapps/lumx/custom-icons';
import { mdiEarth, mdiEyeOff, mdiLock } from '@lumapps/lumx/icons';

import { COMMUNITIES, SPACES } from './keys';
import { CommunityPrivacy, VisibilityOptionType } from './types';

export enum CommunityView {
    home = '',
    dashboard = 'dashboard',
    posts = 'posts',
    post = 'post',
    events = 'events',
    media = 'media',
    calendar = 'calendar',
}

export const DEFAULT_VIEW = CommunityView.posts;

export const VISIBILITY_ICONS: Record<string, string> = {
    open: mdiEarth,
    restricted: mdiEyeOff,
    secret: mdiEyeOff,
    read_only: membersOnlyContribution,
    description_only: mdiLock,
};

export const COMMUNITY_VISIBILITIES: Array<VisibilityOptionType> = [
    {
        // "Open" visibility
        id: CommunityPrivacy.open,
        title: COMMUNITIES.PRIVACY_OPEN,
        description: COMMUNITIES.PRIVACY_OPEN_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.open],
    },
    {
        // "Members only contribution" visibility
        id: CommunityPrivacy.readOnly,
        title: COMMUNITIES.PRIVACY_READ_ONLY,
        description: COMMUNITIES.PRIVACY_READ_ONLY_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.readOnly],
    },
    {
        // "Private" visibility
        id: CommunityPrivacy.descriptionOnly,
        title: COMMUNITIES.PRIVACY_DESCRIPTION_ONLY,
        description: COMMUNITIES.PRIVACY_DESCRIPTION_ONLY_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.descriptionOnly],
    },
    {
        // "Secret" visibility
        id: CommunityPrivacy.restricted,
        title: COMMUNITIES.PRIVACY_RESTRICTED,
        description: COMMUNITIES.PRIVACY_RESTRICTED_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.restricted],
    },
];

export const SPACE_VISIBILITIES: Array<VisibilityOptionType> = [
    {
        // "Open" visibility
        id: CommunityPrivacy.open,
        title: SPACES.PRIVACY_OPEN,
        description: SPACES.PRIVACY_OPEN_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.open],
    },
    {
        // "Members only contribution" visibility
        id: CommunityPrivacy.readOnly,
        title: SPACES.PRIVACY_READ_ONLY,
        description: SPACES.PRIVACY_READ_ONLY_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.readOnly],
    },
    {
        // "Private" visibility
        id: CommunityPrivacy.descriptionOnly,
        title: SPACES.PRIVACY_DESCRIPTION_ONLY,
        description: SPACES.PRIVACY_DESCRIPTION_ONLY_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.descriptionOnly],
    },
    {
        // "Secret" visibility
        id: CommunityPrivacy.restricted,
        title: SPACES.PRIVACY_RESTRICTED,
        description: SPACES.PRIVACY_RESTRICTED_DESCRIPTION,
        icon: VISIBILITY_ICONS[CommunityPrivacy.restricted],
    },
];

export const LEGACY_DEFAULT_POST_TYPES = ['EVENT', 'DEFAULT'];

/** Empty community template used in the legacy application. */
export const LEGACY_EMPTY_COMMUNITY_TEMPLATE = {
    customerId: undefined,
    descriptionOnly: false,
    instanceId: undefined,
    name: '',
    postStatuses: [],
    postTypes: LEGACY_DEFAULT_POST_TYPES,
    privacy: CommunityPrivacy.restricted,
    tagsDetails: [],
    templates: [],
    uid: undefined,
};

export const SAVE_AS_TEMPLATE_DATA_STORAGE_KEY = 'saveAsTemplate';

export const SPACES_FEATURE_TOKEN = 'spaces';
export const SPACES_HOMEPAGE_COPY_FEATURE_TOKEN = 'spaces-homepage-copy';
export const COMMUNITY_FEATURE_TOKEN = 'community';
export const SECURITY_AT_COMMUNITY_LEVEL = 'security-at-community-level';
export const EVENTS = 'events';
export const NEW_COMMUNITY_NAVIGATION = 'new-community-navigation';
export const HORIZONTAL_COMMUNITY_TEMPLATE = 'horizontal-community-template';

/**
 * Defines the contribution field breakpoints according to the probable button width.
 */
export const CONTRIBUTION_FIELD_BREAKPOINTS = {
    0: 90,
    1: 180,
    2: 270,
    3: 360,
    4: 450,
    5: 540,
    6: 630,
    7: 720,
};

/**
 * Used to handle play button with long text on mobile size
 */
export const MOBILE_CONTRIBUTION_FIELD_BREAKPOINTS = {
    0: 115,
    1: 230,
    2: 345,
    3: 460,
    4: 575,
    5: 690,
    6: 805,
    7: 880,
};

/**
 * Defines the contribution field number of buttons to display.
 */
export const CONTRIBUTION_FIELD_BUTTONS: Record<keyof typeof CONTRIBUTION_FIELD_BREAKPOINTS, number> = {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
    7: 8,
};
