import React, { useRef } from 'react';

import { Alignment, AspectRatio, Emphasis, IconButton, Size, Thumbnail, UserBlock } from '@lumapps/lumx/react';
import { mdiFacebookWorkplace, mdiMicrosoftYammer } from '@lumapps/lumx/icons';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { utc } from 'moment';

import { any, objectOf, string } from 'prop-types';

import { translate as t } from 'components/translations';
import { InitialSettings } from 'common/modules/constants/initial-settings_constants';
import { AVATAR_CONTENT_TYPE_VALUES, CHAT_PROVIDER_VALUES } from 'components/components/widgets/chat/chat_constants';
import { getReactionsCount } from 'components/components/chat/utils/chat_utils';

/**
 * Display the wall post block component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The wall post block react element.
 */
const SocialPostBlock = ({ item, provider, theme }) => {
    const containerRef = useRef(null);

    /**
     * Function that open the post url in a new tab.
     */
    const openUrl = () => {
        window.open(item.webUrl, '_blank');
    };

    /**
     * Sanitize post to only keep safe content.
     *
     * @param  {string} str The content to sanitize.
     * @return {string} The sanitized content.
     */
    const sanitizePost = (str) => {
        return sanitizeHTML(str, { useProfiles : { html: true, svg: true, svgFilters: true, mathMl: true }});
    };

    const getText = () => {
        if (!item.body.content) {
            return '';
        }

        if (item.body.content.length <= 500) {
            return { __html: sanitizePost(item.body.content) };
        }

        return {
            __html: `${sanitizePost(item.body.content.slice(0, 500))} ... <a href="${
                item.webUrl
            }" rel="nofollow noopener noreferrer" target="_blank">${t('WALL.SEE_MORE_YAMMER')}</a>`,
        };
    };

    const getUserPhoto = (message) => {
        // Will be removed when all picture will be blobstored
        if (message.avatar) {
            switch (message.avatar.contentType) {
                case AVATAR_CONTENT_TYPE_VALUES.URL:
                    return message.avatar.content;

                default:
                    return message.avatar.content;
            }
        }

        if ([CHAT_PROVIDER_VALUES.SLACK.id, CHAT_PROVIDER_VALUES.FBWORKPLACE.id].includes(provider)) {
            return InitialSettings.DEFAULT_AVATAR;
        }

        const { id } = message.sender.user;

        return `/chat/avatar/${provider}/${id}`;
    };

    const getProviderIcon = () => {
        switch (provider) {
            case CHAT_PROVIDER_VALUES.FBWORKPLACE.id:
                return mdiFacebookWorkplace;

            case CHAT_PROVIDER_VALUES.YAMMER.id:
                return mdiMicrosoftYammer;

            default:
                return '';
        }
    };

    const userPhoto = getUserPhoto(item);
    return (
        <div ref={containerRef} className="social-post-block">
            <div className="social-post-block__header">
                <div className="social-post-block__author">
                    <UserBlock
                        avatarProps={
                            userPhoto
                                ? {
                                      image: userPhoto,
                                      alt: item.sender.user.displayName,
                                  }
                                : undefined
                        }
                        name={item.sender.user.displayName}
                        fields={[
                            `${utc(item.lastModifiedDateTime).fromNow()} ${t('WIDGET.WALL.POSTBLOCK.META.IN')} ${
                                item.groupName
                            }`,
                        ]}
                        size={Size.m}
                        theme={theme}
                    />
                </div>

                <div className="social-post-block__open">
                    <IconButton
                        icon={getProviderIcon()}
                        emphasis={Emphasis.low}
                        theme={theme}
                        href={item.webUrl}
                        target="_blank"
                        label=""
                    />
                </div>
            </div>

            {item.subject && (
                <a
                    className="social-post-block__title"
                    href={item.webUrl}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    {item.subject}
                </a>
            )}

            {getText() && <div className="social-post-block__content" dangerouslySetInnerHTML={getText()} />}

            {item.thumbnail && (
                <div className="social-post-block__thumbnail-wrapper">
                    <div className="social-post-block__thumbnail">
                        <div className="social-post-block__image">
                            <Thumbnail
                                alt=""
                                aspectRatio={AspectRatio.original}
                                align={Alignment.center}
                                image={item.thumbnail}
                                theme={theme}
                                onClick={openUrl}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="social-post-block__reactions">
                <span>
                    {getReactionsCount(item)}{' '}
                    {getReactionsCount(item) <= 1 ? t('LIKE_BUTTON_LABEL') : t('LIKE_BUTTON_LABEL_PLURAL')}
                </span>
                <span>
                    {item.nbComments || '0'}{' '}
                    {!item.nbComments || item.nbComments <= 1
                        ? t('COMMENT_BUTTON_LABEL')
                        : t('COMMENT_BUTTON_LABEL_PLURAL')}
                </span>
            </div>
        </div>
    );
};

SocialPostBlock.defaultProps = {
    provider: undefined,
    theme: undefined,
};

SocialPostBlock.propTypes = {
    /* The post items. */
    item: objectOf(any).isRequired,
    /* The wall provider. */
    provider: string,
    /* The lumx theme. */
    theme: string,
};

export default SocialPostBlock;
