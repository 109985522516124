(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSettingsAdvancedController(Widget, Features, Content) {
        'ngInject';

        var widgetSettingsAdvanced = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetSettingsAdvanced.Widget = Widget;

        widgetSettingsAdvanced.Features = Features;
        widgetSettingsAdvanced.Content = Content;
        widgetSettingsAdvanced.placeholderHelper = 'WIDGET_SETTINGS_HELPER_HELP';

        /**
         *
         */
        widgetSettingsAdvanced.restrictToIds = [];

        /////////////////////////////

        /**
         * Set the parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            widgetSettingsAdvanced.parentCtrl = parentCtrl;
        };

        /**
         *
         */
        function onRestrictToChange(restrictToGroups) {
            widgetSettingsAdvanced.restrictToIds = restrictToGroups.map((g) => g.id);

            widgetSettingsAdvanced.Widget.getCurrent().feedKeys = widgetSettingsAdvanced.restrictToIds;
        }

        /**
         * Initialize the controller.
         */
        function init() {
            const hasNewSimpleTemplateFF = widgetSettingsAdvanced.Features.hasFeature('new-simple-template-experience');
            const isNewSimpleTemplateContent = widgetSettingsAdvanced.Content.getCurrent().template.basicMode;
            const widgetType = widgetSettingsAdvanced.Widget.getCurrent().widgetType;
            const widgetsWithWriterPlaceholder = ['title', 'contribution', 'intro', 'summary', 'featured-image'];

            /** On the New Simple Template custom placeholder doesn't display for interactive widgets in writer mode 
             * That's because they have their own placeholder ("Write text here...", "Featured image"...) 
             * For thos widgets add another helper to indicate to the user that his change will not impact Writer mode */ 
            if (
                hasNewSimpleTemplateFF &&
                isNewSimpleTemplateContent &&
                widgetsWithWriterPlaceholder.includes(widgetType)
            ) {
                widgetSettingsAdvanced.placeholderHelper = 'WIDGET_SETTINGS.INTERACTIVE_WIDGET_PLACEHOLDER_HELPER';
            }
        }

        init();

        widgetSettingsAdvanced.onRestrictToChange = onRestrictToChange;
    }

    /////////////////////////////

    function WidgetSettingsAdvancedDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetSettingsAdvancedController,
            controllerAs: 'widgetSettingsAdvanced',
            link: link,
            require: ['widgetSettingsAdvanced', '^widgetSettings'],
            restrict: 'E',
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/common/views/widget-settings-advanced.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('widgetSettingsAdvanced', WidgetSettingsAdvancedDirective);
})();
