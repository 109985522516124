import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiAccountKey } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { ROLES } from '../../keys';
import { roles } from '../../routes';

export interface RoleSideNavigationProps {
    isAllowed: boolean;
}

const RoleSideNavigation = ({ isAllowed }: RoleSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return <SideNavigationItem label={translateKey(ROLES.ROLES)} icon={mdiAccountKey} route={roles()} />;
};

RoleSideNavigation.displayName = 'SideNavigationItem';

export { RoleSideNavigation };
