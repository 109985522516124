/* eslint-disable no-param-reassign */
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import set from 'lodash/set';

import { TranslatedMetadataListV2 } from '@lumapps/metadata/types';
import createSlice, { AngularAction, PayloadAction } from '@lumapps/redux/createSlice';
import { RouterStatus } from '@lumapps/router/ducks/types';
import { User } from '@lumapps/user/types';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

import { Content } from '../types';
import { domain } from './domain';
import { ActionState, ContentState } from './types';

export const initialState: Partial<ContentState> = {
    id: undefined,
    entities: {},
    actionState: {},
};

export const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        '__angular__/content/update': /* istanbul ignore next */ (
            state: ContentState,
            action: AngularAction<Content>,
        ) => {
            Object.assign(state, action.newState);
        },
        'ROOT/content/RESET_CONTENT': /* istanbul ignore next */ () => {
            return initialState;
        },
        /** Change current content. */
        setContent(state: ContentState, action: PayloadAction<Content>) {
            const content = mergeObjectOnly(omit(state, ['entities']), action.payload);
            Object.assign(state, content);
            set(state, ['entities', content.id], content);
        },
        resetContent() {
            return initialState;
        },
        setNextContentId(
            state: ContentState,
            action: PayloadAction<{
                status: RouterStatus;
                nextContentId?: ContentState['nextContentId'];
            }>,
        ) {
            const { status, nextContentId } = action.payload;

            if (status !== RouterStatus.loading) {
                set(state, 'nextContentId', null);
            } else {
                set(state, 'nextContentId', nextContentId);
            }
        },
        /** Set (or update) contents in the content by id index. */
        setContents(state: ContentState, action: PayloadAction<Content[]>) {
            const newContentEntities = keyBy(action.payload, 'id');
            state.entities = mergeObjectOnly(state.entities, newContentEntities);
        },
        like(state: ContentState, action: PayloadAction<Pick<Content, 'liked' | 'likes'>>) {
            set(state, 'liked', action.payload.liked);
            set(state, 'likes', action.payload.likes);
        },
        follow(state: ContentState, action: PayloadAction<Pick<Content, 'subscription'> | null>) {
            set(state, 'actionState.follow', 'loaded');
            set(state, 'subscription', action.payload);
        },
        setLikesList(
            state: ContentState,
            action: PayloadAction<{
                items: User[];
                isLoaded: boolean;
            }>,
        ) {
            set(state, 'likesList', mergeObjectOnly(state.likesList || {}, action.payload));
        },
        setFetchState(state: ContentState, action: PayloadAction<{ id: string; status: ActionState }>) {
            set(state, ['actionState', 'fetch', action.payload.id], action.payload.status);
        },
        setLikeState(state: ContentState, action: PayloadAction<ActionState>) {
            set(state, 'actionState.like', action.payload);
        },
        setFollowState(state: ContentState, action: PayloadAction<ActionState>) {
            set(state, 'actionState.follow', action.payload);
        },
        setActivateNotificationsState(state: ContentState, action: PayloadAction<ActionState>) {
            set(state, 'actionState.activateNotifications', action.payload);
        },
        setContentMetadata(state: ContentState, action: PayloadAction<TranslatedMetadataListV2>) {
            set(state, 'metadataDetails', action.payload);
        },
    },
});
