import React from 'react';

import { EmptyState } from '@lumapps/lumx-states/components/EmptyState';
import { mdiAccountGroup } from '@lumapps/lumx/icons';
import { FlexBox, Size } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { UserList } from '@lumapps/user/components/UserList';

import { useSpaceMembers } from '../../../hooks/useSpaceMembers';
import { SPACE_KEYS } from '../../../keys';
import { SpaceUserRole } from '../../../types';

export const Members = () => {
    const { users, isLoading, onLoadMore, onSearch, searchQuery } = useSpaceMembers({
        role: SpaceUserRole.member,
    });

    const { translateKey } = useTranslate();

    return (
        <FlexBox orientation="vertical">
            <UserList
                users={users}
                isLoading={isLoading}
                onLoadMore={onLoadMore}
                onSearch={onSearch}
                onSearchClear={() => onSearch('')}
                searchText={searchQuery}
                scope="space-about-members"
                emptyState={
                    <EmptyState
                        message={translateKey(SPACE_KEYS.NO_MEMBERS)}
                        iconProps={{ icon: mdiAccountGroup, size: Size.m }}
                        wrapperProps={{ className: 'lumx-spacing-margin-top-big' }}
                    />
                }
            />
        </FlexBox>
    );
};
