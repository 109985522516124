import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { FormDialog } from '@lumapps/lumx-forms/components/FormDialog';
import { FormURLTextField } from '@lumapps/lumx-forms/components/FormURLTextField';
import { Heading, Size, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { LUMX_DIALOGS } from '../../keys';

const CLASSNAME = 'lumx-link-dialog';

export interface LinkDialogProps {
    /** Value of the current link */
    currentLink: string;
    /** Input label */
    inputLabel?: string;
    /** Input placeholder */
    inputPlaceholder?: string;
    /** Whether the dialog is open or not */
    isOpen: boolean;
    /** Callback when submit button is pressed */
    onChange(link: string): void;
    /** Callback when discard button is pressed */
    onClose(): void;
    /** Feature scope for tracking and a11y purposes */
    scope: string;
    /** Title of the dialog */
    title?: string;
}

/**
 * Component that displays a dialog in order to enter a link
 *
 * @family Dialogs
 * @param LinkDialogProps
 * @returns LinkDialog
 */
export const LinkDialog: React.FC<LinkDialogProps> = (props) => {
    const {
        currentLink,
        inputLabel = LUMX_DIALOGS.LINK_DIALOG_TEXTFIELD_LABEL,
        inputPlaceholder,
        isOpen,
        onChange,
        onClose,
        scope,
        title = currentLink ? LUMX_DIALOGS.LINK_DIALOG_EDIT_TITLE : LUMX_DIALOGS.LINK_DIALOG_ADD_TITLE,
    } = props;
    const { block } = useClassnames(CLASSNAME);
    const [link, setLink] = React.useState(currentLink);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    const handleInsertion = React.useCallback(
        (data: any) => {
            setLink(data.link);
            onChange(data.link);
        },
        [onChange],
    );

    return (
        <FormDialog
            className={block()}
            form={{ defaultValues: { link } }}
            heading={<Heading typography={Typography.title}>{translateKey(title)}</Heading>}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleInsertion}
            formActionProps={{
                saveProps: {
                    children: translateKey(currentLink ? GLOBAL.APPLY : GLOBAL.ADD),
                    ...get({ element: 'button', action: currentLink ? 'apply-link' : 'add-link' }),
                },
                cancelProps: {
                    children: translateKey(GLOBAL.DISCARD),
                    ...get({ element: 'button', action: 'discard-link' }),
                },
            }}
            dialogProps={{ size: Size.tiny }}
            scope={scope}
        >
            <FormURLTextField placeholder={inputPlaceholder} label={translateKey(inputLabel)} name="link" />
        </FormDialog>
    );
};
