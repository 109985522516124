/* istanbul ignore file */
import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { useSelector, useDispatch } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';

import {
    getParentCustomContentTypes,
    isCustomContentTypesLoading,
    doesCurrentUserHaveAccessToCustomContentTypes,
    getPermissionsForCustomContentTypes,
    getCustomContentTypes,
    getCustomContentTypeByIDs,
    isCustomContentTypesIdle,
} from '../ducks/selectors';
import { fetchCustomContentTypes, getOrFetchCustomContentTypesByIds } from '../ducks/thunks';
import { CustomContentType } from '../types';

export interface UseCustomContentTypesOptions {
    /** callback on ccts load */
    onFinishedLoading?: () => void;
    /** whether cache for ccts should be invalidated or not */
    invalidateCache?: boolean;
    /** whether ccts should be fetched */
    shouldFetch?: boolean;
    /** ccts ids that should be fetched */
    ids?: string[];
}

export interface UseCustomContentTypes {
    /** Determines whether we are currently fetching the list or not */
    isLoading?: boolean;
    /** Determines whether the custom types state is idle  */
    isIdle: boolean;
    /** the list of parent custom content types to display */
    parentCustomContentTypes?: CustomContentType[];
    /** custom content types grouped by id */
    customContentTypes?: Record<string, CustomContentType>;
    /** True if the current user is super admin */
    isSuperAdmin: boolean;
    /** whether the current user has access to content types or not */
    hasAccess: boolean;
    /** True if the user is allowed to see the custom content types */
    isAllowed: boolean;
    /** Key/value object where the key is the id of the custom content type and the value is true/false */
    allowedCustomContentTypes: Record<string, boolean>;
    /** callback for fetching ccts */
    fetch: () => void;
}

/**
 * Retrieves the list of custom content types, either from the server or from the redux store. If it fetches
 * the data from the server, it will update the redux store.
 *
 * @param UseCustomContentTypesOptions
 * @returns UseCustomContentTypes
 */
export const useCustomContentTypes = ({
    onFinishedLoading,
    invalidateCache,
    shouldFetch = true,
    ids,
}: UseCustomContentTypesOptions): UseCustomContentTypes => {
    const parentCustomContentTypes = useSelector(getParentCustomContentTypes);
    const isLoading = useSelector(isCustomContentTypesLoading);
    const isIdle = useSelector(isCustomContentTypesIdle);
    const isSuperAdmin = useSelector(isInstanceSuperAdmin);
    const isAllowed = useSelector(doesCurrentUserHaveAccessToCustomContentTypes);
    const allowedCustomContentTypes = useSelector(getPermissionsForCustomContentTypes);
    const customContentTypes = useSelector(
        ids ? (state: BaseStore) => getCustomContentTypeByIDs(state, ids) : getCustomContentTypes,
    );
    const dispatch = useDispatch();

    /** If the user has access to at least one content type in CREATE or EDIT. Based on its role or global admin status */
    const hasAccess = isAllowed || isSuperAdmin;

    const hasIds = !isEmpty(ids);

    const fetch = React.useCallback(() => {
        if (ids) {
            dispatch(getOrFetchCustomContentTypesByIds({ uids: ids }));
        } else {
            dispatch(fetchCustomContentTypes(invalidateCache));
        }
    }, [dispatch, ids, invalidateCache]);

    React.useEffect(() => {
        const hasParentCustomContentTypes =
            parentCustomContentTypes && Object.keys(parentCustomContentTypes).length !== 0;

        /**
         * Pre-fetch the content types on init if
         * - `shouldFetch` is at true, meaning that we want the pre-fetch (set to false in order to fetch later on action with the `fetch` method returned)
         * - `hasAccess` is true, meaning that the user has access to at least one content type, with its role. Or `hasIds` meaning that we want to fetch specific content types no matter the roles.
         * -  We are not currently loading data and `parentCustomContentTypes` is not already loaded.
         * */
        if (!isLoading && !parentCustomContentTypes && (hasAccess || hasIds) && shouldFetch) {
            fetch();
        }

        if ((!isLoading && hasParentCustomContentTypes) || !hasAccess) {
            if (onFinishedLoading) {
                onFinishedLoading();
            }
        }
    }, [
        isLoading,
        parentCustomContentTypes,
        hasAccess,
        onFinishedLoading,
        invalidateCache,
        dispatch,
        shouldFetch,
        ids,
        fetch,
        hasIds,
    ]);

    return {
        parentCustomContentTypes,
        isLoading,
        isIdle,
        isSuperAdmin,
        isAllowed,
        hasAccess,
        allowedCustomContentTypes,
        customContentTypes,
        fetch,
    };
};
