import React from 'react';

import { mdiInformation } from '@lumapps/lumx/icons';
import { ColorPalette, FlexBox, Icon } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_CONTRIBUTION } from '../../keys';

import './index.scss';

const CLASSNAME = 'widget-contribution-settings';

export const WidgetContributionSettings: React.FC = () => {
    const { translateKey } = useTranslate();
    return (
        <FlexBox className={`${CLASSNAME}__wrapper`} hAlign="center" vAlign="center">
            <Icon icon={mdiInformation} color={ColorPalette.dark} className={`${CLASSNAME}__icon`} />
            <p className="lumx-typography-body1">{translateKey(WIDGET_CONTRIBUTION.SETTINGS_DOUBLE_CLICK_INFO)}</p>
        </FlexBox>
    );
};
