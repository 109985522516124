import { gTranslate } from '@lumapps/auto-translation';
// import { GetFrontOfficeState } from '@lumapps/redux/types';
// import { getInstanceColorPalette } from '@lumapps/style/ducks/selectors';
// import { fromDITA } from '@lumapps/wrex/serialization/dita/fromDITA/fromDITA';

import { WidgetState } from '../ducks/type';
import { isBlockHtml, isBlockIntro, isBlockStructuredContent } from './blockPredicates';

export const getWidgetTranslatedData = async (
    widget: Partial<WidgetState>,
    widgetType: string,
    targetLang: string,
    // getState: GetFrontOfficeState,
) => {
    const { header, body } = widget;

    // const state = getState();
    // const colorPalette = getInstanceColorPalette(state);

    return Promise.all([
        header && header.label ? gTranslate({ content: header.label, targetLang }) : null,
        body && isBlockIntro(body, widgetType) && body.text ? gTranslate({ content: body.text, targetLang }) : null,
        body && isBlockHtml(body, widgetType) && body.html
            ? gTranslate({ content: body.html, targetLang, isContentHTML: true })
            : null,
        body && isBlockStructuredContent(body, widgetType) && body.ditaHtmlValues
            ? gTranslate({
                  content: body.ditaHtmlValues,
                  targetLang,
                  isContentHTML: true,
              })
            : null,
        // Disabled until slate support
        /*
        import('@lumapps/wrex/serialization/slate/utils/htmlValuesToSlate'),
        body && isBlockStructuredContent(body, widgetType) && body.slateHtml
            ? gTranslate({
                  content: body.slateHtml,
                  targetLang,
                  isContentHTML: true,
              })
            : null,
        */
    ]).then(([label, text, html, structuredContentHtml /* , htmlValuesToSlate, slateHtml */]) => {
        const widgetTranslatedData: Partial<WidgetState> = {};

        if (label) {
            widgetTranslatedData.header = { label };
        }

        if (text) {
            widgetTranslatedData.body = { text } as any;
        }

        if (html) {
            widgetTranslatedData.body = { html } as any;
        }

        if (body && isBlockStructuredContent(body, widgetType)) {
            // Disabled until slate support
            /*
            if (slateHtml) {
                // TODO: Just use `const slate = blockProperties?.slate;` after tests
                const slate = body.blockProperties?.slate || fromDITA(body.dita).content;
                const translatedSlate = htmlValuesToSlate.htmlValuesToSlate(slate, slateHtml, {
                    colorPalette,
                });

                widgetTranslatedData.body = {
                    translatedSlate,
                } as any;
            } else if (structuredContentHtml) {
                widgetTranslatedData.body = {
                    ditaHtmlValues: structuredContentHtml,
                } as any;
            }
            */
            widgetTranslatedData.body = {
                ditaHtmlValues: structuredContentHtml,
            } as any;
        }

        return widgetTranslatedData;
    });
};
