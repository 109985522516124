import React from 'react';

import { typography as typo, color, useClassnames } from '@lumapps/classnames';
import {
    ColorPalette,
    ColorVariant,
    Divider,
    FlexBox,
    FlexBoxProps,
    Text,
    TextProps,
    Theme,
    Typography,
} from '@lumapps/lumx/react';

import './index.scss';

export interface FormFieldsetProps {
    /** Classname to set on the fieldset */
    className?: string;
    /** legend of the fieldset */
    legend: string;
    /** additional props for the form HTML tag */
    fieldsetProps?: React.FormHTMLAttributes<HTMLFieldSetElement> & Partial<FlexBoxProps>;
    /** additional props for the form HTML tag */
    legendProps?: React.FormHTMLAttributes<HTMLLegendElement>;
    /** legend typography */
    typography?: Typography;
    /** description */
    description?: string;
    /** additional props for the description */
    descriptionProps?: Partial<TextProps>;
    /** adds margin after the legend or description */
    withAfterMargin?: boolean;
    /** whether a divider is displayed or not  */
    withDivider?: boolean;
    /** theme */
    theme?: Theme;
}

const CLASSNAME = 'lumx-forms-fieldset';

/**
 * Fieldset to be used for the Form component
 * @family Forms
 * @param FormFieldsetProps
 * @returns FormFieldset
 */
export const FormFieldset: React.FC<FormFieldsetProps> = ({
    className,
    legend,
    fieldsetProps = {},
    legendProps,
    children,
    typography = 'subtitle2',
    withAfterMargin = true,
    description,
    descriptionProps,
    withDivider = false,
    theme = Theme.light,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { gap = 'huge' } = fieldsetProps;
    const fontColor = theme === Theme.dark ? ColorPalette.light : ColorPalette.dark;
    const variantColor = theme === Theme.dark ? ColorVariant.D2 : ColorVariant.L2;

    return (
        <FlexBox orientation="vertical" as="fieldset" className={block([className])} gap={gap} {...fieldsetProps}>
            <legend
                {...legendProps}
                className={element('legend', { 'with-margin': withAfterMargin && !description }, [
                    typo(typography),
                    color('font', fontColor, 'N'),
                    legendProps?.className,
                ])}
            >
                {legend}
            </legend>

            {description && (
                <Text
                    className={element('description', { 'with-margin': withAfterMargin })}
                    as="p"
                    typography={Typography.caption}
                    color={fontColor}
                    colorVariant={variantColor}
                    {...descriptionProps}
                >
                    {description}
                </Text>
            )}

            {children}

            {withDivider ? <Divider /> : null}
        </FlexBox>
    );
};
