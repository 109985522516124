import { get as getCommunity } from '@lumapps/communities/api';
import { actions as foActions } from '@lumapps/communities/ducks/frontOfficeSlice';
import { currentLanguageSelector } from '@lumapps/languages';
import { error } from '@lumapps/notifications';
import { Dispatch } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';

import { getById } from '../../api';
import { POST_EDIT_FIELDS } from '../../constants';
import { actions as postActions } from '../slice';

export const editPost =
    ({ postId, openPostDialog }: { postId: string; openPostDialog: () => void }) =>
    async (dispatch: Dispatch, getState: () => any) => {
        const state = getState();
        const currentLang = currentLanguageSelector(state);

        try {
            const { data: post } = await getById(postId, POST_EDIT_FIELDS);

            /**
             * Get key informations from the Community that contain the post to edit.
             * */
            const { data: community } = await getCommunity({
                uid: post.externalKey,
                fields: 'tagsDetails,editors',
            });

            /**
             * Set the community that is target for the post creation in store.
             * */
            dispatch(foActions.setSelectedCommunity({ ...community }));
            dispatch(
                postActions.setEditedPost({
                    ...post,
                    content: fromMarkdown(post.content[currentLang] || Object.values(post.content)[0], {
                        mentionsDetails: post.mentionsDetails,
                    }),
                    availableCategories: community.tagsDetails,
                    title: post.title ? post.title[currentLang] || Object.values(post.title)[0] : '',
                }),
            );
            openPostDialog();
        } catch (err) {
            dispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
        }
    };
