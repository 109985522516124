/* istanbul ignore file */
import BaseApi, { ServerListResponse } from '@lumapps/base-api';

import { LegacyCalendarEvent, ListEventParams } from '../types';

export const calendarApi = new BaseApi({
    path: 'calendar',
});

export const listEvents = (params: ListEventParams) =>
    calendarApi.get<ServerListResponse<LegacyCalendarEvent>>('/event/list', { params });

export const fetchCalendarEvents = async (params: ListEventParams) => {
    const events = await listEvents(params);

    return events.data;
};
