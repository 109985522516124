import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Kind, Message, Text, UploaderProps } from '@lumapps/lumx/react';
import { usePlayUser } from '@lumapps/play-roles';
import { DroppableFileZone } from '@lumapps/play/components/DroppableFileZone';
import { PLAY } from '@lumapps/play/keys';
import { useTranslate } from '@lumapps/translations';

import { UPLOADER_ACCEPT_VIDEO_EXTENSIONS } from '../../constants';
import { useUploadVideo } from '../../hooks/useUploadVideo';
import { PLAY_LIBRARY } from '../../keys';

import './index.scss';

export interface VideoUploaderProps extends UploaderProps {
    /** when we have selected something we use this function to make actions possible by returning the selected files */
    onUpload: (selectedFile: File) => void;
    /** Translation key for an error in create video API call */
    createVideoError?: string;
}

const CLASSNAME = 'video-uploader';

/**
 *
 * @param VideoUploaderProps
 * @returns VideoUploader
 */
export const VideoUploader: React.FC<VideoUploaderProps> = ({ onUpload, createVideoError, ...props }) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateAndReplace, translateKey } = useTranslate();
    const { maxUploadSize } = usePlayUser();
    const { hiddenInput, onFileUpload, openPicker } = useUploadVideo({ onUpload });

    return (
        <DroppableFileZone
            className={block()}
            onUpload={onFileUpload}
            droppableZoneText={translateKey(PLAY.UPLOAD_TO_LIBRARY)}
        >
            <DroppableFileZone.Uploader
                label={translateKey(PLAY_LIBRARY.UPLOAD_DIALOG_CTA)}
                onClick={openPicker}
                {...props}
            >
                {hiddenInput}
            </DroppableFileZone.Uploader>

            <DroppableFileZone.Subtitle>{translateKey(PLAY_LIBRARY.UPLOAD_DIALOG_SUBTITLE)}</DroppableFileZone.Subtitle>

            <DroppableFileZone.Description>
                {translateKey(PLAY_LIBRARY.UPLOAD_DIALOG_DESCRIPTION)}
            </DroppableFileZone.Description>

            {createVideoError ? (
                <Message kind={Kind.error} hasBackground className={element('create-video-warning')}>
                    <Text as="p">{translateKey(createVideoError)}</Text>
                </Message>
            ) : (
                <DroppableFileZone.Formats
                    extensions={translateAndReplace(PLAY.SUPPORTED_VIDEO_FORMATS, {
                        formats: UPLOADER_ACCEPT_VIDEO_EXTENSIONS.join(', '),
                    })}
                    maxSize={translateAndReplace(PLAY.MAX_FILE_SIZE, { size: maxUploadSize })}
                />
            )}
        </DroppableFileZone>
    );
};
