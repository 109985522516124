import React from 'react';

import range from 'lodash/range';

import { ListItem, SkeletonRectangle, SkeletonTypography, GenericBlock } from '@lumapps/lumx/react';

export interface SelectedEntitySkeletonsProps {
    number?: number;
    hasSubtitle?: boolean;
}

export const SelectedEntitySkeletons: React.FC<SelectedEntitySkeletonsProps> = ({ number = 2, hasSubtitle }) => {
    return (
        <>
            {range(number).map((i) => (
                <ListItem
                    key={i}
                    className="lumx-color-background-dark-L6 lumx-spacing-margin-bottom-regular lumx-spacing-padding-horizontal-regular"
                >
                    <GenericBlock>
                        <GenericBlock.Figure>
                            <SkeletonRectangle theme="light" width="m" height="m" variant="rounded" />
                        </GenericBlock.Figure>
                        <GenericBlock.Content>
                            <SkeletonTypography theme="light" typography="subtitle1" width="127px" />
                            {hasSubtitle && <SkeletonTypography theme="light" typography="body1" width="71px" />}
                        </GenericBlock.Content>
                    </GenericBlock>
                </ListItem>
            ))}
        </>
    );
};
