import React from 'react';

import { classnames, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiClose, mdiHelpCircleOutline } from '@lumapps/lumx/icons';
import {
    Alignment,
    ColorPalette,
    Emphasis,
    FlexBox,
    IconButton,
    IconButtonProps,
    Orientation,
    Placement,
    PopoverDialog,
    PopoverDialogProps,
    Size,
    Text,
    Theme,
    TooltipPlacement,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDropdown } from '@lumapps/utils/hooks/useDropdown';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import './index.scss';

const BASE_CLASSNAME = 'lumx-toggle-tip-button';
export interface ToggleTipButtonProps {
    /** Classname to pass to the component */
    className?: string;
    /** Content displayed inside the popover */
    content: string | React.ReactNode;
    /** Icon to display */
    icon?: string;
    /**
     * Color of the icon button
     * @default 'dark'
     */
    iconColor?: ColorPalette | undefined;
    /** Props to be passed down to the icon button */
    iconButtonProps?: IconButtonProps;
    /** Label that will be displayed inside tooltip */
    label?: string;
    /** Props to be passed down to the popover */
    popoverDialogProps?: Partial<Omit<PopoverDialogProps, 'aria-labelledby'>>;
    /**
     * Placement of the popover relative to the anchor.
     * @default 'bottom'
     */
    popoverPlacement?: Placement;
    /**
     * Placement of the tooltip relative to the anchor.
     * @default 'top'
     */
    tooltipPlacement?: TooltipPlacement;
    /** Scope use to setup the data-id correctly */
    scope?: string;
    /**
     * Defines the trigger method of the toggle tip.
     * @default 'click'
     */
    mode?: 'click' | 'hover';
    /**
     * Theme that will be applied to the popover, text content and close icon. Either Theme.dark or Theme.light
     * @default Theme.dark
     */
    theme?: Theme;
}

/**
 * Component that displays an Icon Button that, upon clicking on the button, it triggers a popover dialog
 * in order to display additional data.
 *
 * @family Buttons
 * @param ToggleTipButtonProps
 * @returns ToggleTipButton
 */
export const ToggleTipButton: React.FC<ToggleTipButtonProps> = ({
    className,
    content,
    icon = mdiHelpCircleOutline,
    iconColor = ColorPalette.dark,
    iconButtonProps,
    label,
    popoverDialogProps,
    popoverPlacement = Placement.BOTTOM,
    tooltipPlacement = Placement.TOP,
    scope = 'toogle-tip',
    mode = 'click',
    theme = Theme.dark,
}) => {
    const { block, element } = useClassnames(BASE_CLASSNAME);
    const { get } = useDataAttributes(scope);
    const { isOpen, setIsOpen, toggle } = useDropdown(false);
    const { translateKey } = useTranslate();
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const ariaLabelledById = `${BASE_CLASSNAME}-${generateUUID()}`;

    const handleOnClick = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        toggle();
    };

    const onMouseEnter = () => {
        setIsOpen(true);
    };
    const onMouseLeave = () => {
        setIsOpen(false);
    };

    return (
        <>
            <IconButton
                className={block([className])}
                tooltipProps={{ placement: tooltipPlacement }}
                onMouseEnter={mode === 'hover' ? onMouseEnter : null}
                onMouseLeave={mode === 'hover' ? onMouseLeave : null}
                {...iconButtonProps}
                color={iconColor}
                emphasis={Emphasis.low}
                icon={icon}
                id={ariaLabelledById}
                label={mode === 'click' ? label || translateKey(GLOBAL.MORE_INFO) : ''}
                onClick={handleOnClick}
                ref={anchorRef}
                {...get({ element: 'button', action: 'open-toggle-tip' })}
            />

            <PopoverDialog
                className={element('popover')}
                {...popoverDialogProps}
                anchorRef={anchorRef}
                aria-labelledby={ariaLabelledById}
                fitWithinViewportHeight
                focusAnchorOnClose
                hasArrow={mode === 'click'}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                placement={popoverPlacement}
                theme={theme}
            >
                <FlexBox
                    className={classnames(padding('all', Size.big))}
                    orientation={Orientation.horizontal}
                    gap={Size.regular}
                    hAlign={Alignment.top}
                    vAlign="space-between"
                >
                    {typeof content === 'string' ? (
                        <Text
                            as="p"
                            color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                            typography={Typography.body1}
                        >
                            {translateKey(content)}
                        </Text>
                    ) : (
                        content
                    )}
                    {mode === 'click' && (
                        <IconButton
                            className={element('close')}
                            color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                            emphasis={Emphasis.low}
                            icon={mdiClose}
                            label={translateKey(GLOBAL.CLOSE)}
                            onClick={handleOnClick}
                            size={Size.m}
                            {...get({ element: 'button', action: 'close-toggle-tip' })}
                        />
                    )}
                </FlexBox>
            </PopoverDialog>
        </>
    );
};
