import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const instanceSettingsRoutes: Route = {
    path: '/admin/instance-settings',
    legacyId: 'app.admin.instance-settings',
    appId: AppId.backOffice,
};

export const instanceAdvancedSettingsRoutes: Route = {
    path: '/admin/instance-advanced-settings',
    legacyId: 'app.admin.instance-advanced-settings',
    appId: AppId.backOffice,
};

/**
 * Retrieves the route for the instance settings admin.
 */
export const adminSettings = (): Route => instanceSettingsRoutes;
export const adminAdvancedSettings = (): Route => instanceAdvancedSettingsRoutes;
