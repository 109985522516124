import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiDns } from '@lumapps/lumx/icons';
import { useMetadataAdminRights } from '@lumapps/metadata/hooks/useMetadataAdminRights';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { metadataAdminPageRoute, metadataRoutesMatch } from '../../routes';

export const MetadataSideNavigation = () => {
    const { isAllowed } = useMetadataAdminRights();

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={metadataAdminPageRoute}
            label={translateKey(GLOBAL.METADATA)}
            icon={mdiDns}
            pathMatchForHighlighting={metadataRoutesMatch}
        />
    );
};

MetadataSideNavigation.displayName = 'SideNavigationItem';
