import React, { ReactElement, useMemo } from 'react';

import { ComponentTypes } from 'lumapps-sdk-js';

import { classnames } from '@lumapps/classnames';
import { connectorLoginRedirect } from '@lumapps/connectors/utils/connectorLoginRedirect';
import { useContent } from '@lumapps/contents/hooks/useContent';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Text, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';
import { useCustomEventListener } from '@lumapps/utils/hooks/useCustomEventListener';

import { ExtensionStatus } from '../../constants';
import { MARKETPLACE } from '../../keys';
import { LumdrianContextProvider } from '../LumdrianContextProvider';
import { ExtensionComponentProps } from './types';
import { UnavailableExtension } from './UnavailableExtension';

import './index.scss';

const CLASSNAME = 'extension-component';

export function ExtensionComponent({
    className,
    component,
    error,
    extensionId,
    globalProperties,
    isComponentLoading,
    legacyWidgetId,
    name,
    properties,
    status,
    theme = Theme.light,
    type,
    uuid,
    widgetRef = React.createRef(),
    exportProp,
    loadComponent,
}: ExtensionComponentProps<unknown>): JSX.Element {
    const extensionClassname = classnames(CLASSNAME, className);
    const { get: getDataAttributes } = useDataAttributes('marketplace');
    const dataAttributes = getDataAttributes({ element: `extension-${type}-${extensionId}` });
    const { translateKey } = useTranslate();
    const { currentContentId: contentId, isDesignerMode } = useContent();

    useCustomEventListener<boolean>(widgetRef, 'lumapps-remote-extension-display', (display) => {
        if (!widgetRef.current) {
            return;
        }

        const { style } = widgetRef.current;
        style.display = display ? 'block' : 'none';
    });

    React.useEffect(() => {
        if (!isComponentLoading && !component && !error) {
            loadComponent();
        }
    }, [component, error, isComponentLoading, loadComponent]);

    const WidgetElement = useMemo<ReactElement | null>(() => {
        if (isComponentLoading || !component) {
            return null;
        }

        const widget = component[Object.keys(component)[0]] as React.FC<any>;

        const props = {
            contentId,
            exportProp,
            extensionId,
            globalProperties,
            // Note: globalValue is deprecated, but added for compatibility (see sdk).
            globalValue: globalProperties,
            properties,
            theme,
            uuid,
            // Note: value is deprecated, but added for compatibility (see sdk).
            value: properties,
            wrapperRef: widgetRef,
        };
        return React.createElement(widget, { ...props, key: uuid });
    }, [
        component,
        contentId,
        exportProp,
        extensionId,
        globalProperties,
        isComponentLoading,
        properties,
        theme,
        uuid,
        widgetRef,
    ]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {status === ExtensionStatus.unavailable && isDesignerMode ? (
                <UnavailableExtension name={name} type={type as ComponentTypes} />
            ) : (
                <div className={extensionClassname} {...dataAttributes}>
                    <ErrorBoundary
                        fallback={
                            <Text as="p" className="lumx-base-text-center">
                                {translateKey(MARKETPLACE.EXTENSION_COULD_NOT_LOAD)}
                            </Text>
                        }
                    >
                        <LumdrianContextProvider
                            componentType={type}
                            connectorId={properties?.builtInProperties?.connectorId}
                            contentId={contentId}
                            exportProp={exportProp}
                            extensionId={extensionId}
                            globalProperties={globalProperties}
                            legacyWidgetId={legacyWidgetId}
                            properties={properties}
                            uuid={uuid}
                            widgetRef={widgetRef}
                            connectorLoginRedirect={connectorLoginRedirect}
                        >
                            {WidgetElement}
                        </LumdrianContextProvider>
                    </ErrorBoundary>
                </div>
            )}
        </>
    );
}
