/* eslint-disable import/no-unassigned-import */
/* eslint-disable no-inline-comments */
import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';

import { Size, Emphasis, IconButton } from '@lumapps/lumx/react';
import { /* MdiAttachment, */ mdiEmoticon, mdiSend } from '@lumapps/lumx/icons';
import { EmojiPickerDialog } from '@lumapps/pickers/components/EmojiPickerDialog';
import { LegacyFroalaEditor } from '@lumapps/wrex-legacy-froala/components/LegacyFroalaEditor';

import { translate as t } from 'components/translations';

import { FroalaSettings } from 'common/froala/modules/froala_constant';
import { CHAT_PROVIDER_VALUES } from '../chat_constants';

/**
 * Displays the widget chat header component
 * @return {Element} The chat header react element.
 */
const ChatFooter = ({
    fetchFroala,
    froala,
    isEmojiEditorOpened,
    model,
    provider,
    setEmojiEditorOpened,
    sendMessage,
    setModel,
    uuid,
}) => {
    useEffect(() => {
        if (!froala.isLoaded && !froala.isLoading) {
            fetchFroala();
        }
    }, [fetchFroala, froala]);
    const toolBarConfig = ['bold', 'italic', 'formatUL'];
    const emojiButtonRef = React.useRef();
    /**
     * Froala configuration.
     */
    const config = {
        attribution: false,
        autofocus: true,
        charCounterCount: false,
        editorClass: 'widget-chat__froala-editor',
        embedlyKey: FroalaSettings.key,
        height: 50,
        htmlExecuteScripts: false,
        multiLine: true,
        placeholderText: t('FRONT.CHAT.MESSAGE_PLACEHOLDER'),
        toolbarButtons: toolBarConfig,
        toolbarButtonsMD: toolBarConfig,
        toolbarButtonsSM: toolBarConfig,
        toolbarButtonsXS: toolBarConfig,
        toolbarInline: true,
    };

    /**
     * Text received from editor input.
     *
     * @param {string} data Input data.
     */
    const handleModelChange = (data) => {
        setModel({ model: data, uuid });
    };

    /**
     * Get emoji code and add it to text editor.
     *
     * @param {string} code Emoji code.
     */
    const getEmoji = (emojiObject) => {
        const div = document.createElement('div');
        const code = emojiObject.unicode || emojiObject.emoji.unicode;
        div.innerHTML = code;
        const emojiHtml = div.innerText;
        let newModel;
        if (model.slice(-4) === '</p>') {
            newModel = `${model.slice(0, -4)} ${emojiHtml}</p>`;
        } else {
            newModel = `${model} ${emojiHtml}`;
        }
        handleModelChange(newModel);
        setEmojiEditorOpened({ isEmojiEditorOpened: false, uuid });
    };

    /**
     * Toggle Emoji editor.
     */
    const toggleEmojiEditor = () => {
        setEmojiEditorOpened({ isEmojiEditorOpened: !isEmojiEditorOpened, uuid });
    };

    return (
        <div className="widget-chat__footer widget-chat-footer">
            <div id="toolbar-container" />
            {provider === CHAT_PROVIDER_VALUES.YAMMER.id && (
                <div className="widget-chat-footer__textarea-container">
                    <textarea
                        className="widget-chat-footer__textarea"
                        placeholder={t('FRONT.CHAT.MESSAGE_PLACEHOLDER')}
                        value={model}
                        onChange={(changeEvent) => handleModelChange(changeEvent.currentTarget.value)}
                    />
                </div>
            )}
            {provider !== CHAT_PROVIDER_VALUES.YAMMER.id && froala.isLoaded && (
                <LegacyFroalaEditor config={config} model={model} onModelChange={handleModelChange} />
            )}

            <div className="widget-chat-footer__actions">
                <div>
                    <EmojiPickerDialog isOpen={isEmojiEditorOpened} anchorRef={emojiButtonRef} onEmojiClick={getEmoji} />
                    <IconButton
                        emphasis={Emphasis.low}
                        icon={mdiEmoticon}
                        size={Size.m}
                        onClick={() => toggleEmojiEditor()}
                        ref={emojiButtonRef}
                    />
                </div>

                <div>
                    <IconButton
                        emphasis={Emphasis.low}
                        icon={mdiSend}
                        size={Size.m}
                        onClick={() => sendMessage()}
                        label=""
                    />
                </div>
            </div>
        </div>
    );
};

ChatFooter.propTypes = {
    /** Load froala. */
    fetchFroala: func.isRequired,
    /** Froala config. */
    froala: shape().isRequired,
    /** Whether emoji editor is opened. */
    isEmojiEditorOpened: bool.isRequired,
    /** Editor text input. */
    model: string.isRequired,
    /* * Provider input. */
    provider: string.isRequired,
    /** Post message function. */
    sendMessage: func.isRequired,
    /** Emoji editor opened setter. */
    setEmojiEditorOpened: func.isRequired,
    /** Model setter. */
    setModel: func.isRequired,
    /** Component uuid. */
    uuid: string.isRequired,
};

export { ChatFooter };
