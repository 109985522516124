import React, { useMemo } from 'react';

import { margin } from '@lumapps/classnames';
import { isInDesignerMode } from '@lumapps/contents/ducks/selectors';
import { FlexBox, ProgressCircular } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';

import { EmptyStateRendererProps, ErrorStateRendererProps, LegacyWidget, LegacyWidgetProperties } from '../../types';
import { getBlockMeta } from '../../utils/getBlockMeta';
import { renderBlockTree } from '../Block/renderBlockTree';
import { EmptyWidgetPlaceholder } from '../EmptyWidgetPlaceholder';
import { computeWidgetStyle } from '../Widget/styles';
import { WidgetContent, WidgetContentProps } from '../WidgetContent';
import { useLegacyWidget } from './useLegacyWidget';

export interface BackendRecomputedWidgetProps<
    WidgetSpecificProperties = object,
    BlockProperties = object,
    EditableWidgetProperties = object,
> extends Partial<WidgetContentProps<BlockProperties>> {
    /** The Legacy widget to be rendered. */
    legacyWidget: LegacyWidget<WidgetSpecificProperties>;
    /** The component that will be used if the widget is loading. If left undefined, it will render a centered <Progress />. */
    loadingStateRenderer?: React.FC;
    /** The component that will be used if the widget is empty. If left undefined, it will render what the /blocks API returns (ie BlockNoResults). */
    emptyStateRenderer?: React.FC<EmptyStateRendererProps>;
    errorStateRenderer?: React.FC<ErrorStateRendererProps>;
    editableWidgetRenderer?: React.FC<EditableWidgetProperties>;
    editableWidgetProps?: EditableWidgetProperties;
    editingContent?: boolean;
    /** Wether the widget is empty or not (legacy) */
    isWidgetEmpty?: () => boolean;
    canUseLangFallback?: boolean;
    /**
     * Callback called when the /blocks returns an error. (status error after some retries, or not migrated status)
     * */
    onError?: () => void;
}

const DefaultLoader = () => {
    return (
        <FlexBox vAlign="center" className={margin('vertical', 'big')}>
            <ProgressCircular />
        </FlexBox>
    );
};

/**
 * A component rendering a WidgetContent given legacy widget properties.
 * It will call the /blocks API to get the block version of the widget.
 * @param BackendRecomputedWidgetProps
 * @returns BackendRecomputedWidget
 */
export const BackendRecomputedWidget = <
    WidgetSpecificProperties,
    BlockProperties = object,
    EditableWidgetProperties = object,
>({
    legacyWidget: initLegacyWidget,
    loadingStateRenderer: LoadingStateRenderer,
    loadingMoreStateRenderer: LoadingMoreStateRenderer,
    emptyStateRenderer: EmptyStateRenderer,
    editableWidgetRenderer: EditableWidgetRenderer,
    errorStateRenderer: ErrorStateRenderer,
    editableWidgetProps,
    editingContent,
    isWidgetEmpty,
    blockProperties,
    canUseLangFallback,
    onError,
    ...props
}: BackendRecomputedWidgetProps<
    WidgetSpecificProperties & LegacyWidgetProperties,
    BlockProperties,
    EditableWidgetProperties
>) => {
    // isGlobal should always be false, as the backend already handle the value correctly on widget save
    const legacyWidget = React.useMemo(() => {
        return { ...initLegacyWidget, isGlobal: false };
    }, [initLegacyWidget]);
    const isInDesigner = Boolean(useSelector(isInDesignerMode));

    const {
        widget: fetchedWidget,
        fetchMore,
        refetch,
    } = useLegacyWidget({ legacyWidget, isWidgetEmpty, editingContent, canUseLangFallback, onError });

    const widget = {
        ...fetchedWidget,
        widgetId: legacyWidget.legacyWidgetId || legacyWidget.uuid,
        legacyWidget,
    };

    const children = widget?.widgetType && renderBlockTree(widget);
    const block = getBlockMeta(children);

    const { widgetContentStyle } = useMemo(
        () => computeWidgetStyle(fetchedWidget.style, fetchedWidget.body?.style, false),
        [fetchedWidget.body, fetchedWidget.style],
    );

    const theme = fetchedWidget.body?.style?.theme;

    if ((!widget.state || widget.state === 'loading') && !editingContent) {
        return LoadingStateRenderer ? <LoadingStateRenderer /> : <DefaultLoader />;
    }

    const hasFilters = widget.filters;

    if (widget.state === 'empty') {
        if (!hasFilters && !editingContent) {
            if (EmptyStateRenderer) {
                return <EmptyStateRenderer widget={widget} widgetContentStyle={widgetContentStyle} theme={theme} />;
            }
            if (isInDesigner) {
                // In designer, we should always display a placeholder.
                return <EmptyWidgetPlaceholder widget={widget} widgetContentStyle={widgetContentStyle} theme={theme} />;
            }
        }
    }

    if (widget.state === 'error' && !hasFilters && ErrorStateRenderer && !editingContent) {
        return <ErrorStateRenderer widget={widget} widgetContentStyle={widgetContentStyle} refetchWidget={refetch} />;
    }

    return (
        <WidgetContent
            style={widgetContentStyle}
            onLoadMore={fetchMore}
            blockVariant={block?.variant}
            blockType={block?.type}
            widgetId={legacyWidget.uuid}
            loadingState={widget.state}
            isCollapsed={false}
            id={legacyWidget.uuid}
            loadingMoreStateRenderer={LoadingMoreStateRenderer}
            blockProperties={blockProperties}
            {...props}
        >
            {editingContent && EditableWidgetRenderer && editableWidgetProps ? (
                <EditableWidgetRenderer {...editableWidgetProps} theme={theme} />
            ) : (
                children
            )}
        </WidgetContent>
    );
};
