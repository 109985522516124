import { mdiSparkles, mdiExclamationThick } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import { ASK_AI } from './keys';

/** Token for the AskAI feature flag */
export const ASK_AI_FF = 'ask-ai';

/** Token for the AskAI alpha feature flag */
export const ASK_AI_ALPHA_FF = 'ask-ai-alpha';

export const ASK_AI_SCOPE = 'ask-ai';

/** AskAI reaction */
export const THUMB_DOWN_REACTION = 'dislike';

/** All info required for AskAi empty states */
export const EMPTY_STATES = {
    noResults: { icon: mdiSparkles, description: GLOBAL.TRY_ANOTHER_QUERY, subtitle: ASK_AI.NO_RESOURCES_TITLE },
    error: {
        icon: mdiExclamationThick,
        description: ASK_AI.ERROR_DESC,
        subtitle: ASK_AI.ERROR_TITLE,
    },
};

/**
 * The max height the card should have before showing a "show more" button,
 * excluding the height of the "show more" button.
 */
export const CARD_MAX_HEIGHT = 233;

/** Root path of the ask ai backoffice section  */
export const ASK_AI_ADMIN_ROOT_PATH = '/admin/ai-search';
