import { error as notifyError } from '@lumapps/notifications';
import { batch } from '@lumapps/redux/react';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

import { follow, unfollow } from '../../api/contents';
import { contentSelector } from '../selectors';
import { actions } from '../slice';

/**
 * Follow or unfollow a content.
 */
export const onFollow = () => async (dispatch: Dispatch, getState: GetBaseState) => {
    dispatch(actions.setFollowState('loading'));
    try {
        const { id, subscription } = contentSelector(getState());
        let response;
        if (subscription) {
            response = await unfollow(id);
            dispatch(actions.follow(undefined));
        } else {
            response = await follow(id, { notify: false });
            dispatch(actions.follow(response.data));
        }
    } catch (e) {
        batch(() => {
            dispatch(actions.setFollowState('error'));
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        });
    }
};
