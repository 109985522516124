import debounce from 'lodash/debounce';
import loFind from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import set from 'lodash/set';

import { FOLDER_TYPES } from './widget-file-list_constant';

/////////////////////////////

function WidgetFileListController(
    $injector,
    $q,
    $scope,
    $timeout,
    $window,
    Config,
    Content,
    Document,
    Folder,
    InitialSettings,
    Instance,
    MediaConstant,
    Tag,
    Translation,
    Utils,
    Widget,
    WidgetFileListConstant,
    WidgetSettingsConstant,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * List of document providers id.
     *
     * @type {Array} List of document providers id.
     */
    const _documentProviderIds = [];

    /**
     * Name document provider service to use.
     * Default is 'DocumentProvider', but may be override for eg. 'CommunityDocumentProvider'.
     *
     * @type {string}
     */
    const _documentProviderService = 'DocumentProvider';

    /**
     * Used document provider service.
     */
    let _documentProvider;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The sort dropdown id.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DROPDOWN_ID = 'header-sort-dropdown';

    /**
     * A list of folders that make up the breadcrumb.
     *
     * @type {Array}
     */
    vm.breadcrumbList = [];

    /**
     * A map of fields and their visibility.
     *
     * @type {Object}
     */
    vm.fieldsToDisplay = {};

    /**
     * Contains information about the status of the controller.
     *
     * @type {Object}
     */
    vm.is = {
        loading: {
            files: false,
            homeFolder: false,
        },
    };

    /**
     * Is current user writer on current folder.
     *
     * @type {boolean}
     */
    vm.isWriterOnCurrentFolder = false;

    /**
     * The value of the search input.
     *
     * @type {string}
     */
    vm.searchQuery = '';

    /**
     * The sort order properties for the file list.
     *
     * @type {Object}
     */
    vm.sortOrderProperties = {
        description: {
            isHidden: true,
            sortName: '',
            translate: 'FRONT.SETTINGS.DESCRIPTION',
        },
        name: {
            isHidden: false,
            sortName: MediaConstant.SORT_ORDER,
            translate: 'FRONT.SETTINGS.NAME',
        },
    };

    /**
     * List of drive authorized actions. (like create folder, upload, etc.).
     *
     * @type {Array}
     */
    vm.providerActions = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Document = Document;
    vm.Folder = Folder;
    vm.Utils = Utils;
    vm.WidgetFileListConstant = WidgetFileListConstant;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Init document provider service.
     */
    function _initDocumentProvider() {
        // Note: wait for the backend to be implemented before uncomment this.
        // If (isCommunityContext) {.
        //    _documentProviderService = 'CommunityDocumentProvider';
        // }.

        _documentProvider = $injector.get(_documentProviderService);
    }

    /**
     * Init list of available document providers.
     *
     * @return {Promise} Init promise.
     */
    async function _initDocumentProviderList() {
        _documentProviderIds.push(...map(await _documentProvider.getList(), 'id'));
    }

    /**
     * Check if folder type match custom one.
     *
     * @param  {string}  folderType Folder type.
     * @return {boolean} Whether or not the folder is custom.
     */
    function _isCustomFolder(folderType) {
        return folderType === FOLDER_TYPES.CUSTOM;
    }

    /**
     * Get current category search parameters.
     *
     * @return {Promise} Current category search parameters.
     */
    function _getCurrentCategorySearchParameters() {
        // eslint-disable-next-line no-use-before-define
        const properties = _getWidgetProperties();
        const { categoryId, providerId } = properties.folder;

        return _documentProvider
            .getProviderCategory(providerId, categoryId)
            .then((category) => (category && category.properties) || {});
    }

    /**
     * Get home folder.
     *
     * @param  {Object}         folder Folder to get.
     * @return {Promise|Object} Home folder object or promise.
     */
    function _getHomeFolder({ docPath, folderType }) {
        if (_isCustomFolder(folderType)) {
            return Document.promiseGet(
                {
                    docPath,
                },
                undefined,
                undefined,
                false,
            );
        }

        return {};
    }

    /**
     * Get home folder name.
     *
     * @param  {Object} folder Folder to get the home name.
     * @return {string} Home folder name.
     */
    async function _getHomeFolderName(folder) {
        if (angular.isUndefinedOrEmpty(folder)) {
            return '';
        }

        if (_isCustomFolder(folder.folderType)) {
            const media = await _getHomeFolder(folder);
            const { name: folderName } = Document.getMediaContentByLang(media, true);

            return folderName;
        }

        return Translation.translate(
            `FRONT.WIDGET_FILE_LIST.SETTINGS.FOLDER.${folder.providerId.toUpperCase()}.${folder.categoryId.toUpperCase()}`,
        );
    }

    /**
     * Get widget properties.
     *
     * @return {Object} Widget properties.
     */
    function _getWidgetProperties() {
        return get(vm.widget, 'properties');
    }

    /**
     * Set home folder name.
     *
     * @param {Object} folder Folder to set home folder name.
     */
    async function _setHomeFolderName(folder) {
        if (get(vm.homeFolder, 'name')) {
            return;
        }

        vm.is.loading.homeFolder = true;

        const folderName = await _getHomeFolderName(folder);

        $scope.$apply(() => {
            vm.homeFolder = {
                name: folderName,
            };

            vm.is.loading.homeFolder = false;
        });
    }

    /* eslint-disable no-use-before-define */
    /**
     * List media files only.
     *
     * @param  {string}  [newDocPath] The docPath of the folder we want to browse to.
     * @return {Promise} Request promise.
     */
    function _listFiles(newDocPath = getCurrentDocumentPath()) {
        /* eslint-enable no-use-before-define */
        vm.is.loading.files = true;
        const properties = _getWidgetProperties();
        const sortOrderParams = reduce(
            vm.sortOrderProperties,
            (sort, property) => {
                if (angular.isDefinedAndFilled(property.sortName)) {
                    sort.push(property.sortName);
                }

                return sort;
            },
            ['-isFolder'],
        );

        const params = {
            lang: Translation.inputLanguage,
            maxResults: properties.maxNumber || WidgetSettingsConstant.MAX_NUMBER_TO_DISPLAY,
            searchInFolder: newDocPath && !newDocPath.endsWith('resource=root'),
            searchText: vm.searchQuery,
            sortOrder: sortOrderParams,
        };

        let promise = $q.reject();

        if (
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST ||
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
        ) {
            if (properties.folder && includes(_documentProviderIds, properties.folder.providerId)) {
                const { categoryId, providerId } = properties.folder;

                params.docPath = newDocPath;
                params.searchTags = properties.folderTags;

                if (angular.isDefinedAndFilled([providerId, categoryId], 'every')) {
                    promise = _getCurrentCategorySearchParameters().then((searchParameters) => {
                        params.searchParameters = searchParameters;
                    });
                } else {
                    promise = $q.resolve();
                }

                promise = promise
                    .then(() => Document.promiseFilterize(params, vm.widgetListCtrl.getListKey()))
                    .then(() => _setHomeFolderName(properties.folder));
            }
        } else {
            promise = Document.getMulti(properties.files).then((response) => {
                Document.initList(
                    vm.widgetListCtrl.getListKey(),
                    {
                        maxResults: properties.maxNumber || WidgetSettingsConstant.MAX_NUMBER_TO_DISPLAY,
                        searchText: vm.searchQuery,
                    },
                    response.items,
                    false,
                    undefined,
                );
            });
        }

        promise
            .then(() => {
                vm.widgetListCtrl.updateListItems();
            })
            .finally(() => {
                $timeout(() => {
                    vm.is.loading.files = false;
                });
            });

        return promise;
    }

    /**
     * Reset the list of media displayed in the widget.
     */
    function _resetFileList() {
        const properties = _getWidgetProperties();

        Document.initList(
            vm.widgetListCtrl.getListKey(),
            {
                maxResults: properties.maxNumber || WidgetSettingsConstant.MAX_NUMBER_TO_DISPLAY,
                searchText: vm.searchQuery,
            },
            [],
            true,
        );
    }

    /**
     * Initialize the visible fields in the list.
     */
    function _initFields() {
        const properties = _getWidgetProperties();
        const defaultFileFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.FILE_FIELDS);
        let firstFieldsInit = false;

        if (angular.isUndefinedOrEmpty(properties.fields)) {
            properties.fields = [];
            firstFieldsInit = true;
        }

        angular.forEach(defaultFileFields, (field) => {
            if (angular.isString(field)) {
                field = {
                    enable: firstFieldsInit,
                    name: field,
                };
            }

            const findInDefaultFields = loFind(properties.fields, {
                name: field.name,
            });

            if (firstFieldsInit || angular.isUndefinedOrEmpty(findInDefaultFields)) {
                properties.fields.push(field);
            }
        });
    }

    /**
     * Reset home folder.
     */
    function _resetHomeFolder() {
        vm.homeFolder = {};
    }

    /**
     * Set classes for the folder block elements in the list.
     *
     * @return {Array} A list of css classes.
     */
    function _setFolderBlockClasses() {
        const properties = _getWidgetProperties();

        vm.folderBlockClasses = [];

        const theme = get(properties, 'style.content.theme');
        vm.folderBlockClasses.push(`folder-block--theme-${theme}`);

        if (properties.itemsSeparator) {
            vm.folderBlockClasses.push('folder-block--has-separator');
        }

        return vm.folderBlockClasses;
    }

    /**
     * Set classes for the media block elements in the list.
     *
     * @return {Array} A list of css classes.
     *
     * Todo [Arnaud]: this is only handling item separator stuff for now as the rest is not retro-compatible yet.
     */
    function _setMediaBlockClasses() {
        const properties = _getWidgetProperties();

        vm.mediaBlockClasses = [];

        if (properties.itemsSeparator) {
            vm.mediaBlockClasses.push('media-block--has-separator');
        }

        return vm.mediaBlockClasses;
    }

    /**
     * Set classes for the block elements in the list. This is so each block doesn't have to compute these
     * themselves.
     *
     * Todo [Arnaud]: unify the classes when we only have one list of elements (rather than folders and files).
     */
    function _setBlockClasses() {
        _setFolderBlockClasses();

        _setMediaBlockClasses();
    }

    /**
     * Get block style (both folder and media) according to widget style properties.
     *
     * @return {Object} The block style.
     */
    function _setBlockStyle() {
        const properties = _getWidgetProperties();

        vm.blockStyle = {};

        const margin = properties.itemsMargin;

        if (angular.isDefinedAndFilled(margin)) {
            vm.blockStyle.marginBottom = `${margin}px`;
        }

        return vm.blockStyle;
    }

    /**
     * Set the fields to be displayed on media & folder blocks.
     *
     * @return {Object} A map of field names and their visibility.
     */
    function _setFieldsToDisplay() {
        const properties = _getWidgetProperties();

        vm.fieldsToDisplay = {};

        angular.forEach(properties.fields, (field) => {
            vm.fieldsToDisplay[field.name] = field.enable;
        });

        return vm.fieldsToDisplay;
    }

    /**
     * Set the lx-color for the lumx elements (buttons).
     */
    function _setThemeColours() {
        const properties = _getWidgetProperties();

        vm.lxColorTheme = get(properties, 'style.content.theme') === 'dark' ? 'white' : 'grey';
    }

    /**
     * Get current folder permissions.
     *
     * @return {Promise} User permissions on current folder.
     */
    async function _getCurrentFolderPermissions() {
        const searchParameters = await _getCurrentCategorySearchParameters();
        // eslint-disable-next-line no-use-before-define
        const docPath = getCurrentDocumentPath();

        return Document.getPermissions({ docPath, searchParameters });
    }

    /**
     * Get list of actions for provider (create folder, create GDOC, etc.).
     *
     * @return {Promise} promise of actions.
     */
    async function _getProviderActions() {
        const properties = _getWidgetProperties();
        const providerID = properties.folder.providerId;
        const providerActions = await _documentProvider.getProviderAuthorizedActions(providerID);

        return map(providerActions, ({ resource, mimeType }) => ({
            mimeType,
            type: resource,
        }));
    }

    /**
     * Get and store current folder permissions.
     */
    async function _initCurrentFolderPermissions() {
        const { isWriter } = await _getCurrentFolderPermissions();

        const providerActions = [];
        const properties = _getWidgetProperties();
        if (
            (properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST && isWriter) ||
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
        ) {
            // Fetch provider actions when displaying folder list and user has permission.
            providerActions.push(...(await _getProviderActions()));
        }

        $scope.$apply(() => {
            vm.isWriterOnCurrentFolder =
                properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER || isWriter;
            vm.providerActions = providerActions;
        });
    }

    /**
     * List files & folders.
     * Used for all list calls (whenever a filter changes etc...).
     */
    function _initList() {
        // The first time we list files, initialize the list.
        if (!vm.widgetListCtrl.initialized) {
            _resetFileList();
            _resetHomeFolder();
        }

        _listFiles().then((value) => {
            const properties = _getWidgetProperties();
            if (
                properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST ||
                properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
            ) {
                _initCurrentFolderPermissions(value);
            }
        });
    }

    /**
     * Initialize the widget properties and the controller parameters.
     */
    function _initProperties() {
        const properties = _getWidgetProperties();

        properties.displayType = properties.displayType || WidgetSettingsConstant.SELECTION_TYPES.LIST;

        // The very first time the widget is init, set the selected folder as the 'home' folder.
        if (
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST ||
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
        ) {
            _resetHomeFolder();

            if (properties.folder && properties.folder.folderType === FOLDER_TYPES.CUSTOM) {
                if (includes(_documentProviderIds, properties.folder.providerId)) {
                    vm.is.loading.homeFolder = true;
                }
            }
        } else {
            properties.files = properties.files || [];
        }

        const themePropertyPath = 'style.content.theme';
        const theme = get(properties, themePropertyPath, 'light');

        set(properties, themePropertyPath, theme);

        properties.viewMode = 'vertical';
        properties.listOrder = properties.listOrder || 'name';
        properties.listOrderDir = properties.listOrderDir || WidgetSettingsConstant.ORDER_DIRECTION.ASCENDING;

        if (angular.isUndefinedOrEmpty(properties.itemsSeparator)) {
            properties.itemsSeparator = true;
        }

        // For now, always display toolbar when listing stuff (otherwise no way to navigate).
        properties.displayToolbar =
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST ||
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER;

        _initFields();

        _setFieldsToDisplay();

        _setBlockStyle();

        _setBlockClasses();

        _setThemeColours();
    }

    /**
     * An extra condition specific to this type of widget to determine if the widget should be considered empty.
     *
     * @return {boolean} Whether the widget should be empty or not.
     */
    function _isWidgetEmpty() {
        const properties = _getWidgetProperties();

        if (
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST ||
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
        ) {
            return !includes(_documentProviderIds, properties.folder.providerId);
        }

        return angular.isUndefinedOrEmpty(properties.files);
    }

    /**
     * Update the breadcrumb with a given folder.
     *
     * @param {Object} folder The folder to update the breadcrumb with.
     */
    function _updateBreadcrumb(folder) {
        const folderPath = vm.getDocumentPath(folder);

        // Navigating to the home folder effectively means clearing the breadcrumb.
        if (angular.isDefinedAndFilled(vm.homeFolder) && folderPath === vm.getDocumentPath(vm.homeFolder)) {
            vm.breadcrumbList.length = 0;

            return;
        }

        const folderPosition = findIndex(
            vm.breadcrumbList,
            (breadcrumbFolder) => vm.getDocumentPath(breadcrumbFolder) === folderPath,
        );

        // Folder is already in the breadcrumb.
        if (folderPosition > -1) {
            vm.breadcrumbList.splice(folderPosition + 1);
        } else {
            vm.breadcrumbList.push(folder);
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get document path.
     *
     * @param  {Object} media Document to get file content id.
     * @return {string} content file id.
     */
    function getDocumentPath(media) {
        if (angular.isUndefinedOrEmpty(media)) {
            return undefined;
        }

        return media.docPath;
    }

    /**
     * Get currently displayed folder.
     *
     * @return {Object} Current folder.
     */
    function _getCurrentFolder() {
        return vm.breadcrumbList.length > 0 ? last(vm.breadcrumbList) : vm.widget.properties.folder;
    }

    /**
     * Get current document path.
     *
     * @return {string} Current document path.
     */
    function getCurrentDocumentPath() {
        const currentDocument = _getCurrentFolder();

        return getDocumentPath(currentDocument);
    }

    /**
     * Get current folder name.
     *
     * @return {string} Current folder name.
     */
    function getCurrentFolderName() {
        if (vm.breadcrumbList.length === 0) {
            return get(vm.homeFolder, 'name');
        }

        return Document.getMediaContentByLang(last(vm.breadcrumbList), true).name;
    }

    /**
     * Navigate to a given folder in the file library.
     *
     * @param  {Object}  folder The folder to navigate to.
     * @return {Promise} Navigation promise.
     */
    function navigateTo(folder) {
        _updateBreadcrumb(folder);

        vm.isWriterOnCurrentFolder = false;

        // Empty search filter when navigating folder because search will be executed each time.
        vm.searchQuery = '';

        return _listFiles(folder.docPath).then(_initCurrentFolderPermissions);
    }

    /**
     * Navigate to parent folder.
     *
     * @return {Promise} Navigation promise.
     */
    function navigateToParentFolder() {
        const breadcrumbLength = vm.breadcrumbList.length;

        // Empty search filter when navigating folder because search will be executed each time.
        vm.searchQuery = '';

        if (breadcrumbLength === 1) {
            return vm.navigateTo(vm.homeFolder);
        } else if (breadcrumbLength > 1) {
            // eslint-disable-next-line no-magic-numbers
            return vm.navigateTo(vm.breadcrumbList[breadcrumbLength - 2]);
        }

        return Promise.resolve(undefined);
    }

    /**
     * Called when a component request status change.
     *
     * @param {Object} params Object containing new component request status.
     */
    function onStatusChange(params) {
        $scope.$apply(() => {
            vm.is.loading.files = params.status === 'PENDING';
        });
    }

    /**
     * Open current folder in a new tab.
     */
    async function openCurrentFolderInExternalTab() {
        const { docPath } = _getCurrentFolder();

        const folder = await Document.promiseGet({
            docPath,
        });
        const folderContent = Document.getMediaContentByLang(folder, true);
        const folderUri = get(folderContent, 'url');

        if (angular.isUndefinedOrEmpty(folderUri)) {
            return;
        }

        $window.open(decodeURIComponent(folderUri), '_blank');
    }

    /**
     * Reload file list.
     *
     * @return {Promise} Reload promise.
     */
    function reloadContent() {
        return _listFiles().then(_initCurrentFolderPermissions);
    }

    /**
     * Sort the file list by file property.
     *
     * @param {sring} property The file property to sort the file list.
     */
    async function sortOrder(property) {
        const reverseOrderChar = '-';
        const sortPropertyName = vm.sortOrderProperties[property].sortName;

        vm.sortOrderProperties[property].sortName = includes(sortPropertyName, reverseOrderChar)
            ? sortPropertyName.replace(/-/g, '')
            : reverseOrderChar.concat(sortPropertyName || property);

        // Remove other properties set before.
        angular.forEach(vm.sortOrderProperties, (props, key) => {
            if (key !== property) {
                vm.sortOrderProperties[key].sortName = '';
            }
        });

        await _listFiles();
    }

    /////////////////////////////

    vm.debouncedSearchFile = debounce(_listFiles, WidgetSettingsConstant.DEBOUNCE_DELAY);
    vm.getCurrentDocumentPath = getCurrentDocumentPath;
    vm.getCurrentFolderName = getCurrentFolderName;
    vm.getDocumentPath = getDocumentPath;
    vm.navigateTo = navigateTo;
    vm.navigateToParentFolder = navigateToParentFolder;
    vm.onStatusChange = onStatusChange;
    vm.openCurrentFolderInExternalTab = openCurrentFolderInExternalTab;
    vm.reloadContent = reloadContent;
    vm.sortOrder = sortOrder;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * When the theme of the widget changes, update the theme of folders and media.
     */
    $scope.$watch('vm.widget.properties.style.content.theme', () => {
        _setBlockClasses();

        _setThemeColours();
    });

    /**
     * When the margins of the widget changes, update the theme of folders and media.
     */
    $scope.$watch('vm.widget.properties.itemsMargin', () => {
        _setBlockStyle();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     *
     * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
     */
    vm.init = async (saveListKey) => {
        const currentWidget = vm.widget;
        const currentContent = Content.getCurrent();
        const listKey = WidgetFileListConstant.LIST_KEY_MEDIA_TAGS;
        const isCommunityContext = currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY;

        // Only init the media tags if it has never been initialized before.
        if (!Tag.isCallInProgress(listKey) && angular.isUndefinedOrEmpty(Tag.getList(undefined, undefined, listKey))) {
            Tag.init(Config.TAG_TYPE.MEDIA, WidgetFileListConstant.LIST_KEY_MEDIA_TAGS, undefined);
        }

        if (
            isCommunityContext &&
            currentWidget.properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER &&
            angular.isDefinedAndFilled(get(currentContent, 'driveFolder.source'))
        ) {
            const { docPath, source } = currentContent.driveFolder;

            currentWidget.properties.folder = {
                docPath,
                folderType: FOLDER_TYPES.CUSTOM,
                providerId: source,
            };
        }

        _initDocumentProvider();
        await _initDocumentProviderList();

        if (currentWidget.properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST) {
            // The Microsoft API doesnt allow us to sort files by created at date for now.
            if (
                currentWidget.properties.folder &&
                currentWidget.properties.folder.providerId !== MediaConstant.PROVIDERS.microsoft
            ) {
                vm.sortOrderProperties = {
                    ...vm.sortOrderProperties,
                    createdAt: {
                        isHidden: false,
                        sortName: '',
                        translate: 'FRONT.SETTINGS.CREATED_AT',
                    },
                };
            }
        }

        vm.widgetListCtrl.init(saveListKey);
    };

    /**
     * Set parent controller.
     *
     * @param {Object} widgetListCtrl The parent controller.
     */
    this.setParentController = (widgetListCtrl) => {
        vm.widgetListCtrl = widgetListCtrl;

        vm.widgetListCtrl.widgetListChildCtrl = {
            initList: _initList,
            initProperties: _initProperties,
            isWidgetEmpty: _isWidgetEmpty,
        };

        vm.init(true);
    };
}

/////////////////////////////

// eslint-disable-next-line valid-jsdoc
/**
 * The file list widget.
 * Displays a list of file or folders (from library or drive).
 *
 * @param {Object} widget The widget configuration object.
 */

function WidgetFileListDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc-except/require-jsdoc
    function WidgetFileListLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetFileListController,
        controllerAs: 'vm',
        link: WidgetFileListLink,
        replace: true,
        require: ['lsWidgetFileList', '^widgetList'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-file-list/views/widget-file-list.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('lsWidgetFileList', WidgetFileListDirective);

/////////////////////////////

export { WidgetFileListDirective };
