import * as React from 'react';
import classNames from 'classnames';
import * as propTypes from 'prop-types';

import { Icon } from '../icons';

/**
 * Renders a help text block.
 */
export class Information extends React.PureComponent {
    static propTypes = {
        noBorder: propTypes.bool,
        noPadding: propTypes.bool,
        value: propTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { noBorder, noPadding, value } = this.props;

        return (
            <div
                className={classNames(
                    noBorder ? '' : 'has-divider has-divider--dark',
                    noPadding ? 'mv+' : 'pv+ pl pr++',
                )}
                flex-container="row"
            >
                <Icon className="mr" size="s" type="flat" value="information-outline" />
                <span className="text-field-help" flex-item="flex-item">
                    {value}
                </span>
            </div>
        );
    }
}
