/* eslint-disable no-use-before-define */
import { ContentLinkRef } from '@lumapps/contents/types';
import { ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { Route } from '@lumapps/router';
import { TranslateObject, TranslationAPI } from '@lumapps/translations';

export interface NotificationContentDetails {
    type: string;
    slug: TranslateObject;
    title: TranslateObject;
    // UTC content end date
    endDate: string;
    postStatusDetails?: {
        title: string;
    };
}

export enum NotificationTypes {
    ArticleNew = 'article_new',
    ArticleMention = 'article_mention',
    CommentLike = 'comment_like',
    CommentMention = 'comment_mention',
    CommentMentionOnArticle = 'comment_mention_on_article',
    CommentMentionOnEvent = 'comment_mention_on_event',
    CommentNew = 'comment_new',
    CommentReply = 'comment_reply',
    CommentReportCreated = 'comment_report_created',
    CommentReportReviewed = 'comment_report_reviewed',
    CommentReportAccepted = 'comment_report_accepted',
    CommunityExtendedServiceManagement = 'community_extended_service_management',
    CommunityNewAccessRequest = 'community_new_access_request',
    CommunityNewCredential = 'community_new_credential',
    ContentExpiration = 'content_expiration',
    ContentLike = 'content_like',
    ContentMention = 'content_mention',
    ContentNew = 'content_new',
    ContentRefused = 'content_refused',
    ContentReportCreated = 'content_report_created',
    ContentReportReviewed = 'content_report_reviewed',
    ContentReportAccepted = 'content_report_accepted',
    ContentUpdate = 'content_update',
    ContentValidated = 'content_validated',
    ContentWorkflow = 'content_workflow',
    Custom = 'custom',
    EventNew = 'event_new',
    EventMention = 'event_mention',
    EventUpdated = 'event_updated',
    EventInvitationCreated = 'event_invitation_created',
    LearningCongratulation = 'learning_congratulation',
    LearningReminder = 'learning_reminder',
    PostMention = 'post_mention',
    PostMerged = 'post_merged',
    PostMergedMaster = 'post_merged_master',
    PostMergedSlave = 'post_merged_slave',
    PostMergePending = 'post_merge_pending',
    PostModerationDecline = 'post_moderation_decline',
    PostModerationDelete = 'post_moderation_delete',
    PostModerationDeleteSpammer = 'post_moderation_delete_spammer',
    PostNew = 'post_new',
    PostReportNew = 'post_report_new',
    PostReportCreated = 'post_report_created',
    PostReportReviewed = 'post_report_reviewed',
    PostReportAccepted = 'post_report_accepted',
    PostStatusUpdate = 'post_status_update',
    PostUpdate = 'post_update',
    ReplyLike = 'reply_like',
    SocialAdvocacyNewShareableContent = 'social_advocacy_new_shareable_content',
    SocialAdvocacyUserPromotedAMB = 'social_advocacy_user_promoted_amb',
    SocialAdvocacyUserPromotedPOM = 'social_advocacy_user_promoted_pom',
}

export enum NotificationContentType {
    post = 'post',
    page = 'page',
    community = 'community',
    article = 'article',
    event = 'event',
}

export interface NotificationSenderDetails {
    lastName: string;
    apiProfile: {
        profilePicture?: string;
        thumbnailPhotoUrl?: string;
        thumbnail?: {
            etag: string;
            mimeType: string;
            photoData: string;
        };
    };
    uid: string;
    fullName: string;
    profilePictureUrl?: string;
}

export interface NotificationAction {
    senderDetails: NotificationSenderDetails;
}

export interface NotificationGroup {
    notification: Notification;
    actions: NotificationAction[];
    digest?: string;
}

export interface NotificationResponse {
    notifications: NotificationGroup[];
    more: boolean;
    cursor?: string;
    unreadNotificationsCount: number;
}

export interface LastNotification {
    uid?: string;
    retryIn?: number;
}

export interface NotificationCenter {
    notificationGroups: NotificationGroup[];
    unreadNotificationsCount: number;
    isLoading: boolean;
    hasError: boolean;
    more: boolean;
    cursor?: string;
    lastNotification: LastNotification;
}

export interface NotificationMetaLinkAs {
    linkAs: 'a' | any;
    to: ContentLinkRef;
    crossInstanceInNewTab?: boolean;
}

export interface NotificationMeta {
    hasThumbnail?: boolean;
    iconProps: {
        color?: ColorPalette;
        colorVariant?: ColorVariant;
        /** Ignored if customIcon is filled */
        icon?: string;
        /** Use customIcon instead of icon */
        customIcon?: string;
    };
    getDescription(params: NotificationDescriptionParams): string;
    getTitle?(params: NotificationDescriptionParams): string | null;
    getRoute(params: NotificationLinkUrlParams): Route | null;
    getLinkAs?(params?: NotificationLinkUrlParams): NotificationMetaLinkAs | null;
}

export type NotificationDescriptionParams = {
    notification?: Notification;
    count: number;
    senderDetails?: NotificationSenderDetails | null;
    translation: TranslationAPI;
    currentLanguage: string;
};

export type InstanceDetails = {
    id?: string;
    slug: string;
    title: TranslateObject;
    name?: string;
};

export type NotificationLinkUrlParams = {
    notification?: Notification;
    currentInstanceId?: string;
    translation: TranslationAPI;
    anchor?: string;
    view?: string | null;
};

export type Notification = {
    description: TranslateObject;
    instanceId: string;
    title: TranslateObject;
    unreadNotificationCount: number;
    customerId: string;
    priority: number;
    uid: string;
    group: boolean;
    groupName?: string | TranslateObject;
    contentDetails: NotificationContentDetails;
    parentContentDetails: NotificationContentDetails;
    url: TranslateObject;
    properties: any;
    type: NotificationTypes;
    actionCount: number;
    externalKey: string;
    updatedAt: string;
    instanceDetails?: InstanceDetails;
    parentId?: string;
    link?: TranslateObject;
    isRead?: boolean;
};

/**
 * Possible status of the notification filter.
 */
export enum NotificationStatus {
    all = 'all',
    unread = 'unread',
}

export type NotificationQuery = {
    /** The parent id for notification group */
    parentId?: string;
    /** Cursor for the pagination */
    cursor?: string;
    /** The lang */
    lang?: string;
    /** Number of max results to fetch */
    maxResults?: number;
    /** Status of the requested notifications.
     * undefined will return all notifications, read and unread. */
    isRead?: boolean;
};
