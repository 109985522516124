export enum INSTANCE {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452069
     */
    ADD_ADMIN = 'ADMIN_INSTANCE_ADMIN_ADD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452070
     */
    DELETE_ADMIN = 'ADMIN_INSTANCE_ADMIN_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452071
     */
    DESCRIPTION_DELETE_ADMIN = 'ADMIN_INSTANCE_ADMIN_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452072
     */
    SUCCESS_DELETE_ADMIN = 'ADMIN_INSTANCE_ADMIN_DELETE_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244407
     */
    INSTANCE_ADMINS = 'ADMIN.NAV.INSTANCE_ADMINS',
}
