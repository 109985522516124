import { getImageForThumbnail } from '@lumapps/lumx-images/utils';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetFeaturedImageController($rootScope, $scope, Config, Content, Instance, Document, LxDialogService, Media, MediaConstant, Translation, Upload, Utils, UploaderAction, FeaturedImage) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The thumbnail to display.
         *
         * @type {boolean}
         */
        vm.featuredThumbnail = null;

        /**
         * The focalPoint coordinates.
         *
         * @type {Object}
         */
        vm.focalPoint = undefined;

        vm.isCropDisabled = true;
        vm.uploadMethodDialogId = 'upload-method-dialog';

        /////////////////////////////

        vm.Config = Config;
        vm.Content = Content;
        vm.LxDialogService = LxDialogService;
        vm.MediaConstant = MediaConstant;
        vm.Translation = Translation;
        vm.FeaturedImage = FeaturedImage;
        vm.Upload = Upload;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the cropped thumbnail if exists in the good language.
         * If not, get the original thumbnail.
         */
        function getFeaturedImage() {
            const rootContent = Content.getCurrent();
            return Document.getCroppedThumbnailFromRootContent(rootContent, true, undefined, Translation.inputLanguage);
        }

        /**
         * Get the focal point coordinates if exists
         */
        function getFocalPoint() {
            const currentContent = Content.getCurrent();
            if (currentContent.mediaThumbnail) {
                return Document.getFocusPointFromMedia(currentContent.mediaThumbnail, true, Translation.inputLanguage);
            }
            return undefined;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Edit content in basic mode.
         */
        function editContentBasic() {
            if (Content.getAction() !== 'get' && Content.getViewMode() === 'basic') {
                UploaderAction.openSelection(vm.widget.uuid);
            }
        }

        /**
         * Get content thumbnail and widget height.
         *
         * @return {Object} Content thumbnail style.
         */
        function getFeaturedImageStyle() {
            var resize = Media.imageSizeBreakpoints[_.get(vm.parentCtrl, 'widgetSize', 'm')];
            var contentThumbnailUrl = Media.getMediaImage(vm.featuredImage, undefined, undefined, resize);
            if (angular.isUndefinedOrEmpty(contentThumbnailUrl)) {
                return undefined;
            }

            // The image as already been resized.
            var featuredImageStyle = vm.focalPoint
                ? { display: 'block' }
                : Utils.getBackgroundImage(contentThumbnailUrl, -1, undefined, true);

            var properties = vm.widget.properties;

            if (angular.isDefined(properties.height) && !properties.fullHeight) {
                // Backward compatibility.
                properties.height = parseInt(properties.height, 10);
                featuredImageStyle.height = properties.height + 'px';
            } else if (!properties.fullHeight) {
                featuredImageStyle.height = '200px';
            }

            if (!vm.focalPoint) {
                angular.extend(featuredImageStyle, {
                    'background-position': properties.imageFormat.position,
                    'background-size': properties.imageFormat.size,
                });
            }

            // Apply image format.
            return featuredImageStyle;
        }

        function getModeThumbnail() {
            const { properties } = vm.widget;
            return properties.imageFormat && properties.imageFormat.size === 'cover' ? 'free' : 'original';
        }

        /**
         * Get widget class.
         *
         * @return {Array} Widget classes.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            widgetClass.push('widget-editable');

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode.
         *
         * @return {boolean} Is widget empty.
         */
        function isWidgetEmpty() {
            return angular.isUndefinedOrEmpty(vm.featuredImage) && Content.getViewMode() !== 'basic';
        }

        /**
         * Is widget hidden in reading mode.
         *
         * @return {boolean} Is widget hidden.
         */
        function isWidgetHidden() {
            if (
                !vm.parentCtrl.designerMode() &&
                !vm.widget.properties.noResults &&
                angular.isUndefinedOrEmpty(vm.featuredImage)
            ) {
                vm.parentCtrl.isHidden = true;

                return true;
            }

            vm.parentCtrl.isHidden = false;

            return false;
        }
    
        /**
         * Get upload url request parameters.
         *
         * @return {Object} Request parameters.
         */
        async function getUploadUrlRequestParameters() {
            const currentSite = Instance.getCurrentInstanceId(); 

            const parentPath = "provider=local/site=" + currentSite;

            return {
                parentPath,
                shared: false,
            };
        }

        function openCropper() {
            $rootScope.$broadcast('cropper-request');          
        }

        /////////////////////////////

        vm.editContentBasic = editContentBasic;
        vm.getFeaturedImageStyle = getFeaturedImageStyle;
        vm.getModeThumbnail = getModeThumbnail;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.getUploadUrlRequestParameters = getUploadUrlRequestParameters;
        vm.openCropper = openCropper;
    
        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When media picker is closed, check if it was for change the content thumbnail.
         *
         * @param {Event}  evt           The media picker open event.
         * @param {string} mediaPickerId The id of the media picker that closes.
         */
        $scope.$on('abstract-picker__close-end', function onMediaPickerClose(evt, mediaPickerId) {
            if (mediaPickerId === 'media-picker-content-thumbnail') {
                vm.init();

                if (Content.getViewMode() === 'basic') {
                    LxDialogService.close(vm.uploadMethodDialogId);
                }
            }
        });

        /**
         * When the main nav has been loaded, handle sub list.
         */
        $scope.$on('delete-content-thumbnail', function onContentThumbnailDelete() {
            vm.init();

            UploaderAction.clear(vm.widget.uuid);
        });

        $scope.$on('update-featured-image-thumbnail', function onCropperDialogClose() {
            vm.init();

            if (Content.getViewMode() === 'basic') {
                LxDialogService.close(vm.uploadMethodDialogId);
            }
        });

        $scope.$on('abstract-picker__open-start', function onMediaPickerOpen(evt, mediaPickerId) {
            if (mediaPickerId === 'media-picker-content-thumbnail') {
                if (Content.getViewMode() === 'basic') {
                    LxDialogService.close(vm.uploadMethodDialogId);
                }
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            vm.focalPoint = getFocalPoint();
            vm.featuredImage = getFeaturedImage();
            vm.featuredImageBlob = getImageForThumbnail(vm.featuredImage);

            var properties = _.get(vm.widget, 'properties', {});

            if (angular.isUndefinedOrEmpty(properties.imageFormat)) {
                properties.imageFormat = {
                    position: vm.focalPoint ? undefined : 'center center',
                    size: 'cover',
                };
            }

            vm.parentCtrl.setWidgetSize();
            const mediaContent = Document.getMediaContentByLang(
                Content.getCurrent().mediaThumbnail,
                true,
                Translation.inputLanguage,
                false,
                'content',
            );
            vm.isCropDisabled = !angular.isDefinedAndFilled(mediaContent);
        };

        /**
         * Set parent controller
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    /////////////////////////////

    /**
     * The featured image widget.
     * Displays content's thumbnail.
     *
     * @param {Object} widget The widget configuration object.
     */

    function widgetFeaturedImageDirective() {
        'ngInject';

        function widgetFeaturedImageLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetFeaturedImageController,
            controllerAs: 'vm',
            link: widgetFeaturedImageLink,
            replace: true,
            require: ['widgetFeaturedImage', '^widget'],
            restrict: 'E',
            scope: {
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-featured-image/views/widget-featured-image.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetFeaturedImage', widgetFeaturedImageDirective);
})();
