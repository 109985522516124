export const enum TRAINING_TYPE {
    training = 'training_course',
    learningPath = 'learning_path',
    unsupported = 'unsupported',
}

export const enum TRAINING_LEGACY_DATA_TYPE {
    training = 'Training',
    learningPath = 'LearningPath',
}

export type TrainingCoursesViewModeVariantType = 'grouped' | 'ungrouped';

export interface Training {
    code: string;
    identifier: string;
    title: string;
    description: string;
    language: string;
    availableLanguages: string[];
    logo: string;
    cover: string;
    categoriesCodes: string[];
    categoriesTitles: string[];
    progress: number;
    nbCompletedActivities: number;
    isRecommended: boolean;
    isDemo: boolean;
    isSandbox: boolean;
    isLive: boolean;
    type: string;
    accessUntil: Date;
}

export interface TrainingPaginatedQuery {
    language?: string;
    offset?: number;
    searchOffset?: number;
    limit?: number;
    identifier?: string;
    learningAlphaEnabled?: boolean;
    searchByLearner?: string;
}

export interface LearningPaginatedResponse<T> {
    offset: number;
    limit: number;
    items: T[];
}
export interface TrainingUrlData {
    launchUrl: string;
    identifier: string;
    title: string;
    type: string;
}
export const enum DisplayTrainingType {
    recommended = 'recommended',
    in_progress = 'in-progress',
}
