import React, { useState, useMemo } from 'react';

import { Moment, MomentBuiltinFormat, MomentInputObject } from 'moment';

import { classnames } from '@lumapps/classnames';
import { Tagz } from '@lumapps/folksonomy/components/Tagz';
import { Theme } from '@lumapps/lumx/react';
import { MediaType } from '@lumapps/medias/types';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { Attachments } from '@lumapps/posts/components/Attachments';
import { PostEventDates } from '@lumapps/posts/components/PostBlock/PostEventDates';
import { PostTitle } from '@lumapps/posts/components/PostBlock/PostTitle';
import { PostVote } from '@lumapps/posts/components/PostBlock/PostVote';
import { Post, PostType, IdeaVote } from '@lumapps/posts/types';
import { useTranslate } from '@lumapps/translations';
import { isImage } from '@lumapps/wrex-image/utils/isImage';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';

import { CommunitiesVisibilityField } from '../../CommunitiesVisibilityField';
import { PostContent } from '../../PostContent';

declare global {
    interface Window {
        moment: (
            inp?: string | number | void | Moment | Date | (string | number)[] | MomentInputObject | undefined,
            format?: string | MomentBuiltinFormat | (string | MomentBuiltinFormat)[] | undefined,
            strict?: boolean | undefined,
        ) => Moment;
    }
}

export interface PostBlockContentProps {
    /** The current community in which the post is shared. */
    currentCommunity: any;
    /** The post to display. */
    post: Post;
    /** The link to the detailed post view. */
    postLink: any;
    /** The fields to display in the post view. */
    fieldsToDisplay: any;
    /** Whether the folksonomy feature flag is enabled or not. */
    isTagzEnabled: boolean;
    /** Whether the translation feature flag is enabled or not. */
    isAutoTranslationEnabled: boolean;
    /** Whether the end user translation feature flag is enabled or not. */
    isEndUserTranslationEnabled: boolean;
    /** The current language used. */
    currentLang: string;
    /** The current view mode, can be list (posts) or detailed post (post). */
    view: string;
    /** Widget properties of the post list widget. [TODO]: Specify the type in the future widget/layout package. */
    widgetProperties?: Record<string, any>;
    /** The callback called when the user clicks on the additional communities button. */
    onOpenPostVisibilityDialog(): void;
    /** The callback called when the user clicks on a vote button. */
    onVote?(vote: IdeaVote, postId: string): void;
    /** The function called when the user clicks on the translate button. */
    onTranslate?(postId: string, content: string): void;
}

const CLASSNAME = 'post-block-content';

const PostBlockContent: React.FC<PostBlockContentProps> = ({
    onOpenPostVisibilityDialog,
    currentCommunity,
    isTagzEnabled,
    isAutoTranslationEnabled,
    isEndUserTranslationEnabled,
    post,
    postLink,
    currentLang,
    fieldsToDisplay,
    view,
    onTranslate,
    onVote,
    widgetProperties = {},
}) => {
    const { style: { content: { theme = Theme.light } = {} } = {} } = widgetProperties;
    const backgroundColor = useMemo(() => {
        const contentBackground = widgetProperties?.style?.content?.backgroundColor;
        if (contentBackground && contentBackground !== 'transparent') {
            return contentBackground;
        }
        const mainWidgetBackground = widgetProperties?.style?.main?.backgroundColor;
        if (mainWidgetBackground && mainWidgetBackground !== 'transparent') {
            return mainWidgetBackground;
        }
        return '#FFF';
    }, [widgetProperties]);

    const { translateObject } = useTranslate();
    const isOnDetailPostView = view === 'post';
    const hasImagesAttachments = Boolean(post.images && post.images.length);
    const hasFilesAttachments = Boolean(post.files && post.files.length);
    const hasLinksAttachments = Boolean(post.links && post.links.length);
    const inlineImages = fromMarkdown(translateObject(post.content) || '').filter(isImage);
    const hasAttachments =
        hasImagesAttachments || hasFilesAttachments || hasLinksAttachments || inlineImages.length > 0;

    const [detailsOpen, setDetailsOpen] = useState(isOnDetailPostView || !(inlineImages.length > 0));
    const privateCommunitiesCount = post.visibleInCommunitiesDetails
        ? (post.visibleInCommunitiesCount || 0) - post.visibleInCommunitiesDetails.length
        : 0;
    const communitiesSharedWith = currentCommunity
        ? (post.visibleInCommunitiesDetails || []).filter(
              (community) => currentCommunity.uid !== community.postedTo.uid,
          )
        : (post.visibleInCommunitiesDetails || []).filter((community) => community.postedTo.uid !== post.externalKey);
    const { dataId: postTitleDataId, target: postTitleTarget } = postLink;
    const site = { siteId: post.instance as string };

    return (
        <div
            className={classnames(CLASSNAME, {
                [`${CLASSNAME}__has-title`]: fieldsToDisplay.title,
                [`${CLASSNAME}__has-dates`]: fieldsToDisplay.dates && post.postType === PostType.EVENT,
                [`${CLASSNAME}__has-vote`]: post.postType === PostType.IDEA,
            })}
        >
            {fieldsToDisplay.sharedInCommunities && communitiesSharedWith.length > 0 && (
                <div className={`${CLASSNAME}__visibility`}>
                    <CommunitiesVisibilityField
                        onOpenPostVisibilityDialog={onOpenPostVisibilityDialog}
                        privateCommunitiesCount={privateCommunitiesCount}
                        communities={communitiesSharedWith}
                        theme={theme}
                    />
                </div>
            )}
            <div className={`${CLASSNAME}__wrapper`}>
                {fieldsToDisplay.ideaScore && post.postType === PostType.IDEA && onVote && (
                    <PostVote
                        initialVotes={{
                            userVote: post.userVote,
                            upVotes: post.score?.votesUp || 0,
                            downVotes: post.score?.votesDown || 0,
                        }}
                        theme={theme}
                        postId={post.uid}
                    />
                )}
                <div className={`${CLASSNAME}__content-wrapper`}>
                    {fieldsToDisplay.dates && post.postType === PostType.EVENT && (
                        <PostEventDates
                            startDate={post.eventStartDate}
                            endDate={post.eventEndDate}
                            legacyMoment={window.moment}
                            theme={theme}
                        />
                    )}
                    {fieldsToDisplay.title && translateObject(post.title) && (
                        <PostTitle
                            title={translateObject(post.title) || ''}
                            dataId={postTitleDataId}
                            target={postTitleTarget}
                            theme={theme}
                            site={site}
                            communitySlug={translateObject(post.parentContentDetails?.slug) as string}
                            communityId={post.parentContentDetails?.id as string}
                            postId={post.uid}
                        />
                    )}
                    {fieldsToDisplay.content && (
                        <PostContent
                            post={post}
                            linesLimit={widgetProperties.linesLimit}
                            lang={currentLang}
                            id={post.uid}
                            onTranslate={
                                isAutoTranslationEnabled || isEndUserTranslationEnabled ? onTranslate : undefined
                            }
                            detailsOpen={isOnDetailPostView || detailsOpen}
                            setDetailsOpen={setDetailsOpen}
                            hasAttachments={hasAttachments}
                            hasInlineImages={inlineImages.length > 0}
                            theme={theme}
                            isOnDetailPostView={isOnDetailPostView}
                            backgroundColor={backgroundColor}
                            shouldDisplayAttachments={fieldsToDisplay.attachments}
                        />
                    )}
                </div>
            </div>
            {/* Attachments should be hidden if
                - We are in a post list (not in the detail post view) AND
                - The widget advanced settings Visibility for attachments is set to hidden AND
                - The post details are closed.
                In case the content visibility is set to hidden the post details will be always considered as open, so
                we have to check if the post content is set to hidden or not
            */}
            {(fieldsToDisplay.attachments || (detailsOpen && fieldsToDisplay.content)) && hasAttachments && (
                <Attachments
                    theme={theme}
                    readOnly
                    images={
                        isOnDetailPostView || detailsOpen
                            ? post.images
                            : [
                                  ...inlineImages.map((node) => ({
                                      content: [
                                          {
                                              servingUrl: makeSecuredMediaURLRelative(node.src),
                                              type: MediaType.IMAGE,
                                              lang: 'en',
                                          },
                                      ],
                                  })),
                                  ...(post.images || []),
                              ]
                    }
                    files={post.files}
                    locale={currentLang}
                    link={(post.links || [])[0]}
                />
            )}
            {isTagzEnabled && post.tagz && Boolean(post.tagz.length) && (
                <div>
                    <Tagz values={post.tagz} theme={theme} />
                </div>
            )}
        </div>
    );
};

export { PostBlockContent };
