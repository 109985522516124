import React from 'react';

import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import {
    FieldValues,
    useController,
    UseControllerReturn,
    UseFormReset,
    useFormContext as useReactHookFormContext,
    useWatch,
} from 'react-hook-form';

import { Attributes, DataAttributesOptions, useDataAttributes } from '@lumapps/data-attributes';
import { TranslateObject, TranslatableObject, isTranslatableObjectEmpty } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { DefaultValueWrapperProps } from '../components/DefaultValueWrapper';
import { BaseInput } from '../types';
import { useFormContext } from './useFormContext';

export interface UseFormField<T> extends UseControllerReturn {
    /** current language */
    language: string;
    /** list of currently configured languages */
    languages: string[];
    /** callback on change when the context is multilanguage */
    onMultilanguageChange: (val?: T) => void;
    /** value to use for the current form field */
    valueToUse?: T;
    /** default value for the current form field */
    defaultValue?: T;
    /** whether the current value is the actual default value or not */
    isDefaultValueSet?: boolean;
    /** whether the field is disabled or not depending on several configurations */
    isFieldDisabled?: boolean;
    /** props to pass into the DefaultValueWrapper component */
    defaulValueWrapperProps: Omit<DefaultValueWrapperProps, 'children'>;
    /** current status for the form */
    status: BaseLoadingStatus;
    /** whether the form is loading or not */
    isFormLoading: boolean;
    /** props to be passed into the field */
    inputProps: {
        'aria-disabled': boolean;
        readOnly: boolean;
    };
    /** whether the field dropdown is open or not */
    isOpen: boolean;
    /** callback to open/close a dropdown */
    setIsOpen: (open: boolean) => void;
    /** get data attributes function, for tracking purposes */
    getDataAttributes: (options: DataAttributesOptions) => Attributes;
    /** react-hook-form field */
    field: UseControllerReturn['field'] & {
        /** callback for focusing on the field */
        focus: () => void;
    };
    scope: string;
    reset: UseFormReset<FieldValues>;
}

const DIRTY_FIELDS = '__dirty';

/**
 * Hook that can be reused for managing any field that we want to connect to react-hook-form
 */
export const useFormField = <T = any>({
    name,
    isRequired,
    controllerProps = {},
    isMultiLanguage = false,
    preventEmptyFields = false,
    languagesFormat = 'v1',
}: BaseInput): UseFormField<T> => {
    const { watch, setValue, getValues, setFocus, reset } = useReactHookFormContext();
    const defaultLanguage = getValues('defaultLanguage');
    const languages = getValues('formLanguages') || [];
    /** provide isOpen/setIsOpen functionality for components that display popovers or dropdowns */
    const [isOpen, setIsOpen] = React.useState(false);
    const status = useWatch({ name: 'formLoadingStatus' });
    const isLoading = status === BaseLoadingStatus.loading;
    const {
        onValueChanged,
        dependsOn,
        showUseDefaultValuesCheckbox,
        compareValues = isEqual,
        ...ctrlProps
    } = controllerProps;

    const lumxFormContext = useFormContext();
    const { scope = 'form' } = lumxFormContext;
    const { get: getDataAttributes } = useDataAttributes(scope);

    /**
     * if we are in multilanguage context, we need to watch the language
     * in order to update the values displayed
     */
    const language = isMultiLanguage ? watch('lang') : defaultLanguage;

    const rules = ctrlProps && ctrlProps.rules ? ctrlProps.rules : { required: isRequired };

    /**
     * If preventEmptyFields is set to true, the value is trimmed to check that it's not filled with blank spaces
     */
    if (preventEmptyFields) {
        Object.assign(rules, {
            validate: (value: string) => !!value.trim(),
        });
    }

    if (isMultiLanguage && isRequired && !rules.validate) {
        /**
         * If it is multilanguage, the validation for the field is basically checking that it has
         */
        Object.assign(rules, {
            validate: (translatableObject: TranslateObject | TranslatableObject) => {
                return !isTranslatableObjectEmpty(translatableObject);
            },
        });
    }

    const controller = useController({
        rules,
        ...ctrlProps,
        name,
    });

    const { field } = controller;

    let valueToUse = field.value;

    /**
     * If there is a language and a value, we need to determine what text we are going to
     * display on the component.
     */
    if (language && field.value && isMultiLanguage) {
        const values = languagesFormat === 'v1' ? field.value : field.value.translations;
        valueToUse = values[language];
    }

    /**
     * Callback that will be executed when the field changes and we are in a multilanguage field.
     * @param val
     */
    const onMultilanguageChange = (val?: T) => {
        if (isLoading) {
            return;
        }

        let currentValue = field.value;

        /**
         * If the format is v1, we simply update the object with the latest values and set it.
         */
        if (languagesFormat === 'v1') {
            if (!currentValue) {
                currentValue = {};
            }

            currentValue[language] = val;
        } else {
            /**
             * If we are in v2, we need to initialise the value with the correct format
             * for the v2 translations, and then set the value into the `translations` object.
             */
            if (!currentValue) {
                currentValue = {
                    lang: defaultLanguage,
                    value: defaultLanguage === language ? val : undefined,
                    translations: {},
                };
            }

            /**
             * If the current language is the same as the default language, update the value
             */
            if (defaultLanguage === language) {
                currentValue.value = val;
            }

            currentValue.translations[language] = val;
        }

        setValue(name, currentValue);
        field.onChange(currentValue);

        if (onValueChanged) {
            onValueChanged(currentValue);
        }
    };

    const dependsOnValues = dependsOn ? watch(dependsOn.fields) : undefined;

    /**
     * If the `dependsOn` property is defined, we need to validate that the value
     * currently selected for the form field defers from the value coming from
     * the `dependsOn.generator` property. If they are different, we change
     * the currently value with the newly generated one.
     */
    React.useEffect(() => {
        if (dependsOn && dependsOnValues) {
            const valuesByKey: Record<string, any> = {};

            dependsOn.fields.forEach((field, index) => {
                valuesByKey[field] = dependsOnValues[index];
            });

            const newValue = dependsOn.generator(valuesByKey, field.value);
            const shouldBeUpdated = dependsOn.shouldBeUpdated
                ? dependsOn.shouldBeUpdated(field.value, newValue)
                : newValue !== field.value;

            if (shouldBeUpdated) {
                setValue(name, newValue);
                field.onChange(newValue);

                if (onValueChanged) {
                    onValueChanged(newValue);
                }
            }
        }
    }, [dependsOn, dependsOnValues, field, name, onValueChanged, setValue]);

    /**
     * Callback on field value change. onChange should not be called with undefined.
     * See https://www.react-hook-form.com/api/usecontroller/controller/
     * @param event
     */
    const onFieldChange = React.useCallback(
        (event: NonNullable<unknown> | null) => {
            field.onChange(event);

            if (onValueChanged) {
                onValueChanged(event !== '' ? event : null);
            }
        },
        [field, onValueChanged],
    );

    /**
     * Default values management. Here we:
     * - Determine the default value for the field if there is one
     * - Check whether the current value is the default value or not
     * - Manage the state related for the checkbox displayed at the bottom of the field
     */
    const defaultValue =
        lumxFormContext && lumxFormContext.defaultFormValues && lumxFormContext.defaultFormValues[name];
    const isDefaultValueSet = showUseDefaultValuesCheckbox && compareValues(defaultValue, valueToUse);
    const dirtyFields: string[] = React.useMemo(() => getValues(DIRTY_FIELDS) || [], [getValues]);
    const [isDefaultValuesChecked, setIsDefaultValuesChecked] = React.useState(
        isDefaultValueSet && dirtyFields.indexOf(name) < 0,
    );
    const isDefaultCheckboxChecked = isDefaultValueSet && isDefaultValuesChecked;

    /**
     * When the checkbox for the default value changes we need to:
     * - Update the field's value with the default value
     * - Update the list of dirty fields, which is a list of strings that contains the fields
     *   that do not have the default value set.
     */
    const onDefaultValueWrapperChange = React.useCallback(
        (checked) => {
            if (isLoading) {
                return;
            }

            const latestDirtyFields: string[] = getValues(DIRTY_FIELDS) || [];

            if (checked) {
                onFieldChange(defaultValue);

                const updatedDirtyFields = latestDirtyFields.filter((field) => field !== name);

                setValue(DIRTY_FIELDS, updatedDirtyFields);
            } else if (dirtyFields.indexOf(name) < 0) {
                setValue(DIRTY_FIELDS, [...latestDirtyFields, name]);
            }

            setIsDefaultValuesChecked(checked);
        },
        [defaultValue, dirtyFields, getValues, isLoading, name, onFieldChange, setValue],
    );

    return {
        language,
        languages,
        onMultilanguageChange,
        valueToUse,
        ...controller,
        field: React.useMemo(
            () => ({
                ...field,
                onChange: isLoading ? noop : onFieldChange,
                focus: () => setFocus(name),
            }),
            [field, isLoading, name, onFieldChange, setFocus],
        ),
        defaultValue,
        isDefaultValueSet,
        isFieldDisabled: isDefaultCheckboxChecked && showUseDefaultValuesCheckbox,
        status,
        inputProps: {
            'aria-disabled': isLoading,
            readOnly: isLoading,
        },
        defaulValueWrapperProps: {
            isFeatureEnabled: showUseDefaultValuesCheckbox,
            onChange: isLoading ? noop : onDefaultValueWrapperChange,
            isChecked: isDefaultCheckboxChecked,
        },
        isFormLoading: isLoading,
        isOpen: isLoading ? false : isOpen,
        setIsOpen: isLoading ? noop : setIsOpen,
        getDataAttributes,
        scope,
        reset,
    };
};
