import React from 'react';

import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { EXTERNAL_LIBRARIES, useExternalLibrary } from '@lumapps/utils/libs/useExternalLibrary';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { GD_DEFAULT_STARRED_EN, GD_FULL_VIEW_CONFIG } from '../constants';
import { GDCustomDocViewType, GDCustomViewConfig, GDPickerConfiguration } from '../types';
import { getDriveToken } from '../utils/getDriveToken';

export const useGoogleDrivePicker = (
    pickConfig: GDPickerConfiguration,
    { onFilesSelected }: { onFilesSelected: (files: google.picker.DocumentObject[]) => void },
) => {
    const [pickerApiLoaded, setPickerApiLoaded] = React.useState(BaseLoadingStatus.initial);
    const [authApiLoaded, setAuthApiLoaded] = React.useState(BaseLoadingStatus.initial);
    const [shouldLoadLibrary, , , setToTrueShouldLoadLibrary] = useBooleanState(false);

    const onAuthApiLoad = React.useCallback(() => {
        setAuthApiLoaded(BaseLoadingStatus.idle);
    }, [setAuthApiLoaded]);

    const onPickerApiLoad = React.useCallback(() => {
        setPickerApiLoaded(BaseLoadingStatus.idle);
    }, [setPickerApiLoaded]);

    const onApiLoad = React.useCallback(() => {
        /** convertion needed to satisfy TS because gapi.load callback type as specific.
            In fact there is nosense for us to use it this way, we just need to toggle boolean
        */
        gapi.load('auth', onAuthApiLoad as unknown as gapi.LoadCallback);
        gapi.load('picker', onPickerApiLoad as unknown as gapi.LoadCallback);
    }, [onAuthApiLoad, onPickerApiLoad]);

    useExternalLibrary({
        lib: EXTERNAL_LIBRARIES.GOOGLE_API,
        shouldLoadLibrary:
            shouldLoadLibrary &&
            pickerApiLoaded === BaseLoadingStatus.initial &&
            authApiLoaded === BaseLoadingStatus.initial,
        onLoad: onApiLoad,
    });

    // Build Custom DocView array to add to picker
    const buildDocViews = (cvConfig: GDCustomViewConfig) => {
        const googleViewId = google.picker.ViewId[cvConfig.viewId || 'DOCS'];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const docViews: any[] = [];

        const customViewsList = cvConfig.customDocViews.includes(GDCustomDocViewType.FULL_VIEW)
            ? GD_FULL_VIEW_CONFIG
            : cvConfig.customDocViews;
        customViewsList.forEach((cView: GDCustomDocViewType) => {
            switch (cView) {
                case GDCustomDocViewType.SHARED_WITH_ME_VIEW: {
                    // Shared with me
                    const viewSharedWithMe = new google.picker.DocsView(googleViewId);
                    viewSharedWithMe.setIncludeFolders(cvConfig.setIncludeFolders || false);
                    viewSharedWithMe.setSelectFolderEnabled(cvConfig.setSelectFolderEnabled || false);
                    viewSharedWithMe.setOwnedByMe(false);
                    docViews.push(viewSharedWithMe);
                    break;
                }
                case GDCustomDocViewType.STARRED_VIEW: {
                    // Starred  \!/ show Google Drive as label (problem reported google side). We need to translate by adding label
                    const viewStarred = new google.picker.DocsView(googleViewId);
                    viewStarred.setStarred(true);
                    viewStarred.setIncludeFolders(cvConfig.setIncludeFolders || false);
                    viewStarred.setSelectFolderEnabled(cvConfig.setSelectFolderEnabled || false);
                    // eslint-disable-next-line
                    // @ts-ignore ignore because types says that's not exists but in fact it works
                    viewStarred.setLabel(cvConfig.starredTranslation || GD_DEFAULT_STARRED_EN); //  translate text
                    docViews.push(viewStarred);
                    break;
                }
                case GDCustomDocViewType.DEFAULT_VIEW: {
                    // My Drive : create first drive view
                    const viewMyDrive = new google.picker.DocsView(googleViewId);
                    viewMyDrive.setIncludeFolders(cvConfig.setIncludeFolders || false);
                    viewMyDrive.setSelectFolderEnabled(cvConfig.setSelectFolderEnabled || false);
                    viewMyDrive.setOwnedByMe(true);

                    docViews.push(viewMyDrive);
                    break;
                }
                default:
                    break;
            }
        });

        return docViews;
    };

    const onCreatePicker = React.useCallback(
        ({
            appId = '',
            customDocViews,
            developerKey,
            locale = 'en',
            multiselect,
            selectableMimeTypes,
            setIncludeFolders = false,
            setParentFolder = '',
            setSelectFolderEnabled = false,
            showUploadFolders,
            showUploadView = false,
            starredTranslation,
            token,
            viewId = 'DOCS',
            viewMimeTypes,
        }: GDPickerConfiguration) => {
            // the callback implementation to get the selected files info / data return by the API.
            const pickerCallback = (response: google.picker.ResponseObject) => {
                if (response.action === google.picker.Action.PICKED) {
                    onFilesSelected(response.docs);
                }
            };

            // My Drive : create first drive view
            const googleViewId = google.picker.ViewId[viewId];

            // Shared drive (aka. Team Drives)
            const SharedDrive = new google.picker.DocsView(googleViewId);
            SharedDrive.setIncludeFolders(setIncludeFolders);
            SharedDrive.setSelectFolderEnabled(setSelectFolderEnabled);
            SharedDrive.setEnableDrives(true);

            if (viewMimeTypes) {
                SharedDrive.setMimeTypes(viewMimeTypes.join(','));
            }

            // Uplaod view
            const uploadView = new google.picker.DocsUploadView();
            if (showUploadFolders) {
                uploadView.setIncludeFolders(true);
            }
            if (setParentFolder) {
                uploadView.setParent(setParentFolder);
            }

            if (!SharedDrive) {
                throw new Error("Can't find view by viewId");
            }

            const picker = new google.picker.PickerBuilder()
                .setAppId(appId)
                .setOAuthToken(token)
                .setDeveloperKey(developerKey)
                .setCallback(pickerCallback)
                .setLocale(locale);

            if (selectableMimeTypes) {
                picker.setSelectableMimeTypes(selectableMimeTypes.join(','));
            }

            if (multiselect) {
                picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
            }

            const docViews = buildDocViews({
                customDocViews: customDocViews || [],
                viewId,
                setIncludeFolders,
                setSelectFolderEnabled,
                starredTranslation,
            });

            docViews.forEach((view) => {
                if (viewMimeTypes) {
                    view.setMimeTypes(viewMimeTypes.join(','));
                }
                picker.addView(view);
            });

            if (showUploadView) {
                picker.addView(uploadView);
            }
            picker.addView(SharedDrive);

            // Enable 'shared drive' documents to be included in picker results.
            picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES);

            picker.build().setVisible(true);
        },
        [onFilesSelected],
    );

    const openGoogleDrivePicker = async () => {
        setToTrueShouldLoadLibrary();
        if (pickConfig.token) {
            const token = await getDriveToken({ fetchKey: 'getToken', token: pickConfig.token });
            onCreatePicker({ ...pickConfig, token });
        } else {
            // auth Token no found
            return false;
        }

        return true;
    };

    return { openGoogleDrivePicker };
};
