import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Divider, Switch } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';

import {
    VIEW_MODE_GRID_VARIANTS,
    VIEW_MODE_LIST_VARIANTS,
    VIEW_MODE_VARIANTS_VALUES,
    VIEW_MODES,
    VIEW_MODES_VALUES,
} from '../../constants';
import { WIDGET_SETTINGS } from '../../keys';
import { AVAILABLE_OPTIONS, StyleSettingType, ViewModeType } from '../../types';
import { SettingSection } from '../SettingSection';
import { ViewModeList } from '../ViewModeList';

import './index.scss';

export interface ListWidgetStyleSettingProps<VM, VMV> {
    className?: string;
    currentStylesSetting?: StyleSettingType<VM, VMV>;
    dataScope: string;
    /** Optional array containing the possible viewModes. If undefined, use VIEW_MODES */
    viewModesArray?: Array<VM>;
    /** Optional array containing the availableOptions to exclude from the default values in VIEW_MODES */
    excludeAvailableOptions?: Array<AVAILABLE_OPTIONS>;
    onChange: (properties: StyleSettingType<VM, VMV>) => void;
}

const CLASSNAME = 'style-setting';

/**
 * Generic component that allow to set the view mode of a list widget (list, grid, slideshow)
 * Used for widget list settings
 */
export const ListWidgetStyleSetting = <VM, VMV>({
    className,
    currentStylesSetting,
    dataScope,
    viewModesArray,
    excludeAvailableOptions,
    onChange,
}: ListWidgetStyleSettingProps<VM, VMV>) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(dataScope);
    const initViewMode =
        VIEW_MODES.find((view) => view.value === currentStylesSetting?.viewMode) ||
        VIEW_MODES.find((view) => view.value === VIEW_MODES_VALUES.LIST);

    const [selectedViewMode, setSelectedViewMode] = React.useState(initViewMode as ViewModeType<VM>);
    const isListSelected = selectedViewMode?.value === VIEW_MODES_VALUES.LIST;

    const viewModes = viewModesArray ? VIEW_MODES.filter((vm) => viewModesArray.includes(vm.value as VM)) : undefined;

    const variantsArray = isListSelected ? VIEW_MODE_LIST_VARIANTS : VIEW_MODE_GRID_VARIANTS;
    const initViewModeVariant =
        variantsArray.find((view) => view.value === currentStylesSetting?.viewModeVariant) || variantsArray[0];
    const [selectedViewModeVariant, setSelectedViewModeVariant] = React.useState(
        initViewModeVariant as ViewModeType<VMV>,
    );

    const [isSeparatorFieldChecked, setIsSeparatorFieldChecked] = React.useState<boolean>(
        currentStylesSetting?.hasSeparator || true,
    );
    const [availableOptions, setAvailableOptions] = React.useState<AVAILABLE_OPTIONS[] | undefined>(
        excludeAvailableOptions
            ? initViewMode?.availableOptions?.filter((option) => !excludeAvailableOptions.includes(option))
            : initViewMode?.availableOptions,
    );

    const viewModeLabelId = React.useMemo(() => uniqueId(`${CLASSNAME}-view-mode-label-`), []);
    const viewVariantLabelId = React.useMemo(() => uniqueId(`${CLASSNAME}-view-variant-label-`), []);

    const onViewModeChange = (viewMode: any) => {
        const isViewModeVariant =
            VIEW_MODE_LIST_VARIANTS.includes(viewMode) || VIEW_MODE_GRID_VARIANTS.includes(viewMode);

        if (isViewModeVariant) {
            setSelectedViewModeVariant(viewMode);
        } else {
            setSelectedViewMode(viewMode);
            setSelectedViewModeVariant(
                viewMode.label === WIDGET_BASE.SETTINGS_VIEW_MODE_LIST
                    ? (VIEW_MODE_LIST_VARIANTS[0] as ViewModeType<VMV>)
                    : (VIEW_MODE_GRID_VARIANTS[0] as ViewModeType<VMV>),
            );
            setAvailableOptions(
                excludeAvailableOptions
                    ? viewMode.availableOptions?.filter(
                          (option: AVAILABLE_OPTIONS) => !excludeAvailableOptions.includes(option),
                      )
                    : viewMode.availableOptions,
            );
        }
    };

    React.useEffect(() => {
        onChange({
            viewMode: selectedViewMode.value,
            viewModeVariant: selectedViewModeVariant.value,
            hasSeparator: isSeparatorFieldChecked,
            itemsPerLine: selectedViewMode.value === VIEW_MODES_VALUES.SLIDESHOW ? 1 : 3,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedViewMode, selectedViewModeVariant, isSeparatorFieldChecked]);

    return (
        <div className={block([className])}>
            <SettingSection
                title={translateKey(WIDGET_SETTINGS.VIEW_MODE)}
                titleId={viewModeLabelId}
                className={element('view-mode')}
            >
                <ViewModeList<VM>
                    currentViewMode={selectedViewMode}
                    onViewModeChange={onViewModeChange}
                    dataScope={dataScope}
                    viewModes={viewModes}
                />
            </SettingSection>
            {availableOptions?.includes(AVAILABLE_OPTIONS.VARIANT) && (
                <>
                    <Divider />
                    <SettingSection
                        title={translateKey(WIDGET_BASE.SETTINGS_VARIANTS)}
                        titleId={viewVariantLabelId}
                        className={element('view-mode__variants')}
                    >
                        <ViewModeList<VMV>
                            currentViewMode={selectedViewModeVariant}
                            onViewModeChange={onViewModeChange}
                            dataScope={dataScope}
                            viewModes={variantsArray}
                        />
                    </SettingSection>
                </>
            )}
            {availableOptions?.includes(AVAILABLE_OPTIONS.SEPARATOR) &&
                selectedViewModeVariant.value !== VIEW_MODE_VARIANTS_VALUES.UNGROUPED && (
                    <>
                        <Divider />
                        <SettingSection title={translateKey(GLOBAL.OPTIONS)}>
                            <Switch
                                isChecked={isSeparatorFieldChecked}
                                onChange={setIsSeparatorFieldChecked}
                                helper={translateKey(WIDGET_BASE.SETTINGS_SHOW_SEPARATOR_HELPER)}
                                className="lumx-spacing-margin-top-huge"
                                {...get({ element: 'switch', action: 'toggle-separator' })}
                            >
                                {translateKey(WIDGET_BASE.SETTINGS_SHOW_SEPARATOR)}
                            </Switch>
                        </SettingSection>
                    </>
                )}
        </div>
    );
};
