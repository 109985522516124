/* istanbul ignore file */
import { useCallback, useState, useEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

import { VideoWebsites } from '@lumapps/medias/types';
import { getVideoId, getVideoUrlWebsiteName } from '@lumapps/medias/utils';
import { urlInfo } from '@lumapps/posts/api';

import type { LinkPreviewMetadata } from '../types';

// eslint-disable-next-line default-param-last
export const useLinkPreview = (url: string, shouldCall: boolean, shouldDebounce = true, embedUrl?: string) => {
    const [fetchedPreview, setFetchedPreview] = useState<Partial<LinkPreviewMetadata>>({});
    const [isPreviewLoading, setPreviewLoading] = useState(shouldCall);
    const [previewError, setPreviewError] = useState<number>();
    const [activePreviewImage, setActivePreviewImage] = useState(0);
    const videoId = useMemo(() => getVideoId(url, getVideoUrlWebsiteName(url) as VideoWebsites), [url]);

    const onPreviewThumbnailSwitch = useCallback(() => {
        if (fetchedPreview?.images) {
            const imageCount = fetchedPreview?.images.length;
            if (imageCount) {
                setActivePreviewImage((prevIndex) => {
                    const nextIndex = prevIndex + 1;
                    return nextIndex >= imageCount ? 0 : nextIndex;
                });
            }
        }
    }, [fetchedPreview]);

    const fetchPreview = (previewUrl: string) => {
        setPreviewLoading(true);

        urlInfo(previewUrl)
            .then((response) => {
                setPreviewLoading(false);
                setFetchedPreview(response.data);
                setActivePreviewImage(0);
            })
            .catch((error) => {
                setPreviewError(error.response.status);
            })
            .finally(() => {
                setPreviewLoading(false);
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchPreview = useCallback(
        debounce((previewUrl: string) => {
            fetchPreview(previewUrl);
        }, 800),
        [],
    );

    useEffect(() => {
        setPreviewError(undefined);
        setFetchedPreview({});
        if (shouldCall) {
            if (shouldDebounce) {
                debouncedFetchPreview(url);
            } else {
                fetchPreview(url);
            }
        }
    }, [debouncedFetchPreview, shouldCall, shouldDebounce, url]);

    return {
        preview: { ...fetchedPreview, thumbnailIndex: activePreviewImage, videoId, embedUrl },
        onPreviewThumbnailSwitch,
        previewError,
        isPreviewLoading,
        fetchPreview: () => (shouldDebounce ? debouncedFetchPreview(url) : fetchPreview(url)),
        undebouncedFetchPreview: fetchPreview,
    };
};
