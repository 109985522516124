import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size } from '@lumapps/lumx/react';
import { UserBlock, UserBlockProps } from '@lumapps/user/components/UserBlock';

import { CLASSNAME } from '../../constants';
import { AttributeProps } from './BaseAttribute';

export type UserAttributeProps = UserBlockProps & Partial<AttributeProps>;

/**
 * Component to be used for displaying users while using the `GenericEntityBlock`
 * pattern.
 *
 * @param UserBlockProps
 * @family Blocks
 * @returns UserAttribute
 */
export const UserAttribute: React.FC<UserAttributeProps> = ({ className, ...props }) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <UserBlock
            {...props}
            // Explicitely remove the email from the user block to avoid multiple lines
            email={undefined}
            size={Size.s}
            className={element('user', [className])}
        />
    );
};
