import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { findInterest, saveInterest } from '../api/followInterest';
import { WIDGET_CONTENT_FILTER } from '../keys';
import { FollowInterestPayload } from '../types';

interface UseFollowInterestProps {
    contentTypes?: string[];
    tags?: string[];
    metadata?: string[];
}

const extractPayloadFromRejectedPromise = (settledPromise: PromiseRejectedResult) => {
    const rawPayload = settledPromise.reason.config.data;
    return typeof rawPayload === 'string' ? (JSON.parse(rawPayload) as FollowInterestPayload) : null;
};

export const useFollowInterest = ({ contentTypes, tags, metadata }: UseFollowInterestProps) => {
    const { translateKey } = useTranslate();
    const [isLoading, startLoading, stopLoading] = useBooleanState(false);
    const { error, success, info } = useNotification();
    const instanceId = useSelector(instanceIdSelector);

    const payloads = contentTypes?.map((contentType: string) => ({
        customContentType: contentType,
        customContentTypeTags: tags || [],
        orMetadata: metadata || [],
    }));

    /**
     * First we are trying to find all interests for each custom content types,
     * then, if at least one is not found we can conclude that it is not existing.
     * In this case we are saving all interests, one per custom content types.
     * Else, we are notifying the user that these interests are already saved.
     */
    const handleInterest = async () => {
        if (payloads?.length && !isLoading) {
            startLoading();
            try {
                const findPromises = payloads.map((payload) => findInterest(payload));
                const findResults = await Promise.allSettled(findPromises);
                const interestsToBeSaved = findResults.filter(
                    (result) => result.status === 'rejected',
                ) as PromiseRejectedResult[];
                const rejectedPayloads = compact<FollowInterestPayload>(
                    map(interestsToBeSaved, extractPayloadFromRejectedPromise),
                );
                if (!isEmpty(rejectedPayloads)) {
                    const savePromises = rejectedPayloads.map((payload) =>
                        saveInterest({ ...payload, instanceId, notify: true }),
                    );
                    const saveResults = await Promise.allSettled(savePromises);
                    if (saveResults.some((result) => result.status === 'rejected')) {
                        error({ content: translateKey(WIDGET_CONTENT_FILTER.INTEREST_ERROR_SAVED) });
                    } else {
                        success({ content: translateKey(WIDGET_CONTENT_FILTER.INTEREST_SAVED) });
                    }
                } else {
                    info({ content: translateKey(WIDGET_CONTENT_FILTER.INTEREST_ALREADY_SAVED) });
                }
            } catch (e) {
                error({ content: translateKey(WIDGET_CONTENT_FILTER.INTEREST_ERROR_SAVED) });
            }
            stopLoading();
        }
    };

    return handleInterest;
};
