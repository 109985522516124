import { useCallback, useEffect, useState } from 'react';

import unionBy from 'lodash/unionBy';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { TranslateObject } from '@lumapps/translations';

import { listAdminMetadata } from '../api';

interface UseRootMetadataProps {
    contentTypes?: string[];
    displayInFilter?: boolean;
    withoutCustomContentTypes?: boolean;
}

interface MetadataRoot {
    name: TranslateObject;
    id: string;
    rootId: string;
}

/**
 * Provide a list of root metadata for given content types
 * @param contentTypes list of content type ids
 * @param displayInFilter can be true, false or undefined (in this last case all metadata will be returned)
 * @returns loading & error state of the fetch call, as long as a list of Metadata
 */
export const useRootMetadata = ({
    contentTypes,
    displayInFilter,
    withoutCustomContentTypes = true,
}: UseRootMetadataProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [platformMetadata, setPlatformMetadata] = useState<MetadataRoot[]>([]);
    const [instanceMetadata, setInstanceMetadata] = useState<MetadataRoot[]>([]);

    const instanceId = useSelector(instanceIdSelector);

    /** Method to fetch platform metadata information */
    const getPlatformMetadata = useCallback(async () => {
        try {
            setIsLoading(true);
            setHasError(false);
            const data = await listAdminMetadata({
                displayInFilter,
                customContentTypeIds: contentTypes,
                emptyParent: true,
                withoutCustomContentTypes,
            });
            const response = await data;
            const formatedData = response.data.items.map(({ id, name, familyKey }) => ({
                id,
                name,
                rootId: familyKey,
            }));
            setPlatformMetadata(formatedData);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [contentTypes, displayInFilter, withoutCustomContentTypes]);

    /** Method to fetch instance metadata information */
    const getInstanceMetadata = useCallback(async () => {
        try {
            setIsLoading(true);
            setHasError(false);
            const data = await listAdminMetadata({
                displayInFilter,
                customContentTypeIds: contentTypes,
                instance: instanceId,
                emptyParent: true,
                withoutCustomContentTypes,
            });
            const response = await data;
            const formatedData = response.data.items.map(({ id, name, familyKey }) => ({
                id,
                name,
                rootId: familyKey,
            }));
            setInstanceMetadata(formatedData);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [contentTypes, displayInFilter, instanceId, withoutCustomContentTypes]);

    useEffect(() => {
        getPlatformMetadata();
    }, [getPlatformMetadata]);

    useEffect(() => {
        getInstanceMetadata();
    }, [getInstanceMetadata]);

    return {
        rootMetadata: unionBy(instanceMetadata, platformMetadata, 'id'),
        isLoading,
        hasError,
    };
};
