import React, { isValidElement } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Divider } from '@lumapps/lumx/react';

import { BlockStreamItem as BlockStreamItemType } from '../../types';
import { streamContextBannerBlockByResourceTypeAndAction } from '../BlockStreamContextBanner/resources';
import { streamResourceBlockByResourceType } from './resources';

import './block-stream-item.scss';

const CLASSNAME = 'block-stream-item';

export const BlockStreamItem: React.FC<BlockStreamItemType> = (props) => {
    const { block } = useClassnames(CLASSNAME);
    const { children, resourceType, userAction, theme } = props;

    const ResourceBlock = streamResourceBlockByResourceType[resourceType];
    const StreamContextBannerBlock =
        streamContextBannerBlockByResourceTypeAndAction[resourceType]?.[userAction?.action];

    if (!ResourceBlock && !isValidElement(children)) {
        return null;
    }

    return (
        <div className={block()}>
            {StreamContextBannerBlock && (
                <>
                    <StreamContextBannerBlock {...props} />
                    <Divider className="lumx-spacing-margin-top-big lumx-spacing-margin-bottom-big" theme={theme} />
                </>
            )}
            {ResourceBlock && <ResourceBlock {...props}>{children}</ResourceBlock>}
            {!ResourceBlock && <div className={block()}>{children}</div>}
        </div>
    );
};

BlockStreamItem.displayName = 'BlockStreamItem';
