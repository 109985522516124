export enum METADATA {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244409
     */
    METADATA_KEY = 'ADMIN.NAV.METADATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244424
     */
    CUSTOMER_METADATA = 'ADMIN.NAV.CUSTOMER_METADATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244424
     */
    NO_METADATA = 'METADATA.NO_METADATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438104675
     */
    LOADING_METADATA_ERROR = 'METADATA.LOADING_METADATA_ERROR',
}
