import React, { Fragment, Children } from 'react';

import { LoadMoreFocusHelper } from '@lumapps/a11y/components/LoadMoreFocusHelper';
import { margin, useClassnames, visuallyHidden } from '@lumapps/classnames';
import {
    Divider,
    Button,
    Size,
    Emphasis,
    FlexBox,
    Orientation,
    Alignment,
    ProgressCircular,
    ButtonProps,
    Text,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WidgetState } from '../../../ducks/type';
import { WIDGET_BASE } from '../../../keys';
import {
    BlockComponent,
    ContainerBlockVariant,
    BlockListOrientation,
    BlockList as BlockListType,
} from '../../../types';
import { WidgetContentProps } from '../../WidgetContent';
import { BlockListItem } from './BlockListItem';
import { BlockListWrapper } from './BlockListWrapper';

import './index.scss';

const CLASSNAME = 'block-list';

export type BlockListSize = number | typeof Size.medium | typeof Size.huge;

export interface BlockListProps extends BlockListType {
    className?: string;
    loadingState: WidgetState['state'];
    hasSeparator?: boolean;
    gapSize?: BlockListSize;
    /** The component that will be used as a loader when the list loads more items. Default: <ProgressCircular /> */
    loadingStateRenderer?: React.FC;
    /**
     * The pagination type to use.
     * If "infinite" is set, the "Feed" pattern will be applied
     */
    paginationType?: WidgetState['paginationType'];
    /**
     * Optional blockProperties passed down from a WidgetContent, that contains the ariaLabelledBy props
     * used to generate the aria-label of the Feed
     */
    blockProperties?: WidgetContentProps<{
        ariaLabelledBy: string;
    }>['blockProperties'];
    loadMoreButtonProps?: Partial<ButtonProps>;
}

export const BlockList: BlockComponent<BlockListProps> = ({
    children,
    className,
    orientation = BlockListOrientation.vertical,
    variant = ContainerBlockVariant.grouped,
    contentStyles,
    theme,
    onLoadMore,
    loadingState,
    hasSeparator = true,
    gapSize,
    loadingStateRenderer: LoadingStateRenderer = ProgressCircular,
    paginationType = 'load_more',
    blockProperties,
    maxItemsPerPage,
    loadMoreButtonProps,
}) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    let effectiveGapSize: string | undefined;
    if (gapSize && typeof gapSize === 'string') {
        // Use lumx Size variable.
        effectiveGapSize = `var(--lumx-spacing-unit-${gapSize})`;
    } else if (Number.isInteger(gapSize)) {
        // Use size in px.
        effectiveGapSize = `${gapSize}px`;
    }
    const blockListGap: any = effectiveGapSize && { '--block-list-gap': effectiveGapSize };

    const childrenArray = Children.toArray(children);

    return (
        <>
            <BlockListWrapper
                style={blockListGap}
                className={block(
                    {
                        horizontal: orientation === BlockListOrientation.horizontal,
                        vertical: orientation === BlockListOrientation.vertical,
                        grouped: variant === ContainerBlockVariant.grouped,
                        ungrouped: variant === ContainerBlockVariant.ungrouped,
                    },
                    [className],
                )}
                onLoadMore={onLoadMore}
                loadingState={loadingState}
                paginationType={paginationType}
                loadMoreButton={
                    <Button
                        style={blockListGap}
                        theme={theme}
                        className={element('load-more')}
                        emphasis={Emphasis.medium}
                        {...loadMoreButtonProps}
                        onClick={onLoadMore}
                    >
                        {translateKey(WIDGET_BASE.LOAD_MORE)}
                    </Button>
                }
                {...blockProperties}
            >
                {childrenArray.map((child, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {index > 0 &&
                            orientation === BlockListOrientation.vertical &&
                            variant === ContainerBlockVariant.grouped &&
                            hasSeparator && <Divider theme={theme} />}
                        <LoadMoreFocusHelper
                            itemIndex={index}
                            itemsDisplayedLength={childrenArray.length}
                            itemsPerPage={maxItemsPerPage}
                        />
                        <BlockListItem
                            paginationType={paginationType}
                            totalChildren={childrenArray.length}
                            index={index}
                            className={element('item')}
                            style={variant === ContainerBlockVariant.ungrouped ? contentStyles : undefined}
                        >
                            {child}
                        </BlockListItem>
                    </Fragment>
                ))}
            </BlockListWrapper>
            {loadingState === 'loadingmore' && (
                <FlexBox
                    orientation={Orientation.vertical}
                    vAlign={Alignment.center}
                    role="alert"
                    aria-live="polite"
                    className={margin('top', 'huge')}
                >
                    <Text as="p" className={visuallyHidden()}>
                        {translateKey(GLOBAL.LOADING)}
                    </Text>
                    <LoadingStateRenderer />
                </FlexBox>
            )}
        </>
    );
};
BlockList.displayName = 'BlockList';
