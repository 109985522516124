import React, { useEffect, useRef, useState } from 'react';

import { GroupChipPickerField } from '@lumapps/group-pickers/components/GroupChipPickerField';
import { Group } from '@lumapps/groups/types';
import { LEADERBOARD } from '@lumapps/leaderboard/keys';
import { LearningLeaderboardDisplayType } from '@lumapps/leaderboard/types';
import { RadioButton, RadioGroup } from '@lumapps/lumx/react';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { useTranslate } from '@lumapps/translations';

import { LearningLeaderboardSettingsProps } from '../../types';

export const LearningLeaderboardSettings: React.FC<LearningLeaderboardSettingsProps> = ({
    onChange,
    properties = {},
}) => {
    const { translateKey } = useTranslate();
    const [groups, setGroups] = useState<Group[]>(properties.groups || []);
    const groupsRef = useRef<Group[]>([]);
    const [isLegacyApp, setIslegacyApp] = useState<boolean>(false);
    const { displayType } = properties;
    const { isLegacySpace } = useSpace();

    useEffect(() => {
        groupsRef.current = groups;
    }, [groups]);

    useEffect(() => {
        setIslegacyApp(isLegacySpace);
        setGroups(groupsRef.current);
    }, [isLegacySpace]);

    const handleGroupSelected = (groups: Group[]) => {
        setGroups(groups);
        onChange({ groups, isLegacyApp });
    };

    const handleDisplaySelected = (displayType: LearningLeaderboardDisplayType) => {
        onChange({ displayType, isLegacyApp });
    };

    return (
        <div>
            {isLegacySpace && (
                <RadioGroup>
                    <RadioButton
                        isChecked={displayType === LearningLeaderboardDisplayType.CURRENT}
                        label={translateKey(LEADERBOARD.CURRENT)}
                        value={LearningLeaderboardDisplayType.CURRENT}
                        onChange={handleDisplaySelected}
                        name="selection-current"
                    />
                    <RadioButton
                        isChecked={displayType === LearningLeaderboardDisplayType.PICK}
                        label={translateKey(LEADERBOARD.PICK)}
                        value={LearningLeaderboardDisplayType.PICK}
                        onChange={handleDisplaySelected}
                        name="selection-pick"
                    />
                </RadioGroup>
            )}
            {displayType === LearningLeaderboardDisplayType.PICK && (
                <GroupChipPickerField
                    onGroupsSelected={(groups) => handleGroupSelected(groups)}
                    selectedGroups={groups}
                    shouldCloseOnSelect
                />
            )}
        </div>
    );
};
