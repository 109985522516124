import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { LinkPreviewType, OgTitle, OgDescription, OgImage } from '@lumapps/wrex/serialization/dita/types';

import type { LinkPreviewElement } from '../../types';
import { createLinkPreview } from '../../utils/createLinkPreview';

/**
 * Convert Dita Link Preview into slate Link Preview element.
 */
export const LINK_PREVIEW_CONVERT: ElementConvertOption<LinkPreviewType> = {
    test: matchAttribute('type', /lumapps:link-preview/),
    transform: (node): LinkPreviewElement => {
        const ogTitleNode = node.children.find(matchAttribute('name', /og:title/)) as OgTitle;
        const ogDescriptionNode = node.children.find(matchAttribute('name', /og:description/)) as OgDescription;
        const ogImageNode = node.children.find(matchAttribute('name', /og:image/)) as OgImage;

        return createLinkPreview({
            title: ogTitleNode?.attributes.value,
            thumbnailUrl: ogImageNode?.attributes.value,
            description: ogDescriptionNode?.attributes.value,
            url: node.attributes.href,
        });
    },
};
