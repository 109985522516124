import pickBy from 'lodash/pickBy';

import { BaseApiError, formatLanguageHeader, isCancel } from '@lumapps/base-api';
import { getServerErrorData } from '@lumapps/base-api/utils/getServerError';
import { isInDesignerMode } from '@lumapps/contents/ducks/selectors';
import { getAcceptedContributionLanguageHeader } from '@lumapps/languages';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { inputLocaleSelector } from '@lumapps/translations/selectors';

import { FetchBlockParams } from '../../api';
import { EDITABLE_WIDGET_LIST } from '../../constants';
import { isArrayContainerBlock, isSingleContainerBlock, Widget } from '../../types';
import { getUpdatedWidgetState } from '../../utils/getUpdatedWidgetState';
import { getWidgetLoadingState } from '../selectors';
import { actions } from '../slice';
import { WidgetState } from '../type';

/**
 * Fetch widget blocks and index in Redux store.
 *
 * @param widgetId         widget id.
 * @param fetchFunction    The api function used to fetch the blocks.
 * @param params           widget fetch blocks API params.
 * @param isMainWidget     whether the widget is the main one.
 * @param widgetType       type of the widget
 */
export const fetchWidgetBlocks =
    (
        widgetId: string,
        fetchFunction: (acceptLanguageHeader: string) => Promise<Widget>,
        params?: FetchBlockParams,
        isMainWidget?: boolean,
        widgetType?: string,
    ) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const currentState = getState();
        let loadingState: WidgetState['state'];

        const currentInputLang = inputLocaleSelector(currentState);
        const isInDesigner = isInDesignerMode(currentState);
        const acceptedLanguages = formatLanguageHeader([currentInputLang], false);

        const isEditableWidget = widgetType && isInDesigner && EDITABLE_WIDGET_LIST.includes(widgetType);

        /**
         * Getting all queryParams other than cursor and filters.
         */
        const blockResolutionInfo = pickBy(params, (_value, key) => key !== 'cursor' && key !== 'filters');

        if (params?.cursor) {
            loadingState = 'loadingmore';
        } else if (params?.filters && getWidgetLoadingState(currentState, { widgetId })) {
            // we do not want to do this when filters are initialized before first complete load (no loading state)
            loadingState = 'loadingcontent';
        } else {
            loadingState = 'loading';
        }

        dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: loadingState } }));

        const acceptLanguageHeader = isEditableWidget
            ? acceptedLanguages
            : getAcceptedContributionLanguageHeader(currentState);

        try {
            const widget = await fetchFunction(acceptLanguageHeader);
            const state = getUpdatedWidgetState(widget);

            if (loadingState === 'loading' && state !== 'loaded') {
                // Widget not correct loaded (first load): update only the state.
                dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state } }));
                return;
            }

            if (params?.cursor) {
                // Widget is loading more items in root container block: appending the list and updating the state.
                if (
                    widget.body &&
                    isSingleContainerBlock(widget.body) &&
                    isArrayContainerBlock(widget.body.container)
                ) {
                    dispatch(
                        actions.appendWidgetPage({
                            widgetId,
                            nextItems: widget.body.container.items,
                            cursor: widget.cursor,
                            more: !!widget.more,
                        }),
                    );
                } else if (widget.body && isArrayContainerBlock(widget.body)) {
                    dispatch(
                        actions.appendWidgetPage({
                            widgetId,
                            nextItems: widget.body.items,
                            cursor: widget.cursor,
                            more: !!widget.more,
                        }),
                    );
                }
                dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'loaded' } }));
            } else if (params?.filters && loadingState === 'loadingcontent') {
                // Widget is loading new replacing content: replace the widget body and update the state.
                dispatch(
                    actions.setWidgetProperties({
                        widgetId,
                        widgetProperties: {
                            body: widget.body,
                            more: widget.more,
                            state: 'loaded',
                            cursor: widget.cursor,
                            blockResolutionInfo,
                            isMainWidget,
                        },
                    }),
                );
            } else {
                // Widget loaded fully: replace in state.
                dispatch(actions.setWidget({ ...widget, blockResolutionInfo, isMainWidget }));
            }
        } catch (error) {
            /* istanbul ignore next */
            if (isCancel(error)) {
                // Widget block request canceled.
                dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'cancelled' } }));
                return;
            }

            if (error instanceof BaseApiError && error.response?.status === 400) {
                if (getServerErrorData(error.response)?.code === 'WIDGET_UNSUPPORTED_SETTINGS') {
                    // Widget block request not migrated.
                    dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'notmigrated' } }));
                }
                return;
            }

            if ((error as BaseApiError)?.response?.status === 403) {
                // Widget block request restricted.
                dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'restricted' } }));
                return;
            }

            // Widget block request errored.
            dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'error' } }));
            throw error;
        }
    };
