import React from 'react';

import { classnames } from '@lumapps/classnames';
import {
    SideNavigationItem as NavigationItem,
    SideNavigationItemProps as NavigationProps,
    Emphasis,
} from '@lumapps/lumx/react';
import { Route, useRouteMatching, useLinkProps } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { useSideBarNavigationDataAttributes } from '../../../hooks/useSideBarNavigationDataAttributes';
import { SideNavigationMenu } from './SideNavigationMenu';

import './index.scss';

export interface SideNavigationItemProps extends Omit<NavigationProps, 'toggleButtonProps'> {
    label: string;
    className?: string;
    dynamicIcon?: string;
    route?: Route;
    id?: string;
    openInNewTab?: boolean;
    /**
     * Path match pattern (eg, /site/:slug) to ensure the nav item
     * is still highlighted on subroutes match
     */
    pathMatchForHighlighting?: string;
}

export const CLASSNAME = 'side-navigation-item';
/**
 * Side Navigation Item that can display a single item or a section with multiple links
 *
 * @family Back office
 * @param SideNavigationItemProps
 * @returns SideNavigationItem
 */
const SideNavigationItem: React.FC<SideNavigationItemProps> = ({
    className,
    children,
    isOpen = false,
    route,
    dynamicIcon = '',
    linkProps = {},
    icon,
    pathMatchForHighlighting,
    openInNewTab = false,
    ...props
}) => {
    const { translateKey } = useTranslate();
    const isCurrentRoute = useRouteMatching(route, pathMatchForHighlighting);
    const { iconPath } = useDynamicIcon({ icon: dynamicIcon });
    const dataAttributes = useSideBarNavigationDataAttributes({ route });
    const { onClick, ...routerLinkProps } = useLinkProps({ route, target: linkProps.target, openInNewTab });

    const classname = classnames(CLASSNAME, className);
    const iconToUse = dynamicIcon ? iconPath : icon || undefined;
    /**
     * We check whether there are more navigation items passed in as children in order to determine
     * whether we need to add the open/close behaviour or not. If the prop is defined, it means that
     * we need to display more links on this section and we need to allow the user to open and close
     * the section to see the other links, therefore rendering the SideNavigationMenu component
     */
    const hasChildren = Boolean(children);

    // If the item in only a container, do not display it when there are no children
    if (!hasChildren && !routerLinkProps.href) {
        return null;
    }

    if (hasChildren) {
        return (
            <SideNavigationMenu
                className={className}
                baseClassName={CLASSNAME}
                isOpen={isOpen}
                icon={iconToUse}
                {...props}
            >
                {children}
            </SideNavigationMenu>
        );
    }

    return (
        <NavigationItem
            {...props}
            className={classname}
            emphasis={Emphasis.medium}
            isSelected={isCurrentRoute}
            linkProps={{
                ...linkProps,
                ...routerLinkProps,
            }}
            icon={iconToUse}
            toggleButtonProps={{ label: translateKey(GLOBAL.TOGGLE) }}
            {...dataAttributes}
            onClick={onClick}
        />
    );
};

SideNavigationItem.displayName = 'SideNavigationItem';

export { SideNavigationItem };
