/* eslint-disable capitalized-comments */
/* eslint-disable lumapps/comments-sentences */
import produce from 'immer';

import {
    FETCH_CHANNELS_FOR_GROUP_ERROR,
    FETCH_CHANNELS_FOR_GROUP_LOADING,
    FETCH_CHANNELS_FOR_GROUP_SUCCESS,
    FETCH_JOINED_GROUPS_ERROR,
    FETCH_JOINED_GROUPS_LOADING,
    FETCH_JOINED_GROUPS_SUCCESS,
    FETCH_MESSAGES_FOR_CHANNEL_ERROR,
    FETCH_MESSAGES_FOR_CHANNEL_LOADING,
    FETCH_MESSAGES_FOR_CHANNEL_SUCCESS,
    FETCH_GROUPS_ERROR,
    FETCH_GROUPS_LOADING,
    FETCH_GROUPS_SUCCESS,
    CREATE_MESSAGE_FOR_CHANNEL_ERROR,
    CREATE_MESSAGE_FOR_CHANNEL_LOADING,
    CREATE_MESSAGE_FOR_CHANNEL_SUCCESS,
    FETCH_USER_PHOTO_ERROR,
    FETCH_USER_PHOTO_LOADING,
    FETCH_USER_PHOTO_SUCCESS,
    FETCH_PROVIDERS_ERROR,
    FETCH_PROVIDERS_LOADING,
    FETCH_PROVIDERS_SUCCESS,
    GET_CHANNEL_BY_ID_ERROR,
    GET_CHANNEL_BY_ID_LOADING,
    GET_CHANNEL_BY_ID_SUCCESS,
    GET_GROUP_BY_ID_ERROR,
    GET_GROUP_BY_ID_LOADING,
    GET_GROUP_BY_ID_SUCCESS,
    HAS_USER_JOINED_CHANNEL_ERROR,
    HAS_USER_JOINED_CHANNEL_LOADING,
    HAS_USER_JOINED_CHANNEL_SUCCESS,
    SET_LOADING,
    SET_PAGINATION_LOADING,
    SET_SIZE,
    SET_OPEN,
    SET_SELECTED_THREAD,
    SET_MODEL,
    SET_SHOW,
    SET_LAST_CLOSED,
    SET_CREATE_MESSAGE_LOADING,
    SET_NOTIFICATION_COUNT,
    SET_EMOJI_OPEN,
    SET_CHANNEL_URL,
    SET_PHOTO_REQUESTED,
} from './chat_actions';

// Default state.
const defaultState = {
    // components: {
    //     <dynamic-key>: {
    //         isLoading: true,
    //         isEmojiEditorOpened; false,
    //         isPaginationLoading: false,
    //         model: null,
    //         isOpen: false,
    //         size: null,
    //         selectedThread: null,
    //     },
    // },
    // providers: {
    //     <dynamic-key>: {
    //         userSpaces: {
    //             <dynamic-key>: {
    //                 channels: {
    //                     <dynamic-key>: {
    //                         messages: {
    //                             channelId: null,
    //                             cursor: null,
    //                             replies: {
    //                                  <dynamic-key>: {
    //                                  items: [],
    //                                  more: false,
    //                                  page: 0,
    //                                  },
    //                             },
    //                             items: [],
    //                             more: false,
    //                             page: 0,
    //                             provider: null,
    //                             spaceId: null,
    //                         },
    //                     },
    //                     items: [],
    //                     more: false,
    //                     page: 0,
    //                     provider: null,
    //                     spaceId: null,
    //                 },
    //             },
    //             items: [],
    //             more: false,
    //             page: 0,
    //             photos: null,
    //             provider: null,
    //         },
    //     },
    // },
};

/**
 * Init the needed variables for the reducer.
 *
 * @param  {Object} action Request action containing payload.
 * @param  {Object} state  Current state of the store.
 * @return {Object} Returns needed variables.
 */
const init = ({ action, state }) => {
    const meta = action.meta || {};
    const payload = action.payload || {};
    const stateProvider = (state.providers && state.providers[meta.provider]) || {};
    const stateUserSpace = (stateProvider.userSpaces && stateProvider.userSpaces[meta.spaceId]) || {};
    const stateChannel = (stateUserSpace.channels && stateUserSpace.channels[meta.channelId]) || {};
    const stateComponent = (state.components && state.components[payload.uuid]) || {};
    const stateMessages = stateChannel.messages || {};
    const stateItems = stateMessages.items || [];
    const stateReplies = stateMessages.replies || {};
    const statePhotos = stateProvider.photos || {};
    const providers = {};
    const userSpaces = {};
    const channels = {};
    const photos = {};
    const replies = {};
    const components = {};
    const userTokenStatus = {};
    const userJoinedStatus = {};

    return {
        channels,
        components,
        meta,
        userTokenStatus,
        userJoinedStatus,
        payload,
        photos,
        providers,
        replies,
        stateChannel,
        stateComponent,
        stateItems,
        stateMessages,
        statePhotos,
        stateProvider,
        stateReplies,
        stateUserSpace,
        userSpaces,
    };
};

const mergeMessagesUp = (itemsToAdd, exisitngItems) => {
    /** Merge new messages before older messages. */
    const existingIds = exisitngItems.map((item) => item.id);

    /** Do not merge existing messages */
    const newItems = itemsToAdd.filter((item) => !existingIds.includes(item.id));
    if (newItems.length > 0) {
        return newItems.concat(exisitngItems);
    }

    return exisitngItems;
};

const mergeMessagesDown = (itemsToAdd, exisitngItems) => {
    /** Merge new messages after newer messages. */
    const existingIds = exisitngItems.map((item) => item.id);

    /** Do not merge existing messages */
    const newItems = itemsToAdd.filter((item) => !existingIds.includes(item.id));
    if (newItems.length > 0) {
        return exisitngItems.concat(newItems);
    }

    return exisitngItems;
};

const reducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_GROUPS_LOADING:
                Object.assign(draft, state);

                break;
            case FETCH_GROUPS_SUCCESS: {
                const { payload, meta, stateProvider, providers } = init({ action, state });

                let stateUserSpaces = [];
                if (payload.page > 1) {
                    stateUserSpaces = (stateProvider.userSpaces && stateProvider.userSpaces.items) || [];
                }

                if (!payload.items) {
                    payload.items = [];
                }

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...payload,
                        items: [...stateUserSpaces, ...payload.items],
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case FETCH_GROUPS_ERROR:
                break;
            case FETCH_JOINED_GROUPS_LOADING:
                Object.assign(draft, state);

                break;
            case FETCH_JOINED_GROUPS_SUCCESS: {
                const { payload, meta, stateProvider, providers } = init({ action, state });

                let stateUserSpaces = [];
                if (payload.page > 1) {
                    stateUserSpaces = (stateProvider.userSpaces && stateProvider.userSpaces.items) || [];
                }

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...payload,
                        items: [...stateUserSpaces, ...payload.items],
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case FETCH_JOINED_GROUPS_ERROR:
                break;
            case FETCH_MESSAGES_FOR_CHANNEL_LOADING:
                Object.assign(draft, state);

                break;
            case FETCH_MESSAGES_FOR_CHANNEL_SUCCESS: {
                const {
                    channels,
                    userSpaces,
                    payload,
                    providers,
                    replies,
                    meta,
                    stateChannel,
                    stateUserSpace,
                    stateItems,
                    stateMessages,
                    stateProvider,
                    stateReplies,
                } = init({ action, state });

                if (meta.chatMessageId) {
                    /** Get replies to a thread. */
                    if (payload.page > 1 || meta.loadMore) {
                        /** Inifinite scroll management */
                        const existingReply = stateReplies[meta.chatMessageId];

                        payload.items = mergeMessagesUp(payload.items, existingReply.items);
                        replies[meta.chatMessageId] = payload;

                        channels[meta.channelId] = {
                            ...stateChannel,
                            messages: {
                                ...stateMessages,
                                replies: {
                                    ...stateReplies,
                                    ...replies,
                                },
                            },
                        };
                    } else if (!stateReplies[meta.chatMessageId]) {
                        /** Case of first load of the replies */
                        replies[meta.chatMessageId] = payload;

                        channels[meta.channelId] = {
                            ...stateChannel,
                            messages: {
                                ...stateMessages,
                                replies: {
                                    ...replies,
                                },
                            },
                        };
                    } else {
                        /** Case of polling */
                        const existingReply = stateReplies[meta.chatMessageId];

                        stateReplies[meta.chatMessageId].items = mergeMessagesDown(payload.items, existingReply.items);

                        channels[meta.channelId] = {
                            ...stateChannel,
                            messages: {
                                ...stateMessages,
                                replies: {
                                    ...stateReplies,
                                },
                            },
                        };
                    }
                } else if (payload.page > 1 || meta.loadMore) {
                    /* * Get next page. */
                    channels[meta.channelId] = {
                        ...stateChannel,
                        messages: {
                            page: 2, // Force a page in case of slack to keep cursor if fetch more
                            ...stateMessages,
                            ...payload,
                            items: [...payload.items, ...stateItems],
                        },
                    };
                } else {
                    /** Polling. */
                    if (stateItems && payload.items) {
                        payload.items = mergeMessagesDown(payload.items, stateItems);
                    }

                    if (stateMessages.page && stateMessages.page > 1) {
                        channels[meta.channelId] = {
                            ...stateChannel,
                            messages: {
                                ...stateMessages,
                                items: [...payload.items],
                            },
                        };
                    } else {
                        channels[meta.channelId] = {
                            ...stateChannel,
                            messages: {
                                ...stateMessages,
                                ...payload,
                            },
                        };
                    }
                }

                userSpaces[meta.spaceId] = {
                    ...stateUserSpace,
                    channels: {
                        ...stateUserSpace.channels,
                        ...channels,
                    },
                };

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...userSpaces,
                    },
                };

                return Object.assign(draft, {
                    ...state,
                    providers: {
                        ...state.providers,
                        ...providers,
                    },
                });
            }
            case FETCH_MESSAGES_FOR_CHANNEL_ERROR:
                break;
            case FETCH_CHANNELS_FOR_GROUP_LOADING:
                Object.assign(draft, { ...state, ...defaultState });

                break;
            case FETCH_CHANNELS_FOR_GROUP_SUCCESS: {
                const { userSpaces, payload, providers, meta, stateUserSpace, stateProvider } = init({ action, state });

                let stateChannels = [];
                if (payload.page > 1) {
                    stateChannels = (stateUserSpace.channels && stateUserSpace.channels.items) || [];
                }

                userSpaces[meta.spaceId] = {
                    ...stateUserSpace,
                    channels: {
                        ...stateUserSpace.channels,
                        ...payload,
                        items: [...stateChannels, ...payload.items],
                    },
                };

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...userSpaces,
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case FETCH_CHANNELS_FOR_GROUP_ERROR:
                break;
            case CREATE_MESSAGE_FOR_CHANNEL_LOADING:
            case CREATE_MESSAGE_FOR_CHANNEL_SUCCESS:
                Object.assign(draft, state);

                break;
            case CREATE_MESSAGE_FOR_CHANNEL_ERROR:
                break;
            case FETCH_USER_PHOTO_LOADING:
                Object.assign(draft, state);

                break;
            case FETCH_USER_PHOTO_SUCCESS: {
                const { payload, photos, providers, meta, statePhotos, stateProvider } = init({ action, state });

                photos[payload.content.id] = payload;

                providers[meta.provider] = {
                    ...stateProvider,
                    photos: {
                        ...statePhotos,
                        ...photos,
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case FETCH_USER_PHOTO_ERROR:
                break;
            case FETCH_PROVIDERS_LOADING:
                Object.assign(draft, state);

                break;
            case FETCH_PROVIDERS_SUCCESS: {
                const { payload } = action;

                return Object.assign(draft, { ...state, items: payload });
            }
            case FETCH_PROVIDERS_ERROR:
                break;
            case GET_GROUP_BY_ID_LOADING: {
                const { payload, providers, meta, stateProvider } = init({ action, state });

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...payload,
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case GET_GROUP_BY_ID_SUCCESS: {
                const { payload, providers, meta, stateProvider } = init({ action, state });

                const stateUserSpaceItems = (stateProvider.userSpaces && stateProvider.userSpaces.items) || [];
                stateUserSpaceItems.push(payload.space);

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...payload,
                        items: [...stateUserSpaceItems],
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case GET_GROUP_BY_ID_ERROR:
                break;
            case GET_CHANNEL_BY_ID_LOADING:
                Object.assign(draft, { ...state, ...defaultState });

                break;
            case GET_CHANNEL_BY_ID_SUCCESS: {
                const { userSpaces, payload, providers, meta, stateUserSpace, stateProvider } = init({ action, state });

                const stateChannels = (stateUserSpace.channels && stateUserSpace.channels.items) || [];
                stateChannels.push(payload.channel);

                userSpaces[meta.spaceId] = {
                    ...stateUserSpace,
                    channels: {
                        ...stateUserSpace.channels,
                        ...payload,
                        items: [...stateChannels],
                    },
                };

                providers[meta.provider] = {
                    ...stateProvider,
                    userSpaces: {
                        ...stateProvider.userSpaces,
                        ...userSpaces,
                    },
                };

                return Object.assign(draft, { ...state, providers: { ...state.providers, ...providers } });
            }
            case GET_CHANNEL_BY_ID_ERROR:
                break;
            case HAS_USER_JOINED_CHANNEL_ERROR:
                break;
            case HAS_USER_JOINED_CHANNEL_LOADING:
                Object.assign(draft, { ...state, ...defaultState });
                break;
            case HAS_USER_JOINED_CHANNEL_SUCCESS: {
                const { userJoinedStatus, meta, payload } = init({ action, state });

                const stateUserJoinedStatus = userJoinedStatus || {};
                const stateUserJoinedProvider = (stateUserJoinedStatus && stateUserJoinedStatus[meta.provider]) || {};
                const stateUserJoinedUserSpace =
                    (stateUserJoinedProvider && stateUserJoinedProvider[meta.spaceId]) || {};
                stateUserJoinedProvider[meta.spaceId] = stateUserJoinedUserSpace;
                stateUserJoinedUserSpace[meta.channelId] = payload.userHasJoined;

                stateUserJoinedStatus[meta.provider] = {
                    ...stateUserJoinedProvider,
                };

                return Object.assign(draft, {
                    ...state,
                    userJoinedStatus: { ...state.userJoinedStatus, ...stateUserJoinedStatus },
                });
            }
            case SET_CREATE_MESSAGE_LOADING:
            case SET_NOTIFICATION_COUNT:
            case SET_LAST_CLOSED:
            case SET_SHOW:
            case SET_MODEL:
            case SET_SELECTED_THREAD:
            case SET_OPEN:
            case SET_SIZE:
            case SET_PAGINATION_LOADING:
            case SET_LOADING:
            case SET_EMOJI_OPEN:
            case SET_CHANNEL_URL:
            case SET_PHOTO_REQUESTED: {
                const { components, payload, stateComponent } = init({ action, state });

                components[payload.uuid] = {
                    ...stateComponent,
                    ...payload,
                };

                return Object.assign(draft, { ...state, components: { ...state.components, ...components } });
            }

            default:
                return state;
        }

        return draft;
    });

export { reducer };
