import { arrayOf, objectOf, oneOfType, number, shape, string } from 'prop-types';

const translatedText = oneOfType([objectOf(string), string]);

const valueObjectType = shape({
    id: oneOfType([string, number]),
    name: translatedText.isRequired,
    uid: string,
});

const choicesType = arrayOf(oneOfType([valueObjectType]));

const groupedChoicesType = arrayOf(
    shape({
        children: choicesType,
        group: shape({}),
    }),
);

export { translatedText, valueObjectType, choicesType, groupedChoicesType };
