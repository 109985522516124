function ChatFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/chat/:element/:methodKey`,
        {},
        {
            getChannelById: {
                isArray: false,
                method: 'GET',
                params: {
                    element: 'channels',
                    methodKey: 'get',
                },
            },
            getChannelByName: {
                isArray: false,
                method: 'GET',
                params: {
                    element: 'channels',
                    methodKey: 'getByName',
                },
            },
            listChannelsForUserSpace: {
                isArray: false,
                method: 'POST',
                params: {
                    element: 'channels',
                    methodKey: 'list',
                },
            },
            listProviders: {
                isArray: false,
                method: 'GET',
                params: {
                    element: 'providers',
                    methodKey: 'list',
                },
            },
            listUserSpaces: {
                isArray: false,
                method: 'POST',
                params: {
                    element: 'userSpaces',
                    methodKey: 'list',
                },
            },
        },
    );
}

angular.module('Factories').factory('ChatFactory', ChatFactory);

/////////////////////////////

export { ChatFactory };
