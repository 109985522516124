import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import { METADATA_SCOPES } from './constants';

export interface AdminPageParams {
    section?: METADATA_SCOPES;
}

export const metadataRoutesMatch = '/admin/metadata/:slug';
export const metadataBaseRoute: Route = {
    path: '/admin/metadata',
    appId: AppId.backOffice,
    legacyId: 'app.admin.metadata',
};

export const metadataAdminPageRoute: Route = {
    ...metadataBaseRoute,
    path: `${metadataBaseRoute.path}/:section?`,
};

export const metadataAdminSectionRoute = (params: AdminPageParams): Route => {
    return {
        ...metadataAdminPageRoute,
        params: {
            section: params.section,
        },
    };
};
