import { mdiCalendar, mdiHelp, mdiLightbulbOutline, mdiMessageTextOutline } from '@lumapps/lumx/icons';
import { Image } from '@lumapps/wrex-image/components/blocks/Image';
import { Link } from '@lumapps/wrex-link/components/blocks/Link';
import { LINK_FEATURES } from '@lumapps/wrex-link/constants';
import { ListItem } from '@lumapps/wrex-list/components/blocks/ListItem';
import { OrderedList } from '@lumapps/wrex-list/components/blocks/OrderedList';
import { UnorderedList } from '@lumapps/wrex-list/components/blocks/UnorderedList';
import { LIST_FEATURES } from '@lumapps/wrex-list/constants';
import { QuoteBlock } from '@lumapps/wrex-quote/components/blocks/QuoteBlock';
import { Table, TD, TR } from '@lumapps/wrex-table/components/blocks';
import { CodeBlock } from '@lumapps/wrex-typography/components/blocks/CodeBlock';
import { Headline } from '@lumapps/wrex-typography/components/blocks/Headline';
import { Bold, Italic, Strikethrough, Underline } from '@lumapps/wrex-typography/components/blocks/Mark';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';
import { Subtitle } from '@lumapps/wrex-typography/components/blocks/Subtitle';
import { Title } from '@lumapps/wrex-typography/components/blocks/Title';
import { TYPOGRAPHY_FEATURES } from '@lumapps/wrex-typography/constants';
import { UserMention } from '@lumapps/wrex-user-mention/components/blocks/UserMention';

import { POSTS } from './keys';
import { PostType, RefreshEvent } from './types';

export const PACKAGE_PREFIX = 'posts';

/** Maps PostType to mdi icon */
export const POST_TYPE_ICONS: Record<PostType, string> = {
    [PostType.DEFAULT]: mdiMessageTextOutline,
    [PostType.EVENT]: mdiCalendar,
    [PostType.QUESTION]: mdiHelp,
    [PostType.IDEA]: mdiLightbulbOutline,
};

/** Maps PostType to translation keys. */
export const POST_TYPE_LABEL_KEYS: Record<PostType, string> = {
    [PostType.DEFAULT]: POSTS.POST_TYPE,
    [PostType.IDEA]: POSTS.POST_TYPE_IDEA,
    [PostType.EVENT]: POSTS.POST_TYPE_EVENT,
    [PostType.QUESTION]: POSTS.POST_TYPE_QUESTION,
};

/** Maps PostType to tooltip translation keys. */
export const POST_TYPE_TOOLTIP_KEYS: Record<PostType, string> = {
    [PostType.DEFAULT]: '',
    [PostType.IDEA]: POSTS.POST_CREATE_TYPE_IDEA,
    [PostType.EVENT]: POSTS.POST_CREATE_TYPE_EVENT,
    [PostType.QUESTION]: POSTS.POST_CREATE_TYPE_QUESTION,
};

export const MAX_TAGZ_NUMBER = 20;

/** API fields fetched before editing a post */
export const POST_EDIT_FIELDS =
    'uid,eventStartDate,eventEndDate,title,content,postType,images,postStatusDetails,files,links,authorDetails(uid,customer,firstName,lastName),content,customer,instance,mentions,createdAt,parentContentDetails(postTypes,postStatuses,securedRepository),version,id,tags,tagz,postStatus,tagsDetails,externalKey,mentionsDetails(uid,apiProfile(name(fullName,givenName,familyName)))';

export const REFRESH_EVENTS: Record<string, RefreshEvent> = {
    POST_UNPIN: 'unpin',
    POST_PIN: 'pin',
    POST_DELETE: 'delete',
    POST_MOVE: 'move',
    POST_SHARE: 'share',
    POST_UNSHARE: 'unshare',
    COMMENT_ADD: 'add',
    COMMENT_HIDE: 'hide',
    COMMENT_MARK_RELEVANT: 'mark',
    COMMENT_UNMARK_RELEVANT: 'unmark',
};

const STARTING_BREAKPOINT = 270;
const BUTTON_SIZE = 36;
const DIVIDER_SIZE = 9; // 1px + 2x4px margin
/**
 * Defines the post editor toolbar breakpoints according to a button rounded width.
 */
export const POST_EDITOR_TOOLBAR_BREAKPOINTS = {
    0: 0,
    1: STARTING_BREAKPOINT + BUTTON_SIZE + DIVIDER_SIZE,
    2: STARTING_BREAKPOINT + BUTTON_SIZE * 2 + DIVIDER_SIZE,
    3: STARTING_BREAKPOINT + BUTTON_SIZE * 3 + DIVIDER_SIZE,
    4: STARTING_BREAKPOINT + BUTTON_SIZE * 4 + DIVIDER_SIZE * 2,
    5: STARTING_BREAKPOINT + BUTTON_SIZE * 5 + DIVIDER_SIZE * 3,
    6: STARTING_BREAKPOINT + BUTTON_SIZE * 6 + DIVIDER_SIZE * 3,
    7: STARTING_BREAKPOINT + BUTTON_SIZE * 7 + DIVIDER_SIZE * 4,
    8: STARTING_BREAKPOINT + BUTTON_SIZE * 8 + DIVIDER_SIZE * 4,
};

/**
 * Defines the post editor toolbar number of buttons to display.
 */
export const POST_EDITOR_TOOLBAR_BUTTONS: Record<keyof typeof POST_EDITOR_TOOLBAR_BREAKPOINTS, number> = {
    0: 0,
    1: 3, // Nothing but the undo/redo + block type selector + divider
    2: 4, // Bold
    3: 5, // Italic
    4: 7, // Link plugin + divider
    5: 9, // List + divider
    6: 10, // emoji plugin
    7: 12, // Image plugin + divider
    8: 13, // Video attachment
};

export const DISABLE_QUICK_POST_FF = 'disable-quick-post';
export const POST_ANALYTICS_FF = 'post-analytics';

export const MAX_SYMBOLS_IN_POST = 1300;

/** List of the plugins enabled features for the post editor */
export const postEditorEnabledFeatures = {
    typography: [
        TYPOGRAPHY_FEATURES.bold,
        TYPOGRAPHY_FEATURES.italic,
        TYPOGRAPHY_FEATURES.headline,
        TYPOGRAPHY_FEATURES.title,
        TYPOGRAPHY_FEATURES.codeBlock,
    ],
    list: [LIST_FEATURES.unorderedList],
    link: [LINK_FEATURES.linkPreviewConversion],
};

/**
 * Post analytics impression threshold: define what percentage of a post in the viewport is considered as "seen"
 * Example: 0.5 = 50%
 */
export const POST_IMPRESSION_THRESHOLD = 0.5;

/** List of post Wrex elements */
export const POST_WREX_ELEMENTS = [
    Paragraph,
    Headline,
    Title,
    Subtitle,
    Link,
    Image,
    UnorderedList,
    OrderedList,
    ListItem,
    Table,
    TR,
    TD,
    QuoteBlock,
    UserMention,
    CodeBlock,
];

/** List of post Wrex marks */
export const POST_WREX_MARKS = [Bold, Italic, Underline, Strikethrough];
