import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const adminMainNavigationRoutes: Route = {
    path: '/admin/navigation',
    appId: AppId.backOffice,
    legacyId: 'app.admin.navigation',
};

export const adminMainNavigation = () => adminMainNavigationRoutes;
