import { UseLinkProps } from '@lumapps/router/hooks/useLinkProps';
import { ENGINES } from '@lumapps/search/constants';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants/types';
import { BaseSearchResult, FeaturesStatus, SearchFilter, SearchFilterExtension } from '@lumapps/search/types';
import { TranslationAPI } from '@lumapps/translations';

import { props as articleProps } from '../components/ArticleResult/props';
import { props as communityProps } from '../components/CommunityResult/props';
import { props as directoryProps } from '../components/DirectoryResult/props';
import { props as genericDocumentProps } from '../components/GenericDocumentResult/props';
import { getProps as genericProps } from '../components/GenericResult/props';
import { props as calendarProps } from '../components/GoogleResults/CalendarResult/props';
import { props as defaultProps } from '../components/GoogleResults/DefaultResult/props';
import { props as thirdPartyProps } from '../components/GoogleResults/ThirdPartyResult/props';
import { props as lumworkProps } from '../components/LumWorkResult/props';
import { props as mediaProps } from '../components/MediaResult/props';
import { props as postProps } from '../components/PostResult/props';
import { getComponent } from '../components/SearchResults/results';
import { props as userProps } from '../components/UserResult/props';
import { props as zendeskProps } from '../components/ZendeskResult/props';

const components = {
    default: genericProps,

    article: articleProps,
    articles: articleProps,

    community: communityProps,
    communities: communityProps,

    media: mediaProps,

    user: userProps,
    users: userProps,

    directory_entries: directoryProps,
    directoryEntry: directoryProps,

    post: postProps,
    posts: postProps,

    '3p': thirdPartyProps,
    calendar: calendarProps,
    google: defaultProps,
    genericDocument: genericDocumentProps,

    [ENGINES.DRIVE]: defaultProps,
    [ENGINES.ZENDESK]: zendeskProps,
    [ENGINES.LUMWORK]: lumworkProps,
    [ENGINES.SHAREPOINT]: mediaProps,
};

export type GetEntityPropsParams = {
    item: BaseSearchResult;
    currentInstanceId: string;
    searchQuery?: string;
    searchQueryUid?: string;
    currentLanguage: string;
    translate: TranslationAPI['translate'];
    translateKey?: TranslationAPI['translateKey'];
    features: FeaturesStatus;
    userUrl?: UseLinkProps;
    shouldAddBaseSlug?: boolean;
    isUserConnected?: boolean;
    totalCount?: number;
    selectedTab?: SearchFilter | SearchFilterExtension;
    extensionId?: string;
    searchEngine: string | null;
};

export const getEntityProps = ({
    item,
    currentInstanceId,
    features,
    shouldAddBaseSlug,
    translate,
    translateKey,
    userUrl,
    isUserConnected,
    currentLanguage,
    searchQuery = '',
    searchQueryUid = '',
    selectedTab,
    totalCount,
    searchEngine,
    extensionId,
}: GetEntityPropsParams) => {
    const baseProps = genericProps(
        item,
        currentInstanceId,
        searchQuery,
        searchQueryUid,
        currentLanguage,
        features,
        searchEngine,
        userUrl,
        totalCount,
        selectedTab,
        extensionId,
        isUserConnected,
    );
    const component = getComponent(item, {}, ENGINES.LUMAPPS, components);

    if (component === components.default) {
        return {
            id: baseProps.id as string,
            title: baseProps.name,
            type: SEARCH_RESULT_TYPES.GENERIC,
            item,
            originSiteId: baseProps.originSiteId,
        };
    }

    const result = component(item, baseProps, {
        translate,
        translateKey,
        features,
        currentInstanceId,
        shouldAddBaseSlug,
        fromBackOffice: false,
    });

    return {
        id: result.id,
        thumbnail: result.thumbnail,
        thumbnailVariant: result.thumbnailVariant,
        icon: result.iconName,
        title: result.name,
        subtitle: result.kind,
        type: result.type as SEARCH_RESULT_TYPES,
        link: result.url,
        breadcrumbLevels: result.breadcrumbLevels,
        kind: result.kind,
        isFromCurrentInstance: result.isFromCurrentInstance,
        hideKindInBreadcrumb: result.hideKindInBreadcrumb,
        item,
        originSiteId: result.originSiteId,
    };
};
