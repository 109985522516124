import React, { FormEventHandler, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import { margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiArrowRight } from '@lumapps/lumx/icons';
import { Button, Emphasis, FlexBox, Link, Theme, Typography } from '@lumapps/lumx/react';
import { MetadataFilter } from '@lumapps/metadata-pickers/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { ENGINES } from '@lumapps/search/constants';
import { getSuggestionEngine } from '@lumapps/search/ducks/selectors';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import {
    isNGIContentFiltersGlobalSearchFFEnabled as isNGIContentFiltersGlobalSearchFFEnabledSelector,
    isNGIContentFiltersFFEnabled as isNGIContentFiltersFFEnabledSelector,
} from '@lumapps/widget-base/ducks/selectors';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';
import { WIDGET_CONTENT_LIST_TYPE } from '@lumapps/widget-content-list/constants';
import { WIDGET_DIRECTORY_ENTRIES_TYPE } from '@lumapps/widget-directory-entries/constants';

import { CONTENT_FILTER_SUPPORTED_WIDGETS } from '../../constants';
import { useContentFilters } from '../../hooks/useContentFilters';
import { useGetSearchURL } from '../../hooks/useGetSearchURL';
import { WIDGET_CONTENT_FILTER } from '../../keys';
import {
    FormattedAuthorFilter,
    FormattedPublicationDates,
    FormattedSiteFilter,
    FormattedTagFilter,
    LegacyContentFilterProperties,
    NGIFilterId,
} from '../../types';
import { AuthorContentFilter } from '../AuthorContentFilter';
import { CustomContentTypeTagsFilter } from '../CustomContentTypeTagsFilter';
import { FollowThisInterest } from '../FollowThisInterest';
import { HighlightedContentFilter } from '../HighlightedContentFilter';
import { KeywordContentFilter } from '../KeywordContentFilter';
import { MetadataContentFilter } from '../MetadataContentFilter';
import { PublicationDatesContentFilter } from '../PublicationDatesContentFilter';
import { SitesContentFilter } from '../SitesContentFilter';
import { SkeletonFilter } from '../SkeletonFilter';
import { TagsFromDirectoriesFilter } from '../TagsFromDirectoriesFilter';

interface ClientComputedContentFilterProps extends ClientComputedProps {
    properties?: LegacyContentFilterProperties;
}

export const SCOPE = 'content-filter';
const LOADING_SKELETON_QUANTITY = 5;

/**
 * The Content Filter widget. Used to filter a list widget flagged as main widget.
 * Will be displayed only with ngi-content-filter FF.
 */
export const ClientComputedContentFilter: React.FC<ClientComputedContentFilterProps> = ({ properties, uuid }) => {
    const { get } = useDataAttributes(SCOPE);
    const { translateKey } = useTranslate();
    const dispatch = useDispatch();

    /**
     * Feature Flags
     */

    /** Advanced search link is only displayed for native search */
    const isNativeSearch = useSelector(getSuggestionEngine) === ENGINES.LUMAPPS;
    /** Content filter feature flag */
    const isNGIContentFiltersFFEnabled = useSelector(isNGIContentFiltersFFEnabledSelector);
    /** Global search feature flag */
    const isNGIContentFiltersGlobalSearchFFEnabled = useSelector(isNGIContentFiltersGlobalSearchFFEnabledSelector);

    /**
     * Props
     */

    const { filters, hideSubheader = false } = properties || {};
    const theme = properties?.style?.content?.theme || Theme.light;

    /**
     * Filter logic
     */

    const {
        availableFilters,
        filtersCurrentValues,
        mainWidgetType,
        filtersFromWidgetProperties,
        handleFilterChange,
        handleApply,
        handleClear,
    } = useContentFilters(uuid, { rawFilters: filters });

    /** Generate global search URL from displayed filters (not necessarily active) and prefilters */
    const advancedSearchURL = useGetSearchURL({ ...filtersFromWidgetProperties, ...filtersCurrentValues });

    /** List content type Ids from widget properties */
    const contentTypesFromPropertiesIds = useMemo(
        () => map(filtersFromWidgetProperties?.contentTypes, 'id'),
        [filtersFromWidgetProperties],
    );

    /**
     * Features availability
     */

    /** Check if main widget is supported by the content filter */
    const isMainWidgetSupported = mainWidgetType && CONTENT_FILTER_SUPPORTED_WIDGETS.includes(mainWidgetType);
    /** Check if the follow button should be displayed */
    const enableFollowThisInterest = !properties?.disableFollowButton && mainWidgetType === WIDGET_CONTENT_LIST_TYPE;
    /** Check if the global search should be displayed */
    const enableGlobalSearchLink =
        isNativeSearch && isNGIContentFiltersGlobalSearchFFEnabled && mainWidgetType === WIDGET_CONTENT_LIST_TYPE;

    /**
     * Hide widget if not applicable
     */

    React.useEffect(() => {
        if (!isMainWidgetSupported || isEmpty(availableFilters) || !isNGIContentFiltersFFEnabled) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [availableFilters, dispatch, isMainWidgetSupported, isNGIContentFiltersFFEnabled, uuid]);

    /**
     * Handlers
     */

    const handleApplyPreventDefault: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        handleApply();
    };

    const handleKeywordFilter = (value?: string) => {
        handleFilterChange(NGIFilterId.query, value || '');
    };

    const handleMetadataFilter = (value: MetadataFilter[]) => {
        handleFilterChange(NGIFilterId.metadata, value);
    };

    const handleTagFilter = (value: FormattedTagFilter[]) => {
        handleFilterChange(NGIFilterId.tags, value);
    };

    const handleHighlightedForFilter = (value: boolean) => {
        handleFilterChange(NGIFilterId.onlyHighlighted, value);
    };

    const handleDateFilter = (value: FormattedPublicationDates) => {
        handleFilterChange(NGIFilterId.publicationDates, value);
    };

    const handleAuthorFilter = (value: FormattedAuthorFilter) => {
        handleFilterChange(NGIFilterId.author, value);
    };

    const handleSitesFilter = (value: FormattedSiteFilter[]) => {
        handleFilterChange(NGIFilterId.site, value);
    };

    if (isNil(filtersFromWidgetProperties)) {
        return <SkeletonFilter quantity={LOADING_SKELETON_QUANTITY} />;
    }

    return (
        <form onSubmit={handleApplyPreventDefault}>
            {map(availableFilters, (type) => {
                switch (type) {
                    case NGIFilterId.query:
                        return (
                            <KeywordContentFilter
                                value={filtersCurrentValues?.searchQuery || ''}
                                onChange={handleKeywordFilter}
                                theme={theme}
                                scope={SCOPE}
                                key={NGIFilterId.query}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.tags:
                        return mainWidgetType === WIDGET_DIRECTORY_ENTRIES_TYPE ? (
                            <TagsFromDirectoriesFilter
                                shouldBeHiddenIfEmpty
                                onChange={handleTagFilter}
                                directories={filtersFromWidgetProperties?.directories}
                                selectedTags={filtersCurrentValues?.tags}
                                theme={theme}
                                scope={`${SCOPE}-tags`}
                                key={NGIFilterId.tags}
                                hideSubheader={hideSubheader}
                            />
                        ) : (
                            <CustomContentTypeTagsFilter
                                shouldBeHiddenIfEmpty
                                onChange={handleTagFilter}
                                selectedTags={filtersCurrentValues?.tags}
                                contentTypeIds={contentTypesFromPropertiesIds}
                                sites={filtersCurrentValues?.site}
                                theme={theme}
                                scope={`${SCOPE}-tags`}
                                key={NGIFilterId.tags}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.metadata:
                        return (
                            <MetadataContentFilter
                                onChange={handleMetadataFilter}
                                selectedMetadata={filtersCurrentValues?.metadata}
                                theme={theme}
                                contentTypes={contentTypesFromPropertiesIds}
                                scope={`${SCOPE}-metadata`}
                                key={NGIFilterId.metadata}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.onlyHighlighted:
                        return (
                            <HighlightedContentFilter
                                theme={theme}
                                scope={SCOPE}
                                isChecked={!!filtersCurrentValues?.onlyHighlighted}
                                onChange={handleHighlightedForFilter}
                                key={NGIFilterId.onlyHighlighted}
                            />
                        );
                    case NGIFilterId.publicationDates:
                        return (
                            <PublicationDatesContentFilter
                                theme={theme}
                                scope={SCOPE}
                                onChange={handleDateFilter}
                                publicationDates={filtersCurrentValues?.publicationDates}
                                key={NGIFilterId.publicationDates}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.author:
                        return (
                            <AuthorContentFilter
                                theme={theme}
                                scope={SCOPE}
                                selectedValue={filtersCurrentValues?.author}
                                onChange={handleAuthorFilter}
                                key={NGIFilterId.author}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.site:
                        return (
                            <SitesContentFilter
                                theme={theme}
                                scope={SCOPE}
                                shouldBeHiddenIfEmpty
                                handleSitesFilter={handleSitesFilter}
                                selectedSites={filtersCurrentValues?.site}
                                siteReferences={filtersFromWidgetProperties?.site}
                                key={NGIFilterId.site}
                                hideSubheader={hideSubheader}
                            />
                        );
                    default:
                        return null;
                }
            })}
            {enableGlobalSearchLink && (
                <Link
                    className={margin('top', 'big')}
                    rightIcon={mdiArrowRight}
                    href={advancedSearchURL}
                    typography={Typography.body1}
                    theme={theme}
                    {...get({ element: 'link', action: 'advanced-search' })}
                >
                    {translateKey(WIDGET_CONTENT_FILTER.ADVANCED_FILTERS)}
                </Link>
            )}

            <FlexBox gap="regular" className={margin('top', 'huge')} hAlign="center" vAlign="space-between" wrap>
                <div>
                    <Button
                        theme={theme}
                        className={margin('right', 'regular')}
                        type="submit"
                        {...get({ element: 'button', action: 'submit' })}
                    >
                        {translateKey(GLOBAL.APPLY)}
                    </Button>
                    <Button
                        theme={theme}
                        onClick={handleClear}
                        emphasis={Emphasis.low}
                        type="reset"
                        {...get({ element: 'button', action: 'reset' })}
                    >
                        {translateKey(GLOBAL.RESET)}
                    </Button>
                </div>
                {enableFollowThisInterest && (
                    <FollowThisInterest
                        filtersValues={{ ...filtersFromWidgetProperties, ...filtersCurrentValues }}
                        theme={theme}
                        scope={`${SCOPE}-follow-interest`}
                    />
                )}
            </FlexBox>
        </form>
    );
};
