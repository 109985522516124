import { get, getChat } from '../../_api/workspaceApi';

const fetchChatTask = ({ id }) => {
    return getChat({ id }).then((response) => ({ payload: response.data }));
};

/**
 * Triggers an API call to fetch a workspace.
 *
 * @return {Promise} The call promise.
 */
const fetchWorkspaceTask = ({ workspaceId } = {}) => {
    return get({ workspaceId }).then((response) => {
        const workspace = response.data;

        return {
            workspace,
        };
    });
};

export { fetchChatTask, fetchWorkspaceTask };
