/* istanbul ignore file */
import get from 'lodash/get';
import partial from 'lodash/partial';

import { or } from '@lumapps/utils/function/predicate/or';
import { isTable } from '@lumapps/wrex-table/utils/isTable';
import { Editor, Element, isEditor, Node, NodeEntry, Path, Point, Range, Text, Transforms } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSibling } from '@lumapps/wrex/slate/utils/getSibling';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';
import { isElementType } from '@lumapps/wrex/slate/utils/isElementType';
import { isSelectionEmpty } from '@lumapps/wrex/slate/utils/isSelectionEmpty';
import { toRange } from '@lumapps/wrex/slate/utils/toRange';
import type { Wrex, WrexEditor } from '@lumapps/wrex/types';

import { CodeBlock } from '../components/blocks/CodeBlock';
import { Headline } from '../components/blocks/Headline';
import { InlineCode } from '../components/blocks/InlineCode';
import { Bold, Colored, Italic, Strikethrough, Underline } from '../components/blocks/Mark';
import { Paragraph } from '../components/blocks/Paragraph';
import { Subtitle } from '../components/blocks/Subtitle';
import { Title } from '../components/blocks/Title';
import { CODE_BLOCK, COLORED, INLINE_CODE, PARAGRAPH, TYPOGRAPHY_FEATURES } from '../constants';
import type { TypographyEditorOptions, TypographyEditor } from '../types';
import { changeTextAlignment } from '../utils/changeTextAlignment';
import { createInlineCode } from '../utils/createInlineCode';
import { getTextAlignment } from '../utils/getTextAlignment';
import { isCodeBlock } from '../utils/isCodeBlock';
import { isCodeBlockAllowed } from '../utils/isCodeBlockAllowed';
import { isInlineCode } from '../utils/isInlineCode';
import { isParagraph } from '../utils/isParagraph';
import { isTextAlignmentAllowed } from '../utils/isTextAlignmentAllowed';
import { getKeyHandler } from './event/keyboard';
import { normalizeElement } from './normalize/normalizeElement';
import { normalizeVoidElement } from './normalize/normalizeVoidElement';

const getEnabledElementsAndMarks = (enabledFeatures: TYPOGRAPHY_FEATURES[]) => {
    const enabledElementsFeatures = [Headline, Title, Subtitle, CodeBlock, InlineCode].filter((element) =>
        enabledFeatures.includes(element.displayName as TYPOGRAPHY_FEATURES),
    );

    const enabledMarksFeatures = [Bold, Italic, Underline, Strikethrough, Colored].filter((mark) =>
        enabledFeatures.includes(mark.displayName as TYPOGRAPHY_FEATURES),
    );

    return { enabledElementsFeatures: [...enabledElementsFeatures, Paragraph], enabledMarksFeatures };
};

const createPluginEditor =
    (options: TypographyEditorOptions): CreatePluginEditor<TypographyEditor, WrexEditor<TypographyEditor>> =>
    (editor) => {
        let lastSelection: Editor['selection'];
        const {
            normalizeNode,
            onChange,
            insertBreak,
            insertSoftBreak,
            insertData,
            isInline,
            deleteBackward,
            deleteForward,
        } = editor;

        const { blockTypeParentMatch } = options;

        return {
            isInline: (element: any) => {
                return isElementType(INLINE_CODE)(element) ? true : isInline(element);
            },

            insertData(data: DataTransfer) {
                const text = data.getData('text/plain');
                if (text && (editor.getBlockType() === CODE_BLOCK || editor.getInlineType() === INLINE_CODE)) {
                    // We should always paste as text in a code block or inline code.
                    editor.insertText(text);
                } else {
                    insertData(data);
                }
            },

            isInlineAllowed() {
                if (!editor.selection) {
                    return true;
                }
                const elements = Array.from(
                    Editor.nodes(editor, { at: editor.selection, match: isElement, mode: 'lowest' }),
                );
                // Editor selection should include only one block.
                if (elements.length !== 1) {
                    return false;
                }
                // Check allowed parent block.
                if (options.allowInlinesOnlyInParagraph) {
                    // First block in node entry list is an allowed parent block.
                    return (elements[0][0] as any).type === PARAGRAPH;
                }
                return true;
            },

            isMarkActive(type) {
                const { selection } = editor;
                if (selection && Range.isCollapsed(selection)) {
                    try {
                        const [node] = Editor.node(editor, selection.focus);
                        if (Element.isElement(node) && Editor.isVoid(editor, node)) {
                            return false;
                        }
                    } catch (e) {
                        return false;
                    }
                }
                const marks = Editor.marks(editor);
                return (marks as any)?.[type];
            },

            resetAllMarks() {
                const marks = Editor.marks(editor);

                if (marks) {
                    Object.keys(marks).forEach((mark) => {
                        Editor.removeMark(editor, mark);
                    });
                }
            },

            isMarkAllowed() {
                const { selection } = editor;
                if (!selection) {
                    return true;
                }
                if (!options.allowedMarkParents) {
                    return true;
                }
                // List elements in selection.
                const elementsInSelection = Array.from(
                    Editor.nodes(editor, { match: isElement, mode: 'lowest', at: selection }),
                );
                // Check that some of the elements allow marks.
                const doesParentAllowMarks = elementsInSelection.some(([{ type }]) => {
                    return options.allowedMarkParents?.includes(type);
                });
                return doesParentAllowMarks;
            },

            toggleMark(type) {
                if (editor.isMarkActive(type)) {
                    Editor.removeMark(editor, type);
                } else {
                    Editor.addMark(editor, type, true);
                }
            },

            isBlockTypeAllowed(type) {
                const entries = Array.from(
                    Editor.nodes(editor, {
                        match: (node) =>
                            blockTypeParentMatch
                                ? Element.isElement(node) &&
                                  Editor.isBlock(editor, node) &&
                                  !or(...Object.values(blockTypeParentMatch))(node as Wrex.Element)
                                : Element.isElement(node) && Editor.isBlock(editor, node),
                        mode: 'highest',
                    }),
                );

                // Get type from first not entry.
                const firstNodeEntry = get(entries, [0]) as NodeEntry;

                if (
                    !firstNodeEntry ||
                    !entries.every(
                        ([node]) => isElement(firstNodeEntry[0]) && isElementType(firstNodeEntry[0].type)(node),
                    )
                ) {
                    // Heterogeneous block types.
                    return false;
                }

                const [parent] = editor.parent(firstNodeEntry[1]);

                return Boolean(
                    isEditor(parent) ||
                        (blockTypeParentMatch &&
                            blockTypeParentMatch[type] &&
                            blockTypeParentMatch[type](parent as Wrex.Element)),
                );
            },

            getBlockType(at = editor.selection) {
                if (!at) {
                    return null;
                }

                const entries = Array.from(
                    Editor.nodes(editor, {
                        match: (node) =>
                            blockTypeParentMatch
                                ? Element.isElement(node) &&
                                  Editor.isBlock(editor, node) &&
                                  !or(...Object.values(blockTypeParentMatch))(node as Wrex.Element)
                                : Element.isElement(node) && Editor.isBlock(editor, node),
                        mode: 'highest',
                    }),
                );

                // Get type from first not entry.
                const nodeType = get(entries, [0, 0, 'type']) as string;
                if (!nodeType || !entries.every(([node]) => isElementType(nodeType)(node))) {
                    // Heterogeneous block types.
                    return null;
                }

                return nodeType;
            },

            getInlineType(at = editor.selection) {
                if (!at) {
                    return null;
                }
                const entries = Array.from(
                    Editor.nodes(editor, {
                        match: (node) => Element.isElement(node) && Editor.isInline(editor, node),
                        mode: 'lowest',
                    }),
                );
                // Get type from first not entry.
                const nodeType = get(entries, [0, 0, 'type']) as string;
                if (!nodeType || !entries.every(([node]) => isElementType(nodeType)(node))) {
                    // Heterogeneous block types.
                    return null;
                }
                return nodeType;
            },

            changeBlockType(type, at = editor.selection || undefined) {
                if (!at) {
                    return;
                }

                const rangeRef = Editor.rangeRef(editor, toRange(at));

                // Change block type of the root blocks.
                Transforms.setNodes(editor, { type } as Partial<Wrex.Element>, {
                    match: (node) =>
                        blockTypeParentMatch
                            ? !Editor.isEditor(node) &&
                              !or(...Object.values(blockTypeParentMatch))(node as Wrex.Element)
                            : !Editor.isEditor(node),
                    mode: 'highest',
                    at,
                });

                const newSelection = rangeRef.unref() as Range;

                // Put selection at the end of the location.
                focusAt(editor, newSelection);
            },

            isTextAlignmentAllowed: partial(isTextAlignmentAllowed, editor, options),

            getTextAlignment: partial(getTextAlignment, editor),

            changeTextAlignment: partial(changeTextAlignment, editor),

            normalizeNode(entry) {
                if (normalizeVoidElement(editor, entry)) {
                    return;
                }
                if (normalizeElement(editor, entry, options)) {
                    return;
                }
                normalizeNode(entry);
            },

            onChange() {
                onChange();
                if (!editor.selection) {
                    return;
                }
                lastSelection = editor.selection;
                const selectionEnd = Range.end(editor.selection);

                if (Range.isExpanded(editor.selection)) {
                    const selectedFragment = Editor.fragment(editor, editor.selection);

                    const [lastSelected] = Node.last({ children: selectedFragment }, [selectedFragment.length - 1]);
                    if (Text.isText(lastSelected) && lastSelected.text.endsWith('\n')) {
                        const pointBefore = Editor.before(editor, selectionEnd, { distance: 1, unit: 'character' });
                        const endEdge = Range.isBackward(editor.selection) ? 'anchor' : 'focus';
                        // Fix selection end.
                        Transforms.setSelection(editor, { [endEdge]: pointBefore });
                    }
                }
            },

            getLastSelection() {
                return editor.selection || lastSelection || undefined;
            },

            wrapCode(nodePath?: Path) {
                if (!editor.isInlineAllowed()) {
                    return;
                }
                const { selection } = editor;
                const isCollapsed = selection && Range.isCollapsed(selection);
                const [node] = Editor.nodes(editor, {
                    match: isInlineCode,
                    at: nodePath,
                });
                if (!node) {
                    const code = createInlineCode();
                    // If the selection is collapsed, insert a new inline code element.
                    if (selection && isCollapsed) {
                        const [currentNode] = Editor.node(editor, selection.anchor);
                        if (
                            Editor.previous(editor, { at: selection.anchor, match: isInlineCode }) &&
                            Editor.next(editor, { at: selection.anchor, match: isInlineCode }) &&
                            Text.isText(currentNode) &&
                            currentNode.text === ''
                        ) {
                            // When the cursor is between two inline code elements, on an empty text node
                            // Then we merge the two inline code elements into one.
                            Editor.withoutNormalizing(editor, () => {
                                Transforms.removeNodes(editor, {
                                    at: selection.anchor,
                                    mode: 'lowest',
                                    match: Text.isText,
                                });
                                Transforms.mergeNodes(editor, { match: isInlineCode });
                            });
                        } else {
                            // Otherwise we just insert a new inline code element.
                            Transforms.insertNodes(editor, code);
                        }
                    } else {
                        // Otherwise we wrap the selection in a new inline code element.
                        Transforms.wrapNodes(editor, code, { split: true, at: nodePath });
                        Transforms.collapse(editor, { edge: 'end' });
                    }
                }
            },

            unwrapCode(nodePath?: Path) {
                const { selection } = editor;

                const isCollapsed = selection && Range.isCollapsed(selection);
                const nodes = Array.from(
                    Editor.nodes(editor, {
                        match: isInlineCode,
                        at: nodePath,
                    }),
                );

                if (isCollapsed && nodes.length > 0) {
                    const [nodeEntry] = nodes;
                    if (Node.string(nodeEntry[0]) === '') {
                        // Remove the node code when unwrapping an empty inline code.
                        Transforms.removeNodes(editor, { at: nodePath, match: isInlineCode });
                    } else {
                        const inTheEnd =
                            selection?.focus && Point.equals(selection.focus, Editor.end(editor, nodeEntry[1]));
                        // Splits the inline code element in two.
                        Transforms.splitNodes(editor, {
                            at: nodePath,
                            match: isInlineCode,
                        });
                        // By default, the cursor will be in the right part of the splitted element, we move
                        // the cursor in reverse to stay between the two parts. If cursor was at the end
                        // of the inline code element before the split, just moves it out of the element.
                        Transforms.move(editor, { unit: 'offset', reverse: !inTheEnd });
                    }
                } else if (selection && nodes.length > 0) {
                    // If selection is not collapsed, unwrap only the selected text.
                    const nextNode = Path.next(nodes[0][1]);
                    if (nextNode) {
                        // We retrieve the position of the inline code node end, to check if the selection
                        // include the whole inline code element.
                        const endofnode = Editor.before(editor, nextNode, { distance: 1, unit: 'offset' });
                        if (endofnode) {
                            const nodeRange = {
                                anchor: {
                                    offset: 0,
                                    path: nodes[0][1],
                                },
                                focus: endofnode,
                            };
                            // We get the intersection between selection and the node range.
                            const nodeRangeInSelection = Range.intersection(nodeRange, selection);
                            // If the intersection matches the node range, then it is the whole node is selected.
                            const isNodeFullySelected =
                                nodeRangeInSelection && Range.equals(nodeRange, nodeRangeInSelection);
                            // We need a special case when the inline code is fully selected.
                            // Instead of splitting the node, we need to unwrap it. Splitting would cause
                            // the creation of an empty inline code element after the unwrapped text.
                            if (isNodeFullySelected) {
                                Transforms.unwrapNodes(editor, {
                                    at: nodePath,
                                    match: isInlineCode,
                                });
                            } else {
                                // Saves the selected text in a variable
                                const selectedText = Editor.string(editor, selection);
                                // Splits the inline code node in two. It will delete the selected text.
                                Transforms.splitNodes(editor, {
                                    at: nodePath,
                                    match: isInlineCode,
                                });
                                // By default, the cursor will be in the right part of the splitted element, we move
                                // the cursor in reverse to stay between the two parts. Then we inserts the previouslu selected text.
                                Transforms.move(editor, { unit: 'offset', reverse: true });
                                Transforms.insertText(editor, selectedText);
                            }
                        }
                    }
                }
            },

            toggleCode(nodePath?: Path) {
                const [node] = Editor.nodes(editor, {
                    match: isInlineCode,
                    at: nodePath,
                });
                if (node) {
                    editor.unwrapCode();
                } else {
                    editor.wrapCode();
                }
            },

            insertBreak() {
                const { selection } = editor;
                // When breaking the line at the end of a paragraph block, we need to disable all marks, to start a new paragraph
                // without any marks.
                if (selection) {
                    const parentEntry = findParent(
                        editor,
                        selection.focus.path,
                        (node) => Element.isElement(node) && Editor.isBlock(editor, node),
                    );
                    if (parentEntry) {
                        const [, parentPath] = parentEntry;
                        const atTheEnd = Point.equals(selection.focus, Editor.end(editor, parentPath));
                        if (atTheEnd) {
                            editor.resetAllMarks();
                        }
                    }
                }
                insertBreak();
            },

            insertSoftBreak() {
                if (editor.selection) {
                    const parentEntry = Editor.parent(editor, editor.selection);
                    if (
                        parentEntry &&
                        (isParagraph(parentEntry[0] as Wrex.Element) || isCodeBlock(parentEntry[0] as Wrex.Element))
                    ) {
                        editor.insertText('\n');
                        return;
                    }
                }
                insertSoftBreak();
            },

            deleteBackward(unit) {
                const { selection } = editor;
                // When deleting backward, we need to check if the selection is at the start of an inline code block and if
                // the previous block is also an inline code block. If so we merge the two inline code blocks.
                if (unit === 'character') {
                    const [inlineCodeAtSelection] = Editor.nodes(editor, {
                        match: isInlineCode,
                    });
                    if (selection && inlineCodeAtSelection) {
                        const [, path] = inlineCodeAtSelection;
                        const isAtStartOfInlineCode = Point.equals(selection.anchor, Editor.start(editor, path));

                        const previousPath = Path.previous(path);
                        const previousNode = Editor.node(editor, previousPath);
                        if (
                            isAtStartOfInlineCode &&
                            previousNode &&
                            Text.isText(previousNode[0]) &&
                            previousNode[0].text.length === 0
                        ) {
                            Editor.withoutNormalizing(editor, () => {
                                Transforms.removeNodes(editor, {
                                    at: previousNode[1],
                                    mode: 'lowest',
                                });
                                Transforms.mergeNodes(editor, { match: isInlineCode });
                            });
                            return;
                        }
                    }
                }

                deleteBackward(unit);
            },

            deleteForward(unit) {
                const { selection } = editor;
                if (unit === 'character' && selection) {
                    const [inlineCodeAtSelection] = Editor.nodes(editor, {
                        match: isInlineCode,
                    });

                    // When deleting forward, we need to check if the selection is at the end of an inline code block and if
                    // the next block is also an inline code block. If so we merge the two inline code blocks.
                    if (inlineCodeAtSelection) {
                        const [, path] = inlineCodeAtSelection;
                        const isAtEndOfInlineCode = Point.equals(selection.anchor, Editor.end(editor, path));

                        const nextPath = Path.next(path);
                        const nextNode = Editor.node(editor, nextPath);
                        if (
                            isAtEndOfInlineCode &&
                            nextNode &&
                            Text.isText(nextNode[0]) &&
                            nextNode[0].text.length === 0
                        ) {
                            Editor.withoutNormalizing(editor, () => {
                                Transforms.removeNodes(editor, {
                                    at: nextNode[1],
                                    mode: 'lowest',
                                });
                                Transforms.mergeNodes(editor, { match: isInlineCode, at: nextPath });
                            });
                            return;
                        }
                    }

                    // Check if the selected line is empty and the next line is a table in order to move the block upward
                    const focusedPath = selection.focus.path[0];
                    const [afterNode] = getSibling(editor, [focusedPath], 'after') || [];

                    if (afterNode && isTable(afterNode) && isSelectionEmpty(editor, selection.focus.path)) {
                        Transforms.moveNodes(editor, {
                            at: [focusedPath + 1],
                            to: [focusedPath],
                        });
                    }
                }

                deleteForward(unit);
            },

            changeColor(color) {
                if (!editor.selection) {
                    initSelection(editor, 'inline');
                }
                if (color) {
                    editor.addMark(COLORED, color);
                } else if (editor.isMarkActive(COLORED)) {
                    editor.removeMark(COLORED);
                }
                focusAt(editor, editor.selection);
            },

            getCurrentColor() {
                const marks = editor.getMarks() as any;
                return marks && marks[COLORED];
            },

            isCodeBlockAllowed() {
                return isCodeBlockAllowed(editor);
            },

            isTypographyFeatureEnabled(feature: TYPOGRAPHY_FEATURES) {
                return options.enabledFeatures.includes(feature);
            },
        };
    };

/**
 * Plugin rendering common elements:
 * - Paragraph text
 * - Headline, Title and Subtitle
 * - Bold, Underline and Italic
 */
export const withTypography = (options: TypographyEditorOptions) => {
    const { enabledElementsFeatures, enabledMarksFeatures } = getEnabledElementsAndMarks(options.enabledFeatures);

    return createPlugin({
        createPluginEditor: createPluginEditor(options || {}),
        getKeyHandler,
        // Only keep the elements and marks included on the enabled features option
        elements: enabledElementsFeatures,
        marks: enabledMarksFeatures,
    });
};
