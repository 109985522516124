export enum ANALYTICS_NAVIGATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27469444
     */
    ANALYTICS_SITE = 'ADMIN.ANALYTICS_SITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=18925294
     */
    ANALYTICS_PLATFORM = 'ADMIN.ANALYTICS_PLATFORM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=18925486
     */
    ANALYTICS_SETTINGS = 'ADMIN.ANALYTICS.SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=411072601
     */
    MOBILE_ANALYTICS = 'ANALYTICS.MOBILE_APPS_ANALYTICS',
}
