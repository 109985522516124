import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const administratorsRoute: Route = {
    path: '/admin/instance-admin',
    legacyId: 'app.admin.instance-admin',
    appId: AppId.backOffice,
};

export const administrators = (): Route => {
    return administratorsRoute;
};
