import {
    WidgetContentStyles,
    WidgetHeaderStyles,
    WidgetFooterStyles,
    WidgetGlobalStyles,
} from '@lumapps/widget-base/types';

export enum WidgetStyleStatus {
    live = 'LIVE',
    deleted = 'DELETED',
}

export enum STYLE_TYPES {
    CUSTOMER = 'globalCustomer',
    INSTANCE = 'global',
    CHILD = 'child',
    PARENT = 'parent',
    WIDGET = 'widget',
}

export interface WidgetStyle {
    customer: string;
    status: WidgetStyleStatus;
    uuid: string;
    name: string;
    updatedAt: string;
    id: string;
    instance: string;
    isDefault: boolean;
    createdBy: string;
    updatedBy: string;
    type: string;
    properties: {
        content?: WidgetContentStyles;
        header?: WidgetHeaderStyles;
        footer?: WidgetFooterStyles;
        main?: WidgetGlobalStyles;
    };
    createdAt: string;
    uid: string;
}

export enum STYLESHEET_KIND {
    ROOT = 'root',
    CUSTOM = 'custom',
}

export interface Stylesheet {
    content?: string;
    kind: STYLESHEET_KIND;
    url: string;
}

export interface Style {
    createdAt: string;
    createdBy: string;
    customer: string;
    id: string;
    instance: string;
    isDefault: boolean;
    name: string;
    properties: any;
    stylesheets: Stylesheet[];
    type: STYLE_TYPES;
    uid: string;
}

export type StyleState = Record<string, Style>;
