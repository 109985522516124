import React from 'react';

import { currentLanguageSelector } from '@lumapps/languages';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { mdiClock, mdiSchool, mdiStar } from '@lumapps/lumx/icons';
import { ColorPalette, GridColumn } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useDimensions } from '@lumapps/responsive';
import { useTranslate } from '@lumapps/translations';
import { numberFormat } from '@lumapps/utils/number/numberFormat';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getLearnerStats } from '../../api';
import { DEFAULT_STAT_SKELETON_DISPLAYED } from '../../constants';
import { LEARNER } from '../../keys';
import { LearnerStatsType } from '../../types';
import { LearnerStat } from '../LearnerStat';
import { LearnerStatsSkeleton } from '../LearnerStatsSkeleton';

/**
 * Display the learner stats (points, time spent, completedTrainings)
 * @returns LearnerStats
 */
export const LearnerStatsList: React.FC = () => {
    const [stats, setStats] = React.useState<LearnerStatsType>({
        points: 0,
        time: 0,
        completedTrainings: 0,
    });
    const [status, setStatus] = React.useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const { translateKey, translateAndReplace, translateAndReplaceWithComponents } = useTranslate();
    const locale = useSelector(currentLanguageSelector);
    const BREAKPOINTS = { XS: 0, SM: 320, MD: 480, LG: 640 };
    const { ref, currentBreakpoint } = useDimensions({ breakpoints: BREAKPOINTS });
    const learningAlphaEnabled = useSelector(isLearningAlphaEnabled);

    const formatedTime = React.useMemo(() => {
        let remaining = stats.time;
        const hours = Math.floor(remaining / 3600);
        remaining %= 3600;
        const minutes = Math.round(remaining / 60);
        return { hours, minutes };
    }, [stats.time]);

    const fetchLearnerStats = async () => {
        try {
            setStatus(BaseLoadingStatus.loading);
            const response = await getLearnerStats(learningAlphaEnabled);
            const stats = response.data;
            if (typeof stats !== 'object' || Object.keys(stats).length !== 3) {
                throw Error('Bad response, expect an object of size 3');
            }
            setStats(stats);
            setStatus(BaseLoadingStatus.idle);
        } catch (error) {
            setStatus(BaseLoadingStatus.error);
        }
    };
    if (status === BaseLoadingStatus.initial) {
        fetchLearnerStats();
    }

    function renderLearnerStatsSkeleton() {
        const array = [];
        for (let i = 0; i < DEFAULT_STAT_SKELETON_DISPLAYED; i++) {
            array.push(<LearnerStatsSkeleton key={i} smallLayout={currentBreakpoint !== 'LG'} />);
        }
        return array;
    }
    return (
        <>
            {status === BaseLoadingStatus.loading && (
                <GridColumn ref={ref} itemMinWidth={60} maxColumns={3} gap="huge">
                    {renderLearnerStatsSkeleton()}
                </GridColumn>
            )}
            {status === BaseLoadingStatus.error && <ServiceNotAvailableState onRetry={fetchLearnerStats} />}
            {status === BaseLoadingStatus.idle && (
                <GridColumn ref={ref} itemMinWidth={60} maxColumns={3}>
                    <LearnerStat
                        stat={numberFormat(stats.points, locale, 6)}
                        icon={mdiStar}
                        color={ColorPalette.red}
                        smallLayout={currentBreakpoint !== 'LG'}
                        description={translateKey(LEARNER.POINTS_EARNED)}
                        mobileDescription={translateAndReplaceWithComponents(LEARNER.POINTS_EARNED_BREAK, {
                            BALISE: <br />,
                        })}
                    />
                    <LearnerStat
                        stat={
                            formatedTime.hours > 0
                                ? translateAndReplace(LEARNER.TIME, {
                                      COUNT_H: formatedTime.hours,
                                      COUNT_M: formatedTime.minutes,
                                  })
                                : translateAndReplace(LEARNER.TIME_MINUTES_ONLY, {
                                      MIN: formatedTime.minutes,
                                  })
                        }
                        icon={mdiClock}
                        color={ColorPalette.green}
                        smallLayout={currentBreakpoint !== 'LG'}
                        description={translateKey(LEARNER.TIME_SPENT)}
                        mobileDescription={translateAndReplaceWithComponents(LEARNER.TIME_SPENT_BREAK, {
                            BALISE: <br />,
                        })}
                    />
                    <LearnerStat
                        stat={stats.completedTrainings}
                        icon={mdiSchool}
                        color={ColorPalette.blue}
                        smallLayout={currentBreakpoint !== 'LG'}
                        description={translateKey(LEARNER.COMPLETED_TRAINING_COURSES)}
                        mobileDescription={translateAndReplaceWithComponents(LEARNER.COMPLETED_TRAINING_COURSES_BREAK, {
                            BALISE: <br />,
                        })}
                    />
                </GridColumn>
            )}
        </>
    );
};
