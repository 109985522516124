import { connect } from '@lumapps/redux/react';

import { fetchFroala } from 'components/components/ducks/froala';

import { ChatFooter } from './ChatFooter';

const mapStateToProps = (state) => {
    const { froala } = state;

    return {
        froala,
    };
};

const mapDispatchToProps = {
    fetchFroala,
};

const connectedChatFooter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChatFooter);

/////////////////////////////

export { connectedChatFooter as ChatFooter };
