import React, { ReactElement, useMemo } from 'react';

import includes from 'lodash/includes';
import kebabCase from 'lodash/kebabCase';

import { classnames } from '@lumapps/classnames';
import { CustomizationComponent, getComponent, PLACEMENT, Targets } from '@lumapps/customizations/api';

import { BLOCK_TYPES_WITH_SPECIFIC_LOADING_CONTENT } from '../../constants';
import { WidgetState } from '../../ducks/type';
import * as types from '../../types';
import { isBlockClientComputed } from '../../types';
import { getWhitelistedPropsForCustomisationsApi } from '../../utils/getWhitelistedPropsForCustomisationsApi';
import { WidgetSkeleton } from '../WidgetSkeleton';
import { computeWidgetContentStyles } from './styles';

import './index.scss';

/** Widget content props. */
export interface WidgetContentProps<BlockProperties = object> extends types.WidgetContent {
    /** The widget content ID. */
    id: string;
    /** The widget ID. */
    widgetId?: string;
    /** Whether the widget is collapsed or not. */
    isCollapsed: boolean;
    /** The function called when the load more button is clicked. */
    onLoadMore?(): void;
    /** Direct children block variant. */
    blockVariant?: string;
    /** Direct children block type. */
    blockType?: string;
    /** The loading state of the widget */
    loadingState?: WidgetState['state'];
    /** The component that will be used as a loader when the container block loads more items. Default: <Progress /> */
    loadingMoreStateRenderer?: React.FC;
    /** specific properties to pass to the block */
    blockProperties?: BlockProperties;
    /** ref of the widget */
    widgetRef?: React.Ref<HTMLElement>;
    /** Classname of widget content */
    className?: string;
    /** HTML ID for the widget */
    htmlId?: string;
}

export const WidgetContent = <BlockProperties,>(
    props: React.PropsWithChildren<WidgetContentProps<BlockProperties>>,
) => {
    const {
        widgetId,
        id,
        isCollapsed,
        children,
        style = {},
        onLoadMore,
        loadingState,
        blockVariant,
        blockType,
        loadingMoreStateRenderer: loadingStateRenderer,
        blockProperties,
        widgetRef,
        className,
        htmlId,
    } = props;

    const { contentStyles, styles } = useMemo(
        () => computeWidgetContentStyles(style, blockVariant === types.ContainerBlockVariant.ungrouped),
        [blockVariant, style],
    );
    const toRender = useMemo(() => {
        const childrenArray = React.Children.toArray(children).filter(Boolean).filter(React.isValidElement);

        /**
         * 'loadingcontent' state is handled by WidgetContent part if the block type is already handling it by itself.
         */
        if (loadingState === 'loadingcontent' && !includes(BLOCK_TYPES_WITH_SPECIFIC_LOADING_CONTENT, blockType)) {
            return { children: <WidgetSkeleton /> };
        }

        if (childrenArray.length === 1) {
            /**
             * While rendering the widget, we take the opportunity to hijack the widget
             * props and use them for the customizations API. This will be used in conjunction with the
             * toRenderWithContext function from that API, in order to render customisations that are
             * contextualised by the current widget.
             */
            const hijackedProps = childrenArray[0].props;

            return {
                props: getWhitelistedPropsForCustomisationsApi(hijackedProps as object),
                // Inject load more and content styles props in block children.
                children: React.cloneElement(childrenArray[0] as ReactElement, {
                    loadingStateRenderer,
                    contentStyles,
                    onLoadMore,
                    loadingState,
                    blockProperties,
                    widgetRef,
                }),
            };
        }

        return {
            props: {},
            children,
        };
    }, [
        children,
        loadingState,
        blockType,
        loadingStateRenderer,
        contentStyles,
        onLoadMore,
        blockProperties,
        widgetRef,
    ]);

    const customizationId = `${Targets.WIDGET}-${widgetId}`;
    const customizationHtmlId = `${Targets.WIDGET}-${htmlId}`;

    const isClientComputedWidget = isBlockClientComputed({ type: blockType });

    return (
        <div
            id={id}
            className={
                !isCollapsed
                    ? classnames(
                          /**
                           * Do not use the following class to style the widget content in v2 pages.
                           */
                          'widget-content',
                          /**
                           * Use this class `.widget-content--v2` to style the component.
                           * (because `.widget-content` is present in v1 and we need to use this component in v2)
                           * TODO: remove this class when angularjs is removed (:pray:)
                           */
                          'widget-content--v2',
                          style.height && 'widget-content--has-fixed-height',
                          blockVariant && `widget-content--block-variant-${kebabCase(blockVariant)}`,
                          blockType && `widget-content--${kebabCase(blockType)}`,
                          className,
                      )
                    : classnames(isClientComputedWidget && 'widget-content--hidden')
            }
            style={!isCollapsed ? styles : undefined}
            dir="auto"
        >
            {(!isCollapsed || isClientComputedWidget) && (
                <>
                    {widgetId ? (
                        <CustomizationComponent
                            target={getComponent(customizationId)}
                            placement={PLACEMENT.ABOVE}
                            context={toRender.props}
                        />
                    ) : null}

                    {htmlId ? (
                        <CustomizationComponent
                            target={getComponent(customizationHtmlId)}
                            placement={PLACEMENT.ABOVE}
                            context={toRender.props}
                        />
                    ) : null}

                    {toRender.children}

                    {widgetId ? (
                        <CustomizationComponent
                            target={getComponent(customizationId)}
                            placement={PLACEMENT.UNDER}
                            context={toRender.props}
                        />
                    ) : null}

                    {htmlId ? (
                        <CustomizationComponent
                            target={getComponent(customizationHtmlId)}
                            placement={PLACEMENT.UNDER}
                            context={toRender.props}
                        />
                    ) : null}
                </>
            )}
        </div>
    );
};

WidgetContent.displayName = 'WidgetContent';
