/* eslint-disable no-inline-comments */
import React, { useEffect, useState, useCallback } from 'react';
import { func, string, bool, number, shape, arrayOf, instanceOf } from 'prop-types';
import classNames from 'classnames';
// eslint-disable-next-line no-shadow
import moment from 'moment';

import {
    Button,
    Progress,
    Size,
    Icon,
    Emphasis,
    CommentBlock,
    Theme,
    IconButton,
    FlexBox,
    Alignment,
} from '@lumapps/lumx/react';
import { mdiReply, mdiDotsVertical, mdiCommentTextOutline, mdiMessageTextOutline } from '@lumapps/lumx/icons';
import { SlackLoginButton } from '@lumapps/chat/components/SlackLoginButton';

import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import loget from 'lodash/get';

import TurndownService from 'turndown';

import { InitialSettings } from 'common/modules/constants/initial-settings_constants';

import { translate as t } from 'components/translations';
import { DropDown, Choice } from 'components/components/ui/menus';

// Add getLikesCount function when the like will be available.
import { getReactionsCountLabel } from 'components/components/chat/utils/chat_utils';

import {
    checkChatFullfillment,
    getContentTypeFromMessage,
    getFormattedText,
    getNotificationCount,
    isRelevant,
} from './widget_chat_utils';

import {
    AVATAR_CONTENT_TYPE_VALUES,
    BODY_CONTENT_TYPE_VALUES,
    CHAT_SIZES_VALUES,
    CHAT_POSITION_VALUES,
    CHAT_PROVIDER_VALUES,
} from './chat_constants';

import { ChatFooter } from './chatFooter';
import { ChatAdviseJoin } from './chatAdvise';
import { ChatHeader } from './ChatHeader';

/**
 * Choice component used in comment action dropdown.
 *
 * @param {value} The value of the action.
 * @return The component.
 */
function ActionChoiceComponent({ value }) {
    if (value === 'CONVERT_TO_POST') {
        return <Icon icon={mdiMessageTextOutline} />;
    }

    return null;
}

/**
 * Displays the widget chat component.
 *
 * @return {Element} The chat react element.
 */
const WidgetChat = ({
    addNewPost,
    uuid,
    bodyContentType,
    size,
    fetchMessagesForChannel,
    organizationId,
    isSlackDefaultAppEnabled,
    createMessageForChannel,
    getChannelById,
    fetchUserTokenStatus,
    hasUserJoinedChannel,
    fetchChat,
    provider,
    spaceId,
    theme,
    channelId,
    channelUrl,
    position,
    messages,
    model,
    lastClosed,
    setLastClosed,
    channels,
    isOpen,
    topResults,
    isLoading,
    isPaginationLoading,
    pollInterval,
    hasActions,
    setLoading,
    setPaginationLoading,
    setSize,
    setOpen,
    setSelectedThread,
    selectedThread,
    isShown,
    setShow,
    setModel,
    setChannelUrl,
    workspaceId,
    isEmojiEditorOpened,
    setEmojiEditorOpened,
    isCreateMessageLoading,
    setCreateMessageLoading,
    notificationCount,
    setNotificationCount,
    userJoinedStatus,
    userTokenStatus,
    features,
}) => {
    /**
     * List of actions available in comment blocks.
     */
    const ACTIONS_LIST = [];

    let messagesContent;
    const turndownService = new TurndownService();
    const [statusConnected, setStatusConnected] = useState(false);
    const [canReadChannel, setCanReadChannel] = useState(false);
    const [titleName, setTitleName] = useState(undefined);
    const [timeoutId, setTimeoutId] = useState(null);
    const [repliesTimeoutId, setRepliesTimeoutId] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [adviseJoin, setadviseJoin] = useState(false);
    const [displayChatSlack, setDisplayChatSlack] = useState(false);

    const scrollToMessage = useCallback(
        (message) => {
            if (messagesContent && message) {
                messagesContent.scrollTo(0, message.offsetTop);
            }
        },
        [messagesContent],
    );

    const isSlackAndNotConnected = useCallback(() => {
        return provider === CHAT_PROVIDER_VALUES.SLACK.id && !statusConnected;
    }, [statusConnected, provider]);

    const convertToPost = (message) => {
        let markdown = message.body.content || '';
        if (message.body.contentType === BODY_CONTENT_TYPE_VALUES.HTML) {
            markdown = turndownService.turndown(markdown);
        }

        addNewPost(undefined, undefined, markdown);
    };

    if (isFunction(addNewPost)) {
        ACTIONS_LIST.push({
            handler: convertToPost,
            label: t('FRONT.CHAT.CONVERT_TO_POST'),
            value: 'CONVERT_TO_POST',
        });
    }

    const componentCleanUp = React.useCallback(() => {
        if (repliesTimeoutId) {
            clearInterval(repliesTimeoutId);
            setRepliesTimeoutId(null);
        }
        if (timeoutId) {
            clearInterval(timeoutId);
            setTimeoutId(null);
        }
    }, [repliesTimeoutId, timeoutId]);

    const startPolling = React.useCallback(
        (timeoutIdRef, setTimeoutIdFn) => {
            // setInterval start after pollIntervall milliseconds
            fetchMessagesForChannel({
                callId: selectedThread ? selectedThread.id : undefined,
                channelId,
                chatMessageId: selectedThread ? selectedThread.id : undefined,
                maxResults: topResults,
                page: 1,
                provider,
                spaceId,
                organizationId,
                isSlackDefaultAppEnabled,
            }).then(() => {
                setLoading({ isLoading: false, uuid });
            });

            const tId = setInterval(async () => {
                await fetchMessagesForChannel({
                    callId: selectedThread ? selectedThread.id : undefined,
                    channelId,
                    chatMessageId: selectedThread ? selectedThread.id : undefined,
                    maxResults: topResults,
                    page: 1,
                    provider,
                    spaceId,
                    organizationId,
                    isSlackDefaultAppEnabled,
                });
            }, pollInterval);
            setTimeoutIdFn(tId);

            // eslint-disable-next-line consistent-return
            return () => {
                clearInterval(timeoutIdRef);
                setTimeoutIdFn(null);
            };
        },
        [
            fetchMessagesForChannel,
            organizationId,
            isSlackDefaultAppEnabled,
            setLoading,
            channelId,
            pollInterval,
            provider,
            spaceId,
            topResults,
            uuid,
            selectedThread,
        ],
    );

    useEffect(() => {
        // Init and clean up
        // componentDidMount

        // componentDidUnmount
        return () => {
            componentCleanUp();
        };
    }, [componentCleanUp]);

    useEffect(() => {
        if (!statusConnected) {
            setShow({ isShown: false, uuid });
            setTitleName(null);
            setLoading({ isLoading: false, uuid });
            setChannelUrl({ channelUrl: null, uuid });
            setCanReadChannel(false);
        }
    }, [statusConnected, setShow, setChannelUrl, setLoading, uuid]);

    useEffect(() => {
        // Set the Open position of the chat community
        if (position === CHAT_POSITION_VALUES.STICKY) {
            return;
        }

        setOpen({
            isOpen: true,
            uuid,
        });
    }, [setOpen, uuid, position]);

    useEffect(() => {
        // In case of community, get the chat associated in the workspace
        if (!workspaceId) {
            return;
        }

        fetchChat({ id: workspaceId });
    }, [fetchChat, workspaceId]);

    useEffect(() => {
        if (!spaceId) {
            return;
        }
        // Check the user token status
        // Only managed for Slack for now
        if (statusConnected) {
            return;
        }
        if (provider !== CHAT_PROVIDER_VALUES.SLACK.id) {
            setStatusConnected(true);
            return;
        }

        fetchUserTokenStatus({ provider, spaceId });
    }, [fetchUserTokenStatus, setStatusConnected, statusConnected, provider, spaceId]);

    useEffect(() => {
        if (provider !== CHAT_PROVIDER_VALUES.SLACK.id) {
            return;
        }

        // Response to the check of the user token status
        if (!spaceId) {
            return;
        }

        if (!userTokenStatus || !(spaceId in userTokenStatus)) {
            setStatusConnected(false);
            return;
        }

        setStatusConnected(loget(userTokenStatus[spaceId], 'is_connected', false));
    }, [spaceId, userTokenStatus, provider]);

    useEffect(() => {
        // Check if the user has joined the channel
        if (!statusConnected || !provider || !spaceId || !channelId) {
            return;
        }

        hasUserJoinedChannel({ provider, spaceId, channelId });
    }, [hasUserJoinedChannel, statusConnected, provider, spaceId, channelId]);

    useEffect(() => {
        // Set the read-only based on provider or user joined status

        // If the provider is Workplace, force read-only
        if (provider === CHAT_PROVIDER_VALUES.FBWORKPLACE.id) {
            setIsReadOnly(true);
            return;
        }

        // If the call is not done yet
        if (userJoinedStatus === undefined) {
            return;
        }

        // If the user has joined, not in read only
        setIsReadOnly(!userJoinedStatus);
        setadviseJoin(!userJoinedStatus);
    }, [setIsReadOnly, provider, userJoinedStatus]);

    useEffect(() => {
        // Get the channel information
        if (!provider || !spaceId || !channelId) {
            return;
        }
        getChannelById({ channelId, provider, spaceId });
    }, [getChannelById, statusConnected, provider, spaceId, channelId]);

    useEffect(() => {
        // If the provider has the channel (getChannelById reponse)
        //  make it visible and readable
        if (!channels || !channels.items || canReadChannel) {
            return;
        }

        setDisplayChatSlack(true);

        const channel = channels.items.find((item) => item && item.id === channelId);
        if (!channel) {
            return;
        }

        setTitleName(channel.name);
        setChannelUrl({ channelUrl: channel.channelUrl, uuid });
        setShow({ isShown: true, uuid });
        if (statusConnected) {
            setCanReadChannel(true);
        }
    }, [
        setChannelUrl,
        setShow,
        setTitleName,
        canReadChannel,
        channelId,
        channels,
        channels.items,
        uuid,
        statusConnected,
    ]);

    useEffect(() => {
        // Messages polling for channel main thread.
        if (!canReadChannel || timeoutId || selectedThread) {
            return;
        }

        // if coming from a selected thread, stop its polling
        if (repliesTimeoutId) {
            clearInterval(repliesTimeoutId);
            setRepliesTimeoutId(null);
        }

        startPolling(timeoutId, setTimeoutId);
    }, [setRepliesTimeoutId, startPolling, canReadChannel, repliesTimeoutId, selectedThread, timeoutId]);

    useEffect(() => {
        // Message polling for a selected thread
        if (!selectedThread || repliesTimeoutId) {
            return;
        }

        setLoading({ isLoading: true, uuid });

        // if coming from the main thread, stop its polling
        if (timeoutId) {
            clearInterval(timeoutId);
            setTimeoutId(null);
        }
        startPolling(repliesTimeoutId, setRepliesTimeoutId);
    }, [setLoading, setRepliesTimeoutId, startPolling, repliesTimeoutId, selectedThread, timeoutId, uuid]);

    useEffect(() => {
        // Set notification count comparing with the last message
        // when the widget chat is reduced in community
        if (!messages || !messages.items || isOpen) {
            return;
        }

        const count = getNotificationCount(messages.items, lastClosed);
        setNotificationCount({ notificationCount: count, uuid });
    }, [isOpen, lastClosed, messages, setNotificationCount, uuid]);

    useEffect(() => {
        // When reducing the widget chat.
        if (!isOpen) {
            setNotificationCount({ notificationCount: 0, uuid });
            setLastClosed({ lastClosed: moment(new Date()).utc(), uuid });
        }

        if (messagesContent) {
            isOpen && scrollToMessage(messagesContent.lastElementChild);
        }
    }, [isOpen, messagesContent, scrollToMessage, setLastClosed, setNotificationCount, uuid]);

    useEffect(() => {
        if (!messagesContent) {
            return;
        }

        isShown && scrollToMessage(messagesContent.lastElementChild);
    }, [isLoading, isShown, messagesContent, scrollToMessage]);

    /**
     * Manage scroll for pagination.
     *
     * @param {Object} evt Event.
     */
    const scrollHandler = (evt) => {
        let current = messages;

        if (selectedThread) {
            if (messages.replies && messages.replies[selectedThread.id]) {
                current = messages.replies[selectedThread.id];
            } else {
                current = undefined;
            }
        }

        if (!current || isLoading || isPaginationLoading || !current.more || evt.target.scrollTop !== 0) {
            return;
        }

        const cursor = encodeURIComponent(current.cursor);
        const firstReadMessage = messagesContent.firstElementChild;
        setPaginationLoading({ isPaginationLoading: true, uuid });
        fetchMessagesForChannel({
            callId: selectedThread ? selectedThread.id : undefined,
            channelId,
            chatMessageId: selectedThread ? selectedThread.id : undefined,
            cursor,
            maxResults: topResults,
            page: current.page + 1,
            provider,
            spaceId,
            organizationId,
            isSlackDefaultAppEnabled,
            loadMore: true,
        }).then(() => {
            setPaginationLoading({ isPaginationLoading: false, uuid });
            scrollToMessage(firstReadMessage);
        });
    };

    /**
     * Post thread/reply to Microsoft Teams Channel.
     */
    const sendMessage = () => {
        if (model.length === 0) {
            return;
        }

        setCreateMessageLoading({ isCreateMessageLoading: true, uuid });
        scrollToMessage(messagesContent.lastElementChild);
        setModel({ model: '', uuid });
        let content = model;
        let contentType = bodyContentType;

        if (provider === CHAT_PROVIDER_VALUES.SLACK.id && contentType === BODY_CONTENT_TYPE_VALUES.HTML) {
            const options = {
                bulletListMarker: '-',
                emDelimiter: '_',
                strongDelimiter: '*',
            };
            const service = new TurndownService(options);

            service.addRule('strikethrough', {
                filter: ['strike'],
                replacement: (value) => {
                    return `~${value}~`;
                },
            });

            content = service.turndown(model);
            contentType = BODY_CONTENT_TYPE_VALUES.MARKDOWN;
        }

        createMessageForChannel({
            body: content,
            bodyContentType: contentType,
            channelId,
            chatMessageId: selectedThread && selectedThread.id,
            provider,
            spaceId,
        }).then(() => {
            fetchMessagesForChannel({
                callId: selectedThread && selectedThread.id,
                channelId,
                chatMessageId: selectedThread && selectedThread.id,
                maxResults: topResults,
                page: 1,
                provider,
                spaceId,
                organizationId,
                isSlackDefaultAppEnabled,
            }).then(() => {
                setCreateMessageLoading({ isCreateMessageLoading: false, uuid });
                scrollToMessage(messagesContent.lastElementChild);
            });
        });
    };
    if (!checkChatFullfillment(provider, features)) {
        return null;
    }
    /**
     * Get the dropdown actions for a message.
     *
     * @param  {Object}     message Message.
     * @return {DOMElement} Dropdown actions for children messages.
     */
    const getActionsDropdownChildren = (message) => {
        return (
            <ul>
                {ACTIONS_LIST.map((choice, index) => {
                    return (
                        <Choice
                            id={index}
                            key={choice.value}
                            value={choice}
                            ChoiceComponent={ActionChoiceComponent}
                            onClick={() => choice.handler(message)}
                        />
                    );
                })}
            </ul>
        );
    };

    /**
     * Fetch user photo.
     *
     * @param  {Object} message Message.
     * @return {string} User picture url or base64.
     */
    const getUserPhoto = (message) => {
        if (message.avatar) {
            switch (message.avatar.contentType) {
                case AVATAR_CONTENT_TYPE_VALUES.URL:
                    return message.avatar.content;

                default:
                    return message.avatar.content;
            }
        }

        if ([CHAT_PROVIDER_VALUES.SLACK.id, CHAT_PROVIDER_VALUES.FBWORKPLACE.id].includes(provider)) {
            return InitialSettings.DEFAULT_AVATAR;
        }
        // Some MS Teams system event messages have no sender
        if (!message.sender || !message.sender.user) {
            return InitialSettings.DEFAULT_AVATAR;
        }

        const { id } = message.sender.user;

        return `/chat/avatar/${provider}/${id}`;
    };

    /**
     * Get the actions for a message.
     *
     * @param  {Object} message Message.
     * @return {string} The number of likes.
     */
    const getActions = (message) => {
        if (selectedThread) {
            return (
                <div className="widget-chat-content__actions widget-chat-content-actions">
                    {/* <div className="widget-chat-content__actions-like">
                        <Icon className="mr-" icon={mdiHeart} /> {getLikesCount(message)}
                    </div> */}
                    <div className="widget-chat-content-actions__reactions">{getReactionsCountLabel(message)}</div>
                    <div className=" widget-chat-content-actions__menu">
                        {ACTIONS_LIST && ACTIONS_LIST.length > 0 && (
                            <DropDown
                                align="right"
                                toggle={
                                    <IconButton emphasis={Emphasis.low} icon={mdiDotsVertical} size={Size.s} label="" />
                                }
                            >
                                {getActionsDropdownChildren(message)}
                            </DropDown>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="widget-chat-content__actions widget-chat-content-actions">
                    <div>
                        {/* <div className="widget-chat-content__actions-like">
                            <Icon className="mr-" icon={mdiHeart} /> {getLikesCount(message)}
                        </div> */}
                        <Button
                            emphasis={Emphasis.low}
                            leftIcon={mdiReply}
                            size={Size.s}
                            onClick={() => setSelectedThread({ selectedThread: message, uuid })}
                        >
                            {t('FRONT.CHAT.LOAD_REPLIES')}
                        </Button>
                    </div>
                    <div className="widget-chat-content-actions__reactions">{getReactionsCountLabel(message)}</div>
                    <div className="widget-chat-content-actions__menu">
                        {ACTIONS_LIST && ACTIONS_LIST.length > 0 && (
                            <DropDown
                                align="right"
                                toggle={
                                    <IconButton emphasis={Emphasis.low} icon={mdiDotsVertical} size={Size.s} label="" />
                                }
                            >
                                {getActionsDropdownChildren(message)}
                            </DropDown>
                        )}
                    </div>
                </div>
            </>
        );
    };

    /**
     * Returns whether message has children or not.
     *
     * @param  {Object}  message Message.
     * @return {boolean} Returns whether the message has children.
     */
    const hasChildren = (message) => {
        const { replies } = messages;

        if (!replies || isEmpty(replies[message.id]) || isEmpty(replies[message.id].items)) {
            return false;
        }

        return replies[message.id] && replies[message.id].items;
    };

    /**
     * Returns the message children.
     *
     * @param  {Object}    message Message.
     * @return {ReactNode} Returns a list of children from the message.
     */
    const getChildrenMessages = (message) => {
        const children = messages.replies;

        if (!children || isEmpty(children[message.id]) || isEmpty(children[message.id].items)) {
            return [];
        }

        return children[message.id].items.map((item) => {
            if (!item.body || !item.body.content) {
                return undefined;
            }

            const msgUser = item.sender && item.sender.user ? item.sender.user : { displayName: '' };

            return (
                <CommentBlock
                    key={item.id}
                    actions={getActions(item)}
                    avatarProps={{
                        image: getUserPhoto(item),
                        alt: msgUser.displayName,
                    }}
                    contentType={getContentTypeFromMessage(item.body)}
                    date={moment(item.createdDateTime).format(t('SIMPLE_DATE_FORMAT_COMPLETE'))}
                    hasActions={hasActions}
                    isRelevant={isRelevant(item, lastClosed)}
                    name={msgUser.displayName}
                    text={getFormattedText(item.body, item.attachments, provider)}
                    theme={theme}
                />
            );
        });
    };

    if (!isShown && provider !== CHAT_PROVIDER_VALUES.SLACK.id) {
        return null;
    }

    const SlackConnection = (
        <>
            {displayChatSlack ? (
                <>
                    {!statusConnected && (
                        <div className="widget-chat-placholder__content">
                            <span>{t('CHAT.SLACK.SIGN_IN_TO_JOIN')}</span>
                            <SlackLoginButton spaceId={spaceId} />
                        </div>
                    )}
                    {statusConnected && !channelUrl && isSlackDefaultAppEnabled && (
                        <div className="widget-chat-placholder__content">
                            <span>{t('CHAT.SLACK.WRONG_WORKSPACE')}</span>
                            <SlackLoginButton spaceId={spaceId} />
                        </div>
                    )}
                </>
            ) : (
                <Progress className="centered-loader" />
            )}
        </>
    );

    return (
        <>
            {spaceId && provider && channelId ? (
                <div
                    className={classNames(
                        'widget-content',
                        'widget-chat',
                        `widget-chat--provider-${provider}`,
                        `widget-chat--position-${position}`,
                        position === CHAT_POSITION_VALUES.STICKY
                            ? `widget-chat--size-${size} widget-chat--state-${isOpen ? 'open' : 'closed'}`
                            : '',
                    )}
                >
                    <ChatHeader
                        channelUrl={channelUrl}
                        currentSize={size}
                        notificationCount={notificationCount}
                        openState={isOpen}
                        position={position}
                        selectedThread={selectedThread}
                        setCurrentSize={setSize}
                        setOpen={setOpen}
                        setSelectedThread={setSelectedThread}
                        theme={theme}
                        titleName={titleName}
                        uuid={uuid}
                    />

                    <div
                        className={`widget-chat--collapse ${position === CHAT_POSITION_VALUES.STICKY &&
                            !isOpen &&
                            'hidden'}`}
                    >
                        <div
                            ref={(el) => el && (messagesContent = el)}
                            style={{ maxHeight: `${(topResults ?? 3) * 100}px` }}
                            className={classNames(
                                'widget-chat__content',
                                'widget-chat-content',
                                `widget-chat-content--${isReadOnly ? 'read-only' : 'write-enabled'}`,
                                `${adviseJoin ? 'widget-chat-content--advise-join-channel' : ''}`,
                            )}
                            onScroll={scrollHandler}
                        >
                            {!canReadChannel ? (
                                SlackConnection
                            ) : (
                                <>
                                    {(isLoading || isPaginationLoading) && <Progress className="centered-loader" />}
                                    {!isLoading && messages && isEmpty(messages.items) && (
                                        <div className="widget-chat--empty">
                                            <div>
                                                <div className="widget-chat--empty-icon">
                                                    <Icon icon={mdiCommentTextOutline} size={Size.l} />
                                                </div>

                                                <div className="widget-chat--empty-text">
                                                    {t('FRONT.WIDGET_CHAT.LABEL_EMPTY_CHAT')}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isLoading &&
                                        messages &&
                                        messages.items &&
                                        !isEmpty(messages.items) &&
                                        !selectedThread &&
                                        messages.items.map((message) => {
                                            if ((!message.body || !message.body.content) && !message.attachments) {
                                                return (
                                                    <div className="widget-chat__message">
                                                        <CommentBlock
                                                            text={t('FRONT.WIDGET_CHAT.LABEL_DELETED_MESSAGE')}
                                                            theme={theme}
                                                        />
                                                    </div>
                                                );
                                            }
                                            const msgUser =
                                                message.sender && message.sender.user
                                                    ? message.sender.user
                                                    : { displayName: '' };
                                            return (
                                                <div key={message.id} className="widget-chat__message" id={message.id}>
                                                    <CommentBlock
                                                        hasActions
                                                        isOpen
                                                        actions={getActions(message)}
                                                        avatarProps={{
                                                            image: getUserPhoto(message),
                                                            alt: msgUser.displayName,
                                                        }}
                                                        contentType={getContentTypeFromMessage(message.body)}
                                                        date={moment(message.createdDateTime).format(
                                                            t('SIMPLE_DATE_FORMAT_COMPLETE'),
                                                        )}
                                                        isRelevant={isRelevant(message, lastClosed)}
                                                        name={msgUser.displayName}
                                                        text={getFormattedText(
                                                            message.body,
                                                            message.attachments,
                                                            provider,
                                                        )}
                                                        theme={theme}
                                                    />
                                                </div>
                                            );
                                        })}
                                    {!isLoading &&
                                        messages &&
                                        messages.items &&
                                        !isEmpty(messages.items) &&
                                        selectedThread && (
                                            <div key={selectedThread.id} className="widget-chat__message">
                                                <CommentBlock
                                                    isOpen
                                                    actions={getActions(selectedThread)}
                                                    avatarProps={{
                                                        image: getUserPhoto(selectedThread),
                                                        alt:
                                                            selectedThread.sender && selectedThread.sender.user
                                                                ? selectedThread.sender.user.displayName
                                                                : '',
                                                    }}
                                                    contentType={getContentTypeFromMessage(selectedThread.body)}
                                                    date={moment(selectedThread.createdDateTime).format(
                                                        t('SIMPLE_DATE_FORMAT_COMPLETE'),
                                                    )}
                                                    hasActions={hasActions}
                                                    isRelevant={isRelevant(selectedThread, lastClosed)}
                                                    name={
                                                        selectedThread.sender && selectedThread.sender.user
                                                            ? selectedThread.sender.user.displayName
                                                            : ''
                                                    }
                                                    text={getFormattedText(
                                                        selectedThread.body,
                                                        selectedThread.attachments,
                                                        provider,
                                                    )}
                                                    theme={theme}
                                                >
                                                    {getChildrenMessages(selectedThread)}
                                                </CommentBlock>
                                            </div>
                                        )}
                                    {isCreateMessageLoading && <Progress className="centered-loader" />}
                                </>
                            )}
                        </div>
                    </div>
                    {!isSlackAndNotConnected() && (
                        <>
                            {!isReadOnly && (
                                <ChatFooter
                                    isEmojiEditorOpened={isEmojiEditorOpened}
                                    model={model}
                                    provider={provider}
                                    sendMessage={sendMessage}
                                    setEmojiEditorOpened={setEmojiEditorOpened}
                                    setModel={setModel}
                                    uuid={uuid}
                                />
                            )}
                            {adviseJoin && isShown && <ChatAdviseJoin channelUrl={channelUrl} />}
                        </>
                    )}
                </div>
            ) : (
                <FlexBox vAlign={Alignment.center} style={{ marginTop: '50px' }}>
                    {t('CHAT.WIDGET_WRONG_CONFIGURATION')}
                </FlexBox>
            )}
        </>
    );
};

WidgetChat.propTypes = {
    /** Add new post to community method. */
    addNewPost: func,
    /** Message body content type. */
    bodyContentType: string,
    /** Channel Id. */
    channelId: string,
    /** Channel Url. */
    channelUrl: string,
    /** List of available channels. */
    channels: shape({
        items: arrayOf(
            shape({
                channelUrl: string,
                description: string,
                id: string,
                name: string,
            }),
        ),
        more: bool,
        page: number,
        provider: string,
        spaceId: string,
    }),
    /** Create message task. */
    createMessageForChannel: func.isRequired,
    /** Fetch chat info from workspace. */
    fetchChat: func,
    /** Fetch messages for channel. */
    fetchMessagesForChannel: func.isRequired,
    /** Whether the block comment has actions available. */
    hasActions: bool,
    /** Whether message creation is loading. */
    isCreateMessageLoading: bool,
    /** Whether the emoji editor is opened. */
    isEmojiEditorOpened: bool,
    /** Whether chat is loading. */
    isLoading: bool,
    /** Whether chat is open. */
    isOpen: bool,
    /** Whether chat is loading pagination. */
    isPaginationLoading: bool,
    /** Whether chat is displayed. */
    isShown: bool,
    /** Last time chat was closed. */
    lastClosed: instanceOf(moment),
    /** List of messages. */
    messages: shape({
        channelId: string,
        cursor: string,
        items: arrayOf(
            shape({
                attachments: arrayOf(
                    shape({
                        content: string,
                        contentType: string,
                        id: string,
                    }),
                ),
                body: shape({
                    content: string,
                    contentType: string,
                }),
                createdDateTime: string,
                etag: string,
                id: string,
                importance: string,
                locale: string,
                sender: shape({
                    user: shape({
                        displayName: string,
                        id: string,
                        userIdentityType: string,
                    }),
                }),
                subject: string,
            }),
        ),
        more: bool,
        page: number,
        provider: string,
        spaceId: string,
    }),
    /** Message to post. */
    model: string,
    /** Number of notification. */
    notificationCount: number,
    /** Polling interval time in ms. */
    pollInterval: number,
    /** Position of chat component. */
    position: string,
    /** Chat provider. */
    provider: string,
    /** Selected thread. */
    selectedThread: shape({}),
    /** ChannelUrl setter action. */
    setChannelUrl: func,
    /** Create message loading setter action. */
    setCreateMessageLoading: func,
    /** Set emoji editor open status. */
    setEmojiEditorOpened: func,
    /** LastClosed setter action. */
    setLastClosed: func,
    /** Loading setter action. */
    setLoading: func,
    /** Model setter action. */
    setModel: func,
    /** Notification count setter action. */
    setNotificationCount: func,
    /** Open setter action. */
    setOpen: func,
    /** Pagination loading setter action. */
    setPaginationLoading: func,
    /** Selected thread setter action. */
    setSelectedThread: func,
    /** Shown setter action. */
    setShow: func,
    /** Size setter action. */
    setSize: func,
    /** Chat size. */
    size: string,
    /** User Space Id. */
    spaceId: string,
    /** Chat theme. */
    theme: string,
    /** Top result pagination. */
    topResults: number,
    /** Whether User has joined */
    userJoinedStatus: bool,
    userTokenStatus: shape(),
    uuid: string,
    /** Workspace Id. */
    workspaceId: string,
    /** Enabled features */
    features: arrayOf(string),
};

WidgetChat.defaultProps = {
    addNewPost: null,
    bodyContentType: BODY_CONTENT_TYPE_VALUES.HTML,
    channelId: null,
    channelUrl: null,
    channels: null,
    fetchChat: null,
    hasActions: false,
    isCreateMessageLoading: false,
    isEmojiEditorOpened: false,
    isLoading: true,
    isOpen: false,
    isPaginationLoading: false,
    isShown: false,
    lastClosed: moment(new Date()).utc(),
    messages: null,
    model: '',
    notificationCount: 0,
    pollInterval: 2000,
    position: CHAT_POSITION_VALUES.DEFAULT,
    provider: null,
    selectedThread: null,
    setChannelUrl: null,
    setCreateMessageLoading: null,
    setEmojiEditorOpened: null,
    setLastClosed: null,
    setLoading: null,
    setModel: null,
    setNotificationCount: null,
    setOpen: null,
    setPaginationLoading: null,
    setSelectedThread: null,
    setShow: null,
    setSize: null,
    size: CHAT_SIZES_VALUES.SMALL,
    spaceId: null,
    theme: Theme.light,
    topResults: 10,
    userJoinedStatus: undefined,
    userTokenStatus: null,
    uuid: null,
    workspaceId: null,
    features: [],
};

/**
 * Defines whether the widget is editable.
 *
 * @return {boolean} Returns whether the widget is editable.
 */
WidgetChat.isEditable = () => false;

/**
 * Determines whether the widget is hidden or not.
 *
 * @param  {Object}  params The widget props.
 * @return {boolean} Whether the widget is hidden or not.
 */
WidgetChat.isWidgetHidden = (params) => {
    return !checkChatFullfillment(params.provider, params.features) || WidgetChat.isWidgetEmpty(params);
};

export { WidgetChat };
