import React from 'react';

import { hasTermsAndConditions } from '@lumapps/customer/ducks/selectors';
import { isFeatureEnabled as isFeatureEnabledSelector } from '@lumapps/features';
import { doesCurrentInstanceAllowPublicContent } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useLocation } from '@lumapps/router';
import { hasUserAcceptedTermsAndConditions } from '@lumapps/user/ducks/selectors';
import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';
import { useDetectMobileOS } from '@lumapps/utils/hooks/useDetectMobileOS';

import { removeBaseUrlFromPathname } from '../../utils/pathname';

const MOBILE_APP_MODAL_FEATURE_FLAG = 'mobile-deeplink';

/* istanbul ignore next */
const MobileAppModal = React.lazy(async () => {
    const resolved = await import(
        /* webpackChunkName: 'mobile-app-modal' */
        './MobileAppModal'
    );

    return { default: resolved.MobileAppModal };
});

const ConnectedMobileAppModal: React.FC<{ legacyPathname?: string }> = ({ legacyPathname }) => {
    const { pathname: reactPathname } = useLocation();

    // if we are in the legacy, we must fix the pathname we get, else we simply use react router's pathname
    // we have to do this since pathname is not update by the useLocation hook when used inside the legacy
    const pathname = legacyPathname ? removeBaseUrlFromPathname(legacyPathname) : reactPathname;

    const isFeatureEnabled = useSelector(isFeatureEnabledSelector(MOBILE_APP_MODAL_FEATURE_FLAG));

    const isPublicContentAllowed = useSelector(doesCurrentInstanceAllowPublicContent);
    const isTermsAndConditionsFeatureEnabled = useSelector(hasTermsAndConditions);
    const areTermsAndConditionsAccepted = useSelector(hasUserAcceptedTermsAndConditions);

    const isTermsAndConditionsStepPassed =
        !isTermsAndConditionsFeatureEnabled ||
        isPublicContentAllowed ||
        (!isPublicContentAllowed && isTermsAndConditionsFeatureEnabled && areTermsAndConditionsAccepted);

    const mobileOS = useDetectMobileOS();
    const isMobileAppWebView = useDetectMobileAppWebView();

    return (
        <DelayedSuspense
            shouldLoad={
                isFeatureEnabled &&
                (mobileOS === 'Android' || mobileOS === 'iOS') &&
                !isMobileAppWebView &&
                isTermsAndConditionsStepPassed
            }
        >
            <MobileAppModal pathname={pathname} />
        </DelayedSuspense>
    );
};

export { MOBILE_APP_MODAL_FEATURE_FLAG, ConnectedMobileAppModal as MobileAppModal };
