import React, { ReactNode } from 'react';

import { ColorPalette, FlexBox, Icon, Size, Text } from '@lumapps/lumx/react';
import './index.scss';

export interface LearnerStatProps {
    stat: string | number;
    icon: string;
    color: ColorPalette;
    description: string;
    mobileDescription: ReactNode;
    smallLayout: boolean;
}

/**
 *
 * @param LearnerStatProps
 * @returns LearnerStat
 */

export const LearnerStat: React.FC<LearnerStatProps> = ({
    stat,
    icon,
    color,
    description,
    mobileDescription,
    smallLayout,
}) => {
    return (
        <FlexBox
            className="learner-stat"
            orientation={smallLayout ? 'vertical' : 'horizontal'}
            gap="big"
            vAlign={smallLayout ? 'center' : undefined}
        >
            <Icon className="learner-stat__icon" icon={icon} size={Size.l} color={color} hasShape />
            <FlexBox orientation="vertical" gap="tiny" vAlign={smallLayout ? 'center' : undefined}>
                <Text as="p" typography="custom-title3">
                    {stat}
                </Text>
                <Text
                    as="p"
                    className={smallLayout ? 'learner-stat__description' : undefined}
                    typography="custom-body"
                    color="dark"
                    colorVariant="L2"
                >
                    {smallLayout ? mobileDescription : description}
                </Text>
            </FlexBox>
        </FlexBox>
    );
};
