/* eslint-disable no-param-reassign */
import find from 'lodash/find';
import get from 'lodash/get';

import { ContentTypes } from '@lumapps/content-types/types';
import type { Directory } from '@lumapps/directories/types';
import type { Instance } from '@lumapps/instance/types';
import { produce } from '@lumapps/redux/immer';
import { standardizeTranslateObject } from '@lumapps/translations';

import { Content, ContentLinkRef } from '../../types';

/**
 * Update a content link ref from redux state if possible.
 *
 * Uses immer to avoid changing ref when no update is required.
 */
export const updateContentLinkFromState = (
    contentLinkRef: ContentLinkRef,
    translateObject: any,
    state: {
        currentInstanceId: string;
        contentById?: Record<string, Content>;
        instanceById: Record<string, Instance>;
        directories: Directory[];
    },
) =>
    produce(contentLinkRef, (draft) => {
        const instanceId = contentLinkRef.instance ? contentLinkRef.instance.id : state.currentInstanceId;
        const instance = instanceId ? state.instanceById[instanceId] : undefined;

        // Set content id as home page id if the link is the home page.
        if (instanceId === state.currentInstanceId && contentLinkRef.isHomePage && instance?.homePage) {
            draft.id = instance.homePage;
        }

        // Set content is home page if id is the instance home page id.
        if (draft.id !== undefined && draft.id === instance?.homePage) {
            draft.isHomePage = true;
            delete draft.hasStaleData;
        }

        // Content is considered up to date if it is a home page.
        let contentUpToDate = contentLinkRef.isHomePage;

        // Update content link/slug and V2 compatibility.
        const content = contentLinkRef.id ? get(state.contentById, contentLinkRef.id) : undefined;

        try {
            if (content?.link) {
                // Content is an external link
                draft.link = translateObject(standardizeTranslateObject(content.link));
                contentUpToDate = true;
            }
        } catch (e) {
            // Error ignored
        }

        /**
         * Implement the directory information
         * if the content is of type "directory"
         * */
        const directory = contentLinkRef.id && find(state.directories, (dir) => dir.contentId === contentLinkRef.id);
        if (directory) {
            draft.type = ContentTypes.DIRECTORY;
            draft.externalKey = directory.id;
            draft.title = translateObject(standardizeTranslateObject(directory.name));
        }

        try {
            if (content?.slug && content.type !== ContentTypes.POST) {
                // Content slug
                const originalSlug = draft.slug;
                let slug = translateObject(standardizeTranslateObject(content.slug));
                if (originalSlug) {
                    const pathParts = originalSlug.split('/');
                    // Remove last path part
                    pathParts.pop();
                    // Replace it with the content slug
                    slug = [...pathParts, slug].join('/');
                }
                draft.slug = slug;
                contentUpToDate = true;
            }
        } catch (e) {
            // Error ignored
        }

        // Update is V2 compatible
        if (typeof content?.template?.isV2Compatible === 'boolean') {
            draft.isV2Compatible = content.template?.isV2Compatible;
        }

        // Instance is considered up to date if there is none or if i's the current instance.
        let instanceUpToDate = !contentLinkRef.instance?.id;

        if (instanceId === state.currentInstanceId) {
            // The content link ref should not provide the instance if it's the current instance.
            delete draft.instance;
            instanceUpToDate = true;
        } else if (instance) {
            // Update content instance id and slug.
            draft.instance = { id: instance.id, slug: instance.slug };
            // Flag the instance has been updated.
            instanceUpToDate = Boolean(instance.id && instance.slug);
        }

        // Content link ref was marked as stale but the content & instance are now up to date => remove stale data flag.
        if (draft.hasStaleData && contentUpToDate && instanceUpToDate) {
            delete draft.hasStaleData;
        }

        return draft;
    });
