export enum GROUPS {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451726
     */
    FEED_CODE = 'ADMIN_FEED_CODE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451727
     */
    FEED_DELETE = 'ADMIN_FEED_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451728
     */
    FEED_DELETE_DESCRIPTION = 'ADMIN_FEED_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451732
     */
    FEED_NAME = 'ADMIN_FEED_NAME',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451735
     */
    FEED_SETTINGS = 'ADMIN_FEED_SETTINGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451736
     */
    FEED_SYNCHRONIZE = 'ADMIN_FEED_SYNCHRONIZE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451740
     */
    FEED_USER_ADD = 'ADMIN_FEED_USER_ADD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451741
     */
    FEED_USER_DELETE = 'ADMIN_FEED_USER_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451742
     */
    FEED_USER_DELETE_DESCRIPTION = 'ADMIN_FEED_USER_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451743
     */
    FEED_USERS = 'ADMIN_FEED_USERS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=94063257
     */
    DYNAMIC_GROUPS_RULES_LIST = 'ADMIN.GROUP.DYNAMIC_GROUPS_RULES_LIST',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=94064482
     */
    MISSING_MAIN_USER_DIRECTORY_WARNING = 'ADMIN.GROUP.MISSING_MAIN_USER_DIRECTORY_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244408
     */
    FEEDS = 'ADMIN.NAV.FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244422
     */
    CUSTOMER_FEEDS = 'ADMIN.NAV.CUSTOMER_FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538395
     */
    METHOD = 'ADMIN.GROUP.METHOD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538396
     */
    ADD_BY_USER = 'ADMIN.GROUP.ADD_BY_USER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538397
     */
    ADD_BY_USER_HELP = 'ADMIN.GROUP.ADD_BY_USER_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538398
     */
    ADD_BY_EMAIL = 'ADMIN.GROUP.ADD_BY_EMAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538400
     */
    ADD_BY_EMAIL_HELP = 'ADMIN.GROUP.ADD_BY_EMAIL_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538402
     */
    SYNCHRONIZATION = 'ADMIN.GROUP.SYNCHRONIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538405
     */
    SYNCHRONIZATION_HELP = 'ADMIN.GROUP.SYNCHRONIZATION_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11538406
     */
    GROUP_MAIL = 'ADMIN.GROUP.GROUP_MAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13259742
     */
    METADATA = 'ADMIN.RESELLER_CONSOLE_CUSTOMER.METADATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15360505
     */
    LIST_USER = 'ADMIN.GROUP.LIST_USER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93211769
     */
    ADD_BY_USER_ATTRIBUTES = 'ADMIN.GROUP.ADD_BY_USER_ATTRIBUTES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93211789
     */
    ADD_BY_USER_ATTRIBUTES_HELP = 'ADMIN.GROUP.ADD_BY_USER_ATTRIBUTES_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93211798
     */
    RULE_ATTRIBUTE_NAME = 'ADMIN.GROUP.RULE_ATTRIBUTE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216064
     */
    RULE_ATTRIBUTE_PLACEHOLDER = 'ADMIN.GROUP.RULE_ATTRIBUTE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216110
     */
    RULE_OPERATOR_NAME = 'ADMIN.GROUP.RULE_OPERATOR_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216114
     */
    RULE_VALUE_NAME = 'ADMIN.GROUP.RULE_VALUE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216127
     */
    RULE_VALUE_PLACEHOLDER = 'ADMIN.GROUP.RULE_VALUE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216148
     */
    RULE_VALUES_NAME = 'ADMIN.GROUP.RULE_VALUES_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216162
     */
    RULE_VALUES_PLACEHOLDER = 'ADMIN.GROUP.RULE_VALUES_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216176
     */
    RULE_VALUE_METADATA_NAME = 'ADMIN.GROUP.RULE_VALUE_METADATA_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216187
     */
    RULE_VALUE_METADATA_PLACEHOLDER = 'ADMIN.GROUP.RULE_VALUE_METADATA_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216200
     */
    RULE_VALUES_METADATA_NAME = 'ADMIN.GROUP.RULE_VALUES_METADATA_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93216224
     */
    RULE_VALUES_METADATA_PLACEHOLDER = 'ADMIN.GROUP.RULE_VALUES_METADATA_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93984751
     */
    EDIT_GROUP = 'ADMIN.GROUP.EDIT_GROUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94327713
     */
    NO_DATA = 'ADMIN.GROUP.NO_DATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94474019
     */
    FETCH_DIRECTORY_FIELDS = 'ADMIN.GROUP.ERROR.FETCH_DIRECTORY_FIELDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94499725
     */
    WARNING_METADATA_DIRECTORY_FIELDS = 'ADMIN.GROUP.WARNING_METADATA_DIRECTORY_FIELDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94789143
     */
    DYNAMIC_GROUP_CONFIRM_TITLE = 'ADMIN.GROUP.DYNAMIC_GROUP_CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94792928
     */
    DYNAMIC_GROUP_CONFIRM_DESCRIPTION = 'ADMIN.GROUP.DYNAMIC_GROUP_CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109573026
     */
    ADD_A_GROUP_NAME = 'GROUPS.ADD_A_GROUP_NAME',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451737
     */
    FEED_SYNCHRONIZE_SUCCESS = 'ADMIN_FEED_SYNCHRONIZE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451756
     */
    FEED_TYPE_SETTINGS = 'ADMIN_FEED-TYPE_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12194697
     */
    CATEGORY_NAME = 'ADMIN.GROUPS.CATEGORY.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=14554136
     */
    USER_PROFILE_FIELD = 'ADMIN.FEED_TYPE.MAP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12190745
     */
    DAILY_UPDATE = 'ADMIN.UPDATE.DAILY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12190662
     */
    GROUPS_SYNCHRONIZE = 'ADMIN.GROUPS.SYNCHRONIZE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=14565736
     */
    PATTERN_HELP = 'ADMIN.DIRECTORY_CUSTOM.PATTERN_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=14554141
     */
    MAP_EXAMPLE = 'ADMIN.FEED_TYPE.MAP_EXAMPLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451745
     */
    FEED_TYPE_DELETE = 'ADMIN_FEED-TYPE_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451746
     */
    FEED_TYPE_DELETE_DESCRIPTION = 'ADMIN_FEED-TYPE_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355309120
     */
    ADD_NEW_GROUP = 'ADMIN.GROUPS.NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=366380337
     */
    GROUP_ID = 'ADMIN.GROUP.GROUP_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=366380419
     */
    COPY_GROUP_ID = 'ADMIN.GROUP.COPY_GROUP_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=369773312
     */
    ACCESS_PREVIOUS_PAGE = 'ADMIN.GROUP.ACCESS_PREVIOUS_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94473819
     */
    PROVIDER_GROUP_NOT_FOUND = 'ADMIN.GROUP.ERROR.PROVIDER_GROUP_NOT_FOUND',
}
