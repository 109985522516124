import React from 'react';

import { getAnalyticsSettingsRoute } from '@lumapps/analytics-settings/routes';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiChartLine } from '@lumapps/lumx/icons';
import { mobileAnalytics } from '@lumapps/mobile-analytics/routes';
import { platformAnalytics, platformAnalyticsRoutesMatch } from '@lumapps/platform-analytics/routes';
import { SEARCH_ANALYTICS } from '@lumapps/search-analytics/keys';
import { searchAnalytics } from '@lumapps/search-analytics/routes';
import { analytics, siteAnalyticsRoutesMatch } from '@lumapps/site-analytics/routes';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { ANALYTICS_NAVIGATION } from '../../keys';

export type AnalyticsSideNavigationProps = {
    hasSearchAnalyticsAccess: boolean;
    hasSiteAnalyticsAccess: boolean;
    hasExtendedAnalyticsAccess: boolean;
    hasMobileAnalyticsAccess: boolean;
    id: string;
};

export const AnalyticsSideNavigation: React.FC<AnalyticsSideNavigationProps> = ({
    hasSearchAnalyticsAccess,
    hasSiteAnalyticsAccess,
    hasExtendedAnalyticsAccess,
    hasMobileAnalyticsAccess,
    id,
}) => {
    const { translateKey } = useTranslate();
    const sideNavigationPropsList: SideNavigationItemProps[] = [];

    // Adding sections depending on the user access
    if (hasSearchAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(SEARCH_ANALYTICS.NAVIGATION),
            route: searchAnalytics(),
        });
    }
    if (hasSiteAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(ANALYTICS_NAVIGATION.ANALYTICS_SITE),
            route: analytics(),
            pathMatchForHighlighting: siteAnalyticsRoutesMatch,
        });
    }
    if (hasExtendedAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(ANALYTICS_NAVIGATION.ANALYTICS_PLATFORM),
            route: platformAnalytics(),
            pathMatchForHighlighting: platformAnalyticsRoutesMatch,
        });
    }
    if (hasMobileAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(ANALYTICS_NAVIGATION.MOBILE_ANALYTICS),
            route: mobileAnalytics(),
        });
    }
    if (hasExtendedAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(ANALYTICS_NAVIGATION.ANALYTICS_SETTINGS),
            route: getAnalyticsSettingsRoute(),
        });
    }

    if (sideNavigationPropsList.length === 0) {
        return null;
    }
    if (sideNavigationPropsList.length === 1) {
        return <SideNavigationItem {...sideNavigationPropsList[0]} icon={mdiChartLine} id={id} />;
    }
    return (
        <SideNavigationItem label={translateKey(GLOBAL.ANALYTICS)} icon={mdiChartLine} id={id}>
            {sideNavigationPropsList.map((props) => (
                <SideNavigationItem key={props.label} {...props} />
            ))}
        </SideNavigationItem>
    );
};
