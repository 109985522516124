import React from 'react';

import { mdiLinkBoxVariantOutline } from '@lumapps/lumx/icons';
import { DialogSizes } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getEmbedMapUrlFromUrl } from '@lumapps/utils/maps/getEmbedMapUrlFromUrl';
import { isUrl } from '@lumapps/utils/string/isUrl';

import { useLinkPreview } from '../../hooks/useLinkPreview';
import { PREVIEW } from '../../keys';
import type { LinkPreviewMetadata } from '../../types';
import { SUPPORTED_PREVIEWS } from '../LinkPreview';
import { InsertUrlPreviewDialog } from './InsertUrlPreviewDialog';
import { useValidationState } from './useValidationState';

const CLASSNAME = 'insert-link-dialog';

export interface InsertLinkDialogProps {
    /** custom classname */
    className?: string;
    /** Cancel button callback. */
    onClose(): void;
    /** Confirm button callback. */
    onInsert(link: LinkPreviewMetadata): void;
    /** Open/close dialog. */
    isOpen?: boolean;
    /** Dialog size. */
    size?: DialogSizes;
    /** Provide a message in to put inside the */
    children?: React.ReactNode;
    /** list of types of previews to render in the component */
    supportedPreviews?: SUPPORTED_PREVIEWS[];
}

/**
 * Dialog that allows to insert any link, parse it, and then retrieve the metadata for it,
 * displaying a preview
 *
 * @family Dialog
 * @param InsertLinkDialogProps
 * @returns InsertLinkDialog
 */
export const InsertLinkDialog: React.FC<InsertLinkDialogProps> = (props) => {
    const { className, onClose, onInsert, isOpen, supportedPreviews = [] } = props;

    const [url, setUrl] = React.useState('');

    // Compute embed Map URL if needed & if URL is a map URL
    const embedMapUrl = React.useMemo(() => {
        if (!supportedPreviews.includes(SUPPORTED_PREVIEWS.MAP)) {
            return undefined;
        }
        return getEmbedMapUrlFromUrl(url);
    }, [supportedPreviews, url]);

    const { isValid: isUrlValid, isDirty: isUrlDirty } = useValidationState(url, isUrl);
    const { preview, previewError, isPreviewLoading, onPreviewThumbnailSwitch, fetchPreview } = useLinkPreview(
        url,
        isUrlValid && Boolean(url),
        true,
        embedMapUrl,
    );
    const { translateKey } = useTranslate();
    const propsToPass = {
        setUrl,
        url,
        isUrlDirty,
        isUrlValid,
        preview,
        previewError,
        isPreviewLoading,
        onPreviewThumbnailSwitch,
        fetchPreview,
    };

    return (
        <InsertUrlPreviewDialog
            isOpen={isOpen}
            title={translateKey(PREVIEW.INSERT_LINK_DIALOG_TITLE)}
            inputIcon={mdiLinkBoxVariantOutline}
            inputErrorMessage={url ? translateKey(GLOBAL.URL_ERROR) : translateKey(GLOBAL.ERROR_MANDATORY_FIELD)}
            inputLabel={translateKey(PREVIEW.INSERT_LINK_DIALOG_URL_FIELD_LABEL)}
            inputHelper={preview.code === 200 ? undefined : translateKey(GLOBAL.URL_HELPER)}
            {...propsToPass}
            onClose={onClose}
            onInsert={onInsert}
            className={className}
            classNamePrefix={CLASSNAME}
            supportedPreviews={supportedPreviews}
        />
    );
};
InsertLinkDialog.displayName = 'InsertLinkDialog';
