import { BaseApiError } from '@lumapps/base-api';
import { Video } from '@lumapps/play/api/types';

/** This util returns the current asset of a video */
export const getCurrentAsset = (video?: Pick<Video, 'assetId' | 'assets'>) => {
    return video?.assets.find((asset) => asset.id === video.assetId);
};

/** Check if a video was uploaded to China */
export const isOptimizedForChina = (video?: Pick<Video, 'assetId' | 'assets'>) => {
    return Boolean(getCurrentAsset(video)?.r13n?.china);
};

/** `true` if the user tries to upload a video he is not allowed to download */
export const isVideoNotDownloadableError = (error: unknown) => {
    return error instanceof BaseApiError && error.response?.data.code === 'PLAY_NOT_DOWNLOADABLE';
};
