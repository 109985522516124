import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { SEARCH_SOURCE_TYPES } from '@lumapps/search/keys/sourceTypes';
import { BaseSearchResult } from '@lumapps/search/types';

import { props as breadcrumbProps } from '../Breadcrumb/props';
import { props as contentMetadataProps } from '../ContentMetadata/props';

const props = (result: BaseSearchResult, baseProps: any, options: any) => {
    const { url, name, snippet, onClick, flag, quickViewUrl, onQuickViewClick } = baseProps;
    const bProps = {
        url,
        name,
        snippet,
        flag,
        quickViewUrl,
        onQuickViewClick,
    };

    const { directoryEntry } = result;

    if (!directoryEntry) {
        return bProps;
    }

    const { translate } = options;
    const breadcrumbLevels = breadcrumbProps({
        metadata: directoryEntry?.metadataDetails,
        translate,
    });

    const metadata = contentMetadataProps(result, options);

    return {
        ...bProps,
        id: directoryEntry.id,
        name: directoryEntry.name || name,
        breadcrumbLevels,
        instanceDetails: directoryEntry?.instanceDetails,
        metadata,
        image: directoryEntry.thumbnail,
        url: directoryEntry.link,
        onClick,
        position: result.position,
        iconName: 'star',
        kind: translate(SEARCH_SOURCE_TYPES.DIRECTORY),
        type: SEARCH_RESULT_TYPES.DIRECTORY_ENTRY,
    };
};

export { props };
