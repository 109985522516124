import { Attributes } from '@lumapps/data-attributes';
import { LinkProps } from '@lumapps/lumx/react';
import { Route } from '@lumapps/router';

import type { PlayStatus, PublicationStatus, SortKeys } from './api/types';

export enum UploadType {
    image = 'image',
    podcast = 'podcast',
    subtitle = 'subtitles',
    video = 'video',
}

export interface BreadCrumbLevel {
    label?: string;
    url?: string;
    /**
     * When the item has no label, a 'home' icon is displayed
     */
    ariaLabel?: string;
    /**
     * Needed for PendoID
     */
    dataAttributes?: Attributes;
}

export type SortingOption = {
    label: string;
    value: SortKeys;
};

export type StatusWithDraft = Exclude<PlayStatus, 'archived'>;

export type FilterPublicationStatus = PublicationStatus;

export type IsReady = Exclude<PlayStatus, 'draft' | 'archived'>;

export type DropdownStatuses = StatusWithDraft | IsReady;

export type OverlayButtonProps = {
    /** Custom react component for the link (can be used to inject react router Link). */
    linkAs: LinkProps;
    /** <a> target attribute. */
    target?: '_self' | '_blank' | '_parent' | '_top';
    /** Target route */
    to: Route;
};

export interface FamilySite {
    id: string;
    name: string;
}

/** Type used for files from drives */
export interface FileUploadFromDrive {
    name: string;
    url: string;
}

export type FileSize = `${number}${'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB'}`;
