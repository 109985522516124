import { Cache } from '../types';

let memoryStorage: Record<string, any> = {};

const memoryCache: Cache = {
    store: (key: string, value: any) => {
        memoryStorage[key] = value;
    },
    retrieve: (key: string) => {
        return memoryStorage[key] || null;
    },
    clearAll: () => {
        memoryStorage = {};
    },
};

export { memoryCache };
