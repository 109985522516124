import merge from 'lodash/merge';
import pick from 'lodash/pick';

import { LegacyWidgetProperties, Widget } from '../types';

/**
 * These are the props that will be sent over to the customisations API
 * we need to avoid over exposing data, so this is why we have a white list
 * of props to send to the API.
 */
const WHITELISTED_PROPS = [
    /** Base props */
    'widget.htmlId',
    'widget.widgetType',
    'widget.widgetId',
    'widget.cssClass',
    'htmlId',
    'widgetType',
    'widgetId',
    'cssClass',
    /** Base props */
    /** Widget Title */
    'text',
    'body.text',
    /** Widget Title */
    /** Widget HTML */
    'html',
    'body.html',
    /** Widget HTML */
    /** Widgets with items and variants */
    'variant',
    'body.variant',
    'items',
    'body.items',
    'properties.items',
    'body.properties.items',
    /** Widgets with items and variants */
];

/**
 * This function receives a widget in a certain format with all the properties that it needs
 * to render and returns a new widget with only the information that we want to expose
 * to the customisations API.
 * @param props widget properties
 * @returns whitelisted props
 */
export const getWhitelistedPropsForCustomisationsApi = (props: object) => {
    /**
     * Depending on the context from which this function is called, widgets can have their
     * properties directly at the root of `props`, or inside `body`. We try to manage
     * both in order to make this function as reusable as possible
     */
    const pickedProps = pick(props, WHITELISTED_PROPS) as Record<string, any> & {
        widget: Widget;
        body: Widget['body'] & { properties?: LegacyWidgetProperties };
        properties: LegacyWidgetProperties;
    };

    const { widget = {}, body = { properties: undefined }, properties = {}, ...restOfProps } = pickedProps;

    /**
     * We would like to avoid complex objects in the response we give on the customisations API. To do so,
     * we merge all objects and create one single object that has only 1 level.
     *
     * We also remove the `properties` key since we have already moved all the values to the root of the object.
     */
    const mergedProps = merge(restOfProps, widget, body, properties, body.properties);
    delete mergedProps.properties;

    return mergedProps;
};
