import React from 'react';

import { classnames, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiApps } from '@lumapps/lumx/icons';
import { PopoverDialog, Emphasis, IconButton, Placement, Offset, Heading } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDropdown } from '@lumapps/utils/hooks/useDropdown';

import { BookmarkType } from '../../types';
import { Bookmarks } from '../Bookmarks';

import './index.scss';

export interface BookmarksMenuProps {
    /** Class name to be added to the wrapping div */
    className?: string;
    /** List of bookmarks to display */
    bookmarks: BookmarkType[] | undefined;
    /** Function to load remote bookmarks */
    loadRemoteBookmarks(): any;
    /** The dropdown offset */
    offset?: Offset;
    /** The dropdown placement */
    placement?: Placement;
    /** Whether the bookmark should be displayed or not */
    show?: boolean;
    /** Custom styles passed down to the icon. Useful when this icon needs to use the custom colors of the instance */
    styles?: Record<string, any>;
}

const CLASSNAME = 'bookmarked-apps';
const SCOPE = 'bookmarks';
const TITLE_ID = `${SCOPE}-title`;
/**
 * This component display a menu containing a Bookmark list.
 */
const BookmarksMenu: React.FC<BookmarksMenuProps> = ({
    className = '',
    bookmarks,
    loadRemoteBookmarks,
    offset = {},
    placement = Placement.AUTO_END,
    show = true,
    styles = {},
}) => {
    const bookmarksMenuClassname = classnames(CLASSNAME, className);
    const { translateKey } = useTranslate();
    const anchorRef = React.useRef(null);
    const { isOpen, setIsOpen, toggle } = useDropdown(false);
    const focusElement = React.useRef(null);
    const closeMenu = () => setIsOpen(false);
    const { get } = useDataAttributes(SCOPE);

    React.useMemo(() => {
        if (show && !bookmarks && isOpen) {
            loadRemoteBookmarks();
        }
    }, [bookmarks, isOpen, loadRemoteBookmarks, show]);

    if (!show || bookmarks?.length === 0) {
        return null;
    }

    return (
        <>
            <IconButton
                className={bookmarksMenuClassname}
                ref={anchorRef}
                emphasis={Emphasis.low}
                icon={mdiApps}
                onClick={toggle}
                color={styles.themeColor}
                {...get({ element: 'button' })}
                aria-expanded={isOpen}
                aria-haspopup="true"
                label={translateKey(GLOBAL.BOOKMARKED_APPS)}
            />

            <PopoverDialog
                aria-labelledby={TITLE_ID}
                className={`${CLASSNAME}-dropdown`}
                anchorRef={anchorRef}
                fitWithinViewportHeight
                focusElement={focusElement}
                isOpen={isOpen}
                offset={offset}
                onClose={closeMenu}
                placement={placement}
            >
                {/** Set a title for assistive technologies only */}
                <Heading id={TITLE_ID} className={visuallyHidden()} tabIndex={-1} ref={focusElement}>
                    {translateKey(GLOBAL.BOOKMARKED_APPS)}
                </Heading>
                <Bookmarks bookmarksList={bookmarks} />
            </PopoverDialog>
        </>
    );
};

export { BookmarksMenu };
