/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const adminContentListRoute: Route = {
    path: '/admin/content-list/:uid',
    legacyId: 'app.admin.content-list',
    appId: AppId.backOffice,
};

const newContentListRoute = {
    path: '/content/create/custom_list/:uid',
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

const editContentListRoute = {
    path: '/content/edit/:contentIdSelected',
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

const copyContentListRoute = {
    path: '/content/duplicate/:contentIdSelected',
    legacyId: 'app.front.content-duplicate',
    appId: AppId.legacy,
};

const previewContentListRoute = {
    path: '/:url',
    legacyId: 'app.front.content-preview',
    appId: AppId.legacy,
};

const adminContentList = (uid: string): Route => ({
    ...adminContentListRoute,
    params: { customContentType: uid, uid },
});

const newContentList = (uid: string): Route => ({
    ...newContentListRoute,
    params: { uid },
});

const editContentList = (contentIdSelected: string, instanceSlug?: string): Route => ({
    ...editContentListRoute,
    params: { contentIdSelected },
    instanceSlug,
});

const copyContentList = (contentIdSelected: string, customContentType: string): Route => ({
    ...copyContentListRoute,
    params: { contentIdSelected },
    query: { customContentType },
});

const previewContentList = (url: string, query: Record<string, string> = {}): Route => ({
    ...previewContentListRoute,
    params: { url },
    query,
});

export {
    adminContentList,
    newContentList,
    editContentList,
    copyContentList,
    previewContentList,
    adminContentListRoute,
    newContentListRoute,
    editContentListRoute,
    copyContentListRoute,
    previewContentListRoute,
};
