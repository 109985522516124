import omit from 'lodash/omit';

import { computeStyles } from '@lumapps/content-style/computeStyles';
import { Theme } from '@lumapps/lumx/react';

import { WIDGET_STYLE_OUTER_SPACING } from '../../constants';
import { WidgetContentStyles, WidgetGlobalStyles } from '../../types';

/** Compute widget CSS styles. */
export function computeWidgetStyle(widget?: WidgetGlobalStyles, content?: WidgetContentStyles, isMobile?: boolean) {
    const widgetStyle = computeStyles(isMobile ? omit(widget, WIDGET_STYLE_OUTER_SPACING) : widget);

    /* fullHeight is set in the body style, but used at this level. */
    const { fullHeight, theme = Theme.light, ...widgetContentStyle } = content || {};

    return { isFullHeight: fullHeight, theme, widgetContentStyle, widgetStyle };
}
