import { doesUrlContainJavascript } from '@lumapps/utils/string/doesUrlContainJavascript';
import { hasProtocol, isUrl } from '@lumapps/utils/string/isUrl';

/**
 * Method to sanitize URL and prevent XSS.
 * Ensure that the url passed as argument is a valid url
 * with a valid protocol which is not javascript
 * @param {string} url the URL to sanitize.
 * @return {string | undefined} the sanitized url.
 */
export const sanitizeUrl = (url: string) => {
    if (!url) {
        return undefined;
    }

    // full url
    if (isUrl(url)) {
        if (hasProtocol(url)) {
            // test against javascript protocol
            return doesUrlContainJavascript(url) ? undefined : url;
        }
        // prepend url with a http protocol
        return `http://${url}`;
    }

    // relative url
    if (url.startsWith('/')) {
        return url;
    }

    return undefined;
};
