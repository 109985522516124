import React from 'react';

interface Output {
    onMouseDown: React.MouseEventHandler;
    onClick: React.MouseEventHandler;
    onBlur: React.FocusEventHandler;
}

/**
 * Disable blur on an element while it is being activated (between mouse down & mouse click)
 *
 * This can be used to fix Safari bugs where buttons gets blured on click.
 *
 * @return event handlers that should be applied to the element.
 */
export function useDisableBlurWhileActivating(): Output {
    const isActivating = React.useRef(false);

    return React.useMemo(
        () => ({
            onMouseDown() {
                isActivating.current = true;
            },
            onClick() {
                isActivating.current = false;
            },
            onBlur(event) {
                // Prevent blur event if element is activating
                if (isActivating.current) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            },
        }),
        [],
    );
}
