import { useCallback, useMemo, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';

import { getCurrentContentTitle } from '@lumapps/contents/ducks/selectors';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { ContainerType } from '@lumapps/medias/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { uploadFile } from '@lumapps/structured-content/utils/uploadFile';
import { standardizeTranslateObject, TranslateObject, useTranslate } from '@lumapps/translations';
import { inputLocaleSelector } from '@lumapps/translations/selectors';
import { LegacyWidget } from '@lumapps/widget-base/types';
import { fromDITA } from '@lumapps/wrex/serialization/dita/fromDITA/fromDITA';
import { toDITA } from '@lumapps/wrex/serialization/dita/toDITA/toDITA';
import { Topic } from '@lumapps/wrex/serialization/dita/types';
import { createEmptyContent } from '@lumapps/wrex/utils/createEmptyContent';

import { LegacyWidgetContributionProps } from '../type';

export interface UseEditableWidgetContributionProps {
    /** The Legacy widget to be rendered. */
    legacyWidget: LegacyWidget<LegacyWidgetContributionProps>;
    /** The callBack to switch to reading mode on click outside the editor (legacy) */
    switchToReadMode: () => void;
    /** The callBack to save the content in widget properties (legacy) */
    saveDitaInWidgetProperties: (dita: TranslateObject<Topic | undefined>) => void;
}

export const useEditableWidgetContribution = ({
    legacyWidget,
    switchToReadMode,
    saveDitaInWidgetProperties,
}: UseEditableWidgetContributionProps) => {
    const wrapperRef = useRef(null);
    const childrenRefs = useRef([wrapperRef]);
    const site = useSelector(getCurrentInstance);
    const siteId = site.id || site.uid;
    const contentTitle = useSelector(getCurrentContentTitle);
    const currentInputLang = useSelector(inputLocaleSelector);

    const dispatch = useDispatch();
    const { translateObject } = useTranslate();
    const emptySlateContent = createEmptyContent();

    /** we use slate format to easily switch from read to edit without having to parse every time */
    const initialContent = useMemo(() => {
        if (legacyWidget?.properties?.dita && !isEmpty(legacyWidget?.properties?.dita)) {
            /** If a dita is provided, we transform it to slate */
            const { content: slateContent } = fromDITA(legacyWidget?.properties?.dita[currentInputLang]);
            return slateContent;
        }
        /** if no dita provided, we create an empty slate content */
        return emptySlateContent;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** on click outside the editor, we save the slate content in the widget properties and switch to read mode */
    const exitEditionMode = useCallback(
        (content) => {
            const newDita = toDITA({
                content,
                title: translateObject(standardizeTranslateObject(contentTitle)) || '',
            });

            /** saved as translatable object to manage multilang */
            saveDitaInWidgetProperties({
                ...legacyWidget?.properties?.dita,
                [currentInputLang]: newDita,
            });

            switchToReadMode();
        },
        [
            contentTitle,
            currentInputLang,
            legacyWidget?.properties?.dita,
            saveDitaInWidgetProperties,
            switchToReadMode,
            translateObject,
        ],
    );

    const onFileUpload = useCallback(
        (files: File[], errorMessage?: string) => {
            return uploadFile(siteId, ContainerType.SITE, files, errorMessage)(dispatch);
        },
        [siteId, dispatch],
    );

    return {
        wrapperRef,
        exitEditionMode,
        childrenRefs,
        onFileUpload,
        initialContent,
    };
};
