import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const PlatformGroupsRoutes: Route = {
    path: '/admin/platform-feed',
    appId: AppId.backOffice,
};

/** Retrieves the route for the groups at platform level admin. */
const PlatformGroups = (): Route => PlatformGroupsRoutes;

export { PlatformGroups, PlatformGroupsRoutes };
