import { useEffect, useMemo } from 'react';

import { FormattedTagFilter } from '../types';

interface UseUpdateContentTypeProps {
    selectedTags: FormattedTagFilter[];
    formattedTags: FormattedTagFilter[];
    ready: boolean;
    handleTagFilter: (tags: FormattedTagFilter[]) => void;
}

/**
 * This hook is used to update the selected tags based on the currently available tags
 * The "formattedTags" are updated to reflect the available tags, the ones that's belong to the currently available sites.
 * We need to remove the selected tags that are no longer available.
 * */
export const useUpdateContentTypeTags = ({
    selectedTags,
    formattedTags,
    handleTagFilter,
    ready,
}: UseUpdateContentTypeProps) => {
    /**
     * If one of the selected tags Id is not referenced in the formatted tag, we need to update and remove it/them.
     * */
    const hasTagToUpdate = useMemo(
        () => ready && selectedTags.some(({ id }) => !formattedTags.map((tag) => tag.id).includes(id)),
        [formattedTags, selectedTags, ready],
    );

    useEffect(() => {
        if (hasTagToUpdate) {
            const updatedTags = selectedTags.filter(({ id }) => formattedTags.map((tag) => tag.id).includes(id));
            handleTagFilter(updatedTags);
        }
    }, [hasTagToUpdate, selectedTags, formattedTags, handleTagFilter]);
};
