import React, { Ref } from 'react';

import { classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { useTranslate } from '@lumapps/translations';

import { BookmarkType } from '../../../types';

import './index.scss';

export interface BookmarkProps {
    /** className to be added to the wrapping div */
    className?: string;
    /** Bookmark to display */
    item: BookmarkType;
    /** reference to the bookmark link */
    linkRef?: Ref<HTMLAnchorElement>;
}

const CLASSNAME = 'bookmark';
const SCOPE = 'bookmark';

/**
 * This component display a links with an icon.
 */
const Bookmark: React.FC<BookmarkProps> = ({ className = '', item, linkRef }) => {
    const bookmarkClassname = classnames(CLASSNAME, className);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const title = translateKey(item.title);

    return (
        <li className={bookmarkClassname} {...get({ element: 'item' })}>
            <a
                className={`${CLASSNAME}__link`}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                ref={linkRef}
            >
                <div className={`${CLASSNAME}__icon`} style={{ backgroundImage: `url(${item.icon})` }} />

                <div className={`${CLASSNAME}__title`}>{title}</div>
            </a>
        </li>
    );
};

export { Bookmark };
