/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import React from 'react';
import curry from 'lodash/curry';
import get from 'lodash/get';
import partial from 'lodash/partial';
import isEmpty from 'lodash/isEmpty';
import loFind from 'lodash/find';

import { TextField, Progress, Size, Icon, ChipGroup, Chip, LinkPreview } from '@lumapps/lumx/react';

import { mdiLink, mdiPlusCircle, mdiFormatTitle, mdiImageSizeSelectActual, mdiAlertCircle } from '@lumapps/lumx/icons';

import { SA_FEATURE_FLAGS } from 'components/components/social-advocacy/constants';

import { translate as t, transRep as tr } from 'components/translations';
import { GLOBAL } from '@lumapps/translations';

import { SHAREABLE_CONTENT_TYPES } from 'components/components/social-advocacy/ducks/shareableContents/constants';
import { MAX_PHOTOS } from 'components/components/social-advocacy/ducks/shareableWorkflow/constants';

import { choiceRenderFactory, SelectField } from 'components/components/ui/inputs/SelectField';

import { ShareableImagesSelect } from './ShareableImagesSelect/ShareableImagesSelect';
import { SocialNetworksManagement } from './SocialNetworksManagement';

const MAX_SUGGESTED_TOPICS = 5;
const MAX_IMAGE_TITLE_LENGTH = 150;
const CONTENT_TYPES = [
    {
        icon: mdiLink,
        id: SHAREABLE_CONTENT_TYPES.link,
        label: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_CONTENT.TYPE.LINK',
    },
    {
        icon: mdiImageSizeSelectActual,
        id: SHAREABLE_CONTENT_TYPES.images,
        label: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_CONTENT.TYPE.PHOTOS',
    },
];

const SAShareableWorkflowForm = ({
    form,
    contentId,
    contentPreview,
    shareableContent,
    setFormFieldValue,
    setSelectOpen,
    setSelectSearch,
    setContentUrlValue,
    isLoadingPreview,
    mostUsedTopics,
    isFetchingMostUsedTopics,
    recentTopics,
    topics,
    formIsValid,
    setImages,
    removeImage,
    filteredTopics,
    programId,
    fetchProgramTopics,
}) => {
    React.useEffect(() => {
        if (programId && fetchProgramTopics) {
            fetchProgramTopics(programId);
        }
    }, [programId, fetchProgramTopics]);

    const { contentUrl, topics: formTopics, type, contentTitle, images } = get(form, 'fields', {});

    const isExistingContent = Boolean(contentId || shareableContent);

    const suggestedTopics = isEmpty(mostUsedTopics) ? recentTopics : mostUsedTopics;

    const onFieldChange = curry((fieldId, value) => {
        setFormFieldValue(fieldId, value);
    });

    /**
     * Action on topic clicked.
     *
     * @param {string} topic The topic clicked.
     */
    const onSuggestedTopicClick = (topic) => {
        const selectedTopic = loFind(topics, { id: topic.id });
        onFieldChange('topics', [...formTopics.value, selectedTopic]);
    };

    return (
        <>
            <ChipGroup>
                {CONTENT_TYPES.map((contentType) => (
                    <Chip
                        key={contentType.id}
                        before={<Icon icon={contentType.icon} size={Size.xs} />}
                        isDisabled={formIsValid}
                        isSelected={contentType.id === type.value}
                        onBeforeClick={() => onFieldChange('type', contentType.id)}
                        onClick={() => onFieldChange('type', contentType.id)}
                    >
                        {t(contentType.label)}
                    </Chip>
                ))}
            </ChipGroup>

            {type.value === SHAREABLE_CONTENT_TYPES.link ? (
                <>
                    <TextField
                        required
                        hasError={contentUrl.hasError}
                        icon={mdiLink}
                        isDisabled={isExistingContent}
                        label={
                            <>
                                <span className="shareable-workflow-form__required">*</span>
                                {t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_CONTENT_URL.PLACEHOLDER')}
                            </>
                        }
                        value={get(contentUrl, 'value', '')}
                        onChange={setContentUrlValue}
                    />

                    {contentUrl.hasError && contentUrl.errorMessage && (
                        <span className="text-field-error">{t(contentUrl.errorMessage)}</span>
                    )}

                    {isLoadingPreview && (
                        <div className="shareable-workflow-form__spinner-wrapper">
                            <Progress />
                        </div>
                    )}

                    {!isLoadingPreview && !contentUrl.hasError && contentPreview && contentPreview.url && (
                        <LinkPreview
                            title={contentPreview.title}
                            description={contentPreview.description}
                            link={contentPreview.url}
                            className="lumx-spacing-margin-top-huge"
                            thumbnailProps={{
                                image: contentPreview.imageUrl,
                                alt: '',
                                isCrossOriginEnabled: false,
                            }}
                        />
                    )}
                </>
            ) : (
                <>
                    <p className="shareable-workflow-form__title mt+">
                        {isExistingContent ? (
                            t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_CONTENT.TYPE.PHOTOS')
                        ) : (
                            <>
                                <span className="shareable-workflow-form__required">*</span>
                                {t(GLOBAL.ADD_PHOTOS)}
                            </>
                        )}
                    </p>

                    {!isExistingContent && (
                        <p className="shareable-workflow-form__helper shareable-workflow-form__helper--warning mb">
                            <Icon className="mr-" icon={mdiAlertCircle} size={Size.xxs} />
                            {` ${
                                MAX_PHOTOS > 1
                                    ? tr(
                                          'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.MAX_PHOTOS.MORE',
                                          ['NB'],
                                          [MAX_PHOTOS],
                                      )
                                    : t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.MAX_PHOTOS.ONE')
                            } - ${t('FRONT.SOCIAL_ADVOCACY.GIFS_NOT_SUPPORTED')}`}
                        </p>
                    )}

                    <ShareableImagesSelect
                        canEdit={!isExistingContent}
                        images={images && images.value}
                        onChange={setImages}
                        onRemove={removeImage}
                    />
                    <TextField
                        required
                        hasError={Boolean(get(contentTitle, 'errorMessage'))}
                        icon={mdiFormatTitle}
                        label={t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_PHOTOS_TITLE.PLACEHOLDER')}
                        value={get(contentTitle, 'value', '')}
                        onChange={(newValue) =>
                            onFieldChange('contentTitle', newValue.slice(0, MAX_IMAGE_TITLE_LENGTH))
                        }
                    />
                    <div className="shareable-workflow-form__helper mb">
                        <span className="shareable-workflow-form__counter">
                            {`${get(contentTitle, 'value.length', 0)} / ${MAX_IMAGE_TITLE_LENGTH}`}
                        </span>
                        {contentTitle.errorMessage && (
                            <span className="text-field-error">{t(contentTitle.errorMessage)}</span>
                        )}
                    </div>
                </>
            )}

            {!isEmpty(topics) && (
                <SelectField
                    choices={filteredTopics}
                    isOpen={formTopics.isOpen}
                    label={t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_TOPICS')}
                    searchText={formTopics.searchText}
                    setOpen={partial(setSelectOpen, 'topics')}
                    value={formTopics.value}
                    onChange={partial(onFieldChange, 'topics')}
                    onSearch={partial(setSelectSearch, 'topics')}
                >
                    {choiceRenderFactory((topic) => topic.name.value)}
                </SelectField>
            )}

            {!isFetchingMostUsedTopics && !isEmpty(suggestedTopics) && !isEmpty(topics) && (
                <>
                    <p className="shareable-workflow-form__label">
                        {isEmpty(mostUsedTopics)
                            ? t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.RECENT_TOPICS')
                            : t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.MOST_USED_TOPICS')}
                    </p>
                    {suggestedTopics.slice(0, MAX_SUGGESTED_TOPICS).map((topic) => (
                        <button
                            key={topic.id}
                            className="shareable-workflow-form__suggested-topic-btn"
                            type="button"
                            onClick={partial(onSuggestedTopicClick, topic)}
                        >
                            <Icon className="pr-" icon={mdiPlusCircle} size={Size.xs} />
                            {topic.name.value}
                        </button>
                    ))}
                </>
            )}

            {SA_FEATURE_FLAGS.selectSNForShareableContent && <SocialNetworksManagement />}
        </>
    );
};

export { SAShareableWorkflowForm };
