import React from 'react';

import { useInstanceSelectMultiple } from '@lumapps/instance/hooks/useInstanceSelectMultiple';
import { INSTANCE } from '@lumapps/instance/keys';
import { Instance } from '@lumapps/instance/types';
import { useTranslate } from '@lumapps/translations';

import { MultipleSelectFilter, MultipleSelectFilterProps } from '../MultipleSelectFilter';

/**
 * Component that implements the instance filter used for the `Filters` pattern.
 *
 * @family Filters
 * @param InstanceFilterProps
 * @returns InstanceFilter
 */
export const InstanceFilter: React.FC<Omit<MultipleSelectFilterProps<Instance>, 'options'>> = ({
    selectFieldMultipleProps,
    ...props
}) => {
    const {
        sites,
        onOpen,
        isLoading,
        getValueName,
        getValueId,
        hasLoadingError,
        onSearch,
        isLoadingMore,
        searchFieldProps,
        onInfiniteScroll,
        searchText,
        maxResults,
    } = useInstanceSelectMultiple();

    const { translateKey } = useTranslate();

    return (
        <MultipleSelectFilter<Instance>
            {...props}
            id="instances-filter"
            title={translateKey(INSTANCE.INSTANCES_TITLE)}
            options={sites}
            onOpen={onOpen}
            selectFieldMultipleProps={{
                ...selectFieldMultipleProps,
                isLoading,
                getValueName,
                getValueId,
                hasLoadingError,
                onSearch,
                isLoadingMore,
                searchFieldProps,
                onInfiniteScroll,
                searchText,
                maxResultsForInfiniteScroll: maxResults,
            }}
        />
    );
};
