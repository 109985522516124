import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';

import { Training as TrainingType, TrainingCoursesViewModeVariantType } from '../../types';
import { TrainingInformations } from '../TrainingInformations';

import './index.scss';

export interface TrainingProps {
    scope: string;
    training: TrainingType;
    viewModeVariant?: TrainingCoursesViewModeVariantType;
    theme?: Theme;
}

const CLASSNAME = 'training-card';

/**
 * Display a single training card
 * @param TrainingProps
 * @returns Training
 */
export const TrainingCard: React.FC<TrainingProps> = ({ training, viewModeVariant, theme = Theme.light, scope }) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <div className={`${CLASSNAME} ${element(viewModeVariant === 'ungrouped' ? 'ungrouped' : 'grouped')}`}>
            <TrainingInformations training={training} isButtonDisplayed={false} theme={theme} scope={scope} />
        </div>
    );
};
