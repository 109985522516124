import { CHAT_PROVIDER_VALUES } from '../constants';
import { ChatProviderId } from '../types';
/**
 * https://api.slack.com/methods/conversations.create
 * Channel names may only contain
 * lowercase letters, numbers, hyphens, and underscores,
 * and must be 80 characters or less.
 * For lower case, see https://unicode-table.com/en/
 */
const channelNameIsValidSlack = (name: string): boolean => {
    if (name.length > 80) {
        return false;
    }

    // eslint-disable-next-line prefer-regex-literals
    const regExp = new RegExp('^[一-龯A-Z0-9a-zà-ÿ\\d\\-_]*$');

    return regExp.test(name);
};

/**
 * https://api.slack.com/methods/conversations.create
 * Channel names may only contain
 * lowercase letters, numbers, hyphens, and underscores,
 * and must be 80 characters or less.
 * For lower case, see https://unicode-table.com/en/
 */
const makeChannelNameValidSlack = (name = ''): string => {
    if (name.length > 80) {
        return name.substring(0, 80);
    }

    const regExp = /[^a-z0-9à-ÿ\d\-_]/g;

    return name.toLowerCase().replace(/[ ]/g, '-').replace(regExp, '');
};

/**
 * https://docs.microsoft.com/en-us/microsoftteams/limits-specifications-teams#channel-names
 * Channel names can't contain the following characters or words.
 * Characters	~ # % & * { } + / \ : < > ? | ' " , .
 * Characters in these ranges	0 to 1F
 *                              80 to 9F
 * Words	forms, CON, CONIN$, CONOUT$, PRN, AUX, NUL, COM1 to COM9, LPT1 to LPT9, desktop.ini, _vti_
 * Limit to 50 characters
 * @param {String}    name Name to validate
 * @returns {boolean} If the name is valide
 */
const channelNameIsValidTeams = (name: string): boolean => {
    if (name.length > 50) {
        return false;
    }

    // eslint-disable-next-line prefer-regex-literals
    const invalid = new RegExp('[~#%&*{}+/\\\\:<>\\?\\|\'",\\.]');

    if (invalid.test(name)) {
        return false;
    }

    const invalidWords = /\b(forms|CON|CONIN\$|CONOUT\$|PRN|AUX|NUL|COM1 to COM9|LPT1 to LPT9|desktop\.ini|_vti_)\b/;
    const result = invalidWords.exec(name);
    if (result && name.length !== result.index + result[0].length) {
        return false;
    }

    // eslint-disable-next-line no-control-regex, prefer-regex-literals
    const regExp = new RegExp('[\\u0000-\\u001F\\u0080-\\u009F]');

    return !regExp.test(name);
};

/**
 * https://docs.microsoft.com/en-us/microsoftteams/limits-specifications-teams#channel-names
 * Channel names can't contain the following characters or words.
 * Characters	~ # % & * { } + / \ : < > ? | ' " , .
 * Characters in these ranges	0 to 1F
 *                              80 to 9F
 * Words	forms, CON, CONIN$, CONOUT$, PRN, AUX, NUL, COM1 to COM9, LPT1 to LPT9, desktop.ini, _vti_
 * Limit to 50 characters
 * @param {String}    name Name to validate
 * @returns {boolean} If the name is valide
 */
const makeChannelNameValidTeams = (name: string): string => {
    if (name.length > 50) {
        return name.substring(0, 50);
    }

    const regExp = /[~#%&*{}+/\\:<>?|'",.]/g;

    return name.replace(regExp, '');
};

/**
 * No documentation, check only if no unicode chars
 *
 * @param {String}    name Name to validate
 * @returns {boolean} If the name is valide
 */
const channelNameIsValidWorkplace = (name: string): boolean => {
    // eslint-disable-next-line prefer-regex-literals
    const regExp = new RegExp('^[\\w\\-\\u0020]*$');

    return regExp.test(name);
};

/**
 * No documentation, check only if no unicode chars
 *
 * @param {String}    name Name to validate
 * @returns {boolean} If the name is valide
 */
const makeChannelNameValidWorkplace = (name: string): string => {
    const regExp = /[^\w\-\u0020]/g;

    return name.replace(regExp, '');
};

/**
 * No documentation, accept all.
 *
 * @param {String}    name Name to validate
 * @returns {boolean} If the name is valide
 */
const channelNameIsValidYammer = (): boolean => {
    return true;
};

/**
 * No documentation, accept all.
 *
 * @param {String}    name Name to validate
 * @returns {boolean} If the name is valide
 */
const makeChannelNameValidYammer = (name: string): string => {
    return name;
};

/**
 * @param {String}   provider Name of the provider to check
 * @param {String}   name     Name to validate
 * @return {boolean} If the name is valid
 */
const channelNameIsValid = (provider: ChatProviderId, name: string): boolean => {
    switch (provider) {
        case CHAT_PROVIDER_VALUES.SLACK.id:
            return channelNameIsValidSlack(name);
        case CHAT_PROVIDER_VALUES.TEAMS.id:
            return channelNameIsValidTeams(name);
        case CHAT_PROVIDER_VALUES.FBWORKPLACE.id:
            return channelNameIsValidWorkplace(name);
        case CHAT_PROVIDER_VALUES.YAMMER.id:
            return channelNameIsValidYammer();
        default:
            return false;
    }
};

/**
 * @param {String}   provider Name of the provider to check
 * @param {String}   name     Name to validate
 * @return {boolean} If the name is valid
 */
const makeChannelNameValid = (provider: ChatProviderId, name: string): string => {
    switch (provider) {
        case CHAT_PROVIDER_VALUES.SLACK.id:
            return makeChannelNameValidSlack(name);
        case CHAT_PROVIDER_VALUES.TEAMS.id:
            return makeChannelNameValidTeams(name);
        case CHAT_PROVIDER_VALUES.FBWORKPLACE.id:
            return makeChannelNameValidWorkplace(name);
        case CHAT_PROVIDER_VALUES.YAMMER.id:
            return makeChannelNameValidYammer(name);
        default:
            return name;
    }
};

export {
    channelNameIsValidSlack,
    makeChannelNameValidSlack,
    channelNameIsValidTeams,
    makeChannelNameValidTeams,
    channelNameIsValidWorkplace,
    makeChannelNameValidWorkplace,
    channelNameIsValidYammer,
    makeChannelNameValidYammer,
    channelNameIsValid,
    makeChannelNameValid,
};
