import React, { useMemo } from 'react';

import { MAX_IMAGE_SIZE } from '@lumapps/constants';
import { useImageLightboxProvider } from '@lumapps/lumx-images/hooks/useImageLightboxProvider';
import { ImageBlock, AspectRatio, ImageBlockCaptionPosition, Alignment } from '@lumapps/lumx/react';
import { resizeImage } from '@lumapps/medias/utils/resizeImage';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import { unescapeHTML } from '@lumapps/utils/string/unescapeHTML';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockImagePreview as BlockImagePreviewProps } from '../../types';

import './index.scss';

const ASPECT_RATIO_MAP: Record<BlockImagePreviewProps['imageFill'], AspectRatio> = {
    original: AspectRatio.original,
    width: AspectRatio.horizontal,
    height: AspectRatio.vertical,
};

const CAPTION_POSITION_MAP: Record<BlockImagePreviewProps['captionPosition'], ImageBlockCaptionPosition> = {
    below: ImageBlockCaptionPosition.below,
    cover: ImageBlockCaptionPosition.over,
};

export const BlockImagePreview: BlockComponent<BlockImagePreviewProps> = ({
    captionPosition,
    description,
    name,
    imageFill,
    image,
    theme,
}) => {
    const thumbnail = useMemo(() => {
        const alt = name || description || image.url;

        return {
            alt,
            image: image.url,
        };
    }, [description, image.url, name]);

    // Unescape, so that & that have been converted to &amp; are displayed as &
    const sanitizedDescription = unescapeHTML(sanitizeHTML(description));

    const imageFullSize = useMemo(() => {
        return [
            {
                title: name,
                description: sanitizedDescription,
                alt: thumbnail.alt,
                image: resizeImage(thumbnail.image, MAX_IMAGE_SIZE),
            },
        ];
    }, [sanitizedDescription, name, thumbnail]);

    const { getTriggerProps } = useImageLightboxProvider(imageFullSize);

    return (
        <ImageBlock
            className="block-image-preview"
            captionPosition={CAPTION_POSITION_MAP[captionPosition]}
            description={sanitizedDescription}
            image={thumbnail.image}
            theme={theme}
            title={name}
            alt={thumbnail.alt}
            align={Alignment.center}
            thumbnailProps={{
                aspectRatio: ASPECT_RATIO_MAP[imageFill],
                ...getTriggerProps?.(),
                style: { width: '100%' },
            }}
        />
    );
};
