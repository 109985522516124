import { isContributionAlphaFeatureEnabled } from '@lumapps/contents/ducks/selectors';
import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

const isContentBulkAllowed = createSelector(
    isSuperAdmin,
    isInstanceSuperAdmin,
    isContributionAlphaFeatureEnabled,
    (isGlobalAdmin, isSiteAdmin, isContributionAlpha) => {
        /** Allow if the user is a global admin or a site admin and the feature is enabled */
        return (isGlobalAdmin || isSiteAdmin) && isContributionAlpha;
    },
);

export { isContentBulkAllowed };
