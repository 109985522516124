import { canViewAnalyticsAdmin } from '@lumapps/analytics/ducks/selectors';
import { canAccessBroadcast } from '@lumapps/broadcasts/ducks/selectors';
import { actions as communitiesActions } from '@lumapps/communities/ducks/frontOfficeSlice';
import { isCommunitiesAllowed, isNewCommunityNavigationEnabled } from '@lumapps/communities/ducks/selectors';
import {
    isCustomContentTypesLoading,
    getCustomContentTypes,
    getPermissionsForCustomContentTypes,
    doesCurrentUserHaveAccessToCustomContentTypes,
    getParentCustomContentTypeIds,
} from '@lumapps/content-types/ducks/selectors';
import { fetchCustomContentTypes } from '@lumapps/content-types/ducks/thunks';
import { isDirectoriesAllowed, getInstanceDirectories, isModuleAllowed } from '@lumapps/directories/ducks/selectors';
import { isAdminSettingsAllowed } from '@lumapps/instance-settings/ducks/selectors';
import { canAccessJourneysAdmin } from '@lumapps/journeys-admin/ducks/selectors';
import { isMetadataEditAllowed } from '@lumapps/metadata/ducks/selectors';
import { hasMainNavAdminAccess } from '@lumapps/navigation-admin/ducks/selectors';
import { isNewsletterAllowed } from '@lumapps/newsletter/ducks/selectors';
import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';
import {
    isSAEnabled,
    canManagePlaftormPrograms,
    canManagePlatformSocialNetworks,
} from '@lumapps/sa-connected-user/ducks/selectors';
import { canAccessSegmentAdmin } from '@lumapps/segment/ducks/slice/selectors';
import { isSpaceEditableByUser } from '@lumapps/spaces/ducks/selectors';
import { isConnected } from '@lumapps/user/ducks/selectors';

import { SettingsMenu } from './components/SettingsMenu';

const mapStateToProps = (state: FrontOfficeStore) => ({
    isVisible: isConnected(state),
    // Community Section
    isNewCommunityNavigationEnabled: isNewCommunityNavigationEnabled(state),
    // Space Section
    canEditSpace: isSpaceEditableByUser(state),
    // Custom content types section
    isLoading: isCustomContentTypesLoading(state),
    customContentTypes: getCustomContentTypes(state),
    parentCustomContentTypesIds: getParentCustomContentTypeIds(state),
    isSuperAdmin: isInstanceSuperAdmin(state),
    allowedCustomContentTypes: getPermissionsForCustomContentTypes(state),
    isAllowedToAccessCCT: doesCurrentUserHaveAccessToCustomContentTypes(state),
    // Modules Section
    canAccessModules: isModuleAllowed(state),
    canAccessCommunities: isCommunitiesAllowed(state),
    canEditDirectories: isDirectoriesAllowed(state),
    directories: getInstanceDirectories(state),
    canEditNewsletter: isNewsletterAllowed(state),
    // SA Section
    SAEnabled: isSAEnabled(state),
    userCanManagePlatformPrograms: canManagePlaftormPrograms(state),
    userCanManagePlatformSocialNetworks: canManagePlatformSocialNetworks(state),
    // Content Strategy Section
    canAccessBroadcast: canAccessBroadcast(state),
    canAccessJourneys: canAccessJourneysAdmin(state),
    canAccessSegment: canAccessSegmentAdmin(state),
    canAccessContentStream: isSuperAdmin(state),
    // Site Section
    canAccessSiteAnalytics: canViewAnalyticsAdmin(state),
    hasMainNavAdminAccess: hasMainNavAdminAccess(state),
    canEditInstance: isAdminSettingsAllowed(state),
    hasMetadataAdminRights: isMetadataEditAllowed(state),
});
const mapDispatchToProps = {
    fetchCustomContentTypes,
    openCommunityNavigation: communitiesActions.setCommunityNavigationEdit,
    onCommunityEdit: communitiesActions.openConfigurationWizard,
};

const ConnectedSettingsMenu = connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);

export { ConnectedSettingsMenu as SettingsMenu };
