import React from 'react';

// eslint-disable-next-line no-shadow
import moment from 'moment';

import { Size, Thumbnail } from '@lumx/react';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { isConnected, isExternal, isMicrosoft } from 'components/services/user';

import { BODY_CONTENT_TYPE_VALUES, CHAT_PROVIDER_VALUES } from './chat_constants';

import imageMissingPng from 'assets/img/files/image-missing.png';

/**
 * Decode and extract HTML text for the message display in chat and apps.
 *
 * @param  {string} html Input html to decode.
 * @return {string} Raw text.
 */
const decodeHTML = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;

    return txt.value;
};

/**
 * Create the HTML component for the message attachments.
 *
 * @param  {Array}  attachment Input html to decode.
 * @return {string} Returns formatted Attachments content.
 */
const createHTMLAttachment = (attachment) => {
    if (attachment.thumbnailUrl) {
        return (
            <a href={attachment.contentUrl} target="_blank" rel="noopener noreferrer">
                {attachment.name}
                <img
                    alt={attachment.name}
                    src={attachment.thumbnailUrl}
                    onError={(e) => {
                        e.target.onerror = null;
                        if (e.target.src !== imageMissingPng) {
                            e.target.src = imageMissingPng;
                        }
                    }}
                />
            </a>
        );
    }
    if (attachment.previewUrl) {
        return (
            <a href={attachment.contentUrl} target="_blank" rel="noopener noreferrer">
                {attachment.name}
                <Thumbnail alt={attachment.name} image={attachment.previewUrl} size={Size.l} />
            </a>
        );
    }
    if (attachment.contentUrl) {
        return (
            <a href={attachment.contentUrl} target="_blank" rel="noopener noreferrer">
                {attachment.name}
            </a>
        );
    }

    return '';
};

/**
 * Create the HTML component for the message attachments.
 *
 * @param  {string} provider    Target chat provider.
 * @param  {Array}  attachments Input html to decode.
 * @return {string} Returns formatted Attachments content.
 */
const createHTMLAttachments = (provider, attachments) => {
    if (!attachments) {
        return '';
    }

    // Only for Slack right now.
    // Teams and Yammer have attachments in their body.
    if ([CHAT_PROVIDER_VALUES.SLACK.id, CHAT_PROVIDER_VALUES.FBWORKPLACE.id].includes(provider)) {
        return attachments.map((attachment) => {
            return createHTMLAttachment(attachment);
        });
    }

    return '';
};

/**
 * Format text before render.
 *
 * @param  {string} body        Message body.
 * @param  {Array}  attachments Message attachments linked.
 * @param  {string} provider    Target chat provider.
 *
 * @return {string} Returns formatted content.
 */
const getFormattedText = (body, attachments, provider) => {
    if (!body) {
        if (attachments) {
            return <div>{createHTMLAttachments(provider, attachments)}</div>;
        }

        return <></>;
    }

    let contentHTML = sanitizeHTML(body.content, { useProfiles: { html: true } });

    switch (body.contentType) {
        case BODY_CONTENT_TYPE_VALUES.HTML:
            if (provider === CHAT_PROVIDER_VALUES.YAMMER.id) {
                contentHTML = decodeHTML(contentHTML);
            }

            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
                    <div>{createHTMLAttachments(provider, attachments)}</div>
                </div>
            );

        default:
            return (
                <div>
                    {contentHTML}
                    <div>{createHTMLAttachments(provider, attachments)}</div>
                </div>
            );
    }
};

/**
 * Get format to render.
 *
 * @param  {string} body Message body.
 *
 * @return {string} Returns the content type.
 */
const getContentTypeFromMessage = (body) => {
    if (body && body.contentType) {
        return body.contentType;
    }

    return BODY_CONTENT_TYPE_VALUES.HTML;
};

/**
 * Checks if the message is recent.
 *
 * @param  {Object}  message    Message.
 * @param  {Date}    lastClosed Last time chat was closed.
 * @return {boolean} Returns whether the message is more recent than the last time the chat was closed.
 */
const isRelevant = (message, lastClosed) => {
    if (message.lastModifiedDateTime) {
        return moment(new Date(message.lastModifiedDateTime)).utc() >= lastClosed;
    }

    return moment(new Date(message.createdDateTime)).utc() >= lastClosed;
};

/**
 * Get notification count for new incoming messages.
 *
 * @param  {Array}  messages   List of received messages.
 * @param  {Date}   lastClosed Chat last closed date.
 * @return {number} Returns the number of new messages.
 */
const getNotificationCount = (messages, lastClosed) => {
    return messages.filter((message) => isRelevant(message, lastClosed)).length;
};

/**
 * Check if the chat provider constraints are respected.
 *
 * @param  {string}  provider Target chat provider.
 * @param  {Array}   features List of enabled Feature Flags.
 *
 * @return {boolean} Returns if the chat fullfill the provider constraint.
 */
const checkChatFullfillment = (provider, features) => {
    if (provider === CHAT_PROVIDER_VALUES.SLACK.id && isConnected() && features && features.includes('slack')) {
        return true;
    }
    if (provider === CHAT_PROVIDER_VALUES.TEAMS.id && isConnected() && !isExternal() && isMicrosoft()) {
        return true;
    }
    if (
        provider === CHAT_PROVIDER_VALUES.YAMMER.id &&
        isConnected() &&
        !isExternal() &&
        isMicrosoft() &&
        features &&
        features.includes('yammer')
    ) {
        return true;
    }
    if (
        provider === CHAT_PROVIDER_VALUES.FBWORKPLACE.id &&
        isConnected() &&
        features &&
        features.includes('workplace')
    ) {
        return true;
    }

    return false;
};

export { checkChatFullfillment, getContentTypeFromMessage, getFormattedText, getNotificationCount, isRelevant };
