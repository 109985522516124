import { connect } from '@lumapps/redux/react';

import { ChatAdviseJoin } from './ChatAdviseJoin';

const mapStateToProps = (_state, ownProps) => {
    const { channelUrl } = ownProps;

    return {
        channelUrl,
    };
};

const connectedChatAdviseJoin = connect(mapStateToProps)(ChatAdviseJoin);

/////////////////////////////

export { connectedChatAdviseJoin as ChatAdviseJoin };
