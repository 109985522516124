import BaseApi, { makeParamsSerializer, BaseApiResponse } from '@lumapps/base-api';
import type { Video } from '@lumapps/play/api/types';
import { getLanguageHeader } from '@lumapps/play/utils';

import type { Playlist } from '../types';
import type {
    CreatePlaylistData,
    GetPlaylistsParams,
    GetPlaylistsResponse,
    UpdatePlaylistData,
    GetPlaylistParams,
    GetPlaylistVideosParams,
} from './types';

/**
 * Playlist API.
 */
export const playlistApi = new BaseApi({
    version: BaseApi.versions.v2,
});

/**
 * Get list of playlists with full details - Designated for edition
 *
 * @param {GetPlaylistsParams} [params] - List of query parameters to pass
 *
 * @example getPlaylists({ maxResults: 5 }) - Return a list of 5 playlists
 *
 * @returns {Promise} Promise of GetPlaylistsResponse.
 */
export async function getPlaylists(params?: GetPlaylistsParams, fetchKey?: string) {
    return playlistApi.get<GetPlaylistsResponse>(
        '/play/playlists',
        {
            headers: { 'Accept-Language': getLanguageHeader() },
            params,
        },
        true,
        true,
        fetchKey,
    );
}

/** Function to cancel the get list of playlists */
export function cancelGetPlaylists(fetchKey?: string) {
    return playlistApi.cancel('', undefined, fetchKey);
}

export async function getPlaylist<Field extends keyof Playlist>(
    parameters: GetPlaylistParams<Field>,
    fetchKey?: string,
) {
    const { id, ...params } = parameters;
    const endpoint = params?.fields ? `/playlists/${id}` : `/play/playlists/${id}`;

    return playlistApi.get<Playlist>(
        endpoint,
        {
            headers: { 'Accept-Language': getLanguageHeader() },
            params: { ...params },
            paramsSerializer: makeParamsSerializer({ arrayFormat: 'comma', encode: false }),
        },
        true,
        true,
        fetchKey,
    );
}

/** Function to cancel the unique playlist get */
export function cancelGetPlaylist(fetchKey?: string) {
    return playlistApi.cancel('', undefined, fetchKey);
}

/**
 * Create a new playlist.
 *
 * @param {CreatePlaylistData} data - Payload needed to create a new playlist
 *
 * @example createPlaylist(data: CreatePlaylistData);
 *
 * Note: The id of the newly created playlist can be retrieved via the header "Content-Location" sent in the response.
 */
export async function createPlaylist(data: CreatePlaylistData): Promise<string> {
    const { headers } = await playlistApi.post('/play/playlists', data);
    if (!headers) {
        return '';
    }
    const contentLocationPlaylist = headers['content-location']?.split('/');
    const playlistId = contentLocationPlaylist[contentLocationPlaylist.length - 1];
    return playlistId;
}

/**
 * Update a playlist
 *
 * @param {string} playlistId - ID of the playlist we want to update.
 * @param {UpdatePlaylistData} data - Request body needed to update a playlist
 *
 * @example updatePlaylist('1', { status: 'published' });
 */
export async function updatePlaylist(playlistId: Playlist['id'], data: UpdatePlaylistData): Promise<void> {
    await playlistApi.patch(`/play/playlists/${playlistId}`, data);
}

/**
 * Delete a playlist
 *
 * @param {string} playlistId - ID of the playlist we want to delete.
 *
 * @example deletePlaylist('1');
 */
export async function deletePlaylist(playlistId: Playlist['id']): Promise<void> {
    await playlistApi.delete(`/play/playlists/${playlistId}`);
}

/**
 * Get the videos added to the given playlist
 *
 * @param {GetPlaylistVideosParams} parameters - The playlist id + list of query params
 *
 * @example getPlaylistVideos({
 *      id: '1234',
 *      maxResults: 4,
 *      fields: ['title', 'description']
 *     })
 */
export const getPlaylistVideos = async (parameters: GetPlaylistVideosParams): Promise<BaseApiResponse<Video[]>> => {
    const { id, ...params } = parameters;
    const endpoint = `/playlists/${id}/videos`;

    return playlistApi.get<Video[]>(
        endpoint,
        {
            headers: { 'Accept-Language': getLanguageHeader() },
            params,
            paramsSerializer: makeParamsSerializer({ arrayFormat: 'comma', encode: false }),
        },
        true,
        true,
    );
};

/** Function to cancel the get of playlist videos */
export function cancelGetPlaylistVideos(parameters: GetPlaylistVideosParams, fetchKey?: string) {
    const { id, ...params } = parameters;
    const endpoint = `/playlists/${id}/videos`;

    return playlistApi.cancel(endpoint, params, fetchKey);
}
