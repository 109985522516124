import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { VideoDate } from '@lumapps/play/components/VideoDate';
import { handleVideoDatePrefix } from '@lumapps/play/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-video-date` as const;

interface DateProps extends Omit<TextProps, 'as'> {
    /**
     * Which date is displayed?
     * @default createdAt
     */
    is?: 'createdAt' | 'updatedAt';
    /** Whether to display add a prefix to the date  */
    withPrefix?: boolean;
}

/**
 * Displays the date of a video
 */
export const Date = ({ is = 'createdAt', typography = Typography.body1, withPrefix, ...textProps }: DateProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    const { canDisplay, isLoading, video, theme } = useBlockVideoContext();

    if (!canDisplay('DATE')) {
        return null;
    }

    if (isLoading) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="15%" />;
    }

    if (!video?.date) {
        return null;
    }

    const publishedAtDate =
        (video.status === 'published' || video.publicationStatus === 'published') && video.publishedAt;

    return (
        <Text {...textProps} as="span" truncate>
            {is === 'updatedAt' && withPrefix && (
                <>
                    {handleVideoDatePrefix({
                        date: video.date,
                        keyWithSuffix: translateKey(GLOBAL.UPDATED_ON),
                        keyWithoutSuffix: translateKey(GLOBAL.UPDATED),
                    })}
                    &nbsp;
                </>
            )}

            <VideoDate capitalize={!withPrefix} date={publishedAtDate || video.date} />
        </Text>
    );
};
