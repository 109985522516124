import React from 'react';

import { UseFetchProps, UseFetchPropsReturn, useFetchWithStatus, FetchProps } from './useFetchWithStatus';

export interface UsePaginatedFetchWithStatusProps<ApiResponseType> extends UseFetchProps<ApiResponseType> {
    /** The field name in the response that holds the items list. Default to 'items' */
    itemsFieldName?: string;
}

/**
 * Hook that will call and behaves like useFetchWithStatus, but keep in its response the whole preceding fetches.
 * Refer to useFetchWithStatus hook for the params descriptions.
 */
export const usePaginatedFetchWithStatus = <ApiResponseType,>({
    itemsFieldName = 'items',
    ...forwardedProps
}: UsePaginatedFetchWithStatusProps<ApiResponseType> = {}): UseFetchPropsReturn<ApiResponseType> => {
    const { fetch, ...ret } = useFetchWithStatus<ApiResponseType>(forwardedProps);

    // Using refs to avoid necessary update.
    const previousPageItemsRef = React.useRef<any[]>([]);
    const currentPageItemsRef = React.useRef<any[]>([]);

    /**
     * Overwrite useFetchWithStatus' fetch to manage the pagination state.
     * If no fetchMore, it's a new fetch so we empty the state.
     */
    const paginatedFetch = React.useCallback(
        ({ fetchMore, ...props }: FetchProps<ApiResponseType> = {}) => {
            if (!fetchMore) {
                previousPageItemsRef.current = [];
                currentPageItemsRef.current = [];
            } else {
                previousPageItemsRef.current.push(...currentPageItemsRef.current);
            }
            fetch({ fetchMore, ...props });
        },
        [fetch],
    );

    const responseItems = (ret as any).response?.[itemsFieldName];
    React.useEffect(() => {
        if (responseItems) {
            // Update current page ref
            currentPageItemsRef.current = responseItems || [];
        }
    }, [responseItems]);

    return {
        ...ret,
        fetch: paginatedFetch,
        response: React.useMemo<any>(() => {
            if (!ret.response) {
                return undefined;
            }
            return {
                ...ret.response,
                // Prepend previous page to current response items
                [itemsFieldName]: [...previousPageItemsRef.current, ...(responseItems || [])],
            };
        }, [itemsFieldName, responseItems, ret.response]),
    };
};
