import first from 'lodash/first';
import get from 'lodash/get';
import map from 'lodash/map';

/////////////////////////////

function ModuleCommunityShareDialogController($controller, $scope, $window, Feed, ModuleAdmin, Utils) {
    'ngInject';

    const vm = this;

    $controller('CommunityBuilderController', {
        $scope: vm,
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list of added groups to the community.
     */
    vm.addedGroups = [];

    /**
     * The list of added groups ids to the community.
     */
    vm.addedGroupIds = [];

    /**
     * The list of added users to the community.
     *
     * @type {Array}
     */
    vm.addedUsers = [];

    /**
     * The calendar applyed to the community.
     *
     * @type {Object}
     */
    vm.calendar = {};

    /**
     * The identifier of the MS Calendar share dialog.
     *
     * @type {string}
     */
    vm.dialogIdMsShare = ModuleAdmin.dialogKeys.communityMsShare;

    /**
     * The privacy of the community.
     *
     * @type {string}
     */
    vm.privacy = undefined;

    /**
     * The list of removed groups to the community.
     *
     * @type {Array}
     */
    vm.removedGroups = [];

    /**
     * The list of removed groups ids to the community.
     *
     * @type {Array}
     */
    vm.removedGroupIds = [];

    /**
     * The list of removed users to the community.
     *
     * @type {Array}
     */
    vm.removedUsers = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Retrieve the first group mail adress from the group.
     *
     * @param  {Object} group The current group.
     * @return {string} The first mail defined in the group.
     */
    function getGroupMail(group) {
        return get(first(group.groups), 'group');
    }

    /**
     * Redirect the user to MS calendar in order to apply manually the share options.
     */
    function goToCalendar() {
        $window.open('https://outlook.office.com/owa/?path=/calendar/view/Month', '_blank');
    }

    /**
     * Check if the is at least one group with a mail adress in the list of groups.
     *
     * @param  {Array}   groups The list of groups.
     * @return {boolean} Whether there is at least one group with a mail adress.
     */
    function hasMailGroups(groups) {
        return angular.isDefinedAndFilled(map(groups, 'groups.group'));
    }

    /////////////////////////////

    vm.getGroupMail = getGroupMail;
    vm.goToCalendar = goToCalendar;
    vm.hasMailGroups = hasMailGroups;

    /////////////////////////////

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Initialize the community when we open the dialog.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The identifier of the dialog triggering the open start event.
     */
    $scope.$on('lx-dialog__open-start', function onCommunityDialogOpen(evt, dialogId, params) {
        if (dialogId !== vm.dialogIdMsShare) {
            return;
        }

        vm.addedGroupIds = params.addedGroupIds;
        vm.addedUsers = params.addedUsers;
        vm.calendar = params.calendar;
        vm.privacy = params.privacy;
        vm.removedGroupIds = params.removedGroupIds;
        vm.removedUsers = params.removedUsers;

        if (angular.isDefinedAndFilled(vm.addedGroupIds)) {
            Feed.getMulti(vm.addedGroupIds).then(function onGetFeed(feeds) {
                vm.addedGroups = feeds.items;
            });
        }

        if (angular.isDefinedAndFilled(vm.removedGroupIds)) {
            Feed.getMulti(vm.removedGroupIds).then(function onGetFeed(feeds) {
                vm.removedGroups = feeds.items;
            });
        }
    });
}

/////////////////////////////

angular.module('Controllers').controller('ModuleCommunityShareDialogController', ModuleCommunityShareDialogController);

/////////////////////////////

export { ModuleCommunityShareDialogController };
