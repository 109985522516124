import React from 'react';

import { TeamKpi } from '@lumapps/learning-team-kpi/components/TeamKpi';
import { isLearningAlphaEnabled, isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { TeamKpiProps } from '../../types';

export const ClientComputedTeamKpi: React.FC<TeamKpiProps> = ({ uuid }) => {
    const dispatch = useDispatch();
    const learningEnabled = useSelector(isLearningEnabled);
    const learningAlphaEnabled = useSelector(isLearningAlphaEnabled);

    const onLoad = (data: string | undefined) => {
        if (!data) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    };
    if (!learningEnabled) {
        return null;
    }
    return <TeamKpi onLoad={onLoad} sameCluster={learningAlphaEnabled} />;
};
