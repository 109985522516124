/**
 * Wrap the decodeURIComponent native function
 * Handle % and encode it (not handled by the native function)
 * Handle decode exception
 * @param s the uri to decode
 * @returns decoded uri
 */
export const decodeURIComponentSafe = (s: string) => {
    try {
        if (!s) {
            return s;
        }
        return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
    } catch (ex) {
        return s;
    }
};

/**
 * Wrap the encodeURIComponent native function
 * Encode % char if any
 * You should use this function if you are dealing with dynamic parameter comming from a text input in your url like /search/{query}
 * @param s the uri to encode
 * @returns encoded uri
 */
export const encodeURIComponentSafe = (s: string) => (s ? encodeURIComponent(s.replace(/%/g, '%25')) : s);
