export const LINK_PREVIEW = 'link-preview';

/** Types of resources that can be included in the rich text editor */
export enum LINK_PREVIEW_RESOURCE_TYPES {
    ARTICLE = 'article',
    COMMUNITY = 'community',
    CONTENT = 'content',
    LEARNING_PATH = 'learning_path',
    MICRO_APP = 'micro_app',
    PLAY_VIDEO = 'play_video',
    PLAY_VIDEO_PLAYLIST = 'play_video_playlist',
    POST = 'post',
    TRAINING_COURSE = 'training_course',
}
