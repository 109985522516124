/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable spaced-comment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable id-blacklist */
/* eslint-disable no-use-before-define */
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import {
    listChannelsForUserSpace,
    listJoinedUserSpaces,
    listMyChannelsForUserSpace,
} from '@lumapps/chat/api/legacyApi';
import { getProviderNameFromId } from 'components/components/chat/utils/chat_utils';

import { listProviders } from 'components/components/wall/api/WallApi';

import { error } from 'components/services/notification';

import { translate as t } from 'components/translations';

import { ORDER_BY, POST_TYPES, FB_POST_TYPES } from 'front/modules/wall/js/wall_constant';

function WidgetWallSettingsController($log, $q, $rootScope, $timeout, Config, Content, ContentTemplate, Widget) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetWallSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current widget instance.
     *
     * @type {Object}
     */

    let _currentWidget = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * A list of groups (full objects) to filter the post list on.
     *
     * @type {Array}
     */
    widgetWallSettings.userSpaces = {};

    /**
     * A list of channels of a selected group.
     *
     * @type {Array}
     */
    widgetWallSettings.channels = [];

    /**
     * Providers loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.providersLoading = false;

    /**
     * The user spaces list call response data (the current page and the HasMOre attribute).
     *
     * @type {Object}
     */
    const _userSpaceListOptions = {
        hasMore: false,
        page: undefined,
    };

    /**
     * User spaces loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.userSpacesLoading = false;

    /**
     * User spaces more loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.userSpacesMoreLoading = false;

    /**
     * Channels loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.channelsLoading = false;

    /**
     * Channels more loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.channelsMoreLoading = false;

    /**
     * The channels list call response data (the current page and the HasMOre attribute).
     *
     * @type {Object}
     */
    const channelListOptions = {
        cursor: undefined,
        hasMore: false,
        page: undefined,
    };

    /**
     * Channels loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.myChannelsLoading = false;

    /**
     * Channels more loading state.
     *
     * @type {Boolean}
     */
    widgetWallSettings.myChannelsMoreLoading = false;

    /**
     * The channels list call response data (the current page and the HasMOre attribute).
     *
     * @type {Object}
     */
    const myChannelListOptions = {
        hasMore: false,
        page: undefined,
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Function that update a value of a properties of the current widget.
     *
     * @param {string} key   The property's key.
     * @param {string} value The property's value.
     */
    function updateWidget(key, value) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        set(properties, [key], value);
        currentWidget.properties = properties;
    }

    /**
     * Get user spaces depending on selected provider;
     */
    function getUserSpaces() {
        _currentWidget = Widget.getCurrent();
        widgetWallSettings.userSpacesLoading = true;
        const provider = get(_currentWidget, 'properties.provider', null);

        if (!provider) {
            return;
        }
        listJoinedUserSpaces({ provider: provider.name })
            .then((response) => {
                const { data } = response;
                _userSpaceListOptions.hasMore = data.more;
                _userSpaceListOptions.page = data.page;

                widgetWallSettings.userSpaces = get(data, 'items', []);
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetWallSettings.userSpacesLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });
    }

    /**
     * Load the next page of user spaces.
     *
     * @return {Promise} The call promise.
     */
    function loadNextUserSpaces() {
        const defer = $q.defer();

        if (!_userSpaceListOptions.hasMore) {
            defer.resolve([]);

            return defer.promise;
        }

        const provider = get(_currentWidget, 'properties.provider', null);

        widgetWallSettings.userSpacesMoreLoading = true;

        listJoinedUserSpaces({ page: _userSpaceListOptions.page + 1, provider })
            .then((response) => {
                const { data } = response;
                _userSpaceListOptions.hasMore = data.more;
                _userSpaceListOptions.page = data.page;

                defer.resolve(get(data, 'items', []));
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
                widgetWallSettings.userSpacesMoreLoading = false;
            })
            .finally(() => {
                widgetWallSettings.userSpacesMoreLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });

        return defer.promise;
    }

    /////////////////////////////

    /**
     * Get channels on user space selection.
     */
    function getChannels() {
        _currentWidget = Widget.getCurrent();
        widgetWallSettings.channelsLoading = true;
        const provider = get(_currentWidget, 'properties.provider.name', null);
        const spaceId = get(_currentWidget, 'properties.userSpace.id', null);

        listChannelsForUserSpace({
            provider,
            spaceId,
        })
            .then((response) => {
                const { data } = response;
                channelListOptions.cursor = data.cursor;
                channelListOptions.hasMore = data.more;
                channelListOptions.page = data.page;

                widgetWallSettings.channels = get(data, 'items', []);
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_CHANNELS_FOR_GROUP_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetWallSettings.channelsLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });
    }

    /**
     * Load the next page of channels.
     *
     * @return {Promise} The call promise.
     */
    function loadNextChannels() {
        const defer = $q.defer();

        if (!channelListOptions.hasMore) {
            defer.resolve([]);

            return defer.promise;
        }

        const provider = get(_currentWidget, 'properties.provider.name', null);
        const spaceId = get(_currentWidget, 'properties.userSpace.id', null);

        widgetWallSettings.channelsMoreLoading = true;

        listChannelsForUserSpace({
            page: channelListOptions.page + 1,
            cursor: channelListOptions.cursor,
            provider,
            spaceId,
        })
            .then((response) => {
                const { data } = response;
                channelListOptions.cursor = data.cursor;
                channelListOptions.hasMore = data.more;
                channelListOptions.page = data.page;

                defer.resolve(get(data, 'items', []));
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
                widgetWallSettings.channelsMoreLoading = false;
            })
            .finally(() => {
                widgetWallSettings.channelsMoreLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });

        return defer.promise;
    }

    /**
     * Get channels on user space selection.
     */
    function getMyChannels() {
        _currentWidget = Widget.getCurrent();
        widgetWallSettings.myChannelsLoading = true;
        const provider = get(_currentWidget, 'properties.provider.name', null);
        const spaceId = get(_currentWidget, 'properties.userSpace.id', null);

        listMyChannelsForUserSpace({
            provider,
            spaceId,
        })
            .then((response) => {
                const { data } = response;
                myChannelListOptions.hasMore = data.more;
                myChannelListOptions.page = data.page;

                widgetWallSettings.myChannels = get(data, 'items', []);
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_CHANNELS_FOR_GROUP_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetWallSettings.myChannelsLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });
    }

    /**
     * Load the next page of channels.
     *
     * @return {Promise} The call promise.
     */
    function loadMyNextChannels() {
        const defer = $q.defer();

        if (!myChannelListOptions.hasMore) {
            defer.resolve([]);

            return defer.promise;
        }

        const provider = get(_currentWidget, 'properties.provider', null);
        const spaceId = get(_currentWidget, 'properties.userSpace.id', null);

        widgetWallSettings.myChannelsMoreLoading = true;

        listMyChannelsForUserSpace({ page: myChannelListOptions.page + 1, provider, spaceId })
            .then((response) => {
                const { data } = response;
                myChannelListOptions.hasMore = data.more;
                myChannelListOptions.page = data.page;

                defer.resolve(get(data, 'items', []));
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetWallSettings.myChannelsMoreLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });

        return defer.promise;
    }

    /**
     * Handle provider selection.
     * @param {string} value Selected provider.
     */
    function selectProvider(value) {
        _currentWidget = Widget.getCurrent();
        widgetWallSettings.channels = [];
        widgetWallSettings.userSpaces = [];
        widgetWallSettings.availablePostTypes = value.name === 'fbworkplace' ? FB_POST_TYPES : POST_TYPES;

        _currentWidget.properties.postType = 'ALL';
        _currentWidget.properties.channel = null;
        _currentWidget.properties.userSpace = null;
        _currentWidget.properties.provider = value;

        getUserSpaces();
    }

    /**
     * Handle user space selection.
     */
    function selectUserSpace() {
        _currentWidget = Widget.getCurrent();
        widgetWallSettings.channels = [];
        _currentWidget.properties.channel = null;

        const currentContentType = getProperty(['contentType']);
        if (currentContentType === 'currentUserGroups') {
            getMyChannels();
        } else {
            getChannels();
        }
    }

    /**
     * Function that initialize the current contentType.
     *
     * @return {string} The current contentType tag.
     */
    function getContentType() {
        const currentContentType = getProperty(['contentType']);
        if (angular.isUndefinedOrEmpty(currentContentType)) {
            return 'currentUserGroups';
        }

        return currentContentType;
    }

    function getProperty(key) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        return get(properties, key);
    }

    /**
     * Switch widget view mode.
     *
     * @param {string} viewMode The wanted view mode.
     */
    function switchViewMode(viewMode) {
        const currentViewMode = getProperty(['viewMode']);

        if (currentViewMode === viewMode) {
            return;
        }

        updateWidget('viewMode', viewMode);
    }

    /**
     * Function that set the default value for some properties.
     */
    function initFields() {
        const properties = get(_currentWidget, 'properties');
        widgetWallSettings.availablePostTypes =
            properties.provider && properties.provider.name === 'fbworkplace' ? FB_POST_TYPES : POST_TYPES;
        widgetWallSettings.availableOrderBy = ORDER_BY;

        if (angular.isUndefinedOrEmpty(properties.viewMode)) {
            switchViewMode('list');
        }

        if (angular.isUndefinedOrEmpty(properties.orderBy)) {
            properties.orderBy = 'CREATED';
        }

        if (angular.isUndefinedOrEmpty(properties.postType)) {
            properties.postType = 'ALL';
        }

        if (angular.isUndefinedOrEmpty(properties.listOrderDir)) {
            properties.listOrderDir = 'asc';
        }

        if (angular.isUndefinedOrEmpty(properties.maxNumber)) {
            updateWidget('maxNumber', 10);
        }

        if (angular.isUndefinedOrEmpty(properties.itemsPerLine)) {
            properties.itemsPerLine = 1;
        }

        if (angular.isUndefinedOrEmpty(properties.slideshowAutoplay)) {
            properties.slideshowAutoplay = true;
        }

        if (angular.isUndefinedOrEmpty(properties.slideshowInterval)) {
            properties.slideshowInterval = 2000;
        }

        set(_currentWidget.properties, properties);
    }

    function toggleContentType(contentType = getContentType()) {
        const oldContentType = _currentWidget.properties.contentType;

        if (oldContentType !== contentType) {
            _currentWidget.properties.channel = null;
            _currentWidget.properties.userSpace = null;
            _currentWidget.properties.targetedPost = null;
        }
        updateWidget('contentType', contentType);
    }

    /**
     * Return if current group is Empty.
     *
     * @param {string}   type The wanted filter type.
     * @return {boolean} Return true if can be filter on type.
     */
    function hasFilter(type) {
        if (!_currentWidget.properties.provider) {
            return false;
        }

        if (
            _currentWidget.properties.provider.name === 'fbworkplace' &&
            type !== 'PROPERTIES' &&
            type !== 'RESTRICTIONS'
        ) {
            return false;
        }

        return true;
    }

    /////////////////////////////
    //                         //
    //    Public functions     //
    //                         //
    /////////////////////////////

    /**
     * Return if current group is Empty.
     *
     * @return {boolean} Return true if the group is Empty.
     */
    function isGroupEmpty() {
        return isEmpty(getProperty(['userSpace']));
    }

    /**
     * If this widget is now a "Main Widget", remove the isMainWidget attribute to the other widgets.
     */
    function setAsMainWidget() {
        const currentWidget = Widget.getCurrent();
        if (currentWidget.isMainWidget) {
            const widgetList = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isMainWidget', true);
            angular.forEach(widgetList, function forEachWidget(widget) {
                if (widget.uuid !== currentWidget.uuid) {
                    widget.isMainWidget = false;
                }
            });
        }

        $rootScope.$broadcast('widget-is-main-toggled', currentWidget.uuid, currentWidget.isMainWidget);
    }

    /////////////////////////////

    widgetWallSettings.setAsMainWidget = setAsMainWidget;
    widgetWallSettings.toggleContentType = toggleContentType;
    widgetWallSettings.Config = Config;
    widgetWallSettings.switchViewMode = switchViewMode;
    widgetWallSettings.isGroupEmpty = isGroupEmpty;
    widgetWallSettings.hasFilter = hasFilter;

    widgetWallSettings.loadNextUserSpaces = loadNextUserSpaces;
    widgetWallSettings.loadNextChannels = loadNextChannels;
    widgetWallSettings.loadMyNextChannels = loadMyNextChannels;
    widgetWallSettings.selectUserSpace = selectUserSpace;
    widgetWallSettings.selectProvider = selectProvider;
    widgetWallSettings.getProviderNameFromId = getProviderNameFromId;

    /////////////////////////////

    /**
     * The Initialize functions.
     */
    function initUserSpaces() {
        if (!_currentWidget.properties.provider) {
            return;
        }
        updateWidget('provider', _currentWidget.properties.provider);
        getUserSpaces();

        if (_currentWidget.properties.userSpace) {
            if (getContentType() === 'currentUserGroups') {
                getMyChannels();
            } else {
                getChannels();
            }
        }
    }

    function initProviders() {
        widgetWallSettings.providersLoading = true;

        listProviders()
            .then((response) => {
                widgetWallSettings.providers = get(response, 'data.providers', []);
            })
            .catch((exception) => {
                error(t('FRONT.CHAT.FETCH_PROVIDERS_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetWallSettings.providersLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-wall-settings', _currentWidget.uuid);
                });
                initUserSpaces();
            });
    }

    function init() {
        _currentWidget = Widget.getCurrent();
        _currentWidget.properties = _currentWidget.properties || {};

        widgetWallSettings.toggleContentType(getContentType());
        initProviders();
        initFields();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetWallSettingsController', WidgetWallSettingsController);

/////////////////////////////

export { WidgetWallSettingsController };
