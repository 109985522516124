import { AppId } from '@lumapps/constants/app';
import { ContentTypes } from '@lumapps/content-types/types';
import { legacyContentPath, newContentByIdSlugPath } from '@lumapps/contents/routes';
import { ContentLinkRef } from '@lumapps/contents/types';
import { Route } from '@lumapps/router';

export const directoryView = (params: { isLayoutEnabled: boolean; to: ContentLinkRef }): Route => {
    const { id, slug, title, externalKey, anchor, keepParentPath = false, instance, query, isV2Compatible } = params.to;

    const instanceSlug = instance?.slug;
    const correctedSlug = (keepParentPath ? slug : (slug?.split('/').pop() as string)) || '';

    /**
     * Implement the MobilePageDescriptor
     * Enable the webview routing
     * */
    const mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined =
        externalKey && title
            ? {
                  id: externalKey,
                  title,
                  type: ContentTypes.DIRECTORY,
              }
            : undefined;

    if (id && params.isLayoutEnabled && isV2Compatible) {
        return {
            appId: AppId.frontOffice,
            path: newContentByIdSlugPath,
            params: { slug: correctedSlug, id },
            ignoreParamEncoding: true,
            anchor,
            query,
            instanceSlug,
            mobilePageDescriptor,
        };
    }

    return {
        legacyId: 'app.front.content-get',
        appId: AppId.legacy,
        path: legacyContentPath,
        params: { slug: correctedSlug, id },
        ignoreParamEncoding: true,
        anchor,
        query,
        instanceSlug,
        mobilePageDescriptor,
    };
};
