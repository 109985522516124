import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { InlineDialog, InlineDialogProps } from '@lumapps/lumx-dialogs/components/InlineDialog';
import {
    ChipProps,
    DropdownProps,
    Button,
    ButtonProps,
    Emphasis,
    Tooltip,
    Placement,
    FlexBox,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { FilterTrigger } from '../FilterTrigger';

import './index.scss';

export interface FiltersDropdownProps {
    /** callback to be executed once the clear button on the main CTA is clicked */
    onClearClick: () => void;
    /** callback to be executed once the dropdown is closed */
    onClose: () => void;
    /** callback to be executed once the filter button is clicked */
    onFilter: () => void;
    /** callback to be executed once the clear button is clicked. */
    onClearAll?: () => void;
    /** callback executed once the filters are displayed */
    onOpen?: () => void;
    /** override the label displayed for the chip that will open the dropdown  */
    label?: string;
    /** whether the filter chip label should be truncated when needed */
    truncateLabel?: boolean;
    /** title of the dropdown, it default to GLOBAL.FILTERS */
    title?: string;
    /** whether the dropdown is open or not */
    isOpen?: boolean;
    /** whether the dropdown is disabled or not */
    isDisabled?: boolean;
    /** the total amount of selected filters to display in the button that opens the dropdown */
    totalSelectedDisplayedFilters?: number;
    /** list of selected filters */
    selectedFilter?: string[];
    /** props to be passed down to the dropdown trigger */
    triggerProps?: Partial<ChipProps>;
    /** props to be passed down to the dropdown */
    dropdownProps?: Partial<DropdownProps>;
    /** props to be passed down to the filter button */
    filterProps?: Partial<ButtonProps>;
    inlineDialogProps?: Partial<InlineDialogProps>;
}

const CLASSNAME = 'filters-dropdown';
const FORM_ID = `${CLASSNAME}-form`;
/**
 * Component that displays a list of filters inside a dropdown and manages the list of selected ones.
 * This component is highly customisable, so use only if you need to do some custom specific customisations.
 * If your filters are pretty straightforward, please use `Filters`.
 *
 * @family Filters
 * @param FiltersDropdownProps
 * @returns FiltersDropdown
 */
export const FiltersDropdown: React.FC<FiltersDropdownProps> = ({
    title,
    label,
    isOpen = false,
    onFilter,
    selectedFilter = [],
    children,
    onClearClick,
    onClose,
    triggerProps,
    dropdownProps,
    inlineDialogProps,
    filterProps,
    totalSelectedDisplayedFilters = 3,
    truncateLabel,
    isDisabled = false,
    onClearAll,
    onOpen,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(isOpen);
    const { translateKey } = useTranslate();
    const ref = React.useRef<HTMLAnchorElement>(null);

    React.useEffect(() => {
        setIsDropdownOpen(isOpen);
    }, [isOpen]);

    const onFilterClick = (event: any) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        onFilter();
        setIsDropdownOpen(false);
    };

    return (
        <Tooltip label={translateKey(GLOBAL.FILTERS)} placement={Placement.TOP}>
            <FilterTrigger
                onClearClick={onClearClick}
                setIsFilterDropdownOpen={(open, event) => {
                    if (open && onOpen) {
                        onOpen();
                    } else if (!open) {
                        onClose();
                    }

                    if (event.key === 'Enter') {
                        /**
                         * for some reason I cannot explain, if this set timeout is not added,
                         * the dropdown won't open if you are just using your keyboard.
                         */
                        setTimeout(() => setIsDropdownOpen(open));
                    } else {
                        setIsDropdownOpen(open);
                    }
                }}
                isDisabled={isDisabled}
                isFilterDropdownOpen={isDropdownOpen}
                label={label}
                selectedFilter={selectedFilter}
                totalSelectedDisplayedFilters={totalSelectedDisplayedFilters}
                triggerProps={triggerProps}
                truncateLabel={truncateLabel}
                anchorRef={ref}
            />

            <InlineDialog
                setIsOpen={setIsDropdownOpen}
                isOpen={isDropdownOpen}
                className={CLASSNAME}
                anchorRef={ref}
                onClose={() => {
                    onClose();
                    setIsDropdownOpen(false);
                }}
                title={title || translateKey(GLOBAL.FILTERS)}
                popoverProps={dropdownProps}
                wrapperProps={{
                    as: 'form',
                    className: element('filters'),
                    id: FORM_ID,
                    onSubmit: onFilterClick,
                    // Disable form auto validation and manage it ourselves
                    noValidate: true,
                }}
                footerProps={{
                    className: element('footer', { 'with-clear': Boolean(onClearAll) }),
                    after: (
                        <Button
                            form={FORM_ID}
                            type="submit"
                            /* eslint-disable-next-line react/no-children-prop */
                            children={translateKey(GLOBAL.FILTER)}
                            {...filterProps}
                            onClick={onFilterClick}
                        />
                    ),
                    before: onClearAll ? (
                        <Button emphasis={Emphasis.low} onClick={onClearAll}>
                            {translateKey(GLOBAL.CLEAR)}
                        </Button>
                    ) : null,
                }}
                {...inlineDialogProps}
            >
                <div className={element('inner')}>
                    <FlexBox orientation="vertical" gap="huge">
                        {children}
                    </FlexBox>
                </div>
            </InlineDialog>
        </Tooltip>
    );
};
