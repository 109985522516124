import React from 'react';

import noop from 'lodash/noop';

import { classnames } from '@lumapps/classnames';
import { Message, MessageProps, Size, SkeletonRectangle, Heading } from '@lumapps/lumx/react';

import { useCodeMirrorTextArea, UseCodeTextAreaOptions, MODES } from './useCodeMirrorTextArea';

export interface CodeTextAreaOptions extends UseCodeTextAreaOptions {
    /** optional className that will be used on the text area */
    className?: string;
    /** value for the text field */
    value?: string;
    /** title to be displayed for the field  */
    title?: string;
    /** optional message to be displayed between the title and the text area */
    message?: MessageProps;
    /** optional className to be displayed on this component's wrapper */
    wrapperClassName?: string;
    /** optional boolean to fold/unfold code mirror */
    isFolded?: boolean;
    /** optional boolean to if code mirror is read only */
    readOnly?: boolean;
    /** to set editor size */
    editorSize?: { w: number | string; h: number | string };
}

export { MODES };

const CLASSNAME = 'code-mirror-text-area';
/**
 * Text Area with syntax highlighting provided by Code Mirror. It needs to be used with the useThirdPartyLibrary hook
 * @param CodeTextAreaOptions
 * @returns CodeMirrorTextArea
 */
export const CodeMirrorTextArea: React.FC<CodeTextAreaOptions> = ({
    mode,
    hasFinishedLoading,
    id,
    className,
    value = '',
    setValue,
    title,
    message = null,
    shouldApplyHighlightSyntax = false,
    wrapperClassName,
    forceReload,
    overrideProps,
    isFolded,
    readOnly = false,
    editorSize,
    defaultIndentationLevel,
}) => {
    const textAreaId = `${CLASSNAME}__${id}`;
    useCodeMirrorTextArea({
        mode,
        hasFinishedLoading,
        id: textAreaId,
        setValue,
        shouldApplyHighlightSyntax,
        value,
        forceReload,
        overrideProps,
        isFolded,
        readOnly,
        editorSize,
        defaultIndentationLevel,
    });

    return (
        <div className={classnames(wrapperClassName, CLASSNAME, textAreaId)}>
            {title ? (
                <Heading
                    as="h3"
                    typography="subtitle2"
                    className="lumx-spacing-margin-top-huge lumx-spacing-margin-bottom-big"
                >
                    {title}
                </Heading>
            ) : null}
            {message ? <Message {...message} className="lumx-spacing-margin-bottom-big" /> : null}
            {hasFinishedLoading && shouldApplyHighlightSyntax ? (
                <textarea
                    id={textAreaId}
                    name={textAreaId}
                    className={className}
                    value={value}
                    style={{ display: 'none' }}
                    readOnly={readOnly}
                    // Handled by code mirror
                    onChange={noop}
                />
            ) : null}
            {!hasFinishedLoading || !shouldApplyHighlightSyntax ? <SkeletonRectangle height={Size.xxl} /> : null}
        </div>
    );
};
