/* istanbul ignore file */
import React, { Fragment, useCallback } from 'react';

import { classnames } from '@lumapps/classnames';
import { communityHomeRoute } from '@lumapps/communities/routes';
import { AppId } from '@lumapps/constants/app';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { Link } from '@lumapps/router';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { WIDGET_LEGACY_POST_LIST } from '../../keys';

import './CommunitiesVisibilityField.scss';

/**
 * Maximum number of communities displayed in the horizontal list before grouping them.
 */
const MAX_COMMUNITIES_IN_HORIZONTAL_LIST = 4;

/**
 * Displays the conjunction word AND.
 */
const CommunityListConjunction = ({ theme }) => {
    const { translate } = useTranslate();
    return (
        <span
            className={classnames('communities-visibility-field__conjunction', {
                'lumx-color-font-light-N': theme === Theme.dark,
                'lumx-color-font-dark-L2': theme === Theme.light,
            })}
        >
            {translate(GLOBAL.AND)}
        </span>
    );
};

/**
 * Displays a simple comma to seperate the elements of the lists of conjunction.
 */
const CommunityListCommaSeparator = () => <span className="communities-visibility-field__comma">,</span>;

/**
 * Displays a list of communities the post is shared in as an horizontal list of elements
 *
 * @return {JSX.Element | null} SimpleCommunityList component.
 */
const SimpleCommunityList = ({ communities, additionalCommunitiesCount, theme }) => {
    const { translate } = useTranslate();

    /**
     * Id of the current instance.
     */
    const currentInstanceId = useSelector(instanceIdSelector);

    /**
     * Returns the right type of separator to display before the next community title.
     *
     * @param {number} index the index of the next community title in the list of community titles.
     *
     * @return {JSX.Element | null} JSX element used as seperator.
     */
    const getSeparator = (index) => {
        if (index === 0) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }
        if (index === communities.length - 1) {
            if (additionalCommunitiesCount) {
                return <CommunityListCommaSeparator />;
            }

            return <CommunityListConjunction theme={theme} />;
        }

        return <CommunityListCommaSeparator />;
    };

    const getLinkProps = useCallback(
        (community) => {
            if (currentInstanceId === community.postedTo.instance) {
                return {
                    to: { ...communityHomeRoute, params: { slug: translate(community.postedTo.slug) } },
                };
            }

            return {
                to: {
                    appId: AppId.external,
                    path: translate(community.postedTo.canonicalUrl),
                },
                target: '_blank',
            };
        },
        [currentInstanceId, translate],
    );
    return (
        <>
            {communities.map((community, index) => (
                <Fragment key={community.postedTo.uid.toString()}>
                    {getSeparator(index)}
                    <Link className="communities-visibility-field__link" {...getLinkProps(community)}>
                        {translate(community.postedTo.title)}
                    </Link>
                </Fragment>
            ))}
        </>
    );
};

/**
 * Displays a list of communities the post is shared in.
 *
 * @return {JSX.Element | null} shared in communities component.
 */
export const CommunitiesVisibilityField = ({
    communities,
    privateCommunitiesCount,
    onOpenPostVisibilityDialog,
    theme,
}) => {
    /**
     * Called when the user clicks on the additional communities.
     * Uses the callback props passed to this react component to
     * lead to opening the dialog in angularjs
     *
     * @param {Event} evt The click event.
     */
    const openDialog = (evt) => {
        evt.stopPropagation();
        onOpenPostVisibilityDialog();
    };

    /**
     * Returns the number of extra communities that don't fit in the simple horizontal list of communities
     * if there are communities private to the current user, they are necessarily considered as additional communities
     * if there are exactly MAX_COMMUNITIES_IN_HORIZONTAL_LIST and there are no private communities, the last
     * community will be displayed normally with its title so this count will stay at 0.
     *
     * @return {number} the number of additional communities that won't be displayed in the simple list.
     */
    const getAdditionalCommunitiesCount = () => {
        return (
            privateCommunitiesCount +
            (communities.length === MAX_COMMUNITIES_IN_HORIZONTAL_LIST && privateCommunitiesCount > 0 ? 1 : 0) +
            (communities.length > MAX_COMMUNITIES_IN_HORIZONTAL_LIST
                ? communities.length - (MAX_COMMUNITIES_IN_HORIZONTAL_LIST - 1)
                : 0)
        );
    };

    /**
     * Returns the array of communities to display as a simple horizontal list.
     * The returned array will be used as a props for the SimpleCommunityList component.
     * The returned array contains MAX_COMMUNITIES_IN_HORIZONTAL_LIST elements except if there is an
     * additional communities link (to display in a dialog) in which case it contains one less element
     *
     * @return {Array} an array of communities.
     */
    const getFirstCommunities = () => {
        return [...communities].splice(
            0,
            getAdditionalCommunitiesCount() > 0
                ? MAX_COMMUNITIES_IN_HORIZONTAL_LIST - 1
                : MAX_COMMUNITIES_IN_HORIZONTAL_LIST,
        );
    };

    const { translateKey } = useTranslate();

    return (
        <div className="communities-visibility-field">
            <div
                className={classnames('communities-visibility-field__title', {
                    'lumx-color-font-light-N': theme === Theme.dark,
                    'lumx-color-font-dark-L2': theme === Theme.light,
                })}
            >
                {translateKey(WIDGET_LEGACY_POST_LIST.SHARED_WITH)}
            </div>
            {communities.length > 0 && (
                <>
                    <SimpleCommunityList
                        additionalCommunitiesCount={getAdditionalCommunitiesCount()}
                        communities={getFirstCommunities()}
                        theme={theme}
                    />
                    {getAdditionalCommunitiesCount() > 0 && <CommunityListConjunction theme={theme} />}
                </>
            )}
            {getAdditionalCommunitiesCount() > 0 && (
                <div
                    className="communities-visibility-field__other-communities"
                    onClick={openDialog}
                    onKeyPress={openDialog}
                    tabIndex="0"
                    role="button"
                >
                    {`${getAdditionalCommunitiesCount()} ${
                        getAdditionalCommunitiesCount() === 1
                            ? translateKey(GLOBAL.COMMUNITY)
                            : translateKey(GLOBAL.COMMUNITIES)
                    }`}
                </div>
            )}
        </div>
    );
};
