import { Card } from '@lumapps/lumx-cards/components/Card';
import {
    Message,
    Kind,
    Size,
    ColorPalette,
    Emphasis,
    FlexBox,
    Orientation,
    Alignment,
    Theme,
    Chip,
    Link,
    Thumbnail,
    ListItem,
    Toolbar,
    AspectRatio,
    ThumbnailVariant,
    List,
    ListSubheader,
    Avatar,
    Badge,
    ListDivider,
    SkeletonCircle,
    SkeletonRectangle,
    SkeletonTypography,
    Typography,
    Tooltip,
    Placement,
} from '@lumapps/lumx/react';

import { ComponentConstructor } from '../../types';
import { CustomizationButton, CustomizationIconButton, CustomizationIcon } from './CustomizationButton';
import { CustomizationContextualAction } from './CustomizationContextualAction';
import { CustomizationDialog } from './CustomizationDialog';
import { CustomizationDropdown } from './CustomizationDropdown';
import { CustomizationDropdownItem } from './CustomizationDropdownItem';
import { CustomizationDropdownSection } from './CustomizationDropdownSection';
import { CustomizationRawHTML } from './CustomizationRawHTML';
import { CustomizationText } from './CustomizationText';

/**
 * Here is the list of components that are available for the customizations API.
 * Due to performance and complexity concerns, not all components are exposed
 * and not all of them are the ones out of the box by DS.
 */
const ReactComponents = {
    Message,
    Kind,
    Size,
    ColorPalette,
    Emphasis,
    Orientation,
    Alignment,
    AspectRatio,
    Theme,
    ThumbnailVariant,
    Button: CustomizationButton,
    IconButton: CustomizationIconButton,
    Card,
    Chip,
    Link,
    Thumbnail,
    Tooltip,
    TooltipPlacement: Placement,
    ListItem,
    FlexBox,
    List,
    ListSubheader,
    Avatar,
    Badge,
    ListDivider,
    Icon: CustomizationIcon,
    DropdownSection: CustomizationDropdownSection,
    DropdownItem: CustomizationDropdownItem,
    Dropdown: CustomizationDropdown,
    RawHTML: CustomizationRawHTML,
    Toolbar,
    Dialog: CustomizationDialog,
    ContextualAction: CustomizationContextualAction,
    SkeletonCircle,
    SkeletonRectangle,
    SkeletonTypography,
    // eslint-disable-next-line deprecation/deprecation
    Text: CustomizationText,
};

const dsKeys = Object.keys(ReactComponents);
const components: Record<string, ComponentConstructor> = {};
const constants = {
    Typography,
};
const componentConstructor: ComponentConstructor = (component: string) => (props: any) => ({ component, props });

/**
 * In order to expose these components and constants in a proper manner, we create two variables,
 * components and constants, and depending whether the object is a component or an object, it will separate
 * those objects into two variables
 */
dsKeys.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const designSystemElement: any = ReactComponents[key];

    if (designSystemElement) {
        if (designSystemElement.displayName) {
            /**
             * If the object is a react element, instead of exposing the component itself, we expose
             * a function that will represent that component. It is just a function that, once executed,
             * it returns the component and the props used, which we will use afterwards in order to
             * render our react components
             */
            Object.assign(components, {
                [key]: componentConstructor(key),
            });
        } else {
            Object.assign(constants, {
                [key]: designSystemElement,
            });
        }
    }
});

export { components, constants, ReactComponents, componentConstructor };
