import React from 'react';

import { translate as t } from 'components/translations';

const ChatAdviseJoin = ({ channelUrl }) => {
    return (
        <div className="widget-chat-advise-join-channel widget-chat__advise-join">
            <a href={channelUrl} target="_blank" rel="noopener noreferrer">
                <p>{t('FRONT.WIDGET_CHAT.LABEL_ADVISE_JOIN_CHANNEL')}</p>
            </a>
        </div>
    );
};
export { ChatAdviseJoin };
