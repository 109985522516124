import isEmpty from 'lodash/isEmpty';

import { currentLanguageSelector } from '@lumapps/languages';
import { error as notifyError } from '@lumapps/notifications';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

import { Widget } from '../../types';
import { getWidgetTranslatableData } from '../../utils/getWidgetTranslatableData';
import { getWidgetTranslatedData } from '../../utils/getWidgetTranslatedData';
import { getTranslationEntity } from '../selectors';
import { actions } from '../slice';

/**
 * Translate widget text into currentLanguage.
 *
 * @param widget widget to translate.
 * @param translate  translate or rollback translation.
 * @param requestedLang  language to translate to.
 * @throws on API error.
 */
export const translateWidget =
    (widget: Widget, translate: boolean, requestedLang?: string, shouldResetTranslation?: boolean) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const { widgetId, widgetType } = widget;

        const state = getState();
        const targetLang = requestedLang || currentLanguageSelector(state);

        const currentTranslatableWidgetData = getWidgetTranslatableData(widget);
        const oldTranslatableWidgetData = shouldResetTranslation
            ? undefined
            : getTranslationEntity(state, { widgetId });

        if (isEmpty(currentTranslatableWidgetData) || (!translate && !oldTranslatableWidgetData)) {
            return;
        }

        dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'loading' } }));
        if (translate && !oldTranslatableWidgetData) {
            try {
                const translatedWidgetData = await getWidgetTranslatedData(
                    currentTranslatableWidgetData,
                    widgetType,
                    targetLang,
                    // getState,
                );

                dispatch(
                    actions.setWidgetProperties({
                        widgetId,
                        widgetProperties: { state: 'loaded', ...translatedWidgetData },
                    }),
                );
                dispatch(actions.setTranslationEntity({ widgetId, widget: currentTranslatableWidgetData }));
            } catch (err) {
                dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
                dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'loaded' } }));
            }
        } else {
            dispatch(
                actions.setWidgetProperties({
                    widgetId,
                    widgetProperties: { state: 'loaded', ...oldTranslatableWidgetData },
                }),
            );
            dispatch(actions.setTranslationEntity({ widgetId, widget: currentTranslatableWidgetData }));
        }
    };
