import { ASK_AI_ADMIN_ROOT_PATH } from '@lumapps/ask-ai/constants';
import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

/**
 * Ask Ai Concepts Route
 */
export const askAiConceptsAdminRoute: Route = {
    path: `${ASK_AI_ADMIN_ROOT_PATH}/expressions`,
    legacyId: 'app.admin.ai-search-concepts',
    appId: AppId.backOffice,
};

export const askAiConceptsAdmin = (): Route => askAiConceptsAdminRoute;
