import React, { useCallback } from 'react';

import { Kind } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { UseAdvancedStyling } from '../../hooks/useAdvancedStyling';
import { ADVANCED_STYLES } from '../../keys';
import { STYLESHEET_KIND } from '../../types';
import { CodeMirrorTextArea, MODES } from '../CodeMirrorTextArea';

export interface FieldsOptions extends UseAdvancedStyling {
    /** whether the fields are currently displayed on the page or not. */
    areFieldsDisplayed?: boolean;
    /** whether root styles should be displayed or not */
    shouldShowRootStyles?: boolean;
}

export const AdvancedStylingFields: React.FC<FieldsOptions> = ({
    areFieldsDisplayed = false,
    isLoaded,
    cssRoot,
    setStyle,
    cssCustom,
    instanceHead,
    setInstanceHead,
    shouldShowRootStyles = false,
}) => {
    const { translateKey } = useTranslate();
    const setRootCallback = useCallback(
        (value: string) => {
            setStyle(STYLESHEET_KIND.ROOT, value);
        },
        [setStyle],
    );

    return (
        <>
            {shouldShowRootStyles ? (
                <CodeMirrorTextArea
                    hasFinishedLoading={isLoaded}
                    // We want to avoid executing CodeMirror when the fields are not displayed on the page.
                    shouldApplyHighlightSyntax={areFieldsDisplayed}
                    mode={MODES.css}
                    id="cssRoot"
                    value={cssRoot}
                    setValue={setRootCallback}
                    title={translateKey(ADVANCED_STYLES.INSTANCE_CSS_ROOT)}
                    forceReload
                />
            ) : null}
            <CodeMirrorTextArea
                hasFinishedLoading={isLoaded}
                shouldApplyHighlightSyntax={areFieldsDisplayed}
                mode={MODES.css}
                id="cssCustom"
                value={cssCustom}
                setValue={useCallback(
                    (value: string) => {
                        setStyle(STYLESHEET_KIND.CUSTOM, value);
                    },
                    [setStyle],
                )}
                title={translateKey(ADVANCED_STYLES.INSTANCE_CSS_THEME)}
                message={{
                    kind: Kind.warning,
                    children: translateKey(ADVANCED_STYLES.CUSTOM_CSS_WARNING),
                    hasBackground: true,
                }}
                forceReload
            />
            <CodeMirrorTextArea
                hasFinishedLoading={isLoaded}
                shouldApplyHighlightSyntax={areFieldsDisplayed}
                mode={MODES.html}
                id="instance-head"
                value={instanceHead}
                setValue={setInstanceHead}
                title={translateKey(ADVANCED_STYLES.INSTANCE_HEAD)}
                forceReload
                message={{
                    kind: Kind.warning,
                    children: translateKey(ADVANCED_STYLES.CUSTOM_JAVASCRIPT_WARNING),
                    hasBackground: true,
                }}
            />
        </>
    );
};
