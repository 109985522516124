import { connect } from '@lumapps/redux/react';

import get from 'lodash/get';

import { fetchUserTokenStatus } from '@lumapps/chat/ducks/thunks';
import { organizationIdSelector } from '@lumapps/chat/ducks/selectors';

import { isFeatureEnabled } from '@lumapps/features';

import { WidgetChat } from './WidgetChat';

import { PROVIDER_POLL_INTERVAL } from './chat_constants';

import {
    fetchChannelsForUserSpace,
    fetchJoinedUserSpaces,
    fetchMessagesForChannel,
    createMessageForChannel,
    getChannelById,
    hasUserJoinedChannel,
    setLoading,
    setPaginationLoading,
    setSize,
    setOpen,
    setSelectedThread,
    setModel,
    setLastClosed,
    setShow,
    setCreateMessageLoading,
    setNotificationCount,
    setEmojiEditorOpened,
    setChannelUrl,
} from '../../chat/ducks/chat_actions';

import { fetchChat } from '../../workspaces/_ducks/workspaces';

const getState = (state, key, subkey) => {
    if (!subkey) {
        return state[key] || {};
    }

    return (state[key] && state[key][subkey]) || {};
};

const mapStateToProps = (state, ownProps) => {
    const { workspaceId, uuid } = ownProps;
    let {
        channelUrl,
        channelId,
        provider,
        spaceId,
        size,
        isOpen,
        selectedThread,
        isEmojiEditorOpened,
        isLoading,
        isPaginationLoading,
        model,
        notificationCount,
        lastClosed,
        isShown,
        isCreateMessageLoading,
    } = ownProps;

    const { topResults } = ownProps;

    const workspace = get(state.workspaces.list, workspaceId, {});
    const { chat = {} } = workspace;

    provider = provider || chat.provider;
    channelId = channelId || chat.chanId;
    spaceId = spaceId || chat.apiId;

    const pollInterval = PROVIDER_POLL_INTERVAL[provider];
    const stateProviders = getState(state.chats, 'providers');
    const stateUserSpaces = getState(stateProviders, provider, 'userSpaces');
    const stateChannels = getState(stateUserSpaces, spaceId, 'channels');
    const stateComponent = getState(state.chats, 'components', uuid);
    const messages = getState(stateChannels, channelId, 'messages');
    const channels = stateChannels;
    const stateUserTokenStatus = getState(state.chat, 'userTokenStatus');
    const userTokenStatus = getState(stateUserTokenStatus, provider);

    const stateUserJoinedStatus = getState(state.chats, 'userJoinedStatus');
    const stateUserJoinedProvider = getState(stateUserJoinedStatus, provider);
    const stateUserJoinedUserSpace = getState(stateUserJoinedProvider, spaceId);
    const userJoinedStatus = get(stateUserJoinedUserSpace, channelId, undefined);
    const features = get(state.customer, 'features');

    const organizationId = organizationIdSelector(state);
    const isSlackDefaultAppEnabled = isFeatureEnabled('slack-default-app')(state);

    size = stateComponent.size === undefined ? size : stateComponent.size;
    isOpen = stateComponent.isOpen === undefined ? isOpen : stateComponent.isOpen;
    selectedThread = stateComponent.selectedThread === undefined ? selectedThread : stateComponent.selectedThread;
    isLoading = stateComponent.isLoading === undefined ? isLoading : stateComponent.isLoading;
    isPaginationLoading =
        stateComponent.isPaginationLoading === undefined ? isPaginationLoading : stateComponent.isPaginationLoading;
    model = stateComponent.model === undefined ? model : stateComponent.model;
    isShown = stateComponent.isShown === undefined ? isShown : stateComponent.isShown;
    lastClosed = stateComponent.lastClosed === undefined ? lastClosed : stateComponent.lastClosed;
    isCreateMessageLoading =
        stateComponent.isCreateMessageLoading === undefined
            ? isCreateMessageLoading
            : stateComponent.isCreateMessageLoading;
    notificationCount =
        stateComponent.notificationCount === undefined ? notificationCount : stateComponent.notificationCount;
    isEmojiEditorOpened =
        stateComponent.isEmojiEditorOpened === undefined ? isEmojiEditorOpened : stateComponent.isEmojiEditorOpened;
    channelUrl = stateComponent.channelUrl === undefined ? channelUrl : stateComponent.channelUrl;

    const stateToProps = {
        channelUrl,
        channels,
        isCreateMessageLoading,
        isEmojiEditorOpened,
        isLoading,
        isOpen,
        isPaginationLoading,
        isShown,
        lastClosed,
        messages,
        model,
        notificationCount,
        pollInterval,
        selectedThread,
        size,
        topResults,
        userJoinedStatus,
        userTokenStatus,
        features,
        organizationId,
        isSlackDefaultAppEnabled,
    };

    if (chat.provider && chat.chanId && chat.apiId) {
        Object.assign(stateToProps, {
            ...stateToProps,
            channelId: chat.chanId,
            provider: chat.provider,
            spaceId: chat.apiId,
        });
    }

    return stateToProps;
};

const ConnectedWidgetChat = connect(mapStateToProps, {
    createMessageForChannel,
    fetchChannelsForUserSpace,
    fetchChat,
    fetchJoinedUserSpaces,
    fetchMessagesForChannel,
    getChannelById,
    fetchUserTokenStatus,
    hasUserJoinedChannel,
    setChannelUrl,
    setCreateMessageLoading,
    setEmojiEditorOpened,
    setLastClosed,
    setLoading,
    setModel,
    setNotificationCount,
    setOpen,
    setPaginationLoading,
    setSelectedThread,
    setShow,
    setSize,
})(WidgetChat);

export { ConnectedWidgetChat as WidgetChat };
