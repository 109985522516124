import React, { useRef } from 'react';

import { ComboboxOption } from '@lumapps/combobox/components/ComboboxOption';
import { ComboboxOptionProps } from '@lumapps/combobox/types';
import { useContentLink } from '@lumapps/contents/hooks/useContentLink';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Size } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { Link } from '@lumapps/router';
import { addBaseSlugToUrl, isExternalUrl } from '@lumapps/router/utils';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { PolymorphicComponentProps } from '@lumapps/utils/types/PolymorphicComponent';

import { SearchBoxOptionLabel } from '../SearchBoxOptionLabel';
import { SearchBoxOptionThumbnail } from '../SearchBoxOptionThumbnail';
import { SearchBoxOptionTypeProps, SearchBoxOptionWithItemProps, isSuggestionWithItem } from './types';

/**
 * Base component for all "interaction" type options.
 * This displays the thumbnail / label
 */
const BaseSearchBoxInteractionOption = <C extends React.ElementType>({
    suggestion,
    onSelect,
    ...forwardedProps
}: PolymorphicComponentProps<C, SearchBoxOptionWithItemProps>) => {
    const { thumbnail, entityType, icon } = suggestion.item;
    const charactersToDisplay = entityType === SEARCH_RESULT_TYPES.THIRD_PARTY ? 2 : 1;
    const linkRef = useRef<any>(null);

    /**
     * When the combobox option is selected via the keyboard,
     * click on the interaction to trigger the link
     */
    const handleOnSelect: ComboboxOptionProps['onSelect'] = (option, source) => {
        if (source === 'keyboard' && linkRef.current) {
            linkRef.current.click();
        } else {
            onSelect();
        }
    };

    return (
        <ComboboxOption
            ref={linkRef}
            onSelect={handleOnSelect}
            {...forwardedProps}
            textValue={suggestion.label}
            before={
                <SearchBoxOptionThumbnail
                    iconName={icon}
                    image={thumbnail}
                    alt=""
                    size={Size.s}
                    hasShape
                    charactersToDisplay={charactersToDisplay}
                />
            }
            data={suggestion}
        >
            <SearchBoxOptionLabel label={suggestion.label} />
        </ComboboxOption>
    );
};

/**
 * Search box options for users.
 * Generates the link to the user and pass it to the Base component
 */
const UserSearchSuggestion = ({ suggestion, ...forwardedProps }: SearchBoxOptionWithItemProps) => {
    const { item } = suggestion;
    const { route } = useSocialProfileRoute({
        routeParams: { userId: item?.entityId },
    });

    return <BaseSearchBoxInteractionOption suggestion={suggestion} as={Link} to={route} {...forwardedProps} />;
};

/**
 * Default component for all "interaction" type options.
 * This generates the link based on the suggestion data.
 */
const DefaultSearchInteractionItem = ({ suggestion, ...forwardedProps }: SearchBoxOptionWithItemProps) => {
    const instanceId = useSelector(instanceIdSelector);
    const { entityId, url, entityType, originSiteId } = suggestion.item;
    const formattedUrl =
        entityType === (SEARCH_RESULT_TYPES.MEDIA || SEARCH_RESULT_TYPES.DIRECTORY_ENTRY) ? url : addBaseSlugToUrl(url);

    const contentLink = useContentLink({
        id: entityId,
        slug: url,
    });

    /**
     * Origin site id may not be set in older version of quick-search
     * Site id is an int and the instance id too. Only the originSiteId is a string.
     * TODO: remove this when the backend is sending string for siteId
     */
    const isFromCurrentInstance = `${originSiteId || suggestion.siteId}` === `${instanceId}`;
    const shouldOpenInNewTab =
        isExternalUrl(url) || !isFromCurrentInstance || Boolean(contentLink.contentLinkRef.instance);

    if (!suggestion.item) {
        return null;
    }

    let linkProps: { rel?: string; target: React.ComponentProps<'a'>['target'] } = {
        rel: undefined,
        target: '_self',
    };
    if (shouldOpenInNewTab) {
        linkProps = {
            rel: 'noopener noreferrer',
            target: '_blank',
        };
    }

    return (
        <BaseSearchBoxInteractionOption
            suggestion={suggestion}
            as="a"
            href={
                entityType === SEARCH_RESULT_TYPES.ARTICLE && isFromCurrentInstance
                    ? contentLink.props.href
                    : formattedUrl
            }
            {...forwardedProps}
            {...linkProps}
        />
    );
};

/**
 * Dictionary of all suggestion component to use depending on the search result type.
 */
const ComponentsByEntityType: { [key in SEARCH_RESULT_TYPES]?: React.FC<SearchBoxOptionWithItemProps> } = {
    [SEARCH_RESULT_TYPES.USER]: UserSearchSuggestion,
};

/**
 * Component to display "interaction" type options.
 * Interactions are options that redirects directly to the resource
 */
export const SearchBoxInteractionOption = ({ suggestion, ...forwardedProps }: SearchBoxOptionTypeProps) => {
    if (!isSuggestionWithItem(suggestion)) {
        return null;
    }

    // Check that a specific component is set fot this entity type, if not use the default interaction item.
    const InteractionComponent = ComponentsByEntityType[suggestion.item.entityType] || DefaultSearchInteractionItem;

    return <InteractionComponent suggestion={suggestion} {...forwardedProps} />;
};
