/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const contentBulkRoute: Route = {
    path: '/admin/contents',
    legacyId: 'app.admin.contents',
    appId: AppId.backOffice,
};

export const adminContentBulkRoute = (): Route => contentBulkRoute;
