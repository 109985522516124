/* istanbul ignore file */
import BaseApi, { BaseApiPromise } from '@lumapps/base-api';
import { GetBaseUrl } from '@lumapps/learning-utils/getBaseUrl';

import { TeamKpiUrl } from '../types';

/**
 * TeamKpi API to retrieve url for Manager TeamKpi  widget.
 *
 */
const managerTeamKpiApi = (baseURL: string) =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL,
    });

export const paths = {
    teamKpi: 'learning-analytics/v2/dashboard/manager',
};

export function getTeamKpiUrl(learningAlphaEnabled: boolean): BaseApiPromise<TeamKpiUrl> {
    const baseURL = GetBaseUrl(learningAlphaEnabled);

    return managerTeamKpiApi(baseURL).get<TeamKpiUrl>(paths.teamKpi);
}

export { managerTeamKpiApi };
