import React, { Children, Fragment, ReactElement, useMemo } from 'react';

import { LoadMoreFocusHelper } from '@lumapps/a11y/components/LoadMoreFocusHelper';
import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { ImageLightboxProvider } from '@lumapps/lumx-images/components/ImageLightboxProvider';
import {
    Alignment,
    Button,
    Emphasis,
    FlexBox,
    GridColumn,
    Orientation,
    ProgressCircular,
    Text,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { WIDGET_COMMUNITY_LIST_TYPE } from '@lumapps/widget-community-list/constants';

import { WidgetState } from '../../../ducks/type';
import { WIDGET_BASE } from '../../../keys';
import { BlockComponent, BlockGrid as BlockGridType, ContainerBlockVariant } from '../../../types';
import { getBlockMinWidth } from '../../../utils/getBlockMinWidth';

import './index.scss';

const CLASSNAME = 'block-grid';
const DEFAULT_COLUMNS = 1;

/** Grid block. */
export interface BlockGridProps extends BlockGridType {
    loadingState: WidgetState['state'] | BaseLoadingStatus;
    onLoadMore?(): void;
    /** The component that will be used as a loader when the list loads more items. Default: <Progress /> */
    loadingStateRenderer?: React.FC;
    /** Item min width to adapt the number of visible items to the available space */
    itemMinWidth?: number;
    /** whether the load more button is fullWidth or not */
    fullWidthButton?: boolean;
}

/**
 * Default values for the grid.
 * */
const GRID_ITEM_UNGROUPED_DEFAULT_PADDING = 24;

export const BlockGrid: BlockComponent<BlockGridProps> = ({
    children,
    variant = ContainerBlockVariant.grouped,
    columns = DEFAULT_COLUMNS,
    contentStyles,
    onLoadMore,
    loadingState,
    loadingStateRenderer: LoadingStateRenderer = ProgressCircular,
    itemMinWidth,
    maxItemsPerPage,
    fullWidthButton,
}) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);

    /** Getting childType from Children, assuming that all children are sharing the same type */
    const blockType = (Children.toArray(children)[0] as ReactElement)?.props?.type;
    const widget = (Children.toArray(children)[0] as ReactElement)?.props?.widget;

    /** For the community list, ungrouped variant, we need to implement a style exception to remove the padding of each items */
    const shouldItemHaveNoPadding =
        widget?.widgetType === WIDGET_COMMUNITY_LIST_TYPE && variant === ContainerBlockVariant.ungrouped;

    /**
     * We want to adjust the grid item min-width for each BlockType.
     * It will define when to reduce the number of column.
     * Take into account the innerSpacing by default or set by the user.
     * */
    const minWidth = useMemo(() => {
        return getBlockMinWidth({
            itemMinWidth,
            blockType,
            variant,
            contentStyles,
            defaultPadding: GRID_ITEM_UNGROUPED_DEFAULT_PADDING,
        });
    }, [blockType, contentStyles, itemMinWidth, variant]);

    return (
        <>
            <GridColumn
                className={block({
                    grouped: variant === ContainerBlockVariant.grouped,
                    ungrouped: variant === ContainerBlockVariant.ungrouped,
                })}
                itemMinWidth={minWidth}
                maxColumns={columns}
                gap="huge"
            >
                <ImageLightboxProvider>
                    {React.Children.map(Children.toArray(children), (child, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i}>
                            <LoadMoreFocusHelper
                                itemIndex={i}
                                itemsDisplayedLength={Children.toArray(children).length}
                                itemsPerPage={maxItemsPerPage}
                            />
                            <div
                                className={element('item', {
                                    'no-padding': shouldItemHaveNoPadding,
                                })}
                                style={variant === ContainerBlockVariant.ungrouped ? contentStyles : undefined}
                            >
                                {child}
                            </div>
                        </Fragment>
                    ))}
                </ImageLightboxProvider>
            </GridColumn>
            {((onLoadMore && loadingState === 'loaded') || loadingState === BaseLoadingStatus.idle) && (
                <Button
                    className={element('load-more')}
                    emphasis={Emphasis.medium}
                    onClick={onLoadMore}
                    fullWidth={fullWidthButton}
                >
                    {translateKey(WIDGET_BASE.LOAD_MORE)}
                </Button>
            )}
            {(loadingState === 'loadingmore' || loadingState === BaseLoadingStatus.loadingMore) && (
                <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center} role="alert" aria-live="polite">
                    <Text as="p" className={visuallyHidden()}>
                        {translateKey(GLOBAL.LOADING)}
                    </Text>
                    <LoadingStateRenderer />
                </FlexBox>
            )}
        </>
    );
};
BlockGrid.displayName = 'BlockGrid';
