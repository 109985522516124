import { Article } from '@lumapps/articles/types';
import { Community } from '@lumapps/communities/types';
import { Content } from '@lumapps/contents/types';
import { trainingView } from '@lumapps/learning-trainings/routes';
import { TRAINING_TYPE, Training } from '@lumapps/learning-trainings/types';
import { InstalledExtensionAPIProps } from '@lumapps/marketplace/legacy-types';
import { Media } from '@lumapps/medias/types';
import { getImageUrlFromContent, getMediaContent } from '@lumapps/medias/utils';
import { Playlist } from '@lumapps/play-playlists/types';
import { Video } from '@lumapps/play/api/types';
import { Post } from '@lumapps/posts/types';
import { addLocationOriginToUrl, createUrl } from '@lumapps/router/utils';
import { GLOBAL, TranslationAPI, normalizeV2Format } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../constants';
import { LinkPreviewAttributes } from '../types';

/**
 * Maps the object we get from the content picker to link preview resource
 * @param content - the content we want to map
 * @param translateObject - the translation function to use
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerContentToResourcePreview = (
    content: Content,
    translateObject: TranslationAPI['translateObject'],
    language: string,
): LinkPreviewAttributes => {
    const { excerpt, id, instance, mediaThumbnail, slug, title } = content;

    const contentData = {
        title: translateObject(title, language) || undefined,
        thumbnailUrl: getImageUrlFromContent(getMediaContent(mediaThumbnail as Media, translateObject, language)),
        description: translateObject(excerpt, language) || undefined,
    };

    return {
        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        url: '',
        ...contentData,
        resource: {
            id,
            siteSlug: instance || '',
            slug: translateObject(slug, language) || '',
            type: LINK_PREVIEW_RESOURCE_TYPES.CONTENT,
            ...contentData,
        },
    };
};

/**
 * Maps the object we get from the learning picker to link preview resource
 * @param learning - the learning resource we want to map
 * @returns LinkPreviewAttributes
 */
export const mapPickerLearningToResourcePreview = (learning: Training): LinkPreviewAttributes => {
    const { cover, description, identifier, title, type } = learning;

    const learningData = {
        title,
        thumbnailUrl: cover,
        description: sanitizeHTML(description),
    };

    // for learning resource, we need to send the url to backend since they need it
    return {
        url: addLocationOriginToUrl(createUrl(trainingView(identifier))),
        ...learningData,
        resource: {
            id: identifier,
            type:
                type === TRAINING_TYPE.learningPath
                    ? LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH
                    : LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE,
            ...learningData,
        },
    };
};

/**
 * Maps the object we get from the community picker to link preview resource
 * @param community The community we want to map
 * @param translateObject - the translation function to use
 * @param language The language to use.
 * @returns LinkPreviewAttributes
 */
export const mapPickerCommunityToResourcePreview = (
    community: Community,
    translateObject: TranslationAPI['translateObject'],
    language: string,
): LinkPreviewAttributes => {
    const { description, id, instanceDetails, thumbnail, title, slug } = community;

    const communityData = {
        title: translateObject(title, language) || undefined,
        thumbnailUrl: thumbnail,
        description: translateObject(description, language) || undefined,
    };

    return {
        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        url: '',
        ...communityData,
        resource: {
            id,
            siteSlug: instanceDetails?.id || '',
            slug: translateObject(slug, language) || '',
            type: LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY,
            ...communityData,
        },
    };
};

/**
 * Maps the object we get from the playlist video picker to link preview
 * @param video - the video we want to map
 * @param translateObject - the translation function to use
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerVideoToResourcePreview = (
    video: Video,
    translateObject: TranslationAPI['translateObject'],
    language: string,
): LinkPreviewAttributes => {
    const { id, title, description, siteId, thumbnail } = video;
    // video.title/video.description are typed as a translatableObject, but they do not return the `translations` field,
    // so, we use normalizeV2Format in here to ensure that all the fields are there.
    const titleTranslations = normalizeV2Format(title).translations;
    const descriptionTranslations = normalizeV2Format(description).translations;

    const videoData = {
        title: translateObject(titleTranslations, language) || undefined,
        thumbnailUrl: thumbnail?.url,
        description: translateObject(descriptionTranslations, language) || undefined,
    };
    // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
    // hence the empty string here + they don't need it to create the url.
    return {
        url: '',
        ...videoData,
        resource: {
            id,
            siteSlug: siteId,
            type: LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO,
            ...videoData,
        },
    };
};
/**
 * Maps the object we get from the playlist video picker to link preview
 * @param playlist - the playlist we want to map
 * @param translateObject - the translation function to use
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerPlaylistToResourcePreview = (
    playlist: Playlist,
    translateObject: TranslationAPI['translateObject'],
    language: string,
): LinkPreviewAttributes => {
    const { id, title, description, siteId, thumbnail } = playlist;
    const playlistData = {
        title: translateObject(title.translations, language) || undefined,
        thumbnailUrl: thumbnail?.url,
        description: translateObject(description?.translations, language) || undefined,
    };

    return {
        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        url: '',
        ...playlistData,
        resource: {
            id,
            siteSlug: siteId,
            type: LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST,
            ...playlistData,
        },
    };
};

/**
 * Maps the object we get from the article picker to link preview resource
 * @param article - the article we want to map
 * @param translateObject - the translation function to use
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerArticleToResourcePreview = (
    article: Article,
    translateObject: TranslationAPI['translateObject'],
    language: string,
): LinkPreviewAttributes => {
    const { id, structuredContent, title, site } = article;

    const featuredImage = structuredContent?.featuredImage;
    const featureImageTranslations = featuredImage?.translations;
    const imageUrl = featureImageTranslations ? featureImageTranslations[featuredImage.lang as string]?.url : '';

    const articleData = {
        title: translateObject(title, language) || undefined,
        thumbnailUrl: imageUrl,
        description: translateObject(structuredContent?.intro.translations, language) || undefined,
    };

    return {
        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        url: '',
        ...articleData,
        resource: {
            id: id || '',
            siteSlug: site?.siteId || '',
            type: LINK_PREVIEW_RESOURCE_TYPES.ARTICLE,
            ...articleData,
        },
    };
};

/**
 * Maps the object we get from the post picker to link preview resource
 * @param post - the post we want to map
 * @param t - an object containing all translation functions
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerPostToResourcePreview = (
    post: Post,
    t: TranslationAPI,
    language: string,
): LinkPreviewAttributes => {
    const { id, title, parentContentDetails, excerpt } = post;
    const { translateObject, translateAndReplace, translateKey } = t;

    const postTitle =
        translateObject(title, language) ||
        translateAndReplace(GLOBAL.CONTENTS_IN_CONTAINER, {
            contents: translateKey(GLOBAL.POST),
            container: translateObject(parentContentDetails?.title) || '',
        });

    const postData = {
        title: postTitle || undefined,
        // posts never have a thumbnail
        thumbnailUrl: '',
        description: translateObject(excerpt, language) || '',
    };

    return {
        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        url: '',
        ...postData,
        resource: {
            id: id || '',
            communityId: parentContentDetails?.id || '',
            communitySlug: translateObject(parentContentDetails?.slug) || '',
            type: LINK_PREVIEW_RESOURCE_TYPES.POST,
            ...postData,
        },
    };
};

/**
 * Maps the object we get from the micro app picker to link preview resource
 * @param microApp - the micro app we want to map
 * @param translateObject - the translation function to use
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerMicroAppToResourcePreview = (
    microApp: InstalledExtensionAPIProps,
    translateObject: TranslationAPI['translateObject'],
    language: string,
): LinkPreviewAttributes => {
    const { id, extension } = microApp;
    const { name, icon, description } = extension;

    const microAppData = {
        title: translateObject(name, language) || undefined,
        thumbnailUrl: translateObject(icon, language) || '',
        description: translateObject(description, language) || undefined,
    };

    return {
        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        url: '',
        ...microAppData,
        resource: {
            id,
            type: LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP,
            ...microAppData,
        },
    };
};
