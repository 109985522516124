import React, { PureComponent } from 'react';
import { bool, func, object, string } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';

import { InputTextFieldDialog } from '../../ui/dialogs/input-text-field';

import { getInputLanguage, translate as t } from '../../../translations';
import { getMediaContentByLang, update } from '../../../services/document';
import { error, success } from '../../../services/notification';

const _INPUT_NAME = 'name';

/**
 * Open a dialog to edit a document name.
 */
class RenameDocumentDialog extends PureComponent {
    constructor(props) {
        super(props);

        const { name: documentName } = getMediaContentByLang(props.media, true);

        this._INITIAL_STATE = {
            documentName,
            inputLanguage: getInputLanguage(),
            loading: false,
        };
        this.state = cloneDeep(this._INITIAL_STATE);

        this.handleClose = this.handleClose.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
    }

    /**
     * Handle dialog close.
     */
    handleClose() {
        const { onClose } = this.props;

        this.setState(cloneDeep(this._INITIAL_STATE));
        onClose();
    }

    /**
     * Handle rename confirmation.
     *
     * @param {string} name The new document name.
     */
    async handleValidate({ [_INPUT_NAME]: name }) {
        const { onValidate } = this.props;

        await this.saveDocument({ name });
        onValidate();
    }

    /**
     * Makes save document request with new name.
     *
     * @param  {string}  name The new document name.
     * @return {Promise} Request promise.
     */
    saveDocument({ name }) {
        const { media, type } = this.props;
        // Note that we do not want to do this if we use this component with Lumapps docs provider.
        // It's only necessary for 3rd party docs provider like Google or Microsoft.
        const mediaContent = first(media.content);

        mediaContent.name = name;
        media.content = [mediaContent];

        this.setState({ loading: true });

        return update(media)
            .then(
                () => {
                    success(t(`FRONT.DOCUMENT.RENAME.${type}.SUCCESS`));
                },
                () => {
                    error(t(`FRONT.DOCUMENT.RENAME.${type}.ERROR`));
                },
            )
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const { documentName, loading } = this.state;
        const { canChangeLanguage } = this.props;

        return (
            <InputTextFieldDialog
                fullWidth
                canChangeLanguage={canChangeLanguage}
                label="FRONT.MEDIA_PICKER.DETAIL.MEDIA_NAME"
                loading={loading}
                min={1}
                mustDiffer
                name={_INPUT_NAME}
                title="FRONT.WIDGET_FILE_LIST.SETTINGS.RENAME_FILE"
                value={documentName}
                onClose={this.handleClose}
                onValidate={this.handleValidate}
            />
        );
    }
}

/////////////////////////////

RenameDocumentDialog.propTypes = {
    /** Display input language selector. */
    canChangeLanguage: bool,
    /** Document we want to rename. */
    media: object.isRequired,
    /** Called when closing dialog. */
    onClose: func.isRequired,
    /** Called when the document has been renamed. */
    onValidate: func.isRequired,
    /** Type of media (FILE or FOLDER). */
    type: string.isRequired,
};

RenameDocumentDialog.defaultProps = {
    canChangeLanguage: false,
};

/////////////////////////////

export { RenameDocumentDialog };
