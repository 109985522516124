import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import includes from 'lodash/includes';
import startsWith from 'lodash/startsWith';
import without from 'lodash/without';

import { getWysiwygToolbar } from '@lumapps/wrex-legacy-froala/constants';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { uploadDocumentsWithValidation } from '@lumapps/medias-document/api';
import { addQueryParamsToUrl } from '@lumapps/router/utils';

import { AdvancedSettingsConstant as AdvancedSettingsCst } from 'common/modules/constants/advanced-settings_constants';

/////////////////////////////

/**
 * The redux update action. It has it own export to be import in react.
 *
 * @type {string}
 * @constant
 * @readonly
 */
const UPDATE_ACTION_TYPE = 'froala/update';

/////////////////////////////

function FroalaService(
    $compile,
    $rootScope,
    $timeout,
    $window,
    AbstractPicker,
    AdvancedSettingsConstant,
    Config,
    ConfigTheme,
    ContentPicker,
    FroalaSettings,
    Instance,
    MainNav,
    Media,
    MediaConstant,
    Style,
    ReduxStore,
    Translation,
    Upload,
    UploadFactory,
    Utils,
) {
    'ngInject';

    const service = this;
    const currentInstanceId = Instance.getCurrentInstanceId();
    const currentLang = Translation.getLang('current');

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default set of toolbar buttons to use in the editor and for each breakpoint.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _DEFAULT_OPTIONS_TOOLBAR_BUTTONS = [
        'fullscreen',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        '|',
        'color',
        'inlineStyle',
        'paragraphStyle',
        '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'insertHR',
        '|',
        'insertLink',
        'undo',
        'redo',
        'clearFormatting',
        'selectAll',
        'html',
    ];

    /**
     * The default froala options.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _DEFAULT_OPTIONS = {
        events: {},
        linkEditButtons: FroalaSettings.linkEditButtons,
        linkInsertButtons: FroalaSettings.linkInsertButtons,
        pastePlain: Instance.getProperty(Config.INSTANCE_PROPERTIES.WYSIWYG_PASTEPLAIN) !== 'No',
        toolbarButtons: _DEFAULT_OPTIONS_TOOLBAR_BUTTONS,
        toolbarSticky: true,
    };

    /**
     * The minimum width of the screen to consider that we are on a desktop computer.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DESKTOP_MIN_SCREEN_WIDTH = 1024;

    /**
     * Contains all the tags allowed as social media.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _SOCIAL_MEDIA_TAGS = ['twitterwidget'];

    /**
     * Contains all the tags allowed as specific tags commonly used in intranet solutions.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _EXTENDED_FROALA_TAGS = ['tableau-viz'];

    /**
     * Contains the list of allowed HTML tags.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _HTML_ALLOWED_TAGS = [].concat(
        $.FroalaEditor.DEFAULTS.htmlAllowedTags,
        // eslint-disable-next-line no-use-before-define
        FroalaSettings.svgTags,
        // eslint-disable-next-line no-use-before-define
        _SOCIAL_MEDIA_TAGS,
        _EXTENDED_FROALA_TAGS,
    );

    /**
     * Contains the list of allowed attributes on HTML tags.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _HTML_ALLOWED_ATTRS = [].concat(
        $.FroalaEditor.DEFAULTS.htmlAllowedAttrs,
        // eslint-disable-next-line no-use-before-define
        FroalaSettings.svgAttributes,
        ['ng-click', 'onclick', 'ui-sref', 'ng-href'],
    );

    /**
     * Contains the list of HTML tags that are allowed to be empty.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _HTML_ALLOWED_EMPTY_TAGS = [].concat(
        $.FroalaEditor.DEFAULTS.htmlAllowedEmptyTags,
        ['i', 'span', 'div'],
        FroalaSettings.svgTags,
        _SOCIAL_MEDIA_TAGS,
        _EXTENDED_FROALA_TAGS,
    );

    /**
     * The maximum size for an image included in a Froala Editor.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const IMG_MAX_SIZE = 1600;

    /**
     * The offset of the content when we consider we are on mobile (or small) screen (< 1024px).
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MOBILE_HEADER_OFFSET = 100;

    /**
     * Contains the instance's colors.
     *
     * @type {Array}
     */
    let _instanceColors = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The light set of toolbar buttons to use in the editor.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    service.LIGHT_OPTIONS_TOOLBAR_BUTTONS = ['bold', 'italic', 'underline', 'strikeThrough', 'superscript'];

    /**
     * Indicates if we are editing in a popin.
     *
     * @type {boolean}
     */
    service.editingPopin = false;

    /**
     * Contains all the links.
     *
     * @type {Object}
     */
    service.linkItem = {};

    /**
     * Contains a list of media.
     *
     * @type {Array}
     */
    service.mediaList = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    service.MediaConstant = MediaConstant;

    /////////////////////////////

    /*
     *  Define Material design icons instead of Font awesome ones.
     */
    $.FroalaEditor.DefineIconTemplate('material_design', '<i class="mdi mdi-[NAME]"></i>');

    angular.forEach(FroalaSettings.tools, function redefineEachToolsIcon(tool, toolId) {
        $.FroalaEditor.DefineIcon(toolId, {
            NAME: tool.icon,
        });
    });

    /*
     *  Here we backup insertImage and insertLink buttons before override them.
     *  An override is legitimate since we do not want the native process at all and
     * it is required since it provides a far better integrability in the environment.
     *
     *  The original buttons are still accessible using nativeInsertImage and nativeInsertLink, respectively.
     *  But there is no reason to use them.
     */
    $.FroalaEditor.RegisterCommand('nativeInsertImage', $.FroalaEditor.COMMANDS.insertImage);
    $.FroalaEditor.RegisterCommand('nativeInsertLink', $.FroalaEditor.COMMANDS.insertLink);
    $.FroalaEditor.DefineIcon('nativeInsertLink', {
        NAME: 'link',
    });

    /////////////////////////////
    //                         //
    //       Media Picker      //
    //                         //
    /////////////////////////////

    $.FroalaEditor.RegisterCommand('insertImage', {
        // eslint-disable-next-line id-blacklist
        callback: function insertImageCallback() {
            service.mediaPickerCallback = this;
            service.mediaPickerCallback.markers.insert();

            const scope = $rootScope.$new();
            scope.Config = Config;
            scope.FroalaService = service;

            service.mediaPickerId = `froala-media-picker-${generateUUID()}`;

            service.mediaPicker = $compile(
                `${'<ls-picker ' +
                    'ls-type="Media"' +
                    'ls-type-path="Folder"' +
                    'ls-allowed-providers="[FroalaService.MediaConstant.PROVIDERS.lumapps]"' +
                    'ls-allowed-types="FroalaService.MediaConstant.DOCUMENT_TYPES.imagesAndFolders"' +
                    'ls-has-sidebar="true" ' +
                    'ls-picker-id="'}${service.mediaPickerId}" ` +
                    'ng-model="FroalaService.mediaList" ' +
                    `ls-widget-uuid="${service.mediaPickerId}" ` +
                    'ls-multiple="true">' +
                    '</ls-picker>',
            )(scope);

            angular.element('body').append(service.mediaPicker);

            Utils.waitForAndExecute(`#${service.mediaPickerId}`, AbstractPicker);
        },
        focus: true,
        refreshAfterCallback: true,
        title: Translation.translate('INSERT_IMAGE'),
        undo: true,
    });

    /**
     * When the media picker starts to close, display each selected media in the WYSIWYG editor.
     *
     * @param {Event}  evt           The media picker close start event.
     * @param {string} idMediaPicker The id of the media picker that starts to close.
     */
    $rootScope.$on('abstract-picker__close-end', function onMediaPickerCloseStart(evt, idMediaPicker) {
        if (service.mediaPickerId !== idMediaPicker) {
            return;
        }

        // I know that media is invariable. But I also need to iterate over each media...
        const medias = angular.isArray(service.mediaList) ? service.mediaList : [service.mediaList];

        let imageHTML = '';

        angular.forEach(medias, function forEachMedia(media) {
            const mediaContent = Translation.translate(Media.getMediaContent(media));

            if (angular.isDefined(mediaContent) && Media.isImage(mediaContent.type)) {
                const url = angular.isDefinedAndFilled(mediaContent.servingUrl)
                    ? mediaContent.servingUrl
                    : mediaContent.value;

                imageHTML += `<img class="fr-dib fr-draggable" src="${Utils.resizeImage(
                    url,
                    IMG_MAX_SIZE,
                    false,
                    true,
                )}" style="width: 300px;">`;
            }
        });

        if (angular.isDefinedAndFilled(imageHTML)) {
            service.mediaPickerCallback.$el.find('.fr-marker').replaceWith(imageHTML);
            service.mediaPickerCallback.undo.saveStep();
            service.mediaList = [];
        }
    });

    /**
     * When the media picker is closed, remove the marker in the content of the WYSIWYG editor.
     *
     * @param {Event}  evt           The media picker close end event.
     * @param {string} idMediaPicker The id of the media picker that closes.
     */
    $rootScope.$on('abstract-picker__close-end', function onMediaPickerCloseEnd(evt, idMediaPicker) {
        if (service.mediaPickerId === idMediaPicker) {
            // Do not check if `isDefined[AndFilled]` as it's a DOM element. This may have unpredictable outcome.
            if (angular.isDefined(service.mediaPickerCallback) && service.mediaPickerCallback.markers) {
                service.mediaPickerCallback.markers.remove();
            }

            // Do not check if `isDefined[AndFilled]` as it's a DOM element. This may have unpredictable outcome.
            if (service.mediaPicker) {
                service.mediaPicker.remove();
            }
        }
    });

    /////////////////////////////
    //                         //
    //       Content link      //
    //                         //
    /////////////////////////////

    /**
     * Contains the dropdown that is displayed when clicking the link button in toolbar.
     *
     * @type {Object}
     */
    const _dropdownConfig = {
        // eslint-disable-next-line id-blacklist
        callback: function insertLinkDropdownCallback(cmd, val) {
            this.commands.exec(val);
        },
        focus: true,
        icon: 'insertLink',
        refreshAfterCallback: true,
        title: 'Insert Link',
        type: 'dropdown',
        undo: false,
    };

    $.FroalaEditor.RegisterCommand(
        'insertLink',
        angular.extend(angular.fastCopy(_dropdownConfig), {
            options: {
                linkContent: Translation.translate('INSERT_CONTENT_LINK'),
                nativeInsertLink: 'Insert Link',
            },
        }),
    );
    $.FroalaEditor.RegisterCommand(
        'imageLinkDropdown',
        angular.extend(angular.fastCopy(_dropdownConfig), {
            options: {
                imageLink: 'Insert Link',
                linkContent: Translation.translate('INSERT_CONTENT_LINK'),
            },
            refresh: function imageLinkDropdownRefresh($btn) {
                const link = this.link.get();
                $btn.toggleClass('fr-hidden', Boolean(link));
            },
        }),
    );

    $.FroalaEditor.RegisterCommand('linkContent', {
        // eslint-disable-next-line id-blacklist
        callback: function linkContentCallback() {
            service.contentPickerCallback = this;

            service.currentSelectionText = service.contentPickerCallback.selection.text();
            service.currentSelection = service.contentPickerCallback.selection;
            service.currentSelectionImage = angular.isDefined(service.contentPickerCallback.image)
                ? service.contentPickerCallback.image.get()
                : undefined;

            service.currentSelection.save();

            const scope = $rootScope.$new();
            scope.FroalaService = service;

            service.contentPickerId = `froala-content-picker-${generateUUID()}`;
            service.contentPicker = $compile(
                `<ls-content-picker 'ls-multi="false" ls-picker-id="${
                    service.contentPickerId
                }" ls-kind="object" ls-view-mode="library" ng-model="FroalaService.linkItem"></ls-content-picker>`,
            )(scope);

            angular.element('body').append(service.contentPicker);

            Utils.waitForAndExecute(`#${service.contentPickerId}`, ContentPicker);
        },
        focus: true,
        refreshAfterCallback: true,
        title: Translation.translate('INSERT_CONTENT_LINK'),
        undo: true,
    });

    $.FroalaEditor.RegisterCommand('linkEditWrapper', {
        // eslint-disable-next-line id-blacklist
        callback: function linkEditWrapperCallback() {
            service.contentPickerCallback = this;

            service.currentSelectionText = service.contentPickerCallback.selection.text();
            service.currentSelection = service.contentPickerCallback.selection;
            service.currentSelectionImage = angular.isDefined(service.contentPickerCallback.image)
                ? service.contentPickerCallback.image.get()
                : undefined;

            service.currentSelection.save();

            const linkNode = angular.isDefinedAndFilled(service.currentSelectionImage)
                ? angular.element(service.currentSelectionImage.get()).parent('a')
                : angular.element(get(service.currentSelection.get(), 'focusNode', {})).parent('a');

            // Don't use "angular.isUndefinedOrEmpty" here as it's an JQNode.
            if (!linkNode) {
                this.commands.exec('linkEdit');

                return;
            }

            const dataUrl = linkNode.attr('data-url');
            if (angular.isUndefinedOrEmpty(dataUrl)) {
                this.commands.exec('linkEdit');

                return;
            }

            service.editLink = linkNode;

            try {
                service.linkItem = angular.fromJson(dataUrl);
            } catch (exception) {
                // Nothing to do here.
            }

            const scope = $rootScope.$new();
            scope.FroalaService = service;

            service.contentPickerId = `froala-content-picker-${generateUUID()}`;
            service.contentPicker = $compile(
                `<ls-content-picker ls-multi="false" ls-picker-id="${
                    service.contentPickerId
                }" ls-kind="object" ls-view-mode="library" ng-model="FroalaService.linkItem"></ls-content-picker>`,
            )(scope);

            angular.element('body').append(service.contentPicker);

            Utils.waitForAndExecute(`#${service.contentPickerId}`, ContentPicker);

            angular.element('.fr-popup.fr-active').removeClass('fr-active');
        },
        focus: true,
        icon: 'linkEdit',
        refreshAfterCallback: true,
        title: 'Edit Link',
        undo: true,
    });

    /**
     * When the content picker starts to close, add the links for each picked content in the WYSIWYG editor.
     *
     * @param {Event}  evt             The media picker close start event.
     * @param {string} idContentPicker The id of the media picker that starts to close.
     * @param {string} canceled        If the content picker has been canceled.
     */
    $rootScope.$on('content-picker__close-start', function onContentPickerCloseStart(evt, idContentPicker, canceled) {
        const hasLink = angular.isUndefinedOrEmpty(
            [get(service.linkItem, 'id'), get(service.linkItem, 'slug')],
            'some',
        );

        if (canceled || hasLink) {
            service.editLink = undefined;
            service.linkItem = {};

            return;
        }

        const title = service.currentSelectionText || MainNav.getNavItemTitle(service.linkItem);
        const blank = service.linkItem.instance === Instance.getCurrentInstanceId() ? '' : ' target="_blank"';

        service.currentSelection.restore();

        const urlParams = {
            id: service.linkItem.id,
            instance: service.linkItem.instance,
            link: service.linkItem.link,
            slug: service.linkItem.slug,
            type: service.linkItem.type,
            renderingType: service.linkItem.renderingType,
        };

        let urlParamsStr = angular.toJson(urlParams, false);

        if (service.editLink) {
            service.editLink.attr('data-url', urlParamsStr);
        } else {
            urlParamsStr = urlParamsStr.replace(/[\\"']/g, '&quot;');

            if (service.currentSelectionImage) {
                service.currentSelectionImage.attr('alt', title);

                const imageHTML = service.currentSelectionImage[0].outerHTML;
                service.contentPickerCallback.image.remove(service.currentSelectionImage);
                service.contentPickerCallback.html.insert(`<a data-url="${urlParamsStr}"${blank}>${imageHTML}</a>`);
            } else {
                service.contentPickerCallback.html.insert(`<a data-url="${urlParamsStr}"${blank}>${title}</a>`);
            }
        }

        service.contentPickerCallback.undo.saveStep();
        service.linkItem = {};
        service.editLink = undefined;
    });

    /////////////////////////////
    //                         //
    //        Popin link       //
    //                         //
    /////////////////////////////

    $.FroalaEditor.RegisterCommand('insertPopin', {
        // eslint-disable-next-line id-blacklist
        callback: function insertPopinCallback() {
            service.popinCallback = this;

            service.currentSelectionText = service.popinCallback.selection.text();
            service.currentSelection = service.popinCallback.selection;
            service.currentSelectionImage = angular.isDefined(service.popinCallback.image)
                ? service.popinCallback.image.get()
                : undefined;

            service.popinCallback.selection.save();

            if (service.currentSelectionImage) {
                const imageHTML = service.currentSelectionImage[0].outerHTML;
                service.popinCallback.image.remove(service.currentSelectionImage);
                service.popinCallback.html.insert(
                    `<a href="popin" ng-click="vm.Utils.openPopin($event, '${service.widgetUuid}')">${imageHTML}</a>`,
                );
            } else {
                service.popinCallback.html.insert(
                    `<a href="popin" ng-click="vm.Utils.openPopin($event, '${service.widgetUuid}')">${
                        service.currentSelectionText ? service.currentSelectionText : 'popin'
                    }</a>`,
                );
            }

            service.popinCallback.undo.saveStep();
            service.editingPopin = true;

            $rootScope.$apply();
        },
        disabled: true,
        focus: true,
        forcedRefresh: true,
        refresh: function insertPopinRefresh($btn) {
            const selection = this.selection.text() || (this.image ? this.image.get() : null);
            $btn.toggleClass('fr-disabled', angular.isUndefinedOrEmpty(selection));
        },
        refreshAfterCallback: true,
        title: Translation.translate('INSERT_POPIN'),
        undo: true,
    });

    $.FroalaEditor.RegisterCommand('editPopin', {
        // eslint-disable-next-line id-blacklist
        callback: function editPopinCallback() {
            service.editingPopin = true;

            $rootScope.$apply();
        },
        focus: true,
        forcedRefresh: true,
        hidden: true,
        refresh: function editPopinRefresh($btn) {
            $btn.toggleClass('fr-hidden', !includes(this.html.get(), 'Utils.openPopin'));
        },
        title: Translation.translate('EDIT_POPIN'),
        undo: false,
    });

    $.FroalaEditor.RegisterCommand('editContent', {
        // eslint-disable-next-line id-blacklist
        callback: function editContentCallback() {
            service.editingPopin = false;
            $rootScope.$apply();
        },
        focus: true,
        forcedRefresh: true,
        hidden: true,
        refresh: function editContentRefresh($btn) {
            $btn.toggleClass('fr-hidden', !this.opts.isPopin);
        },
        title: Translation.translate('EDIT_CONTENT'),
        undo: false,
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    function uploadImages(images) {
        const filesToUpload = Array.from(images).map((image) => {
            return {
                lang: currentLang,
                shared: false,
                parentPath: `provider=local/site=${currentInstanceId}`,
                file: image,
            };
        })

        uploadDocumentsWithValidation(filesToUpload).then((response) => {
            const images = response.map(({ data }, index) => {
                const { content } = data;
                const { url } = content[0];

                return { url: addQueryParamsToUrl(url, { canUsePlaceholder: 'false' }), type: 'image' };
            });

            service.insertImageFromUpload(images);
        }).catch(() => {
            service.editor.edit.on();
            service.editor.image.hideProgressBar();
            service.editor.popups.hideAll();
        })
    }

    /**
     * Method to override the froalaEditor.image.beforeUpload event to upload image to our servers instead of
     * froala's ones.
     * Needs to be applied for each Froala editor instance.
     *
     * Usage:
     *     vm.FROALA_OPTIONS = {
     *         // ...
     *         events : {
     *             'froalaEditor.image.beforeUpload': FroalaService.beforeUpload,
     *         },
     *         // ...
     *     };
     *
     * @param  {Event}   evt    The upload event.
     * @param  {Object}  editor The Froala editor object.
     * @param  {Array}   images The images to upload.
     * @return {boolean} False, always.
     */
    function beforeUpload(evt, editor, images) {
        service.editor = editor;

        // Freeze the editor during the upload.
        editor.image.showProgressBar();
        editor.edit.off();

        uploadImages(images);

        // Stop default upload system (since we've just already done the thing).
        return false;
    }

    _DEFAULT_OPTIONS.events['froalaEditor.image.beforeUpload'] = beforeUpload;

    /**
     * Method to override the froalaEditor.image.beforePasteUpload event to upload pasted image to our servers
     * instead of froala's ones.
     * Needs to be applied for each Froala editor instance.
     *
     * @param  {Event}   evt    The paste upload event.
     * @param  {Object}  editor The Froala editor object.
     * @param  {Object}  img    The pasted image.
     * @return {boolean} False, always.
     */
    function beforePasteUpload(evt, editor, img) {
        service.editor = editor;

        // Freeze the editor during the upload.
        editor.image.showProgressBar();
        editor.edit.off();

        const imgElement = angular.element(img);

        // Convert image to blob.
        const binary = atob(imgElement.attr('src').split(',')[1]);
        const array = [];
        // eslint-disable-next-line lumapps/angular-foreach
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        const uploadImg = new Blob([new Uint8Array(array)], {
            type: 'image/jpeg',
        });

        // Remove DOM modification made by Froala.
        imgElement.remove();

        Object.assign(uploadImg, { name: generateUUID() })

        uploadImages([uploadImg]);

        // Stop default upload system (since we've just already done the thing).
        return false;
    }

    _DEFAULT_OPTIONS.events['froalaEditor.image.beforePasteUpload'] = beforePasteUpload;


    /**
     * Focus the editor, move the cursor at the end of the content and scroll the page so the cursor is visible.
     *
     * @param {Event}  evt                    An event (on which we trigger the focus, usually 'initialized').
     * @param {Object} editor                 The Froala editor.
     * @param {string} [position='beginning'] Indicates where we want to put the cursor.
     *                                        Possible values: 'beginning' to place it at the beginning of the
     *                                                         WYSIWYG;
     *                                                         'end' to place it at the end of the WYSIWYG and to
     *                                                         scroll the page to this location.
     */
    function focusEditor(evt, editor, position) {
        position = position || 'beginning';

        editor.events.focus();

        if (position === 'end') {
            $timeout(function delayMoveCursorAtEnd() {
                Utils.setEndOfContenteditable(editor.$el.get(0));

                const headerHeight =
                    $window.innerWidth >= _DESKTOP_MIN_SCREEN_WIDTH
                        ? ConfigTheme.HEADER_FRONT_OFFSET
                        : _MOBILE_HEADER_OFFSET;
                angular
                    .element($window)
                    .scrollTop(
                        editor.$el.offset().top +
                            editor.$el.height() +
                            headerHeight -
                            angular.element($window).height(),
                    );
            });
        }
    }

    /**
     * Get the config of the customizable toolbar.
     *
     * @return {Array} The toolbar configuration.
     */
    function getHTMLToolbar() {
        return Instance.getProperty(Config.INSTANCE_PROPERTIES.WYSIWYG_TOOLBAR) || Config.DEFAULT_FROALA_HTML_TOOLBAR;
    }

    /**
     * Get the default options for the Froala editor.
     *
     * @param  {boolean} [addHTML=false]                                   Indicates if the editor needs extra
     *                                                                     HTML-related options.
     * @param  {boolean} [addImageButtons=false]                           Indicates if the editor needs extra
     *                                                                     HTML-related options.
     * @param  {boolean} [addFocusOnInit=false]                            Indicates if we need to focus the editor
     *                                                                     on init or not.
     * @param  {boolean} [allowFullscreen=false]                           Indicates if the editor has the
     *                                                                     fullscreen toolbar button or not.
     * @param  {Array}   [toolbarButtons=_DEFAULT_OPTIONS_TOOLBAR_BUTTONS] An array of the toolbar buttons to use.
     * @return {Object}  The default options of the Froala editor.
     */
    function getOptions(addHTML, addImageButtons, addFocusOnInit, allowFullscreen, toolbarButtons, toolbarStickyOffset) {
        const options = angular.fastCopy(_DEFAULT_OPTIONS);

        addHTML = addHTML || false;
        addImageButtons = addImageButtons || false;
        addFocusOnInit = addFocusOnInit || false;
        allowFullscreen = allowFullscreen || false;
        toolbarButtons = toolbarButtons || angular.fastCopy(_DEFAULT_OPTIONS_TOOLBAR_BUTTONS);

        if (addHTML) {
            options.htmlAllowedAttrs = _HTML_ALLOWED_ATTRS;
            options.htmlAllowedEmptyTags = _HTML_ALLOWED_EMPTY_TAGS;
            options.htmlAllowedTags = _HTML_ALLOWED_TAGS;
        }

        if (addFocusOnInit) {
            options.events['froalaEditor.initialized'] = service.focus;
        }

        if (addImageButtons) {
            options.imageEditButtons = [
                'imageAlign',
                'imageRemove',
                '|',
                'imageLinkDropdown',
                'linkEditWrapper',
                'linkRemove',
                '-',
                'imageDisplay',
                'imageStyle',
                'imageAlt',
                'imageSize',
            ];
        }

        if (allowFullscreen && !includes(toolbarButtons, 'fullscreen')) {
            toolbarButtons.unshift('fullscreen');
        } else if (!allowFullscreen) {
            toolbarButtons = without(toolbarButtons, 'fullscreen');
        }

        service.setStyleOptions(ConfigTheme);

        // Remove inline style if we don't have inline classes.
        if (angular.isUndefinedOrEmpty(ConfigTheme.FROALA_INLINE_STYLES) && includes(toolbarButtons, 'inlineStyle')) {
            toolbarButtons = without(toolbarButtons, 'inlineStyle');
        }

        // Remove paragraph style if we don't have paragraph classes.
        if (
            angular.isUndefinedOrEmpty(ConfigTheme.FROALA_PARAGRAPH_CLASSES) &&
            includes(toolbarButtons, 'paragraphStyle')
        ) {
            toolbarButtons = without(toolbarButtons, 'paragraphStyle');
        }

        // Set the same toolbar for all froala breakpoint.
        options.toolbarButtons = toolbarButtons;
        options.toolbarButtonsMD = toolbarButtons;
        options.toolbarButtonsSM = toolbarButtons;
        options.toolbarButtonsXS = toolbarButtons;

        // Set stiky toolbar offset
        if (angular.isDefinedAndFilled(toolbarStickyOffset)) {
            options.toolbarStickyOffset = toolbarStickyOffset;
        }

        options.charCounterCount = false;

        return options;
    }

    /**
     * Insert an image resulting from an upload in the content.
     *
     * @param {Array} result The result of the upload.
     */
    function insertImageFromUpload(result) {
        // Insert images in editor as HTML.
        const images = angular.isArray(result) ? result : [result];

        angular.forEach(images, function forEachImages(image) {
            if (image.type === 'image') {
                service.editor.html.insert(
                    `<img class="fr-dib fr-draggable"src="${image.url}" style="width: 300px;"><br>`,
                );
            }
        });

        // Commit the insertion.
        service.editor.edit.on();
        service.editor.undo.saveStep();

        // Hide popups.
        service.editor.image.hideProgressBar();
        service.editor.popups.hideAll();
    }

    /**
     * Define Froala style config from instance scripts.
     *
     * @param {Object} froalaConfig Instance configuration for Froala editor.
     */
    function setStyleOptions(froalaConfig) {
        let { toolbarButtons } = _DEFAULT_OPTIONS;

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_FONT_FAMILY)) {
            $.FroalaEditor.DEFAULTS.fontFamily = froalaConfig.FROALA_FONT_FAMILY;
        }

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_IMAGE_CLASSES)) {
            $.FroalaEditor.DEFAULTS.imageStyles = froalaConfig.FROALA_IMAGE_CLASSES;
        }

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_INLINE_STYLES)) {
            $.FroalaEditor.DEFAULTS.inlineStyles = froalaConfig.FROALA_INLINE_STYLES;
        } else if (includes(toolbarButtons, 'inlineStyle')) {
            toolbarButtons = without(toolbarButtons, 'inlineStyle');
        }

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_LINK_CLASSES)) {
            $.FroalaEditor.DEFAULTS.linkStyles = froalaConfig.FROALA_LINK_CLASSES;
        }

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_PARAGRAPH_CLASSES)) {
            $.FroalaEditor.DEFAULTS.paragraphStyles = froalaConfig.FROALA_PARAGRAPH_CLASSES;
        } else if (includes(toolbarButtons, 'paragraphStyle')) {
            toolbarButtons = without(toolbarButtons, 'paragraphStyle');
        }

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_TABLE_CELL_CLASSES)) {
            $.FroalaEditor.DEFAULTS.tableCellStyles = froalaConfig.FROALA_TABLE_CELL_CLASSES;
        }

        if (angular.isDefinedAndFilled(froalaConfig.FROALA_TABLE_CLASSES)) {
            $.FroalaEditor.DEFAULTS.tableStyles = froalaConfig.FROALA_TABLE_CLASSES;
        }
    }

    /////////////////////////////

    service.beforePasteUpload = beforePasteUpload;
    service.beforeUpload = beforeUpload;
    service.focus = focusEditor;
    service.getHTMLToolbar = getHTMLToolbar;
    service.getOptions = getOptions;
    service.insertImageFromUpload = insertImageFromUpload;
    service.setStyleOptions = setStyleOptions;

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        return {
            isLoaded: true,
        };
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'froala';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = UPDATE_ACTION_TYPE;

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    service.init = function init() {
        service.setStyleOptions(ConfigTheme);

        AdvancedSettingsCst.WYSIWYG_TOOLBAR = {
            category: 'wysiwyg',
            choices: getWysiwygToolbar(),
            dragAndDropType: 'froala-toolbar',
            translationPrefix: 'FRONT.FROALA.',
            type: 'drag-and-drop',
        };
        AdvancedSettingsConstant.WYSIWYG_TOOLBAR = AdvancedSettingsCst.WYSIWYG_TOOLBAR;

        _DEFAULT_OPTIONS.language = Translation.getLang('current', 'froala');

        // The colors are provided by the global style with a fallback to the default color of the core theme.
        _instanceColors = get(Style.getInstanceGlobalStyle(), 'properties.colors', ConfigTheme.COLORS);

        const whiteIndex = findIndex(_instanceColors, function findWhite(color) {
            color = color.toUpperCase();

            return (
                color === 'WHITE' ||
                color === '#FFFFFF' ||
                color === '#FFF' ||
                // eslint-disable-next-line no-magic-numbers
                (startsWith(color, 'RGB(255') && (color.match(/255/g) || []).length === 3)
            );
        });
        if (whiteIndex > -1) {
            _instanceColors.splice(whiteIndex, 1);
            _instanceColors.unshift('#FFFFFF');
        }
        const transparentIndex = findIndex(_instanceColors, function findWhite(color) {
            color = color.toUpperCase();

            // eslint-disable-next-line unicorn/no-unsafe-regex
            return color === 'TRANSPARENT' || /, ?0(\.0+)?$/.test(color);
        });
        if (transparentIndex > -1) {
            _instanceColors.splice(transparentIndex, 1);
            _instanceColors.push('transparent');
        }

        /*
         * Defaults options of the Froala WYSIWYG editor.
         */
        angular.extend($.FroalaEditor.DEFAULTS, {
            // eslint-disable-next-line angular/window-service
            codeMirror: window.CodeMirror,
            colorsBackground: _instanceColors,
            colorsText: _instanceColors,
            fontFamily: ConfigTheme.FROALA_FONT_FAMILY || FroalaSettings.fonts,
            fontSize: FroalaSettings.fontSize,
            htmlExecuteScripts: false,
            htmlRemoveTags: [],
            iconsTemplate: 'material_design',
            imageStyles: ConfigTheme.FROALA_IMAGE_CLASSES,
            inlineStyles: ConfigTheme.FROALA_INLINE_STYLES,
            isPopin: false,
            key: FroalaSettings.key,
            linkStyles: ConfigTheme.FROALA_LINK_CLASSES,
            paragraphStyles: ConfigTheme.FROALA_PARAGRAPH_CLASSES,
            pasteDeniedAttrs: Instance.getProperty(Config.INSTANCE_PROPERTIES.WYSIWYG_PASTEPLAIN) !== 'No' ? ['class', 'id', 'style'] : [],
            shortcutsEnabled: ['undo', 'redo'],
            tableCellStyles: ConfigTheme.FROALA_TABLE_CELL_CLASSES,
            tableColors: _instanceColors,
            tableStyles: ConfigTheme.FROALA_TABLE_CLASSES,
            videoDefaultDisplay: 'block',
            videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
            videoResponsive: true,
        });

        // Enable Redux sync.
        ReduxStore.subscribe(service);
    };

    service.init();
}

/////////////////////////////

angular.module('Services').service('FroalaService', FroalaService);

/////////////////////////////

export { FroalaService, UPDATE_ACTION_TYPE };
