import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { generatePath } from 'react-router';

import { get as getConstants } from '@lumapps/constants';
import { isLegacyBrowser } from '@lumapps/utils/browser/isLegacyBrowser';
import { hasProtocol, isUrl } from '@lumapps/utils/string/isUrl';

import { AppId, LOCAL_PORT_CONFIGURATION, IGNORABLE_QUERY_PARAMS } from '../constants';
import { OptionalAppRoute, Query, Route } from '../types';

const constants = getConstants();

const isUrlAbsolute = (url: string) => {
    return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
};

/**
 * Retrieve a query param from a given URL.
 * Credit: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 * @param name
 * @param url
 * @returns query param value
 */
const getParameterByName = (name: string, url = window.location.href) => {
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Retrieves the base URL composed of the customer and instance slug from a full URL
 * If the domain is custom and includeInstanceSlug is true, it returns the instance slug only.
 * @param url - URL normally retrieved from window.location.pathname
 * @param includeInstanceSlug - whether the URL returned should include the instance slug or not
 * @param includeTrailingSlash - whether to include a trailing slash (/) at the end or not.
 */
const getBaseUrl = (url: string, includeInstanceSlug = true, includeTrailingSlash = false) => {
    if (constants.baseUrl) {
        return constants.baseUrl;
    }

    const splitPath = url.split('/');
    let baseUrl = '';

    const isCustomDomain = url.indexOf('/a/') === -1;
    if (isCustomDomain) {
        // If it is a custom domain and includeInstanceSlug is true, return the instance slug only.
        // if includeInstanceSlug is false, return empty string.
        if (includeInstanceSlug) {
            baseUrl = `/${splitPath[1] || ''}`;
        }
    } else {
        baseUrl = '/a';

        if (splitPath[2]) {
            baseUrl = `${baseUrl}/${splitPath[2]}`;

            if (splitPath[3] && includeInstanceSlug) {
                baseUrl = `${baseUrl}/${splitPath[3]}`;
            }
        }
    }

    if (includeTrailingSlash) {
        if (!baseUrl.endsWith('/')) {
            baseUrl = `${baseUrl}/`;
        }
    }

    return baseUrl;
};

const removeParamFromUrl = (key: string, sourceURL: string) => {
    let rtn = sourceURL.split('?')[0];
    let param;
    let paramsArr = [];
    const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

    if (queryString !== '') {
        paramsArr = queryString.split('&');
        for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
            [param] = paramsArr[i].split('=');
            if (param === key) {
                paramsArr.splice(i, 1);
            }
        }

        rtn = paramsArr.length > 0 ? `${rtn}?${paramsArr.join('&')}` : rtn;
    }

    return rtn;
};

const addQueryParamsToUrl = (url: string, query: Query, replaceTrailingSlash = true) => {
    let urlWithQuery = url;
    let isFirst = urlWithQuery.indexOf('?') < 0;

    if (typeof query === 'string') {
        if (query && !query.startsWith('?')) {
            urlWithQuery += '?';
        }
        urlWithQuery += query;
    } else {
        Object.keys(query).forEach((q) => {
            const queryValue = query[q];
            const queryParam = `${q}${queryValue ? `=${encodeURIComponent(queryValue)}` : ''}`;

            if (isFirst) {
                urlWithQuery = `${urlWithQuery}?${queryParam}`;
                isFirst = false;
            } else {
                urlWithQuery = `${urlWithQuery}&${queryParam}`;
            }
        });
    }

    return replaceTrailingSlash ? urlWithQuery.replace('/?', '?') : urlWithQuery;
};

const getPathFromUrl = (url: string) => {
    return url.split('?')[0];
};

/**
 * Extract relative Route object from a string URL using new URL().
 * @param url - URL as a string.
 */
const getRelativeRouteFromUrl = (url: string): Route | null => {
    if (!url) {
        return null;
    }

    try {
        const newUrl = new URL(url, window.location.origin);

        return {
            query: newUrl.search,
            anchor: newUrl.hash,
            appId: constants.applicationId as AppId,
            path: newUrl.pathname,
        };
    } catch (e) {
        return null;
    }
};

const replacePathWithParamsForRoute = (route: OptionalAppRoute) => {
    const { params, path, ignoreParamEncoding } = route;
    let url = path;

    if (params) {
        Object.keys(params).forEach((param) => {
            const paramValue = params[param];

            if (paramValue) {
                url = url.replace(`:${param}`, ignoreParamEncoding ? paramValue : encodeURIComponent(paramValue));
            }
        });

        /**
         * Since there are parameters that can be optional, we need to remove them
         * from the URL if they are still present. This section checks that
         * and figures out what it needs to remove.
         */
        if (url.indexOf(':') >= 0 || url.indexOf('?') >= 0) {
            /**
             * This regexp checks the following pattern:
             * - :test? => match (optional parameter not replaced), needs to be removed entirely
             * - test? => match (optional parameter replaced but there is an extra ?), we need to remove the ?
             * - :test* => path parameter not replace => we need to remove the parameter entirely
             */
            const regex = /(:?)[a-zA-Z]+(\?|\*)/gm;
            const matches = regex.exec(url);

            if (matches) {
                matches.forEach((match) => {
                    if (match.indexOf(':') >= 0) {
                        url = url.replace(match, '');
                    } else if (match.indexOf('?') >= 0) {
                        url = url.replace('?', '');
                    }
                });

                url = url.replace('?', '');
            }
        }

        /**
         * Final cleanup, this double checks that there are no double slashes due to the parameters cleanup
         */
        if (url.indexOf('//') >= 0) {
            url = url.replace(/\/\//g, '/');
        }
    }

    /**
     * Sometimes an * lingers at the end after certain URLs that use /:param* at the end for having multiple path levels.
     * React router seems to leave them there, so we need to remove them.
     */
    if (url[url.length - 1] === '*') {
        url = url.slice(0, -1);
    }

    return url;
};

/**
 * Adds the front version query param to a given URL if the
 * URL does not have it already. This should be used only if the URLs are generated
 * on the backend side, if the URL are created on the frontend side, using @lumapps/router
 * should do the trick.
 *
 * @param url
 * @returns url with front version query param
 */
const addFrontVersionToUrl = (url: string) => {
    let urlWithFrontVersion = url;
    const frontVersion = getParameterByName('frontVersion');
    const frontVersionFromUrl = getParameterByName('frontVersion', url);

    if (frontVersion && !frontVersionFromUrl) {
        urlWithFrontVersion = addQueryParamsToUrl(urlWithFrontVersion, { frontVersion }, false);
    }

    return urlWithFrontVersion;
};

/**
 * Creates a relative URL (does not take into account instance and customer slugs) with the
 * given url parameters and query parameters.
 * @param route - route used for creating the url
 */
const createRelativeUrl = (route: OptionalAppRoute) => {
    const { query, params, anchor, path, ignoreParamEncoding } = route;
    let url = path;

    if (!ignoreParamEncoding) {
        try {
            url = generatePath(path, params);
        } catch (excp) {
            /**
             * If React Router returns an exception, it means that there was something on the URL that was not provided.
             * It could have been a param, a path, wrong URL formatting, etc. In this case instead of throwing an exception
             * we want to at least try the old fashion way and replace the parameters by hand. If that does not do the trick
             * then well, a wrongly formed URL will be formed, but at least no errors will be thrown.
             */
            // eslint-disable-next-line no-console
            console.error(excp);

            url = replacePathWithParamsForRoute(route);
        }
    } else {
        url = replacePathWithParamsForRoute(route);
    }

    if (query) {
        url = addQueryParamsToUrl(url, query);
    }

    url = addFrontVersionToUrl(url);

    if (anchor) {
        url += anchor.startsWith('#') ? anchor : `#${anchor}`;
    }

    return url;
};

/**
 * Returns a URL that takes into account the customer and instance slugs
 * @param route - route used to create the url
 */
const createUrl = (route: OptionalAppRoute, relativeUrl?: string | null, customInstanceSlug?: string) => {
    const instanceSlug = customInstanceSlug || route.instanceSlug;
    const hasCustomInstanceSlug = Boolean(instanceSlug);
    const isExternal = route.appId === AppId.external;
    const baseUrl = isExternal ? '' : getBaseUrl(window.location.pathname, !hasCustomInstanceSlug);
    const customInstancePart = !isExternal && hasCustomInstanceSlug ? `/${instanceSlug}` : '';
    const url = `${baseUrl}${customInstancePart}${relativeUrl || createRelativeUrl(route)}`;

    /**
     * This checks if we are currently developing locally and the application target is different
     * from the current application id, it means that we are trying to create a link towards another
     * application. In this scenario, we need to create a different URL with a different port, since they
     * are separate applications hosted in different ports.
     */
    if (
        route.appId &&
        constants.isDev &&
        !constants.noDefaultPageNotFoundRedirection &&
        route.appId !== constants.applicationId &&
        !isExternal
    ) {
        const portForLocalTargetApp = LOCAL_PORT_CONFIGURATION[route.appId];
        return `http://localhost:${portForLocalTargetApp}${url}`;
    }

    return url;
};

const normalizeSlug = (slug: string) => {
    const normalized = slug.indexOf('/') === 0 ? slug.slice(1, slug.length) : slug;

    return normalized;
};

/**
 * Add the base url to the given slug
 * The base url is taken from the current location pathname
 * @param slug a content slug
 * @param includeInstanceSlug - whether it should include the instance slug on the base URL or not
 */
const addBaseSlug = (slug: string, includeInstanceSlug = true) => {
    if (slug && slug !== '') {
        const currentLocation = window.location.pathname;

        const baseUrl = getBaseUrl(currentLocation, includeInstanceSlug);

        // Avoid to add a double `/` between base and slug if the slug start with `/`
        const separator = slug.indexOf('/') === 0 ? '' : '/';
        // Avoid to add `//` at the beginning of the url to be sure that the slug is considered as internal
        return `${baseUrl !== '/' ? baseUrl : ''}${separator}${slug}`;
    }

    return slug;
};

/**
 * Normalize a link whether it is exernal or internal.
 * @param link
 */
const normalizeLink = (link?: string | null) => {
    if (link) {
        // Check if the link is external
        if (isUrl(link)) {
            return link || '';
        }

        return addBaseSlug(link);
    }

    return null;
};

const removeBaseSlug = (slug: string) => {
    const baseSlug = getBaseUrl(slug);

    return slug.replace(baseSlug, '');
};

const addBaseSlugToUrl = (url: string, location?: string, includeInstanceSlug = false) => {
    let urlWithSlug = url;

    if (url && url !== '') {
        const currentLocation = location || window.location.pathname;
        const isCurrentLocationACustomDomain = currentLocation.indexOf('/a/') === -1;
        const doesUrlIncludeSlug = url.indexOf('/a/') > -1;

        if (!isCurrentLocationACustomDomain && !doesUrlIncludeSlug && !isUrlAbsolute(url)) {
            const baseUrl = getBaseUrl(currentLocation, includeInstanceSlug);

            urlWithSlug = url.indexOf('/') === 0 ? `${baseUrl}${url}` : `${baseUrl}/${url}`;
        }

        urlWithSlug = addFrontVersionToUrl(urlWithSlug);
    }

    return urlWithSlug;
};

/**
 * It returns the pathname and the query params for a given URL.
 * https://we.lumapps.com/we/community?lang=fr => /we/community?lang=fr
 */
const getPathnameFromUrl = (url: string) => {
    if (isLegacyBrowser()) {
        return url;
    }

    try {
        const parsedUrl = new URL(url);
        return `${parsedUrl.pathname}${parsedUrl.search}`;
    } catch (excep) {
        return url;
    }
};

const getRouteAsLinkProps = (route: Route, relativeUrl?: string | null, customInstanceSlug?: string) => {
    const href = createUrl(route, relativeUrl, customInstanceSlug);

    let target;

    if (route.appId !== AppId.legacy && constants.isLegacyContext) {
        target = '_self';
    } else if (route.appId === AppId.external) {
        target = '_blank';
    }

    return {
        href,
        target,
    };
};

const addLocationOriginToUrl = (url: string) => (hasProtocol(url) ? url : window.location.origin + url);

const isCurrentURL = (url: string, includeInstanceSlug = true, shouldEncodeURI = false) => {
    let currentUrl = `${window.location.pathname}${window.location.search}`;

    for (const key of IGNORABLE_QUERY_PARAMS) {
        currentUrl = removeParamFromUrl(key, currentUrl);
    }

    if (isUrl(url)) {
        const pathname = getPathnameFromUrl(url);

        return pathname === currentUrl;
    }

    const basedSlugUrl = addBaseSlugToUrl(url, undefined, includeInstanceSlug);

    // If shouldEncodeURI is true, encode the created url so it can handle non-alphanumericals URI comparison
    return currentUrl === (shouldEncodeURI ? encodeURI(basedSlugUrl) : basedSlugUrl);
};

/**
 * Check if the provided url is contained in the current page url
 * @param url The url to be checked
 * @param includeInstanceSlug whether instance slug should be included
 * @returns a boolean stating if the provided url is included in the current url
 */

const isPartOfCurrentURL = (url: string, includeInstanceSlug = true) => {
    const currentUrl = `${window.location.pathname}${window.location.search}`;

    if (isUrl(url)) {
        const pathname = getPathnameFromUrl(url);

        return currentUrl.includes(pathname);
    }

    const basedSlugUrl = addBaseSlugToUrl(url, undefined, includeInstanceSlug);

    return currentUrl.includes(basedSlugUrl);
};

/**
 * Test if a given URL is in the same instance that the current page.
 *
 * @param  {string} url The url to test.
 * @return {boolean} Does the url passed the test.
 */
const isSameInstanceUrl = (url: string) => {
    const currentOrigin = getBaseUrl(window.location.pathname);
    const urlOrigin = getBaseUrl(url);

    return currentOrigin === urlOrigin;
};

/**
 * Get the naked domain of the given URL (no "http[s]" or "www").
 *
 * @param  {string} urlToParse The url to get the naked domain from.
 * @return {string} The naked domain.
 */
function getDomainFromString(urlToParse: string) {
    return urlToParse.replace('http://', '').replace('https://', '');
}

/**
 * Get a location object based on a URL string.
 * E.g. "http://lumapps.com" will return the following location object:
 *     { hostname: "lumapps.com", ... }
 * this is typically used to retrieve hostname, pathname, protocol, ... from a string URL.
 *
 * @param  {string} urlToParse The URL to parse.
 * @return {Object} A location object.
 */
function getLocationFromString(urlToParse: string) {
    if (!urlToParse || !isString(urlToParse)) {
        return {};
    }

    const anchorEl = document.createElement('a');
    anchorEl.href = urlToParse;

    return anchorEl || {};
}
const isAdminUrl = (url: string) => {
    return url.indexOf('/admin/') >= 0;
};

/**
 * Get an absolute url from another url if needed.
 */
const getAbsoluteUrl = (url: string) => {
    return isUrl(url) || isEmpty(url) || isUrlAbsolute(url) ? url : addLocationOriginToUrl(url);
};

export {
    getBaseUrl,
    createUrl,
    createRelativeUrl,
    addBaseSlug,
    normalizeLink,
    addQueryParamsToUrl,
    getPathFromUrl,
    removeParamFromUrl,
    removeBaseSlug,
    addBaseSlugToUrl,
    getPathnameFromUrl,
    normalizeSlug,
    getRouteAsLinkProps,
    addLocationOriginToUrl,
    isCurrentURL,
    isPartOfCurrentURL,
    isUrl as isExternalUrl,
    isUrlAbsolute,
    getDomainFromString,
    getLocationFromString,
    isAdminUrl,
    getParameterByName,
    replacePathWithParamsForRoute,
    getAbsoluteUrl,
    getRelativeRouteFromUrl,
    isSameInstanceUrl,
    addFrontVersionToUrl,
};
