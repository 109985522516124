import { useEffect, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import some from 'lodash/some';

import { FILTERS_WITH_POTENTIAL_DEFAULT_VALUES } from '../constants';
import { FormattedTagFilter, NGIFilterId, GenericListFilterType, GenericListFilterProperties } from '../types';

interface UseFilterPropertiesProps {
    activeFilters?: { ignoredDefaultFilters?: string[]; [key: string]: any };
    filterProperties?: GenericListFilterProperties;
    onFilterChange?: (newFilters: Record<string, any>) => void;
}

/**
 * This hook holds two functions:
 * - Format the filter properties from the main widget
 * - Udpate the active filters with the filter properties
 * @param UseFilterPropertiesProps
 * @returns GenericListFilterType
 */
export const useFilterProperties = ({ activeFilters, filterProperties, onFilterChange }: UseFilterPropertiesProps) => {
    /**
     * Get filter properties in the format we are using commonly,
     * making sure that only keys with values are returned
     * */
    const filterPropertiesFormated = useMemo(() => {
        if (filterProperties === undefined) {
            return null;
        }
        const metadata = filterProperties?.metadata?.map((metadata) => ({
            id: metadata.id,
            name: metadata.name,
            rootId: metadata.root.id,
        }));
        const site = filterProperties?.siteReferences?.map((site) => ({ ...site, name: site.name || '' }));
        const tags: FormattedTagFilter[] | undefined = filterProperties?.tags?.map(
            (tag) =>
                ({
                    id: tag.id,
                    name: tag.name.value,
                }) as FormattedTagFilter,
        );
        const author = filterProperties.authorReference?.email
            ? {
                  email: filterProperties.authorReference?.email || '',
                  fullName: filterProperties.authorReference?.fullName || '',
                  lastName: '',
                  firstName: '',
              }
            : undefined;

        return omitBy<GenericListFilterType>(
            {
                metadata,
                tags,
                contentTypes: filterProperties?.contentTypeReferences,
                directories: filterProperties?.directoryReferences,
                site,
                author,
                sort: filterProperties?.sort,
                areTabsEnabled: filterProperties?.areTabsEnabled,
                onlyHighlighted: filterProperties?.onlyHighlighted,
            },
            isNil,
        );
    }, [filterProperties]);

    /**
     * We are checking main widget filters from their properties with potential default values.
     * Then, we can update the active filters accordingly.
     */
    const isFilterUpdateNeeded = useMemo(
        () =>
            some(FILTERS_WITH_POTENTIAL_DEFAULT_VALUES, (filterId) => {
                return (
                    filterPropertiesFormated &&
                    (!isEmpty(filterPropertiesFormated[filterId]) || filterPropertiesFormated[filterId] === true)
                );
            }),
        [filterPropertiesFormated],
    );

    /**
     * We are updating the active filters with the default filtering if none was existing before
     */
    useEffect(() => {
        if (isFilterUpdateNeeded && filterPropertiesFormated && onFilterChange) {
            /**
             * Resolve a filter given its name, it returns the filter value
             */
            const resolveFilter = (filterKey: keyof typeof filterPropertiesFormated) => {
                if (
                    !activeFilters?.ignoredDefaultFilters?.includes(filterKey) &&
                    isNil(activeFilters?.[filterKey]) &&
                    (!isEmpty(filterPropertiesFormated[filterKey]) || filterPropertiesFormated[filterKey] === true)
                ) {
                    return {
                        [filterKey]: filterPropertiesFormated[filterKey],
                        ignoredDefaultFilters: [filterKey],
                    };
                }

                return undefined;
            };

            /**
             * Exception: if tabs are activated, we are not supporting tags (which is basically the same things, just different naming)
             */
            const filtersToResolve = filterPropertiesFormated?.areTabsEnabled
                ? FILTERS_WITH_POTENTIAL_DEFAULT_VALUES.filter((filter) => filter !== NGIFilterId.tags)
                : FILTERS_WITH_POTENTIAL_DEFAULT_VALUES;

            const resolvedFilters = filtersToResolve.reduce<{
                ignoredDefaultFilters?: string[];
                [key: string]: unknown;
            }>((acc, filterKey) => {
                const resolvedFilter = resolveFilter(filterKey as keyof GenericListFilterType);

                return resolvedFilter
                    ? {
                          ...acc,
                          ...resolvedFilter,
                          ignoredDefaultFilters: (acc.ignoredDefaultFilters || []).concat(
                              resolvedFilter.ignoredDefaultFilters || [],
                          ),
                      }
                    : acc;
            }, {});

            if (!isEmpty(resolvedFilters)) {
                /**
                 * If no overridable filter applied before getting properties, then we apply default filter from properties,
                 * We also add the concerned filters to the list of ignored default filters to make sure to ignore the default filtering in the next calls
                 * now that it is explicited in the /blocks API call
                 */
                onFilterChange(resolvedFilters);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterPropertiesFormated?.metadata, filterPropertiesFormated?.tags, filterPropertiesFormated?.site]);

    /** If filter properties are not defined, it's not relevant to return filterPropertiesFormated */
    return filterPropertiesFormated;
};
