export enum REPORTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130563628
     */
    ELEMENT_NUMBER = 'REPORTS.ELEMENT_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131321378
     */
    SEE_POST = 'REPORTS.SEE_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=143935538
     */
    SEE_CONTENT = 'REPORTS.SEE_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113872319
     */
    REPORT_DIALOG_SUBTITLE = 'REPORTS.REPORT_DIALOG_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113872672
     */
    REPORT_DIALOG_MESSAGE = 'REPORTS.REPORT_DIALOG_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113930720
     */
    DIALOG_REQUIRED_REASON = 'REPORTS.DIALOG_REQUIRED_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114404970
     */
    COMMENT_COMPETITOR_ACCESS_MODAL_TITLE = 'REPORTS.COMMENT_COMPETITOR_ACCESS_MODAL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161810091
     */
    POST_COMPETITOR_ACCESS_MODAL_TITLE = 'REPORTS.POST_COMPETITOR_ACCESS_MODAL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114404328
     */
    COMMENT_COMPETITOR_ACCESS_MODAL_MESSAGE = 'REPORTS.COMMENT_COMPETITOR_ACCESS_MODAL_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161810220
     */
    POST_COMPETITOR_ACCESS_MODAL_MESSAGE = 'REPORTS.POST_COMPETITOR_ACCESS_MODAL_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113930771
     */
    REPORT_DIALOG_VALIDATE = 'REPORTS.REPORT_DIALOG_VALIDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114987215
     */
    COMMENT_REVIEW_DIALOG_TITLE = 'REPORTS.COMMENT_REVIEW_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114987149
     */
    POST_REVIEW_DIALOG_TITLE = 'REPORTS.POST_REVIEW_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114989200
     */
    COMMENT_REVIEW_DIALOG_REPORTED_COMMENT = 'REPORTS.COMMENT_REVIEW_DIALOG_REPORTED_COMMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164971146
     */
    POST_REVIEW_DIALOG_REPORTED_POST = 'REPORTS.POST_REVIEW_DIALOG_REPORTED_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114989236
     */
    REVIEW_DIALOG_DISMISS_REPORT_LABEL = 'REPORTS.REVIEW_DIALOG_DISMISS_REPORT_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115000125
     */
    REPORTS_TITLE = 'REPORTS.REPORTS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130563555
     */
    EMPTY_REVIEW_LIST_TITLE = 'REPORTS.EMPTY_REVIEW_LIST_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130563598
     */
    EMPTY_REVIEW_LIST_SUBTITLE = 'REPORTS.EMPTY_REVIEW_LIST_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130563618
     */
    REVIEW = 'REPORTS.REVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134928200
     */
    REPORT = 'REPORTS.REPORT',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133363578
     */
    DISMISS_REPORT_COMMENT_SUCCESS = 'REPORTS.DISMISS_REPORT_COMMENT_SUCCESS',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=143089009
     */
    LOAD_MORE_REPORTS = 'REPORTS.LOAD_MORE_REPORTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165742313
     * */
    CLEAR_ALL = 'REPORTS.CLEAR_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165742585
     * */
    EMPTY_REVIEW_LIST_TITLE_NO_MATCHING_RESULT = 'REPORTS.EMPTY_REVIEW_LIST_TITLE_NO_MATCHING_RESULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165742607
     * */
    EMPTY_REVIEW_LIST_SUBTITLE_NO_MATCHING_RESULT = 'REPORTS.EMPTY_REVIEW_LIST_SUBTITLE_NO_MATCHING_RESULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164973628
     */
    THANKS_DIALOG_MESSAGE = 'REPORTS.REPORT_THANKS_DIALOG_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=322535334
     */
    ERROR_REPORTED_RESOURCE_NOT_FOUND = 'REPORTS.ERROR_REPORTED_RESOURCE_NOT_FOUND',
}

export enum REPORT_ALREADY_REPORTED {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=128074913
     */
    comment = 'REPORTS.COMMENT_REPORT_ALREADY_REPORTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163319186
     */
    content = 'REPORTS.CONTENT_REPORT_ALREADY_REPORTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164083925
     */
    post = 'REPORTS.POST_REPORT_ALREADY_REPORTED',
}

export enum REPORT_ALREADY_REPORTED_DIALOG_MESSAGE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=140089814
     */
    comment = 'REPORTS.COMMENT_REPORT_ALREADY_REPORTED_DIALOG_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163315548
     */
    content = 'REPORTS.CONTENT_REPORT_ALREADY_REPORTED_DIALOG_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164084856
     */
    post = 'REPORTS.POST_REPORT_ALREADY_REPORTED_DIALOG_MESSAGE',
}

export enum REPORT_DIALOG_TITLE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113871961
     */
    comment = 'REPORTS.COMMENT_REPORT_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163314506
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164045804
     */
    post = 'REPORTS.POST_REPORT_DIALOG_TITLE',
}

export enum REPORT_DIALOG_REASON_ERROR {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113918969
     */
    comment = 'REPORTS.COMMENT_REPORT_DIALOG_REASON_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163309141
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_REASON_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164045898
     */
    post = 'REPORTS.POST_REPORT_DIALOG_REASON_ERROR',
}

export enum REPORT_DIALOG_INNAPROPRIATE_REASON {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113920382
     */
    comment = 'REPORTS.COMMENT_REPORT_DIALOG_INNAPROPRIATE_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163309584
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_INNAPROPRIATE_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164045911
     */
    post = 'REPORTS.POST_REPORT_DIALOG_INNAPROPRIATE_REASON',
}

export enum REPORT_DIALOG_PRIVACY_REASON {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113930573
     */
    comment = 'REPORTS.COMMENT_REPORT_DIALOG_PRIVACY_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164675513
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_PRIVACY_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164050060
     */
    post = 'REPORTS.POST_REPORT_DIALOG_PRIVACY_REASON',
}

export enum REPORT_DIALOG_OUTDATED_REASON {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170503
     */
    comment = 'REPORTS.COMMENT_REPORT_DIALOG_OUTDATED_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170539
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_OUTDATED_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170559
     */
    post = 'REPORTS.POST_REPORT_DIALOG_OUTDATED_REASON',
}

export enum REPORT_DIALOG_DUPLICATED_REASON {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170295
     */
    comment = 'REPORTS.COMMENT_REPORT_DIALOG_DUPLICATED_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170397
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_DUPLICATED_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170481
     */
    post = 'REPORTS.POST_REPORT_DIALOG_DUPLICATED_REASON',
}

export enum REPORT_DIALOG_VALIDATE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113930771
     */
    comment = 'REPORTS.REPORT_DIALOG_VALIDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163314541
     */
    content = 'REPORTS.CONTENT_REPORT_DIALOG_VALIDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164045784
     */
    post = 'REPORTS.POST_REPORT_DIALOG_VALIDATE',
}

export enum REVIEW_BLOCK_HEADER_BASE_MESSAGE {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114647488
     */
    comment = 'REPORTS.SINGLE_COMMENT_REPORT_BANNER_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163638050
     */
    content = 'REPORTS.SINGLE_CONTENT_REPORT_BANNER_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=163638135
     */
    post = 'REPORTS.SINGLE_POST_REPORT_BANNER_MESSAGE',
}

export enum RESOURCE_TYPE_FILTER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164453633
     */
    comment = 'REPORTS.COMMENT_RESOURCE_TYPE_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164454037
     */
    content = 'REPORTS.CONTENT_RESOURCE_TYPE_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164457168
     */
    post = 'REPORTS.POST_RESOURCE_TYPE_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164441971
     */
    all = 'REPORTS.ALL_RESOURCE_TYPE_FILTER',
}

export enum REVIEW_DIALOG_TITLE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114987215
     */
    comment = 'REPORTS.COMMENT_REVIEW_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114987215
     */
    content = 'REPORTS.CONTENT_REVIEW_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114987149
     */
    post = 'REPORTS.POST_REVIEW_DIALOG_TITLE',
}

export enum REVIEW_DIALOG_REPORTED_RESOURCE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114989200
     */
    comment = 'REPORTS.COMMENT_REVIEW_DIALOG_REPORTED_COMMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165413517
     */
    content = 'REPORTS.CONTENT_REVIEW_DIALOG_REPORTED_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164971146
     */
    post = 'REPORTS.POST_REVIEW_DIALOG_REPORTED_POST',
}

export enum REVIEW_DIALOG_APPROVE_REPORT_LABEL {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114989205
     */
    comment = 'REPORTS.COMMENT_REVIEW_DIALOG_APPROVE_REPORT_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165417082
     */
    content = 'REPORTS.CONTENT_REVIEW_DIALOG_APPROVE_REPORT_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115000193
     */
    post = 'REPORTS.POST_REVIEW_DIALOG_APPROVE_REPORT_LABEL',
}

export enum ACCEPT_REPORT_SUCCESS {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133368021
     */
    comment = 'REPORTS.ACCEPT_REPORT_COMMENT_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165415697
     */
    content = 'REPORTS.ACCEPT_REPORT_CONTENT_SUCCESS',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=
     */
    post = 'REPORTS.ACCEPT_REPORT_POST_SUCCESS',
}

export enum DISMISS_REPORT_SUCCESS {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133363578
     */
    comment = 'REPORTS.DISMISS_REPORT_COMMENT_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165417067
     */
    content = 'REPORTS.DISMISS_REPORT_CONTENT_SUCCESS',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165116125
     */
    post = 'REPORTS.DISMISS_REPORT_POST_SUCCESS',
}
export enum COMPETITOR_ACCESS_MODAL_TITLE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114404970
     */
    comment = 'REPORTS.COMMENT_COMPETITOR_ACCESS_MODAL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165021987
     */
    content = 'REPORTS.CONTENT_COMPETITOR_ACCESS_MODAL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161810091
     */
    post = 'REPORTS.POST_COMPETITOR_ACCESS_MODAL_TITLE',
}

export enum COMPETITOR_ACCESS_MODAL_MESSAGE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114404328
     */
    comment = 'REPORTS.COMMENT_COMPETITOR_ACCESS_MODAL_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165021989
     */
    content = 'REPORTS.CONTENT_COMPETITOR_ACCESS_MODAL_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161810220
     */
    post = 'REPORTS.POST_COMPETITOR_ACCESS_MODAL_MESSAGE',
}
