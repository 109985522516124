import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { sendTrackingDirectoryEntryClickActionEvent } from '@lumapps/directories/api/analytics';
import { useEntryLink } from '@lumapps/directory-entries/hooks/useEntryLink';
import { DirectoryEntry } from '@lumapps/directory-entries/types';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiBookmark } from '@lumapps/lumx/icons';
import { Thumbnail, Icon } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { isUrl } from '@lumapps/utils/string/isUrl';

export type FavoriteDropdownItemProps = {
    instanceSlug?: string;
    item: DirectoryEntry;
    className: string;
};

const SCOPE = 'favorite-item';

/**
 * A favorite item
 * @param FavoriteDropdownItemProps
 */
export const FavoriteDropdownItem: React.FC<FavoriteDropdownItemProps> = ({
    instanceSlug,
    item,
    className,
    ...rest
}) => {
    const { translateObject } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const thumbnail = React.useMemo(() => {
        if (!item.thumbnail) {
            return null;
        }

        return isUrl(item.thumbnail) ? item.thumbnail : `/serve/${item.thumbnail}`;
    }, [item.thumbnail]);

    const url = useEntryLink(item.link, instanceSlug);
    if (!url) {
        return null;
    }

    const name = translateObject(item.name);

    // used to send tracking event on user click
    const onClick = () => {
        sendTrackingDirectoryEntryClickActionEvent({
            targetId: item.id,
            url,
            title: name ?? undefined,
            thumbnail: thumbnail ?? undefined,
        });
    };

    return (
        <Menu.Item
            {...rest}
            before={
                thumbnail ? (
                    <Thumbnail
                        alt=""
                        image={thumbnail}
                        size="s"
                        aspectRatio="square"
                        className={`${className}-item__image`}
                    />
                ) : (
                    <Icon icon={mdiBookmark} size="s" />
                )
            }
            as="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
            {...get({ element: 'entry', action: item.id })}
        >
            {name}
        </Menu.Item>
    );
};
