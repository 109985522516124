import React from 'react';

import { Message } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_LEGACY_CORE } from '../../keys';

import './index.scss';

const CLASSNAME = 'mobile-compatibility-info';

interface MobileCompatibilityInfoProps {
    widgetType: string;
}

export const MobileCompatibilityInfo: React.FC<MobileCompatibilityInfoProps> = ({ widgetType }) => {
    const { translateKey } = useTranslate();
    const incompatibleWidgetList = translateKey(
        WIDGET_LEGACY_CORE.MOBILE_INCOMPATIBLE_WIDGET_LIST_VARIABLE,
        'en',
    ).split(',');

    const isWidgetMobileIncompatible = incompatibleWidgetList.includes(widgetType);

    if (!isWidgetMobileIncompatible) {
        return null;
    }

    return (
        <Message className={CLASSNAME} kind="info" hasBackground>
            {translateKey(WIDGET_LEGACY_CORE.WIDGET_PICKER_MOBILE_INCOMPATIBLE_MESSAGE)}
        </Message>
    );
};
