import React from 'react';

import { COMMUNITIES, getCommunityPrivacy } from '@lumapps/communities/keys';
import { CommunityPrivacy, CommunitySearchScopes } from '@lumapps/communities/types';
import { Instance } from '@lumapps/instance/types';
import { Filters } from '@lumapps/lumx-filter-and-sort/components/Filters';
import { FILTER_TYPES } from '@lumapps/lumx-filter-and-sort/components/Filters/config';
import { BasicFilterOption } from '@lumapps/lumx-filter-and-sort/components/Filters/types';
import { InstanceFilter } from '@lumapps/lumx-filter-and-sort/components/InstanceFilter';
import { useListFilter } from '@lumapps/lumx-filter-and-sort/hooks/useListFilter';
import { useTranslate, GLOBAL } from '@lumapps/translations';

export interface CommunityPickerDialogFiltersProps {
    /** callback to be executed once filters have changed */
    setFilters: (filters: Record<string, any>) => void;
    /** ids of the filters that should be enabled */
    enabledFilters?: string[];
    scopes?: CommunitySearchScopes[];
}

const KEY_FOR_SEARCH_SCOPES: Record<CommunitySearchScopes, string> = {
    [CommunitySearchScopes.FOLLOWING]: COMMUNITIES.SEARCH_SCOPE_FOLLOWING,
};

/**
 * Filters to be displayed on the Community Picker
 *
 * @family Filters
 * @param CommunityPickerFiltersProps
 * @returns CommunityPickerFilters
 */
export const CommunityPickerDialogFilters: React.FC<CommunityPickerDialogFiltersProps> = ({
    setFilters,
    enabledFilters,
    scopes,
}) => {
    const { translateKey, translate } = useTranslate();
    const defaultSelected = React.useMemo(() => {
        return scopes?.map((scope: CommunitySearchScopes) => ({
            id: scope,
            text: translateKey(KEY_FOR_SEARCH_SCOPES[scope]),
        }));
    }, [scopes, translateKey]);

    const filters = [
        {
            id: 'scope',
            type: FILTER_TYPES.CHECKBOX,
            renderSelected: (scope: BasicFilterOption) => scope.text,
            config: {
                options: [
                    {
                        id: CommunitySearchScopes.FOLLOWING,
                        text: translateKey(KEY_FOR_SEARCH_SCOPES[CommunitySearchScopes.FOLLOWING]),
                    },
                ],
                selectFieldSimpleProps: {
                    getValueName: (scope: BasicFilterOption) => scope.text,
                    getValueId: (scope: BasicFilterOption) => scope.id,
                },
                renderCheckbox: (scope: BasicFilterOption) => scope.text,
                hideTitle: true,
            },
            defaults: {
                defaultSelected,
                getId: (scope: BasicFilterOption) => scope.id,
            },
        },
        {
            id: 'privacy',
            type: FILTER_TYPES.SINGLE_SELECT,
            renderSelected: (privacy: BasicFilterOption) => privacy.text,
            config: {
                options: [
                    {
                        id: CommunityPrivacy.descriptionOnly,
                        text: translateKey(getCommunityPrivacy(CommunityPrivacy.descriptionOnly)),
                    },
                    {
                        id: CommunityPrivacy.open,
                        text: translateKey(getCommunityPrivacy(CommunityPrivacy.open)),
                    },
                    {
                        id: CommunityPrivacy.readOnly,
                        text: translateKey(getCommunityPrivacy(CommunityPrivacy.readOnly)),
                    },
                    {
                        id: CommunityPrivacy.restricted,
                        text: translateKey(getCommunityPrivacy(CommunityPrivacy.restricted)),
                    },
                ],
                selectFieldSimpleProps: {
                    getValueName: (privacy: BasicFilterOption) => privacy.text,
                    getValueId: (privacy: BasicFilterOption) => privacy.id,
                },
                title: translateKey(GLOBAL.VISIBILITY),
            },
        },
        {
            id: 'instances',
            type: 'INSTANCES',
            renderSelected: (instance: Instance) => translate(instance.title) || instance.name,
        },
    ];

    return (
        <Filters
            filters={enabledFilters ? filters.filter((filter) => enabledFilters.indexOf(filter.id) >= 0) : filters}
            filterDropdownProps={{
                /**
                 * There is not a lot of room on the community picker, so we only limit to 1 the
                 * total amount of filters displayed
                 */
                totalSelectedDisplayedFilters: 1,
                truncateLabel: true,
                filterProps: {
                    children: translateKey(GLOBAL.APPLY),
                },
            }}
            onFilter={setFilters}
            customFilters={{
                INSTANCES: {
                    Component: InstanceFilter,
                    hook: useListFilter,
                },
            }}
        />
    );
};
