import { useCallback, useEffect, useMemo, useState } from 'react';

import camelCase from 'lodash/fp/camelCase';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import getOr from 'lodash/fp/getOr';
import isEqual from 'lodash/fp/isEqual';
import isUndefined from 'lodash/fp/isUndefined';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import partition from 'lodash/fp/partition';
import property from 'lodash/fp/property';
import zipObject from 'lodash/fp/zipObject';

import evolve from '@lumapps/utils/function/evolve';
import { ContainerFilter, FilterComponentProps } from '@lumapps/widget-base/types';

import { FilterId, PostListAvailableFilter, PostListFilterType } from '../../types';
import { useUpdatePostListURLFilters } from './useUpdatePostListURLFilters';

interface UsePostListFiltersProps {
    activeFiltersValues: Partial<PostListFilterType> | undefined;
    onApply: FilterComponentProps['onApply'];
    onFilterChange: FilterComponentProps['onFilterChange'];
    onReset: FilterComponentProps['onReset'];
    rawAvailableFilters: ContainerFilter[] | undefined;
}

export interface UsePostListFiltersReturnType {
    availableFilters: PostListAvailableFilter[];
    filtersValues?: Partial<PostListFilterType>;
    handleApply(filterIds: FilterId[]): void;
    handleCancel(filterIds: FilterId[]): void;
    handleReset(filterIds: FilterId[]): void;
    handleSearch(query: string): void;
    handleValuesChange(filterId: FilterId, values: PostListFilterType[FilterId]): void;
}

export function usePostListFilters(props: UsePostListFiltersProps): UsePostListFiltersReturnType {
    const { activeFiltersValues, onApply, onFilterChange, onReset, rawAvailableFilters } = props;

    // Update URL everytime active filters change /////////////////////////////////////////////////
    useUpdatePostListURLFilters(activeFiltersValues);

    // Available filters and prefiltered values ///////////////////////////////////////////////////
    const availableFilters = useMemo(() => {
        if (!rawAvailableFilters) {
            return [];
        }

        // this places the search query always first and changes the type property to camelCase
        return flow(
            map(evolve({ type: camelCase })),
            partition(flow(property('type'), isEqual('searchQuery'))),
            flatten,
        )(rawAvailableFilters);
    }, [rawAvailableFilters]) as PostListAvailableFilter[];

    // Local filters and active filters management ////////////////////////////////////////////////
    const [filtersValues, setFiltersValues] = useState(activeFiltersValues);

    useEffect(() => {
        setFiltersValues(activeFiltersValues);
    }, [activeFiltersValues]);

    const setFilterValues = useCallback(
        (filterId: FilterId, values: PostListFilterType[FilterId]) => {
            setFiltersValues({
                ...filtersValues,
                [filterId]: values,
            });
        },
        [filtersValues],
    );

    // Handlers ///////////////////////////////////////////////////////////////////////////////////
    const handleApply = useCallback(
        (filterIds: FilterId[]) => {
            const newFilters = zipObject(
                filterIds,
                map((filterId) => getOr([], filterId, filtersValues), filterIds),
            );
            onFilterChange(newFilters);
            onApply();
        },
        [filtersValues, onApply, onFilterChange],
    );

    const handleCancel = useCallback(
        (filterIds: FilterId[]) => {
            const previousFiltersValues = zipObject(
                filterIds,
                map((filterId: string) => property(filterId, activeFiltersValues), filterIds),
            );

            const nextFiltersValues = omitBy(isUndefined, {
                ...filtersValues,
                ...previousFiltersValues,
            });

            setFiltersValues(nextFiltersValues);
        },
        [activeFiltersValues, filtersValues],
    );

    const handleReset = useCallback(
        (filterIds: FilterId[]) => {
            setFiltersValues(omit(filterIds, filtersValues));

            onReset(filterIds);
        },
        [filtersValues, onReset],
    );

    const handleSearch = useCallback(
        (query: string) => {
            setFilterValues('searchQuery', query);

            onFilterChange({ searchQuery: query });
            onApply();
        },
        [onApply, onFilterChange, setFilterValues],
    );

    return {
        availableFilters,
        filtersValues,
        handleApply,
        handleCancel,
        handleReset,
        handleSearch,
        handleValuesChange: setFilterValues,
    };
}
