/* istanbul ignore file */
import first from 'lodash/first';

import BaseApi, { ServerListResponse } from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { MAX_DIRECTORY_ENTRIES, ENTRIES_FIELDS } from '../constants';
import { DirectoryEndpointQuery, DirectoryEntry } from '../types';

export const directoryApi = new BaseApi({
    path: 'directory',
});

export const getEntries = ({
    fields = ENTRIES_FIELDS,
    maxResults = MAX_DIRECTORY_ENTRIES,
    sortOrder = 'name',
    ...params
}: DirectoryEndpointQuery) => {
    return directoryApi.post<ServerListResponse<DirectoryEntry>>('entry/list', {
        fields,
        maxResults,
        sortOrder,
        ...params,
    });
};

export const getFavoriteEntries = async ({ id, language }: { id: string; language: string }) => {
    const response = await directoryApi.postCacheFirst<ServerListResponse<DirectoryEntry>>(
        'entry/list',
        {
            directory: id,
            fields: ENTRIES_FIELDS,
            lang: language,
            maxResults: MAX_DIRECTORY_ENTRIES,
            more: true,
            sortOrder: 'name',
            userFavoritesOnly: true,
        },
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
    );

    return response.data;
};

export const saveEntry = (entry: Partial<DirectoryEntry>) => {
    return directoryApi.post<DirectoryEntry>('entry/save', entry);
};

export const deleteMultipleEntries = (uid: string[]) => {
    return directoryApi.delete<DirectoryEntry>('entry/deleteMulti', { params: { uid } });
};

// TODO: [BRIDGE-550 - https://lumapps.atlassian.net/browse/BRIDGE-550] - implement the new GET endpoint
export const getUserEntry = async (uid: string) => {
    const {
        data: { items },
    } = await directoryApi.post<ServerListResponse<DirectoryEntry>>('entry/list', {
        ids: [uid],
    });

    return first(items);
};
export const saveUserEntry = async (entry: Partial<DirectoryEntry>) => {
    return directoryApi.post<DirectoryEntry>('entry/user/save', entry);
};
export const deleteUserEntry = async (uid: string) => {
    return directoryApi.delete<DirectoryEntry>('entry/user/delete', { params: { uid } });
};
