import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const mobileLoginHelperRoutes: Route = {
    path: '/admin/mobile-login-helper',
    legacyId: 'app.admin.mobile-login-helper',
    appId: AppId.backOffice,
};

const mobileLoginQrCodeRoutes: Route = {
    path: '/admin/mobile-login-qr-code',
    legacyId: 'app.admin.mobile-login-qr-code',
    appId: AppId.backOffice,
};
const adminMobileLoginHelper = (): Route => {
    return mobileLoginHelperRoutes;
};
const adminMobileLoginQrCode = (): Route => {
    return mobileLoginQrCodeRoutes;
};

export { adminMobileLoginHelper, mobileLoginHelperRoutes, adminMobileLoginQrCode, mobileLoginQrCodeRoutes };
