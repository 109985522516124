import React from 'react';

import { DisplayTrainings } from '@lumapps/learning-trainings/components/DisplayTrainings';
import { Training as TrainingType, DisplayTrainingType } from '@lumapps/learning-trainings/types';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { TrainingCoursesInProgressProps } from '../../types';

export const ClientComputedTrainingCoursesInProgress: React.FC<TrainingCoursesInProgressProps> = ({
    uuid,
    properties,
    theme = Theme.light,
}) => {
    const dispatch = useDispatch();
    const LearningEnabled = useSelector(isLearningEnabled);

    const onLoad = (data: TrainingType[] | undefined) => {
        if (!data) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    };
    if (!LearningEnabled) {
        return null;
    }
    return (
        <DisplayTrainings
            displayTrainingType={DisplayTrainingType.in_progress}
            viewModeVariant={properties?.viewModeVariant}
            onLoad={onLoad}
            theme={theme}
        />
    );
};
