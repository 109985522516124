import { isSpacesFeatureEnabled as isSpacesFeatureEnabledSelector } from '@lumapps/communities/ducks/selectors';
import { webviewSpaceHomepageViewRoute } from '@lumapps/community-webview/routes';
import { get as getConstants } from '@lumapps/constants';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useRouteMatching, useRouteMatch } from '@lumapps/router';

import { isSpaceEditableByUser, selectIsMemberOfSpace } from '../ducks/selectors';
import { spaceViewRoute } from '../routes';
import { SpaceRouteParameters } from '../types';

export interface UseSpaceApi extends Partial<SpaceRouteParameters> {
    /** Does the route match the Space one */
    isSpace: boolean;
    /** The Spaces feature is enabled */
    isSpaceFeatureEnabled: boolean;
    /** Can the user edit the Space */
    canEdit: boolean;
    /** Can the user contribute to the Space */
    canContribute: boolean;
    /** Does the route match the Legacy Space one */
    isLegacySpace: boolean;
    legacySpaceId: string | undefined;
}

/**
 * Custom hook used to get the default attributes for a Space.
 * Can be used both inside or outside the router context.
 * */
export const useSpace = (): UseSpaceApi => {
    const isSpaceFeatureEnabled = useSelector(isSpacesFeatureEnabledSelector);
    const isSpace = useRouteMatching(spaceViewRoute);
    const isSpaceInWebview = useRouteMatching(webviewSpaceHomepageViewRoute);
    const { isLegacyContext } = getConstants();

    /**
     * We are using RouteMatch rather than useParams to get the URL params
     * since useParams does not work outside the router
     * and this hook will be called in other part of the APP (header for instance)
     * */
    const match = useRouteMatch<SpaceRouteParameters>(spaceViewRoute);
    const canEdit = useSelector(isSpaceEditableByUser);
    const canContribute = useSelector(selectIsMemberOfSpace);

    const content = useSelector(contentSelector);
    const isLegacySpace = isLegacyContext && content.type === 'community';
    const legacySpaceId = isLegacySpace ? content.id : undefined;
    return {
        isSpace: isSpace || isSpaceInWebview,
        isSpaceFeatureEnabled,
        isLegacySpace,
        legacySpaceId,
        canEdit,
        canContribute,
        ...match?.params,
    };
};
