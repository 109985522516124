import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Skeleton } from '@lumapps/play-player';
import { THUMBNAIL_LARGE } from '@lumapps/play/constants';
import {
    useFetchUniqueVideo,
    CLIENT_COMPUTED_PLAY_FIELDS,
} from '@lumapps/play/hooks/useFetchUniqueVideo/useFetchUniqueVideo';
import { useDispatch } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { ImportOnVisibility } from '@lumapps/utils/components/ImportOnVisibility';
import { actions } from '@lumapps/widget-base/ducks/slice';
import type { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { WIDGET_PLAY } from '../../keys';
import { AllVideoMetadata, type WidgetPlayPropertiesType, type VisibleMetadata } from '../../types';

import './index.scss';

const BlockPlay = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'block-play' */
            '../BlockPlay'
        ),
);

export interface ClientComputedPlayProps extends ClientComputedProps {
    properties?: WidgetPlayPropertiesType;
}

const CLASSNAME = 'widget-play';

export const ClientComputedPlay: React.FC<ClientComputedPlayProps> = ({ properties, uuid }) => {
    const { element } = useClassnames(CLASSNAME);
    const dispatch = useDispatch();
    const { translateKey } = useTranslate();

    /**
     * '|| properties?.videoId' can be removed when we are sure that all widgets
     * have been reverted back to video.id
     */
    const videoId = properties?.video?.id || properties?.videoId;

    /**
     * If the widget was created before the metadata selection was shipped, we display all metadata on the widget.
     * Else, we simply read the widget settings to display only the needed metadata
     */
    const visibleMetadata = properties?.order || (Object.keys(AllVideoMetadata) as VisibleMetadata);

    const { video, videoError, videoLoading } = useFetchUniqueVideo(
        {
            videoId,
            fields: CLIENT_COMPUTED_PLAY_FIELDS,
        },
        { thumbnailSizes: THUMBNAIL_LARGE },
    );

    const isEmptyWidget = !videoId && !videoError;

    React.useEffect(() => {
        if (isEmptyWidget) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, uuid, isEmptyWidget]);

    if (isEmptyWidget) {
        return null;
    }

    return (
        <div className={CLASSNAME}>
            {videoError ? (
                <div className={element('error')}>
                    <p>{translateKey(WIDGET_PLAY.ERROR_WHILE_FETCHING_DATA)}</p>
                </div>
            ) : (
                <ImportOnVisibility loadingFallback={<Skeleton />}>
                    <BlockPlay
                        isLoading={videoLoading}
                        theme={properties?.style?.content?.theme}
                        type="BlockPlay"
                        video={video}
                        visibleMetadata={visibleMetadata}
                    />
                </ImportOnVisibility>
            )}
        </div>
    );
};
