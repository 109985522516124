import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { Icon, IconProps, Text, Tooltip } from '@lumapps/lumx/react';
import { useRovingTabIndex } from '@lumapps/moving-focus';
import { calculateThemeColor } from '@lumapps/utils/css/calculateThemeColor';
import { useOverflowTooltipLabel } from '@lumapps/utils/hooks/useOverflowTooltipLabel';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { useDisableBlurWhileActivating } from '../../../hooks/useDisableBlurWhileActivating';
import { BASE_CLASSNAME } from '../constants';
import { MenuContext } from '../context';
import { BaseItemProps, ButtonProps, LinkProps } from './types';

export type MenuItemProps = BaseItemProps &
    (ButtonProps | LinkProps) & {
        color?: IconProps['color'];
    };

export const CLASSNAME = `${BASE_CLASSNAME}-item`;

/**
 * Menu item (ARIA `menuitem`).
 *
 * To use inside a `Menu` or `Menubar`.
 *
 * @family Menus
 */
export const MenuItem = React.forwardRef<HTMLElement, MenuItemProps>((props, ref) => {
    const {
        className,
        children,
        before,
        after,
        afterItem,
        icon,
        as,
        onKeyDown,
        onClick,
        onBlur,
        tooltipLabel,
        tooltipProps,
        'aria-haspopup': ariaHaspopup,
        'aria-busy': ariaBusy,
        'aria-checked': ariaChecked,
        'aria-expanded': ariaExpanded,
        'aria-disabled': ariaDisabled,
        isDisabled = ariaDisabled,
        role = 'menuitem',
        rovingTabIndexOptions,
        color,
        ...forwardedProps
    } = props;
    const { block, element } = useClassnames(CLASSNAME);
    const wrapperRef = React.useRef<HTMLButtonElement>(null);
    const [tabIndex, focused, rovingTabindexKeyDown] = useRovingTabIndex(
        wrapperRef,
        ariaDisabled ? false : !!isDisabled,
        rovingTabIndexOptions?.rowIndex,
    );

    const { closeRoot, menubarVariant } = React.useContext(MenuContext) || {};

    const disableBlurWhileActivating = useDisableBlurWhileActivating();

    const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(
        (event) => {
            rovingTabindexKeyDown(event);
            onKeyDown?.(event);
        },
        [rovingTabindexKeyDown, onKeyDown],
    );
    const handleClick = React.useCallback<React.MouseEventHandler>(
        (event) => {
            if (isDisabled || ariaDisabled) {
                return;
            }
            // Close root menu if menuitem and not a submenu menu item and not busy
            if (role === 'menuitem' && !ariaHaspopup && !ariaBusy && ariaBusy !== 'true') {
                closeRoot?.(true);
            }

            onClick?.(event);
            disableBlurWhileActivating.onClick(event);
        },
        [ariaBusy, ariaDisabled, ariaHaspopup, closeRoot, disableBlurWhileActivating, isDisabled, onClick, role],
    );

    const handleBlur = React.useCallback(
        (event: React.FocusEvent) => {
            disableBlurWhileActivating.onBlur(event);
            onBlur?.(event);
        },
        [disableBlurWhileActivating, onBlur],
    );

    // Handle focus on roving tabindex update
    React.useEffect(() => {
        const wrapper = wrapperRef.current;
        if (wrapper && focused) {
            setTimeout(() => wrapper.focus(), 10);
        }
    }, [focused, tabIndex]);

    // Render button or link or custom component
    let wrapperProps: any = {};
    let WrapperElement = as || 'button';
    if (props.href && !as) {
        WrapperElement = 'a';
    }
    if (WrapperElement === 'button') {
        wrapperProps = { type: 'button', disabled: isDisabled && !ariaDisabled };
    }

    // Adapt click event on touch device (because of compatibility issues on iOS <= 16)
    if ('ontouchend' in window) {
        wrapperProps.onTouchEnd = handleClick;
    } else {
        wrapperProps.onClick = handleClick;
    }

    // Compute a tooltip label based on label content if text is overflowing
    const { labelRef, tooltipLabel: tooltipLabelSmall } = useOverflowTooltipLabel();

    /*
     * MenuItem in a menubar uses LumX Button styles
     * MenuItem in a menu uses LumX ListItem styles
     *
     * We chosed to keep this two use-cases into a single component because,
     * other than style, they behave perfectly identically and we want to be able
     * to freely move MenuItem between menu and menubar.
     *
     * Here we are using the LumX classes directly because the component mixins
     * would not work correctly on some states (aria expanded, aria checked, etc.)
     */
    let lumxClasses: string | undefined;
    let lumxWrapperClasses: string | undefined;
    let lumxBeforeClasses: string | undefined;
    let lumxContentClasses: string | undefined;
    let lumxAfterClasses: string | undefined;
    if (menubarVariant) {
        // Use LumX Button classes
        const { size = 'm', theme = 'light' } = menubarVariant;
        lumxWrapperClasses = classnames(
            'lumx-button lumx-button--emphasis-low lumx-button--variant-button',
            isDisabled && 'lumx-button--is-disabled',
            ariaChecked && 'lumx-button--is-selected',
            ariaExpanded && 'lumx-button--is-hovered',
            `lumx-button--size-${size}`,
            `lumx-button--color-${calculateThemeColor(theme)}`,
        );
    } else {
        // Use LumX ListItem classes
        lumxClasses = 'lumx-list-item lumx-list-item--size-tiny';
        lumxWrapperClasses = classnames(
            'lumx-list-item__link',
            isDisabled && 'lumx-list-item__link--is-disabled',
            ariaChecked && 'lumx-list-item__link--is-selected',
            ariaExpanded && 'lumx-list-item__link--is-highlighted',
        );
        lumxBeforeClasses = 'lumx-list-item__before';
        lumxContentClasses = 'lumx-list-item__content';
        lumxAfterClasses = 'lumx-list-item__after';
    }

    return (
        <li role="none" className={block(undefined, [className, lumxClasses])}>
            <Tooltip label={tooltipLabel || tooltipLabelSmall} {...tooltipProps}>
                <WrapperElement
                    ref={mergeRefs([ref, wrapperRef])}
                    role={role}
                    aria-haspopup={ariaHaspopup}
                    aria-busy={ariaBusy}
                    aria-disabled={isDisabled}
                    aria-checked={ariaChecked}
                    aria-expanded={ariaExpanded}
                    tabIndex={tabIndex}
                    className={element('wrapper', undefined, lumxWrapperClasses)}
                    {...forwardedProps}
                    {...wrapperProps}
                    onKeyDown={handleKeyDown}
                    onMouseDown={disableBlurWhileActivating.onMouseDown}
                    onBlur={handleBlur}
                >
                    {(icon || before) && (
                        <Text as="span" className={element('before', undefined, lumxBeforeClasses)}>
                            {icon && <Icon className={element('before-icon')} icon={icon} size="xs" color={color} />}
                            {before}
                        </Text>
                    )}
                    {children && (
                        <Text
                            ref={labelRef}
                            as="span"
                            truncate
                            className={element('content', undefined, lumxContentClasses)}
                            color={color}
                        >
                            {children}
                        </Text>
                    )}
                    {after && (
                        <Text as="span" className={element('after', undefined, lumxAfterClasses)}>
                            {after}
                        </Text>
                    )}
                </WrapperElement>
            </Tooltip>
            {afterItem}
        </li>
    );
});

MenuItem.displayName = 'MenuItem';
