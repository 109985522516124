import find from 'lodash/find';
import get from 'lodash/get';

import { get as getInstance } from '@lumapps/instance/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { service } from '@lumapps/user/service';
import { getFieldDataFromMap } from '@lumapps/user/utils/apiProfile';

const { getConnected } = service;

const userDirectorySelector = (state: any) => state.userDirectory;

const userListSettingsDirectoryListSelector = createSelector(userDirectorySelector, (userDirectory) =>
    userDirectory.userListSettings.ids.map((id: string) => userDirectory.entities[id]),
);

const userListSettingsStateSelector = createSelector(
    userDirectorySelector,
    (userDirectory) => userDirectory.userListSettings,
);

const hasMoreSelector = createSelector(userDirectorySelector, (userDirectory) => userDirectory.hasMore);

const userDirectoriesSelector = (state: any) => state.userDirectories;

const getDefaultUserDirectory = createSelector(
    [getInstance, userDirectoriesSelector],
    (instance: any, userDirectories) => {
        const defaultId = instance.defaultUserDirectory;
        return find(userDirectories, (ud) => ud.id === defaultId);
    },
);

const getDefaultUserDirectoryAttributes = createSelector(
    [getDefaultUserDirectory, getConnected],
    (userDirectory, user) => {
        const attrs: Record<string, any> = {};
        if (userDirectory) {
            const components = get(userDirectory, 'template.components', []);
            components.forEach((component: any) => {
                const code = get(component, 'properties.analyticsCode');
                if (code) {
                    const path = get(component.properties, 'boundMap.text', component.uuid);
                    attrs[`lumapps_user_dir_${code}`] = getFieldDataFromMap(path, user as any);
                }
            });
        }

        return attrs;
    },
);

export {
    hasMoreSelector,
    getDefaultUserDirectory,
    getDefaultUserDirectoryAttributes,
    userListSettingsDirectoryListSelector,
    userListSettingsStateSelector,
};
