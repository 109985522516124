import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiSparkles } from '@lumapps/lumx/icons';
import { Button, Emphasis, Theme } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { STRUCTURED_CONTENT_WREX_EDITOR_CLASSNAME } from '@lumapps/structured-content/constants';
import { useTranslate } from '@lumapps/translations';
import { BLOCK_STRUCTURED_CONTENT_CLASSNAME, WIDGET_CONTRIBUTION_TYPE } from '@lumapps/widget-contribution/constants';
import {
    LEGACY_BLOCK_HTML_CLASSNAME,
    LEGACY_EDITION_HTML_CLASSNAME,
    WIDGET_HTML_TYPE,
} from '@lumapps/widget-html/constants';
import {
    LEGACY_BLOCK_INTRO_CLASSNAME,
    LEGACY_EDITION_INTRO_CLASSNAME,
    WIDGET_INTRO_TYPE,
} from '@lumapps/widget-intro/constants';
import type { Layout } from '@lumapps/widget-layout/types';
import { hasOneOfWidgetTypesInContent } from '@lumapps/widget-layout/utils/hasOneOfWidgetTypesInContent';

import { CLASSNAME } from '../../constants';
import { WIDGET_SUMMARY } from '../../keys';

export interface ButtonGenerateProps {
    /** Callback called on generate button click */
    onGenerate: (prompt: string) => Promise<void>;
    /** Whether a text has already been generated before */
    hasTextBeenGenerated: boolean;
    /** Whether a generation is ongoing */
    isGeneratingSummary: boolean;
    theme?: Theme;
}

/**
 * Button for generate summary with AI
 * @param ButtonGenerateProps
 * @returns ButtonGenerate
 */
export const ButtonGenerate: React.FC<ButtonGenerateProps> = ({
    onGenerate,
    hasTextBeenGenerated,
    isGeneratingSummary,
    theme = Theme.light,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(CLASSNAME);
    const currentContent = useSelector(contentSelector);

    const { info: infoNotification } = useNotification();

    const textualWidgetsElements = Array.from(
        document.querySelectorAll(
            `.${LEGACY_BLOCK_HTML_CLASSNAME}, .${LEGACY_EDITION_HTML_CLASSNAME} .fr-element, .${BLOCK_STRUCTURED_CONTENT_CLASSNAME}, .${STRUCTURED_CONTENT_WREX_EDITOR_CLASSNAME}, .${LEGACY_BLOCK_INTRO_CLASSNAME}, .${LEGACY_EDITION_INTRO_CLASSNAME} .fr-element`,
        ),
    );

    const areTextualWidgetInContent = React.useMemo(() => {
        const contentTemplate = currentContent.template as Layout;

        if (contentTemplate) {
            return hasOneOfWidgetTypesInContent(
                [WIDGET_HTML_TYPE, WIDGET_INTRO_TYPE, WIDGET_CONTRIBUTION_TYPE],
                contentTemplate,
            );
        }

        return false;
    }, [currentContent.template]);

    const textualWidgetsContent = React.useMemo(() => {
        let textualContent = '';

        textualWidgetsElements.forEach((element) => {
            // Check for widget rich text placeholder presence
            const isDisplayingPlaceholder = !!element.querySelector('[data-slate-placeholder="true"]');

            if (!isDisplayingPlaceholder && element.textContent) {
                textualContent = textualContent.concat(' ', element.textContent);
            }
        });
        return textualContent;
    }, [textualWidgetsElements]);

    const handleGenerate = () => {
        if (!areTextualWidgetInContent || isEmpty(textualWidgetsContent) || isEmpty(textualWidgetsContent.trim())) {
            infoNotification({ content: translateKey(WIDGET_SUMMARY.NO_TEXTUAL_WIDGET_INFO) });
        } else {
            onGenerate(textualWidgetsContent);
        }
    };

    return (
        <Button
            leftIcon={mdiSparkles}
            emphasis={Emphasis.low}
            onClick={handleGenerate}
            isDisabled={isGeneratingSummary}
            theme={theme}
            {...get({ element: 'button', action: 'generate-ai-summary' })}
        >
            {hasTextBeenGenerated
                ? translateKey(WIDGET_SUMMARY.REGENERATE_SUMMARY)
                : translateKey(WIDGET_SUMMARY.GENERATE_SUMMARY)}
        </Button>
    );
};
