import React, { RefObject } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import { Size, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WREX_GENERATED_CONTENT } from '../../keys';
import { GenerativeContentButton } from '../GenerativeContentButton/GenerativeContentButton';
import { RefineContentButton } from '../RefineContentButton/RefineContentButton';

import './index.scss';

const CLASSNAME = 'generative-ai-toolbar';

export interface GenerativeAIToolbarProps {
    theme: Theme;
    parentDialogRef?: RefObject<HTMLDivElement>;
}

export const GenerativeAIToolbar: React.FC<GenerativeAIToolbarProps> = ({ theme, parentDialogRef }) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    return (
        <div className={block()}>
            <Menubar
                ariaLabel={translateKey(WREX_GENERATED_CONTENT.AI_EDITOR_TOOLBAR_LABEL)}
                size={Size.s}
                className={element('menu')}
            >
                <RefineContentButton theme={theme} />

                <GenerativeContentButton
                    theme={theme}
                    className="generative-content-button"
                    parentDialogRef={parentDialogRef}
                />
            </Menubar>
        </div>
    );
};
