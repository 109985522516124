import React from 'react';

import { SkeletonWrapper } from '@lumapps/a11y/components/SkeletonWrapper';
import { classnames } from '@lumapps/classnames';
import { ProgressCircular } from '@lumapps/lumx/react';

import { BookmarkType } from '../../types';
import { Bookmark } from './Bookmark';

import './index.scss';

export interface BookmarksProps {
    /** className to be added to the wrapping div */
    className?: string;
    /** The list of bookmarks */
    bookmarksList?: BookmarkType[];
}

const CLASSNAME = 'bookmarks';

/**
 * This component displays the a list of links with their icons.
 */
export const Bookmarks: React.FC<BookmarksProps> = ({ className = '', bookmarksList }) => {
    const bookmarksClassname = classnames(CLASSNAME, className);

    if (!bookmarksList) {
        return (
            <div className={`${bookmarksClassname} loading`}>
                <SkeletonWrapper>
                    <ProgressCircular />
                </SkeletonWrapper>
            </div>
        );
    }

    return (
        <ul className={bookmarksClassname}>
            {bookmarksList.map((bookmark, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <Bookmark key={key} item={bookmark} />
            ))}
        </ul>
    );
};
