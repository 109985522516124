import get from 'lodash/get';

/////////////////////////////

function WidgetBreadcrumbController($location, $state, Content, Customer, Instance, MainNav, Translation) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.MainNav = MainNav;
    vm.Translation = Translation;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get back to the parent content.
     */
    function back() {
        const breadcrumb = Content.getBreadcrumb();
        const parentContent = breadcrumb[breadcrumb.length - 2];

        if (angular.isDefinedAndFilled(get(parentContent, 'slugFull'))) {
            $state.go('app.front.content-get', {
                slug: Translation.translate(parentContent.slugFull),
            });
        }
    }

    /**
     * Get the widget's CSS classes.
     *
     * @return {Array} The widget's CSS classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        return widgetClass;
    }

    /**
     * Go to the home page.
     */
    function goToHome() {
        let path = '';

        if ($location.path().indexOf('/a/') === 0) {
            path = `/a/${Customer.getCustomerSlug()}`;
        }

        path += `/${Instance.getInstance().slug}`;

        $location.path(path);
    }

    /**
     * Check if the widget is empty.
     * The widget is empty if the menu is not loading and there is no element in the breadcrumbs.
     *
     * Used by the designer mode.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        return !MainNav.isCallInProgress() && angular.isUndefinedOrEmpty(Content.getBreadcrumb());
    }

    /**
     * Check if the widget is hidden.
     * The widget is hidden if we are in read mode, the menu is not loading and there is no element in the
     * breadcrumb.
     *
     * Used by the read mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        if (
            !vm.parentCtrl.designerMode() &&
            !vm.widget.properties.noResults &&
            !MainNav.isCallInProgress() &&
            angular.isUndefinedOrEmpty(Content.getBreadcrumb())
        ) {
            vm.parentCtrl.isHidden = true;
        } else {
            vm.parentCtrl.isHidden = false;
        }

        return vm.parentCtrl.isHidden;
    }

    /////////////////////////////

    vm.back = back;
    vm.getWidgetClass = getWidgetClass;
    vm.goToHome = goToHome;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;

    /////////////////////////////

    /**
     * Set parent controller.
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };
}

/////////////////////////////

/**
 * The breadcrumb widget.
 * Displays a breadcrumb.
 *
 * @param  {Object} widget The widget configuration object.
 * @return {Widget} The widget.
 */
function widgetBreadcrumbDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc
    function WidgetBreadcrumbLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetBreadcrumbController,
        controllerAs: 'vm',
        link: WidgetBreadcrumbLink,
        replace: true,
        require: ['widgetBreadcrumb', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-breadcrumb/views/widget-breadcrumb.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetBreadcrumb', widgetBreadcrumbDirective);

/////////////////////////////

export { widgetBreadcrumbDirective };
