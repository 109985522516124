export enum SEARCH_SETTINGS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616532
     */
    TITLE = 'SEARCH_SETTINGS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616534
     */
    COVEO_CONNECTION_TITLE = 'ADMIN.SEARCH_SETTINS.COVEO_CONNECTION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346401729
     */
    COVEO_ANALYTICS_TITLE = 'ADMIN.SEARCH_SETTINGS.COVEO_ANALYTICS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346401748
     */
    COVEO_ANALYTICS_DESCRIPTION = 'ADMIN.SEARCH_SETTINGS.COVEO_ANALYTICS_DESCRIPTION',
    /**
     *https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398394048
     */
    COVEO_ANALYTICS_FIELD_NAME = 'ADMIN.SEARCH_SETTINGS.COVEO_ANALYTICS_FIELD_NAME',
    /**
     *https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398394151
     */
    COVEO_ANALYTICS_FIELD_NAME_HELPER = 'ADMIN.SEARCH_SETTINGS.COVEO_ANALYTICS_FIELD_NAME_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346401757
     */
    USER_PROFILE_FIELDS_LABEL = 'ADMIN.SEARCH_SETTINGS.USER_PROFILE_FIELDS_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346404113
     */
    USER_PROFILE_FIELDS_HELPER = 'ADMIN.SEARCH_SETTINGS.USER_PROFILE_FIELDS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616540
     */
    FILTERS_TITLE = 'ADMIN.SEARCH_SETTINS.FILTERS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616560
     */
    NO_FILTERS = 'ADMIN.SEARCH_SETTINGS.NO_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616574
     */
    ADD_FILTER = 'ADMIN.SEARCH_SETTINGS.ADD_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616590
     */
    CONNECTION_SUBMIT = 'ADMIN.SEARCH_SETTINGS.CONNECTION_SUBMIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248916125
     */
    CONNECTION_ERROR = 'ADMIN.SEARCH_SETTINGS.CONNECTION_COVEO_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519672
     */
    ADD_FILTER_TITLE = 'ADMIN.SEARCH_SETTINGS.ADD_FILTER_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519673
     */
    FILTER_NAME = 'ADMIN.SEARCH_SETTINGS.FILTER_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519674
     */
    FILTER_NAME_PLACEHOLDER = 'ADMIN.SEARCH_SETTINGS.FILTER_NAME_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519676
     */
    FIELD = 'ADMIN.SEARCH_SETTINGS.FIELD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519677
     */
    FIELD_PLACEHOLDER = 'ADMIN.SEARCH_SETTINGS.FIELD_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519678
     */
    FILTER_IS_COLLAPSED = 'ADMIN.SEARCH_SETTINGS.FILTER_IS_COLLAPSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98519679
     */
    FILTER_IS_MULTISELECT = 'ADMIN.SEARCH_SETTINGS.FILTER_IS_MULTISELECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98521859
     */
    DELETE_FILTER_CONFIRM_TITLE = 'ADMIN.SEARCH_SETTINGS.DELETE_FILTER_CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98521861
     */
    DELETE_FILTER_CONFIRM_DESCRIPTION = 'ADMIN.SEARCH_SETTINGS.DELETE_FILTER_CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98675848
     */
    GLOBAL_ERROR = 'ADMIN.SEARCH_SETTINGS.ERROR_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98675880
     */
    GLOBAL_ERROR_EXPLANATION = 'ADMIN.SEARCH_SETTINGS.ERROR_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=99671232
     */
    SAVE_FILTER_ERROR = 'ADMIN.SEARCH_SETTINGS.SAVE_FILTER_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102074967
     */
    FIELD_SEARCH_PLACEHOLDER = 'ADMIN.SEARCH_SETTINGS.FIELD_SEARCH_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103528407
     */
    FIELDS_MAPPING_DISCLAIMER = 'ADMIN.SEARCH_SETTINGS.FIELDS_MAPPING.DISCLAIMER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103555520
     */
    FIELD_MAPPING_SAVE_SUCCESS = 'ADMIN.SEARCH_SETTINGS.FIELDS_MAPPING.SAVE_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103573141
     */
    MAPPING_ERROR = 'ADMIN.SEARCH_SETTINGS.MAPPING_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103690219
     */
    PIN_FILTER = 'ADMIN.SEARCH_SETTINGS.PIN_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103690259
     */
    UNPIN_FILTER = 'ADMIN.SEARCH_SETTINGS.UNPIN_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104035593
     */
    FILTERS_TAB = 'ADMIN.SEARCH_SETTINGS.TAB_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104035622
     */
    CONNECTION_TAB = 'ADMIN.SEARCH_SETTINGS.TAB_CONNECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104035627
     */
    MAPPING_TAB = 'ADMIN.SEARCH_SETTINGS.TAB_FIELDS_MAPPING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=110440636
     */
    PREDEFINED_VALUES = 'SEARCH_SETTINGS.PREDEFINED_VALUES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=110440705
     */
    PREDEFINED_VALUES_HELPER = 'SEARCH_SETTINGS.PREDEFINED_VALUES_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=110440767
     */
    PREDEFINED_VALUES_EXAMPLES = 'SEARCH_SETTINGS.PREDEFINED_VALUES_EXAMPLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134186978
     */
    SOURCE_CONFIGURATIONS = 'SEARCH_SETTINGS.SOURCE_CONFIGURATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134186987
     */
    SOURCE_CONFIGURATIONS_EXPLANATION = 'SEARCH_SETTINGS.SOURCE_CONFIGURATIONS_EXPLANATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134187002
     */
    SEE_CONFIG = 'SEARCH_SETTINGS.SEE_CONFIG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134187015
     */
    CONFIG_IS_APPLIED = 'SEARCH_SETTINGS.CONFIG_IS_APPLIED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134187038
     */
    DEFINE_CONFIG = 'SEARCH_SETTINGS.DEFINE_CONFIG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134187051
     */
    APPLY_CONFIG_SHORT = 'SEARCH_SETTINGS.APPLY_CONFIG_SHORT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136222443
     */
    RESULTS_SETTINGS = 'SEARCH_SETTINGS.RESULTS_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136222487
     */
    COVEO_SETTINGS = 'SEARCH_SETTINGS.COVEO_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136222688
     */
    CLOUDSEARCH_SETTINGS = 'SEARCH_SETTINGS.CLOUDSEARCH_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136222988
     */
    DEFAULT_CONFIGURATION = 'SEARCH_SETTINGS.DEFAULT_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136223083
     */
    DEFAULT_CONFIGURATION_DESC = 'SEARCH_SETTINGS.DEFAULT_CONFIGURATION_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136223189
     */
    CUSTOM_CONFIGURATION = 'SEARCH_SETTINGS.CUSTOM_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136223197
     */
    CUSTOM_CONFIGURATION_DESC = 'SEARCH_SETTINGS.CUSTOM_CONFIGURATION_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136224029
     */
    ADD_RESULT_TYPE = 'SEARCH_SETTINGS.ADD_RESULT_TYPE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=137685538 */
    APPLY_CONFIGURATION = 'SEARCH_SETTINGS.APPLY_CONFIGURATION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=137685539 */
    APPLY_CONFIGURATION_WARNING = 'SEARCH_SETTINGS.APPLY_CONFIGURATION_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=137700221 */
    SAVE_CONFIGURATION = 'SEARCH_SETTINGS.SAVE_CONFIGURATION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=137703417 */
    SAVE_CONFIGURATION_WARNING = 'SEARCH_SETTINGS.SAVE_CONFIGURATION_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=138652453 */
    NATIVE_SEARCH = 'SEARCH_SETTINGS.NATIVE_SEARCH',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=139757869 */
    GCS_FALLBACK_WARNING = 'SEARCH_SETTINGS.GCS_FALLBACK_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=160147184 */
    EDIT_SOURCE_LABEL = 'SEARCH_SETTINGS.EDIT_SOURCE_LABEL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=160181358 */
    ORIGINAL_TAB_NAME = 'SEARCH_SETTINGS.ORIGINAL_TAB_NAME',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170788601 */
    ADD_EXTENSION = 'SEARCH_SETTINGS.ADD_EXTENSION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170781364 */
    EXTENSION_LABEL_WARNING = 'SEARCH_SETTINGS.EXTENSION_LABEL_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210381669 */
    FIRST_TAB = 'SEARCH_SETTINGS.FIRST_TAB_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210381999 */
    OTHER_TAB = 'SEARCH_SETTINGS.OTHER_TAB_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529004  */
    LUMAPPS_SOURCE = 'SEARCH_SETTINGS.LUMAPPS_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529006 */
    ZENDESK_SOURCE = 'SEARCH_SETTINGS.ZENDESK_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529019 */
    LUMWORK_SOURCE = 'SEARCH_SETTINGS.LUMWORK_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529044 */
    DRIVE_SOURCE = 'SEARCH_SETTINGS.DRIVE_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529049 */
    SHAREPOINT_SOURCE = 'SEARCH_SETTINGS.SHAREPOINT_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529735 */
    MICROSOFT_SOURCE = 'SEARCH_SETTINGS.MICROSOFT_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529745 */
    EXTERNAL_SOURCE = 'SEARCH_SETTINGS.EXTERNAL_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210529754 */
    IFRAME_SOURCE = 'SEARCH_SETTINGS.IFRAME_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211312143 */
    EXTENSION_SOURCE = 'SEARCH_SETTINGS.EXTENSION_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=204192287 */
    INTERNAL_SOURCE_LABEL_WARNING = 'SEARCH_SETTINGS.INTERNAL_SOURCE_LABEL_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203758299 */
    EXTENSION_SOURCE_TITLE = 'SEARCH_SETTINS.EXTENSION_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203758333 */
    EXTENSION_SOURCE_DESC = 'SEARCH_SETTINS.EXTENSION_SOURCE_DESCRIPTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203758340 */
    LUMAPPS_SOURCE_TITLE = 'SEARCH_SETTINS.LUMAPPS_SOURCE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203758344 */
    LUMAPPS_SOURCE_DESC = 'SEARCH_SETTINS.LUMAPPS_SOURCE_DESCRIPTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203758353 */
    SELECT_LUMAPPS_SOURCE = 'SEARCH_SETTINS.SELECT_LUMAPPS_SOURCE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222979844 */
    HIDE_AUTHOR = 'SEARCH_SETTINGS.HIDE_AUTHOR_LABEL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222979879 */
    DISPLAY_SETTINGS_TITLE = 'SEARCH_SETTINGS.DISPLAY_SETTINGS_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222979922 */
    DISPLAY_SETTINGS_DESCRIPTION = 'SEARCH_SETTINGS.DISPLAY_SETTINGS_DESC',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247265738 */
    COVEO_SERVER_LOCATION_TITLE = 'ADMIN.SEARCH_SETTINGS.COVEO_SERVER_LOCATION_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247263871 */
    COVEO_SERVER_LOCATION_DESC = 'ADMIN.SEARCH_SETTINGS.COVEO_SERVER_LOCATION_DESC',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247272000 */
    COVEO_SERVER_LOCATION_US = 'ADMIN.SEARCH_SETTINGS.COVEO_SERVER_LOCATION_US',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247273376 */
    COVEO_SERVER_LOCATION_EU = 'ADMIN.SEARCH_SETTINGS.COVEO_SERVER_LOCATION_EU',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247273745 */
    COVEO_SERVER_LOCATION_AU = 'ADMIN.SEARCH_SETTINGS.COVEO_SERVER_LOCATION_AU',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247917151 */
    COVEO_SERVER_LOCATION_ERROR = 'ADMIN.SEARCH_SETTINGS.COVEO_SERVER_LOCATION_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=267757185
     */
    EDIT_EXTENSION = 'SEARCH_SETTINGS.EDIT_EXTENSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268339761
     */
    ADD_INTERNAL_SOURCE = 'SEARCH_SETTINGS.ADD_INTERNAL_SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268339759
     */
    EDIT_INTERNAL_SOURCE = 'SEARCH_SETTINGS.EDIT_INTERNAL_SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300537095
     */
    SOURCE_VISIBILITY_SECTION_TITLE = 'SEARCH_SETTINGS.SOURCE_VISIBILITY_SECTION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300537134
     */
    SOURCE_VISIBILITY_GROUP_PICKER_HELPER_CUSTOM = 'SEARCH_SETTINGS.SOURCE_VISIBILITY_GROUP_PICKER_HELPER_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300537134
     */
    SOURCE_VISIBILITY_GROUP_PICKER_HELPER_DEFAULT = 'SEARCH_SETTINGS.SOURCE_VISIBILITY_GROUP_PICKER_HELPER_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=353238909
     */
    CUSTOM_FIELD_USER_PROFILE_DESCRIPTION = 'SEARCH_SETTINGS.CUSTOM_FIELD_USER_PROFILE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=353239061
     */
    CUSTOM_FIELD_USER_PROFILE_SETTING_DESCRIPTION = 'SEARCH_SETTINGS.CUSTOM_FIELD_USER_PROFILE_SETTING_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=385572023
     */
    COVEO_ANALYTICS_DESCRIPTION_LABEL = 'SEARCH_SETTINGS.COVEO_ANALYTICS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=385578829
     */
    COVEO_USE_FIELD_ANALYTICS = 'SEARCH_SETTINGS.COVEO_ANALYTICS_FIELD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=415994860
     */
    USER_CONTEXT_TAB = 'SEARCH_SETTINGS.USER_CONTEXT_TAB',
}
