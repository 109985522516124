import { getContrastColor, shadeBlendColors } from '@lumapps/utils/color';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function BadgeController($scope, ConfigTheme, Style) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains the list of allowed shapes.
         *
         * @type {Array}
         */
        var _SHAPES = ['hexagon'];

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get a random color from the colors list.
         * The color is not really random, it's based on the length of the text or icon.
         *
         * @return {string} The color.
         */
        function _getRandomColor() {
            var hashCode = Math.abs((vm.text || vm.image || vm.icon || '').hashCode());

            return vm.colors[hashCode % vm.colors.length] || vm.colors[0];
        }

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When we want to redraw the badge.
         *
         * @param {Event}  evt     The badge redraw event.
         * @param {string} badgeId The id of the badge we want to redraw.
         *                         If none is given, all badges will be redrawn.
         */
        $scope.$on('badge-redraw', function onBadgeRedraw(evt, badgeId) {
            if (vm.id === badgeId || angular.isUndefinedOrEmpty(badgeId)) {
                vm.init();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            vm.allowCustomColor = angular.isUndefinedOrEmpty(vm.allowCustomColor) ? true : vm.allowCustomColor;

            vm.colors = vm.colors || _.get(Style.getCurrent('global'), 'properties.colors') || ConfigTheme.COLORS;
            vm.colors = _.without(vm.colors, 'transparent');

            vm.primaryColor = vm.backgroundColor || _getRandomColor();
            if (!vm.allowCustomColor && !_.includes(vm.colors, vm.primaryColor)) {
                vm.primaryColor = _getRandomColor();
            }

            vm.secondaryColor = vm.borderColor || shadeBlendColors(-0.2, vm.primaryColor);
            vm.thirdColor = vm.fontColor || getContrastColor(vm.primaryColor);

            vm.maxLength = angular.isUndefined(vm.maxLength) ? 1 : vm.maxLength;

            vm.size = angular.isDefinedAndFilled(vm.size) ? parseInt(vm.size, 10) : 140;
            vm.shape = _.includes(_SHAPES, vm.shape) ? vm.shape : _SHAPES[0];
        };

        vm.init();
    }

    /**
     * Badge directive.
     * Display a shaped badge with a background (primary) color, a border (secondary) color and an icon, a text or an
     * image inside of it.
     *
     * @param {boolean} [allowCustomColor=true]       Indicates if we want to allow a background color that is not in
     *                                                the (given or computed) color list.
     * @param {string}  [backgroundColor=<random>]    The color to use as the background.
     *                                                If no color is given, then a random color will be picked in the
     *                                                (given or computed) color list based on the lenght of the text (or
     *                                                the length of the icon name).
     *                                                Note that you can pass a color that doesn't belong to the (given
     *                                                or computed) color list if you pass the `allowCustomColor`
     *                                                parameter to `true`. Else, if the color cannot be found in the
     *                                                list, then a random one will be picked.
     * @param {string}  [borderColor=<darken>]        The color to use as the border of the shape.
     *                                                If no color is given, simply darken the primary color.
     * @param {Array}   [colors=<instance's palette>] The list of colors in which to pick the color (if none given).
     *                                                If no list is given, defaults to the instance's style color
     *                                                palette.
     * @param {string}  [fontColor=<contrast color>]  The color for the icon or the text.
     *                                                If none given, it will be either black or white according to the
     *                                                best contrast with the primary (background) color.
     * @param {string}  [icon]                        The icon to display in the badge.
     *                                                The `icon` will be displayed only if `image` is not given. If
     *                                                `image` and `icon` are both given, then `image` will have
     *                                                priority.
     * @param {string}  [id]                          A unique identifier for the badge.
     * @param {string}  [image]                       The image to display in the badge.
     *                                                The `image` will take precedence over the `icon` and the `text`.
     * @param {boolean} [imageOnly=false]             Indicates if we want to use the image as only badge.
     * @param {number}  [maxLength=1]                 When using `text`, define the number of letter of the text to show
     *                                                in the badge.
     *                                                By default, only show the first letter of the text.
     * @param {string}  [shape='hexagon']             The shape of the badge.
     *                                                Possible values are; 'hexagon'.
     * @param {number}  [size=140]                    The size (height and width) of the badge.
     * @param {string}  [text]                        The text to be displayed in the badge.
     *                                                In fact, only a part of this text will be displayed (based on the
     *                                                `maxLength` parameter).
     *                                                The `text` will be displayed only if `icon` and `image` are not
     *                                                given. If `image` ore `icon` are given with `text`, then the
     *                                                `image` or the `icon` will have the priority.
     */

    function BadgeDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: BadgeController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                allowCustomColor: '<?lsAllowCustomColor',
                backgroundColor: '@?lsBackgroundColor',
                borderColor: '@?lsBorderColor',
                colors: '<?lsColors',
                fontColor: '@?lsFontColor',
                icon: '@?lsIcon',
                id: '@?lsId',
                image: '@?lsImage',
                imageOnly: '<?lsImageOnly',
                maxLength: '<?lsMaxLength',
                shape: '@?shape',
                size: '<?lsSize',
                text: '@?lsText',
            },
            templateUrl: '/client/common/modules/badge/views/badge.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsBadge', BadgeDirective);
})();
