import React, { Fragment, useEffect } from 'react';

import isArray from 'lodash/isArray';
import trim from 'lodash/trim';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiPound } from '@lumapps/lumx/icons';
import {
    AutocompleteMultiple,
    AutocompleteMultipleProps,
    ChipGroup,
    ColorVariant,
    Icon,
    List,
    ListItem,
    Size,
    Placement,
    ListDivider,
    Theme,
} from '@lumapps/lumx/react';
import { LabelWithHighlightedQuery } from '@lumapps/search/components/LabelWithHighlightedQuery';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBoundInputSelection } from '@lumapps/utils/hooks/useBoundInputSelection';
import { ALL_SPACES_PATTERN } from '@lumapps/utils/string/constants';

import { TAGZ_CONTEXT_NAME, SPECIAL_POUND_CARACTER, TAGZ_MAX_LENGTH } from '../../constants';
import { FOLKSONOMY } from '../../keys';
import * as types from '../../types';
import { Tag } from '../Tag';
import {
    isTagNameValid,
    isTagDuplicated,
    removeTagFromTagsList,
    createTagSuggestion,
    removePoundFromLabel,
    formatTagNameInput,
} from '../utils';

/** Initial value for the suggestion that will be displayed while navigating. */
const INITIAL_NAVIGATION_SUGGESTION = undefined;

/** Base classname for the component. */
const CLASSNAME = 'tagz__autocomplete';

export interface TagsAutocompleteProps {
    /** True if the suggestions should be displayed from the start. */
    isTagSuggestionVisible?: boolean;
    /** True if the suggestions are on inline mode, meaning that they will not show the form label and the underline decoration. */
    isInline?: boolean;
    /**
     * Whether the field should be automatically focused.
     * Will trigger a focus if changed from false to true.
     *
     * Default = false */
    shouldFocus?: boolean;
    /** Index in the `tags` array that determines whether that tag should be highlighted from the start or not. */
    highlightedTagIndex?: number;
    /** Initial navigation suggestion */
    navSuggestion?: string;
    /** List of selected tags. */
    tags?: types.Tag[];
    /** Callback executed when the current tag being searched changes. */
    onTagNameChange: (name: string, tags: types.Tag[]) => void;
    /** True if the suggestions are still being loaded. */
    isLoading?: boolean;
    /** List of suggested tags. */
    tagSuggestions: types.Tag[];
    /** Current UI theme */
    theme?: Theme;
    /** Callback executed when the list of selected tags changes. */
    onTagsChange: (tags: types.Tag[]) => void;
    /** Callback executed when the user focuses in the autocomplete. */
    onFocus?(): void;
    /** Callback executed when the user blurs out the autocomplete. */
    onBlur?(): void;
    /** Callback executed when the user focuses a suggestion. */
    onSuggestionFocus?(): void;
    /** Callback executed when the user blurs out a suggestion. */
    onSuggestionBlur?(): void;
    /** Callback executed when the user focuses a selected tag. */
    onTagFocus?(): void;
    /** Callback executed when the user blurs out a selected tag. */
    onTagBlur?(): void;
    /** whether the label displayed above the autocomplete should be visible or not. */
    shouldHideLabel?: boolean;
    /** icon to be displayed on the autocomplete field */
    icon?: AutocompleteMultipleProps['icon'];
    /** whether the pound character before the search text should be displayed or not */
    shouldDisplayPoundCharacter?: boolean;
    /**
     * Give the ability to create or not a new tag
     * @default true
     */
    isNewTagAllowed?: boolean;
}

/**
 * Display tags autocomplete.
 *
 * @family Pickers
 */
const TagsAutocomplete: React.FC<TagsAutocompleteProps> = ({
    isTagSuggestionVisible = false,
    isInline = false,
    shouldFocus = false,
    highlightedTagIndex,
    tags = [],
    tagSuggestions,
    onTagNameChange,
    isLoading = false,
    navSuggestion = INITIAL_NAVIGATION_SUGGESTION,
    theme,
    onTagsChange,
    onBlur,
    onFocus,
    onSuggestionFocus,
    onSuggestionBlur,
    onTagFocus,
    onTagBlur,
    shouldHideLabel = false,
    shouldDisplayPoundCharacter = true,
    icon,
    isNewTagAllowed = true,
}) => {
    const [tagName, setTagName] = React.useState<string>('');
    const { element, block } = useClassnames(CLASSNAME);

    /**
     * Hooks setup.
     * - `inputRef`: reference for the text field used to search tags
     * - `suggestionsVisible`: allows to control when the suggestions are displayed or not.
     * - `navigationSuggestion`: text displayed on the text field while the user is navigating the suggestions.
     */
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [suggestionsVisible, setSuggestionsVisible] = React.useState(isTagSuggestionVisible);
    const [navigationSuggestion, setNavigationSuggestion] = React.useState<string | undefined>(navSuggestion);
    useBoundInputSelection(inputRef, { min: 1 });

    const { translateKey, translateAndReplace } = useTranslate();
    const { get } = useDataAttributes(TAGZ_CONTEXT_NAME);

    /**
     * Defined whether the current tagName is valid.
     * A new tag is defined as valid if:
     * * The tag name is actually valid on itself (via isTagNameValid utils)
     * * The tag name is not already suggested (via isTagDuplicated).
     */
    const hasValidNewTag = React.useMemo(() => {
        const normalizedTag = createTagSuggestion(tagName);
        return isTagNameValid(tagName) && !isTagDuplicated(tagSuggestions, normalizedTag);
    }, [tagName, tagSuggestions]);

    /**
     * The tagz label list in lowercase will be
     * use to exclude tagz already added from suggestions
     */
    const tagzLabelListInLowerCase = React.useMemo(() => {
        return isArray(tags) ? tags.map((tag) => tag.label.toLowerCase()) : [];
    }, [tags]);

    /**
     * If a tag name is defined, add it to the keyboardListNavigation items
     * so the useKeyboardListNavigation hook knows about it and manage it properly.
     */
    const suggestionsWithNewTag = React.useMemo(() => {
        const normalizedTag = createTagSuggestion(tagName);
        return hasValidNewTag ? [normalizedTag, ...tagSuggestions] : tagSuggestions;
    }, [hasValidNewTag, tagName, tagSuggestions]);

    /**
     * The result will be use to bold the matching part in suggestion label
     */
    const trimmedAndFormattedTagNameInput = React.useMemo(() => {
        return trim(formatTagNameInput(tagName), ALL_SPACES_PATTERN);
    }, [tagName]);

    /**
     * We don't want to show the suggestions while there are loading.
     * We also don't want to show an empty list if no suggestions are returned
     * or if the user hasn't entered a tagName yet unless is at true.
     */
    const showSuggestions = Boolean(
        !isLoading && suggestionsVisible && (tagName || isTagSuggestionVisible) && suggestionsWithNewTag.length > 0,
    );

    /**
     * Trigger a field focus when `shouldFocus` prop changes to `true`.
     */
    React.useEffect(() => {
        if (shouldFocus) {
            // eslint-disable-next-line no-unused-expressions
            inputRef?.current?.focus();
        }
    }, [shouldFocus]);

    /**
     * Callback executed when two consecutive backspaces are pressed. This event triggers the deletion of
     * the latest chip, so we trigger the `onTagsChange` function removing the last one.
     */
    /* istanbul ignore next */
    const onChipDeleted = () => {
        onTagsChange([...tags.slice(0, tags.length - 1)]);
    };

    const {
        activeChip: activeTagIndex,
        onBackspacePressed: chipBackspaceNavigation,
        resetChipNavigation,
    } = ChipGroup.useChipGroupNavigation(tags, onChipDeleted, highlightedTagIndex);

    /**
     * Callback executed when the autocomplete closes.
     * In that scenario, we need to update the internal state to `false`.
     */
    const closeAutocomplete = () => {
        setSuggestionsVisible(false);
    };

    /**
     * Function that adds the new tag to the list of tags and resets the autocomplete.
     * In case the tag already exists, we avoid adding it to the list. Else,
     * we reset the text field, change the navigation suggestions to its initial state
     * and trigger the callback with the newly added tag.
     * @param {Object} tag Tag to be added.
     */
    const addNewTag = (tag: types.Tag) => {
        // This condition fix SEA-104 (pressing enter twice when validating a tag also adds one of the suggested tags)
        const canAddTag = tagName || showSuggestions;
        if (!canAddTag || isTagDuplicated(tags, tag)) {
            return;
        }

        setNavigationSuggestion(INITIAL_NAVIGATION_SUGGESTION);
        onTagsChange([...tags, tag]);
        setTagName('');
        /**
         * This solve LUM-14209. Can be reproduce on mac with japanese keyboard
         * If you were typing some characters and press enter, the tag was added but the input was not cleared.
         * 2 change events were sent. The first one to clear the input and the second one with the previous value.
         * We cannot figure out where the second event comes from. The idea of the timeout is to invert the position
         * of these events and make the clean event appear in second.
         * Don't use git blame on that part, no one is proud of this
         */
        setTimeout(() => {
            onTagNameChange('', tags);
        }, 0);
    };

    /**
     * Resets the navigation suggestion to its initial state.
     */
    const resetNavigation = () => {
        setNavigationSuggestion(INITIAL_NAVIGATION_SUGGESTION);
        resetChipNavigation();
    };

    /**
     * Function triggered by the `onChange` event on the Text field. Here, we trigger the callback
     * passed by props and set the suggestions as visible depending on whether the query is valid.
     * @param {string} value New value entered on the text field.
     */
    const onChange = (value?: string) => {
        /**
         * If the POUND character is not present, the if on this method will fail since the value of an
         * empty tag will be '' instead of '#'. In that scenario, the validity of the tags value should be evaluated
         * considering whether the value exists or the text is an empty string.
         */
        if (value || (!shouldDisplayPoundCharacter && value === '')) {
            // Remove forbidden characters, smash spaces (regular and japanese) then add pound character since pound character is forbidden
            const formattedValue = formatTagNameInput(value as string);

            // Array conversion is required to handle correctly unicode
            if ([...formattedValue].length > TAGZ_MAX_LENGTH) {
                return;
            }
            onTagNameChange(formattedValue, tags);
            setSuggestionsVisible(isTagNameValid(formattedValue));
            setTagName(formattedValue);
            resetNavigation();
        }
    };

    /**
     * Function triggered when the user is navigating through the suggestions list. Useful to show
     * the suggestion on the Text Field.
     * @param {Object} tag      Tag navigated.
     * @param {string} tag.name Tag name.
     */
    const onItemNavigated = (tag: types.Tag) => {
        // This condition fix SEA-105 (Pressing up/down arrow displays one of the suggested tagz)
        if (!showSuggestions) {
            return;
        }
        // If the user tries to navigate while the suggestions are hidden
        // show them again.
        if (!isTagSuggestionVisible) {
            setSuggestionsVisible(true);
        }
        setNavigationSuggestion(tag.id);
    };

    /**
     * Function triggered when one of the selected tags wants to be removed. Upon removing the tag
     * we want to keep focus on the Text Field so the user can continue their selection.
     * @param {Object} evt Event that triggered the function
     * @param {Object} tag Tag to be erased.
     */
    const onClearTag = (evt: React.MouseEvent, tag: types.Tag) => {
        // Ignoring this line from coverage and eslint, inputRef is always defined upon executing this line, but TS and eslint do not agree
        /* istanbul ignore next */
        /* eslint-disable-next-line */
        inputRef?.current?.focus();
        onTagsChange(tag ? removeTagFromTagsList(tags, tag) : []);
    };

    /**
     * Function triggered when the user selects a tag from the suggestions list.
     * @param {Object} selectedTag Tag selected on the suggestions list.
     */
    const onTagSelected = (selectedTag: types.Tag) => {
        addNewTag(selectedTag);
        // eslint-disable-next-line no-unused-expressions
        inputRef?.current?.focus();
    };

    /**
     * Function triggered when the user decides to create a new Tag. This means that they
     * hit ENTER on the Text Field.
     * @param {string} newTag Tag name entered.
     */
    const onNewTagCreated = (newTag: string) => {
        const cleanedTag = removePoundFromLabel(newTag);
        if (isTagNameValid(cleanedTag)) {
            onTagSelected(createTagSuggestion(cleanedTag));
        }
    };

    /**
     * Function triggered when the Text Field was focused.
     * If the TextField is filled, we need to show the suggestions.
     */
    const handleOnFocus = () => {
        if (onFocus) {
            onFocus();
        }
        if (tagName) {
            setSuggestionsVisible(true);
            onTagNameChange(tagName, tags);
        }
    };

    /**
     * Returns true if the Text Field is currently empty.
     * @return {boolean} True if the text field is empty.
     */
    const isTextFieldEmpty: boolean = tagName.length === 0 && !navigationSuggestion;

    /**
     * Function triggered when the user hits BACKSPACE on their keyboards. The idea here is that, if the text
     * field is empty and there are tags selected, we change
     * `wasBackspacePressed` to true and set the active tag as the last one. That will show the user that they are
     * about to delete the last tag since it will be displayed as active. If they hit backspace once more, we
     * remove the last tag, update the tags list and change `wasBackspacePressed` back to false and start over.
     *
     * If there is a navigation suggestion, we need to remove it and let the user continue using the
     * text field. In that case, we trigger an `onChange` and reset the navigation suggestion.
     */
    const onBackspacePressed = () => {
        if (isTextFieldEmpty && tags.length > 0) {
            chipBackspaceNavigation();
        } else if (navigationSuggestion) {
            onChange(navigationSuggestion);
            resetNavigation();
        }
    };

    const { activeItemIndex, setActiveItemIndex } = List.useKeyboardListNavigation(
        // the list of items that will be navigated using the keyboard.
        suggestionsWithNewTag,
        // A reference to the element that is controlling the navigation.
        inputRef,
        // callback to be executed when the ENTER key is pressed on an item.
        onTagSelected,
        // callback to be executed when the Arrow keys are pressed.
        onItemNavigated,
        // callback to be executed when the ENTER key is pressed.
        onNewTagCreated,
        // callback to be executed when the BACKSPACE key is pressed.
        onBackspacePressed,
        // determines whether after selecting an item, the focus should be maintained on the current target or not.
        true,
        // where should the navigation start from. it defaults to `-1`, so the first item navigated is the item on position `0`.
        0,
        // determines whether upon TAB, if there is a value entered, the event is prevented or not.
        false,
    );

    const handleOnItemSelected = (selectedTag: types.Tag) => () => {
        return onTagSelected(selectedTag);
    };

    const className = block({
        inline: isInline,
    });

    const createCurrentTagName = () => onNewTagCreated(tagName);

    const suggestionsWithNewTagLabelsInLowerCase = React.useMemo(
        () => suggestionsWithNewTag.map((suggestion) => suggestion.label.toLowerCase()),
        [suggestionsWithNewTag],
    );

    /**
     * In case the tagName is included in suggestions,
     * the following code will put the highlight on it
     */
    useEffect(() => {
        const tagNameInLowerCaseWithoutTrailingSpaces = trim(tagName.toLowerCase(), ALL_SPACES_PATTERN);
        const tagNameIndex = suggestionsWithNewTagLabelsInLowerCase.findIndex(
            (suggestionLabelInLowerCase) => suggestionLabelInLowerCase === tagNameInLowerCaseWithoutTrailingSpaces,
        );
        setActiveItemIndex(tagNameIndex !== -1 ? tagNameIndex : 0);
    }, [setActiveItemIndex, suggestionsWithNewTagLabelsInLowerCase, tagName]);

    return (
        <AutocompleteMultiple
            anchorToInput
            className={className}
            inputRef={inputRef}
            isOpen={showSuggestions}
            label={isInline || shouldHideLabel ? undefined : translateKey(GLOBAL.TAGZ)}
            icon={icon}
            // eslint-disable-next-line react/no-unstable-nested-components
            selectedChipRender={(tag: types.Tag, index: number) => (
                <Tag
                    // The key here is required since the autocomplete will loop through each of given values
                    key={tag.id || index}
                    isEditable
                    isHighlighted={index === activeTagIndex}
                    tag={{ ...tag, index }}
                    position={index + 1}
                    onClear={onClearTag}
                    onClick={onClearTag}
                    onFocus={onTagFocus}
                    onBlur={onTagBlur}
                />
            )}
            value={`${shouldDisplayPoundCharacter ? SPECIAL_POUND_CARACTER : ' '}${navigationSuggestion || tagName}`}
            /**
             * HEAD'S UP! Sometimes `tags` has a value of `{}` provided by the angularjs `react-element` directive.
             * This happens when angular is rendering other sections that are not in React and has not
             * reached this component yet. It does not affect the behaviour of this component, since when `tags = {}`
             * this component is not displayed, but it does get executed. That's why we check to see if it is an array.
             */
            values={isArray(tags) ? tags : []}
            onChange={onChange}
            onClose={closeAutocomplete}
            onFocus={handleOnFocus}
            onBlur={onBlur}
            name="tagz-autocomplete"
            closeOnClickAway
            placement={Placement.BOTTOM_START}
            fitToAnchorWidth={false}
            theme={theme}
            {...get({ element: 'autocomplete' })}
        >
            {showSuggestions && (
                <List
                    className={element('suggestions')}
                    isClickable
                    {...get({ element: 'dropdown', action: 'suggest' })}
                >
                    {suggestionsWithNewTag.map((suggestion, index) => {
                        /**
                         * If the user is currently typing a new tag,
                         * display a dedicated ListItem prompting them to create a new tag
                         * if the tag was not already added.
                         */
                        if (index === 0 && hasValidNewTag) {
                            const isTagAlreadyAdded = tagzLabelListInLowerCase.includes(suggestion.label.toLowerCase());
                            return (
                                <Fragment key={suggestion.id || index}>
                                    {isTagAlreadyAdded ? (
                                        <ListItem size={Size.tiny}>
                                            {translateAndReplace(GLOBAL.IS_ALREADY_ADDED, {
                                                TAG: suggestion.label,
                                            })}
                                        </ListItem>
                                    ) : null}
                                    {!isTagAlreadyAdded && isNewTagAllowed ? (
                                        <ListItem
                                            isHighlighted={activeItemIndex === 0 || activeItemIndex === -1}
                                            onItemSelected={createCurrentTagName}
                                            size={Size.tiny}
                                            onFocus={onSuggestionFocus}
                                            onBlur={onSuggestionBlur}
                                        >
                                            {translateAndReplace(FOLKSONOMY.NEW_TAG, {
                                                TAG_NAME: suggestion.label,
                                            })}
                                        </ListItem>
                                    ) : null}
                                    {/* If there also are suggestions, show a divider */}
                                    {tagSuggestions?.length > 0 && isNewTagAllowed && <ListDivider />}
                                </Fragment>
                            );
                        }
                        return (
                            <ListItem
                                key={suggestion.id || index}
                                before={
                                    <Icon
                                        color="dark"
                                        colorVariant={ColorVariant.L2}
                                        icon={mdiPound}
                                        size={Size.xxs}
                                        alt=""
                                    />
                                }
                                isHighlighted={activeItemIndex === index}
                                size={Size.tiny}
                                {...get({
                                    element: 'suggest-item',
                                    action: suggestion.id,
                                    position: index,
                                })}
                                onItemSelected={handleOnItemSelected({
                                    ...suggestion,
                                    index,
                                })}
                                onFocus={onSuggestionFocus}
                                onBlur={onSuggestionBlur}
                            >
                                <LabelWithHighlightedQuery
                                    label={suggestion.label}
                                    searchQuery={trimmedAndFormattedTagNameInput}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </AutocompleteMultiple>
    );
};

export { TagsAutocomplete };
