import React, { useEffect, useMemo } from 'react';

import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { currentLanguageSelector } from '@lumapps/languages';
import { Calendar } from '@lumapps/lumx-calendar';
import { CalendarConfig } from '@lumapps/lumx-calendar/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { useCalendarsFilters } from '../../hooks/useCalendarsFilters';
import { useCalendarWidget } from '../../hooks/useCalendarWidget';
import { WIDGET_CALENDAR } from '../../keys';
import { LegacyCalendar, LegacyCalendarProperties } from '../../types';
import { getCalendarConfig } from '../../utils/getCalendarConfig';

import './index.scss';

interface ClientComputedCalendarProps extends ClientComputedProps {
    properties?: LegacyCalendarProperties;
}

export const ClientComputedCalendar: React.FC<ClientComputedCalendarProps> = ({ properties = {}, uuid }) => {
    const dispatch = useDispatch();
    const { translateKey } = useTranslate();
    const locale = useSelector(currentLanguageSelector);

    const { calendarsSelected = [], userCalendarChecked, userCalendarColor, displayToolbar: hasToolbar } = properties;
    const theme = properties?.style?.content?.theme;
    const backgroundColor = properties?.style?.content?.backgroundColor ?? properties?.style?.main?.backgroundColor;

    /**
     * Get all available calendars used as sources for the widget
     * if the user calendar is selected, we generate the calendar locally and add it to the list
     * */
    const availableCalendars: LegacyCalendar[] = useMemo(
        () =>
            compact([
                userCalendarChecked && {
                    color: userCalendarColor as string,
                    id: 'primary',
                    isChecked: false,
                    summary: translateKey(WIDGET_CALENDAR.USER_CALENDAR),
                },
                ...calendarsSelected,
            ]),
        [calendarsSelected, translateKey, userCalendarChecked, userCalendarColor],
    );

    /**
     * Define the default config object for the Calendar.
     * Based on the widget properties configuration.
     * */
    const calendarConfig: CalendarConfig = useMemo(() => getCalendarConfig(properties, locale), [locale, properties]);

    /**
     * Get the list of currently visible calendars and the Calendar selector component.
     * */
    const { selectedCalendars, CalendarSelectorMenu } = useCalendarsFilters(availableCalendars, hasToolbar, theme);

    /**
     * Generate the properties and callbacks for the Calendar component.
     * */
    const { ...calendarProps } = useCalendarWidget(
        calendarConfig,
        properties as LegacyCalendarProperties,
        selectedCalendars,
    );

    /**
     * If the widget does not have any calendar selected
     * We remove the client computed widget from the layout.
     * */
    useEffect(() => {
        if (isEmpty(properties) || isEmpty(availableCalendars)) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, properties, uuid, availableCalendars]);

    /**
     * If the widget does not have any calendar selected
     * We skip the rendering since the widget will be removed in the useEffect above
     * */
    if (isEmpty(properties) || isEmpty(availableCalendars)) {
        return null;
    }

    return (
        <Calendar
            {...calendarProps}
            theme={theme}
            backgroundColor={backgroundColor}
            config={calendarConfig}
            toolbarBefore={CalendarSelectorMenu}
        />
    );
};
