/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { GetBaseUrl } from '@lumapps/learning-utils/getBaseUrl';

import { LeaderboardDataType } from '../types';

/**
 * Leaderboard API to retrieve leaderboard data for learning feature.
 *
 */
const leaderboardApi = (baseURL: string) =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL,
    });
const paths = {
    leaderboard: 'learning-analytics/v2/leaderboard',
};

export async function getLeaderboard(
    offset: number,
    limit: number,
    learningAlphaEnabled: boolean,
    groupId?: string[],
): Promise<LeaderboardDataType> {
    const baseURL = GetBaseUrl(learningAlphaEnabled);

    const { data } = await leaderboardApi(baseURL).get<LeaderboardDataType>(paths.leaderboard, {
        params: { offset, limit, groupId },
    });

    return data;
}

export { leaderboardApi };
