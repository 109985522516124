/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { Group } from '@lumapps/groups/types';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Button, Emphasis, FlexBox, List, ListItem, ListSubheader } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useParams } from '@lumapps/router';
import { getSpaceData } from '@lumapps/spaces/api';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus, isError, isLoading } from '@lumapps/utils/types/BaseLoadingStatus';

import { getLeaderboard } from '../../api';
import {
    DEFAULT_MAX_NUMBER_ELEMENTS_ON_LEADERBOARD_LIST,
    SIZE_INITIAL_RANKING_LIST,
    INITIAL_LEADERBOARD_OFFSET,
    DEFAULT_DISPLAY_TYPE,
} from '../../constants';
import { LEADERBOARD } from '../../keys';
import { RankingData, userTypes, SpacesId, LearningLeaderboardDisplayType } from '../../types';
import { RankingListItem } from '../RankingListItem';
import { RankingListItemSkeleton } from '../RankingListItem/RankingListItemSkeleton';

export interface LeaderboardProps {
    inSystemPage?: boolean;
    groups?: Group[];
    displayType?: LearningLeaderboardDisplayType;
    isLegacyApp?: boolean;
    spaceId?: string;
}

export const Leaderboard: React.FC<LeaderboardProps> = ({
    inSystemPage = false,
    groups,
    displayType = DEFAULT_DISPLAY_TYPE,
    isLegacyApp = false,
    spaceId = '',
}) => {
    const groupId = groups?.map((group) => group.id) || [];
    const [currentGroupIds, setCurrentGroupIds] = useState<string[]>([]);
    const [currentUserData, setCurrentUserData] = useState<RankingData>();
    const [rankingList, setRankingList] = useState<RankingData[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [isGroupIdSet, setIsGroupIdSet] = useState<boolean>(false);
    const learningAlphaEnabled = useSelector(isLearningAlphaEnabled);
    const { id: spaceIdentifier } = useParams<SpacesId>();
    const { isSpace } = useSpace();

    const [status, setStatus] = useState(BaseLoadingStatus.initial);
    const { translateKey } = useTranslate();

    const getSpacesGroupIds = useCallback(async () => {
        const { data } = await getSpaceData(spaceIdentifier || spaceId);

        const allGroupIds = [
            data.spaceGroups.technicalGroupId,
            ...data.spaceGroups.adminGroupIds,
            ...data.spaceGroups.memberGroupIds,
        ];
        const spacesGroupIds = new Set(allGroupIds);

        return Array.from(spacesGroupIds);
    }, [spaceId]);

    const fetchLeaderboardData = async (offset = 0) => {
        try {
            setStatus(BaseLoadingStatus.loading);
            const groupsToLeaderboard = isGroupIdSet ? currentGroupIds : groupId;
            const response = await getLeaderboard(
                offset,
                SIZE_INITIAL_RANKING_LIST,
                learningAlphaEnabled,
                groupsToLeaderboard,
            );

            if (offset === 0) {
                setRankingList(response.rankingList?.items || []);
            } else {
                setRankingList((prevList) => [...prevList, ...(response.rankingList?.items || [])]);
            }
            setHasMore(response.rankingList.more);
            if (response?.currentUser) {
                setCurrentUserData(response.currentUser);
            }
            setStatus(BaseLoadingStatus.idle);
        } catch (e) {
            setStatus(BaseLoadingStatus.error);
        }
    };

    useEffect(() => {
        const fetchSpacesGroupIds = async () => {
            const spacesGroupIds = await getSpacesGroupIds();
            setCurrentGroupIds(spacesGroupIds);
            setIsGroupIdSet(true);
        };

        const initFetch = async () => {
            if (!isGroupIdSet && (isSpace || isLegacyApp) && displayType === 'current') {
                await fetchSpacesGroupIds();
            } else {
                setIsGroupIdSet(false);
                setCurrentGroupIds([]);
            }
        };
        setRankingList([]);
        initFetch();
    }, [displayType]);

    useEffect(() => {
        if (!isGroupIdSet && displayType === 'current' && !isGroupIdSet && isSpace) {
            return;
        }
        fetchLeaderboardData();
    }, [isGroupIdSet, groups]);

    const handleLoadMore = () => {
        fetchLeaderboardData(rankingList.length || 0);
    };
    const checkUserType = (ranking: RankingData) => {
        if (rankingList && rankingList?.length > 1) {
            if (currentUserData?.identifier === ranking.identifier) {
                return userTypes.currentUserInTotalRanking;
            }
            return userTypes.someUser;
        }
        return userTypes.currentUserRanking;
    };

    const shouldShowAverageScore = (rankingList: RankingData[], index: number) => {
        const currentPoints = rankingList[index]?.totalPoints;
        const nextPoints = rankingList[index + 1]?.totalPoints;
        const prevPoints = rankingList[index - 1]?.totalPoints;

        if (
            (currentPoints !== undefined && currentPoints === nextPoints) ||
            (currentPoints !== undefined && currentPoints === prevPoints)
        ) {
            return true;
        }
        return false;
    };
    return (
        <FlexBox className={inSystemPage ? '' : 'lumx-spacing-padding-huge'}>
            {isError(status) && (
                <ServiceNotAvailableState
                    shouldShowIcon
                    onRetry={() =>
                        getLeaderboard(INITIAL_LEADERBOARD_OFFSET, SIZE_INITIAL_RANKING_LIST, learningAlphaEnabled)
                    }
                />
            )}

            {isLoading(status) && (
                <>
                    <List>
                        <ListSubheader> {translateKey(LEADERBOARD.USER_RANKING)}</ListSubheader>
                        <ListItem>
                            <RankingListItemSkeleton />
                        </ListItem>
                    </List>
                    <List>
                        <ListSubheader> {translateKey(LEADERBOARD.FULL_RANKING)}</ListSubheader>
                        {Array.from({ length: DEFAULT_MAX_NUMBER_ELEMENTS_ON_LEADERBOARD_LIST }, () => {
                            return (
                                <ListItem>
                                    <RankingListItemSkeleton />
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}

            {currentUserData && (
                <>
                    <List>
                        <ListSubheader> {translateKey(LEADERBOARD.USER_RANKING)}</ListSubheader>
                        <ListItem>
                            <RankingListItem
                                key={currentUserData.identifier}
                                ranking={currentUserData}
                                userType={userTypes.currentUserRanking}
                            />
                        </ListItem>
                    </List>
                    {rankingList.length > 0 && (
                        <List>
                            <ListSubheader> {translateKey(LEADERBOARD.FULL_RANKING)}</ListSubheader>

                            {rankingList?.map((ranking, index) => {
                                const showAverageScore = shouldShowAverageScore(rankingList, index);
                                const userType = checkUserType(ranking);
                                return (
                                    <ListItem key={ranking.identifier}>
                                        <RankingListItem
                                            key={ranking?.ranking}
                                            ranking={ranking}
                                            userType={userType}
                                            showAverageScore={showAverageScore}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}

                    {rankingList.length > 0 && hasMore && (
                        <Button fullWidth emphasis={Emphasis.medium} onClick={handleLoadMore}>
                            {translateKey(GLOBAL.NEXT)}
                        </Button>
                    )}
                </>
            )}
        </FlexBox>
    );
};
