import React from 'react';

import { GROUPS } from '@lumapps/groups/keys';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiAccountMultiple } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { Groups } from '../../routes';

export interface GroupsSideNavigationProps {
    isAllowed: boolean;
}

const GroupsSideNavigation = ({ isAllowed }: GroupsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return <SideNavigationItem label={translateKey(GROUPS.FEEDS)} icon={mdiAccountMultiple} route={Groups()} />;
};

GroupsSideNavigation.displayName = 'SideNavigationItem';

export { GroupsSideNavigation };
