import { mdiImageOutline } from '@lumapps/lumx/icons';
import { useMediaPickerDropdown } from '@lumapps/medias/hooks/useMediaPickerDropdown';
import { Media, MediaPickerParams } from '@lumapps/medias/types';
import { useTranslate } from '@lumapps/translations';
import { useFilePicker } from '@lumapps/utils/hooks/useFilePicker';
import { ToolbarItem } from '@lumapps/wrex/types';

import { IMAGE_GROUP } from '../../../constants';
import { WREX_ENHANCED_IMAGE } from '../../../keys';

export interface UseInsertEnhancedImageButtonProps {
    /** Whether Drive is allowed in the media picker. */
    isDriveAllowed?: boolean;
    // Whether we are in a community context or not.
    isInCommunityContext?: boolean;
    /** Whether Lumdrive is allowed in the media picker. */
    isLumdriveAllowed?: boolean;
    /** Params of the media picker */
    mediaPickerParams?: MediaPickerParams;
    /** Function to upload from computer. */
    onQuickUpload: (files: File[]) => void;
    /** On media change callback */
    onMediaChange?(media: Media[]): void;
    /** Files that will already be selected on the media picker */
    currentFiles?: Media[];
    /** Other props passed to the ToolbarItem */
    otherProps?: any;
    isImageGalleriesEnabled?: boolean;
}

export const useInsertEnhancedImageButton = ({
    onQuickUpload,
    isImageGalleriesEnabled,
    currentFiles,
    isDriveAllowed,
    isLumdriveAllowed,
    isInCommunityContext,
    mediaPickerParams,
    onMediaChange,
    otherProps,
}: UseInsertEnhancedImageButtonProps) => {
    const { translateKey } = useTranslate();

    const { hiddenInput, openPicker } = useFilePicker({
        onSelectFiles: (files) => {
            if (files) {
                onQuickUpload(files);
            }
        },
        accept: 'image/*',
        multiple: Boolean(isImageGalleriesEnabled),
        inputOrigin: 'image-insertion-button',
    });

    const { mediaPickerDropdownProps } = useMediaPickerDropdown({
        onUploadFromComputer: openPicker,
        currentFiles,
        isDriveAllowed,
        isInCommunityContext,
        isLumdriveAllowed,
        mediaPickerParams,
        onMediaChange,
        subMenuProps: {
            itemKey: IMAGE_GROUP,
            icon: mdiImageOutline,
            otherProps,
            tooltipLabel: translateKey(WREX_ENHANCED_IMAGE.INSERT_IMAGE),
            verticalModeProps: {
                label: translateKey(WREX_ENHANCED_IMAGE.IMAGE_BUTTON_LABEL),
                tooltipLabel: undefined,
            },
        },
    });

    const insertImageButtonProps: ToolbarItem = mediaPickerDropdownProps || {
        type: 'option',
        itemKey: IMAGE_GROUP,
        icon: mdiImageOutline,
        onClick: openPicker,
        otherProps,
        tooltipLabel: translateKey(WREX_ENHANCED_IMAGE.INSERT_IMAGE),
        verticalModeProps: {
            label: translateKey(WREX_ENHANCED_IMAGE.IMAGE_BUTTON_LABEL),
            tooltipLabel: undefined,
        },
    };

    return { hiddenInput, insertImageButtonProps };
};
