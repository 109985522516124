import React, { useEffect } from 'react';

import { Catalog } from '@lumapps/learning-catalog/components/Catalog';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { LearningCatalogProps } from '../../types';

export const ClientComputedLearningCatalog: React.FC<LearningCatalogProps> = ({ properties = {}, uuid }) => {
    const dispatch = useDispatch();
    const learningEnabled = useSelector(isLearningEnabled);

    useEffect(() => {
        if (!learningEnabled) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [learningEnabled, dispatch, uuid]);

    return <Catalog viewModeVariant={properties.viewModeVariant} categoryCode={properties.categoryCode} />;
};
