export enum BACK_OFFICE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244383
     */
    SUB_MODULES = 'ADMIN.NAV.SUB_MODULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244399
     */
    SUB_INSTANCE = 'ADMIN.NAV.SUB_INSTANCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244412
     */
    SUB_PLATFORM = 'ADMIN.NAV.SUB_PLATFORM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=25104594
     */
    VIEW_RELEASE_INFO = 'ADMIN.NAV.VIEW_RELEASE_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=76458049
     */
    SUB_MOBILE = 'ADMIN.NAV.SUB_MOBILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78959603
     */
    INSIGHTS = 'ADMIN.NAV.INSIGHTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98616979
     */
    SEARCH = 'ADMIN.NAV.SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165869871
     */
    SUB_CONTENT_STRATEGY = 'ADMIN.NAV.SUB_CONTENT_STRATEGY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=386608630
     */
    COMPANION = 'ADMIN.NAV.COMPANION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460509904
     */
    SEARCH_AI = 'ASK_LUMAPPS_ADMIN.SEARCH_AI',
}
