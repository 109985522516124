import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const journeysBaseRoute: Route = {
    path: '/admin/journeys',
    appId: AppId.backOffice,
    legacyId: 'app.admin.journeys',
};

export const journeysGlobalSettingsRoute: Route = {
    ...journeysBaseRoute,
    path: `${journeysBaseRoute.path}/settings`,
    legacyId: `${journeysBaseRoute.legacyId}-settings`,
};

export const journeysListRoute: Route = {
    ...journeysBaseRoute,
    path: `${journeysBaseRoute.path}/list`,
    legacyId: `${journeysBaseRoute.legacyId}-list`,
};

export interface JourneyListParams {
    title?: string;
}

export const journeyList = (query?: JourneyListParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    return {
        ...journeysListRoute,
        query: queryParams,
    };
};

export enum JourneyViews {
    edit = 'edit',
    view = 'view',
    itemConfigurations = 'item-configurations',
    enrollments = 'enrollments',
}

export const journeyPageRoute: Route = {
    appId: AppId.backOffice,
    path: `${journeysBaseRoute.path}/:id/:view?`,
};

export interface JourneyPageParams {
    id: string;
    view?: JourneyViews;
}

export const journeyPage = (params: JourneyPageParams, query?: { fullName?: string }): Route => {
    return {
        ...journeyPageRoute,
        params: {
            id: params.id,
            view: params.view || JourneyViews.view,
        },
        query: query?.fullName ? { name: query.fullName } : {},
    };
};

export const journeysCreateRoute: Route = {
    path: `${journeysBaseRoute.path}/create`,
    appId: AppId.backOffice,
};

export const journeyViewRoute: Route = {
    ...journeyPageRoute,
    params: {
        view: JourneyViews.view,
    },
};

export const journeyView = (params: Omit<JourneyPageParams, 'view'>): Route => {
    return {
        ...journeyViewRoute,
        params: {
            ...journeyViewRoute.params,
            id: params.id,
        },
    };
};

export const journeyEditRoute: Route = {
    ...journeyPageRoute,
    params: {
        view: JourneyViews.edit,
    },
};

export const journeyEdit = (params: Omit<JourneyPageParams, 'view'>): Route => {
    return {
        ...journeyEditRoute,
        params: {
            ...journeyEditRoute.params,
            id: params.id,
        },
    };
};
