import { useLayoutEffect } from 'react';

export enum MODES {
    css = 'css',
    html = 'htmlmixed',
    javascript = 'javascript',
}

const editorForId: Record<string, any> = {};

export interface UseCodeTextAreaOptions {
    /** mode to be used for rendering the code mirror field */
    mode: MODES | { name: MODES; json: boolean };
    /** whether the data is still loading or it has already loaded */
    hasFinishedLoading?: boolean;
    /** whether syntax highlighting should be applied or not */
    shouldApplyHighlightSyntax?: boolean;
    /** id of the field */
    id: string;
    /** optional boolean to fold/unfold code mirror */
    isFolded?: boolean;
    /** callback that will be executed once the text area is blurred */
    setValue?: (value: string) => void;
    /** initial value to be displayed on the text area */
    value?: string;
    /** force code mirror reload  */
    forceReload?: boolean;
    /** override props for code mirror */
    overrideProps?: Record<string, any>;
    /** to set editor size */
    editorSize?: { w: number | string; h: number | string };
    /** optional boolean to if code mirror is read only */
    readOnly?: boolean;
    /** the level of indentation default */
    defaultIndentationLevel?: number;
}

/**
 * Text Area with syntax highlighting provided by Code Mirror. It needs to be used with the useThirdPartyLibrary hook
 * @param CodeTextAreaOptions
 * @returns CodeMirrorTextArea
 */
export const useCodeMirrorTextArea = ({
    mode,
    hasFinishedLoading,
    id,
    setValue,
    shouldApplyHighlightSyntax = false,
    value,
    forceReload = false,
    overrideProps,
    isFolded,
    editorSize,
    readOnly,
    defaultIndentationLevel = 1,
}: UseCodeTextAreaOptions) => {
    /**
     * Fold code for a given indentation level
     * @param editor The code mirror editor
     * @param level the level of indentation require
     */
    const foldBeyondLevel = (editor: any, level: number) => {
        if (!editor || typeof editor.operation !== 'function' || typeof editor.eachLine !== 'function') {
            // Invalid CodeMirror editor instance
            return;
        }

        editor.operation(() => {
            editor.eachLine((lineHandle: any) => {
                const line = lineHandle.lineNo();
                const lineIndent = editor.getLine(line).search(/\S/);
                const indentSize = editor.getOption('indentUnit');

                if (lineIndent !== -1 && lineIndent > level * indentSize) {
                    const startPos = { line, ch: 0 };
                    editor.foldCode(startPos, null, 'fold');
                }
            });
        });
    };

    useLayoutEffect(() => {
        let editor;

        /**
         * If there is already an editor created for the given id, we need to avoid rendering again CodeMirror
         * because this lib instead of replacing the existing text area, it will create a new one beside the existing
         * one. If there was already and editor created for the given id, we just change the value of the editor
         * for the new one.
         */
        if (editorForId[id] && !forceReload) {
            editor = editorForId[id];

            editor.setValue(value);
        } else if (hasFinishedLoading && shouldApplyHighlightSyntax && window.CodeMirror) {
            /**
             * If this is a forceReload, we need to make sure that we delete the old code mirror instance (if there is)
             * The only way to do this is to remove code mirror from the DOM.
             */
            if (forceReload) {
                const oldEditor = document.querySelector(`.${id} .CodeMirror`);

                if (oldEditor && oldEditor.parentNode) {
                    oldEditor.parentNode.removeChild(oldEditor);
                }
            }
            /**
             * We should only trigger code mirror if the data was loaded and if this component's instance
             * wants to display syntax highlighting and if the code mirror variable is loaded
             */
            const textArea = document.getElementById(id);

            editor = window.CodeMirror.fromTextArea(textArea, {
                mode,
                selectionPointer: true,
                lineNumbers: true,
                smartIndent: true,
                readOnly,
                ...(overrideProps || {}),
            });

            if (editorSize) {
                editor.setSize(editorSize.w, editorSize.h);
            }

            editorForId[id] = editor;
        }

        /**
         * We are updating the actual value of the text area on blur in order to avoid re-renders
         * on each entered key. CodeMirror is not really a fan of having multiple updates in a short time frame.
         */
        if (editor && setValue) {
            editor.on('blur', (params: any) => {
                setValue(params.doc.getValue());
            });
        }

        /**
         * We fold all code blocks after the first level of indentation
         */
        if (editor && isFolded) {
            foldBeyondLevel(editor, defaultIndentationLevel);
        }

        /**
         * We unfold all code blocks
         */
        if (editor && isFolded === false) {
            editor.execCommand('unfoldAll');
        }
    }, [
        hasFinishedLoading,
        shouldApplyHighlightSyntax,
        mode,
        id,
        setValue,
        value,
        forceReload,
        overrideProps,
        isFolded,
        editorSize,
        readOnly,
        defaultIndentationLevel,
    ]);
};
