export enum PLATFORM {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452291
     */
    CUSTOMER_LICENSE_EXPIRATION_DATE = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_LICENSE_EXPIRATION_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452292
     */
    CUSTOMER_LICENSE_NUMBER = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_LICENSE_NUMBER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452293
     */
    CUSTOMER_LICENSE_TYPE = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_LICENSE_TYPE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452310
     */
    LICENSE_EXPIRED = 'ADMIN_RESELLER_CONSOLE_LICENSE_EXPIRED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452311
     */
    LICENSE_OVER_QUOTA = 'ADMIN_RESELLER_CONSOLE_LICENSE_OVER_QUOTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244438
     */
    RESELLER = 'ADMIN.NAV.RESELLER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11056045
     */
    IDENTIY_PROVIDER_LABEL = 'ADMIN.CUSTOMER.IDENTIY_PROVIDER_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=167753986
     */
    IDENTITY_PROVIDER_INTERNAL = 'ADMIN.CUSTOMER.IDENTITY_PROVIDER_INTERNAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645076
     */
    SYNC_TO_GSUITE = 'ADMIN.PROVIDER.SYNC_TO_GSUITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645277
     */
    EXCEPTIONS_HELPER = 'ADMIN.PROVIDER.EXCEPTIONS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645284
     */
    EXCEPTIONS_FIELD_TYPE = 'ADMIN.PROVIDER.EXCEPTIONS_FIELD_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645300
     */
    EXCEPTIONS_FIELD_VALUE = 'ADMIN.PROVIDER.EXCEPTIONS_FIELD_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645303
     */
    EXCEPTIONS = 'ADMIN.PROVIDER.EXCEPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645311
     */
    OPTIONS = 'ADMIN.PROVIDER.OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645316
     */
    SOURCE = 'ADMIN.PROVIDER.SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645342
     */
    AUTO_SYNC_HELPER = 'ADMIN.PROVIDER.AUTO_SYNC_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645355
     */
    SSO_ENABLED = 'ADMIN.PROVIDER.SSO_ENABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29645369
     */
    SSO_ENABLED_HELPER = 'ADMIN.PROVIDER.SSO_ENABLED_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29647046
     */
    TECHNICAL_ACCOUNT = 'ADMIN.PROVIDER.TECHNICAL_ACCOUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29647056
     */
    EXCEPTIONS_FIELD_NAME = 'ADMIN.PROVIDER.EXCEPTIONS_FIELD_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29648027
     */
    MICROSOFT_HELPER = 'ADMIN.PROVIDER.MICROSOFT.HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29648137
     */
    AUTO_SYNC_LABEL = 'ADMIN.PROVIDER.AUTO_SYNC_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29651989
     */
    CRED_SECRET_HELPER = 'ADMIN.PROVIDER.CRED_SECRET_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29651994
     */
    CRED_CLIENT_ID_HELPER = 'ADMIN.PROVIDER.CRED_CLIENT_ID_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29651998
     */
    CRED_SECRET = 'ADMIN.PROVIDER.CRED_SECRET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29652006
     */
    CRED_CLIENT_ID = 'ADMIN.PROVIDER.CRED_CLIENT_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29652016
     */
    API_TOKEN_HELPER = 'ADMIN.PROVIDER.API_TOKEN_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29652017
     */
    API_TOKEN = 'ADMIN.PROVIDER.API_TOKEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29652058
     */
    CRED = 'ADMIN.PROVIDER.CRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33483110
     */
    CUSTOMER_SYNC_HIDE_USER = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_SYNC_HIDE_USER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33483111
     */
    CUSTOMER_SYNC_HIDE_USER_HELP = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_SYNC_HIDE_USER_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=35116284
     */
    CUSTOMER_LOGIN_DOMAIN = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_LOGIN_DOMAIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36616953
     */
    CUSTOMER_SSO_DOMAIN_HINT = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_SSO_DOMAIN_HINT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=41309060
     */
    VERIF_AFTER_LOGIN = 'ADMIN.PROVIDER.DOMAIN.VERIF_AFTER_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=41310376
     */
    VALIDATION_AFTER_SAVING = 'ADMIN.PROVIDER.DOMAIN.VALIDATION_AFTER_SAVING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42039154
     */
    TECHNICAL_ACCOUNT_HELPER = 'ADMIN.PROVIDER.TECHNICAL_ACCOUNT.HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49870460
     */
    PROVISIONING_ENABLED = 'ADMIN.PROVIDER.PROVISIONING_ENABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49870470
     */
    PROVISIONING_ENABLED_HELPER = 'ADMIN.PROVIDER.PROVISIONING_ENABLED_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=52424219
     */
    LOGIN_ENABLED = 'ADMIN.PROVIDER.LOGIN_ENABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=52424231
     */
    LOGIN_ENABLED_HELPER = 'ADMIN.PROVIDER.LOGIN_ENABLED_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58815081
     */
    HAS_INTEGRATION_ONLY = 'ADMIN.PROVIDER.HAS_INTEGRATION_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58815084
     */
    HAS_INTEGRATION_ONLY_HELPER = 'ADMIN.PROVIDER.HAS_INTEGRATION_ONLY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=64229243
     */
    USE_BOOKMARKS = 'ADMIN.PROVIDER.USE_BOOKMARKS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=64229309
     */
    USE_BOOKMARKS_HELPER = 'ADMIN.PROVIDER.USE_BOOKMARKS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=69907304
     */
    FEDERATE_ON_EMPLOYEEID = 'ADMIN.PROVIDER.FEDERATE_ON_EMPLOYEEID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=69907305
     */
    FEDERATE_ON_EMPLOYEEID_HELPER = 'ADMIN.PROVIDER.FEDERATE_ON_EMPLOYEEID_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133199061
     */
    SYNC_APPLICATION_ONLY = 'ADMIN.PROVIDER.SYNC_APPLICATION_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133199066
     */
    SYNC_APPLICATION_ONLY_HELPER = 'ADMIN.PROVIDER.SYNC_APPLICATION_ONLY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=148982941
     */
    EXCEPTIONS_FIELD_CONDITION = 'ADMIN.PROVIDER.EXCEPTIONS_FIELD_CONDITION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175310119
     */
    PROVIDER_LOGIN_REDIRECT = 'ADMIN.PROVIDER.LOGIN_REDIRECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175310384
     */
    PROVIDER_LOGIN_REDIRECT_HELPER = 'ADMIN.PROVIDER.LOGIN_REDIRECT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=176433582
     */
    PROVIDER_AUTO_LOGIN_REDIRECT_HELPER = 'ADMIN.PROVIDER.AUTO_LOGIN_REDIRECT_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452298
     */
    CUSTOMER_SAVED = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182272339
     */
    PROVIDER_LOGIN_VISIBILITY_LABEL = 'ADMIN.PROVIDER.LOGIN_VISIBILITY_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182272596
     */
    PROVIDER_LOGIN_VISIBILITY_HELPER = 'ADMIN.PROVIDER.LOGIN_VISIBILITY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182274487
     */
    PROVIDER_LOGIN_VISIBILITY_WEB = 'ADMIN.PROVIDER.LOGIN_VISIBILITY_WEB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182274516
     */
    PROVIDER_LOGIN_VISIBILITY_MOBILE = 'ADMIN.PROVIDER.LOGIN_VISIBILITY_MOBILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182274635
     */
    PROVIDER_LOGIN_VISIBILITY_WEBANDMOBILE = 'ADMIN.PROVIDER.LOGIN_VISIBILITY_WEBANDMOBILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660319
     */
    PROVIDER_SELECT_VALUE = 'GLOBAL.INPUT.SELECT_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497879782
     */
    MOBILE_OKTA_CRED_SDK = 'RESELLER_CONSOLE.MOBILE_OKTA_CRED_SDK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497880819
     */
    MOBILE_OKTA_CLIENT_ID_SDK = 'RESELLER_CONSOLE.MOBILE_OKTA_CLIENT_ID_SDK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497880827
     */
    MOBILE_OKTA_CLIENT_ID_SDK_HELPER = 'RESELLER_CONSOLE.MOBILE_OKTA_CLIENT_ID_SDK_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452312
     */
    REGISTRATION_LINK = 'ADMIN_RESELLER_CONSOLE_REGISTRATION_LINK',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452314
     */
    REGISTRATION_LINK_HELP = 'ADMIN_RESELLER_CONSOLE_REGISTRATION_LINK_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452299
     */
    CUSTOMER_SETTINGS = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_SETTINGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452288
     */
    CUSTOMER_HTTPS_ONLY_HELP = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_HTTPS_ONLY_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452287
     */
    CUSTOMER_HTTPS_ONLY = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_HTTPS_ONLY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452294
     */
    CUSTOMER_NAME = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_NAME',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452281
     */
    CUSTOMER_DOMAIN = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_DOMAIN',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451763
     */
    FILTER_STATUS = 'ADMIN_FILTER_STATUS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452290
     */
    CUSTOMER_IS_RESELLER = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_IS_RESELLER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452308
     */
    CUSTOMER_TRUSTED_TESTER_HELPER = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_TRUSTED_TESTER_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452307
     */
    CUSTOMER_TRUSTED_TESTER = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_TRUSTED_TESTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7391578
     */
    CUSTOMER = 'ADMIN.RESELLER_CONSOLE.CUSTOMER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452289
     */
    CUSTOMER_ID = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32755582
     */
    GOOGLE_CLOUD_IDENTITY_SOURCE_ID = 'ADMIN_RESELLER_CONSOLE_GOOGLE_CLOUD_IDENTITY_SOURCE_ID',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452300
     */
    CUSTOMER_SLUG = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_SLUG',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452282
     */
    CUSTOMER_EXTERNAL_DIRECTORY = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_EXTERNAL_DIRECTORY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452295
     */
    CUSTOMER_NB_USERS = 'ADMIN_RESELLER_CONSOLE_CUSTOMER_NB_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7393178
     */
    EXTERNAL = 'ADMIN.ACCOUNT_TYPE.EXTERNAL',
}
