import React from 'react';
import { bool, func, node, oneOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import isFunction from 'lodash/isFunction';

/**
 * Displays information or allow an action on a compact element.
 * This is the base component for all variations of the chips see https://material.io/design/components/chips.html.
 *
 * @return {Component} The Chip component.
 */
const Chip = ({
    AfterComponent,
    BeforeComponent,
    LabelComponent,
    isActive,
    isDisabled,
    onAfterClick,
    onBeforeClick,
    onClick,
    size,
    theme,
}) => {
    /**
     * Execute the onBeforeClick method passed as a prop but stop propagation to avoid triggering the onClick method.
     *
     * @param {Event} evt The click event on the before element that triggers this method.
     */
    const handleOnBeforeClick = (evt) => {
        if (!evt) {
            return;
        }

        onBeforeClick(evt);
        evt.stopPropagation();
    };

    /**
     * Execute the onAfterClick method passed as a prop but stop propagation to avoid triggering the onClick method.
     *
     * @param {Event} evt The click event on the after element that triggers this method.
     */
    const handleOnAfterClick = (evt) => {
        if (!evt) {
            return;
        }

        onAfterClick(evt);
        evt.stopPropagation();
    };

    const hasAfterClick = isFunction(onAfterClick);
    const hasBeforeClick = isFunction(onBeforeClick);
    const hasOnClick = isFunction(onClick);

    return (
        <div
            className={classNames('lx-chip', `lx-chip--size-${size}`, `lx-chip--theme-${theme}`, {
                'lx-chip--has-after': hasAfterClick,
                'lx-chip--has-before': hasBeforeClick,
                'lx-chip--is-active': isActive,
                'lx-chip--is-clickable': hasOnClick,
                'lx-chip--is-disabled': isDisabled,
                'lx-chip--is-idle': !isActive,
            })}
            role="button"
            tabIndex={isDisabled || !hasOnClick ? -1 : 0}
            onClick={onClick}
            onKeyPress={onClick}
        >
            {BeforeComponent && (
                <button
                    className={classNames('lx-chip__before', {
                        'lx-chip__before--is-clickable': hasBeforeClick,
                    })}
                    type="button"
                    onClick={handleOnBeforeClick}
                >
                    {BeforeComponent}
                </button>
            )}

            <div className="lx-chip__label">{LabelComponent}</div>

            {AfterComponent && (
                <button
                    className={classNames('lx-chip__after', {
                        'lx-chip__after--is-clickable': hasAfterClick,
                    })}
                    type="button"
                    onClick={handleOnAfterClick}
                >
                    {AfterComponent}
                </button>
            )}
        </div>
    );
};

Chip.propTypes = {
    /** A component to be rendered after the main label area. */
    AfterComponent: node,
    /** A component to be rendered before the main label area. */
    BeforeComponent: node,
    /** A component to be rendered within the label area. Displays the placeholder or selected value(s). */
    LabelComponent: oneOfType([func, node, string]).isRequired,
    /** Indicates if the chip is currently in an active state or not. */
    isActive: bool,
    /** Indicates if the chip is currently disabled or not. */
    isDisabled: bool,
    /** A function to be executed when the after element is clicked. */
    onAfterClick: func,
    /** A function to be executed when the before element is clicked. */
    onBeforeClick: func,
    /** A function to be executed when the chip is clicked. */
    onClick: func,
    /** The size of the chip. */
    size: oneOf(['s', 'm']),
    /** The theme to apply to the component. Can be either 'light' or 'dark'. */
    theme: oneOf(['light', 'dark']),
};

Chip.defaultProps = {
    AfterComponent: null,
    BeforeComponent: null,
    isActive: false,
    isDisabled: false,
    onAfterClick: null,
    onBeforeClick: null,
    onClick: null,
    size: 'm',
    theme: 'light',
};

export { Chip };
