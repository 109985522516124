import BaseApi, { BaseApiPromise } from '@lumapps/base-api';
import { GetBaseUrl } from '@lumapps/learning-utils/getBaseUrl';

import { MAX_TRAINING_ENTRIES_PER_QUERY } from '../constants';
import { LearningPaginatedResponse, Training, TrainingPaginatedQuery, TrainingUrlData } from '../types';

/**
 * trainings API.
 */
const trainingsApi = (baseURL: string) =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'trainings',
        baseURL,
    });

/**
 * catalog parametrized APIs
 * - `catalog/v2/trainings allows to retrieve in-progress or recommended trainings
 */
const paths = {
    recommended: '/catalog/v2/trainings/recommended',
    current: '/catalog/v2/trainings/in-progress',
    training: (identifier: string) => `catalog/v2/trainings/${identifier}/launch-url`,
};

export const getCurrentTrainings = ({
    language,
    offset = 0,
    limit = MAX_TRAINING_ENTRIES_PER_QUERY + 1,
    learningAlphaEnabled,
}: TrainingPaginatedQuery): BaseApiPromise<LearningPaginatedResponse<Training>> => {
    const baseURL = GetBaseUrl(learningAlphaEnabled);

    return trainingsApi(baseURL).get<LearningPaginatedResponse<Training>>(paths.current, {
        params: { language, offset, limit },
    });
};

export const getRecommendedTrainings = ({
    language,
    offset = 0,
    limit = MAX_TRAINING_ENTRIES_PER_QUERY + 1,
    learningAlphaEnabled,
}: TrainingPaginatedQuery): BaseApiPromise<LearningPaginatedResponse<Training>> => {
    const baseURL = GetBaseUrl(learningAlphaEnabled);

    return trainingsApi(baseURL).get<LearningPaginatedResponse<Training>>(paths.recommended, {
        params: { language, offset, limit },
    });
};

export const getTrainingUrl = (identifier: string, learningAlphaEnabled: boolean): BaseApiPromise<TrainingUrlData> => {
    const baseURL = GetBaseUrl(learningAlphaEnabled);

    return trainingsApi(baseURL).get<TrainingUrlData>(paths.training(identifier));
};

export { trainingsApi, paths };
