import { getAskAiAdminPath } from '@lumapps/ask-ai/utils';
import { AppId } from '@lumapps/router/constants';
import { Route } from '@lumapps/router/types';

import { AskAiConfiguration } from './types';

/**
 * Ask Ai Configurations Route
 */
export const askAiConfigurationsAdminRoute: Route = {
    path: getAskAiAdminPath('configurations'),
    legacyId: 'app.admin.ai-search-configurations',
    appId: AppId.backOffice,
};

export const askAiConfigurationsAdmin = (): Route => askAiConfigurationsAdminRoute;

/** Edit */
export const askAiEditConfigurationRoute: Route = {
    ...askAiConfigurationsAdminRoute,
    path: `${getAskAiAdminPath('configurations')}/edit/:id`,
};

export const askAiEditConfiguration = (configuration: Pick<AskAiConfiguration, 'id'>): Route => ({
    ...askAiEditConfigurationRoute,
    params: {
        id: configuration.id,
    },
});

/** Create */
export const askAiCreateConfigurationRoute: Route = {
    ...askAiConfigurationsAdminRoute,
    path: `${getAskAiAdminPath('configurations')}/create`,
};
export const askAiCreateConfiguration = (): Route => ({
    ...askAiEditConfigurationRoute,
});
