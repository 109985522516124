import React from 'react';

import { classnames, margin, useClassnames } from '@lumapps/classnames';
import { useImageLightbox, ImageLightbox } from '@lumapps/lumx-images/components/ImageLightbox';
import { Orientation, Thumbnail, FlexBox, FlexBoxProps, ThumbnailProps, Size, AspectRatio } from '@lumapps/lumx/react';

import './index.scss';

export interface ImageWallProps {
    /** List of images */
    images: ThumbnailProps[];
    /** Any other FlexBox props you want to pass */
    otherProps?: Partial<FlexBoxProps>;
    /** If component is editable */
    isEditable?: boolean;
    /** Whether is loading */
    isLoading?: boolean;
}

const CLASSNAME = 'image-wall';

/** Component for display images in mode image wall masonry
 *
 * @param ImageWallProps
 * @returns ImageWall
 */
export const ImageWall: React.FC<ImageWallProps> = ({ images, isEditable = false, isLoading, ...otherProps }) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { getTriggerProps, imageLightboxProps } = useImageLightbox(images);
    const getContextTriggerProps = React.useCallback(
        (activeImageIndex) => {
            if (isEditable) {
                return undefined;
            }
            return getTriggerProps({ activeImageIndex });
        },
        [isEditable, getTriggerProps],
    );

    const renderWall = () => {
        const rows = [];
        const itemsInRow = 3;
        const itemSingle = images.length === 1;

        for (let i = 0; i < images.length; i += itemsInRow) {
            const listItems = images.slice(i, i + itemsInRow).map((image, index) => (
                <Thumbnail
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    {...image}
                    {...getContextTriggerProps(index + i)}
                    className={itemSingle ? element('item-single') : element('item', `${index + i}`)}
                    tabIndex={isEditable ? -1 : 0}
                    isLoading={isLoading}
                    aspectRatio={itemSingle ? AspectRatio.wide : undefined}
                />
            ));
            rows.push(
                <FlexBox
                    orientation={Orientation.horizontal}
                    className={classnames(
                        element('items', { 'item-single-in-row': listItems.length === 1 }, margin('bottom', Size.big)),
                    )}
                    gap={Size.big}
                    key={i}
                    {...otherProps}
                >
                    {listItems}
                </FlexBox>,
            );
        }

        return rows;
    };

    return (
        <div className={block()} contentEditable={false}>
            {!isEditable && <ImageLightbox {...imageLightboxProps} />}

            {renderWall()}
        </div>
    );
};
