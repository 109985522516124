import keys from 'lodash/keys';

import { getPrefixedFacet, isArrayFacet, convertArrayParameterToValues } from '../constants';
import { FacetFilter, FacetOptions } from '../types';

export const getCurrentFacets = (facetParams: Record<string, string>, facetToIgnore?: FacetFilter) => {
    const facets: FacetOptions[] = [];

    /**
     * We go through the current facets and we add them to the list of
     * facets to apply. We avoid the facet that changed so we can later on determine
     * whether we need to add it or not.
     */
    keys(facetParams).forEach((key) => {
        if (!facetToIgnore || (facetToIgnore && getPrefixedFacet(facetToIgnore.id) !== key)) {
            const rawFacetValue = facetParams[key];
            const facetValue = isArrayFacet(rawFacetValue)
                ? convertArrayParameterToValues(rawFacetValue)
                : rawFacetValue;

            facets.push({
                id: key,
                value: facetValue,
            });
        }
    });

    return facets;
};
