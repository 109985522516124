import { SPACES } from '@lumapps/communities/keys';
import { CommunityPrivacy } from '@lumapps/communities/types';
import { StaticNavigationItem } from '@lumapps/community-navigation/types';
import {
    mdiCalendar,
    mdiFileDocumentOutline,
    mdiFolderGoogleDrive,
    mdiHome,
    mdiMessageTextOutline,
    mdiNewspaper,
    mdiNewspaperVariantOutline,
    mdiCalendarMonthOutline,
    mdiSchool,
} from '@lumapps/lumx/icons';
import { ManagedStreamResources } from '@lumapps/widget-stream/types';

import { SpaceServices, SpaceViews, SpaceVisibility } from './types';

/**
 * Map from legacy communities privacy/visibility to space visibility
 */
export const spaceToCommunityVisibilityMap: Record<
    SpaceVisibility,
    Exclude<CommunityPrivacy, CommunityPrivacy.secret>
> = {
    [SpaceVisibility.open]: CommunityPrivacy.open,
    [SpaceVisibility.read_only]: CommunityPrivacy.readOnly,
    [SpaceVisibility.private]: CommunityPrivacy.descriptionOnly,
    [SpaceVisibility.secret]: CommunityPrivacy.restricted,
};

/**
 * List of all the Space services associated pages to be shown in the Space navigation.
 * Order is important, entries will be displayed in that order of priority.
 * */
export const SPACE_SERVICES_RECORD: Record<keyof typeof SpaceServices, Omit<StaticNavigationItem<SpaceViews>, 'to'>> = {
    [SpaceServices.homepage]: {
        icon: mdiHome,
        label: SPACES.HOMEPAGE,
        view: SpaceViews.home,
    },
    [SpaceServices.feed]: {
        icon: mdiNewspaperVariantOutline,
        label: SPACES.FEED,
        view: SpaceViews.feed,
    },
    [SpaceServices.posts]: {
        icon: mdiMessageTextOutline,
        label: SPACES.POSTS,
        view: SpaceViews.posts,
    },
    [SpaceServices.article]: {
        icon: mdiNewspaper,
        label: SPACES.ARTICLES,
        view: SpaceViews.articles,
    },
    [SpaceServices.event]: {
        icon: mdiCalendar,
        label: SPACES.EVENTS,
        view: SpaceViews.events,
    },
    [SpaceServices.learning]: {
        icon: mdiSchool,
        label: SPACES.LEARNING,
        view: SpaceViews.learning,
    },
    [SpaceServices.documents]: {
        icon: mdiFileDocumentOutline,
        label: SPACES.MANAGED_MEDIA_LIBRARY,
        view: SpaceViews.documents,
    },
    [SpaceServices.drive]: {
        icon: mdiFolderGoogleDrive,
        label: SPACES.MEDIA,
        view: SpaceViews.drive,
    },
    [SpaceServices.calendar]: {
        icon: mdiCalendarMonthOutline,
        label: SPACES.CALENDAR,
        view: SpaceViews.calendar,
    },
};

/** Array containing all the possible Resources types for a space feed. */
export const SPACE_FEED_RESOURCE_TYPES: ManagedStreamResources[] = ['post', 'article', 'event'];
