import get from 'lodash/get';

import { addBlob } from '@lumapps/lumx-images/components/BlobThumbnail/store';

/////////////////////////////

function FeaturedImageService(
    $rootScope,
    AbstractPicker,
    Config,
    Content,
    Features,
    Media,
    Translation,
    Utils,
    Document,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The media picker id.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.MEDIA_PICKER_ID = 'media-picker-content-thumbnail';

    /////////////////////////////

    /**
     * Services and utilities.
     */
    service.Config = Config;
    service.Content = Content;
    service.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Media picker is closed, add new media uploaded to a local variable.
     */
    function _onMediaPickerClose() {
        const currentContent = Content.getCurrent();
        const translatedMedia = Media.getMediaContent(currentContent.mediaThumbnail, Translation.inputLanguage, true);

        currentContent.thumbnail = get(translatedMedia, 'value');
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add content thumbnail.
     *
     * @param {Object} media The new media uploaded.
     */
    function addContentThumbnail(responses, _, files) {
        const media = responses[0].items[0];

        const currentContent = Content.getCurrent();
        const translatedMedia = Media.getMediaContent(media, Translation.inputLanguage, true);

        currentContent.thumbnail = translatedMedia.value;
        service.featuredImage = translatedMedia.servingUrl;

        if (media.content[0].type === 'image' && (media.source === 'local' || media.source === 'haussmann_media')) {
            // Provide a blob url on featured image upload
            responses.forEach((res, index) => {
                const mediaThumbnail = Document.getImageThumbnailForMedia(res.items[0]);
                const file = files[index];

                addBlob({ ...mediaThumbnail, blobUrl: URL.createObjectURL(file.file) }, true);
            });
        }

        currentContent.mediaThumbnail = media;

        $rootScope.$broadcast('update-content-thumbnail');
        $rootScope.$broadcast('update-featured-image-thumbnail');
    }

    /**
     * Delete content thumbnail.
     */
    function deleteContentThumbnail() {
        Content.getCurrent().mediaThumbnail = undefined;
        Content.getCurrent().thumbnail = undefined;

        $rootScope.$broadcast('delete-content-thumbnail', Content.getCurrent().id);
    }

    /**
     * Open media picker for the content thumbnail.
     */
    function openMediaPicker() {
        Utils.waitForAndExecute(`#${service.MEDIA_PICKER_ID}`, AbstractPicker);
    }

    /////////////////////////////

    service.addContentThumbnail = addContentThumbnail;
    service.deleteContentThumbnail = deleteContentThumbnail;
    service.openMediaPicker = openMediaPicker;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the media picker is closed, add new media uploaded to a local variable.
     *
     * @param {Event}  evt           The original event triggering this method.
     * @param {string} mediaPickerId The id of the closed media picker.
     */
    $rootScope.$on('abstract-picker__close-end', (evt, mediaPickerId) => {
        if (mediaPickerId !== service.MEDIA_PICKER_ID) {
            return;
        }

        _onMediaPickerClose();
    });
}

/////////////////////////////

angular.module('Services').service('FeaturedImage', FeaturedImageService);

export { FeaturedImageService as FeaturedImage };
