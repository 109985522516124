import isEqual from 'lodash/isEqual';
import loFind from 'lodash/find';
import get from 'lodash/get';
import last from 'lodash/last';
import map from 'lodash/map';

/////////////////////////////

function WidgetPostListController(
    $scope,
    Community,
    Config,
    Content,
    ContentPicker,
    InitialSettings,
    Instance,
    Post,
    PostConstant,
    Tag,
    Translation,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The fields and properties to be listed in the response of the community.list endpoint.
     *
     * @type {Object}
     */
    const _COMMUNITIES_PROJECTION = {
        items: {
            title: true,
            uid: true,
        },
    };

    /**
     * Community picker modal identifier - only for basic mode.
     *
     * @type {string}
     */
    let _COMMUNITY_PICKER_ID = 'widget-cp-list-settings-content-picker-';

    /**
     * The fields and properties to be listed in the response of the tags.list endpoint.
     *
     * @type {Object}
     */
    const _TAGS_PROJECTION = {
        items: {
            name: true,
            uid: true,
        },
        kind: true,
    };

    /**
     * The current type of tabs being used in the widget.
     * Possible values are 'tags', 'communities' or undefined if no tabs.
     *
     * @type {string}
     */
    let _currentTabsType;

    /**
     * Whether we have selected only event posts.
     */
    let _isOnlyEvents;
    /**
     * Whether the widget is in pinned only mode or not.
     *
     * @type {boolean}
     */
    let _isPinnedOnly;

    /**
     * Contains the projected fields for the post list request.
     *
     * @type {Array}
     */
    let _projection = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the translation to the 'all' tab and a list of the currently selected tab items (can be communities
     * or tags).
     *
     * @type {Object}
     */
    vm.tabs = {
        all: Translation.translate('ALL'),
        items: [],
    };

    /**
     * The post context, if is displayed in a community or not.
     *
     * @type {boolean}
     */
    vm.isCommunityContext = Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Post = Post;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Handle backward compatibility. This is due to changes in models.
     * Note: it should be removed as time goes by running scripts on the backend.
     */
    function _handleBackwardCompatibility() {
        const properties = get(vm.widget, 'properties');

        if (angular.isUndefinedOrEmpty(properties)) {
            return;
        }

        if (angular.isDefinedAndFilled(properties.maxNumber) && !angular.isNumber(properties.maxNumber)) {
            vm.widget.properties.maxNumber = parseInt(properties.maxNumber, 10);
        }

        // Community used to be an object, it is now an array of community ids.
        if (angular.isDefinedAndFilled(properties.community) && !angular.isArray(properties.community)) {
            vm.widget.properties.community = [properties.community.id];
            vm.widget.properties.displayType = 'pick';
        }

        if (angular.isDefinedAndFilled(properties.truncate) && angular.isUndefinedOrEmpty(properties.linesLimit)) {
            vm.widget.properties.linesLimit = Math.ceil(properties.truncate / 100);

            delete vm.widget.properties.truncate;
        }
    }

    /**
     * Initialize the needed projections according to the parameters of the content list.
     */
    function _initProjections() {
        _projection = {
            items: {
                authorDetails: true,
                alreadyReported: true,
                canDelete: true,
                canEdit: true,
                canMove: true,
                canShare: true,
                content: true,
                customer: true,
                externalKey: true,
                feedKeys: true,
                instance: true,
                isPinned: true,
                liked: true,
                likes: true,
                parentContentDetails: true,
                pinnedInCommunitiesDetails: true,
                postStatus: true,
                postType: true,
                reportStatus: true,
                score: true,
                tagz: true,
                type: true,
                uid: true,
                updateAt: true,
                userVote: true,
                version: true,
                visibleInCommunitiesCount: true,
                visibleInCommunitiesDetails: true,
                files: true,
                images: true,
                links: true,
                relevantComment: true,
            },
        };

        const { properties } = vm.widget;

        if (angular.isDefinedAndFilled(properties.fields)) {
            angular.forEach(properties.fields, (field) => {
                if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name) || !field.enable) {
                    return;
                }

                const fieldName = field.name.toLowerCase();
                switch (fieldName) {
                    case 'comments':
                        _projection.items.authorDetails = true;
                        _projection.items.comments = true;
                        _projection.items.relevantCommentDetails = true;

                        break;

                    case 'content':
                        _projection.items.mentionsDetails = true;
                        _projection.items.mentionsFeedKeys = true;

                        break;

                    case 'dates':
                        _projection.items.eventStartDate = true;
                        _projection.items.eventEndDate = true;

                        break;

                    case 'metadata':
                        _projection.items.authorDetails = true;
                        _projection.items.comments = true;
                        _projection.items.isMerged = true;
                        _projection.items.liked = true;
                        _projection.items.likes = true;
                        _projection.items.merge = {
                            masterPost: true,
                            slavesPosts: true,
                        };
                        _projection.items.postStatusDetails = true;
                        _projection.items.tagsDetails = true;
                        _projection.items.publicationDate = true;

                        break;

                    case 'title':
                        _projection.items.title = true;

                        break;

                    default:
                        break;
                }
            });
        } else {
            _projection.items.authorDetails = true;
            _projection.items.comments = true;
            _projection.items.content = true;
            _projection.items.eventEndDate = true;
            _projection.items.eventStartDate = true;
            _projection.items.files = true;
            _projection.items.images = true;
            _projection.items.links = true;
            _projection.items.mentionsDetails = true;
            _projection.items.mentionsFeedKeys = true;
            _projection.items.postType = true;
            _projection.items.publicationDate = true;
            _projection.items.relevantComment = true;
            _projection.items.relevantCommentDetails = true;
            _projection.items.tagsDetails = true;
            _projection.items.title = true;
        }
    }

    /**
     * Initialize the widget properties and the controller parameters.
     */
    function _initProperties() {
        vm.widget.properties = vm.widget.properties || {};
        const { properties } = vm.widget;

        properties.community = properties.community || [];
        properties.communityTags = properties.communityTags || [];

        // By default, list posts from all communities a user has access to.
        properties.displayType = properties.displayType || 'list';

        if (properties.displayType === 'list') {
            properties.tabsEnabled = false;
        }

        // Refresh tabs type selection.
        if (properties.tabsEnabled) {
            // If we have enough tags but not enough communities.
            if (properties.community.length < 2 && properties.communityTags.length > 1) {
                properties.tabsType = 'tags';
                _currentTabsType = properties.tabsType;
            } else if (properties.communityTags.length < 2 && properties.community.length > 1) {
                properties.tabsType = 'communities';
                _currentTabsType = properties.tabsType;
            }
        }

        if (angular.isUndefinedOrEmpty(properties.viewMode)) {
            properties.viewMode = 'list';
        }

        if (angular.isUndefinedOrEmpty(properties.viewModeVariant)) {
            properties.viewModeVariant = 'group';
        }

        if (angular.isUndefinedOrEmpty(properties.itemsSeparator)) {
            properties.itemsSeparator = true;
        }

        const instanceProperty = properties.instance || [];

        Instance.getSiblings().then((fetchedSiblings) => {
            if ((fetchedSiblings.length > 1 && instanceProperty.length > 1) || properties.isAllInstancesSiblings) {
                properties.isInstanceNameDisplayed = angular.isDefined(properties.isInstanceNameDisplayed)
                    ? properties.isInstanceNameDisplayed
                    : true;
            } else {
                properties.isInstanceNameDisplayed = false;
            }

            properties.instance = angular.isDefinedAndFilled(instanceProperty)
                ? instanceProperty
                : [Instance.getCurrentInstanceId()];
        });

        // Fields manager.
        const defaultPostFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.POST_FIELDS);

        let firstFieldsInit = false;
        if (angular.isUndefinedOrEmpty(properties.fields)) {
            properties.fields = [];
            firstFieldsInit = true;
        } else {
            if (defaultPostFields.find((defaultPostField) => defaultPostField === 'sharedInCommunities')) {
                //check if sharedInCommunities field is in the properties fields
                const findSharedInCommunitiesInFields = _.find(properties.fields, {
                    name: 'sharedInCommunities',
                });
                //if not, the widget post list was created before multiposting enabled by default on plateforms. We add the visibility field manually.
                if (!findSharedInCommunitiesInFields) {
                    properties.fields.push({
                        name: 'sharedInCommunities',
                        enable: true,
                    });
                }
            }
        }
        angular.forEach(defaultPostFields, (defaultPostField) => {
            if (angular.isString(defaultPostField)) {
                defaultPostField = {
                    enable:
                        (defaultPostField === 'attachments' || defaultPostField === 'comments') &&
                        !vm.isCommunityContext
                            ? false
                            : firstFieldsInit,
                    name: defaultPostField,
                };
            }

            const findInDefaultFields = loFind(properties.fields, {
                name: defaultPostField.name,
            });

            if (firstFieldsInit || angular.isUndefinedOrEmpty(findInDefaultFields)) {
                properties.fields.push(defaultPostField);
            }
        });

        const isWidgetDisplayingCurrentCommunity = properties.displayType === 'current';
        const isWidgetFiltered =
            properties.pinnedOnly ||
            properties.withAttachmentOnly ||
            (properties.postType && properties.postType.length > 0);

        if (!isWidgetDisplayingCurrentCommunity || isWidgetFiltered) {
            properties.isContributionFieldDisplayed = false;
        }
    }

    /**
     * List posts.
     *
     * @param {Object} additionalFilters The additional filters to apply to the list.
     */
    function _initList(additionalFilters) {
        const properties = vm.widget.properties || {};
        _isPinnedOnly = get(properties, 'pinnedOnly');

        if (properties.displayType === 'pick' && angular.isUndefinedOrEmpty(properties.community)) {
            vm.widgetListCtrl.updateListItems();

            return;
        }

        _initProjections();

        // Note: properties.community could be an empty array, so check only if defined or not.
        let contentId;
        let tags;

        const currentContent = Content.getCurrent();

        const displayType = properties.displayType || 'list';

        if (displayType === 'current' && currentContent.type === 'community') {
            contentId = angular.isDefinedAndFilled(currentContent.uid) ? [currentContent.uid] : [];
        } else {
            contentId = displayType === 'pick' ? properties.community || [] : [];
            tags = displayType === 'pick' ? properties.communityTags || [] : [];
        }

        if (
            properties.tabsEnabled &&
            angular.isDefinedAndFilled(_currentTabsType) &&
            angular.isDefined(vm.widgetListCtrl.tabSelected) &&
            vm.widgetListCtrl.tabSelected.uid !== 'all'
        ) {
            if (_currentTabsType === 'communities') {
                contentId = [vm.widgetListCtrl.tabSelected.uid];
            } else {
                tags = [vm.widgetListCtrl.tabSelected.uid];
            }
        }

        const params = {
            contentId,
            followingOnly: Boolean(properties.followingOnly),
            // If properties.withAttachmentOnly is false we want the params to be undefined to get posts with and without attachment.
            hasAttachments: properties.withAttachmentOnly ? true : undefined,
            ignore403: true,
            // If properties.pinnedOnly is false we want the params to be undefined so pinned post are listed.
            isPinned: _isPinnedOnly || undefined,
            tags,
        };

        if (displayType === 'list') {
            if (get(properties, 'isAllInstancesSiblings', false)) {
                params.instanceId = undefined;
                // Set currentInstance property to fetch content from all siblings.
                params.currentInstance = Instance.getCurrentInstanceId();
            } else if (angular.isDefinedAndFilled(additionalFilters.instance)) {
                params.instanceId = additionalFilters.instance;
            } else if (angular.isDefinedAndFilled(properties.instance)) {
                params.instanceId = properties.instance;
            } else {
                params.instanceId = [Instance.getCurrentInstanceId()];
            }
        } else {
            params.instanceId = undefined;
        }

        let sortDir;

        if (angular.isNumber(properties.maxNumber)) {
            params.maxResults = properties.maxNumber;
        }

        // Add a smaller page size for communities so it doesn't take ages to load.
        if (vm.isCommunityContext && angular.isUndefinedOrEmpty(params.maxResults)) {
            params.maxResults = 10;
        }

        if (angular.isDefined(properties.listOrderDir)) {
            sortDir = properties.listOrderDir.toLowerCase() === 'asc' ? '' : '-';
        }

        if (angular.isDefinedAndFilled(properties.listOrder)) {
            // If a widget was saved with createdAt when it was still an option. It is not anymore.
            if (properties.listOrder === 'createdAt') {
                properties.listOrder = 'publicationDate';
            }
            if (properties.listOrder === 'publicationDate') {
                params.sortOrder = [`${angular.isDefined(sortDir) ? sortDir : '-'}lastSharedAt`];
            } else if (properties.listOrder === 'updatedAt') {
                params.sortOrder = [`${angular.isDefined(sortDir) ? sortDir : '-'}lastUpdatedOrSharedAt`];
            } else {
                params.sortOrder = [`${angular.isDefined(sortDir) ? sortDir : ''}${properties.listOrder}`];
            }
        } else {
            params.sortOrder = [`${angular.isDefined(sortDir) ? sortDir : '-'}lastSharedAt`];
        }

        // Additional filters, most likely coming from the widget content filter.
        if (angular.isDefinedAndFilled(additionalFilters)) {
            const mergeFlags = Post.getRelatedStatusFlags(get(additionalFilters, 'isMerged.value'));
            const reportStatus = additionalFilters.reportStatus === true ? Post.REPORT_STATUSES.REPORTED : undefined;

            angular.extend(params, {
                author: get(additionalFilters.author, 'email'),
                hasRelevant: get(additionalFilters.hasRelevant, 'value'),
                isMergePending: mergeFlags[1],
                isMerged: mergeFlags[0],
                postStatus: map(additionalFilters.postStatus, 'uuid'),
                postType: additionalFilters.postType,
                query: additionalFilters.query,
                reportStatus,
                tags: angular.isUndefinedOrEmpty(additionalFilters.communityTags)
                    ? tags
                    : map(additionalFilters.communityTags, 'uid'),
            });
        }

        if (angular.isDefinedAndFilled(properties.postType) && angular.isUndefinedOrEmpty(params.postType)) {
            params.postType = properties.postType;
        }

        if (displayType === 'current' && angular.isUndefinedOrEmpty(params.contentId)) {
            vm.widgetListCtrl.updateListItems();

            return;
        }

        // Specific case: if we list event type only.
        if (_isOnlyEvents) {
            const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SS');

            switch (properties.eventFilter) {
                case PostConstant.EVENT_FILTERS.PAST_EVENTS:
                    params.eventEndBefore = now;

                    break;

                case PostConstant.EVENT_FILTERS.ONGOING_EVENTS:
                    params.eventStartBefore = now;
                    params.eventEndAfter = now;

                    break;

                case PostConstant.EVENT_FILTERS.FUTURE_EVENTS:
                    params.eventStartAfter = now;

                    break;

                default:
                    break;
            }
        }

        // Todo [Arnaud]: improve this and use some cacheFilterize in most cases?
        Post.filterize(
            params,
            () => {
                // Then process as before.
                // eslint-disable-next-line prefer-rest-params
                vm.widgetListCtrl.updateListItems.apply(null, arguments);
            },
            vm.widgetListCtrl.updateListItems,
            vm.widgetListCtrl.getListKey(),
            _projection,
        );
    }

    /**
     * Init tabs type (tags or communities).
     */
    function _computeTabsNames() {
        const { properties } = vm.widget;

        if (!properties.tabsEnabled) {
            return;
        }

        const listKey = vm.widgetListCtrl.getListKey();

        if (
            properties.tabsType === 'tags' &&
            angular.isDefined(properties.communityTags) &&
            properties.communityTags.length > 1
        ) {
            const tagsQueryParams = {
                ids: properties.communityTags,
                instance: Instance.getCurrentInstanceId(),
                kind: 'community',
            };

            // Don't bother fetching again if we want exactly the same tabs we already have.
            if (
                _currentTabsType === properties.tabsType &&
                angular.equals(map(vm.widgetListCtrl.tabs.items, 'uid'), properties.communityTags)
            ) {
                vm.widgetListCtrl.selectTab(
                    properties.allTabEnabled ? undefined : get(vm.widgetListCtrl.tabs.items, '[0]'),
                    true,
                );

                return;
            }

            Utils.empty(vm.widgetListCtrl.tabs.items);
            _currentTabsType = properties.tabsType;

            Tag.filterize(
                tagsQueryParams,
                () => {
                    const tags = Tag.getList(undefined, undefined, listKey);
                    let firstTab;

                    if (angular.isUndefinedOrEmpty(tags)) {
                        return;
                    }

                    angular.forEach(properties.communityTags, (communityTag) => {
                        const tag = loFind(tags, {
                            uid: communityTag,
                        });

                        vm.widgetListCtrl.tabs.items.push({
                            name: Translation.translate(tag.name),
                            uid: tag.uid,
                        });

                        if (angular.isUndefined(firstTab)) {
                            firstTab = angular.fastCopy(last(vm.widgetListCtrl.tabs.items));
                        }
                    });

                    vm.widgetListCtrl.selectTab(properties.allTabEnabled ? undefined : firstTab, true);
                },
                undefined,
                listKey,
                _TAGS_PROJECTION,
            );
        } else if (
            properties.tabsType === 'communities' &&
            angular.isDefined(properties.community) &&
            properties.community.length > 1
        ) {
            const communitiesQueryParams = {
                followingOnly: Boolean(properties.followingOnly),
                ids: properties.community,
            };

            // Don't bother fetching again if we want exactly the same tabs we already have.
            if (
                _currentTabsType === properties.tabsType &&
                angular.equals(map(vm.widgetListCtrl.tabs.items, 'uid'), properties.community)
            ) {
                vm.widgetListCtrl.selectTab(
                    properties.allTabEnabled ? undefined : get(vm.widgetListCtrl.tabs.items, '[0]'),
                    true,
                );

                return;
            }

            Utils.empty(vm.widgetListCtrl.tabs.items);
            _currentTabsType = properties.tabsType;

            // Set vm.widgetListCtrl.tabs.items with communities id and title.
            Community.filterize(
                communitiesQueryParams,
                () => {
                    const communities = Community.displayList(listKey);
                    let firstTab;

                    if (angular.isUndefinedOrEmpty(communities)) {
                        return;
                    }

                    angular.forEach(communities, (community) => {
                        if (angular.isUndefinedOrEmpty(community) || !Translation.hasTranslations(community.title)) {
                            return;
                        }

                        vm.widgetListCtrl.tabs.items.push({
                            name: Translation.translate(community.title),
                            uid: community.uid,
                        });

                        if (angular.isUndefined(firstTab)) {
                            firstTab = angular.fastCopy(last(vm.widgetListCtrl.tabs.items));
                        }
                    });

                    vm.widgetListCtrl.selectTab(properties.allTabEnabled ? undefined : firstTab, true);
                },
                undefined,
                listKey,
                _COMMUNITIES_PROJECTION,
            );
        } else {
            _currentTabsType = undefined;
        }
    }

    /**
     * Open community picker (only when widget selection type is pick).
     */
    function _openCommunityPicker() {
        if (
            vm.widget.properties.displayType !== vm.widgetListCtrl.SELECTION_TYPES.pick ||
            Content.getViewMode() !== 'basic' ||
            Content.getAction() === 'get'
        ) {
            return;
        }

        Utils.waitForAndExecute(`#${_COMMUNITY_PICKER_ID}`, ContentPicker);
    }

    /**
     * Set default sort order values, according with the selected post types.
     */
    function _setDefaultSortOrder() {
        const { properties } = vm.widget;

        _isOnlyEvents = isEqual(properties.postType, [InitialSettings.POST_TYPES.EVENT]);

        if (
            !_isOnlyEvents &&
            (angular.isUndefinedOrEmpty([properties.listOrder, properties.listOrderDir], 'every') ||
                get(properties, 'listOrder', '').startsWith('event'))
        ) {
            properties.listOrder = 'publicationDate';
            properties.listOrderDir = 'desc';
        }
    }

    /**
     * Indicates if the tabs should be displayed in the widget or not.
     *
     * @return {boolean} Whether the tabs should be displayed or not.
     */
    function _shouldDisplayListTabs() {
        const { properties } = vm.widget;

        return (
            (angular.isDefined(properties.communityTags) && properties.communityTags.length > 1) ||
            (angular.isDefined(properties.community) && properties.community.length > 1)
        );
    }

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When a post is deleted, splice the post list.
     *
     * @param {Event}  evt     The event triggering this function.
     * @param {string} postUid The deleted post identifier.
     */
    $scope.$on('community__post-deleted', (evt, postUid) => {
        // This test allows the pinned post list of a community to also remove the post which has been deleted.
        if (vm.isCommunityContext && !_isPinnedOnly) {
            return;
        }

        Utils.reject(vm.widgetListCtrl.items, {
            uid: postUid,
        });

        vm.widgetListCtrl.updateListItems();
        $scope.$apply();
    });

    /**
     * When a post is moved, splice the post list.
     *
     * @param {Event}  evt      The event triggering this function.
     * @param {string} response The moved post data.
     */
    $scope.$on('community__post-moved', (evt, response) => {
        // This test allows the pinned post list of a community to also remove the post which has been deleted.
        if (vm.isCommunityContext && !_isPinnedOnly) {
            return;
        }

        Utils.reject(vm.widgetListCtrl.items, {
            uid: response.uid,
        });

        vm.widgetListCtrl.updateListItems();
        $scope.$apply();
    });

    /**
     * When asked to refresh the posts, re-initialize the list.
     * The list will be re-initialized:
     *     - if the widget is in 'current community' mode, the current content is a community and the asked community
     *       is the same as the current one;
     *     - or if the widget is in pick mode and the asked community is in the list of picked communities;
     *     - and if we ask to refresh all post or only pinned post and the post list only pinned posts.
     *
     * @param {Event}   evt     The event triggering this method.
     * @param {string}  listKey The list key of the post list widget we want to refresh.
     * @param {boolean} hard    Indicates if we want to hard refresh the list (reload the list from the back).
     */
    $scope.$on('refresh-posts', (evt, listKey, hard) => {
        if (vm.widgetListCtrl.getListKey() !== listKey) {
            return;
        }

        if (hard) {
            vm.widgetListCtrl.init(false);
        } else {
            vm.widgetListCtrl.updateListItems();
        }
    });

    /**
     * When the settings of the post list widget are changed, re-initialize this widget.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the post list widget whose settings are changed.
     */
    $scope.$on('widget-post-list-settings-update-selected-types', (evt, widgetUuid) => {
        if (vm.widget.uuid !== widgetUuid) {
            return;
        }

        vm.init();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     *
     * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
     */
    vm.init = function init(saveListKey) {
        if (saveListKey) {
            _COMMUNITY_PICKER_ID += vm.widget.uuid;
        }

        const currentContent = Content.getCurrent();

        const properties = vm.widget.properties || {};
        _isPinnedOnly = get(properties, 'pinnedOnly');

        if (properties.displayType === 'current' && currentContent.type === 'community' && _isPinnedOnly) {
            vm.widgetListCtrl.widgetCtrl.setListKey(`community-pinned-${currentContent.uid || ''}-${vm.widget.uuid}`);
        }

        // Default sort order.
        _setDefaultSortOrder();

        // Events filters init.
        if (angular.isUndefinedOrEmpty(properties.eventFilter)) {
            properties.eventFilter = PostConstant.EVENT_FILTERS.ALL_EVENTS;
        }

        vm.widgetListCtrl.init(saveListKey);
    };

    /**
     * Set parent controller.
     *
     * @param {Object} widgetListCtrl The parent controller object.
     */
    this.setParentController = (widgetListCtrl) => {
        vm.widgetListCtrl = widgetListCtrl;

        // If it's the main post list widget of a community.
        // Set same listkey so post append to the right list.
        if (vm.isCommunityContext && vm.widgetListCtrl.widget.isMainWidget) {
            vm.widgetListCtrl.setListKey(`community-${Content.getCurrent().uid}`);
        }

        vm.widgetListCtrl.widgetListChildCtrl = {
            computeTabsNames: _computeTabsNames,
            handleBackwardCompatibility: _handleBackwardCompatibility,
            initList: _initList,
            initProperties: _initProperties,
            onWidgetListClick: _openCommunityPicker,
            shouldDisplayListTabs: _shouldDisplayListTabs,
        };

        vm.init(true);
    };

    this.isLoading = () => {
        return vm.widgetListCtrl.service.isCallInProgress(vm.widgetListCtrl.getListKey());
    };
}

/////////////////////////////

/**
 * The post list widget.
 * Displays a list of posts and in a list or tabs.
 *
 * @param {Object} widget The widget configuration object.
 */

function WidgetPostListDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc-except/require-jsdoc
    function WidgetPostListLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetPostListController,
        controllerAs: 'vm',
        link: WidgetPostListLink,
        replace: true,
        require: ['lsWidgetPostList', '^widgetList'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-post-list/views/widget-post-list.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('lsWidgetPostList', WidgetPostListDirective);

/////////////////////////////

export { WidgetPostListDirective };
