import React from 'react';

import { ArticlePickerDialog } from '@lumapps/article-pickers/components/ArticlePickerDialog';
import { Article } from '@lumapps/articles/types';
import { Community } from '@lumapps/communities/types';
import { CommunityPickerDialog } from '@lumapps/community-pickers/components/CommunityPickerDialog';
import { useCommunityListPicker } from '@lumapps/community-pickers/hooks/useCommunityListPicker';
import { ContentPickerDialog } from '@lumapps/content-pickers/components/ContentPickerDialog';
import { Content } from '@lumapps/contents/types';
import { fetchCommunityParams, fetchContentParams } from '@lumapps/journeys/constants';
import { currentLanguageSelector } from '@lumapps/languages';
import { TrainingPickerDialog } from '@lumapps/learning-pickers/components/TrainingPickerDialog';
import { Training } from '@lumapps/learning-trainings/types';
import { InstalledExtensionAPIProps } from '@lumapps/marketplace/legacy-types';
import { MicroAppPickerDialog } from '@lumapps/micro-app-pickers/components/MicroAppPickerDialog';
import { PlaylistPickerDialog } from '@lumapps/play-pickers/components/PlaylistPickerDialog';
import { VideoPickerDialog } from '@lumapps/play-pickers/components/VideoPickerDialog';
import { Playlist } from '@lumapps/play-playlists/types';
import { Video } from '@lumapps/play/api/types';
import { PostPickerDialog } from '@lumapps/post-pickers/components/PostPickerDialog';
import { Post } from '@lumapps/posts/types';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../../../constants';
import { LinkPreviewAttributes } from '../../../types';
import {
    mapPickerArticleToResourcePreview,
    mapPickerCommunityToResourcePreview,
    mapPickerContentToResourcePreview,
    mapPickerLearningToResourcePreview,
    mapPickerMicroAppToResourcePreview,
    mapPickerPlaylistToResourcePreview,
    mapPickerPostToResourcePreview,
    mapPickerVideoToResourcePreview,
} from '../../../utils/mapPickers';

export interface ReplaceResourcePreviewDialogProps {
    /** whether the dialog is open or not. */
    isOpen: boolean;
    /** callback to be executed when the dialog is closed */
    onClose: () => void;
    /** callback to be executed once the preview has been changed */
    onChange: (preview: LinkPreviewAttributes) => void;
    /** ref of parent element to make keyboard navigation easy */
    parentElement: React.RefObject<any>;
    /** resource preview type */
    resourceType: LINK_PREVIEW_RESOURCE_TYPES | undefined;
}

/**
 * Opens a resource corresponding dialog when clicking on replace button, to pick a new resource
 * @param ReplaceResourcePreviewDialogProps
 * @returns ReplaceResourcePreviewDialog
 */
export const ReplaceResourcePreviewDialog: React.FC<ReplaceResourcePreviewDialogProps> = ({
    isOpen,
    onClose,
    onChange,
    parentElement,
    resourceType,
}) => {
    const t = useTranslate();
    const { translateObject } = t;

    const currentLanguage = useSelector(currentLanguageSelector);

    return (
        <>
            <ContentPickerDialog
                parentElement={parentElement}
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.CONTENT}
                onCancel={() => onClose()}
                onConfirm={(contents: Content[]) => {
                    if (contents.length) {
                        onChange(mapPickerContentToResourcePreview(contents[0], translateObject, currentLanguage));
                    }
                    onClose();
                }}
                fetchParamsOverrides={fetchContentParams}
            />

            <TrainingPickerDialog
                isOpen={
                    isOpen &&
                    (resourceType === LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH ||
                        resourceType === LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE)
                }
                onCancel={() => onClose()}
                onConfirm={(learning: Training[]) => {
                    if (learning.length) {
                        onChange(mapPickerLearningToResourcePreview(learning[0]));
                    }
                    onClose();
                }}
            />

            <CommunityPickerDialog
                parentElement={parentElement}
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY}
                onCancel={() => onClose()}
                onConfirm={(communities: Community[]) => {
                    if (communities.length) {
                        onChange(mapPickerCommunityToResourcePreview(communities[0], translateObject, currentLanguage));
                    }
                    onClose();
                }}
                fetchParams={fetchCommunityParams}
                communityPickerHook={useCommunityListPicker}
            />

            <VideoPickerDialog
                parentElement={parentElement}
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO}
                onSelectionCancel={() => onClose()}
                onSelectionConfirm={(videos?: Video[]) => {
                    if (videos?.length) {
                        onChange(mapPickerVideoToResourcePreview(videos[0], translateObject, currentLanguage));
                    }
                    onClose();
                }}
            />

            <PlaylistPickerDialog
                parentElement={parentElement}
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST}
                onSelectionCancel={() => onClose()}
                onSelectionConfirm={(playlists?: Playlist[]) => {
                    if (playlists?.length) {
                        onChange(mapPickerPlaylistToResourcePreview(playlists[0], translateObject, currentLanguage));
                    }
                    onClose();
                }}
            />

            <ArticlePickerDialog
                parentElement={parentElement}
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.ARTICLE}
                onCancel={() => onClose()}
                onConfirm={(articles: Article[]) => {
                    if (articles.length) {
                        onChange(mapPickerArticleToResourcePreview(articles[0], translateObject, currentLanguage));
                    }
                    onClose();
                }}
                context="platform"
            />

            <MicroAppPickerDialog
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP}
                onCancel={() => onClose()}
                onConfirm={(microApps: InstalledExtensionAPIProps[]) => {
                    if (microApps.length) {
                        onChange(mapPickerMicroAppToResourcePreview(microApps[0], translateObject, currentLanguage));
                    }
                    onClose();
                }}
            />

            <PostPickerDialog
                isOpen={isOpen && resourceType === LINK_PREVIEW_RESOURCE_TYPES.POST}
                onlyUseCurrentInstance
                onCancel={() => onClose()}
                onConfirm={(posts: Post[]) => {
                    if (posts.length) {
                        onChange(mapPickerPostToResourcePreview(posts[0], t, currentLanguage));
                    }
                    onClose();
                }}
                params={{ excerptFormat: 'PLAIN_TEXT' }}
            />
        </>
    );
};
