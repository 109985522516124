import BaseApi from '@lumapps/base-api';
import { User } from '@lumapps/user/types';

import { Customer } from '../types';

/**
 * Base API for the /customer service.
 */
export const customerApi = new BaseApi({ path: 'customer' });

/**
 * Base API for the /customer service.
 */
export const customerSharedApi = new BaseApi({ useShared: true });

export interface GetCustomerParams {
    /** customer id */
    id: string;
    /** list of fields (separated by a comma) */
    fields?: string;
}

/**
 * Fetches a specific customer by id.
 * @param params GetCustomerParams
 * @returns Promise<Customer>
 */
export const getCustomer = (params: GetCustomerParams) => customerApi.get<Customer>('get', { params });

/**
 * Saves a given customer.
 * @param params Customer
 * @returns Promise<Customer>
 */
export const saveCustomer = (customer: Customer) => customerApi.post('save', customer);

export interface GetCustomerCrossCells {
    /** customer id */
    id: string;
}

/**
 * Get the customer via admin endpoint
 * This call is cross cells
 *
 * @param {string} id - Can be a customer id, a slug or a host
 * @returns {Promise<Customer>}
 */
export const getCustomerCrossCells = (params: Pick<GetCustomerParams, 'id'>) => {
    const urlParams = { params: { organizationIdentifier: params.id } };
    return customerSharedApi.get<Customer>('/services/organization/admin/organizations', urlParams);
};

/**
 * Saves a given set of properties for the current customer.
 * @param properties
 * @returns Promise<Customer>
 */
export const saveCustomerProperties = (properties: Customer['properties']) =>
    customerApi.post<Customer>('/properties/save', { properties });

export interface GetAdminListParams {
    /** cursor for pagination */
    cursor?: string;
    /** specific instance id */
    instanceId: string;
    /** total results to retrieve */
    maxResults?: number;
    /** whether there are more results or not */
    more?: boolean;
    /** sort field for the results */
    sortOrder?: string;
    /** status */
    status?: string;
}

export interface GetAdminListResponse {
    /** cursor for pagination */
    cursor?: string;
    /** whether there are more admins to retrieve or not */
    more?: boolean;
    /** list of admins (emails) */
    items: string[];
}

/**
 * Retrieves a list of admins for a given instance and customer.
 * @param GetAdminListParams
 * @returns Promise<GetAdminListResponse>
 */
export const getAdminList = ({
    instanceId,
    maxResults = 30,
    more,
    sortOrder = 'email',
    status = 'enabled',
    cursor,
}: GetAdminListParams) => {
    return customerApi.get<GetAdminListResponse>('admin/list', {
        params: {
            maxResults,
            more,
            sortOrder,
            status,
            cursor,
            uid: instanceId,
        },
    });
};

/**
 * Adds a given user as a global admin of the current customer
 * @param selectedUser
 * @returns Promise
 */
export const addGlobalAdmin = (selectedUser: User) => {
    return customerApi.post('admin/add', {
        email: selectedUser.email,
    });
};

export interface DeleteGlobalAdminParams {
    /** global admin to delete */
    email: string;
    /** current instance id */
    instanceId: string;
}

/**
 * Deletes a given user as a global admin of the current customer
 * @param DeleteGlobalAdminParams
 * @returns Promise
 */
export const deleteGlobalAdmin = ({ email, instanceId }: DeleteGlobalAdminParams) => {
    return customerApi.delete('admin/delete', {
        params: {
            email,
            uid: instanceId,
        },
    });
};

export interface GetRegistrationLinkParams {
    kind: string;
}

/**
 * Retrieves a registration link for the given customer. Used on the reseller console.
 * @param params GetRegistrationLinkParams
 * @returns Promise
 */
export const getRegistrationLink = (params: GetRegistrationLinkParams) =>
    customerApi.get('registrationLink', { params });
