import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiBellRing, mdiBellOff } from '@lumapps/lumx/icons';
import { ButtonProps, Emphasis, IconButton } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { useHover } from '@lumapps/utils/hooks/useHover';

import { SUBSCRIPTIONS } from '../../keys';

const SCOPE = 'notification';

export const NotificationButton: React.FC<ButtonProps> = ({ isSelected, scope, ...props }) => {
    const { get } = useDataAttributes(scope || SCOPE);
    const { translateKey } = useTranslate();

    const { isHovered, registerElement } = useHover();

    const buttonLabel = translateKey(
        isSelected
            ? SUBSCRIPTIONS.UNSUBSCRIBE_NOTIFICATIONS_HELP_TEXT
            : SUBSCRIPTIONS.SUBSCRIBE_NOTIFICATIONS_HELP_TEXT,
    );

    const icon = React.useMemo(() => {
        if (isSelected) {
            return isHovered ? mdiBellOff : mdiBellRing;
        }
        return isHovered ? mdiBellRing : mdiBellOff;
    }, [isSelected, isHovered]);

    return (
        <IconButton
            label={buttonLabel}
            isSelected={isSelected}
            ref={registerElement}
            emphasis={Emphasis.medium}
            icon={icon}
            {...get({ element: 'button', action: isSelected ? 'disable-notification' : 'enable-notification' })}
            {...props}
        />
    );
};
