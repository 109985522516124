import { isFeatureEnabled } from '@lumapps/features';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

import { BROADCAST_FF, DISTRIBUTE_CONTENT_FF } from '../constants';

export const isBroadcastsEnabled = isFeatureEnabled(BROADCAST_FF);
export const isDistributeContentEnabled = isFeatureEnabled(DISTRIBUTE_CONTENT_FF);
export const canManageCampaigns = isUserAllowed('canManageCampaigns');

/**
 * canAccessBroadcast if:
 * - the broadcast feature flag is activated AND
 * - EITHER the user is platform admin/site admin of the current site
 * - OR the user has the permission canManageCampaigns (isUserAllowed firstly checks if the user is platform admin/site admin.
 * If so, it automatically allows the user to access the feature. Otherwise, it checks if canManageCampaigns is set to true)
 */
export const canAccessBroadcast = createSelector(
    [isBroadcastsEnabled, canManageCampaigns],
    (broadcastsEnabled, canManageCampaigns) => broadcastsEnabled && canManageCampaigns,
);
