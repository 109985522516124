import React, { useMemo } from 'react';

import { classnames } from '@lumapps/classnames';
import { FlexBox, Alignment, Theme, ButtonProps } from '@lumapps/lumx/react';

import { useSubscription, UseSubscriptionType } from '../../hooks/useSubscription';
import { UseSubscriptionStatuses } from '../../types';
import { FollowButton } from '../FollowButton';
import { NotificationButton } from '../NotificationButton';

export interface SubscriptionsActionsProps {
    subscription: UseSubscriptionType;
    className?: string;
    buttonProps?: ButtonProps;
    scope: string;
    theme?: Theme;
}

export const SubscriptionsActions: React.FC<SubscriptionsActionsProps> = ({
    subscription,
    theme = Theme.light,
    className,
    buttonProps,
    scope,
}) => {
    const { status, isSubscribed, isNotified, toggleSubscription, toggleNotification } = useSubscription(subscription);

    const isLoading = useMemo(
        () => [UseSubscriptionStatuses.loading, UseSubscriptionStatuses.changing].includes(status),
        [status],
    );

    return (
        <FlexBox wrap hAlign={Alignment.center} className={className}>
            <FollowButton
                className={classnames({ 'lumx-spacing-margin-right': isSubscribed })}
                isSelected={isSubscribed}
                onClick={toggleSubscription}
                disabled={isLoading}
                theme={theme}
                scope={scope}
                {...buttonProps}
            />
            {isSubscribed && !!toggleNotification && (
                <NotificationButton
                    isSelected={isNotified}
                    onClick={toggleNotification}
                    disabled={isLoading}
                    theme={theme}
                    scope={scope}
                    {...buttonProps}
                />
            )}
        </FlexBox>
    );
};
