import React, { useState, useReducer } from 'react';

import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { currentLanguageSelector } from '@lumapps/languages';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getCatalog } from '../api/index';
import { actions, catalogReducer, initialState } from '../ducks/slice';
import { CategoryData, TrainingData, CatalogData } from '../types';
// hook responsible for data fetching and state management for the catalog
export const useCatalog = () => {
    const [state, dispatch] = useReducer(catalogReducer, initialState);
    const [status, setStatus] = useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const locale = useSelector(currentLanguageSelector);
    const LearningAlphaEnabled = useSelector(isLearningAlphaEnabled);
    function findCategoryByCode(catalog: CatalogData, categoryCode: string): CategoryData | undefined {
        function searchCategory(categories: CategoryData[], targetCode: string): CategoryData | undefined {
            for (const category of categories) {
                if (category.code === targetCode) {
                    return category;
                }
                if (category.children && category.children.length > 0) {
                    const foundInChildren = searchCategory(category.children, targetCode);
                    if (foundInChildren) {
                        return foundInChildren;
                    }
                }
            }

            return undefined;
        }

        if (catalog.categories) {
            return searchCategory(catalog.categories, categoryCode);
        }

        return undefined;
    }
    // Calling backennd API and retrieve all catalog data
    const fetchCatalog = React.useCallback(
        async (categoryCode) => {
            try {
                setStatus(BaseLoadingStatus.loading);
                const catalog = await getCatalog(locale, LearningAlphaEnabled);
                if (isEmpty(catalog.categories) && isEmpty(catalog.trainings)) {
                    setStatus(BaseLoadingStatus.empty);
                } else if (!categoryCode) {
                    dispatch(actions.setInitialCatalog(catalog));
                    setStatus(BaseLoadingStatus.idle);
                } else {
                    const baseCategory: CategoryData | undefined = findCategoryByCode(catalog, categoryCode);
                    if (baseCategory) {
                        const filteredCatalog: CatalogData = {
                            categories: baseCategory.children,
                            trainings: baseCategory.trainings,
                        };

                        dispatch(actions.setInitialCatalog(filteredCatalog));
                        setStatus(BaseLoadingStatus.idle);
                    }
                }
            } catch (error) {
                setStatus(BaseLoadingStatus.error);
            }
        },
        [LearningAlphaEnabled, locale],
    );

    // when someone click the breadcrumb to return to the parent category this will update all necessary states
    const handleReturnToParentCategory = React.useCallback(() => {
        if (state.parentCategoryStack.length > 1) {
            const parentCategory = state.parentCategoryStack[0];
            dispatch(actions.popParentCategoryBreadcrumb());
            dispatch(actions.enterCategory(parentCategory));
        } else {
            dispatch(actions.popParentCategoryBreadcrumb());
            dispatch(actions.setInitialCatalog(state.catalog));
        }
    }, [state.parentCategoryStack, state.catalog]);

    // Update states when a category is selected
    const handleSelectedCategory = React.useCallback(
        (code: string) => {
            const selectedCategory = state.categories.find((category: CategoryData) => category.code === code);
            dispatch(actions.enterCategory(selectedCategory));
            dispatch(actions.pushParentCategoryBreadcrumb(selectedCategory));
        },
        [state.categories],
    );

    // Update states when a search is done
    const handleSearch = React.useCallback(
        (query: string) => {
            // The search is done locally only for demo purpose (M1 learning)
            // It will then be implemented directly in the main Search when all Trainings / Categories will be indexed by the global search
            const cleanQuery = query.trim().toLowerCase();
            if (!cleanQuery || cleanQuery.length === 0) {
                setStatus(BaseLoadingStatus.idle);
                return dispatch(actions.resetSearch());
            }
            let trainings: TrainingData[] = [];

            function filterTrainings(trainings: TrainingData[], query: string): TrainingData[] {
                return trainings.filter((training: TrainingData) => {
                    const code = (training.code || '').trim().toLowerCase();
                    const title = (training.title || '').trim().toLowerCase();
                    return code.includes(query) || title.includes(query);
                });
            }

            function searchTrainingsFromCategory(category: CategoryData) {
                if (category.trainings && category.trainings.length > 0) {
                    trainings = trainings.concat(filterTrainings(category.trainings, cleanQuery));
                }
                if (category.children && category.children.length > 0) {
                    category.children.forEach((subCategory: CategoryData) => {
                        searchTrainingsFromCategory(subCategory);
                    });
                }
            }

            if (state.catalog && state.catalog.categories && state.catalog.categories.length > 0) {
                state.catalog.categories.forEach(searchTrainingsFromCategory);
            }
            if (state.trainings && state.trainings.length > 0) {
                trainings = trainings.concat(filterTrainings(state.trainings, cleanQuery));
            }

            trainings = uniqBy(trainings, 'identifier');
            if (trainings && trainings.length > 0) {
                setStatus(BaseLoadingStatus.filtering);
            } else {
                setStatus(BaseLoadingStatus.emptyFiltered);
            }
            return dispatch(actions.doSearch({ query, results: trainings }));
        },
        [state],
    );

    return {
        status,
        catalog: state.catalog,
        categories: state.categories,
        trainings: state.trainings,
        breadcrumb: state.breadcrumb,
        categoryCounter: state.categoryCounter,
        trainingCounter: state.trainingCounter,
        searchValue: state.searchValue,
        searchTrainingsResults: state.searchTrainingsResults,
        fetchCatalog,
        handleSelectedCategory,
        handleReturnToParentCategory,
        handleSearch,
    };
};
