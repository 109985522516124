import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetInstanceListSettingsController($rootScope, $scope, $timeout, Content, ContentTemplate, Instance,
        InstancePicker, Metadata, Utils, Widget) {
        'ngInject';

        var widgetInstanceListSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The id to add to the list key.
         * Contains either the content id (if any) or a randomly generated UUID.
         *
         * @type {string}
         */
        var _keyId;

        /**
         * The list key for the instances list.
         *
         * @type {string}
         */
        var _listKey = 'widget-';

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the instance picker in the settings sidebar.
         *
         * @type {string}
         * @constant
         */
        widgetInstanceListSettings.PICKER_ID = 'widget-instance-list-picker-';

        /**
         *  The open/close status of the instance picker.
         */
        widgetInstanceListSettings.isPickerOpen = false;

        /**
         * Contains the list of field pickable for oredering the instances list.
         *
         * @type {Array}
         */
        widgetInstanceListSettings.listOrderSelection = [
            'name',
            'createdAt',
        ];

        /**
         * Contains the list of picked instances id.
         *
         * @type {Array}
         */
        widgetInstanceListSettings.instanceList = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetInstanceListSettings.Instance = Instance;
        widgetInstanceListSettings.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        function _initMetadatas() {
            var metadata = Metadata.getRefactoredMetadata(true);
            // Return the plateform metadata.

            return _.filter(metadata, {
                instance: undefined,
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Open the instance picker.
         */
        function openInstancePicker() {
            widgetInstanceListSettings.isPickerOpen = true;
            Utils.waitForAndExecute('#' + widgetInstanceListSettings.PICKER_ID, InstancePicker);
        }

        /**
         * Update the widget each time the settings change.
         */
        function updateWidget() {
            var currentWidget = Widget.getCurrent();

            if (currentWidget.properties.type === 'pick' && currentWidget.isMainWidget) {
                currentWidget.isMainWidget = false;
            }

            var metadata = _.get(currentWidget, 'properties.metadata', {});

            currentWidget.properties.filterRestriction = {
                // eslint-disable-next-line you-dont-need-lodash-underscore/filter
                metadata: _.filter(metadata, function filterMetadata(val) {
                    // The un-setting doesn't remove the element but sets it to undefined...
                    // So cast it to boolean.
                    return Boolean(val);
                }),
            };

            $timeout(function delayUpdateEventBroadcast() {
                $rootScope.$broadcast('widget-instance-list-settings', Widget.getCurrent().uuid);
            });
        }

        /**
         * Define the widget as "Main widget", and remove th isMainWidget attrubute to the other widget.
         */
        function setAsMainWidget() {
            var currentWidget = Widget.getCurrent();

            if (currentWidget.isMainWidget) {
                var _widgetList = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isMainWidget', true);
                angular.forEach(_widgetList, function foreachWidget(widget) {
                    if (widget.uuid !== currentWidget.uuid) {
                        widget.isMainWidget = false;
                    }
                });

                $rootScope.$broadcast('widget-is-main-toogled', currentWidget.uuid, currentWidget.isMainWidget);
            }
        }

        /**
         * Switch widget view mode.
         *
         * @param {string} viewMode The wanted view mode.
         */
        function switchViewMode(viewMode) {
            if (Widget.getCurrent().properties.viewMode === viewMode) {
                return;
            }

            Widget.getCurrent().properties.viewMode = viewMode;
            Widget.getCurrent().properties.viewModeVariant = 'group';

            widgetInstanceListSettings.updateWidget();
        }

        /**
         * Switch widget view mode variant.
         *
         * @param {string} viewModeVariant The wanted view mode variant.
         */
        function switchViewModeVariant(viewModeVariant) {
            if (Widget.getCurrent().properties.viewModeVariant === viewModeVariant) {
                return;
            }

            Widget.getCurrent().properties.viewModeVariant = viewModeVariant;

            widgetInstanceListSettings.updateWidget();
        }

        /////////////////////////////

        widgetInstanceListSettings.openInstancePicker = openInstancePicker;
        widgetInstanceListSettings.updateWidget = updateWidget;
        widgetInstanceListSettings.setAsMainWidget = setAsMainWidget;
        widgetInstanceListSettings.switchViewMode = switchViewMode;
        widgetInstanceListSettings.switchViewModeVariant = switchViewModeVariant;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the instance picker closes, update the widget's picked instance.
         *
         * @param {Event}  evt      The picker close event.
         * @param {string} pickerId The id of the picker beeing close.
         */
        $scope.$on('instance-picker__close-start', function onInstancePickerCloseStart(evt, pickerId) {
            if (widgetInstanceListSettings.PICKER_ID === pickerId) {
                Widget.getCurrent().properties.instancesIds = _.map(widgetInstanceListSettings.instanceList, 'id');
            }

            widgetInstanceListSettings.updateWidget();
        });

        /**
         * When the instance picker closes, redefined the close toogle.
         */
        $scope.$on('instance-picker__close-end', function onInstancePickerCloseEnd() {
            widgetInstanceListSettings.isPickerOpen = false;
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentContent = Content.getCurrent();
            var currentWidget = Widget.getCurrent();

            _keyId = (angular.isDefinedAndFilled(_.get(currentContent, 'id'))) ?
                currentContent.id : generateUUID();
            _listKey += currentWidget.uuid + '-' + _keyId;

            widgetInstanceListSettings.PICKER_ID += currentWidget.uuid;

            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            widgetInstanceListSettings.metadata = _initMetadatas();
            
            if (angular.isUndefinedOrEmpty(properties.type)) {
                properties.type = 'all';
            }

            if (properties.type === 'pick' && angular.isArray(properties.instancesIds) &&
                angular.isDefinedAndFilled(properties.instancesIds)) {
                widgetInstanceListSettings.isCallInProgress = Instance.isCallInProgress(_listKey + '-pick');

                if (!widgetInstanceListSettings.isCallInProgress) {
                    widgetInstanceListSettings.instanceList = Instance.displayList(_listKey + '-pick');

                    return;
                }

                var unregisterWatcher = $scope.$watch(function isInstanceCallInProgressWatcher() {
                    return Instance.isCallInProgress(_listKey + '-pick');
                }, function isInstanceCallInProgressWatch(isCallInProgress) {
                    widgetInstanceListSettings.isCallInProgress = isCallInProgress;

                    if (!widgetInstanceListSettings.isCallInProgress) {
                        widgetInstanceListSettings.instanceList = Instance.displayList(_listKey + '-pick');

                        if (angular.isFunction(unregisterWatcher)) {
                            unregisterWatcher();
                            unregisterWatcher = undefined;
                        }
                    }
                });
            }
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetInstanceListSettingsController',
        WidgetInstanceListSettingsController);
})();
