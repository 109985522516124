import includes from 'lodash/includes';

import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { BackOfficeStore } from '@lumapps/redux/types';

export const featuresSelector = (state: BackOfficeStore): Record<string, any> =>
    state.feature ? state.feature.entities : state.features;

export const enabledFeaturesSelector = createSelector(customerSelector, (customer) => customer?.enabledFeatures);
export const disabledFeaturesSelector = createSelector(customerSelector, (customer) => customer?.excludedFeatures);

export const isFeatureEnabled = (token: string) =>
    createSelector(enabledFeaturesSelector, (enabledFeatures) => {
        return includes(enabledFeatures, token);
    });
