import React, { PureComponent } from 'react';
import { func } from 'prop-types';

import get from 'lodash/get';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import noop from 'lodash/noop';

import * as styleTypes from '../../types';
import { translate as t } from '../../../../translations';
import { AccordionBox, Information } from '../../../ui';
import { withOnChangeProperty } from '../../../../utils';
import { ModeSettings } from '../../settings/ModeSettings';
import { HeightSettings } from '../../settings/HeightSettings';
import { LabelSettings } from '../../settings/LabelSettings';
import { BackgroundSettings } from '../../settings/background/BackgroundSettings';
import { BorderSettings } from '../../settings/BorderSettings';
import { SpacingSettings } from '../../settings/SpacingSettings';

/**
 * Renders an area header/footer style editor inspector.
 */
export class AreaSettings extends PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.areaStyle.isRequired,
        name: styleTypes.areaType.isRequired,
        /**
         * Called when the `value` changes, with `(value, name, payload)`.
         * The following rule is disabled because `onChange` callback is used in
         * `withOnChangeProperty` decorator.
         * */
        // eslint-disable-next-line react/no-unused-prop-types
        onChange: func.isRequired,
        /** Called when toggling between default/hovering style state. */
        onChangeState: func,
        /** Current state style. */
        state: styleTypes.state,
        /** The current area style. */
        value: styleTypes.areaStyle,
    };

    static defaultProps = {
        onChangeState: noop,
        state: 'default',
        value: 'default',
    };

    /**
     * The property to set (widget.properties.style.main.display).
     *
     * @type {string[]}
     * @constant
     * @readonly
     */
    static PROPERTY_PATH = ['main', 'display'];

    /**
     * List of available mods to transfer to `ModeSettings` component.
     *
     * @type {Object[]}
     * @constant
     * @readonly
     */
    static AVAILABLE_MODES = [
        {
            label: 'Block',
            render() {
                return (
                    <div className="widget-style-mode__outside">
                        <div className="widget-style-mode__inside">
                            <div className="widget-style-mode__icon" />
                            <div className="widget-style-mode__label" />
                        </div>
                    </div>
                );
            },
            value: 'block',
        },
        {
            label: 'Inline',
            render() {
                return (
                    <div className="widget-style-mode__outside">
                        <div className="widget-style-mode__inside">
                            <div className="widget-style-mode__icon" />
                            <div className="widget-style-mode__label" />
                        </div>
                    </div>
                );
            },
            value: 'flex',
        },
    ];

    /**
     * Property names to change depending on the area state.
     */
    static NAMES = {
        default: {
            global: null,
            label: 'label',
            main: 'main',
            wrapper: 'wrapper',
        },
        hover: {
            global: 'hover',
            label: ['hover', 'label'],
            main: ['hover', 'main'],
            wrapper: ['hover', 'wrapper'],
        },
    };

    /**
     * States an area can have depending on the user interactions.
     */
    static STATES = [{ icon: 'cursor-default', id: 'default' }, { icon: 'cursor-pointer', id: 'hover' }];

    /**
     * Merges default values with the `default` state value.
     *
     * @param  {Object} defaults The default values.
     * @param  {Object} value    The values of the `default` state.
     * @return {Object} The merged values.
     */
    static mergeDefaults(defaults, value) {
        return merge({}, defaults, omit(value, ['hover']));
    }

    constructor(props) {
        super(props);
        const { mergeDefaults } = this.constructor;
        this.state = {
            defaults: props.defaults,
            state: props.state || 'default',
        };
        withOnChangeProperty(this);

        /**
         * Sets current state to the provided `state`.
         * If `state` is `'hover'`, updates the `defaults`.
         * Calls `props.onChangeState` with `(state)` when done.
         *
         * @param  {string} state The new state name to set.
         * @return {void}   Nothing.
         */
        this.onChangeState = (state) => {
            const { defaults, value, onChangeState } = props;

            this.setState(
                {
                    defaults: state === 'default' ? defaults : mergeDefaults(defaults, value),
                    state,
                },
                () => onChangeState && onChangeState(state),
            );
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.state !== state.state) {
            return {
                state: props.state || 'default',
            };
        }

        return null;
    }

    render() {
        const { value = {}, defaults, name: key } = this.props;
        const { state, defaults: stateDefaults } = this.state;
        const stateValue = state === 'default' ? value : get(value, state, {});
        const display = get(value.main, 'display', 'block');
        const targetName = display === 'block' ? 'main' : 'wrapper';
        const { AVAILABLE_MODES, PROPERTY_PATH, STATES, NAMES } = this.constructor;
        const states = key === 'footer' ? STATES : null;

        return (
            <div className="widget-style-area__settings-wrapper">
                <Information value={t('STYLE.WRAPPER_HELPER')} />
                <AccordionBox label={t('STYLE.MODE')} variant="simple">
                    <ModeSettings
                        current={display}
                        defaults={defaults.main.display}
                        modes={AVAILABLE_MODES}
                        property={PROPERTY_PATH}
                        onChange={this.onChangeProperty}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.HEIGHT')} variant="simple">
                    <HeightSettings
                        defaults={defaults.wrapper}
                        name="wrapper"
                        value={value.wrapper}
                        onChange={this.onChangeProperty}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.LABEL_AND_ICON')} variant="simple">
                    <LabelSettings
                        defaults={stateDefaults}
                        name={NAMES[state].global}
                        state={state}
                        states={states}
                        value={stateValue}
                        onChange={this.onChangeProperty}
                        onChangeState={this.onChangeState}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.SPACINGS')} variant="simple">
                    <SpacingSettings
                        defaults={stateDefaults.wrapper}
                        name={NAMES[state].wrapper}
                        state={state}
                        states={states}
                        value={stateValue.wrapper}
                        onChange={this.onChangeProperty}
                        onChangeState={this.onChangeState}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BORDERS')} variant="simple">
                    <BorderSettings
                        defaults={stateDefaults.wrapper}
                        name={NAMES[state].wrapper}
                        state={state}
                        states={states}
                        value={stateValue.wrapper}
                        onChange={this.onChangeProperty}
                        onChangeState={this.onChangeState}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BACKGROUND')} variant="simple">
                    <BackgroundSettings
                        shadow
                        defaults={stateDefaults[targetName]}
                        name={NAMES[state][targetName]}
                        state={state}
                        states={states}
                        value={stateValue[targetName]}
                        onChange={this.onChangeProperty}
                        onChangeState={this.onChangeState}
                    />
                </AccordionBox>
            </div>
        );
    }
}
