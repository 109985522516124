import { get } from '@lumapps/constants';

const Config = get();
const orgId = Config.customerId;

export const learningAlphaApi = {
    URL: `${Config.haussmannCell}/v2/organizations/${orgId}/learning/centaurus/api`,
};

export const learningApi = {
    URL: `${Config.haussmannCell}/_/api`,
};
/** Feature flag used for under development features */
export const LEARNING_ALPHA_FF = 'learning-alpha';

/**  Feature flag used for learning specific features */
export const LEARNING_FF = 'learning';
