import { ListItemProps, Theme } from '@lumapps/lumx/react';
import { User } from '@lumapps/user/types';
import {
    Block,
    BlockReactions,
    BlockTagReferenceList,
    CommunityInfo,
    NgiCommunityReference,
    LinkAttachment,
    MediaPreview,
    SiteReference,
    UserReference,
} from '@lumapps/widget-base/types';

export interface BlockInappropriate extends Block {
    type: 'BlockInappropriate';
    reportCount: number;
    lastReportedAt: string;
    isReportedByMe: boolean;
}

export interface BlockVotes extends Block {
    type: 'BlockVotes';
    /** The type of the associated resource. */
    resourceType: string;
    /** The id of the associated resource. */
    resourceId: string;
    /** The number of upvotes for the associated resource. */
    upVotes: number;
    /** The number of downvotes for the associated resource. */
    downVotes: number;
    /** The user vote for the associated resource. */
    userVote: string;
    /** Is the user voting */
    isLoading?: boolean;
}

export interface PostShare {
    sharedBy: UserReference;
    sharedAt: string;
    sharedTo: NgiCommunityReference;
    site: Partial<SiteReference>;
    isPinned: boolean;
    canPin?: boolean;
    canUnshare?: boolean;
    isPinStatusDisplayed?: boolean;
}

export type OrderString =
    | 'PUBLICATION_INFO'
    | 'EVENT_DATES'
    | 'CONTENT'
    | 'ATTACHMENTS'
    | 'SHARES'
    | 'TITLE'
    | 'REACTIONS';

export enum PostType {
    DEFAULT = 'DEFAULT',
    EVENT = 'EVENT',
    QUESTION = 'QUESTION',
    IDEA = 'IDEA',
}
export interface ArticlePreview {
    id: string;
    title: string;
    image?: string;
    description?: string;
}

export interface EventPreview {
    id: string;
    title: string;
    image?: string;
    description?: string;
}

export interface PlayVideoPreview {
    id: string;
}

export interface BlockPostIdeaStatus {
    id: string;
    label: string;
}

export interface BlockPost extends Block {
    type: 'BlockPost';
    /** The post id */
    postId: string;
    /** The post's type. */
    postType: PostType;
    /** The post's author */
    author: UserReference;
    /** The community information. */
    community: CommunityInfo;
    /** The site reference */
    site: Partial<SiteReference>;
    /** The post's shares. */
    shares: PostShare[];
    /** The post's reports. */
    reports?: BlockInappropriate;
    /** The post's title. */
    title?: string;
    /** The post's content, in markdown or DITA format. */
    content?: string;
    /** The post's tags. */
    tags?: BlockTagReferenceList;
    /** Information about mentioned users. */
    userMentions?: UserReference[];
    /** Information about the article attached to the post. */
    articlePreview?: ArticlePreview;
    /** Information about the event attached to the post. */
    eventPreview?: EventPreview;
    /** Information about the Play video attached to the post. */
    playVideoPreview?: PlayVideoPreview;
    /** The post's images. */
    images?: MediaPreview[];
    /** The post's files. */
    files?: MediaPreview[];
    /** The post's links. */
    links?: LinkAttachment[];
    /** The post's reactions. */
    reactions?: BlockReactions;
    /** The post's votes */
    votes?: BlockVotes;
    /** For EVENT posts, the post's event start date */
    eventStartsAt?: string;
    /** For EVENT posts, the post's event end date */
    eventEndsAt?: string;
    /** Whether the current user can share the post. */
    canShare?: boolean;
    /** Whether the current user can edit the post. */
    canEdit?: boolean;
    /** Whether the current user can move the post. */
    canMove?: boolean;
    /** Whether the current user can delete the post. */
    canDelete?: boolean;
    /** Whether the post of type answer has a response or not  */
    isAnswered?: boolean;
    /** Order of the fields displayed in the post. */
    order: OrderString[];
    /** The number of lines to show in the preview. */
    visibleLines?: number;
    /** The id of the relevant comment, if any */
    relevantCommentId?: string;
    /** Post idea status */
    ideaStatus?: BlockPostIdeaStatus;
    /** should the fact that the block is pinned be displayed */
    isPinStatusDisplayed?: boolean;
    /** is the block post used in details page and should it expand the comments */
    isDetailed?: boolean;
}

export interface CustomAction {
    // The label of the action, should be translated before.
    label: string;
    // The icon of the action
    icon: string;
    // The function to trigger when the button is clicked.
    onClick(): void;
    // Props passed to the ListItem component
    listItemProps: Partial<ListItemProps> & {
        'data-id': string;
    };
}

export enum HasRelevantComment {
    all = 'all',
    exclude = 'exclude',
    only = 'only',
}

export type Author = Pick<User, 'email' | 'firstName' | 'lastName'>;

export interface PostListFilterType {
    authorEmail: Author[];
    hasRelevantComment: HasRelevantComment[];
    postCategories: string[];
    searchQuery: string;
}

export interface PostListAvailableFilter {
    type: keyof PostListFilterType;
    predefinedValues?: string[];
    defaultValue?: string;
}

export type FilterId = keyof PostListFilterType;

export interface PostListFilterChoice<T> {
    name: string;
    value: T;
}

export interface PostListFilterBlockProps<T = PostListFilterType[FilterId]> {
    /** Theme of the block */
    theme: Theme;
    /** Label of the filter, expected to be already translated */
    label: string;
    /** Displayed name of the filter, can be the label or a string representation of current value(s) */
    name: string;
    /** Whether the filter is loading its choices */
    isLoading?: boolean;
    /** Callback that will be called on filter change */
    onSelect: (filterId: FilterId, selectedValues: T[]) => void;
    /** Choices available for the filter */
    choices?: PostListFilterChoice<T>[];
    /** Choices selected, array of choices values */
    selectedValues?: T[];
    /** Whether the filter as active values (not just selected but actually applied for filtering) */
    isActive: boolean;
    /** Callback to extract a choice name. Note that the result of this callback is expected to be translated */
    getChoiceName: (value: T) => string;
    /** The filter identifier */
    filterId: FilterId;
}

export interface PostListFilterHookProps {
    activeFiltersValues?: Partial<PostListFilterType>;
    filtersValues?: Partial<PostListFilterType>;
    handleValuesChange(filterId: FilterId, values: PostListFilterType[FilterId]): void;
}

export interface URLPostListFiltersType {
    // next 3 might change since the discussion is ongoing about the way to provide user info through the url
    author_email?: string;
    author_firstname?: string;
    author_lastname?: string;
    has_relevant_comment?: boolean;
    post_categories?: string[];
    post_statuses?: string[];
    post_types?: string[];
    search_query?: string;
}

export enum URLPostListFilterEnum {
    author_email = 'author_email',
    author_firstname = 'author_firstname',
    author_lastname = 'author_lastname',
    has_relevant_comment = 'has_relevant_comment',
    post_categories = 'post_categories',
    post_statuses = 'post_statuses',
    post_types = 'post_types',
    search_query = 'search_query',
}
