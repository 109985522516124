/* istanbul ignore file */
export enum SEGMENT {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171787831
     */
    CREATE_SEGMENT_MODAL_TITLE = 'SEGMENT.CREATE_SEGMENT_MODAL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169661497
     */
    EDIT_SEGMENT_MODAL_TITLE = 'SEGMENT.EDIT_SEGMENT_MODAL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169649190
     */
    CREATE_SEGMENT = 'SEGMENT.CREATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=192730599
     */
    NEW_SEGMENT = 'SEGMENT.NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175497196
     */
    CALCULATE_SEGMENT_SIZE = 'SEGMENT.CALCULATE_SEGMENT_SIZE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175498301
     */
    CALCULATING_SEGMENT_SIZE = 'SEGMENT.CALCULATING_SEGMENT_SIZE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=180392856
     */
    CONTINUE_EDITING = 'SEGMENT.CONTINUE_EDITING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175195898
     */
    RULES = 'SEGMENT.RULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171811562
     */
    SEGMENT_CREATED_NOTIF = 'SEGMENT.SEGMENT_CREATED_NOTIF',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=180212269
     */
    SEGMENT_EDITED_NOTIF = 'SEGMENT.SEGMENT_EDITED_NOTIF',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=229762618
     */
    SEGMENT_ARCHIVED_NOTIF = 'SEGMENT.SEGMENT_ARCHIVED_NOTIF',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=229762618
     */
    SEGMENT_UNARCHIVED_NOTIF = 'SEGMENT.SEGMENT_UNARCHIVED_NOTIF',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185320699
     */
    SEGMENTS = 'SEGMENT.SEGMENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194578599
     */
    ATTRIBUTE_SELECT_LABEL = 'SEGMENT.ATTRIBUTE_SELECT_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194578908
     */
    OPERATOR_SELECT_LABEL = 'SEGMENT.OPERATOR_SELECT_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=197857245
     */
    USER_PROFILE_INFO = 'SEGMENT.USER_PROFILE_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=198212337
     */
    INCLUDE = 'SEGMENT.INCLUDE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=198226548
     */
    INCLUDE_DESCRIPTION = 'SEGMENT.INCLUDE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=198433279
     */
    DELETE_CRITERIA_TOOLTIP = 'SEGMENT.DELETE_CRITERIA_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200810078
     */
    VALUE = 'SEGMENT.VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200837606
     */
    ANY_OF_OPERATOR = 'SEGMENT.ANY_OF_OPERATOR',
    /**
     *
     */
    NOTIF_MAX_AND_BLOCKS_REACHED = 'SEGMENT.NOTIF_MAX_AND_BLOCKS_REACHED ',
    /**
     *
     */
    NOTIF_MAX_OR_BLOCKS_PER_AND_BLOCK_REACHED = 'SEGMENT.NOTIF_MAX_OR_BLOCKS_PER_AND_BLOCK_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=202302273
     */
    NOTIF_MAX_VALUES_REACHED = 'SEGMENT.NOTIF_MAX_VALUES_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210467850a
     */
    STARTS_WITH_OPERATOR = 'SEGMENT.STARTS_WITH_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=219999555
     */
    ENDS_WITH_OPERATOR = 'SEGMENT.ENDS_WITH_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=219591296
     */
    NOT_IN_OPERATOR = 'SEGMENT.NOT_IN_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=219625541
     */
    EMPTY_OPERATOR = 'SEGMENT.EMPTY_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220139826
     */
    NOT_EMPTY_OPERATOR = 'SEGMENT.NOT_EMPTY_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=219606467
     */
    CONTAINS_OPERATOR = 'SEGMENT.CONTAINS_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222564569
     */
    NOT_CONTAINS_OPERATOR = 'SEGMENT.NOT_CONTAINS_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=221273945
     */
    NOT_ENDS_WITH_OPERATOR = 'SEGMENT.NOT_ENDS_WITH_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230697908
     */
    SEGMENT_SELECTED_BUT_ARCHIVED_HELPER = 'SEGMENT.SEGMENT_SELECTED_BUT_ARCHIVED_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=225218521
     */
    USE = 'SEGMENT.USE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232136124
     */
    ENTER_A_VALUE = 'SEGMENT.ENTER_A_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261851086
     */
    UPLOAD_CSV_FILE_TITLE = 'SEGMENT.UPLOAD_CSV_FILE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262841783
     */
    UPLOAD_SUPPORTED_SEPARATORS = 'SEGMENT.UPLOAD_SUPPORTED_SEPARATORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262845611
     */
    UPLOAD_COLUMN_USED = 'SEGMENT.UPLOAD_COLUMN_USED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262845657
     */
    UPLOAD_FILE_RULES_INTRO = 'SEGMENT.UPLOAD_FILE_RULES_INTRO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=264132459
     */
    IN_CSV_OPERATOR = 'SEGMENT.IN_CSV_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=264132532
     */
    NOT_IN_CSV_OPERATOR = 'SEGMENT.NOT_IN_CSV_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=264572150
     */
    IN_CSV_TOOLTIP = 'SEGMENT.IN_CSV_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=335781868
     */
    SEGMENT_DESCRIPTION_LABEL = 'SEGMENT.DESCRIPTION_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=335558853
     */
    SEGMENT_PROPERTY_PROMOTION_LABEL = 'SEGMENT.PROPERTY_PROMOTION_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=335804760
     */
    SEGMENT_PROPERTY_PROMOTION_HELPER = 'SEGMENT.PROPERTY_PROMOTION_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=335840151
     */
    SEGMENT_PROPERTY_PROMOTION_DRAWER_DESCRIPTION = 'SEGMENT.PROPERTY_PROMOTION_DRAWER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=336583096
     * @deprecated use ReferToDocumentation component instead
     */
    SEGMENT_PROPERTIES_DESCRIPTION = 'SEGMENT.PROPERTIES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=336584072
     */
    SEGMENT_PROPERTIES_MORE_INFO_LINK = 'SEGMENT.PROPERTIES_MORE_INFO_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=349633356
     */
    SEGMENT_CSV_NOT_UPLOADED_ERROR_MESSAGE = 'SEGMENT.CSV_NOT_UPLOADED_ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=371947526
     */
    AVAILABLE_FOR_CONTENT = 'SEGMENT.AVAILABLE_FOR_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=372010960
     */
    DESCRIPTION_HELPER = 'SEGMENT.DESCRIPTION_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=372011534
     */
    SEGMENT_PROPERTY_PROMOTION_WARNING = 'SEGMENT.SEGMENT_PROPERTY_PROMOTION_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=372032024
     */
    SEGMENT_LAST_UPDATED_ON = 'SEGMENT.LAST_UPDATED_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=373878791
     */
    AVAILABILITY = 'SEGMENT.AVAILABILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=383187960
     */
    SEGMENT_FORM_AND_BLOCK_MAX_LIMIT_REACHED = 'SEGMENT_FORM_AND_BLOCK_MAX_LIMIT_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=383187988
     */
    SEGMENT_FORM_OR_BLOCK_MAX_LIMIT_REACHED = 'SEGMENT_FORM_OR_BLOCK_MAX_LIMIT_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384920253
     */
    SEGMENT_PROPERTY_ANALYTICS_HELPER = 'SEGMENT.SEGMENT_PROPERTY_ANALYTICS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384935271
     */
    SEGMENT_PROPERTY_ANALYTICS_DRAWER_DESCRIPTION = 'SEGMENT.SEGMENT_PROPERTY_ANALYTICS_DRAWER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398412389
     */
    SEGMENT_OUTSIDE_BROADCAST = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398423092
     */
    SEGMENT_OUTSIDE_BROADCAST_HELPER = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398424053
     */
    SEGMENT_OUTSIDE_BROADCAST_HELPER_USABLE_IN_CONTENT = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST_HELPER_USABLE_IN_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398424695
     */
    SEGMENT_OUTSIDE_BROADCAST_HELPER_USABLE_IN_ANALYTICS = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST_HELPER_USABLE_IN_ANALYTICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398487941
     */
    SEGMENT_OUTSIDE_BROADCAST_DETAILS = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST_DETAILS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=418115847
     */
    IS_OPERATOR = 'SEGMENT.IS_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=418116637
     */
    IS_NOT_OPERATOR = 'SEGMENT.IS_NOT_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423110017
     */
    SEGMENT_RULE_DESCRIPTION = 'SEGMENT.SEGMENT_RULE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444223117
     */
    IS_ON_OPERATOR = 'SEGMENT.IS_ON_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444223125
     */
    IS_BEFORE_OPERATOR = 'SEGMENT.IS_BEFORE_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444223130
     */
    IS_AFTER_OPERATOR = 'SEGMENT.IS_AFTER_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444223135
     */
    IS_NEVER_OPERATOR = 'SEGMENT.IS_NEVER_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=445074013
     */
    SEGMENT_OUTSIDE_BROADCAST_HELPER_WIDGET_VISIBILITY = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST_HELPER_WIDGET_VISIBILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=446435947
     */
    ACCOUNT_INFO_SUBHEADER = 'SEGMENT.ACCOUNT_INFO_SUBHEADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=446490889
     */
    ACCOUNT_CREATION_DATE = 'SEGMENT.ACCOUNT_CREATION_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=446495413
     */
    ACCOUNT_FIRST_SESSION = 'SEGMENT.ACCOUNT_FIRST_SESSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=446495711
     */
    ACCOUNT_LAST_SESSION = 'SEGMENT.ACCOUNT_LAST_SESSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457509262
     */
    TIMESCALE = 'SEGMENT.TIMESCALE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458825806
     */
    IS_NOT_IN_THE_NEXT_OPERATOR = 'SEGMENT.IS_NOT_IN_THE_NEXT_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458825801
     */
    IS_IN_THE_NEXT_OPERATOR = 'SEGMENT.IS_IN_THE_NEXT_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458825735
     */
    IS_NOT_IN_THE_LAST_OPERATOR = 'SEGMENT.IS_NOT_IN_THE_LAST_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458820864
     */
    IS_IN_THE_LAST_OPERATOR = 'SEGMENT.IS_IN_THE_LAST_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458815219
     */
    IS_THIS_OPERATOR = 'SEGMENT.IS_THIS_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=459070909
     */
    RELATIVE_DATE = 'SEGMENT.RELATIVE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=459070852
     */
    ABSOLUTE_DATE = 'SEGMENT.ABSOLUTE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=465243200
     */
    YEAR = 'SEGMENT.YEAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=208700326
     */
    UNKNOWN_OPERATOR = 'SEGMENT.UNKNOWN_OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530408153
     */
    SEGMENT_OUTSIDE_BROADCAST_HELPER_SITE_NAVIGATION = 'SEGMENT.SEGMENT_OUTSIDE_BROADCAST_HELPER_SITE_NAVIGATION',
}
