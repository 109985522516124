import React, { useRef } from 'react';
import { node, bool } from 'prop-types';
import classNames from 'classnames';

import { height } from 'components/utils';

/**
 * Displays a simple menu element. This menu looks like a basic `DropDown`.
 *
 * @return {Element} The react element.
 */
const Menu = ({ anchorEl, children, isOpen }) => {
    const menuRef = useRef(null);

    return (
        isOpen && (
            <div ref={menuRef} className={classNames('menu')} style={{ marginTop: height(anchorEl) }}>
                <div className="menu__content">{children}</div>
            </div>
        )
    );
};

Menu.propTypes = {
    /** The menu anchor, it will define its position. */
    anchorEl: node.isRequired,
    /** The menu children element. */
    children: node.isRequired,
    /** Whether the menu is open or not. */
    isOpen: bool.isRequired,
};

export { Menu };
