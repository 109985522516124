import React from 'react';

import { JOURNEYS_ICON } from '@lumapps/journeys/constants';
import { JOURNEY_CORE } from '@lumapps/journeys/keys';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { canAccessJourneysAdmin } from '../../ducks/selectors';
import { journeysListRoute } from '../../routes';

export interface JourneysSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** True if the user has access to the journeys section */
    isAllowed: boolean;
}

export const JourneysSideNavigation = (props: Partial<JourneysSideNavigationProps>) => {
    const isAllowed = useSelector(canAccessJourneysAdmin);

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={journeysListRoute}
            label={translateKey(JOURNEY_CORE.JOURNEYS)}
            {...props}
            icon={JOURNEYS_ICON}
        />
    );
};

JourneysSideNavigation.displayName = 'SideNavigationItem';
