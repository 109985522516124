import React from 'react';

import { mdiGoogleDrive } from '@lumapps/lumx/icons';
import { useGoogleDriveUpload } from '@lumapps/play-video-library/hooks/useGoogleDriveUpload';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { FileUploadFromDrive } from '@lumapps/play/types';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { VideoUploadDialog } from '../../components/VideoUploadDialog';
import { isPlayCommunitiesFeatureEnabled } from '../../ducks/selectors';
import { COMMUNITIES } from '../../keys';
import { CommunityVideo } from '../../types';

export interface UseGooglePickerButtonProps {
    onInsert(video: CommunityVideo): void;
}

export const useGooglePickerButton = ({ onInsert }: UseGooglePickerButtonProps) => {
    const { translateKey } = useTranslate();
    const isPlayCommunitiesEnabled = useSelector(isPlayCommunitiesFeatureEnabled);
    const isPlayEnabled = useSelector(isPlayVideoEnabled);

    const [isGoogleUploadDialogOpen, , closeGoogleUploadDialog, openGoogleUploadDialog] = useBooleanState(false);

    const [videoFromDrive, setVideoFromDrive] = React.useState<FileUploadFromDrive>();

    const { isGoogleUser, openGoogleDrivePicker } = useGoogleDriveUpload({
        multiselect: false,
        onFilesSelected: (files) => {
            setVideoFromDrive(files[0]);
            openGoogleUploadDialog();
        },
    });

    const handleSubmit = React.useCallback(
        (video: CommunityVideo) => {
            onInsert(video);
            closeGoogleUploadDialog();
        },
        [closeGoogleUploadDialog, onInsert],
    );

    const googlePickerButtonProps = React.useMemo(
        () => ({
            icon: mdiGoogleDrive,
            label: translateKey(COMMUNITIES.UPLOAD_VIDEO_FROM_DRIVE),
            onSelected: openGoogleDrivePicker,
        }),
        [openGoogleDrivePicker, translateKey],
    );

    const GoogleUploadDialog = React.useMemo(
        () => (
            <VideoUploadDialog
                isOpen={isGoogleUploadDialogOpen}
                videoFromDrive={videoFromDrive}
                onCancel={closeGoogleUploadDialog}
                onSubmit={handleSubmit}
            />
        ),
        [closeGoogleUploadDialog, handleSubmit, isGoogleUploadDialogOpen, videoFromDrive],
    );

    return {
        ...(isPlayEnabled &&
            isPlayCommunitiesEnabled &&
            isGoogleUser && { googlePickerButtonProps, GoogleUploadDialog, isGoogleUploadDialogOpen }),
    };
};
