export enum PLAYLIST {
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=209468921 */
    ADD_TO_PLAYLIST = 'PLAY.ADD_TO_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=297179854 */
    ADD_VIDEOS_TO_DYNAMIC_PLAYLIST = 'PLAY.ADD_VIDEOS_TO_DYNAMIC_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191603367 */
    ADD_VIDEOS_TO_PLAYLIST = 'PLAY.ADD_VIDEOS_TO_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301747128 */
    ANY_TIME = 'PLAY.SINCE_ANY_TIME',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=216197097 */
    AUTO_PLAY = 'PLAY.AUTO_PLAY',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194990988 */
    AVAILABILITY_SEARCH_DESCRIPTION = 'PLAY.AVAILABILITY_PLAYLIST_SEARCH_DESCRIPTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=303329058 */
    CONTAINS_VIDEO = 'PLAY.PLAYLIST_CONTAINS_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193459767 */
    CREATE = 'PLAY.CREATE_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191189786 */
    DELETE_CONFIRM = 'PLAY.DELETE_PLAYLISTS_CONFIRM',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191201875 */
    DELETE_CTA = 'PLAY.DELETE_PLAYLISTS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191190943 */
    DELETE_MESSAGE = 'PLAY.DELETE_PLAYLISTS_MESSAGE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200046877 */
    DELETED = 'PLAY.PLAYLIST_DELETED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292222786 */
    DYNAMIC = 'PLAY.SMART_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292227774 */
    DYNAMIC_HELPER = 'PLAY.SMART_PLAYLIST_HELPER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392341086 */
    EDIT_PLAYLIST_AUTHOR = 'PLAY.EDIT_PLAYLIST_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=195117849 */
    ENTER_DESCRIPTION = 'PLAY.ENTER_PLAYLIST_DESCRIPTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190365062 */
    ENTER_TITLE = 'PLAY.ENTER_PLAYLIST_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=303322037 */
    INCLUDE = 'PLAY.PLAYLIST_INCLUDE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194991268 */
    INTEGRATION_IN_SITE_TITLE = 'PLAY.INTEGRATION_PLAYLIST_IN_SITE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194543492 */
    LINK = 'PLAY.PLAYLIST_LINK',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311370 */
    LIST_OPTIONS = 'PLAY.LIST_OPTIONS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292229516 */
    MANUAL = 'PLAY.MANUAL_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292229017 */
    MANUAL_HELPER = 'PLAY.MANUAL_PLAYLIST_HELPER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=302793481 */
    MATCH_ALL = 'PLAY.MATCH_ALL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=302793817 */
    MATCH_ONE = 'PLAY.MATCH_ONE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190224946 */
    NEW = 'PLAY.NEW_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193456627 */
    NO_PLAYLIST = 'PLAY.NO_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220763331 */
    OPEN_PLAYLIST = 'PLAY.OPEN_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311622 */
    ORDER_VIDEOS_BY = 'PLAY.ORDER_VIDEOS_BY',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311361 */
    PARAMETERS = 'PLAY.PLAYLIST_PARAMETERS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311361 */
    PAST_MONTH = 'FRONT.SEARCH_FILTERS.DATE_RANGE.LAST_MONTH',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311361 */
    PAST_WEEK = 'FRONT.SEARCH_FILTERS.DATE_RANGE.LAST_WEEK',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311361 */
    PAST_YEAR = 'FRONT.SEARCH_FILTERS.DATE_RANGE.LAST_YEAR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311476 */
    PICK_VIDEOS_FROM = 'PLAY.PICK_VIDEOS_FROM',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294311503 */
    PICK_VIDEOS_SINCE = 'PLAY.PICK_VIDEOS_SINCE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300893724 */
    PLAYLIST = 'PLAY.PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392341085 */
    PLAYLIST_AUTHOR_HELPER = 'PLAY.PLAYLIST_AUTHOR_HELPER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392341082 */
    PLAYLIST_AUTHORS_CHANGED = 'PLAY.PLAYLIST_AUTHORS_CHANGED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199269359 */
    PLAYLISTS = 'PLAY.PLAYLISTS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=272714985 */
    REMOVE_VIDEO_FROM_PLAYLIST = 'PLAY.REMOVE_VIDEO_FROM_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194287967 */
    RESTRICTED_VIDEO = 'PLAY.RESTRICTED_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200047617 */
    SAVED = 'PLAY.PLAYLIST_SAVED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392341081 */
    SELECTED_NB_PLAYLIST_FROM_NB_AUTHOR = 'PLAY.SELECTED_NB_PLAYLIST_FROM_NB_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392341083 */
    SELECTED_PLAYLIST_FROM_AUTHOR = 'PLAY.SELECTED_PLAYLIST_FROM_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199055402 */
    THUMBNAIL = 'PLAY.PLAYLIST_THUMBNAIL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220504595 */
    UPDATED = 'GLOBAL.UPDATED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220507665 */
    UPDATED_ON = 'GLOBAL.UPDATED_ON',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109483594 */
    UPLOADED_ON = 'PLAY.VIDEO_LIST_ITEM_UPDATED_AT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194287993 */
    VIDEO_VISIBILITY_RESTRICTED = 'PLAY.VIDEO_VISIBILITY_RESTRICTED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194988138 */
    VISIBILITY_DESCRIPTION = 'PLAY.VISIBILITY_DESCRIPTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=212734453 */
    WATCH_ALL = 'PLAY.WATCH_ALL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392341084 */
    WHO_CAN_BE_PLAYLIST_AUTHOR = 'PLAY.WHO_CAN_BE_PLAYLIST_AUTHOR',
}
