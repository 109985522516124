import React from 'react';

import { AnalyticsSideNavigation } from '@lumapps/analytics-navigation/components/AnalyticsSideNavigation';
import { BroadcastSideNavigation } from '@lumapps/broadcasts/components/BroadcastSideNavigation';
import { CampaignSideNavigation } from '@lumapps/campaigns/components/CampaignSideNavigation';
import { CommunitiesSideNavigation } from '@lumapps/community-side-navigation/components/CommunitiesSideNavigation';
import { SpacesSideNavigation } from '@lumapps/community-side-navigation/components/SpacesSideNavigation';
import { ContentBulkSideNavigation } from '@lumapps/content-admin/components/ContentBulkSideNavigation/ContentBulkSideNavigation';
import { ContentStreamsSideNavigation } from '@lumapps/content-streams-admin/components/ContentStreamsSideNavigation';
import { ContentTypesSideNavigation } from '@lumapps/content-types/components/ContentTypesSideNavigation';
import { CustomContentTypesSideNavigation } from '@lumapps/content-types/components/CustomContentTypesSideNavigation';
import { CustomerSettingsSideNavigation } from '@lumapps/customer/components/CustomerSettingsSideNavigation';
import { GlobalAdministratorsSideNavigation } from '@lumapps/customer/components/GlobalAdministratorsSideNavigation';
import { DigitalAssistantSideNavigation } from '@lumapps/digital-assistant-admin/components/DigitalAssistantSideNavigation';
import { DirectoriesSideNavigation } from '@lumapps/directories/components/DirectoriesSideNavigation';
import { ModuleSideNavigation } from '@lumapps/directories/components/ModuleSideNavigation';
import { DomainsManagementSideNavigation } from '@lumapps/domains-management/components/DomainsManagementSideNavigation';
import { FeaturesManagementSideNavigation } from '@lumapps/features-management/components/FeaturesManagementSideNavigation';
import { TagzSideNavigation } from '@lumapps/folksonomy-admin/components/TagzSideNavigation';
import { PlatformGroupsSideNavigation } from '@lumapps/groups-platform/components/PlatformGroupsSideNavigation';
import { GroupsSideNavigation } from '@lumapps/groups-site/components/GroupsSideNavigation';
import { InstanceAdminSideNavigation } from '@lumapps/instance-administrators/components/InstanceAdminSideNavigation';
import { InstanceSettingsSideNavigation } from '@lumapps/instance-settings/components/InstanceSettingsSideNavigation';
import { InstancesSideNavigation } from '@lumapps/instance/components/InstancesSideNavigation';
import { JourneysSideNavigation } from '@lumapps/journeys-admin/components/JourneysSideNavigation';
import { LearningAdminSideNavigation } from '@lumapps/learning-admin/components/LearningAdminSideNavigation';
import { NavigationHeader, NavigationHeaderProps } from '@lumapps/lumx-back-office/components/NavigationHeader';
import {
    SideNavigation as Navigation,
    SideNavigationSection as Section,
} from '@lumapps/lumx-back-office/components/SideNavigation';
import { PlatformExtensionsSideNavigation } from '@lumapps/marketplace/components/PlatformExtensionsSideNavigation';
import { MediaSideNavigation } from '@lumapps/medias/components/MediaSideNavigation';
import { MetadataSideNavigation } from '@lumapps/metadata-admin/components/MetadataSideNavigation';
import { PendoTrigger } from '@lumapps/metric/components/PendoTrigger';
import { PENDO_SCOPES } from '@lumapps/metric/constants';
import { MobileConfigurationSideNavigation } from '@lumapps/mobile-configuration/components/MobileConfigurationSideNavigation';
import { MobileCustomizationSideNavigation } from '@lumapps/mobile-customization/components/MobileCustomizationSideNavigation';
import { MobileGeneralSettingsSideNavigation } from '@lumapps/mobile-general-settings/components/MobileGeneralSettingsSideNavigation';
import { MobileLoginConfigurationSideNavigation } from '@lumapps/mobile-login/components/MobileLoginConfigurationSideNavigation';
import { MobileNavigationAdministrationSideNavigation } from '@lumapps/mobile-navigation/components/MobileNavigationAdministrationSideNavigation';
import { NannySideNavigation } from '@lumapps/nanny-admin/components/NannySideNavigation';
import { MainNavSideNavigation } from '@lumapps/navigation/components/MainNavSideNavigation';
import { NewsletterSideNavigation } from '@lumapps/newsletter/components/NewsletterSideNavigation';
import { ProvidersSideNavigation } from '@lumapps/providers/components/ProvidersSideNavigation';
import { ResellerSideNavigation } from '@lumapps/reseller-console/components/ResellerSideNavigation';
import { RoleSideNavigation } from '@lumapps/roles/components/RoleSideNavigation';
import { AdminSideNavigation as SocialAdvocacySideNavigation } from '@lumapps/sa-navigation/components/AdminSideNavigation';
import { PromotedResultsSideNavigation } from '@lumapps/search-promoted/components/PromotedResultSideNavigation';
import { SearchSettingsSideNavigation } from '@lumapps/search-settings/components/SearchSettingsSideNavigation';
import { SegmentSideNavigation } from '@lumapps/segment-admin/components/SegmentSideNavigation';
import { StyleSideNavigation } from '@lumapps/style/components/StyleSideNavigation';
import { useComputedTopStyles } from '@lumapps/style/hooks/useComputedTopStyles';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { UserManagementSideNavigation } from '@lumapps/user-management/components/UserManagementSideNavigation';

import { BACK_OFFICE } from '../../keys';
import { SearchAiSideNavigation } from '../SearchAISideNavigation';

import './index.scss';

export interface SideNavigationProps {
    navHeaderProps: NavigationHeaderProps;
    isSuperAdmin: boolean;
    enabledFeatures?: Record<string, boolean>;
}

const CLASSNAME = 'backoffice-navigation';
const SideNavigation: React.FC<SideNavigationProps> = ({ navHeaderProps, isSuperAdmin, enabledFeatures }) => {
    const { translateKey } = useTranslate();
    const styles = useComputedTopStyles({
        parentInstance: navHeaderProps?.parentInstance,
        isMainNavInheritanceEnabled: navHeaderProps?.isMainNavInheritanceEnabled,
    });
    return (
        <Navigation className={CLASSNAME}>
            <NavigationHeader {...navHeaderProps} isSticky>
                <PendoTrigger scope={PENDO_SCOPES.SIDE_NAV} theme={styles?.themeColor} />
            </NavigationHeader>

            <Section header={translateKey(GLOBAL.CONTENTS)} id="contents">
                <MainNavSideNavigation />
                <ContentTypesSideNavigation />
                <MediaSideNavigation />
                <MetadataSideNavigation />
            </Section>

            <Section header={translateKey(GLOBAL.CONTENT_TYPES)} id="custom-content-types">
                <ContentBulkSideNavigation />
                <CustomContentTypesSideNavigation />
            </Section>

            <Section header={translateKey(BACK_OFFICE.SUB_MODULES)} id="modules">
                <CommunitiesSideNavigation />
                <SpacesSideNavigation />
                <NewsletterSideNavigation />
                <ModuleSideNavigation />
                <DirectoriesSideNavigation />
            </Section>

            <Section header={translateKey(BACK_OFFICE.SUB_CONTENT_STRATEGY)} id="content-strategy">
                <BroadcastSideNavigation />
                <CampaignSideNavigation />
                <JourneysSideNavigation />
                <SegmentSideNavigation />
                <ContentStreamsSideNavigation />
            </Section>

            {enabledFeatures?.digitalAssistant && isSuperAdmin && (
                <Section header={translateKey(BACK_OFFICE.COMPANION)} id="companion">
                    <DigitalAssistantSideNavigation />
                </Section>
            )}

            <Section header={translateKey(BACK_OFFICE.INSIGHTS)} id="insights">
                <AnalyticsSideNavigation id="insights-analytics" />
                <TagzSideNavigation id="insights-folksonomy" />
            </Section>

            {enabledFeatures?.learningAlpha && <LearningAdminSideNavigation />}

            <Section header={translateKey(BACK_OFFICE.SEARCH)} id="search">
                <SearchAiSideNavigation id="ai-search" />
                <PromotedResultsSideNavigation id="promoted-results" />
                <SearchSettingsSideNavigation id="search-settings" />
            </Section>

            <SocialAdvocacySideNavigation />

            <Section header={translateKey(BACK_OFFICE.SUB_INSTANCE)} id="instance">
                <InstanceSettingsSideNavigation />
                <RoleSideNavigation />
                <InstanceAdminSideNavigation />
                <GroupsSideNavigation />
                <StyleSideNavigation />
            </Section>

            {isSuperAdmin && (
                <Section header={translateKey(BACK_OFFICE.SUB_MOBILE)} id="mobile">
                    <MobileGeneralSettingsSideNavigation />
                    <MobileCustomizationSideNavigation />
                    <MobileLoginConfigurationSideNavigation />
                    <MobileNavigationAdministrationSideNavigation />
                    <MobileConfigurationSideNavigation />
                </Section>
            )}

            {isSuperAdmin && (
                <Section header={translateKey(BACK_OFFICE.SUB_PLATFORM)} id="platform">
                    <CustomerSettingsSideNavigation />
                    <DomainsManagementSideNavigation />
                    <FeaturesManagementSideNavigation />
                    <PlatformExtensionsSideNavigation />
                    <GlobalAdministratorsSideNavigation />
                    <ProvidersSideNavigation />
                    <UserManagementSideNavigation />
                    <PlatformGroupsSideNavigation />
                    <NannySideNavigation />
                    <InstancesSideNavigation />
                    <ResellerSideNavigation />
                </Section>
            )}
        </Navigation>
    );
};

export { SideNavigation };
