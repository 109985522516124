import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiImage } from '@lumapps/lumx/icons';
import { adminTags } from '@lumapps/medias-tags/routes';
import { useTranslate } from '@lumapps/translations';

import { MEDIAS } from '../../keys';
import { admin, adminTrash } from '../../routes';

export interface MediaSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
    isAdvancedPickerEnabled: boolean;
}

const MediaSideNavigation = (props: Partial<MediaSideNavigationProps>) => {
    const { isAllowed, isAdvancedPickerEnabled } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(MEDIAS.NAV_MEDIA)} icon={mdiImage} id="media">
            <SideNavigationItem label={translateKey(MEDIAS.MEDIA_LIBRARY)} route={admin()} />
            <SideNavigationItem label={translateKey(MEDIAS.MEDIA_TAGS)} route={adminTags()} />
            {isAdvancedPickerEnabled && (
                <SideNavigationItem label={translateKey(MEDIAS.MEDIA_TRASH)} route={adminTrash()} />
            )}
        </SideNavigationItem>
    );
};

MediaSideNavigation.displayName = 'SideNavigationItem';

export { MediaSideNavigation };
