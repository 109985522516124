import { communityPostView, communityView } from '@lumapps/communities/routes';
import { ContentTypes } from '@lumapps/content-types/types';
import { directoryView } from '@lumapps/directories-page/routes';
import { OptionalAppRoute, Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';
import { isExternalUrl } from '@lumapps/router/utils';

import { ContentLinkRef } from '../types';

/* Paths: */
export const homePath = '/';
export const legacyContentPath = '/:path*/:slug';
export const newContentByIdPath = `/${URL_PREFIX}/content/id/:id`;
export const newContentByIdSlugPath = `/${URL_PREFIX}/content/:id/:path*/:slug?`;

/* Routes: */
export const legacyContentViewRoute: Route<string[]> = {
    path: [
        /** Legacy content URL. */
        legacyContentPath,
        /** Home page URL. Keep it at the end of the array or else params from other paths won't be reachable. */
        homePath,
    ],
    legacyId: 'app.front.content-get',
    appId: AppId.legacy,
};

export const homePageViewRoute: OptionalAppRoute = {
    path: homePath,
    legacyId: 'app.front.content-get',
};

export const newContentViewRoute: Route<string[]> = {
    path: [
        /** New content URL by `id`. */
        newContentByIdPath,
        /** New content URL by `id` and `slug`. */
        newContentByIdSlugPath,
        /** Home page URL. Keep it at the end of the array or else params from other paths won't be reachable. */
        homePath,
    ],
    appId: AppId.frontOffice,
};

/* Parameterized routes: */

/**
 * Home page URL.
 * With layout v2 enabled, we consider the home page to always be compatible v2.
 */
export const home = ({
    isV2Compatible,
    anchor,
    query,
    instanceSlug,
}: {
    isV2Compatible?: boolean;
    anchor?: Route['anchor'];
    query?: Route['query'];
    instanceSlug?: string;
}): Route => {
    if (isV2Compatible) {
        return {
            ...homePageViewRoute,
            anchor,
            query,
            instanceSlug,
            legacyId: undefined,
            appId: AppId.frontOffice,
        };
    }
    return {
        ...homePageViewRoute,
        anchor,
        query,
        instanceSlug,
        // Required for the legacy to know we actually changed page
        params: { slug: null } as any,
        appId: AppId.legacy,
    };
};

/**
 * Switches between the legacy & new app content view route depending on the layout FF and the content migration status.
 */
export function contentView(params: { isLayoutEnabled: boolean; to: ContentLinkRef }): Route {
    const {
        id,
        slug,
        type,
        isHomePage,
        anchor,
        keepParentPath = false,
        instance,
        isV2Compatible,
        query,
        link = isExternalUrl(slug) ? slug : undefined,
    } = params.to;
    const instanceSlug = instance?.slug;
    const correctedSlug = (keepParentPath ? slug : (slug?.split('/').pop() as string)) || '';

    // Home page.
    if (isHomePage) {
        return home({ isV2Compatible: params.isLayoutEnabled && isV2Compatible, instanceSlug, anchor, query });
    }

    // Content external link.
    if (link) {
        return {
            appId: AppId.external,
            anchor,
            query,
            path: link,
        };
    }

    /**
     * Redirect to the route associated with the content type.
     * */
    switch (type) {
        case ContentTypes.COMMUNITY:
            return communityView({ slug: slug as string, id, anchor, instanceSlug });
        case ContentTypes.POST:
            return communityPostView({ slug: slug as string, postId: id as string, anchor, instanceSlug });
        case ContentTypes.DIRECTORY:
            return directoryView(params);
        default:
            break;
    }

    // Add mobile webview page descriptor when possible.
    let mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined;
    if (id) {
        mobilePageDescriptor = {
            id,
            type: 'content',
            section: anchor?.match(/#?comments/) ? 'comments' : undefined,
        };
    }

    // Layout v2 content by id and slug.
    if (id && slug && params.isLayoutEnabled && isV2Compatible) {
        return {
            ...newContentViewRoute,
            path: newContentByIdSlugPath,
            params: { slug: correctedSlug, id },
            ignoreParamEncoding: true,
            anchor,
            query,
            instanceSlug,
            mobilePageDescriptor,
        };
    }

    // Layout v2 content by id.
    if (id && params.isLayoutEnabled && isV2Compatible) {
        return {
            ...newContentViewRoute,
            path: newContentByIdPath,
            params: { id },
            anchor,
            query,
            instanceSlug,
            mobilePageDescriptor,
        };
    }

    // Legacy layout v1 route.
    return {
        ...legacyContentViewRoute,
        path: legacyContentPath,
        params: { slug: correctedSlug },
        anchor,
        query,
        ignoreParamEncoding: true,
        instanceSlug,
        mobilePageDescriptor,
    };
}
