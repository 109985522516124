import React, { useState, useRef } from 'react';
import { shape, func, number, oneOfType, string, arrayOf, bool } from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import { Chip, Dropdown, Placement, List, ListItem, Size, Icon } from '@lumapps/lumx/react';
import { mdiCloseCircle, mdiMenuDown } from '@lumapps/lumx/icons';

import { getLabel } from '../../utils';

/**
 * Renders a dropdown selection menu with the look of a Chip to display the current selection state.
 *
 * They're destructured to help build the other components props.
 *
 * @return {Component} The SelectionChip component.
 */
// eslint-disable-next-line no-shadow
const SelectionChip = ({ choices, isOpen, name, onChange, value, placeholder, zIndex }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
    const selectionChipRef = useRef(null);

    const hasValue = !isEmpty(value) && !isNull(value);
    const label = getLabel({ choices, placeholder, value });

    const handleOnItemSelected = (itemValue) => {
        setIsDropdownOpen(isOpen);
        // Only update if the value has changed.
        if (itemValue !== value) {
            onChange(itemValue, name);
        }
    };

    const handleOnAfterClick = () => {
        // If a value is selected, remove it.
        if (hasValue) {
            // Null is given to reset the selected value.
            onChange(null, name);
        } else {
            // Toggle dropdown display.
            setIsDropdownOpen(!isDropdownOpen);
        }
    };

    return (
        <>
            <Chip
                after={<Icon icon={hasValue ? mdiCloseCircle : mdiMenuDown} size={Size.xs} />}
                ref={selectionChipRef}
                isSelected={hasValue}
                onAfterClick={handleOnAfterClick}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                {label.text}
            </Chip>
            <Dropdown
                closeOnClickAway
                closeOnEscape
                anchorRef={selectionChipRef}
                placement={Placement.BOTTOM_START}
                isOpen={isDropdownOpen}
                zIndex={zIndex}
                onClose={() => setIsDropdownOpen(isOpen)}
            >
                <List isClickable>
                    {choices.map((choice) => (
                        <ListItem
                            key={choice.value}
                            isSelected={choice.value === value}
                            size={Size.tiny}
                            onItemSelected={() => handleOnItemSelected(choice.value)}
                        >
                            {choice.label}
                        </ListItem>
                    ))}
                </List>
            </Dropdown>
        </>
    );
};

SelectionChip.propTypes = {
    /** List of possible items. */
    choices: arrayOf(
        shape({
            label: string,
            value: oneOfType([number, string]),
        }),
    ),
    /** Is the dropdown opened. */
    isOpen: bool,
    /** The name of the select input. */
    name: string.isRequired,
    /** Called when `value` changes with `(value, name, occurence)`. */
    onChange: func.isRequired,
    /** The default displayed text. */
    placeholder: string,
    /** Current value to display. */
    value: oneOfType([number, string]),
    /** ZIndex of the dropdown. */
    zIndex: number,
};

SelectionChip.defaultProps = {
    choices: [],
    isOpen: false,
    placeholder: '',
    value: null,
    // eslint-disable-next-line no-magic-numbers
    zIndex: 900,
};

export { SelectionChip };
