import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const SiteGroupsRoutes: Route = {
    path: '/admin/feed',
    appId: AppId.backOffice,
};

/** Retrieves the route for the groups at site level admin. */
const Groups = (): Route => SiteGroupsRoutes;
export { Groups, SiteGroupsRoutes };
