import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { mdiChevronRight } from '@lumapps/lumx/icons';
import { Theme, Icon, Size, Link } from '@lumapps/lumx/react';
import { NavigationItem, NavigationItemType } from '@lumapps/navigation/types';
import { getSystemPageDefaultTitle, getSystemPageUrl } from '@lumapps/navigation/utils/systemPageUtils';
import { useTranslate } from '@lumapps/translations';

interface BreadcrumbElementProps {
    node: NavigationItem;
    className: string;
    theme?: Theme;
}

/** Single Breadcrumb Element. Will recursively call and mount its children, if any, with a separator in between */
export const BreadcrumbElement = ({ node, className, theme }: BreadcrumbElementProps) => {
    const { translate, translateObject } = useTranslate();
    const { element } = useClassnames(className);

    const isLast = node.children === undefined || node.children.length === 0;

    return (
        <>
            <li className={element('list-item')}>
                {(node.type === NavigationItemType.MENU || node.type === NavigationItemType.SECTION) && (
                    <p className={element('section')}>{translateObject(node.title)}</p>
                )}

                {/* If the node has an `url` value, treat it like an external link */}
                {(node.type === NavigationItemType.LINK || node.url) && (
                    <Link
                        theme={theme}
                        className={classnames(element('link'), {
                            [element('link', 'last')]: isLast,
                        })}
                        linkAs="a"
                        href={node.url}
                        aria-current={isLast ? 'page' : undefined}
                        target="_blank"
                    >
                        {translateObject(node.title)}
                    </Link>
                )}

                {node.type === NavigationItemType.PAGE && !node.url && (
                    <Link
                        linkAs={node.pageType === NavigationItemType.COMMUNITY ? CommunityLink : ContentLink}
                        className={classnames(element('link'), {
                            [element('link', 'last')]: isLast,
                        })}
                        to={{
                            id: node.pageId,
                            slug:
                                /**
                                 * Community slug need to be parsed since they contain the URL from their
                                 * parent item in the navigation. In order to fix that, we just retrieve the last
                                 * part of the slug and use it as the communities slug.
                                 */
                                node.pageType === NavigationItemType.COMMUNITY
                                    ? translateObject(node.slug)?.split('/').pop()
                                    : translateObject(node.slug),
                            keepParentPath: true,
                        }}
                        theme={theme}
                        aria-current={isLast ? 'page' : undefined}
                    >
                        {translateObject(node.title)}
                    </Link>
                )}

                {node.type === NavigationItemType.SYSTEM_PAGE && node.systemPageReference?.type && (
                    <Link
                        className={classnames(element('link'), {
                            [element('link', 'last')]: isLast,
                        })}
                        href={getSystemPageUrl(node.systemPageReference.type)}
                        target={node.newTab ? '_blank' : '_self'}
                        theme={theme}
                        aria-current={isLast ? 'page' : undefined}
                    >
                        {translateObject(node.title) ||
                            getSystemPageDefaultTitle(translate, node.systemPageReference.type)}
                    </Link>
                )}

                {!isLast && (
                    <Icon
                        className={element('separator')}
                        icon={mdiChevronRight}
                        size={Size.xs}
                        theme={theme}
                        aria-hidden="true"
                    />
                )}
            </li>
            {!isLast && node.children && (
                <BreadcrumbElement node={node.children[0]} className={className} theme={theme} />
            )}
        </>
    );
};
