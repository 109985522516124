import React from 'react';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import {
    getCurrentFeatures,
    getSearchQueryUid,
    getSearchQuery,
    getTotalResultsCount,
    getSelectedFilter,
    getSearchEngine,
} from '@lumapps/search/ducks/selectors';
import { BaseSearchResult } from '@lumapps/search/types';
import { useTranslate } from '@lumapps/translations';

import { GenericResultItem } from '../types';
import { getEntityProps } from '../utils/getEntityProps';

export const useResultsGenericItemsConverter = (
    items?: BaseSearchResult[],
    defaultValue: GenericResultItem[] | null = [],
): GenericResultItem[] | null => {
    const { translate, translateKey } = useTranslate();
    const features = useSelector(getCurrentFeatures);
    const currentInstanceId = useSelector(instanceIdSelector);
    const currentLanguage = useSelector(currentLanguageSelector);
    const searchQueryUid = useSelector(getSearchQueryUid);
    const searchQuery = useSelector(getSearchQuery);
    const totalCount = useSelector(getTotalResultsCount);
    const selectedTab = useSelector(getSelectedFilter);
    const searchEngine = useSelector(getSearchEngine);

    return React.useMemo(() => {
        if (!items) {
            return defaultValue;
        }

        return items.map((item) => {
            return getEntityProps({
                item,
                translate,
                translateKey,
                features,
                currentInstanceId,
                searchQuery,
                searchQueryUid,
                currentLanguage,
                shouldAddBaseSlug: true,
                totalCount: totalCount || undefined,
                selectedTab: selectedTab || undefined,
                searchEngine,
            });
        });
    }, [
        items,
        defaultValue,
        translate,
        translateKey,
        features,
        currentInstanceId,
        searchQuery,
        searchQueryUid,
        currentLanguage,
        totalCount,
        selectedTab,
        searchEngine,
    ]);
};

export const useResultsGenericItemConverter = (item: BaseSearchResult): GenericResultItem => {
    const { translate, translateKey } = useTranslate();
    const features = useSelector(getCurrentFeatures);
    const currentInstanceId = useSelector(instanceIdSelector);
    const currentLanguage = useSelector(currentLanguageSelector);
    const searchQueryUid = useSelector(getSearchQueryUid);
    const searchQuery = useSelector(getSearchQuery);
    const searchEngine = useSelector(getSearchEngine);

    return React.useMemo(() => {
        return getEntityProps({
            item,
            translate,
            translateKey,
            features,
            currentInstanceId,
            searchQuery,
            searchQueryUid,
            currentLanguage,
            shouldAddBaseSlug: true,
            searchEngine,
        });
    }, [
        item,
        translate,
        translateKey,
        features,
        currentInstanceId,
        searchQuery,
        searchQueryUid,
        currentLanguage,
        searchEngine,
    ]);
};
