import get from 'lodash/get';
import { hasProtocol } from '@lumapps/utils/string/isUrl';

/////////////////////////////

function WidgetFooterController($state, $window, Analytics, Instance, Media, Style, Translation) {
    'ngInject';

    /* eslint-disable consistent-this */
    const widgetFooter = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Wheter the link is to a content of the current instance or not.
     *
     * @type {boolean}
     */
    let _IS_INTERNAL_LINK;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Whether the footer is being hovered or not.
     *
     * @type {boolean}
     */
    widgetFooter.isHovering = false;

    /**
     * Link attribute to a Community|Space|content
     *
     * @type {Object}
     * @example { slug: 'content-slug', id: 'content-id', instance: { slug: 'instance-slug', id: 'instance-id' }}
     */
    widgetFooter.communityLink = undefined;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Clean the global properties if specific values are differents.
     * Used when properties has been set in normal mode with the lock and specific values are sets in hovering mode.
     *
     * @param  {Object} style The merged style with normal and hovering attributes.
     * @return {Object} The updated style.
     */
    function _cleanHoverStyle(style) {
        if (
            ((style.wrapper.borderTopWidth !== style.wrapper.borderBottomWidth) !== style.wrapper.borderLeftWidth) !==
            style.wrapper.borderRightWidth
        ) {
            delete style.wrapper.borderWidth;
        }

        if (
            ((style.wrapper.borderTopColor !== style.wrapper.borderBottomColor) !== style.wrapper.borderLeftColor) !==
            style.wrapper.borderRightColor
        ) {
            delete style.wrapper.borderColor;
        }

        if (
            ((style.wrapper.borderBottomLeftRadius !== style.wrapper.borderBottomRightRadius) !==
                style.wrapper.borderTopLeftRadius) !==
            style.wrapper.borderTopRightRadius
        ) {
            delete style.wrapper.borderRadius;
        }

        if (
            ((style.wrapper.marginTop !== style.wrapper.marginLeft) !== style.wrapper.marginBottom) !==
            style.wrapper.marginRight
        ) {
            delete style.wrapper.margin;
        }

        if (
            ((style.wrapper.paddingTop !== style.wrapper.paddingLeft) !== style.wrapper.paddingBottom) !==
            style.wrapper.paddingRight
        ) {
            delete style.wrapper.padding;
        }

        return style;
    }

    /**
     * Extract the query parameters of an url.
     *
     * @param  {string} urlToParse The url we want to get the query parameters.
     * @return {Array}  The query parameters.
     */
    function _getQueryParameters(urlToParse) {
        const regex = /[?&]([^=#]+)=([^&#]*)/g;
        const params = {};

        let match = regex.exec(urlToParse);
        while (angular.isDefined(match)) {
            params[match[1]] = match[2];
            match = regex.exec(urlToParse);
        }

        return params;
    }

    /**
     * Gets the footer style.
     *
     * @return {Object} The footer style.
     */
    function _getFooterStyle() {
        const { widget } = widgetFooter.parentCtrl;
        const isHovering = widgetFooter.isHovering || widget.state === 'hover';
        const footerStyle = angular.fastCopy(get(widget, 'properties.style.footer', {}));

        /* By default, the footer uses the mode "block" (display = block), so the background is applied on the wrapper
         * and we have to strip the main background.
         * If the footer uses the mode "inline" (display = flex), we strip the wrapper background. */
        const backgroundToStrip = get(footerStyle, 'main.display', 'block') === 'block' ? 'wrapper' : 'main';

        const backgroundPropertiesToReject = ['backgroundColor', 'backgroundImage'];

        angular.forEach(backgroundPropertiesToReject, (backgroundKey) => {
            // Remove the unwanted background.
            if (angular.isDefinedAndFilled(get(footerStyle, [backgroundToStrip, backgroundKey]))) {
                delete footerStyle[backgroundToStrip][backgroundKey];
            }

            // Remove the unwanted hover background.
            if (angular.isDefinedAndFilled(get(footerStyle, ['hover', backgroundToStrip, backgroundKey]))) {
                delete footerStyle.hover[backgroundToStrip][backgroundKey];
            }
        });

        return isHovering ? _cleanHoverStyle(angular.merge({}, footerStyle, footerStyle.hover || {})) : footerStyle;
    }

    /**
     * Build footer link with parameters.
     *
     * @param {Object} params         Href parameters.
     * @param {string} translatedLink Translated slug.
     */
    function _buildLinkWithParameters(params, translatedLink) {
        params = {
            ...params,
            ..._getQueryParameters(translatedLink),
        };
        widgetFooter.footerLink = $state.href('app.front.content-get', params);
        widgetFooter.footerTarget = '_self';
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Build footer link.
     *
     * @param {Object} widget The widget.
     */
    function buildFooterLink(widget) {
        const { link = undefined, linkInstance = undefined } = get(widget, 'properties.more', {});

        if (angular.isUndefinedOrEmpty(link)) {
            return;
        }

        const translatedLink = Translation.translate(link);

        if (angular.isUndefinedOrEmpty(translatedLink)) {
            return;
        }

        Analytics.handleTaggingMap('widget-footer_click', 'button', {
            widget,
        });

        const params = {
            slug: translatedLink.split('?')[0],
        };

        if (hasProtocol(translatedLink)) {
            _IS_INTERNAL_LINK = false;

            widgetFooter.footerLink = translatedLink;
            widgetFooter.footerTarget = '_blank';

        } else if (params && params.slug.startsWith('ls/community')) {
            if (params && params.slug.startsWith('ls/community')) {
                widgetFooter.communityLink = {
                    instance: {
                        id: linkInstance && linkInstance !== Instance.getCurrentInstanceId() ? linkInstance : Instance.getCurrentInstanceId()
                    },
                    slug: params.slug.split('/').pop(),
                }
            }
        } else if (linkInstance && linkInstance !== Instance.getCurrentInstanceId()) {
            _IS_INTERNAL_LINK = false;

            Instance.getInstanceById(
                linkInstance,
                (targetedInstance) => {
                    if (angular.isDefinedAndFilled(targetedInstance)) {
                        params.instance = targetedInstance.slug;
                    }

                    _buildLinkWithParameters(params, translatedLink);
                },
                () => {
                    _buildLinkWithParameters(params, translatedLink);
                },
            );
        } else {
            _IS_INTERNAL_LINK = true;
            _buildLinkWithParameters(params, translatedLink);
        }
    }

    /**
     * Get widget footer font style according to widget properties.
     *
     * @return {Object} The widget footer font style.
     */
    function getWidgetFooterLabelStyle() {
        return _getFooterStyle().label || {};
    }

    /**
     * Gets the widget footer icon style.
     *
     * @return {Object} The widget footer icon style.
     */
    function getWidgetFooterIconStyle() {
        return _getFooterStyle().icon || {};
    }

    /**
     * Get wrapper classes.
     *
     * @return {Array} The wrapper CSS classes.
     */
    function getWrapperClasses() {
        const wrapperClasses = [];
        const widgetStyle = widgetFooter.parentCtrl.widget.properties.style;

        const flexDirection = get(widgetStyle, 'footer.wrapper.flexDirection') || 'row';

        if (flexDirection === 'row') {
            wrapperClasses.push('widget-footer__wrapper--layout-left');
        }

        if (flexDirection === 'row-reverse') {
            wrapperClasses.push('widget-footer__wrapper--layout-right');
        }

        if (flexDirection === 'column') {
            wrapperClasses.push('widget-footer__wrapper--layout-top');
        }

        if (flexDirection === 'column-reverse') {
            wrapperClasses.push('widget-footer__wrapper--layout-bottom');
        }

        return wrapperClasses;
    }

    /**
     * Get widget footer style according to widget properties.
     *
     * @return {Object} The widget footer style.
     */
    function getWidgetFooterStyle() {
        const mainStyle = angular.fastCopy(_getFooterStyle().main) || {};

        return Style.adjustShadow(Media.adjustBackgroundImage(mainStyle));
    }

    /**
     * Get widget footer wrapper style according to widget properties.
     *
     * @return {Object} The widget footer wrapper style.
     */
    function getWidgetFooterWrapperStyle() {
        const wrapperStyle = _getFooterStyle().wrapper || {};

        return Style.adjustShadow(Media.adjustBackgroundImage(wrapperStyle));
    }

    /**
     * Handle click on footer link.
     *
     * @param {Event}  evt    The triggering click.
     * @param {Object} widget The widget.
     */
    function handleFooterLink(evt, widget) {
        if (!_IS_INTERNAL_LINK) {
            return;
        }

        evt.preventDefault();

        const link = get(widget, 'properties.more.link');

        if (angular.isUndefinedOrEmpty(link)) {
            return;
        }

        const translatedLink = Translation.translate(link);

        if (angular.isUndefinedOrEmpty(translatedLink)) {
            return;
        }

        const stateParams = _getQueryParameters(translatedLink) || {};
        stateParams.slug = translatedLink.split('?')[0];
        $state.go('app.front.content-get', stateParams);
    }

    /**
     * Sets the isHovering state.
     *
     * @param {boolean} isHovering Indicates if the footer is hovered or not.
     */
    function setIsHovering(isHovering) {
        widgetFooter.isHovering = isHovering;
    }

    /////////////////////////////

    widgetFooter.buildFooterLink = buildFooterLink;
    widgetFooter.getWrapperClasses = getWrapperClasses;
    widgetFooter.getWidgetFooterLabelStyle = getWidgetFooterLabelStyle;
    widgetFooter.getWidgetFooterIconStyle = getWidgetFooterIconStyle;
    widgetFooter.getWidgetFooterStyle = getWidgetFooterStyle;
    widgetFooter.getWidgetFooterWrapperStyle = getWidgetFooterWrapperStyle;
    widgetFooter.handleFooterLink = handleFooterLink;
    widgetFooter.setIsHovering = setIsHovering;

    /////////////////////////////

    /**
     * Initialize controller.
     */
    widgetFooter.init = () => {
        widgetFooter.buildFooterLink(widgetFooter.parentCtrl.widget);
    };

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        widgetFooter.parentCtrl = parentCtrl;
        widgetFooter.init();
    };
}

/////////////////////////////

function WidgetFooterDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetFooterController,
        controllerAs: 'widgetFooter',
        link,
        replace: true,
        require: ['lsWidgetFooter', '^widget'],
        restrict: 'E',
        templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-footer.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsWidgetFooter', WidgetFooterDirective);

/////////////////////////////

export { WidgetFooterDirective };
