/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { GetBaseUrl } from '@lumapps/learning-utils/getBaseUrl';

import { LearningRolesData, LaunchUrlData } from '../types';

/**
 * getLearningRoles API to retrieve roles data of learning user.
 *
 */
const LearningRolesApi = (baseURL: string) =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL,
    });
const paths = {
    roles: 'identity/v2/users/current/roles/codes',
    launchUrl: 'identity/v2/authentication/mc/autologin',
};

export async function getLearningRoles(learningAlphaEnabled: boolean): Promise<LearningRolesData> {
    const baseUrl = GetBaseUrl(learningAlphaEnabled);
    const { data } = await LearningRolesApi(baseUrl).get<LearningRolesData>(paths.roles);
    return data;
}

export async function getMCLaunchUrl(learningAlphaEnabled: boolean): Promise<LaunchUrlData> {
    const baseUrl = GetBaseUrl(learningAlphaEnabled);
    const { data } = await LearningRolesApi(baseUrl).get<LaunchUrlData>(paths.launchUrl);
    return data;
}
