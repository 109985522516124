import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { classnames, margin, padding } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiInformationOutline } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, IconButton, Placement, Popover, Text, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useEventListener } from '@lumapps/utils/hooks/useEventListener';

import { SCOPE, SEGMENT_EMPTY_DESC_PLACEHOLDER, SEGMENT_POPOVER_CLASSNAME } from '../../constants';
import { useSegment } from '../../hooks/useSegment';
import { Segment } from '../../types';
import { SegmentSizeField } from '../SegmentSizeField/SegmentSizeField';
import { SegmentDetailsPopoverSkeleton } from './SegmentDetailsPopoverSkeleton';

import './SegmentDetailsPopover.scss';

export interface SegmentDetailsPopoverProps {
    segmentId: string;
    segmentInfo?: Segment;
    scope?: string;
}

export const SegmentDetailsPopover = ({ segmentId, segmentInfo, scope }: SegmentDetailsPopoverProps) => {
    const { get } = useDataAttributes(scope || SCOPE);
    const ref = React.useRef<HTMLButtonElement | null>(null);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

    // If we already the segment info, we don't need to fetch it again
    const {
        isLoading,
        segment: fetchedSegment,
        cancelFetch,
    } = useSegment({
        fetchOnMount: isPopoverOpen && !segmentInfo,
        segmentId,
        withCache: true,
    });

    const { translateObject, translateKey } = useTranslate();
    const segmentDescriptionTitle = translateKey(GLOBAL.DESCRIPTION);
    const segmentSizeTitle = translateKey(GLOBAL.SIZE);
    const segment = segmentInfo || fetchedSegment;

    const onMouseEnter = (evt: MouseEvent) => {
        ref.current = evt.target as HTMLButtonElement;
        setIsPopoverOpen(true);
    };

    const onMouseLeave = () => {
        setIsPopoverOpen(false);
        cancelFetch();
    };

    // Need to close the popover when the user presses escape
    const onKeyDown = React.useCallback((event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            setIsPopoverOpen(false);
        }
    }, []);

    useEventListener('keydown', onKeyDown, ref);

    if (!segmentId && !isLoading) {
        return null;
    }

    return (
        <>
            <IconButton
                icon={mdiInformationOutline}
                emphasis={Emphasis.low}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                label=""
                className={`${SEGMENT_POPOVER_CLASSNAME}__icon`}
                {...get({ element: 'segment-picker-info' })}
            />

            {isLoading && (
                <SegmentDetailsPopoverSkeleton
                    anchorRef={ref}
                    isOpen={isPopoverOpen}
                    descriptionTitle={segmentDescriptionTitle}
                    sizeTitle={segmentSizeTitle}
                />
            )}

            {segment && (
                <Popover
                    anchorRef={ref}
                    isOpen={isPopoverOpen}
                    className={classnames(padding('all', 'big'), SEGMENT_POPOVER_CLASSNAME)}
                    closeOnEscape
                    closeOnClickAway
                    placement={Placement.BOTTOM_START}
                    {...get({ element: 'segment-picker-info' })}
                >
                    <FlexBox orientation="vertical" fillSpace>
                        <>
                            <div className={margin('bottom', 'regular')}>
                                <Text
                                    as="h2"
                                    className={padding('bottom', 'regular')}
                                    typography={Typography.subtitle1}
                                >
                                    {segmentDescriptionTitle}
                                </Text>
                                <Text as="p" typography={Typography.body1}>
                                    {isEmpty(segment.description?.translations)
                                        ? SEGMENT_EMPTY_DESC_PLACEHOLDER
                                        : translateObject(segment.description?.translations)}
                                </Text>
                            </div>
                            <div>
                                <Text
                                    as="h2"
                                    className={padding('bottom', 'regular')}
                                    typography={Typography.subtitle1}
                                >
                                    {segmentSizeTitle}
                                </Text>
                                <SegmentSizeField
                                    canCalculateSize={false}
                                    layout="line"
                                    currentSize={segment.preview?.approxCount}
                                    lastUpdateAt={segment.preview?.computedAt}
                                    mode="popover"
                                />
                            </div>
                        </>
                    </FlexBox>
                </Popover>
            )}
        </>
    );
};
