import React from 'react';

import { classnames } from '@lumapps/classnames';
import { TextField, Heading } from '@lumapps/lumx/react';
import { VideoItemFieldOrder } from '@lumapps/play-video/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { GenericSwitchList } from '@lumapps/widget-base/components/WidgetSettings/GenericSwitchList';
import { Setting } from '@lumapps/widget-base/types/setting';

import { WIDGET_PLAYLIST } from '../../../keys';
import { WidgetPlaylistProperties } from '../../../types';

import './index.scss';

export type VideoMetadata = Pick<WidgetPlaylistProperties, 'videoItemFieldOrder' | 'maxNumber'>;

export interface VideoMetadataSelectionProps {
    className?: string;
    properties: VideoMetadata;
    isLoading?: boolean;
    onFieldChange: <K extends keyof WidgetPlaylistProperties>(fieldName: K) => (nextValue: VideoItemFieldOrder) => void;
}

const CLASSNAME = 'video-metadata-selection';

/**
 *
 * @param VideoMetadataSelectionProps
 * @returns VideoMetadataSelection
 */
export const VideoMetadataSelection = ({
    className,
    properties,
    isLoading,
    onFieldChange,
}: VideoMetadataSelectionProps) => {
    const { translateKey } = useTranslate();
    const settings: Setting<VideoItemFieldOrder>[] = [
        {
            label: translateKey(GLOBAL.THUMBNAIL),
            name: 'thumbnail',
        },
        {
            label: translateKey(GLOBAL.TITLE),
            name: 'title',
        },
        {
            label: translateKey(GLOBAL.DESCRIPTION),
            name: 'description',
        },
        {
            label: translateKey(GLOBAL.AUTHOR),
            name: 'author',
        },
        {
            label: translateKey(GLOBAL.DATE),
            name: 'date',
        },
        {
            label: translateKey(GLOBAL.VIEWS),
            name: 'views',
        },
        {
            label: translateKey(GLOBAL.DURATION),
            name: 'duration',
        },
    ];

    return (
        <div className={classnames(className, CLASSNAME)}>
            <Heading as="h5" className={`${CLASSNAME}__subtitle`}>
                {translateKey(WIDGET_PLAYLIST.LIST_OF_VIDEOS)}
            </Heading>
            <TextField
                className={`${CLASSNAME}__displayed-video`}
                onChange={(value) => onFieldChange('maxNumber')(Number(value) as unknown as VideoItemFieldOrder)}
                label={translateKey(WIDGET_PLAYLIST.DISPLAYED_VIDEO_NUMBER)}
                value={properties?.maxNumber?.toString()} // TODO: (play) Figure out why DEFAULT_WIDGET_PROPERTIES was not taken into account
                type="number"
            />
            <GenericSwitchList<VideoItemFieldOrder>
                className={`${CLASSNAME}__switch-list`}
                items={settings}
                order={properties.videoItemFieldOrder}
                onChange={onFieldChange('videoItemFieldOrder')}
                isLoading={isLoading}
            />
        </div>
    );
};
