export enum JOURNEY_CORE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=217801839
     */
    DAYS_AFTER_BASELINE_DATE = 'ITEM_CONFIGURATIONS.DAYS_AFTER_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=217802152
     */
    DAYS_BEFORE_BASELINE_DATE = 'ITEM_CONFIGURATIONS.DAYS_BEFORE_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=340411881
     */
    ACKNOWLEDGMENT = 'JOURNEYS_CORE.ACKNOWLEDGMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339582211
     */
    ACKNOWLEDGE_BUTTON_LABEL = 'JOURNEYS_CORE.ACKNOWLEDGE_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=309497511
     */
    ATTACHMENT_MANDATORY = 'JOURNEYS_CORE.ATTACHMENT_MANDATORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194265378
     */
    BASELINE_DATE = 'JOURNEYS_CORE.BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194265481
     */
    BASELINE_DATE_NAME = 'JOURNEYS_CORE.BASELINE_DATE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=341964154
     */
    BASELINE_DATE_NAME_OFFSET_DETAIL = 'JOURNEYS_CORE.BASELINE_DATE_NAME_OFFSET_DETAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=188190765
     */
    CAMPAIGNS = 'CAMPAIGNS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24948751
     */
    CHANNEL = 'FRONT.WIDGET_CHAT.SETTINGS.CHANNEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=448461784
     */
    CHANNEL_MODIFIED_NOT_ALLOWED = 'JOURNEY_CORE.CHANNEL_MODIFIED_NOT_ALLOWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=283412156
     */
    CHANNEL_DESCRIPTION = 'JOURNEYS_CORE.CHANNEL_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=283412240
     */
    CHANNEL_TITLE = 'JOURNEYS_CORE.CHANNEL_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222922829
     */
    DAY = 'JOURNEYS_CORE.DAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=330274473
     */
    DESCRIBE_FOR_INTERNAL = 'JOURNEYS_CORE.DESCRIBE_FOR_INTERNAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232153663
     */
    DESCRIPTION_CLICKED = 'JOURNEYS_CORE.DESCRIPTION_CLICKED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232157985
     */
    DESCRIPTION_FAILED = 'JOURNEYS_CORE.DESCRIPTION_FAILED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232157353
     */
    DESCRIPTION_OPENED_DELIVERED_SENT_PENDING = 'JOURNEYS_CORE.DESCRIPTION_OPENED_DELIVERED_SENT_PENDING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232162780
     */
    DESCRIPTION_SCHEDULED_NOTSENT = 'JOURNEYS_CORE.DESCRIPTION_SCHEDULED_NOTSENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232960227
     */
    EMPTY_CHANNEL = 'JOURNEYS_CORE.EMPTY_CHANNEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232960252
     */
    EMPTY_MESSAGE = 'JOURNEYS_CORE.EMPTY_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232960647
     */
    EMPTY_RESOURCE = 'JOURNEYS_CORE.EMPTY_RESOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=374411805
     */
    EMPTY_SENDER = 'JOURNEYS_CORE.EMPTY_SENDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232963386
     */
    EMPTY_SUBJECT = 'JOURNEYS_CORE.EMPTY_SUBJECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384689958
     */
    EMPTY_TITLE = 'JOURNEYS_CORE.EMPTY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232131628
     */
    ENROLLMENT_PROGRESS = 'JOURNEYS_CORE.ENROLLMENT_PROGRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248329469
     */
    ERROR_LOADING_CHANNELS = 'JOURNEYS_CORE.ERROR_LOADING_CHANNELS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40827676
     */
    EVENT = 'FRONT.COMMUNITY.POST.TYPE.EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=307535368
     */
    FIELD_IS_MANDATORY = 'JOURNEYS_CORE.FIELD_IS_MANDATORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=223329538
     */
    GOOGLE_CHAT = 'JOURNEYS.GOOGLE_CHAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=451547120
     */
    JOURNEY = 'JOURNEY_CORE.JOURNEY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=215448519
     */
    JOURNEYS = 'JOURNEY_ADMIN.JOURNEYS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339649793
     */
    MARK_AS_DONE = 'JOURNEYS_CORE.MARK_AS_DONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232963426
     */
    MESSAGE_COLORED_KEYWORDS_PERSONALIZATION = 'JOURNEYS_CORE.MESSAGE_COLORED_KEYWORDS_PERSONALIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232963426
     */
    MESSAGE_KEYWORDS_PERSONALIZATION = 'JOURNEYS_CORE.MESSAGE_KEYWORDS_PERSONALIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193191169
     */
    NUMBER_DRAFT_REMAINING = 'JOURNEYS_CORE.NUMBER_DRAFT_REMAINING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409938916
     */
    OCCURRENCES_PARTICIPANTS = 'ENROLLMENTS.OCCURENCES_PARTICIPANTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=217802225
     */
    ON_BASELINE_DATE = 'ITEM_CONFIGURATIONS.ON_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=298855007
     */
    PASTE_EXTERNAL_LINK = 'JOURNEYS_CORE.PASTE_EXTERNAL_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281769332
     */
    PICK_A_RESOURCE = 'JOURNEYS_CORE.PICK_A_RESOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=250619902
     */
    PICK_AN_ARTICLE = 'JOURNEYS_CORE.PICK_AN_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=197867175
     */
    PICK_AN_EVENT = 'JOURNEYS_CORE.PICK_AN_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500251
     */
    PICK_COMMUNITY_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_COMMUNITY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500146
     */
    PICK_CONTENT_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_CONTENT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500646
     */
    PICK_EXTERNAL_LINK_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_EXTERNAL_CONTENT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=259447862
     */
    PICK_PLAYLIST_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_PLAYLIST_VIDEO_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203046526
     */
    PICK_VIDEO_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_VIDEO_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210469330
     */
    PREVIEW_NOTIFICATION_SUCCESS = 'BROADCASTS.PREVIEW_NOTIFICATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457711320
     */
    PUSH_MESSAGE = 'JOURNEYS_CORE.PUSH_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=233072486
     */
    PUSH_NOTIFICATION = 'JOURNEYS_CORE.PUSH_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232963462
     */
    RESOURCE = 'JOURNEYS_CORE.RESOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=266225343
     */
    RESOURCE_MODIFIED_NOT_ALLOWED = 'JOURNEY_CORE.RESOURCE_MODIFIED_NOT_ALLOWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294243466
     */
    RESOURCE_DESCRIPTION = 'JOURNEYS_CORE.RESOURCE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=295009331
     */
    RESOURCE_MESSAGE_PLACEHOLDER = 'JOURNEYS_CORE.RESOURCE_MESSAGE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294243321
     */
    RESOURCE_TITLE = 'JOURNEYS_CORE.RESOURCE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=309504223
     */
    OPTION_REQUIRED = 'JOURNEYS_CORE.OPTION_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232964855
     */
    SELECT_A_TYPE_OF_CHANNEL_TO_BROADCAST = 'JOURNEYS_CORE.SELECT_A_TYPE_OF_CHANNEL_TO_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232970246
     */
    SELECT_A_TYPE_OF_RESOURCE_TO_BROADCAST = 'JOURNEYS_CORE.SELECT_A_TYPE_OF_RESOURCE_TO_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232976385
     */
    SEND_EMAIL_AUDIENCE = 'JOURNEYS_CORE.SEND_EMAIL_AUDIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247839630
     */
    SEND_MESSAGE_GOOGLE_CHAT = 'JOURNEYS_CORE.SEND_MESSAGE_GOOGLE_CHAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247838911
     */
    SEND_MESSAGE_SLACK = 'JOURNEYS_CORE.SEND_MESSAGE_SLACK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=198474624
     */
    SEND_MESSAGE_TEAMS = 'JOURNEYS_CORE.SEND_MESSAGE_TEAMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194819018
     */
    SEND_NOTIFICATION_AUDIENCE = 'JOURNEYS_CORE.SEND_NOTIFICATION_AUDIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294638002
     */
    SEND_ON_THE_BASELINE_DATE = 'JOURNEYS_CORE.SEND_ON_THE_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210315672
     */
    SEND_TEST_NOTIFICATION = 'BROADCASTS.SEND_TEST_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232977022
     */
    SEND_VIA_CHANNEL = 'JOURNEYS_CORE.SEND_VIA_CHANNEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232977124
     */
    SENDER_NAME = 'JOURNEYS_CORE.SENDER_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232979163
     */
    SENDER_NAME_HELPER_TEXT = 'JOURNEYS_CORE.SENDER_NAME_HELPER_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232979972
     */
    SENT_VIA_CHANNEL = 'JOURNEYS_CORE.SENT_VIA_CHANNEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=225714030
     */
    SCHEDULE_BASED_ON_THE_BASELINE_DATE = 'ITEM_CONFIGURATIONS.SCHEDULE_BASED_ON_THE_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=225714812
     */
    SCHEDULE_ON_THE_BASELINE_DATE = 'ITEM_CONFIGURATIONS.SCHEDULE_ON_THE_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78907666
     */
    SHARE_VIA_GOOGLE_CHAT = 'FRONT.SHARE.SHARE_VIA_GOOGLE_CHAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68817054
     */
    SHARE_VIA_SLACK = 'FRONT.SHARE.SHARE_VIA_SLACK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232979986
     */
    THUMBNAIL_VISIBILITY = 'JOURNEYS_CORE.THUMBNAIL_VISIBILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241948507
     */
    TOOLTIP_COMPLETED = 'JOURNEYS_CORE.TOOLTIP_COMPLETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241950360
     */
    TOOLTIP_PENDING = 'JOURNEYS_CORE.TOOLTIP_PENDING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241950336
     */
    TOOLTIP_NOTDELIVERED = 'JOURNEYS_CORE.TOOLTIP_NOTDELIVERED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241950587
     */
    TOOLTIP_SCHEDULED = 'JOURNEYS_CORE.TOOLTIP_SCHEDULED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=249622885
     */
    UNKNOWN_CHANNEL = 'JOURNEYS_CORE.UNKNOWN_CHANNEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339649806
     */
    UNMARK_AS_DONE = 'JOURNEYS_CORE.UNMARK_AS_DONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901113
     * @todo: Change the id of this key to make it more generic
     */
    VIDEO = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=330276921
     */
    VISIBILITY_HELPER = 'JOURNEYS_CORE.VISIBILITY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222922909
     */
    WEEK = 'JOURNEYS_CORE.WEEK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230219617
     */
    WEEKS_AFTER_BASELINE_DATE = 'ITEM_CONFIGURATIONS.WEEKS_AFTER_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230219807
     */
    WEEKS_BEFORE_BASELINE_DATE = 'ITEM_CONFIGURATIONS.WEEKS_BEFORE_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=233071204
     */
    WITH_RESOURCE = 'JOURNEYS_CORE.WITH_RESOURCE',
}
