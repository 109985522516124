import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const siteAnalyticsRoutesMatch = '/admin/site-analytics/:slug';
const baseSiteAnalyticsRoute: Route = {
    path: '/admin/site-analytics',
    legacyId: 'app.admin.site-analytics',
    appId: AppId.backOffice,
};

const analytics = (slug?: string): Route => {
    return {
        ...baseSiteAnalyticsRoute,
        instanceSlug: slug,
    };
};

export { analytics, baseSiteAnalyticsRoute as siteAnalyticsRoute, siteAnalyticsRoutesMatch };
