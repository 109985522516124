import React, { Fragment, useEffect, useRef } from 'react';
import { arrayOf, bool, func, oneOfType, string } from 'prop-types';

import { mdiClose, mdiMagnify } from '@lumapps/lumx/icons';
import {
    Chip,
    Icon,
    List,
    ListDivider,
    ListItem,
    ListSubheader,
    Select,
    SelectMultiple,
    Size,
} from '@lumapps/lumx/react';

import { curry, isFunction, reject } from 'lodash';

import { translate as t } from 'components/translations';

import { choicesType, groupedChoicesType, valueObjectType } from './types';

/**
 * Get value object ID (identity if string value, id/uid if object).
 *
 * @param  {Object}      value Value object (@see valueObjectType).
 * @return {string|null} Value ID or null.
 */
const getValueId = (value) => {
    if (value && 'uid' in value) {
        return String(value.uid);
    }
    if (value && 'id' in value) {
        return String(value.id);
    }

    return null;
};

/**
 * Check whether two values (@see valueObjectType) have the same ID.
 */
const sameValue = curry((value1, value2) => {
    return getValueId(value1) === getValueId(value2);
});

/**
 * Check if the selection contains a value.
 *
 * @param  {Array}         selection Selection
 * @param  {string|Object} value     Value
 * @return {boolean}       true if value is in selection;
 */
const selectionIncludes = (selection, value) => selection.some(sameValue(value));

/**
 * Render value to text.
 *
 * @param  {string} selectedValue Value.
 * @return {string} Rendered value.
 */
const renderValue = (selectedValue) => {
    if (typeof selectedValue === 'string') {
        return t(selectedValue);
    }
    if (typeof selectedValue === 'object' && 'name' in selectedValue) {
        return t(selectedValue.name);
    }

    return selectedValue;
};

const defaultChoiceTextRenderer = (choice) => t(choice.name);

/**
 * Build a choice renderer (@see choicesType).
 *
 * @param  {Function} renderChoiceText Choice to text renderer.
 * @return {Function} Choice renderer.
 */
// eslint-disable-next-line react/display-name
const choiceRenderFactory = (renderChoiceText = defaultChoiceTextRenderer) => (choice, value, onSelect) => {
    const isSelected = selectionIncludes(value, choice);

    return (
        <ListItem key={getValueId(choice)} isSelected={isSelected} onItemSelected={onSelect(choice, isSelected)}>
            {renderChoiceText(choice)}
        </ListItem>
    );
};

/**
 * Build a grouped choice renderer (@see groupedChoicesType).
 *
 * @param  {Function} renderGroupName Group to group name renderer.
 * @param  {Function} renderChoice    Choice renderer.
 * @return {Function} Choice renderer.
 */
// eslint-disable-next-line react/display-name
const groupedChoiceRenderFactory = (renderGroupName, renderChoice) => ({ group, children }, value, onSelect) => (
    <Fragment key={renderGroupName(group)}>
        <ListSubheader>{renderGroupName(group)}</ListSubheader>
        {children.map((choice) => renderChoice(choice, value, onSelect))}
    </Fragment>
);

/**
 * Trigger focus on search field element when the select is open.
 *
 * @param {boolean}          isOpen      Select open status.
 * @param {HTMLInputElement} searchField Search field element.
 */
const useFocusOnOpen = (isOpen, searchField) => {
    useEffect(() => {
        if (isOpen && searchField) {
            searchField.focus();
        }
    }, [isOpen, searchField]);
};

/**
 * Wrapper around the Select component handling simple & multiple selection of
 * value object (name/uid objects @see valueObjectType).
 *
 * @param  {Object}       props Component props.
 * @return {ReactElement} React element.
 */
const SelectField = ({ label, isOpen, value, choices, searchText, setOpen, onSearch, onChange, children }) => {
    const isMultiple = Array.isArray(value);
    const valueArray = isMultiple ? value : [value];
    if (!isFunction(children)) {
        throw new Error('The SelectField should have a function children used to render a choice.');
    }

    const onSearchFieldChange = (evt) => onSearch(evt.target.value);
    const searchFieldRef = useRef(null);
    useFocusOnOpen(isOpen, searchFieldRef.current);

    const onSelect = (choice, wasSelected = selectionIncludes(valueArray, choice)) => () => {
        if (isMultiple) {
            const newValue = wasSelected ? reject(valueArray, sameValue(choice)) : [...valueArray, choice];
            onChange(newValue);
        } else if (getValueId(valueArray) !== getValueId(choice)) {
            onChange(choice);
            setOpen(false);
        }
    };
    const toggleSelect = () => setOpen(!isOpen);
    const closeSelect = () => isOpen && setOpen(false);

    const selectedChipRender = (selectedValue) => (
        <Chip
            key={getValueId(selectedValue)}
            after={<Icon icon={mdiClose} size={Size.xxs} />}
            className="select-field__chip"
            size={Size.s}
            onAfterClick={onSelect(selectedValue, true)}
            onClick={onSelect(selectedValue, true)}
        >
            {renderValue(selectedValue)}
        </Chip>
    );

    const SelectComponent = isMultiple ? SelectMultiple : Select;

    return (
        <SelectComponent
            isOpen={isOpen}
            label={label}
            selectedChipRender={selectedChipRender}
            selectedValueRender={renderValue}
            value={value}
            onDropdownClose={closeSelect}
            onInputClick={toggleSelect}
        >
            <List isClickable>
                {onSearch && (
                    <>
                        <div className="select-field__search">
                            <Icon icon={mdiMagnify} size={Size.s} />
                            <input ref={searchFieldRef} value={searchText} onChange={onSearchFieldChange} />
                        </div>
                        <ListDivider />
                    </>
                )}
                {choices.length === 0 ? (
                    <ListItem>{t('NO_RESULTS')}</ListItem>
                ) : (
                    choices.map((choice) => children(choice, valueArray, onSelect))
                )}
            </List>
        </SelectComponent>
    );
};

SelectField.defaultProps = {
    isOpen: false,
    label: undefined,
    onSearch: undefined,
    searchText: '',
};

SelectField.propTypes = {
    children: func.isRequired,
    choices: oneOfType([groupedChoicesType, choicesType]).isRequired,
    isOpen: bool,
    label: string,
    onChange: func.isRequired,
    onSearch: func,
    searchText: string,
    setOpen: func.isRequired,
    value: oneOfType([string, arrayOf(string), arrayOf(valueObjectType), valueObjectType]).isRequired,
};

export { defaultChoiceTextRenderer, choiceRenderFactory, groupedChoiceRenderFactory, SelectField };
