import React, { useState, useRef } from 'react';

import * as propTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { IconButton, Emphasis, Dropdown, List, ListItem, Size } from '@lumapps/lumx/react';
import { mdiDotsVertical } from '@lumapps/lumx/icons';

import { translate as t } from 'components/translations';

/**
 * UI component that display a call to action button to display.
 * An anchored Menu.
 *
 * @param  {Array}        menuOptions Options to display in the context menu.
 * @return {ReactElement} The Shareable content box.
 */
const ContextMenu = ({ menuOptions, ...iconProps }) => {
    const [menuOpen, setOpen] = useState(false);
    const anchorEl = useRef(null);

    /**
     * Hide the menu.
     */
    const requestClose = () => {
        setOpen(false);
    };

    /**
     * Action on item clicked.
     *
     * @param {Object} option The option clicked.
     */
    const onItemClicked = (option) => {
        option.onSelect();
        requestClose();
    };

    /**
     * Switch the visibility of the Menu list OFF or ON.
     */
    const toggleMenuVisibility = () => {
        setOpen(!menuOpen);
    };

    return (
        <>
            <IconButton
                ref={anchorEl}
                emphasis={Emphasis.low}
                icon={mdiDotsVertical}
                onClick={toggleMenuVisibility}
                label=""
                {...iconProps}
            />
            <Dropdown
                closeOnClickAway
                closeOnEscape
                anchorRef={anchorEl}
                isOpen={menuOpen}
                onClose={toggleMenuVisibility}
            >
                <List isClickable>
                    {!isEmpty(menuOptions) &&
                        menuOptions.map((option) => (
                            <ListItem
                                key={option.labelKey}
                                before={option.icon}
                                size={Size.tiny}
                                onItemSelected={() => onItemClicked(option)}
                            >
                                {t(option.labelKey)}
                            </ListItem>
                        ))}
                </List>
            </Dropdown>
        </>
    );
};

ContextMenu.propTypes = {
    /* * Menu entries. */
    menuOptions: propTypes.arrayOf(
        propTypes.shape({
            icon: propTypes.element,
            labelKey: propTypes.string,
            onSelect: propTypes.func,
        }),
    ).isRequired,
};

export { ContextMenu };
