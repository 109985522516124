import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiLoginVariant } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { MOBILE_LOGIN } from '../../keys';
import { adminMobileLoginHelper, adminMobileLoginQrCode } from '../../routes';

export interface MobileLoginConfigurationSideNavigationProps {
    isAllowed: boolean;
    isQrCodeFeatureEnabled: boolean;
}

const MobileLoginConfigurationSideNavigation = ({
    isAllowed,
    isQrCodeFeatureEnabled,
}: MobileLoginConfigurationSideNavigationProps) => {
    const { translateKey } = useTranslate();
    if (!isAllowed) {
        return null;
    }
    return (
        <SideNavigationItem
            id="mobile-login"
            label={translateKey(MOBILE_LOGIN.MOBILE_SETTINGS_LOGIN)}
            icon={mdiLoginVariant}
        >
            {isQrCodeFeatureEnabled && (
                <SideNavigationItem
                    label={translateKey(MOBILE_LOGIN.QR_CODE_ACCESS)}
                    route={adminMobileLoginQrCode()}
                />
            )}

            <SideNavigationItem
                label={translateKey(MOBILE_LOGIN.LOGIN_HELP_URL_PAGE_NAME)}
                route={adminMobileLoginHelper()}
            />
        </SideNavigationItem>
    );
};

MobileLoginConfigurationSideNavigation.displayName = 'SideNavigationItem';

export { MobileLoginConfigurationSideNavigation };
