import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ImageLightbox, useImageLightbox } from '@lumapps/lumx-images/components/ImageLightbox';
import { Mosaic, Thumbnail, ThumbnailProps } from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import { ElementRender } from '@lumapps/wrex/types';

import { CLASSNAME, IMAGE_GROUP, IMAGE_WIDTHS } from '../../../constants';
import { ImageGroupElement } from '../../../types';

import './index.scss';

export interface EnhancedImageProps {
    imgRef?: React.Ref<HTMLImageElement>;
    isInEditionMode?: boolean;
}

/**
 * Render image group as single image or mosaic images
 */
export const EnhancedImage: ElementRender<ImageGroupElement, HTMLDivElement, EnhancedImageProps> = (props) => {
    const { className, elementRef, element, children, isInEditionMode, imgRef, ...forwardedProps } = props;
    const theme = useThemeContext();
    const { element: elementClass, block: blockClass } = useClassnames(CLASSNAME);

    const { images = [], title, isLoading } = element ?? {};

    const isFullWidth = element?.width === IMAGE_WIDTHS.fullWidth;
    const isHalfWidth = element?.width === IMAGE_WIDTHS.halfWidth;

    const thumbnails = useMemo(
        () =>
            images.map(({ src = '', alt = '', tempSrc, height, width, link = '' }): ThumbnailProps => {
                const image = tempSrc || makeSecuredMediaURLRelative(src) || '';
                const imgProps = { height, width };
                const linkProps = link && !isInEditionMode ? { href: link } : undefined;

                return { image, title, alt, imgProps, linkProps };
            }),
        [images, title, isInEditionMode],
    );

    const { getTriggerProps, imageLightboxProps } = useImageLightbox(thumbnails);

    const getContextTriggerProps = (activeImageIndex: number) => {
        if (isInEditionMode) {
            return undefined;
        }
        return getTriggerProps({ activeImageIndex });
    };

    return (
        <figure
            {...forwardedProps}
            ref={elementRef}
            className={blockClass(
                {
                    'is-loading': !!isLoading,
                    multiple: thumbnails.length > 1,
                    'full-width': isFullWidth,
                    'half-width': isHalfWidth,
                },
                className,
            )}
        >
            {thumbnails.length === 1 && (
                <Thumbnail
                    className={elementClass('thumbnail')}
                    imgRef={imgRef}
                    {...thumbnails[0]}
                    {...getContextTriggerProps(0)}
                    contentEditable={false}
                    linkProps={{
                        ...thumbnails[0].linkProps,
                        target: '_blank',
                        rel: 'noopener noreferrer',
                    }}
                    linkAs={thumbnails[0].linkProps ? 'a' : undefined}
                    imgProps={{
                        ...thumbnails[0].imgProps,
                        // Inline width style to reverse space while loading
                        style: { width: isFullWidth ? undefined : thumbnails[0]?.imgProps?.width },
                        draggable: isInEditionMode ? 'false' : undefined,
                    }}
                />
            )}
            {thumbnails.length > 1 && (
                <Mosaic
                    className={elementClass('mosaic')}
                    style={{ width: '100%' }}
                    thumbnails={thumbnails.map((thumbnail, index) => ({
                        ...thumbnail,
                        ...getContextTriggerProps(index),
                    }))}
                    contentEditable={false}
                    theme={theme}
                />
            )}
            {children}
            {!isInEditionMode && <figcaption className={elementClass('caption')}>{title}</figcaption>}
            <ImageLightbox {...imageLightboxProps} />
        </figure>
    );
};
EnhancedImage.displayName = IMAGE_GROUP;
