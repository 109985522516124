import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiPencil } from '@lumapps/lumx/icons';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { contentEdit } from '../../routes';

export interface ContentEditSettingsMenuProps {
    /** True if the user is allowed to edit the content */
    canEditContent: boolean;
    /** Content id. */
    contentId?: string;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
}

export const ContentEditSettingsMenu: React.FC<ContentEditSettingsMenuProps> = ({
    canEditContent,
    contentId,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!canEditContent || !contentId) {
        return null;
    }

    return (
        <Menu.Section label={translateKey(GLOBAL.CONTENTS)} {...get({ element: 'content' })}>
            <Menu.Item
                as={RouterLink}
                to={contentEdit(contentId)}
                icon={mdiPencil}
                {...get({ element: 'content', action: 'edit' })}
            >
                {translateKey(GLOBAL.EDIT)}
            </Menu.Item>
        </Menu.Section>
    );
};

ContentEditSettingsMenu.displayName = 'ContentEditSettingsMenu';
