import React from 'react';

import type { MarkRender, Wrex } from '@lumapps/wrex/types';

import { BOLD, COLORED, ITALIC, SPAN, STRIKETHROUGH, UNDERLINE } from '../../../constants';

import './index.scss';

export const Span: MarkRender<typeof SPAN> = ({ children, attributes }) => (
    <span {...attributes} className="wrex-content-span">
        {children}
    </span>
);
Span.displayName = SPAN;

export const Bold: MarkRender<typeof BOLD> = ({ children, attributes }) => (
    <strong {...attributes} className="wrex-content-bold">
        {children}
    </strong>
);
Bold.displayName = BOLD;

export const Italic: MarkRender<typeof ITALIC> = ({ children, attributes }) => (
    <em {...attributes} className="wrex-content-italic">
        {children}
    </em>
);
Italic.displayName = ITALIC;

export const Underline: MarkRender<typeof UNDERLINE> = ({ children, attributes }) => (
    <u {...attributes} className="wrex-content-underline">
        {children}
    </u>
);
Underline.displayName = UNDERLINE;

export const STRIKETHROUGH_CLASS = 'wrex-content-strikethrough';
export const Strikethrough: MarkRender<typeof STRIKETHROUGH> = ({ children, attributes }) => (
    <span {...attributes} className={STRIKETHROUGH_CLASS}>
        {children}
    </span>
);
Strikethrough.displayName = STRIKETHROUGH;

export const Colored: MarkRender<typeof COLORED> = ({ children, attributes, leaf }) => {
    const { color } = leaf as Wrex.Text;
    return (
        <span {...attributes} style={{ color }}>
            {children}
        </span>
    );
};
Colored.displayName = COLORED;
