import keys from 'lodash/keys';

import BaseApi from '@lumapps/base-api';
import { BaseApiResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import {
    MAX_SEARCH_RESULTS,
    FACET_PREFIX,
    HIGHLIGHT_WRAPPER_TAG_START,
    HIGHLIGHT_WRAPPER_TAG_END,
    isArrayFacet,
    convertArrayParameterToValues,
    MAX_SEARCH_SUGGEST_RESULTS,
    DEFAULT_FILTER,
} from '../constants';
import {
    SearchTab,
    SearchFilter,
    Facet,
    FacetFilter,
    CustomField,
    BaseSearchResult,
    SearchResults,
    SearchParams,
    PromotedResults,
    SearchSuggestionParams,
    SearchSuggestionResponse,
    BackendSearchSort,
    SearchSort,
} from '../types';
import { convertTabToSearchFilter } from '../utils';

const searchApi = new BaseApi({ path: 'omnisearch' });
const searchApiV2 = new BaseApi({ path: 'search', version: BaseApi.versions.v2 });

export interface LumAppsSearchResults extends SearchResults {
    items: BaseSearchResult[];
    promoted: PromotedResults;
}

const formatFiltersAndMetadata = (tabs?: SearchTab[]) => {
    const filters: SearchFilter[] = [];
    const metadata: Record<string, SearchTab> = {};

    /**
     * If there is a key called tabs in the response, it means that the search has filters.
     * So we take this opportunity to:
     * - format those filters so they are already UI ready
     * - save the raw information in another object by key, so it is easier to access afterwards.
     */
    if (tabs) {
        tabs.forEach((tab: SearchTab) => {
            filters.push(convertTabToSearchFilter(tab));

            const customFields: Record<string, CustomField> = {};

            /**
             * If the tab has custom fields, we need to change the data structure for it since we need to access
             * those fields by name afterwards, and looking through a list is less efficient than retrieving
             * an item by key
             */
            if (tab.customFields) {
                tab.customFields.forEach((customField) => {
                    const { name } = customField;

                    customFields[name] = customField;
                });
            }

            metadata[tab.uid] = {
                ...tab,
                fields: customFields,
                // Remove custom fields from this point on since all the information that we need is in `fields`
                customFields: undefined,
            };
        });
    }

    return { filters, metadata };
};

const formatFacets = (facets?: Facet[]) => {
    const formattedFacets: FacetFilter[] = [];

    /**
     * If there are facets present in the response, we need to format them in order to show them as Chips.
     * Basically it does the following:
     * - checks each facet and format the different choices available for that facet
     * - for each facet, it checks the ones that are selected. If there is one selected, we set it as the value for the facet.
     */
    if (facets) {
        facets.forEach((facet: Facet) => {
            const {
                buckets,
                operatorName,
                field,
                localizedOperatorName,
                isCollapsed,
                isHierarchical = false,
                isMultiple,
                shouldDisplayAllValues = false,
            } = facet;
            const choices: SearchFilter[] = [];
            let selectedChoice: SearchFilter | undefined;
            let selectedChoices: SearchFilter[] | undefined;

            buckets.forEach((bucket) => {
                const choice: SearchFilter = {
                    count: bucket.count,
                    label: bucket.label,
                    value: bucket.value,
                };

                choices.push(choice);

                if (bucket.selected) {
                    selectedChoice = choice;
                    if (!selectedChoices) {
                        selectedChoices = [];
                    }
                    selectedChoices.push(choice);
                }
            });

            formattedFacets.push({
                label: localizedOperatorName,
                id: operatorName,
                field,
                value: isMultiple ? selectedChoices : selectedChoice,
                choices,
                isCollapsed,
                isHierarchical,
                isMultiple,
                shouldDisplayAllValues,
            });
        });
    }

    return formattedFacets;
};

export const formatSorts = (sorts: BackendSearchSort[] = []): SearchSort[] => {
    return sorts.map((sort: BackendSearchSort) => {
        return {
            label: sort.localizedOperatorName,
            value: sort.operatorName,
        };
    });
};

const getQuickView = (id: string): Promise<any> => {
    // need to encode the id since it can be something like an URI
    const encodedId = encodeURIComponent(id);
    return searchApiV2
        .getCacheFirst(`results/${encodedId}/preview`, CACHE_TYPE.MEMORY, PRIORITY.HIGH)
        .then((response) => {
            return response.data;
        });
};

const formatResultItems = (items: BaseSearchResult[]): BaseSearchResult[] => {
    if (!items) {
        return [];
    }

    return items.map((item) => {
        return {
            ...item,
            quickViewUrl: item.metadata?.externalId ? item.metadata.externalId : undefined,
        };
    });
};

/**
 * We need to do some alterations to the backend's response in order to display the UI that we want
 * This function generates those formatted results from the list of raw results
 * @param results list of raw results
 * @param params - search query params
 * TODO: remove any use and complete SearchResults interface
 */
const formatResults = (
    results: any,
    headers: BaseApiResponse['headers'],
    responseStatus: string,
): LumAppsSearchResults => {
    const {
        items,
        tabs,
        facets,
        more,
        wCursor,
        cursor,
        sortOrders,
        spellResults,
        promoted,
        resultCountExact,
        page,
        responseTime,
        searchQueryUid,
        splitTestRunName,
        splitTestRunVersion,
        templates,
    } = results;

    const engine = headers['x-lumapps-searchengine'];
    const formattedFacets = formatFacets(facets);
    const { filters, metadata } = formatFiltersAndMetadata(tabs);
    const formattedItems = formatResultItems(items);

    return {
        items: formattedItems,
        filters,
        metadata,
        facets: formattedFacets,
        sorts: formatSorts(sortOrders),
        more,
        cursor: wCursor || cursor,
        promoted,
        spellResults,
        resultCountExact,
        page,
        responseTime,
        searchQueryUid,
        splitTestRunName,
        splitTestRunVersion,
        sortOrders,
        engine,
        templates,
        responseStatus,
    };
};

/**
 * From the current selected options, we generate the necessary object for the backend to respond to our
 * current filters.
 * @param facets - list of filters to apply
 */
const generateFilterOptions = (facets?: Record<string, string>): any[] | undefined => {
    let filterOptions: any[] | undefined;
    const facetKeys = keys(facets);

    // TODO: we should definitely talk with the backend folks and see if we can improve this API
    if (facets && facetKeys.length > 0) {
        filterOptions = [
            {
                filters: facetKeys.map((key) => {
                    const facetValue = facets[key];
                    const isArrayFacetValue = isArrayFacet(facetValue);

                    return {
                        name: key.replace(FACET_PREFIX, ''),
                        values: isArrayFacetValue ? convertArrayParameterToValues(facetValue) : facetValue,
                        kind: isArrayFacetValue ? 'array' : 'string',
                    };
                }),
            },
        ];
    }

    return filterOptions;
};

const formatQuery = (params: SearchParams) => {
    const { query, features } = params;
    const decodedQuery = decodeURIComponentSafe(query);

    /**
     * in order to allow the possibility to search for all results on the new search,
     * we need to change the query before sending it to the server. So, when we are in the
     * NS world, and the query is *, we just return an empty query since that is the character
     * accepted by NS to search all results. And since GCS accepts the * as the query to search
     * for everything, in order to keep a unified UI, we use always * and change it here.
     */
    if (features && features.isNSEnabled && query === '*') {
        return '';
    }

    return decodedQuery;
};

const formatSearchParams = (params: SearchParams) => {
    // We remove the features object from the params to avoid sending it to the backend.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { filter, query, facets, cursor, sort, features, ...restOfParams } = params;
    // TODO: remove GCS check when GCS is behind winston.
    const sources = {
        source: filter || (features?.isCSEnabled ? DEFAULT_FILTER : undefined),
        filterOptions: generateFilterOptions(facets),
    };
    return {
        maxResults: MAX_SEARCH_RESULTS,
        ...restOfParams,
        query: formatQuery(params),
        sort,
        sources,
        wrapper: {
            start: HIGHLIGHT_WRAPPER_TAG_START,
            end: HIGHLIGHT_WRAPPER_TAG_END,
        },
        wCursor: cursor,
    };
};

const search = (
    params: SearchParams,
    useCache = true,
    isCancellable = false,
    shouldFormatResults = true,
    traceId: string = '',
): Promise<LumAppsSearchResults | any> => {
    const formattedParams = formatSearchParams(params);
    const headers = traceId
        ? {
              headers: {
                  'LumApps-Trace-Id': traceId,
              },
          }
        : {};

    const promiseSearch = useCache
        ? searchApi.postCacheFirst(
              'search',
              formattedParams,
              CACHE_TYPE.MEMORY,
              PRIORITY.HIGH,
              headers,
              true,
              isCancellable,
          )
        : searchApi.postWithPriority('search', formattedParams, PRIORITY.HIGH, headers, true, isCancellable);

    return promiseSearch.then((response) => {
        if (!shouldFormatResults) {
            return response;
        }

        const formattedResults = formatResults(response.data, response.headers, response.statusText);

        return formattedResults;
    });
};

/**
 * Get suggestions for a given query.
 *
 * @param  {string}  params The parameters to pass to the suggest endpoint.
 * @return {Promise} The promise of the suggest call.
 */
const suggest = ({
    query,
    siteId,
    nbQueries = MAX_SEARCH_SUGGEST_RESULTS,
    nbResults = MAX_SEARCH_SUGGEST_RESULTS,
    acceptLanguage,
}: SearchSuggestionParams): Promise<SearchSuggestionResponse> => {
    return searchApiV2
        .getCacheFirst<SearchSuggestionResponse>(`suggest/${query}`, CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
            params: { nbQueries, nbResults, siteId },
            headers: acceptLanguage
                ? {
                      'Accept-Language': acceptLanguage,
                  }
                : undefined,
        })
        .then((response) => {
            const { queries = [], results = [] } = response.data;

            return { queries, results };
        });
};

export {
    searchApi,
    search,
    formatFiltersAndMetadata,
    formatFacets,
    formatResults,
    formatSearchParams,
    generateFilterOptions,
    formatQuery,
    suggest,
    getQuickView,
};
