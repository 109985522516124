export enum WIDGET_CONTENT_FILTER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=429427899
     */
    BY_KEYWORD_LABEL = 'CONTENT_FILTER.KEYWORD_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=429516160
     */
    ADVANCED_FILTERS = 'WIDGET_CONTENT_FILTER.ADVANCED_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457476533
     */
    SAVE_AS_INTEREST = 'CONTENT_FILTER.SAVE_AS_INTEREST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457477392
     */
    INTEREST_SAVED = 'CONTENT_FILTER.INTEREST_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457477460
     */
    INTEREST_ALREADY_SAVED = 'CONTENT_FILTER.INTEREST_ALREADY_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457477481
     */
    INTEREST_ERROR_SAVED = 'CONTENT_FILTER.INTEREST_ERROR_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457484318
     * TODO: Split into two translations
     */
    INTEREST_INFORMATION = 'CONTENT_FILTER.INTEREST_INFORMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472148253
     */
    HIGHLIGHTED_FOR_ME = 'CONTENT_FILTER.HIGHLIGHTED_FOR_ME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473947790
     */
    START_DATE = 'CONTENT_FILTER.START_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473947837
     */
    END_DATE = 'CONTENT_FILTER.END_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452651
     * */
    KEYWORD_SUBHEADER = 'CONTENT_FILTER_KEYWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228465912
     * */
    CONTENT_TYPE_SUBHEADER = 'CONTENT_TYPE_CUSTOM',
}
