/* istanbul ignore file */

import { LINK } from '@lumapps/wrex-link/constants';
import { ORDERED_LIST, UNORDERED_LIST } from '@lumapps/wrex-list/constants';
import { BOLD, INLINE_CODE, ITALIC, REDO, STRIKETHROUGH, UNDERLINE, UNDO } from '@lumapps/wrex-typography/constants';

import { formatShortcut } from './utils/formatShortcut';

/**
 * List of element type that will be translated with google translate
 */
export const ELEMENT_TYPE_TO_TRANSLATE = ['text', 'og:description'];

/**
 * List of element type that will not be translated with google translate
 */
export const ELEMENT_TYPE_TO_NOT_TRANSLATE = ['lumapps:user'];

/** Block alignments */
export const ALIGNMENTS = {
    start: 'start',
    center: 'center',
    end: 'end',
} as const;

/**
 * Command keys for shortcuts
 */
export const PLUGIN_SHORTCUTS = {
    [BOLD]: { hotkey: 'mod+b', shortcut: formatShortcut(true, 'B') },
    [ITALIC]: { hotkey: 'mod+i', shortcut: formatShortcut(true, 'I') },
    [UNORDERED_LIST]: { hotkey: 'mod+shift+8', shortcut: formatShortcut(false, 'Shift+8') },
    [ORDERED_LIST]: { hotkey: 'mod+shift+7', shortcut: formatShortcut(false, 'Shift+7') },
    [LINK]: { hotkey: 'mod+k', shortcut: formatShortcut(true, 'K') },
    [UNDO]: { hotkey: 'mod+z', shortcut: formatShortcut(true, 'Z') },
    [REDO]: { hotkey: 'mod+y', shortcut: formatShortcut(true, 'Y') },
    [INLINE_CODE]: { hotkey: 'mod+shift+c', shortcut: undefined },
    [UNDERLINE]: { hotkey: 'mod+u', shortcut: formatShortcut(true, 'U') },
    [STRIKETHROUGH]: { hotkey: 'mod+shift+x', shortcut: formatShortcut(false, 'Shift+X') },
    [ALIGNMENTS.start]: { hotkey: 'mod+shift+l', shortcut: formatShortcut(false, 'Shift+L') },
    [ALIGNMENTS.center]: { hotkey: 'mod+shift+e', shortcut: formatShortcut(false, 'Shift+E') },
    [ALIGNMENTS.end]: { hotkey: 'mod+shift+r', shortcut: formatShortcut(false, 'Shift+R') },
};

/**
 * Alignment HTML classes
 */
export const ALIGNMENT_CLASSES = {
    [ALIGNMENTS.start]: 'wrex-text-align-start',
    [ALIGNMENTS.center]: 'wrex-text-align-center',
    [ALIGNMENTS.end]: 'wrex-text-align-end',
};
