/* eslint-disable angular/json-functions */
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import loFind from 'lodash/find';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import set from 'lodash/set';
import union from 'lodash/union';

/////////////////////////////

function WidgetService(
    $injector,
    $rootScope,
    ExtensionWidget,
    Header,
    InitialSettings,
    LumsitesBaseService,
    LxNotificationService,
    ReduxStore,
    Translation,
    Utils,
    WidgetFactory,
) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(WidgetFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default background color of the main area of the widget.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DEFAULT_MAIN_BACKGROUND_COLOR = '#FFFFFF';

    /**
     * The default background color of the main area of the widget when it use ungroup view mode.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DEFAULT_MAIN_UNGROUP_BACKGROUND_COLOR = 'transparent';

    /**
     * The default options of a new widget.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _DEFAULT_WIDGET_OPTIONS = {
        cellType: ['default', 'survey'],
        googleSync: false,
        icon: '',
        type: '',
    };

    /**
     * Font weights used for migration.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _FONT_WEIGHTS = {
        bold: 700,
        light: 300,
        normal: 400,
        semibold: 500,
    };

    /**
     * Header and footer properties to migrate.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _WIDGET_AREA_PROPERTIES = {
        main: ['display', 'backgroundSize', 'backgroundColor', 'backgroundPosition', 'backgroundImage'],
        wrapper: [
            'flexDirection',
            'alignItems',
            'justifyContent',
            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',
            'borderTopColor',
            'borderRightColor',
            'borderBottomColor',
            'borderLeftColor',
            'borderTopLeftRadius',
            'borderBottomLeftRadius',
            'borderTopRightRadius',
            'borderBottomRightRadius',
            'marginTop',
            'marginRight',
            'marginBottom',
            'marginLeft',
            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',
        ],
    };

    /**
     * Contains the currently selected widget.
     *
     * @type {Object}
     */
    let _current;

    /**
     * Indicates if the widget is draggable or not.
     *
     * @type {boolean}
     */
    let _dragDisabled = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the properties of the widget header and footer that will switch between the parent element and the
     * wrapper element depending of the mode of the header/footer (block or inline/flex).
     *
     * @type {Array}
     */
    service._WIDGET_HEADER_FOOTER_SWITCHABLE_PROPERTIES = [
        'backgroundColor',
        'backgroundImage',
        'backgroundImageObject',
        'backgroundPosition',
        'backgroundSize',
        'boxShadow',
        'shadowElevation',
        'shadowOpacity',
    ];

    /**
     * A list of all the widgets available to the user.
     *
     * @type {Array}
     */
    service.availableWidgets = [];

    /**
     * The default parameters for the service requests.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     *  List of enabled extensions widgets.
     *
     * @type {Array}
     */
    service.enabledExtensionWidgets = [];

    /**
     * Whether the widget is active or not.
     *
     * @type {boolean}
     */
    service.isActive = false;

    /**
     * Whether the widget is dragged or not.
     *
     * @type {boolean|string}
     */
    service.isDragging = false;

    /**
     * Whether the wdiget is focused or not.
     *
     * @type {boolean}
     */
    service.isFocused = false;

    /**
     * The allowed widget context.
     *
     * @type {Array}
     */
    service.context = undefined;

    /**
     * The index of the active tab in the settings sidebar.
     *
     * @type {number}
     */
    service.settingsActiveTab = 0;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Migrates properties from an object to another.
     *
     * @param  {Object} properties  The properties to migrate.
     * @param  {Object} destination The destination object the properties will be migrated to.
     * @param  {Object} area        The current area we are working on.
     * @return {Object} The mutated area.
     *
     */
    function _migrateAreaProperties(properties, destination, area) {
        angular.forEach(properties, function forEachAreaProperty(property) {
            const value = area[property];
            if (angular.isDefined(value)) {
                area[destination][property] = value;
                delete area[property];
            }
        });

        return area;
    }

    /**
     * Backward compatibility for font and icon style properties.
     *
     * @param  {Object} style The current style object.
     * @return {Object} The mutated style object.
     */
    function _migrateFontAndIcon(style) {
        if (angular.isDefinedAndFilled(style.fontColor)) {
            style.label.color = style.fontColor;
            delete style.fontColor;
        }
        if (angular.isDefinedAndFilled(style.fontSize)) {
            style.label.fontSize = style.fontSize;
            delete style.fontSize;
        }
        if (angular.isDefinedAndFilled(style.fontWeight)) {
            if (angular.isDefined(_FONT_WEIGHTS[style.fontWeight])) {
                style.label.fontWeight = _FONT_WEIGHTS[style.fontWeight];
            }
            delete style.fontWeight;
        }
        if (angular.isDefinedAndFilled(style.iconColor)) {
            style.icon.color = style.iconColor;
            delete style.iconColor;
        }
        if (angular.isDefinedAndFilled(style.iconSize)) {
            style.icon.fontSize = style.iconSize;
            delete style.iconSize;
        }

        return style;
    }

    /**
     * Backward compatibility. Moves old attached margin and padding properties which were set to the
     * root widget properties to the proper style properties.
     *
     * @param {Object} widgetProperties The current widget properties.
     * @param {Object} position         Which position we want to migrate (top|bottom|left|right).
     */
    function _initGlobalMarginAndPaddingProperty(widgetProperties, position) {
        const paddingProperty = `padding${position}`;
        const marginProperty = `margin${position}`;
        const widgetPropertiesStyle = widgetProperties.style;
        const paddingValue = angular.isUndefinedOrEmpty(widgetProperties[paddingProperty])
            ? widgetPropertiesStyle.global[paddingProperty]
            : widgetProperties[paddingProperty];

        const marginValue = angular.isUndefinedOrEmpty(widgetProperties[marginProperty])
            ? widgetPropertiesStyle.global[marginProperty]
            : widgetProperties[marginProperty];

        if (angular.isDefinedAndFilled(paddingValue)) {
            if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.content[paddingProperty])) {
                widgetPropertiesStyle.content[paddingProperty] = paddingValue;
            }

            if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.header.wrapper[marginProperty])) {
                widgetPropertiesStyle.header.wrapper[marginProperty] = paddingValue;
            }

            if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.footer.wrapper[marginProperty])) {
                widgetPropertiesStyle.footer.wrapper[marginProperty] = paddingValue;
            }

            delete widgetPropertiesStyle.global[paddingProperty];
            delete widgetProperties[paddingProperty];
        }

        if (angular.isDefinedAndFilled(marginValue)) {
            widgetPropertiesStyle.main[marginProperty] = marginValue;

            delete widgetPropertiesStyle.global[marginProperty];
            delete widgetProperties[marginProperty];
        }
    }

    /**
     * Migrate all old padding and margin properties to the style object.
     *
     * @param {Object} widgetProperties Current widget properties.
     */
    function _migrateGlobalMarginAndPadding(widgetProperties) {
        const marginAndPaddingPositions = ['Top', 'Right', 'Bottom', 'Left'];

        angular.forEach(marginAndPaddingPositions, function forEachGlobalMarginAndPadding(property) {
            _initGlobalMarginAndPaddingProperty(widgetProperties, property);
        });
    }

    /**
     * Migrate all header and footer padding left and right to margin left and right.
     *
     * @param {Object} widgetPropertiesStyle Current widget Footer or Header style.
     */
    function _migratePaddingToMargin(widgetPropertiesStyle) {
        widgetPropertiesStyle.marginLeft = widgetPropertiesStyle.paddingLeft;
        widgetPropertiesStyle.marginRight = widgetPropertiesStyle.paddingRight;

        delete widgetPropertiesStyle.paddingLeft;
        delete widgetPropertiesStyle.paddingRight;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Create a new widget object based on the given options.
     *
     * @param  {Object} options The options for the widget to create.
     * @return {Object} The new widget.
     */
    function create(options) {
        return angular.extend(angular.fastCopy(_DEFAULT_WIDGET_OPTIONS), options);
    }

    /**
     * Delete the current widget.
     *
     * @param {Object} component The component from which to delete the current widget.
     */
    function deleteCurrent(component) {
        LxNotificationService.confirm(
            Translation.translate('WIDGET_DELETE'),
            Translation.translate('WIDGET_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function confirmDeleteWidget(answer) {
                if (answer) {
                    const widgetParents = service.getParents(component, _current);
                    const widgetContainer = widgetParents[widgetParents.length - 1];

                    if (angular.isDefinedAndFilled(widgetContainer.components)) {
                        widgetContainer.components.splice(widgetContainer.components.indexOf(_current), 1);
                    }

                    $rootScope.$broadcast('designer-component__delete', 'widget');
                }
            },
        );
    }

    /**
     * Find all the widget in a content that matches the given type.
     *
     * @param  {Object} content    The content in which to find the widgets.
     * @param  {string} widgetType The widget type we want to find.
     * @return {Array}  The widgets of the given type in the given content.
     */
    function findWidgetsByType(content, widgetType) {
        let widgets = [];

        if (angular.isUndefinedOrEmpty(content)) {
            return widgets;
        }

        angular.forEach(content.components, function forEachComponents(component) {
            widgets = widgets.concat(service.getWidgetsByType(component, widgetType));
        });

        return widgets;
    }

    /**
     * Find all the widget in a row that matches the given type.
     *
     * @param  {Object} row        The row in which to find the widgets.
     * @param  {string} widgetType The widget type we want to find.
     * @return {Array}  The widgets of the given type in the given row.
     */
    function findWidgetsByTypeInRow(row, widgetType) {
        let widgets = [];

        if (angular.isUndefinedOrEmpty(row)) {
            return widgets;
        }

        angular.forEach(row.cells, function forEachCells(cell) {
            if (angular.isUndefinedOrEmpty(cell)) {
                return;
            }

            angular.forEach(cell.components, function forEachComponents(component) {
                widgets = widgets.concat(service.getWidgetsByType(component, widgetType));
            });
        });

        return widgets;
    }

    /**
     * Get the enabled extension widgets list.
     *
     * @return {Array} The extension widgets list.
     */
    async function getEnabledExtensionWidgets() {
        service.enabledExtensionWidgets = await ExtensionWidget.getEnabledExtensions();
        return service.enabledExtensionWidgets;
    }

    /**
     * Gets all the widgets of a type in a component.
     *
     * @param  {Object} component  The component in which we want to get the widgets.
     * @param  {string} widgetType The widget type we want to get.
     * @return {Array}  The widgets of the given type in the given row.
     */
    function getWidgetsByType(component, widgetType) {
        let widgets = [];

        if (angular.isUndefinedOrEmpty(component)) {
            return widgets;
        }

        widgetType = widgetType.toUpperCase();

        if (component.type === 'row') {
            widgets = widgets.concat(findWidgetsByTypeInRow(component, widgetType));
        } else if (component.type === 'page') {
            widgets = widgets.concat(findWidgetsByType(component, widgetType));
        } else if (component.type === 'widget' && component.widgetType.toUpperCase() === widgetType) {
            widgets.push(component);
        }

        return widgets;
    }

    /**
     * Apply a transformation to all widgets matching a predicate in a component.
     *
     * @param  {Object}   component  The component in which we want to apply the transformation on.
     * @param  {Function} predicate  The filter function to get the widgets we want to apply the transformation on.
     * @param  {Function} transform  The transformation function we want to apply on the widgets.
     * @return {Array}               The widgets of the given type in the given row.
     */
    function transformWidgetsByPredicate(component, predicate, transform) {
        if(!component) {
            return;
        }
        
        if (component.type === 'row') {
            angular.forEach(component.cells, function forEachCells(cell) {
                if (angular.isUndefinedOrEmpty(cell)) {
                    return;
                }
    
                angular.forEach(cell.components, function forEachComponents(comp) {
                    service.transformWidgetsByPredicate(comp, predicate, transform);
                });
            });
        } else if (component.type === 'page' || !component.type) {
            angular.forEach(component.components, function forEachComponents(comp) {
                service.transformWidgetsByPredicate(comp, predicate, transform);
            });
        } else if (component.type === 'widget' && predicate(component)) {
            transform(component);
        }
    }


    /**
     * Get lang according to context and no fallback param translate content in input language in edit mode.
     * Translate content according to preferred / fallback user langs or according to current language if no
     * fallback param is defined.
     *
     * @param  {Object}  translation  A translatable object.
     * @param  {boolean} [noFallback] Indicates if we want to use a fallback or not.
     * @return {string}  The translated string.
     */
    function getWidgetTranslation(translation, noFallback) {
        if (angular.isUndefinedOrEmpty(translation)) {
            return undefined;
        }

        let translatedString;

        if (Utils.isDesignerMode()) {
            translatedString = translation[Translation.inputLanguage];
        } else if (noFallback) {
            translatedString = translation[Translation.getLang('current')];
        } else {
            translatedString = Translation.translate(translation);
        }

        return translatedString;
    }

    /**
     * Get the current widget.
     *
     * @return {Object} The current widget.
     */
    function getCurrent() {
        return _current;
    }

    /**
     * Filter the list of widgets.
     * When in a Space, we should only return NGI compatible widgets.
     * When in a Community, we should remove uncompatible widgets.
     *
     * @param {Object} widgets - The list of widgets to filter
     * @param {String} key - The key used to filter the widgets
     * Both Default & Global widgets have the same "type" key, but not used for the same purpose.
     *
     * @return {Array} The filtered widget list
     * */
    function filterWidgets(widgets, key = 'type') {
        const Content = $injector.get('Content');
        const ConfigInstance = $injector.get('ConfigInstance');
        const currentContent = Content.getCurrent();
        const useSpaceWidgetsOnly = _.get(currentContent, 'renderingType')
            ? currentContent.renderingType === 'space'
            : false;
        const useCommunityWidgetsOnly = _.get(currentContent, 'renderingType')
            ? currentContent.renderingType === 'community'
            : false;

        if (useSpaceWidgetsOnly) {
            return filter(widgets, (widget) => {
                return ConfigInstance.AVAILABLE_SPACE_WIDGETS.includes(widget[key]);
            });
        }

        if (useCommunityWidgetsOnly) {
            // Remove widgets excluded from communities.
            return filter(widgets, (widget) => {
                return !ConfigInstance.COMMUNITY_WIDGETS_EXCLUSION_LIST.includes(widget[key]);
            });
        }
        return widgets;
    }

    /**
     * Get the global widgets list.
     *
     * @return {Array} The global widgets list.
     */
    function getGlobalWidgets() {
        return filterWidgets(InitialSettings.GLOBAL_WIDGETS, 'widgetType');
    }

    /**
     * Gets the widget type in a backward compatible manner.
     * For example, "community-post-list" got renamed to "post-list", so "community-post-list" is still stored in
     * the DB but the files are all renamed to "post-list".
     * So we want "post-list" returned but still store "community-post-list".
     *
     * @param  {string} widgetType The type of widget to transform.
     * @return {string} The widget type that is backward compatible.
     */
    function getBackwardCompatibleWidgetType(widgetType) {
        switch (widgetType) {
            case 'manpower-directory-entry':
                return 'manpower-directory-entry-list';

            case InitialSettings.WIDGET_TYPES.COMMUNITY_POST_LIST:
                return 'post-list';

            case InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY:
                return 'directory-entry-list';

            case InitialSettings.WIDGET_TYPES.INSTANCE_LIST:
                return 'instance-list';

            case InitialSettings.WIDGET_TYPES.USER:
                return 'user-list';

            default:
                return widgetType;
        }
    }

    /**
     * Get the widget's parents.
     *
     * @param  {Object} component The component in which we want to find the widget's parents.
     * @param  {Object} widget    The widget of which we want to get the parents.
     * @return {Array}  The parents of the given widget in the given component.
     */
    function getParents(component, widget) {
        component = component || {};

        const list = component.components || component.cells || [];

        for (let i = 0, len = list.length; i < len; i++) {
            if (widget.uuid === list[i].uuid) {
                return [];
            }

            const childResult = getParents(list[i], widget);

            if (angular.isDefined(childResult)) {
                childResult.unshift(list[i]);

                return childResult;
            }
        }

        return undefined;
    }

    /**
     * Initialize style parameters.
     *
     * @param {Object}  widget              The widget to initialize the styles params.
     * @param {boolean} [isPlainCell=false] Indicates if the cell containing the widget is in plain mode.
     */
    function initStyleParams(widget, isPlainCell) {
        if (angular.isUndefinedOrEmpty(widget)) {
            return;
        }

        widget.properties = widget.properties || {};
        widget.properties.style = widget.properties.style || {};

        const initialStyle = angular.fastCopy(widget.properties.style);

        const widgetProperties = widget.properties;
        const widgetPropertiesStyle = widget.properties.style;

        // Ensure style sets are defined.
        if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.main)) {
            widgetPropertiesStyle.main = {};
        }

        if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.header)) {
            widgetPropertiesStyle.header = {};
        }
        const widgetHeaderStyle = widgetPropertiesStyle.header;
        angular.forEach(['main', 'wrapper', 'icon', 'label'], function forEachAreaProperty(property) {
            if (angular.isUndefinedOrEmpty(widgetHeaderStyle[property])) {
                widgetHeaderStyle[property] = {};
            }
            if (angular.isDefined(_WIDGET_AREA_PROPERTIES[property])) {
                _migrateAreaProperties(_WIDGET_AREA_PROPERTIES[property], property, widgetHeaderStyle);
            }
        });

        if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.content)) {
            widgetPropertiesStyle.content = {};
        }

        if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.footer)) {
            widgetPropertiesStyle.footer = {};
        }

        const widgetFooterStyle = widgetPropertiesStyle.footer;

        angular.forEach(['main', 'wrapper', 'icon', 'label'], function forEachAreaProperty(property) {
            if (angular.isUndefinedOrEmpty(widgetFooterStyle[property])) {
                widgetFooterStyle[property] = {};
            }
            if (angular.isDefined(_WIDGET_AREA_PROPERTIES[property])) {
                _migrateAreaProperties(_WIDGET_AREA_PROPERTIES[property], property, widgetFooterStyle);
            }
        });

        if (angular.isDefined(widgetPropertiesStyle.global)) {
            _migratePaddingToMargin(widgetHeaderStyle.wrapper);
            _migratePaddingToMargin(widgetFooterStyle.wrapper);

            if (angular.isUndefinedOrEmpty(widgetPropertiesStyle.global)) {
                delete widgetPropertiesStyle.global;
            }
        }

        // Shared properties.
        if (angular.isDefinedAndFilled(widgetProperties.color)) {
            widgetPropertiesStyle.main.backgroundColor = widgetProperties.color;
            // TODO: Remove veolia-specific setting.
            widgetProperties.veoliaWidgetColor = widgetProperties.color;

            delete widgetProperties.color;
        }

        const marginPropertiesToCopy = ['marginBottom', 'marginLeft', 'marginRight', 'marginTop'];

        const destinationPath = 'style.main';

        if (angular.isDefinedAndFilled(widgetProperties.paddingLeft)) {
            widgetPropertiesStyle.global = widgetPropertiesStyle.global || {};
            widgetPropertiesStyle.global.paddingLeft = widgetProperties.paddingLeft;

            delete widgetProperties.paddingLeft;
        }

        if (angular.isDefinedAndFilled(widgetProperties.paddingRight)) {
            widgetPropertiesStyle.global = widgetPropertiesStyle.global || {};
            widgetPropertiesStyle.global.paddingRight = widgetProperties.paddingRight;

            delete widgetProperties.paddingRight;
        }

        // Main.
        if (angular.isDefinedAndFilled(widgetPropertiesStyle.global)) {
            widgetProperties.isBackwardedStyle = true;
            _migrateGlobalMarginAndPadding(widgetProperties);

            const sourcePath = 'style.global';

            const backgroundPropertiesToCopy = [
                'backgroundSize',
                'backgroundColor',
                'backgroundPosition',
                'backgroundImage',
            ];

            const borderWidthPropertiesToCopy = [
                'borderTopWidth',
                'borderRightWidth',
                'borderBottomWidth',
                'borderLeftWidth',
            ];

            const borderColorPropertiesToCopy = [
                'borderTopColor',
                'borderRightColor',
                'borderBottomColor',
                'borderLeftColor',
            ];

            const borderRadiusPropertiesToCopy = [
                'borderTopLeftRadius',
                'borderTopRightRadius',
                'borderBottomRightRadius',
                'borderBottomLeftRadius',
            ];

            Utils.copyProperties(
                union(
                    backgroundPropertiesToCopy,
                    marginPropertiesToCopy,
                    borderWidthPropertiesToCopy,
                    borderRadiusPropertiesToCopy,
                ),
                sourcePath,
                destinationPath,
                widgetProperties,
            );

            Utils.copyPropertySet(
                'borderColor',
                borderColorPropertiesToCopy,
                sourcePath,
                destinationPath,
                widgetProperties,
            );

            Utils.copyPropertySet(
                'borderRadius',
                borderRadiusPropertiesToCopy,
                sourcePath,
                destinationPath,
                widgetProperties,
            );

            if (angular.isDefinedAndFilled(get(widgetPropertiesStyle, 'global.boxShadow'))) {
                if (widgetProperties.viewMode === 'cascade' || isWidgetUngrouped(widgetProperties.viewModeVariant)) {
                    set(widgetProperties, 'style.content.shadowElevation', widgetProperties.style.global.boxShadow);
                } else {
                    set(widgetProperties, 'style.main.shadowElevation', widgetProperties.style.global.boxShadow);
                }
            }

            delete widgetPropertiesStyle.global;
        }

        Utils.copyProperties(marginPropertiesToCopy, '', destinationPath, widgetProperties, true);

        // Content.
        if (angular.isDefinedAndFilled(widgetProperties.height)) {
            widgetPropertiesStyle.content.height = widgetProperties.height;
        }
        if (angular.isDefinedAndFilled(widgetProperties.fullHeight)) {
            widgetPropertiesStyle.content.fullHeight = widgetProperties.fullHeight;
        }

        // Header.
        if (angular.isDefinedAndFilled(widgetHeaderStyle.borderWidth)) {
            widgetHeaderStyle.wrapper.borderBottomWidth = widgetHeaderStyle.borderWidth;
            delete widgetHeaderStyle.borderWidth;
        }
        if (angular.isDefinedAndFilled(widgetHeaderStyle.icon)) {
            if (isString(widgetHeaderStyle.icon)) {
                widgetProperties.headerIcon = widgetHeaderStyle.icon;
                widgetHeaderStyle.icon = {};
            }
        }
        if (angular.isDefinedAndFilled(widgetHeaderStyle.height)) {
            widgetHeaderStyle.wrapper.height = widgetHeaderStyle.height;
            delete widgetHeaderStyle.height;
        }
        _migrateFontAndIcon(widgetHeaderStyle);
        Utils.copyPropertySet(
            'borderColor',
            ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
            'style.header',
            'style.header.wrapper',
            widgetProperties,
        );

        // Footer.
        if (angular.isDefinedAndFilled(widgetFooterStyle.borderWidth)) {
            widgetFooterStyle.wrapper.borderTopWidth = widgetFooterStyle.borderWidth;

            delete widgetFooterStyle.borderWidth;
        }
        if (angular.isDefinedAndFilled(widgetFooterStyle.height)) {
            widgetFooterStyle.wrapper.height = widgetFooterStyle.height;
            delete widgetFooterStyle.height;
        }
        _migrateFontAndIcon(widgetFooterStyle);
        Utils.copyPropertySet(
            'borderColor',
            ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
            'style.footer',
            'style.footer.wrapper',
            widgetProperties,
        );

        const defaultBackgroundColor =
            isWidgetUngrouped(widget.properties.viewModeVariant) || isPlainCell
                ? _DEFAULT_MAIN_UNGROUP_BACKGROUND_COLOR
                : _DEFAULT_MAIN_BACKGROUND_COLOR;

        if (
            widgetProperties.isBackwardedStyle &&
            (widgetFooterStyle.main.backgroundColor === 'transparent' ||
                widgetHeaderStyle.main.backgroundColor === 'transparent' ||
                widgetPropertiesStyle.content.backgroundColor === 'transparent')
        ) {
            widgetPropertiesStyle.main.backgroundColor = 'transparent';

            widgetFooterStyle.main.backgroundColor = widgetFooterStyle.main.backgroundColor || defaultBackgroundColor;

            widgetHeaderStyle.main.backgroundColor = widgetHeaderStyle.main.backgroundColor || defaultBackgroundColor;

            widgetPropertiesStyle.content.backgroundColor =
                widgetPropertiesStyle.content.backgroundColor || defaultBackgroundColor;
        }

        if (angular.toJson(initialStyle) !== angular.toJson(widget.properties.style)) {
            widget.properties.stylesMigrated = true;
        }
    }

    /**
     * Set if the widget is draggable or not.
     *
     * @param  {boolean} dragDisabled Indicates if the widget is draggable or not.
     * @return {boolean} If the widget is draggable or not.
     */
    function isDragDisabled(dragDisabled) {
        if (angular.isDefined(dragDisabled)) {
            _dragDisabled = dragDisabled;
        }

        return _dragDisabled;
    }

    /**
     * Get available widgets.
     * The list is filtered whether we want to enforce NGI-Widgets or not.
     */
    function getAvailableWidgets() {
        return filterWidgets(service.availableWidgets, 'type');
    }

    /**
     * Reset available widgets list.
     */
    function resetAvailableWidgets() {
        const ConfigInstance = $injector.get('ConfigInstance');
        const User = $injector.get('User');

        service.availableWidgets = filter(ConfigInstance.AVAILABLE_WIDGETS, function forEachAvailableWidgets(widget) {
            return (
                /* eslint-disable no-sync */
                (widget.googleSync && !User.isMicrosoft()) ||
                (!widget.googleSync && !widget.isMicrosoft) ||
                (widget.isMicrosoft && User.isMicrosoft())
                /* eslint-enable no-sync */
            );
        });
    }

    /**
     * Reset the current widget and widget states.
     */
    function resetWidget() {
        _current = undefined;

        service.isFocused = false;
        service.isActive = false;

        $rootScope.$broadcast('designer-component__unset', 'widget');
    }

    /**
     * Save the global Widget using Api.
     *
     * @param {Function} cb    The cb to execute when the save is successful.
     * @param {Function} errCb The cb to execute if there is an error during the save.
     */
    function saveGlobalWidget(cb, errCb) {
        cb = angular.isUndefined(cb) || !angular.isFunction(cb) ? noop : cb;
        errCb = angular.isUndefined(errCb) || !angular.isFunction(errCb) ? noop : errCb;

        service.save(
            _current,
            function saveSuccess(response) {
                _current.id = response.id;

                const currentWidget = loFind(service.getGlobalWidgets(), {
                    id: response.id,
                });
                if (angular.isDefinedAndFilled(currentWidget)) {
                    Utils.swapObject(currentWidget, angular.fastCopy(_current));
                } else {
                    getGlobalWidgets().push(angular.fastCopy(_current));
                }

                cb();
            },
            errCb,
        );
    }

    /**
     * Set the current widget.
     *
     * @param {Object} widget The widget to set as current.
     */
    function setCurrent(widget) {
        if (angular.isDefinedAndFilled(_current)) {
            _current.state = 'default';
        }

        if (widget.widgetType === 'remote') {
            const currentExtension = ExtensionWidget.extensions.find(
                (extension) => extension.id === widget.properties.remoteExtension.id,
            );

            // Extension can be disabled
            widget.name =  currentExtension?.name ?? 'MARKETPLACE.EXTENSION_DISABLED'; 
            widget.enabled= Boolean(currentExtension?.enabled);
        }

        _current = widget;

        if (angular.isDefinedAndFilled(widget)) {
            Header.isActive = false;
            $rootScope.$broadcast('designer-component__set', 'widget');
        } else {
            $rootScope.$broadcast('designer-component__unset', 'widget');
        }
    }

    /**
     * Check if we should display the widget header.
     * The header is displayed only if there is a `title`.
     *
     * @param  {Object}  title The widget title object.
     * @return {boolean} If the widget header should be displayed.
     */
    function shouldDisplayWidgetHeader(title) {
        if (angular.isUndefinedOrEmpty(title)) {
            return false;
        }

        return angular.isDefinedAndFilled(service.getWidgetTranslation(title));
    }

    /**
     * Check if the widget sizeVariant is set to ungroup (legacy) or ungrouped (React).
     *
     * @param {String | undefined}  viewModeVariant The widget viewModeVariant property.
     * @return {boolean} If the widget has ungroup|ungrouped view variant.
     */
    function isWidgetUngrouped(viewModeVariant) {
        return ['ungroup', 'ungrouped'].includes(viewModeVariant);
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'widget';

    /////////////////////////////

    service.create = create;
    service.deleteCurrent = deleteCurrent;
    service.findWidgetsByType = findWidgetsByType;
    service.getAvailableWidgets = getAvailableWidgets;
    service.getBackwardCompatibleWidgetType = getBackwardCompatibleWidgetType;
    service.getCurrent = getCurrent;
    service.getEnabledExtensionWidgets = getEnabledExtensionWidgets;
    service.getGlobalWidgets = getGlobalWidgets;
    service.getParents = getParents;
    service.getWidgetsByType = getWidgetsByType;
    service.transformWidgetsByPredicate = transformWidgetsByPredicate;
    service.getWidgetTranslation = getWidgetTranslation;
    service.initStyleParams = initStyleParams;
    service.isDragDisabled = isDragDisabled;
    service.isWidgetUngrouped = isWidgetUngrouped;
    service.resetAvailableWidgets = resetAvailableWidgets;
    service.resetWidget = resetWidget;
    service.saveGlobalWidget = saveGlobalWidget;
    service.setCurrent = setCurrent;
    service.shouldDisplayWidgetHeader = shouldDisplayWidgetHeader;

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        const currentWidget = service.getCurrent();

        return {
            currentWidget,
        };
    }

    /**
     * Triggered when synced data is changed by redux.
     * It takes in the new state and should update the Angular service accordingly.
     *
     * @param {reduxState} state The part of the state that this service is concerned about.
     */
    function mapReduxToAngular({ currentWidget }) {
        // Update current widget content from redux and refresh content.
        if (
            _current &&
            currentWidget &&
            _current.properties &&
            currentWidget.properties &&
            !isEqual(_current.properties.content, currentWidget.properties.content)
        ) {
            _current.properties.content = angular.fastCopy(currentWidget.properties.content);
            $rootScope.$broadcast('content_refresh');
        }
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'legacyWidget';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'widget/update';

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;
    service.mapReduxToAngular = mapReduxToAngular;

    /////////////////////////////

    /**
     * Initialize the main nav redux store.
     */
    service.initReduxStore = function initReduxStore() {
        // Enable Redux sync.
        ReduxStore.subscribe(service, true);
    };

    /**
     * Initialize the service.
     */
    service.init = function init() {
        service.defaultParams = {};

        service.resetAvailableWidgets();
        ExtensionWidget.init(() => {
            service.getEnabledExtensionWidgets();
        });
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Widget', WidgetService);

/////////////////////////////

function WidgetContextFilter() {
    'ngInject';

    // TODO [max]: Do only one filter for the widget list.
    /**
     * Filter widget by context.
     * If the widget config do not have a context is undefined or if the context match, then allow the widget.
     *
     * @param  {Array} items The list to filter.
     * @param  {Array} ctx   The allowed context.
     * @return {Array} The filtered list.
     */
    return function widgetContext(items, ctx) {
        const filtered = [];

        angular.forEach(items, function widgetFilter(item) {
            if (angular.isUndefined(item.context) || intersection(item.context, ctx).length > 0) {
                filtered.push(item);
            }
        });

        return filtered;
    };
}

angular.module('Filters').filter('widgetContext', WidgetContextFilter);

/////////////////////////////

export { WidgetService, WidgetContextFilter };
