/* istanbul ignore file */

import { contentSelector, getCurrentContentHasCommentWidget } from '@lumapps/contents/ducks/selectors';
import { getContent } from '@lumapps/contents/ducks/thunks/getContent';
import { onFollow } from '@lumapps/contents/ducks/thunks/onFollow';
import { onLike } from '@lumapps/contents/ducks/thunks/onLike';
import { onLikeListOpen } from '@lumapps/contents/ducks/thunks/onLikeListOpen';
import { connect } from '@lumapps/redux/react';
import { BaseStore, Dispatch } from '@lumapps/redux/types';

import { ClientComputedSocialMetadata } from './ClientComputedSocialMetadata';

export const mapStateToProps = (state: BaseStore) => ({
    currentContent: contentSelector(state),
    hasCommentWidget: getCurrentContentHasCommentWidget(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchLikedStatus: (currentContentId: string) =>
        dispatch(
            getContent({ uid: currentContentId, fields: 'id,liked' }, 'liked', true, { 'x-lumapps-analytics': 'off' }),
        ),
    onLike: () => dispatch(onLike()),
    onFollow: () => dispatch(onFollow()),
    onLikeListOpen: () => dispatch(onLikeListOpen()),
});

const ConnectedClientComputedSocialMetadata = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClientComputedSocialMetadata);

export { ConnectedClientComputedSocialMetadata as ClientComputedSocialMetadata };
