import { currentLanguageSelector } from '@lumapps/languages';
import { error as notifyError } from '@lumapps/notifications';
import { batch } from '@lumapps/redux/react';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { list } from '@lumapps/user/api';

import { contentSelector } from '../selectors';
import { actions } from '../slice';

/**
 * Open the list of users liking the content.
 */
export const onLikeListOpen = () => async (dispatch: Dispatch, getState: GetBaseState) => {
    try {
        dispatch(actions.setLikesList({ items: [], isLoaded: false }));
        const state = getState();
        const { id } = contentSelector(state);
        const lang = currentLanguageSelector(state);
        const response = await list({
            reactedEntityKind: 'CONTENT',
            reactedEntityKey: id,
            status: 'ENABLED',
            maxResults: 10,
            fields: 'items(fullName,uid),more,callId,cursor',
            showHidden: false,
            lang,
        });
        dispatch(actions.setLikesList({ items: response.data.items, isLoaded: true }));
    } catch (error) {
        batch(() => {
            dispatch(actions.setLikesList({ items: [], isLoaded: true }));
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        });
    }
};
