/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const searchAnalyticsRoute: Route = {
    path: '/admin/search-analytics',
    legacyId: 'app.admin.search-analytics',
    appId: AppId.backOffice,
};

export const searchAnalytics = (): Route => searchAnalyticsRoute;
