import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Switch, Progress, FlexBox, Alignment, Message, Kind, Heading } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { useMetadataOptions } from '../../../hooks/useMetadataOptions/useMetadataOptions';
import { WIDGET_PLAY } from '../../../keys';
import { WidgetPlayPropertiesType, MetadataItems, VisibleMetadataObject } from '../../../types';

import './index.scss';

const CLASSNAME = 'metadata-selection';

export interface MetadataSelectionProps {
    // Update the widget properties with the selected metadata
    onChange: (properties: WidgetPlayPropertiesType) => void;
    // Object of { metadata: boolean }
    visibleMetadata: VisibleMetadataObject;
    videoLoading: boolean;
}

export const MetadataSelection = ({ onChange, visibleMetadata, videoLoading }: MetadataSelectionProps) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);

    const { toggleMetadataVisibility } = useMetadataOptions({
        onChange,
        visibleMetadata,
    });

    return videoLoading ? (
        <FlexBox vAlign={Alignment.center} className={element('loader')}>
            <Progress data-testid={element('progress')} />
        </FlexBox>
    ) : (
        <section>
            <Heading as="h5" className={element('subtitle')}>
                {translateKey(WIDGET_PLAY.DISPLAY_METADATA)}
            </Heading>

            <ul className={element('list')}>
                {(Object.keys(MetadataItems) as Array<keyof typeof MetadataItems>).map((metadata) => {
                    const metadataToTranslationKey = MetadataItems[metadata].toUpperCase() as keyof typeof GLOBAL;
                    const shouldDisplayTitleWarning = metadata === MetadataItems.title && !visibleMetadata.title;
                    return (
                        <React.Fragment key={metadata}>
                            <li className={element('list-item')}>
                                <Switch
                                    isChecked={visibleMetadata?.[metadata]}
                                    onChange={() => toggleMetadataVisibility(metadata)}
                                >
                                    {translateKey(GLOBAL[metadataToTranslationKey])}
                                </Switch>
                                {shouldDisplayTitleWarning && (
                                    <Message kind={Kind.warning} hasBackground className={element('warning-message')}>
                                        <p>{translateKey(WIDGET_PLAY.UNAVAILABLE_LINK)}</p>
                                    </Message>
                                )}
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
        </section>
    );
};
