/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const adminMobileCustomizationRoute: Route = {
    path: '/admin/mobile-customization',
    appId: AppId.backOffice,
    legacyId: 'app.admin.mobile-customization',
};

export const adminMobileCustomization = () => adminMobileCustomizationRoute;
