import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

const mobileAnalyticsRoute: Route = {
    path: '/admin/mobile-analytics',
    legacyId: 'app.admin.mobile-analytics',
    appId: AppId.backOffice,
};

const mobileAnalytics = (): Route => mobileAnalyticsRoute;

export { mobileAnalyticsRoute, mobileAnalytics };
