import React from 'react';

import { margin } from '@lumapps/classnames';
import { GapSize, GlobalSize, Size, SkeletonRectangle, SkeletonTypography } from '@lumapps/lumx/react';
import { UseMetadataListWithSelected } from '@lumapps/metadata-pickers/hooks/useMetadataListWithSelected';

import { FormFieldset, FormFieldsetProps } from '../FormFieldset';
import { FormMetadataField } from '../FormMetadataField';

export interface FormMetadataFieldsetProps extends FormFieldsetProps {
    /** metadatas to display */
    metadatas?: UseMetadataListWithSelected;
    /** gap to be used between fields */
    gap: GapSize;
    /** whether the each field should show multiple levels or just the first one */
    showMultipleLevels?: boolean;
}

export const FormMetadataFieldset: React.FC<FormMetadataFieldsetProps> = ({
    metadatas,
    gap,
    showMultipleLevels,
    ...props
}) => {
    if (metadatas && metadatas.isLoading) {
        return (
            <>
                <SkeletonTypography typography="body1" width="300px" className={margin('bottom')} />
                <SkeletonRectangle width={'100%' as GlobalSize} height={Size.m} className={margin('bottom', 'big')} />
                <SkeletonTypography typography="body1" width="300px" className={margin('bottom')} />
                <SkeletonRectangle width={'100%' as GlobalSize} height={Size.m} />
            </>
        );
    }

    if (metadatas && !metadatas.hasError && metadatas.parentMetadatas.length > 0) {
        return (
            <FormFieldset typography="title" {...props} fieldsetProps={{ gap, ...props.fieldsetProps }}>
                {metadatas.parentMetadatas.map((parentMetadata) => {
                    return (
                        <FormMetadataField
                            name={`metadatas.${parentMetadata.id}`}
                            key={parentMetadata.id}
                            family={parentMetadata}
                            showMultipleLevels={showMultipleLevels}
                        />
                    );
                })}
            </FormFieldset>
        );
    }

    return null;
};
