import {
    getChannelById,
    getChannelByName,
    getUserPhoto,
    getUserSpaceById,
    hasUserJoinedChannel,
    listChannelsForUserSpace,
    listJoinedUserSpaces,
    listMessagesForChannel,
    listProviders,
    listUserSpaces,
    postMessageToChannel,
} from '@lumapps/chat/api/legacyApi';

const fetchUserSpacesTask = ({ cursor, maxResults, page, provider }) => {
    return listUserSpaces({ cursor, maxResults, page, provider }).then((response) => ({ payload: response.data }));
};

const fetchJoinedUserSpacesTask = ({ cursor, maxResults, page, provider }) => {
    return listJoinedUserSpaces({ cursor, maxResults, page, provider }).then((response) => ({
        payload: response.data,
    }));
};

const fetchChannelsForUserSpaceTask = ({ callId, cursor, maxResults, page, provider, spaceId }) => {
    return listChannelsForUserSpace({ callId, cursor, maxResults, page, provider, spaceId }).then((response) => ({
        payload: response.data,
    }));
};

const getChannelByIdTask = ({ provider, spaceId, channelId }) => {
    return getChannelById({ provider, spaceId, channelId }).then((response) => ({
        payload: response.data,
    }));
};

const getChannelByNameTask = ({ provider, spaceId, channelName }) => {
    return getChannelByName({ provider, spaceId, channelName }).then((response) => ({
        payload: response.data,
    }));
};

const getUserSpaceByIdTask = ({ provider, spaceId }) => {
    return getUserSpaceById({ provider, spaceId }).then((response) => ({
        payload: response.data,
    }));
};

const hasUserJoinedChannelTask = ({ provider, spaceId, channelId }) => {
    return hasUserJoinedChannel({ provider, spaceId, channelId }).then((response) => ({
        payload: response.data,
    }));
};

const fetchMessagesForChannelTask = ({
    callId,
    channelId,
    chatMessageId,
    cursor,
    fields,
    maxResults,
    page,
    provider,
    spaceId,
    organizationId,
    isSlackDefaultAppEnabled,
}) => {
    return listMessagesForChannel({
        callId,
        channelId,
        chatMessageId,
        cursor,
        fields,
        maxResults,
        page,
        provider,
        spaceId,
        organizationId,
        isSlackDefaultAppEnabled,
    }).then((response) => ({ payload: response.data }));
};

const createMessageForChannelTask = ({
    body,
    bodyContentType,
    callId,
    channelId,
    chatMessageId,
    provider,
    spaceId,
}) => {
    return postMessageToChannel({
        body,
        bodyContentType,
        callId,
        channelId,
        chatMessageId,
        provider,
        spaceId,
    }).then((response) => ({ payload: response.data }));
};

const fetchUserPhotoTask = ({ provider, userId }) => {
    return getUserPhoto({ provider, userId }).then((response) => ({ payload: response.data }));
};

const fetchProvidersTask = () => {
    return listProviders().then((response) => ({ payload: response.data }));
};

export {
    hasUserJoinedChannelTask,
    fetchUserSpacesTask,
    fetchJoinedUserSpacesTask,
    fetchChannelsForUserSpaceTask,
    fetchMessagesForChannelTask,
    createMessageForChannelTask,
    fetchUserPhotoTask,
    fetchProvidersTask,
    getChannelByIdTask,
    getChannelByNameTask,
    getUserSpaceByIdTask,
};
