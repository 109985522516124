export enum WIDGET_LEGACY_CORE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231550197
     */
    WIDGET_PICKER_MOBILE_INCOMPATIBLE_MESSAGE = 'WIDGET_LEGACY_CORE.WIDGET_PICKER_MOBILE_INCOMPATIBLE_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231559233
     */
    MOBILE_INCOMPATIBLE_WIDGET_LIST_VARIABLE = 'WIDGET_LEGACY_CORE.MOBILE_INCOMPATIBLE_WIDGET_LIST_VARIABLE',
}
