import { useCallback, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from '@lumapps/redux/react';

import { isEventDialogOpenSelector } from '../ducks/selectors';
import { actions } from '../ducks/slice';

/** Hook to get the event dialog open state and toggle it. */
export const useEventDialog = () => {
    const dispatch = useDispatch();
    const dialogTriggerRef = useRef<HTMLButtonElement>(null);

    const isEventDialogOpen = useSelector(isEventDialogOpenSelector);
    const openEventDialog = useCallback(() => dispatch(actions.openDialog()), [dispatch]);
    const closeEventDialog = useCallback(() => dispatch(actions.closeDialog()), [dispatch]);

    /** Closing the dialog on component unmount */
    useEffect(() => {
        return () => {
            closeEventDialog();
        };
    }, [closeEventDialog]);

    return {
        isOpen: isEventDialogOpen,
        openEventDialog,
        closeEventDialog,
        dialogTriggerRef,
    };
};
