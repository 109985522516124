import React from 'react';

import { GridColumn, Theme } from '@lumapps/lumx/react';

import { MAX_COLUMNS_TRAINING_LIST, MIN_WIDTH_TRAINING_LIST_ITEMS } from '../../constants';
import { TrainingCoursesViewModeVariantType, Training as TrainingType } from '../../types';
import { TrainingCard } from '../TrainingCard';

export interface TrainingListProps {
    scope: string;
    trainings: TrainingType[];
    maxColumns?: number;
    minWidth?: number;
    isLoading?: boolean;
    viewModeVariant?: TrainingCoursesViewModeVariantType;
    theme?: Theme;
}

/**
 * Grid List of user's trainings
 * @param TrainingListProps
 * @returns TrainingList
 */

export const TrainingList: React.FC<TrainingListProps> = ({
    scope,
    trainings,
    maxColumns = MAX_COLUMNS_TRAINING_LIST,
    minWidth = MIN_WIDTH_TRAINING_LIST_ITEMS,
    isLoading = false,
    viewModeVariant,
    theme = Theme.light,
}) => (
    <GridColumn itemMinWidth={minWidth} maxColumns={maxColumns} gap="huge">
        {trainings &&
            !isLoading &&
            trainings.map((singleTraining) => (
                <TrainingCard
                    scope={scope}
                    viewModeVariant={viewModeVariant}
                    key={singleTraining.identifier}
                    training={singleTraining}
                    theme={theme}
                />
            ))}
    </GridColumn>
);
