(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetCommunityListController($scope, Community, Config, Content, ContentPicker, ContentTemplate, Instance,
        Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Community picker modal identifier - only for basic mode.
         *
         * @type {string}
         */
        var _COMMUNITY_PICKER_ID = 'widget-community-list-settings-content-picker-';

        /**
         * Describe the fields we want to get in the list.
         *
         * @type {Object}
         */
        var _projection = {};

        /**
         * Describe the fields we want to get in the `subscription` field if needed.
         *
         * @type {Object}
         */
        var _subscriptionProjection = {
            customerKey: true,
            followerUserKey: true,
            followingContentKey: true,
            followingContentType: true,
            id: true,
            instanceKey: true,
            notify: true,
            type: true,
            uid: true,
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the needed projections according to the parameters of the content list.
         */
        function _initProjections() {
            _projection = {
                items: {
                    canContribute: true,
                    id: true,
                    instance: true,
                    privacy: true,
                    properties: true,
                    slug: true,
                    uid: true,
                    renderingType: true
                },
            };

            var properties = vm.widget.properties;

            if (angular.isDefinedAndFilled(properties.fields)) {
                angular.forEach(properties.fields, function forEachFields(field) {
                    if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name) || !field.enable) {
                        return;
                    }

                    var fieldName = field.name.toLowerCase();
                    switch (fieldName) {
                        case 'title':
                            _projection.items.title = true;
                            break;

                        case 'content':
                            _projection.items.description = true;
                            break;

                        case 'follow':
                            _projection.items.subscription = _subscriptionProjection;
                            break;

                        case 'thumbnail':
                            _projection.items.thumbnail = true;
                            break;

                        default:
                            break;
                    }
                });
            } else {
                _projection.items.description = true;
                _projection.items.id = true;
                _projection.items.instance = true;
                _projection.items.slug = true;
                _projection.items.subscription = _subscriptionProjection;
                _projection.items.thumbnail = true;
                _projection.items.title = true;
                _projection.items.uid = true;
            }
        }

        /**
         * Initialize the community list.
         *
         * @param {Object} additionalFilters The additional filters provided by the widget content filter.
         */
        function _initList(additionalFilters) {
            var properties = vm.widget.properties;
            additionalFilters = additionalFilters || {};

            var displayType = properties.displayType;
            var params = {
                followingOnly: Boolean(properties.followingOnly),
                isCommunityAdmin: Boolean(properties.isCommunityAdmin),
                ids: (displayType === 'pick') ? properties.communityIds : [],
                status: [
                    Config.CONTENT_STATUS.LIVE.value,
                ],
            };

            if (angular.isNumber(properties.maxNumber)) {
                params.maxResults = properties.maxNumber;
            }

            if (angular.isDefinedAndFilled(additionalFilters.query)) {
                params.query = additionalFilters.query;
            }

            if (displayType === 'list') {
                if (angular.isDefinedAndFilled(additionalFilters.instance)) {
                    params.instanceId = additionalFilters.instance;
                } else if (_.get(properties, 'isAllInstancesSiblings', false)) {
                    params.instanceId = undefined;
                    // Set currentInstanceId property to fetch content from all siblings.
                    params.currentInstanceId = Instance.getCurrentInstanceId();
                } else if (angular.isDefinedAndFilled(properties.instance)) {
                    params.instanceId = properties.instance;
                } else {
                    params.instanceId = [Instance.getCurrentInstanceId()];
                }
            } else {
                params.instanceId = undefined;
            }

            // If we're in 'pick' mode and have not selected anything yet, we want to display no results.
            if (displayType === 'pick' && angular.isUndefinedOrEmpty(properties.communityIds)) {
                angular.extend(params, {
                    queryType: 'getContentByIds',
                });
            }

            _initProjections();

            Community.filterize(params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                vm.widgetListCtrl.getListKey(), _projection);
        }

        /**
         * Initialize the widget properties and the controller parameters.
         */
        function _initProperties() {
            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            if (angular.isUndefinedOrEmpty(properties.viewMode)) {
                properties.viewMode = 'list';
            }

            if (angular.isUndefinedOrEmpty(properties.displayType)) {
                properties.displayType = 'list';
            }

            if (angular.isUndefinedOrEmpty(properties.viewModeVariant)) {
                properties.viewModeVariant = 'group';
            }

            if (angular.isUndefinedOrEmpty(properties.itemsSeparator)) {
                properties.itemsSeparator = true;
            }

            // Fields manager.
            var defaultFields = Config.COMMUNITY_FIELDS;

            var firstFieldsInit = false;

            if (angular.isUndefinedOrEmpty(properties.fields)) {
                properties.fields = [];
                firstFieldsInit = true;
            }

            angular.forEach(defaultFields, function forEachDefaultField(defaultField) {
                if (angular.isString(defaultField)) {
                    defaultField = {
                        // Note: we want thumbnails to be displayed by default because we didnt have a setting for it.
                        enable: (defaultField === 'thumbnail') ? true : firstFieldsInit,
                        name: defaultField,
                    };
                }

                var findInDefaultFields = _.find(properties.fields, {
                    name: defaultField.name,
                });

                if (firstFieldsInit || angular.isUndefinedOrEmpty(findInDefaultFields)) {
                    properties.fields.push(defaultField);
                }
            });

            var instanceProperty = properties.instance || [];
            var instanceSiblings = [];

            Instance.getSiblings().then(function onInstanceSiblingsSuccess(fetchedSiblings) {
                instanceSiblings = fetchedSiblings;

                if ((instanceSiblings.length > 1 && instanceProperty.length > 1) ||
                    properties.isAllInstancesSiblings || properties.displayType === 'pick') {
                    properties.isInstanceNameDisplayed = (angular.isDefined(properties.isInstanceNameDisplayed)) ?
                        properties.isInstanceNameDisplayed : true;
                } else {
                    properties.isInstanceNameDisplayed = false;
                }

                properties.instance = (angular.isDefinedAndFilled(instanceProperty)) ? instanceProperty :
                    [Instance.getCurrentInstanceId()];
            });
        }

        /**
         * Open community picker (only when widget selection type is pick).
         */
        function _openCommunityPicker() {
            if (vm.widget.properties.displayType !== vm.widgetListCtrl.SELECTION_TYPES.pick ||
                Content.getViewMode() !== 'basic' || Content.getAction() === 'get') {
                return;
            }

            Utils.waitForAndExecute('#' + _COMMUNITY_PICKER_ID, function onContentPickerDialogReady() {
                ContentPicker.open(_COMMUNITY_PICKER_ID);
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the controller.
         *
         * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
         */
        vm.init = function init(saveListKey) {
            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            properties.communityIds = properties.communityIds || [];

            _COMMUNITY_PICKER_ID += vm.widget.uuid;

            vm.widgetListCtrl.init(saveListKey);
        };

        /**
         * Set parent controller.
         *
         * @param {Object} widgetListCtrl The parent controller object.
         */
        this.setParentController = function setParentController(widgetListCtrl) {
            vm.widgetListCtrl = widgetListCtrl;

            vm.widgetListCtrl.widgetListChildCtrl = {
                initList: _initList,
                initProperties: _initProperties,
                onWidgetListClick: _openCommunityPicker,
            };

            vm.init(true);
        };
    }

    /////////////////////////////

    /**
     * The community list widget.
     * Displays a list of communities (either manually picked or the whole list with filters applied).
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetCommunityListDirective() {
        'ngInject';

        function WidgetCommunityListLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetCommunityListController,
            controllerAs: 'vm',
            link: WidgetCommunityListLink,
            replace: true,
            require: ['widgetCommunityList', '^widgetList'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-community-list/views/widget-community-list.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetCommunityList', WidgetCommunityListDirective);
})();
