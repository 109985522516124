import { isFeatureEnabled } from '@lumapps/features';
import { isUserAllowed, isInstanceSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

import { ANALYTICS_PERMISSIONS } from '../permissions';

export const isExtendedAnalyticsEnabled = isFeatureEnabled('extended-lumapps-analytics');
export const isPauseAnalyticsCollectionEnabled = isFeatureEnabled('disable-analytics-collection');
export const hasDiscoverBetaEnabled = isFeatureEnabled('discover-beta');

export const isAnalyticsAllowed = isUserAllowed(ANALYTICS_PERMISSIONS.ANALYTICS_READ);

export const canViewAnalyticsAdmin = createSelector(
    isInstanceSuperAdmin,
    isExtendedAnalyticsEnabled,
    isAnalyticsAllowed,
    (superAdmin = false, isAnalyticEnabled = false, isAnalyticsReadAllowed = false) => {
        const hasAccess = superAdmin || isAnalyticsReadAllowed;

        return hasAccess && isAnalyticEnabled;
    },
);
