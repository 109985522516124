import * as React from 'react';
import * as propTypes from 'prop-types';

import take from 'lodash/take';

/**
 * Renders in a `span` the first letter of `value`, or an empty string if `value` is falsy.
 */
export class FirstLetter extends React.PureComponent {
    static propTypes = {
        /** Defines the number of letters of the text value to display. */
        maxLength: propTypes.number,
        /** The string from which the first letter should be extracted. */
        value: propTypes.string.isRequired,
    };

    render() {
        const { maxLength = 1, value } = this.props;

        if (!value) {
            return null;
        }

        return <span>{take(value.toUpperCase(), maxLength) || ''}</span>;
    }
}
