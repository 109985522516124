import { getAskAiAdminPath } from '@lumapps/ask-ai/utils';
import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

import { AskAiRule } from './types';

/**
 * Ask Ai Rules Routes
 */
/** List */
export const askAiBaseRulesAdminRoute: Route = {
    path: getAskAiAdminPath('rules'),
    legacyId: 'app.admin.ai-search-rules',
    appId: AppId.backOffice,
};
export const askAiBaseRulesAdmin = (): Route => askAiBaseRulesAdminRoute;

/** Edit */
export const askAiEditRuleRoute: Route = {
    ...askAiBaseRulesAdminRoute,
    path: `${getAskAiAdminPath('rules')}/edit/:id`,
};
export const askAiEditRule = (rule: Pick<AskAiRule, 'id'>): Route => ({
    ...askAiEditRuleRoute,
    params: {
        id: rule.id,
    },
});

export interface RuleParams {
    id: string;
}

/** Create */
export const askAiCreateRuleRoute: Route = {
    ...askAiBaseRulesAdminRoute,
    path: `${getAskAiAdminPath('rules')}/create`,
};
export const askAiCreateRule = (): Route => ({
    ...askAiCreateRuleRoute,
});
