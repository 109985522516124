import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const platformAnalyticsRoutesMatch = '/admin/platform-analytics/:slug';
const platformAnalyticsRoutes: Route = {
    path: '/admin/platform-analytics',
    legacyId: 'app.admin.platform-analytics',
    appId: AppId.backOffice,
};

const platformAnalytics = (): Route => platformAnalyticsRoutes;

export { platformAnalytics, platformAnalyticsRoutes, platformAnalyticsRoutesMatch };
