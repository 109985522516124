import { USER_SUBSCRIPTIONS_FILTERS } from './types';

export enum SUBSCRIPTIONS {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453656
     */
    COMMUNITIES = 'SOCIAL_PROFILE_COMMUNITIES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453657
     */
    CONTENT = 'SOCIAL_PROFILE_CONTENT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453663
     */
    INTERESTS = 'SOCIAL_PROFILE_INTERESTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11416948
     */
    FOLLOW_HELP_TEXT = 'FRONT.FOLLOW_HELP_TEXT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453660
     */
    FOLLOWERS = 'SOCIAL_PROFILE_FOLLOWERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11416961
     */
    UNFOLLOW_HELP_TEXT = 'FRONT.UNFOLLOW_HELP_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181292217
     */
    FOLLOWED_COMMUNITIES = 'SUBSCRIPTIONS.FOLLOWED_COMMUNITIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181292566
     */
    FOLLOWED_CONTENT = 'SUBSCRIPTIONS.FOLLOWED_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181293007
     */
    FOLLOWED_PEOPLE = 'SUBSCRIPTIONS.FOLLOWED_PEOPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181293095
     */
    FOLLOWED_INTERESTS = 'SUBSCRIPTIONS.FOLLOWED_INTERESTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11416994
     */
    SUBSCRIBE_NOTIFICATIONS_HELP_TEXT = 'FRONT.SUBSCRIBE_NOTIFICATIONS_HELP_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11417079
     */
    UNSUBSCRIBE_NOTIFICATIONS_HELP_TEXT = 'FRONT.UNSUBSCRIBE_NOTIFICATIONS_HELP_TEXT',
}

export const SubscriptionsTitleByType = {
    [USER_SUBSCRIPTIONS_FILTERS.communities]: SUBSCRIPTIONS.FOLLOWED_COMMUNITIES,
    [USER_SUBSCRIPTIONS_FILTERS.content]: SUBSCRIPTIONS.FOLLOWED_CONTENT,
    [USER_SUBSCRIPTIONS_FILTERS.people]: SUBSCRIPTIONS.FOLLOWED_PEOPLE,
    [USER_SUBSCRIPTIONS_FILTERS.interests]: SUBSCRIPTIONS.FOLLOWED_INTERESTS,
};
