import React from 'react';

import {
    AspectRatio,
    FlexBox,
    Orientation,
    Size,
    Thumbnail,
    ThumbnailVariant,
    Theme,
    FlexBoxProps,
    InlineList,
} from '@lumx/react';

import { classnames } from '@lumapps/classnames';
import { HtmlAttributesWithData } from '@lumapps/data-attributes/types';

import { REAL_SIZE_FOR_LUMX_SIZE } from '../../../constants';

import './index.scss';

/**
 * Defines the props of the GenericEntityBlock.
 */
export interface GenericEntityBlockProps {
    /** Theme. */
    theme?: Theme;
    /** CSS className. */
    className?: string;
    /** Content thumbnail. */
    thumbnail?: React.ReactNode;
    /** Content title. */
    title?: React.ReactNode;
    /** Content description. */
    description?: React.ReactNode;
    /** Content additional info. */
    additionalInfo?: React.ReactNode;
    /** Should the content be wrapped */
    noWrap?: boolean;
    /** Props for the title element */
    titleProps?: HtmlAttributesWithData;
    /** Description for the title element */
    descriptionProps?: React.HTMLAttributes<HTMLDivElement>;
    /** whether the entity is in a disabled state or not */
    isDisabled?: boolean;
    /** gap size to be displayed between the entity's title and description */
    gap?: FlexBoxProps['gap'];
    /** elements displayed last */
    after?: React.ReactNode;
    /** Content before additional info. */
    beforeAdditionalInfo?: React.ReactNode;
}

const CLASSNAME = 'generic-entity-block';

/** Assert node is a LumX Thumbnail. */
const isThumbnail = (node: any): node is React.ReactElement => node?.type === Thumbnail;

/**
 * THIS COMPONENT IS DEPRECATED BUT CANNOT BE DELETED SINCE THERE MIGHT BE SOME CUSTOMERS
 * WHICH THEIR CSS IS BASED UPON THESE CLASSES. PLEASE DO NOT USE AND REFER TO `@lumapps/lumx-blocks/components/GenericEntityBlock`
 *
 * A generic entity with thumbnail, title, description and additional info.
 *
 * @family Blocks
 * @param GenericEntityBlockProps
 * @deprecated please use `@lumapps/lumx-blocks/components/GenericEntityBlock`
 * @returns GenericEntityBlock
 */
export const GenericEntityBlock = React.forwardRef<HTMLDivElement, GenericEntityBlockProps>((props, ref) => {
    const {
        className,
        title,
        description,
        thumbnail,
        additionalInfo,
        noWrap = true,
        titleProps,
        descriptionProps,
        theme = Theme.light,
        isDisabled,
        gap = Size.big,
        after,
        beforeAdditionalInfo,
        ...rest
    } = props;

    return (
        <FlexBox
            ref={ref}
            className={classnames(className, CLASSNAME, {
                [`${CLASSNAME}--no-wrap`]: noWrap,
                [`${CLASSNAME}--disabled`]: isDisabled,
                [`${CLASSNAME}--theme-${theme}`]: theme === Theme.dark,
            })}
            orientation={Orientation.horizontal}
            gap={gap}
            {...rest}
        >
            {thumbnail &&
                (isThumbnail(thumbnail) ? (
                    /* Adapt thumbnail props. */
                    React.cloneElement(thumbnail, {
                        className: classnames(`${CLASSNAME}__thumbnail`, thumbnail.props.className),
                        size: thumbnail.props.size || Size.l,
                        aspectRatio: thumbnail.props.aspectRatio || AspectRatio.square,
                        variant: thumbnail.props.variant || ThumbnailVariant.rounded,
                        width: REAL_SIZE_FOR_LUMX_SIZE[
                            (thumbnail.props.size as keyof typeof REAL_SIZE_FOR_LUMX_SIZE) || Size.l
                        ],
                        height: REAL_SIZE_FOR_LUMX_SIZE[
                            (thumbnail.props.size as keyof typeof REAL_SIZE_FOR_LUMX_SIZE) || Size.l
                        ],
                    })
                ) : (
                    /* Display "thumbnail" node as-is. */
                    <div className={`${CLASSNAME}__thumbnail`}>{thumbnail}</div>
                ))}
            <FlexBox orientation={Orientation.vertical} className={`${CLASSNAME}__detail`}>
                {title && (
                    <span className={`${CLASSNAME}__title`} {...titleProps}>
                        {title}
                    </span>
                )}
                {beforeAdditionalInfo && (
                    <p className={`${CLASSNAME}__before-additional-info`}>{beforeAdditionalInfo}</p>
                )}
                {description && (
                    <p className={`${CLASSNAME}__description`} {...descriptionProps}>
                        {description}
                    </p>
                )}
                {React.Children.toArray(additionalInfo).length > 0 && (
                    <InlineList className={`${CLASSNAME}__additional-info`}>{additionalInfo}</InlineList>
                )}
            </FlexBox>
            {after}
        </FlexBox>
    );
});
GenericEntityBlock.displayName = 'GenericEntityBlock';
