import { Theme } from '@lumapps/lumx/react';
import { MetadataFilter } from '@lumapps/metadata-pickers/types';
import { TranslatableObject, TranslateObject } from '@lumapps/translations';
import { LegacyFieldsType, LegacyWidgetProperties } from '@lumapps/widget-base/types';

/**
 * See LEGACY_TO_NGI_FILTERS_MAP in the ./contants.ts file to pass from legacy to ngi data shape
 */
export enum LegacyFilterId {
    metadata = 'metadata',
    query = 'query',
    tags = 'tags',
    directoryTags = 'directory-tags',
    featureFeeds = 'feature-feeds',
    dates = 'dates',
    author = 'author',
    instance = 'instance',
}

export enum NGIFilterId {
    metadata = 'metadata',
    query = 'searchQuery',
    tags = 'tags',
    onlyHighlighted = 'onlyHighlighted',
    publicationDates = 'publicationDates',
    author = 'author',
    site = 'site',
}

/**
 * Legacy properties of the Content Filter widget.
 *
 * NB: Non-exhaustive type, only migrated and used fields are listed here.
 */
export interface LegacyContentFilterProperties {
    /** Ordered list of available filters */
    filters: LegacyFieldsType<LegacyFilterId>;
    style: LegacyWidgetProperties['style'];
    /** Is the "Follow This Interest" button disabled */
    disableFollowButton: boolean;
    /** Is the subheader titles are hidden */
    hideSubheader?: boolean;
}

export interface ContentFilterItemProps<V> {
    value: V;
    onChange: (value: V) => void;
    theme: Theme;
    scope: string;
    hideSubheader: boolean;
}

export type MetadataFilteredBy = {
    id: string;
    name: TranslateObject;
    description: string;
    root: Omit<MetadataFilteredBy, 'root' | 'parent'>;
    parent: Omit<MetadataFilteredBy, 'root' | 'parent'> & {
        parentId: string;
    };
};

export type SiteFilteredBy = {
    id: string;
    name: string;
    slug: string;
};

export type TagFilteredBy = {
    id: string;
    name: TranslatableObject;
};

export type ContentTypeFilteredBy = {
    id: string;
    name: TranslatableObject;
    siteId: string;
};

export type DirectoryFilteredBy = {
    id: string;
    name: TranslateObject;
};

export type AuthorFilteredBy = {
    id: string;
    fullName: string;
    email: string;
};

/**
 * Filter properties of a widget. Used by the widget content-filter.
 */

export type SortType =
    | 'author_email'
    | '-author_email'
    | 'comment_count'
    | '-comment_count'
    | 'publication_date'
    | '-publication_date'
    | 'rating'
    | '-rating'
    | 'title'
    | '-title'
    | 'updated_date'
    | '-updated_date';

export type GenericListFilterProperties = {
    /** The content types the list should be filtered on. */
    contentTypeReferences?: ContentTypeFilteredBy[];
    /** Metadata the list should be filtered on. */
    metadata?: MetadataFilteredBy[];
    /** The sites the list should be filtered on. */
    siteReferences?: SiteFilteredBy[];
    /** The directories the list should be filtered on. */
    directoryReferences?: DirectoryFilteredBy[];
    /** The sites the list should be filtered on. */
    tags?: TagFilteredBy[];
    /** The author the list should be filtered on. */
    authorReference?: AuthorFilteredBy;
    /** Either we are getting only highlighted content or not */
    onlyHighlighted?: boolean;
    /**
     * The field the list should be sorted on. The direction is indicated
     * by the - character prefixing the field name.
     */
    sort?: SortType;
    /** Whether the content list widget is displayed with tabs.  */
    areTabsEnabled?: boolean;
};

/**
 * Filter properties of a widget. Formated for the store.
 */

export type FormattedSiteFilter = Omit<SiteFilteredBy, 'slug'> & Partial<SiteFilteredBy>;

export type FormattedContentTypeFilter = ContentTypeFilteredBy;

export type FormattedDirectoryFilter = DirectoryFilteredBy;

export type FormattedTagFilter = {
    name: string;
    id: string;
    cct?: {
        name: string;
        id: string;
    };
    site?: FormattedSiteFilter;
    directory?: {
        name: string;
        id: string;
    };
};

export type FormattedPublicationDates = {
    startDate?: string;
    endDate?: string;
};

export type FormattedAuthorFilter = {
    email: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
};

/** Filter type in Redux state */
export interface GenericListFilterType {
    searchQuery?: string;
    metadata?: MetadataFilter[];
    tab?: string;
    tags?: FormattedTagFilter[];
    site?: FormattedSiteFilter[];
    contentTypes?: FormattedContentTypeFilter[];
    directories?: FormattedDirectoryFilter[];
    author?: FormattedAuthorFilter;
    ignoredDefaultFilters?: NGIFilterId[];
    onlyHighlighted?: boolean;
    sort?: SortType;
    publicationDates?: FormattedPublicationDates;
    areTabsEnabled?: boolean;
}

/**
 * Follow Interest
 */

export type FollowInterestPayload = {
    customContentType: string;
    customContentTypeTags: string[];
    orMetadata: string[];
    instanceId?: string;
    notify?: boolean;
};

/**
 * URL types
 */

export interface URLGenericListFiltersType {
    search_query?: string;
    metadata_ids?: string[];
    tags_id?: string[];
    site_ids?: string[];
    ignored_default_filters?: string[];
}

export enum URLGenericListFilterEnum {
    ignored_default_filters = 'ignored_default_filters',
    search_query = 'search_query',
    metadata_ids = 'metadata_ids',
    tags_ids = 'tags_ids',
    only_highlighted = 'only_highlighted',
    end_date = 'end_date',
    start_date = 'start_date',
    author = 'author',
    site_ids = 'site_ids',
}
