import React, { Fragment } from 'react';

import { RenderElementProps } from 'slate-react';

import type { RenderLeafType, Wrex } from '../types';
/**
 * We have to add a non editable invisible character at the beginning and
 * the end of each inline element to make sure that we can always navigate
 * out of the element. This is to fix a bug with Chromium. It doesn't have any
 * impact on other browsers.
 */
const InlineChromiumBugfix = () => (
    <span contentEditable={false} style={{ fontSize: 0 }}>
        {String.fromCodePoint(160) /* Non-breaking space */}
    </span>
);

type RenderersIndex = Record<string, React.FC<any>>;

/**
 * Composes element renderers into one renderer.
 *
 * @param DefaultRenderer The default renderer if the type is not found in the index
 * @param renderers       Index of renderers by element type.
 */
export const renderElements =
    (
        DefaultRenderer: React.FC<any>,
        renderers: RenderersIndex = {},
    ): ((props: RenderElementProps & { isReadOnly?: boolean }) => JSX.Element) =>
    (props) => {
        const { attributes, children, element, isReadOnly } = props;
        const { ref, ...forwardedProps } = attributes;
        const Renderer = renderers[(element as Wrex.Element).type as string];

        if (Renderer) {
            const isElementInline = attributes['data-slate-inline'];
            const isElementVoid = attributes['data-slate-void'];

            return (
                <Renderer elementRef={ref} {...forwardedProps} readOnly={isReadOnly} element={element}>
                    {isElementInline && !isElementVoid && <InlineChromiumBugfix />}
                    {children}
                    {isElementInline && !isElementVoid && <InlineChromiumBugfix />}
                </Renderer>
            );
        }

        return (
            <DefaultRenderer elementRef={ref} {...forwardedProps}>
                {children}
            </DefaultRenderer>
        );
    };

/**
 * Composes a base leaf renderer with mark renderers.
 *
 * @param markRenderers Index of renderers by mark type.
 * @param options.viewMode activate to avoid using span (use fragments).
 */
export const renderLeafs =
    (markRenderers: RenderersIndex = {}, options: { viewMode?: boolean } = {}): RenderLeafType =>
    (props) => {
        const { attributes, leaf, children } = props;

        const content = Object.entries(markRenderers)
            .filter(([leafType]) => (leaf as Wrex.Text)[leafType])
            .reduce(
                (acc, [, Renderer]) => (
                    <Renderer attributes={attributes} leaf={leaf}>
                        {acc}
                    </Renderer>
                ),
                children,
            );

        // Use <span> in edition mode to help slate match to an actual DOM node
        // Use Fragment in view mode to reduce the number of unnecessary DOM nodes.
        const Leaf = options?.viewMode ? Fragment : 'span';
        return <Leaf {...attributes}>{content}</Leaf>;
    };
