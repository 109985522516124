import { useMemo } from 'react';

import { mdiFormatAlignCenter, mdiFormatAlignLeft, mdiFormatAlignRight } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ALIGNMENTS, PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';
import { Alignment } from '@lumapps/wrex/types/core';

import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

export const ICONS = {
    [ALIGNMENTS.start]: mdiFormatAlignLeft,
    [ALIGNMENTS.center]: mdiFormatAlignCenter,
    [ALIGNMENTS.end]: mdiFormatAlignRight,
};

const KEYS = {
    withShortcuts: {
        [ALIGNMENTS.start]: WREX_TYPOGRAPHY.ALIGN_START_WITH_SHORTCUT,
        [ALIGNMENTS.center]: WREX_TYPOGRAPHY.ALIGN_CENTER_WITH_SHORTCUT,
        [ALIGNMENTS.end]: WREX_TYPOGRAPHY.ALIGN_END_WITH_SHORTCUT,
    },
    withoutShortcuts: {
        [ALIGNMENTS.start]: WREX_TYPOGRAPHY.ALIGN_START,
        [ALIGNMENTS.center]: WREX_TYPOGRAPHY.ALIGN_CENTER,
        [ALIGNMENTS.end]: WREX_TYPOGRAPHY.ALIGN_END,
    },
};

export const useAlignButton = (alignment: Alignment, otherProps?: any) => {
    const { translateAndReplace } = useTranslate();

    const editor = useSlateStatic() as ReactEditor & TypographyEditor;

    const isSelected = editor.getTextAlignment() === alignment;
    const isDisabled = !editor.isTextAlignmentAllowed();

    const alignButton = useMemo(() => {
        const onClick = () => {
            if (!editor.selection) {
                initSelection(editor, 'inline');
            }
            editor.changeTextAlignment(alignment);
            focusAt(editor, editor.selection);
        };
        const markButtonProps: ToolbarItem = {
            type: 'toggle-option',
            itemKey: alignment,
            icon: ICONS[alignment],
            tooltipLabel: translateAndReplace(KEYS.withShortcuts[alignment], {
                shortcut: PLUGIN_SHORTCUTS[alignment].shortcut,
            }),
            onClick,
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            verticalModeProps: {
                label: translateAndReplace(KEYS.withoutShortcuts[alignment], {
                    shortcut: PLUGIN_SHORTCUTS[alignment].shortcut,
                }),
                tooltipLabel: translateAndReplace(KEYS.withShortcuts[alignment], {
                    shortcut: PLUGIN_SHORTCUTS[alignment].shortcut,
                }),
            },
            otherProps,
        };
        return markButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, isSelected, translateAndReplace, alignment, otherProps?.isDisabled]);

    return alignButton;
};
