import React from 'react';
import { useContent } from '@lumapps/contents/hooks/useContent';
import {
    CustomizationComponent,
    Targets,
    PLACEMENT,
    computeCustomizationTargets,
    getComponent,
} from '@lumapps/customizations/api';

/**
 * Customizations wrapper useful for embedding the customizations component for the application
 * on the legacy side.
 */
export const CustomizationsApp = () => {
    return (
        <>
            <CustomizationComponent target={Targets.APP} placement={PLACEMENT.LEFT} />
            <CustomizationComponent target={Targets.APP} placement={PLACEMENT.UNDER} />
            <CustomizationComponent target={Targets.APP} placement={PLACEMENT.RIGHT} />
        </>
    );
};

export const CustomizationsPage = (props) => {
    const { id, placement } = props;

    const { currentContentType } = useContent();

    const pageTokens = id.split('.');

    const pageId = pageTokens[pageTokens.length - 1].replace('-get', '').replace('profile', 'user-profile');

    const { id: computedPageId, type: computedPageType, shouldAddPageTypeCustomization } = computeCustomizationTargets(
        pageId,
        currentContentType,
    );

    if (placement === PLACEMENT.ABOVE) {
        return (
            <>
                <CustomizationComponent target={Targets.PAGE} placement={placement} />
                <CustomizationComponent target={computedPageId} placement={placement} />
                {shouldAddPageTypeCustomization ? (
                    <CustomizationComponent target={computedPageType} placement={placement} />
                ) : null}
            </>
        );
    }

    return (
        <>
            {shouldAddPageTypeCustomization ? (
                <CustomizationComponent target={computedPageType} placement={placement} />
            ) : null}
            <CustomizationComponent target={computedPageId} placement={placement} />
            <CustomizationComponent target={Targets.PAGE} placement={placement} />
        </>
    );
};

export const CustomizationsWidget = (props) => {
    const { id, placement } = props;

    return <CustomizationComponent target={getComponent(id)} placement={placement} />;
};
