import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiChevronRight } from '@lumapps/lumx/icons';
import { AspectRatio, FlexBox, Text, Thumbnail, ThumbnailVariant, Icon, Size, Tooltip } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { SCOPE } from '../../constants';
import { Category as CategoryType } from '../../types';

import './index.scss';

export interface CategoryProps {
    category: CategoryType;
    key: string;
    onSelectCategory: (code: string) => void;
}
/**
 * Component that will display a learning category type inside a catalog
 * @param CategoryProps
 * @returns Category
 */

const CLASSNAME = 'learning-category';

export const Category: React.FC<CategoryProps> = ({ category, onSelectCategory }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox
            onClick={() => onSelectCategory(category.code)}
            className={element('wrapper')}
            {...get({ element: 'list-element', action: 'open-Learning-Category' })}
        >
            <Thumbnail
                image={category.image}
                alt={category.image}
                aspectRatio={AspectRatio.wide}
                variant={ThumbnailVariant.rounded}
                style={{ '--lumx-thumbnail-aspect-ratio': '25 / 9' }}
            />
            <FlexBox className={element('box')}>
                <Tooltip label={category.title}>
                    <Text as="p" truncate={{ lines: 2 }} className="lumx-typography-custom-title5">
                        {category.title || translateKey(GLOBAL.UNTITLED)}
                    </Text>
                </Tooltip>
                <Icon icon={mdiChevronRight} size={Size.s} />
            </FlexBox>
        </FlexBox>
    );
};
