import React from 'react';

import { TrainingInformations } from '@lumapps/learning-trainings/components/TrainingInformations';
import { DisplayTrainingType, Training } from '@lumapps/learning-trainings/types';
import { Theme } from '@lumapps/lumx/react';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockLearningPreview as BlockLearningPreviewProps } from '../../types';

/**
 * This block renders a preview of a training in the personal feed
 * @param BlockLearningPreviewProps
 * @returns BlockLearningPreview
 */
export const BlockLearningPreview: BlockComponent<BlockLearningPreviewProps> = ({ theme = Theme.light, ...props }) => {
    const { id, learningType, thumbnail, title, mainCategoryTitle } = props;
    const TrainingBlockResponse: Training = {
        code: '',
        identifier: id,
        title,
        description: '',
        language: '',
        availableLanguages: [],
        logo: '',
        cover: thumbnail,
        categoriesCodes: [],
        categoriesTitles: [mainCategoryTitle],
        progress: 0,
        nbCompletedActivities: 0,
        isRecommended: false,
        isDemo: false,
        isSandbox: false,
        isLive: false,
        type: learningType,
        accessUntil: new Date(),
    };

    return (
        <TrainingInformations training={TrainingBlockResponse} theme={theme} scope={DisplayTrainingType.recommended} />
    );
};

export default BlockLearningPreview;
