import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiNewspaperVariantOutline } from '@lumapps/lumx/icons';
import { Avatar, Button, ColorPalette, Divider, Emphasis, GenericBlock, Size, Text, Theme } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { feedRoute } from '@lumapps/user-feed/routes';
import { ContainerBlockVariant } from '@lumapps/widget-base/types';

import { SCOPE } from '../../constants';
import { WIDGET_PERSONAL_FEED_PREVIEW } from '../../keys';
import type { BlockPersonalFeedPreview, FeedHeader } from '../../types';

export interface BlockPersonalFeedPreviewHeaderProps {
    user: FeedHeader['user'];
    isFeedButtonDisplayed: FeedHeader['isFeedButtonDisplayed'];
    theme: BlockPersonalFeedPreview['theme'];
    variant: BlockPersonalFeedPreview['variant'];
    contentStyles: BlockPersonalFeedPreview['contentStyles'];
}
const CLASSNAME = 'block-personal-feed-preview-header';

/**
 * @param theme Theme
 * @param user User
 * @param variant ContainerBlockVariant
 * @param isFeedButtonDisplayed boolean
 * @returns BlockPersonalFeedPreviewHeader
 */
export const BlockPersonalFeedPreviewHeader: React.FC<BlockPersonalFeedPreviewHeaderProps> = ({
    theme,
    user,
    isFeedButtonDisplayed,
    variant,
    contentStyles,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(SCOPE);
    const { translateKey, translateAndReplace } = useTranslate();
    const userName = user.firstName ?? '';
    const pictureUrl = user.pictureUrl ?? '';
    const fontColor = theme === Theme.dark ? ColorPalette.light : ColorPalette.dark;

    return (
        <>
            <GenericBlock
                className={block(variant.toLowerCase())}
                orientation="horizontal"
                style={contentStyles}
                vAlign="center"
                wrap
            >
                <GenericBlock.Figure>
                    <Avatar theme={theme} image={pictureUrl} alt="" size={Size.m} />
                </GenericBlock.Figure>
                <GenericBlock.Content vAlign="left">
                    <Text as="span" className={element('welcome-message')} typography="custom-title3" color={fontColor}>
                        {translateAndReplace(GLOBAL.HELLO_USERNAME, { userName })}
                    </Text>
                </GenericBlock.Content>
                {isFeedButtonDisplayed && (
                    <GenericBlock.Actions noShrink>
                        <Button
                            emphasis={Emphasis.low}
                            leftIcon={mdiNewspaperVariantOutline}
                            theme={theme}
                            linkAs={RouterLink}
                            to={feedRoute}
                            {...get({ element: 'button', action: 'header-redirect' })}
                        >
                            {translateKey(WIDGET_PERSONAL_FEED_PREVIEW.ACCESS_FEED)}
                        </Button>
                    </GenericBlock.Actions>
                )}
            </GenericBlock>
            {variant === ContainerBlockVariant.grouped && (
                <Divider
                    theme={theme}
                    className="lumx-spacing-margin-vertical-big"
                    data-testid={`${CLASSNAME}-separator`}
                />
            )}
        </>
    );
};
