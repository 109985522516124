/* istanbul ignore file */
import React from 'react';

import { useInjectTapper } from '@lumapps/customizations';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { useWrongDeploymentWarning } from './hooks/useWrongDeploymentWarning';

const AdvancedDebugInfoModal = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'advanced-debug-info' */
            './components/Modal'
        ),
);

const KEY_DOWNS: string[] = [];
/**
 * Combination of keys that will trigger the modal
 */
const TRIGGERS = ['?', 'Control'];
/**
 * Depending on the order used to press the TRIGGERS, it may happen
 * that one key is another. We have this record in order to correct
 * those cases
 */
const ALTERNATIVES = {
    '/': '?',
    ',': '?',
};

export interface AdvancedDebugInfoProps {
    showModes?: boolean;
    showFeatureFlags?: boolean;
}

export const AdvancedDebugInfo = ({ showModes = false, showFeatureFlags = false }: AdvancedDebugInfoProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    useWrongDeploymentWarning({ currentHost: window.location.host });

    React.useEffect(() => {
        const onKeyDown = (ev: KeyboardEvent) => {
            const { key } = ev;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const alternativeKey = ALTERNATIVES[key];

            KEY_DOWNS.push(alternativeKey || key);

            let showModal = true;

            for (let i = 0; i < TRIGGERS.length; i += 1) {
                const trigger = TRIGGERS[i];

                showModal = showModal && KEY_DOWNS.indexOf(trigger) >= 0;
            }

            if (showModal) {
                setIsModalOpen(showModal);
            }
        };

        const onKeyUp = () => {
            KEY_DOWNS.length = 0;
        };

        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    });

    useInjectTapper({
        showAdvancedDebugInfo: () => {
            setIsModalOpen(true);
        },
    });

    return isModalOpen ? (
        <Suspense isDialog>
            <AdvancedDebugInfoModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                showModes={showModes}
                showFeatureFlags={showFeatureFlags}
            />
        </Suspense>
    ) : null;
};
