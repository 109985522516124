import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import { NavigationSkeleton } from '@lumapps/lumx-navigation/components/NavigationSkeleton';
import { ViewMode } from '@lumapps/lumx-navigation/constants';
import { SkeletonRectangle, Theme, FlexBox } from '@lumapps/lumx/react';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';

export const SpaceHeaderSkeleton: React.FC = () => {
    const isInWebview = useDetectMobileAppWebView();

    if (isInWebview) {
        return null;
    }

    return (
        <FlexBox
            orientation="vertical"
            gap="big"
            as="header"
            className={classnames('space-header', margin('bottom', 'big'))}
        >
            <SkeletonRectangle className="space-header__banner" theme={Theme.light} />
            <NavigationSkeleton theme={Theme.light} viewMode={ViewMode.horizontal} />
        </FlexBox>
    );
};
