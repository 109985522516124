import type { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const domainsManagementRoute: Route = {
    path: '/admin/domains-management',
    legacyId: 'app.admin.domains-management',
    appId: AppId.backOffice,
};

export const domainsManagement = (): Route => domainsManagementRoute;
