import React, { useMemo } from 'react';

import moment from 'moment';

import { classnames } from '@lumapps/classnames';
import { get as getConstants } from '@lumapps/constants';
import { mdiFileAlert, mdiFileCheck } from '@lumapps/lumx/icons';
import { Icon, Button, FlexBox, Orientation, Alignment, Emphasis, Size, Progress, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_MANDATORY_READ } from '../keys';

import './index.scss';

export interface MandatoryReadBlockProps {
    isRead?: boolean;
    isLoading?: boolean;
    confirmedAt?: string;
    onConfirm?: () => void;
    theme?: Theme;
    inputLang?: string;
}

const { isLegacyContext } = getConstants();

const CLASSNAME = 'widget-mandatory-read-block';

export const MandatoryReadBlock: React.FC<MandatoryReadBlockProps> = ({
    isRead,
    isLoading,
    confirmedAt,
    onConfirm,
    theme = Theme.light,
    inputLang,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();

    const icon = useMemo(() => {
        if (isRead) {
            return mdiFileCheck;
        }
        return mdiFileAlert;
    }, [isRead]);

    const readDescription = translateKey(WIDGET_MANDATORY_READ.ACKNOWLEDGED_DESCRIPTION_DEFAULT, inputLang);
    const unreadDescription = translateKey(WIDGET_MANDATORY_READ.UNACKNOWLEDGED_DESCRIPTION_DEFAULT, inputLang);
    const buttonLabel = translateKey(WIDGET_MANDATORY_READ.CONFIRM_LABEL_DEFAULT, inputLang);

    return (
        <FlexBox
            orientation={Orientation.horizontal}
            fillSpace
            hAlign={Alignment.top}
            vAlign={Alignment.left}
            className={classnames(`${CLASSNAME}__wrapper`, 'lumx-spacing-padding-huge', {
                [`${CLASSNAME}__wrapper--is-read`]: isRead,
                'lumx-color-background-dark-L6': !isRead && theme !== Theme.dark,
                'lumx-color-background-light-L6': !isRead && theme === Theme.dark,
                [`${CLASSNAME}__wrapper--is-loading`]: isLoading,
                'lumx-spacing-margin-huge': isLegacyContext,
            })}
        >
            {isLoading && <Progress className={`${CLASSNAME}__loader`} />}
            <FlexBox orientation={Orientation.horizontal} fillSpace hAlign={Alignment.top} vAlign={Alignment.left}>
                <Icon
                    className={classnames(`${CLASSNAME}__icon`, 'lumx-spacing-margin-right-big')}
                    icon={icon}
                    size={Size.xs}
                    theme={theme}
                />
                <div
                    className={classnames(`${CLASSNAME}__text`, {
                        'lumx-color-font-white-N': theme === Theme.dark,
                    })}
                >
                    <p
                        className={classnames(
                            'lumx-typography-subtitle1',
                            `${CLASSNAME}__title`,
                            `${CLASSNAME}__title--theme-${theme}`,
                        )}
                    >
                        {isRead
                            ? translateAndReplace(
                                  WIDGET_MANDATORY_READ.READ_ON_LABEL,
                                  {
                                      READ_DATE: moment(confirmedAt).format(translateKey(GLOBAL.DATE_FORMAT_COMPLETE)),
                                  },
                                  inputLang,
                              )
                            : translateKey(WIDGET_MANDATORY_READ.BLOCK_TITLE, inputLang)}
                    </p>
                    <p className="lumx-typography-body1">{isRead ? readDescription : unreadDescription}</p>
                </div>
            </FlexBox>
            {!isRead && (
                <Button
                    theme={theme}
                    onClick={onConfirm}
                    emphasis={Emphasis.medium}
                    size={Size.m}
                    className={`${CLASSNAME}__button`}
                    disabled={isLoading}
                >
                    {buttonLabel}
                </Button>
            )}
        </FlexBox>
    );
};
