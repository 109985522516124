import React, { useMemo } from 'react';

import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import property from 'lodash/fp/property';

import { FlexBox, Orientation } from '@lumapps/lumx/react';
import { FilterComponentProps } from '@lumapps/widget-base/types';

import { useCommentRelevancyFilter } from '../../../hooks/PostListFilters/useCommentRelevancyFilter';
import { usePostAuthorFilter } from '../../../hooks/PostListFilters/usePostAuthorFilter';
import { usePostCategoriesFilter } from '../../../hooks/PostListFilters/usePostCategoriesFilter';
import { usePostListFilters } from '../../../hooks/PostListFilters/usePostListFilters';
import { PostListFilterType, HasRelevantComment, PostListFilterBlockProps } from '../../../types';
import { PostAuthorFilterBlock } from '../PostAuthorFilterBlock';
import { PostListFiltersResponsive } from '../PostListFiltersResponsive';
import { PostRadioFilterBlock } from '../PostRadioFilterBlock';
import { PostSearchQueryFilterBlock } from '../PostSearchQueryFilterBlock';
import { PostSelectMultipleFilterBlock } from '../PostSelectMultipleFilterBlock';

export const PostListFilters: React.FC<FilterComponentProps<Partial<PostListFilterType>>> = ({
    activeFilters: activeFiltersValues,
    availableFilters: rawAvailableFilters,
    onApply,
    onFilterChange,
    onReset,
    theme,
}) => {
    const {
        availableFilters,
        filtersValues,
        handleApply,
        handleCancel,
        handleReset,
        handleSearch,
        handleValuesChange,
    } = usePostListFilters({ activeFiltersValues, onApply, onFilterChange, onReset, rawAvailableFilters });

    const authorFilterProps = usePostAuthorFilter({ activeFiltersValues, filtersValues, handleValuesChange });
    const categoriesFilterProps = usePostCategoriesFilter({ activeFiltersValues, filtersValues, handleValuesChange });
    const relevancyFilterProps = useCommentRelevancyFilter({ activeFiltersValues, filtersValues, handleValuesChange });

    const isSearchQueryAvailable = useMemo(
        () => Boolean(find(flow(property('type'), isEqual('searchQuery')))(availableFilters)),
        [availableFilters],
    );

    if (isEmpty(availableFilters)) {
        return null;
    }

    // this key is here to force the rerender of the responsive component when needed (values change, loading over)
    const renderKey = JSON.stringify({ activeFiltersValues });

    function hasAtLeastOneChoice<T>(filterProps: Omit<PostListFilterBlockProps<T>, 'theme'>) {
        if (property('isLoading', filterProps)) {
            return true;
        }

        const filterChoicesCount = property(['choices', 'length'], filterProps);

        return Boolean(filterChoicesCount) && filterChoicesCount > 1;
    }

    return (
        <FlexBox orientation={Orientation.horizontal} gap="big" vAlign="center" hAlign="center">
            {isSearchQueryAvailable && (
                <PostSearchQueryFilterBlock
                    theme={theme}
                    onSearchQuery={handleSearch}
                    selectedQuery={property('searchQuery', filtersValues)}
                />
            )}
            <PostListFiltersResponsive
                theme={theme}
                onApply={handleApply}
                onCancel={handleCancel}
                onReset={handleReset}
                key={renderKey}
            >
                {map(({ type }) => {
                    switch (type) {
                        case authorFilterProps.filterId:
                            return (
                                <PostAuthorFilterBlock
                                    theme={theme}
                                    key={authorFilterProps.filterId}
                                    {...authorFilterProps}
                                />
                            );
                        case relevancyFilterProps.filterId:
                            return (
                                <PostRadioFilterBlock<HasRelevantComment>
                                    theme={theme}
                                    key={relevancyFilterProps.filterId}
                                    {...relevancyFilterProps}
                                />
                            );
                        case categoriesFilterProps.filterId:
                            return hasAtLeastOneChoice(categoriesFilterProps) ? (
                                <PostSelectMultipleFilterBlock<string>
                                    theme={theme}
                                    key={categoriesFilterProps.filterId}
                                    {...categoriesFilterProps}
                                />
                            ) : null;
                        default:
                            return null;
                    }
                }, availableFilters)}
            </PostListFiltersResponsive>
        </FlexBox>
    );
};
