import endsWith from 'lodash/endsWith';
import filter from 'lodash/filter';
import defaultTo from 'lodash/fp/defaultTo';
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import startsWith from 'lodash/startsWith';

import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { isUserAllowed, getAuthorizationsList, isInstanceSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import { Tag } from '@lumapps/tags/types';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { CUSTOM_CONTENT_TYPE_PERMISSIONS } from '../permissions';
import { CustomContentType } from '../types';
import { ContentTypesState } from './types';

const isContentTypesAllowed = isUserAllowed();

const contentSelector = (state: BaseStore): ContentTypesState => state.contentTypes;

/** Retrieves custom content types index. */
const getCustomContentTypes = createSelector(contentSelector, (content) => content?.entities);

const getListOfCustomContentTypes = createSelector(contentSelector, (content) =>
    content && content.entities ? Object.values(content?.entities) : [],
);

/** Retrieves the parent custom content types ids */

const getParentCustomContentTypeIds = createSelector(contentSelector, (content) => content?.parentCustomContentTypeIds);

/** Retrieves the parent custom content types index. */
const getParentCustomContentTypes = createSelector(
    [getCustomContentTypes, getParentCustomContentTypeIds],
    (entities, parentCustomContentTypeIds) => {
        if (entities && parentCustomContentTypeIds && !isEmpty(parentCustomContentTypeIds)) {
            return map((id) => entities[id], parentCustomContentTypeIds);
        }
        if (entities && parentCustomContentTypeIds && isEmpty(parentCustomContentTypeIds)) {
            return [];
        }
        return undefined;
    },
);

type TagWithParentsIds = Tag & { contentTypeId: string; instanceId: string };

/** Retrieves custom content types tags. */
const getCustomContentTypeTagsByID = createSelector(
    getCustomContentTypes,
    flow(
        defaultTo({}),
        Object.values<CustomContentType>,
        flatMap((contentType) =>
            map(
                (tag) => ({ ...tag, contentTypeId: contentType.id, instanceId: contentType.instance }),
                contentType.tags,
            ),
        ),
        keyBy<TagWithParentsIds>('uid'),
    ),
);

/** Retrieves custom content types tags, from an id list. */
const getCustomContentTypeByIDs = createSelector(
    [getCustomContentTypes, (_: BaseStore, ids: string[]) => ids],
    (entities, ids) => {
        return pick(entities, ids) as Record<string, CustomContentType>;
    },
);

const getCustomContentTypeById = (id: string) =>
    createSelector([getCustomContentTypes], (entities) => {
        return entities ? entities[id] : undefined;
    });

/** Returns true if we are currently loading the custom content types */
const isCustomContentTypesLoading = createSelector(
    contentSelector,
    (content: ContentTypesState) =>
        content.status === BaseLoadingStatus.loading || content.status === BaseLoadingStatus.loadingMore,
);

/** Returns true if the custom content types state is idle */
const isCustomContentTypesIdle = createSelector(
    contentSelector,
    (content: ContentTypesState) => content.status === BaseLoadingStatus.idle,
);

const getLoadingStatus = createSelector(contentSelector, (content: ContentTypesState) => content.status);

/** Returns true if the user has any access of some kind to view custom content types */
const doesCurrentUserHaveAccessToCustomContentTypes = createSelector(getAuthorizationsList, (auths: string[]) => {
    let hasAccessToCustomContent = false;

    for (let i = 0; i < auths.length && !hasAccessToCustomContent; i += 1) {
        const auth = auths[i];

        hasAccessToCustomContent =
            !startsWith(auth, CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_READ) &&
            startsWith(auth, CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT);
    }

    return hasAccessToCustomContent;
});

const getCurrentCustomContentTypeId = createSelector(contentSelector, (content: ContentTypesState) => {
    const { currentContentTypeId } = content;

    return currentContentTypeId;
});

const getCurrentCustomContentTypeEntities = createSelector(contentSelector, (content: ContentTypesState) => {
    const { entities } = content;

    return entities;
});

/** Returns current custom type */
const getCurrentCustomContentType = createSelector(
    [getCurrentCustomContentTypeId, getCurrentCustomContentTypeEntities],
    (currentContentTypeId, entities = {}): CustomContentType | undefined => {
        return entities[currentContentTypeId];
    },
);

/**
 * Retrieves the list of possible permissions that a user can perform on a specific content type.
 * @param customContentTypeId
 * @returns CUSTOM_CONTENT_TYPE_PERMISSIONS[]
 */
const getPermissionsForCustomContentType = (customContentTypeId: string) =>
    createSelector(getAuthorizationsList, isInstanceSuperAdmin, (permissions = [], isSuperAdmin = false) => {
        if (isSuperAdmin) {
            return [
                CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_ARCHIVE,
                CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_DELETE,
                CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT,
                CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_PUBLISH,
                CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_READ,
            ];
        }

        const authorizationsForCurrentContenType =
            filter(permissions, (permission: string) => endsWith(permission, `_${customContentTypeId}`)) || [];

        const permissionsForCustomContentType: CUSTOM_CONTENT_TYPE_PERMISSIONS[] =
            authorizationsForCurrentContenType.map(
                (auth) => auth.replace(`_${customContentTypeId}`, '') as CUSTOM_CONTENT_TYPE_PERMISSIONS,
            );

        return permissionsForCustomContentType;
    });

const getPermissionsForCustomContentTypesCreator = (customContentTypePermissions: string[]) =>
    createSelector(
        getAuthorizationsList,
        getParentCustomContentTypes,
        isInstanceSuperAdmin,
        doesCurrentUserHaveAccessToCustomContentTypes,
        (permissions = [], parentCustomContentTypes = [], isSuperAdmin = false, isAllowed = false) => {
            /**
             * if the user is superAdmin or is not allowed, we return immediately since, for super admins
             * there is no need to check validate this since they will have access. And if the user is not allowed to any cct,
             * there is no need to check which custom content types they can see.
             */
            if (isSuperAdmin || !isAllowed) {
                return {};
            }
            /**
             * The idea here is to go through the different custom content types and check whether
             * the current user has access to them or not. We need to retrieve the authorizations
             * provided by the user's roles, and check against the custom content types that we have, validating
             * whether the user has access or not.
             */
            const permissionsPerCustomContentType: Record<string, boolean> = {};
            parentCustomContentTypes.forEach((parentCustomContentType) => {
                const { id } = parentCustomContentType;
                /**
                 * In order to make this as efficient as possible, we create a regexp that looks like this:
                 * (CUSTOM_CONTENT_EDIT|CUSTOM_CONTENT_CREATE)_<id>. with that, we are able to determine whether
                 * these types can be accessed by the current user or not.
                 */
                const regexp = `(${customContentTypePermissions.join('|')})_${id}`;
                const matcher = new RegExp(regexp);

                const authorizationsForCurrentContenType =
                    filter(permissions, (permission: string) => matcher.exec(permission) !== null) || [];

                permissionsPerCustomContentType[id] = authorizationsForCurrentContenType.length > 0;
            });

            return permissionsPerCustomContentType;
        },
    );

const getCustomContentTypesCacheKey = createSelector(
    customerIdSelector,
    currentLanguageSelector,
    (customerId, currentLanguage) => `custom-content-types-${customerId}-${currentLanguage}`,
);

/**
 * Returns an object where the keys are the ids of the custom content types
 * and the values represent whether the current user has the necessary permissions
 * to access that custom content.
 */
const getPermissionsForCustomContentTypes = getPermissionsForCustomContentTypesCreator([
    CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT,
    CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_DELETE,
    CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_ARCHIVE,
]);

/**
 * Returns an object where the keys are the ids of the custom content types
 * and the values represent whether the current user has the necessary permissions
 * to edit that custom content.
 */
const getEditPermissionForCustomContentTypes = getPermissionsForCustomContentTypesCreator([
    CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT,
]);

const canUserAccessCustomContentType = (
    id: string,
    isSuperAdmin: boolean,
    isAllowed: boolean,
    allowedCustomContentTypes: Record<string, boolean>,
) => {
    if (isSuperAdmin) {
        return true;
    }

    if (!isAllowed) {
        return false;
    }

    return allowedCustomContentTypes[id];
};

export {
    isContentTypesAllowed,
    getCustomContentTypeTagsByID,
    getCustomContentTypeById,
    getCustomContentTypes,
    getParentCustomContentTypeIds,
    getParentCustomContentTypes,
    getCurrentCustomContentType,
    getCurrentCustomContentTypeId,
    isCustomContentTypesLoading,
    isCustomContentTypesIdle,
    getPermissionsForCustomContentTypes,
    getPermissionsForCustomContentType,
    doesCurrentUserHaveAccessToCustomContentTypes,
    getEditPermissionForCustomContentTypes,
    canUserAccessCustomContentType,
    getListOfCustomContentTypes,
    getLoadingStatus,
    getCustomContentTypesCacheKey,
    getCustomContentTypeByIDs,
};
