import React from 'react';

import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { mdiPlay } from '@lumapps/lumx/icons';
import { AspectRatio, ColorPalette, Icon, Thumbnail as LumxThumbnail } from '@lumapps/lumx/react';
import { getPlayVideoRouteById } from '@lumapps/play-video/routes';
import FallbackImage from '@lumapps/play/assets/images/fallback.svg';
import { PLAY } from '@lumapps/play/keys';
import { useSelector } from '@lumapps/redux/react';
import { useDimensions } from '@lumapps/responsive';
import { Link } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { usePlayerClassnames } from '../../hooks';
import { PLAYER } from '../../keys';
import { usePlayerContext } from '../Player';

import './Thumbnail.scss';

const BASE_THUMBNAIL_URL = '/_ah/api/lumsites/v2/organizations';
const THUMBNAIL_ENDPOINT = 'thumbnail.jpg';
const PLAY_ICON_SIZES = { m: 0, l: 256, xl: 640 };

interface ThumbnailProps {
    /** Whether to render the thumbnail as a link or not. */
    asLink?: boolean;
}

export function Thumbnail({ asLink }: ThumbnailProps) {
    const customerId = useSelector(customerIdSelector);
    const { translateKey } = useTranslate();
    const { element } = usePlayerClassnames();

    const { ref, currentBreakpoint: playIconSize } = useDimensions({ breakpoints: PLAY_ICON_SIZES });

    const {
        state: { api, video: fetchedVideo, videoId },
        dispatch,
    } = usePlayerContext();

    const video = fetchedVideo;

    const thumbnail =
        api === 'monolite'
            ? `${BASE_THUMBNAIL_URL}/${customerId}/videos/${videoId}/${THUMBNAIL_ENDPOINT}`
            : video?.thumbnail?.url;

    if (!thumbnail) {
        return null;
    }

    return (
        <div className={element('thumbnail')} ref={ref}>
            <LumxThumbnail
                alt={translateKey(PLAYER.WATCH_VIDEO)}
                aspectRatio={AspectRatio.original}
                className={element('thumbnail__image')}
                fallback={
                    <img
                        alt={translateKey(PLAY.PLAY_VIDEO)}
                        className={element('thumbnail__fallback')}
                        src={FallbackImage}
                    />
                }
                image={thumbnail}
                linkAs={asLink ? Link : undefined}
                to={asLink ? getPlayVideoRouteById(videoId) : undefined}
                onClick={() => !asLink && dispatch({ type: 'HIDE_FACADE' })}
            />

            {Boolean(playIconSize) && (
                <Icon
                    alt=""
                    className={element('thumbnail__play-icon')}
                    color={ColorPalette.light}
                    icon={mdiPlay}
                    size={playIconSize}
                />
            )}
        </div>
    );
}
