import { DITA } from '../types';
import { isParagraph, isText } from './test';

export const isDitaContentEmpty = (dita: DITA) => {
    const ditaTopic = dita.children.find((n) => n.name === 'topic');

    if (ditaTopic) {
        return false;
    }

    const ditaBody = dita.children.find((n) => n.name === 'body');

    if (!ditaBody) {
        return true;
    }

    if (ditaBody.children.length > 1) {
        // If we have more than one child then the body is not empty
        return false;
    }

    if (ditaBody.children.length === 0) {
        // If the body has no children then the body is empty
        return true;
    }

    if (!isParagraph(ditaBody.children[0])) {
        // If the first child is not a paragraph then the body is not empty
        return false;
    }

    if (ditaBody.children[0].children.length > 1) {
        // If the first child (which is a paragraph here) has more than one child then the body is not empty
        return false;
    }

    if (ditaBody.children[0].children.length === 0) {
        // If the first child of the paragraph has no children then the body is empty
        return true;
    }

    if (!isText(ditaBody.children[0].children[0])) {
        // If the first child of this paragraph is not a text then the body is not empty
        return false;
    }

    if (ditaBody.children[0].children[0].value !== '') {
        // If this is a text and its value is not an empty string, then the body is not empty
        return false;
    }

    return true;
};
