(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetContentController(ConfigTheme, Translation, Style, Utils) {
        'ngInject';

        var widgetContent = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetContent.ConfigTheme = ConfigTheme;
        widgetContent.Translation = Translation;
        widgetContent.Utils = Utils;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get widget content style according to widget properties.
         *
         * @return {Object} The widget content style.
         */
        function getWidgetContentStyle() {
            // We don't need the widget content styles if the widget is displayed as NGI. Styles will be handled by NGI.
            return widgetContent.shouldDisplayAsNGI() ? {} : Style.getWidgetContentStyle(_.get(widgetContent.parentCtrl.widget, 'properties', {}));
        }

        /////////////////////////////

        widgetContent.getWidgetContentStyle = getWidgetContentStyle;

        /////////////////////////////

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            widgetContent.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function WidgetContentDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            // Get the shouldDisplayAsNGI function from the widget specific directive.
            // If not defined, the function always return false.
            ctrls[0].shouldDisplayAsNGI = scope.vm.shouldDisplayAsNGI || (() => false);
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetContentController,
            controllerAs: 'widgetContent',
            link: link,
            replace: true,
            require: ['widgetContent', '^widget'],
            restrict: 'E',
            templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-content.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('widgetContent', WidgetContentDirective);
})();
