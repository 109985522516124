/**
 * ProgressBar component set maxwidth default to 200px, wich is low for display 3 elements, rewrite it to 600px
 */
export const PROGRESS_BAR_MAX_WIDTH = '1400px';
/**
 * Max columns of the TrainingList component
 */
export const MAX_COLUMNS_TRAINING_LIST = 3;
/**
 * Min width of the TrainingList items
 */
export const MIN_WIDTH_TRAINING_LIST_ITEMS = 256;
/**
 * Min of elements to display in training list
 */

export const MAX_TRAINING_ENTRIES_PER_QUERY = 6;

export const SCOPE = 'training';
