import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Group } from '@lumapps/groups/types';
import { mdiClose } from '@lumapps/lumx/icons';
import { Chip, Icon, Size, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { GroupPickerField, GroupPickerFieldProps } from '../GroupPickerField';

export interface GroupChipPickerFieldProps extends Omit<GroupPickerFieldProps, 'onGroupSelected' | 'defaultValues'> {
    selectedGroups?: Group[];
    onGroupsSelected: (grps: Group[]) => void;
    scope?: string;
}

/**
 * Component that displays a picker for groups displayed in an autocomplete fashion. This component
 * manages the display of selected groups as chips
 * @family Pickers
 * @param GroupChipPickerFieldProps
 * @returns GroupChipPickerField
 */
export const GroupChipPickerField: React.FC<GroupChipPickerFieldProps> = ({
    selectedGroups = [],
    onGroupsSelected,
    scope = 'group-chip-picker',
    defaultValuesIds,
    ...otherGroupPickerProps
}) => {
    const { get } = useDataAttributes(scope);
    const { translateKey } = useTranslate();

    const handleGroupSelected = (group: Group) => {
        const selectedGroupsIds = selectedGroups.map((grp) => grp.id);
        if (!selectedGroupsIds.includes(group.id)) {
            const newSelection = [...selectedGroups, group];
            onGroupsSelected(newSelection);
        } else {
            // If the item is already picked, we should unpick it
            const newSelection = [...selectedGroups];
            const index = newSelection.findIndex((item) => item.id === group.id);
            newSelection.splice(index, 1);
            onGroupsSelected(newSelection);
        }
    };

    const onClearSelectedGroup = (group: Group) => {
        const filteredGroups = group ? selectedGroups.filter((grp) => grp.id !== group.id) : [];
        onGroupsSelected(filteredGroups);
    };

    const selectedChipRender = (group: Group) => {
        const onClick = () => onClearSelectedGroup(group);
        // Fix to translate ALL and PUBLIC groups
        const name = translateKey(group.name);
        return (
            <Chip
                key={group.id}
                after={<Icon icon={mdiClose} size={Size.xxs} />}
                size={Size.s}
                onAfterClick={onClick}
                onClick={onClick}
                {...get({ element: 'button', action: 'remove-group' })}
            >
                <Text as="span" title={name}>
                    {name}
                </Text>
            </Chip>
        );
    };

    const onDefaultGroupsFetched = (groups: Group[]) => {
        onGroupsSelected(groups);
    };

    return (
        <GroupPickerField
            {...otherGroupPickerProps}
            textFieldProps={{
                ...get({ element: 'group-picker' }),
                ...otherGroupPickerProps.textFieldProps,
            }}
            isMultiple
            onGroupSelected={handleGroupSelected}
            values={selectedGroups}
            selectedChipRender={selectedChipRender}
            defaultValuesIds={defaultValuesIds}
            onDefaultGroupsFetch={onDefaultGroupsFetched}
        />
    );
};
