export const formatName = (
    label: string,
    firstActiveValueName: string | undefined,
    activeValuesCount: number,
): string => {
    if (firstActiveValueName && activeValuesCount > 1) {
        return `${firstActiveValueName} +${activeValuesCount - 1}`;
    }
    if (firstActiveValueName && activeValuesCount === 1) {
        return firstActiveValueName;
    }

    return label;
};
