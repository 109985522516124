// eslint-disable-next-line id-blacklist
import { error } from 'components/services/notification';
import { translate as t } from 'components/translations';

import {
    fetchChannelsForUserSpaceTask,
    fetchJoinedUserSpacesTask,
    fetchMessagesForChannelTask,
    fetchUserSpacesTask,
    getChannelByIdTask,
    getChannelByNameTask,
    getUserSpaceByIdTask,
    hasUserJoinedChannelTask,
    createMessageForChannelTask,
    fetchUserPhotoTask,
    fetchProvidersTask,
} from './chat_tasks';

const FETCH_GROUPS_ERROR = 'chat/FETCH_GROUPS_ERROR';
const FETCH_GROUPS_LOADING = 'chat/FETCH_GROUPS_LOADING';
const FETCH_GROUPS_SUCCESS = 'chat/FETCH_GROUPS_SUCCESS';

const FETCH_JOINED_GROUPS_ERROR = 'chat/FETCH_JOINED_GROUPS_ERROR';
const FETCH_JOINED_GROUPS_LOADING = 'chat/FETCH_JOINED_GROUPS_LOADING';
const FETCH_JOINED_GROUPS_SUCCESS = 'chat/FETCH_JOINED_GROUPS_SUCCESS';

const FETCH_CHANNELS_FOR_GROUP_ERROR = 'chat/FETCH_CHANNELS_FOR_GROUP_ERROR';
const FETCH_CHANNELS_FOR_GROUP_LOADING = 'chat/FETCH_CHANNELS_FOR_GROUP_LOADING';
const FETCH_CHANNELS_FOR_GROUP_SUCCESS = 'chat/FETCH_CHANNELS_FOR_GROUP_SUCCESS';

const FETCH_MESSAGES_FOR_CHANNEL_ERROR = 'chat/FETCH_MESSAGES_FOR_CHANNEL_ERROR';
const FETCH_MESSAGES_FOR_CHANNEL_LOADING = 'chat/FETCH_MESSAGES_FOR_CHANNEL_LOADING';
const FETCH_MESSAGES_FOR_CHANNEL_SUCCESS = 'chat/FETCH_MESSAGES_FOR_CHANNEL_SUCCESS';

const CREATE_MESSAGE_FOR_CHANNEL_ERROR = 'chat/CREATE_MESSAGE_FOR_CHANNEL_ERROR';
const CREATE_MESSAGE_FOR_CHANNEL_LOADING = 'chat/CREATE_MESSAGE_FOR_CHANNEL_LOADING';
const CREATE_MESSAGE_FOR_CHANNEL_SUCCESS = 'chat/CREATE_MESSAGE_FOR_CHANNEL_SUCCESS';

const FETCH_USER_PHOTO_ERROR = 'chat/FETCH_USER_PHOTO_ERROR';
const FETCH_USER_PHOTO_LOADING = 'chat/FETCH_USER_PHOTO_LOADING';
const FETCH_USER_PHOTO_SUCCESS = 'chat/FETCH_USER_PHOTO_SUCCESS';

const FETCH_PROVIDERS_ERROR = 'chat/FETCH_PROVIDERS_ERROR';
const FETCH_PROVIDERS_LOADING = 'chat/FETCH_PROVIDERS_LOADING';
const FETCH_PROVIDERS_SUCCESS = 'chat/FETCH_PROVIDERS_SUCCESS';

const HAS_USER_JOINED_CHANNEL_ERROR = 'chat/HAS_USER_JOINED_CHANNEL_ERROR';
const HAS_USER_JOINED_CHANNEL_LOADING = 'chat/HAS_USER_JOINED_CHANNEL_LOADING';
const HAS_USER_JOINED_CHANNEL_SUCCESS = 'chat/HAS_USER_JOINED_CHANNEL_SUCCESS';

const GET_CHANNEL_BY_ID_ERROR = 'chat/GET_CHANNEL_BY_ID_ERROR';
const GET_CHANNEL_BY_ID_LOADING = 'chat/GET_CHANNEL_BY_ID_LOADING';
const GET_CHANNEL_BY_ID_SUCCESS = 'chat/GET_CHANNEL_BY_ID_SUCCESS';

const GET_CHANNEL_BY_NAME_ERROR = 'chat/GET_CHANNEL_BY_NAME_ERROR';
const GET_CHANNEL_BY_NAME_LOADING = 'chat/GET_CHANNEL_BY_NAME_LOADING';
const GET_CHANNEL_BY_NAME_SUCCESS = 'chat/GET_CHANNEL_BY_NAME_SUCCESS';

const GET_GROUP_BY_ID_ERROR = 'chat/GET_GROUPS_BY_ID_ERROR';
const GET_GROUP_BY_ID_LOADING = 'chat/GET_GROUPS_BY_ID_LOADING';
const GET_GROUP_BY_ID_SUCCESS = 'chat/GET_GROUPS_BY_ID_SUCCESS';

const SET_LOADING = 'chat/SET_LOADING';

const SET_PAGINATION_LOADING = 'chat/SET_PAGINATION_LOADING';

const SET_SIZE = 'chat/SET_SIZE';

const SET_OPEN = 'chat/SET_OPEN';

const SET_SELECTED_THREAD = 'chat/SET_SELECTED_THREAD';

const SET_MODEL = 'chat/SET_MODEL';

const SET_SHOW = 'chat/SET_SHOW';

const SET_LAST_CLOSED = 'chat/SET_LAST_CLOSED';

const SET_CREATE_MESSAGE_LOADING = 'chat/SET_CREATE_MESSAGE_LOADING';

const SET_NOTIFICATION_COUNT = 'chat/SET_NOTIFICATION_COUNT';

const SET_EMOJI_OPEN = 'chat/SET_EMOJI_OPEN';

const SET_CHANNEL_URL = 'chat/SET_CHANNEL_URL';

const SET_PHOTO_REQUESTED = 'chat/SET_PHOTO_REQUESTED';

/**
 * Fetch the list of available user spaces for a provider.
 *
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of user spaces depending on the provider.
 */
const fetchUserSpaces = ({ cursor, maxResults, page, provider }) => (dispatch) => {
    dispatch({
        meta: {
            provider,
        },
        type: FETCH_GROUPS_LOADING,
    });

    return fetchUserSpacesTask({ cursor, maxResults, page, provider })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    provider,
                },
                payload,
                type: FETCH_GROUPS_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    provider,
                },
                payload: exception,
                type: FETCH_GROUPS_ERROR,
            });
            error(t('FRONT.FETCH_GROUPS_ERROR'));
        });
};

/**
 * Fetch the list of joined user spaces for a provider.
 *
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of joined user spaces depending on the provider.
 */
const fetchJoinedUserSpaces = ({ cursor, maxResults, page, provider }) => (dispatch) => {
    dispatch({
        meta: {
            provider,
        },
        type: FETCH_JOINED_GROUPS_LOADING,
    });

    return fetchJoinedUserSpacesTask({ cursor, maxResults, page, provider })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    provider,
                },
                payload,
                type: FETCH_JOINED_GROUPS_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    provider,
                },
                payload: exception,
                type: FETCH_JOINED_GROUPS_ERROR,
            });
            error(t('FRONT.FETCH_JOINED_GROUPS_ERROR'));
        });
};

/**
 * Get a specific userSpace using its id.
 *
 * @param  {string}  provider  Provider (teams, yammer, slack...).
 * @param  {string}  spaceId   User space id.
 * @return {Promise} Returns the corresponding userSpace.
 */
const getUserSpaceById = ({ provider, spaceId }) => (dispatch) => {
    dispatch({
        meta: {
            provider,
            spaceId,
        },
        payload: {
            isLoading: true,
        },
        type: GET_GROUP_BY_ID_LOADING,
    });

    return getUserSpaceByIdTask({ provider, spaceId })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    provider,
                    spaceId,
                },
                payload,
                type: GET_GROUP_BY_ID_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    provider,
                    spaceId,
                },
                payload: exception,
                type: GET_GROUP_BY_ID_ERROR,
            });
            error(t('FRONT.GET_GROUP_BY_ID_ERROR'));
        })
        .finally(() => {
            dispatch({
                meta: {
                    provider,
                    spaceId,
                },
                payload: {
                    isLoading: false,
                },
                type: GET_GROUP_BY_ID_LOADING,
            });
        });
};

/**
 * Fetch the list of available channels for a user space.
 *
 * @param  {string}  [callId]     Unique identifier of the api call.
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {string}  spaceId      User space id.
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of channels depending on the user space.
 */
const fetchChannelsForUserSpace = ({ callId, cursor, maxResults, page, provider, spaceId }) => (dispatch) => {
    dispatch({
        meta: {
            provider,
            spaceId,
        },
        type: FETCH_CHANNELS_FOR_GROUP_LOADING,
    });

    return fetchChannelsForUserSpaceTask({ callId, cursor, maxResults, page, provider, spaceId })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    provider,
                    spaceId,
                },
                payload,
                type: FETCH_CHANNELS_FOR_GROUP_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    provider,
                    spaceId,
                },
                payload: exception,
                type: FETCH_CHANNELS_FOR_GROUP_ERROR,
            });
            error(t('FRONT.FETCH_CHANNELS_FOR_GROUP_ERROR'));
        });
};

/**
 * Get a specific channel using its id.
 *
 * @param  {string}  provider  Provider (teams, yammer, slack...).
 * @param  {string}  spaceId   User space id.
 * @param  {string}  channelId User space id.
 * @return {Promise} Returns the corresponding channel.
 */
const getChannelById = ({ provider, spaceId, channelId }) => (dispatch) => {
    dispatch({
        meta: {
            channelId,
            provider,
            spaceId,
        },
        type: GET_CHANNEL_BY_ID_LOADING,
    });

    return getChannelByIdTask({ provider, spaceId, channelId })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    channelId,
                    provider,
                    spaceId,
                },
                payload,
                type: GET_CHANNEL_BY_ID_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    channelId,
                    provider,
                    spaceId,
                },
                payload: exception,
                type: GET_CHANNEL_BY_ID_ERROR,
            });
        });
};

/**
 * Get a specific channel using its name.
 *
 * @param  {string}  provider    Provider (teams, yammer, slack...).
 * @param  {string}  spaceId     User space id.
 * @param  {string}  channelName channel name to find.
 * @return {Promise} Returns the corresponding channel.
 */
const getChannelByName = ({ provider, spaceId, channelName }) => async () => {
    return getChannelByNameTask({ provider, spaceId, channelName }).then(({ payload } = {}) => {
        return payload;
    });
};

/**
 * Get a specific channel using its id.
 *
 * @param  {string}  provider  Provider (teams, yammer, slack...).
 * @param  {string}  spaceId   User space id.
 * @param  {string}  channelId User space id.
 * @param  {string}  userId    User id.
 * @return {Promise} Returns the corresponding channel.
 */
const hasUserJoinedChannel = ({ provider, spaceId, channelId }) => (dispatch) => {
    dispatch({
        meta: {
            channelId,
            provider,
            spaceId,
        },
        type: HAS_USER_JOINED_CHANNEL_LOADING,
    });

    return hasUserJoinedChannelTask({ provider, spaceId, channelId })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    channelId,
                    provider,
                    spaceId,
                },
                payload,
                type: HAS_USER_JOINED_CHANNEL_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    channelId,
                    provider,
                    spaceId,
                },
                payload: exception,
                type: HAS_USER_JOINED_CHANNEL_ERROR,
            });
            error(t('FRONT.HAS_USER_JOINED_CHANNEL_ERROR'));
        });
};

/**
 * Fetch the list of messages for a channel.
 *
 * @param  {string}  [callId]        Unique identifier of the api call.
 * @param  {string}  provider        Provider (teams, yammer, slack...).
 * @param  {string}  spaceId         User space id.
 * @param  {string}  channelId       Channel id.
 * @param  {string}  [chatMessageId] Message id (fetch the replies).
 * @param  {number}  [maxResults]    Page max results.
 * @param  {string}  [cursor]        Page cursor.
 * @param  {number}  [page]          Page number (used for manual pagination)
 * @return {Promise} Returns the list of messages depending on the channel.
 */
const fetchMessagesForChannel = ({
    callId,
    channelId,
    chatMessageId,
    cursor,
    fields,
    maxResults,
    page,
    provider,
    spaceId,
    organizationId,
    isSlackDefaultAppEnabled,
    loadMore = false,
}) => (dispatch) => {
    dispatch({
        meta: {
            channelId,
            chatMessageId,
            page,
            provider,
            spaceId,
        },
        type: FETCH_MESSAGES_FOR_CHANNEL_LOADING,
    });

    return fetchMessagesForChannelTask({
        callId,
        channelId,
        chatMessageId,
        cursor,
        fields,
        maxResults,
        page,
        provider,
        spaceId,
        organizationId,
        isSlackDefaultAppEnabled,
    })
        .then(({ payload } = {}) => {
            payload.items = payload.items || [];

            dispatch({
                meta: {
                    channelId,
                    chatMessageId,
                    page,
                    provider,
                    spaceId,
                    loadMore,
                },
                payload,
                type: FETCH_MESSAGES_FOR_CHANNEL_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    channelId,
                    chatMessageId,
                    page,
                    provider,
                    spaceId,
                },
                payload: exception,
                type: FETCH_MESSAGES_FOR_CHANNEL_ERROR,
            });
        });
};

/**
 * Post a new message to a channel.
 *
 * @param  {string}  [callId]        Unique identifier of the api call.
 * @param  {string}  provider        Provider (teams, yammer, slack...).
 * @param  {string}  spaceId         User space id.
 * @param  {string}  channelId       Channel id.
 * @param  {string}  [chatMessageId] Message id (fetch the replies).
 * @param  {string}  body            Content of the message.
 * @param  {string}  bodyContentType Content type of the message (html, text...).
 * @return {Promise} Returns the new message posted.
 */
const createMessageForChannel = ({ body, bodyContentType, callId, channelId, chatMessageId, provider, spaceId }) => (
    dispatch,
) => {
    dispatch({
        meta: {
            channelId,
            chatMessageId,
            provider,
            spaceId,
        },
        type: CREATE_MESSAGE_FOR_CHANNEL_LOADING,
    });

    return createMessageForChannelTask({ body, bodyContentType, callId, channelId, chatMessageId, provider, spaceId })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    channelId,
                    chatMessageId,
                    provider,
                    spaceId,
                },
                payload,
                type: CREATE_MESSAGE_FOR_CHANNEL_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    channelId,
                    chatMessageId,
                    provider,
                    spaceId,
                },
                payload: exception,
                type: CREATE_MESSAGE_FOR_CHANNEL_ERROR,
            });
            error(t('FRONT.CHAT.POST_MESSAGE_FOR_CHANNEL_ERROR'));
        });
};

/**
 * Fetch the profile picture of a user (from provider user id).
 *
 * @param  {string}  provider Provider (teams, yammer, slack...).
 * @param  {string}  userId   Provider user id.
 * @return {Promise} Returns the user profile picture.
 */
const fetchUserPhoto = ({ provider, userId }) => (dispatch) => {
    dispatch({
        meta: {
            provider,
            userId,
        },
        type: FETCH_USER_PHOTO_LOADING,
    });

    return fetchUserPhotoTask({ provider, userId })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    provider,
                    userId,
                },
                payload,
                type: FETCH_USER_PHOTO_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    provider,
                    userId,
                },
                payload: exception,
                type: FETCH_USER_PHOTO_ERROR,
            });
            error(t('FRONT.CHAT.FETCH_USER_PICTURE_ERROR'));
        });
};

/**
 * Fetch the list of available chat providers.
 *
 * @return {Promise} Returns the list of available chat providers.
 */
const fetchProviders = () => (dispatch) => {
    dispatch({
        type: FETCH_PROVIDERS_LOADING,
    });

    return fetchProvidersTask()
        .then(({ payload } = {}) => {
            dispatch({
                payload,
                type: FETCH_PROVIDERS_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: FETCH_PROVIDERS_ERROR,
            });
        });
};

/**
 * Set chat loading.
 *
 * @param  {boolean}  isLoading Is chat loading.
 * @param  {string}   uuid      Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setLoading = ({ uuid, isLoading }) => (dispatch) => {
    dispatch({
        payload: {
            isLoading,
            uuid,
        },
        type: SET_LOADING,
    });
};

/**
 * Set pagination loading.
 *
 * @param  {boolean}  isPaginationLoading Chat size.
 * @param  {string}   uuid                Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setPaginationLoading = ({ uuid, isPaginationLoading }) => (dispatch) => {
    dispatch({
        payload: {
            isPaginationLoading,
            uuid,
        },
        type: SET_PAGINATION_LOADING,
    });
};

/**
 * Set chat size.
 *
 * @param  {string}   size Chat size.
 * @param  {string}   uuid Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setSize = ({ size, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            size,
            uuid,
        },
        type: SET_SIZE,
    });
};

/**
 * Set chat open.
 *
 * @param  {boolean}  isOpen Is chat open.
 * @param  {string}   uuid   Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setOpen = ({ isOpen, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            isOpen,
            uuid,
        },
        type: SET_OPEN,
    });
};

/**
 * Select thread.
 *
 * @param  {string}   selectedThread Thread selected.
 * @param  {string}   uuid           Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setSelectedThread = ({ uuid, selectedThread }) => (dispatch) => {
    dispatch({
        payload: {
            selectedThread,
            uuid,
        },
        type: SET_SELECTED_THREAD,
    });
};

/**
 * Set editor model.
 *
 * @param  {string}   model Text editor content.
 * @param  {string}   uuid  Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setModel = ({ model, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            model,
            uuid,
        },
        type: SET_MODEL,
    });
};

/**
 * Show chat if configured properly.
 *
 * @param  {boolean}  show Whether to show the chat or not.
 * @param  {string}   uuid Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setShow = ({ isShown, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            isShown,
            uuid,
        },
        type: SET_SHOW,
    });
};

/**
 * Set chat last closed date.
 *
 * @param  {Date}     lastClosed Chat last closed date.
 * @param  {string}   uuid       Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setLastClosed = ({ lastClosed, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            lastClosed,
            uuid,
        },
        type: SET_LAST_CLOSED,
    });
};

/**
 * Set create message loading.
 *
 * @param  {boolean}  isCreateMessageLoading Whether the chat is loading while creating a message.
 * @param  {string}   uuid                   Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setCreateMessageLoading = ({ isCreateMessageLoading, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            isCreateMessageLoading,
            uuid,
        },
        type: SET_CREATE_MESSAGE_LOADING,
    });
};

/**
 * Set chat notification count.
 *
 * @param  {number}   notificationCount Number of new message notification.
 * @param  {string}   uuid              Unique identifier of the component.
 * @return {Function} The actions creator's thunk.
 */
const setNotificationCount = ({ notificationCount, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            notificationCount,
            uuid,
        },
        type: SET_NOTIFICATION_COUNT,
    });
};

/**
 * Set emoji editor in open/close state.
 *
 * @param  {boolean}  isEmojiEditorOpened Boolean to open Emoji Editor.
 * @param  {string}   uuid                Component id.
 * @return {Function} The actions creator's thunk.
 */
const setEmojiEditorOpened = ({ isEmojiEditorOpened, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            isEmojiEditorOpened,
            uuid,
        },
        type: SET_EMOJI_OPEN,
    });
};

/**
 * Set channelUrl.
 *
 * @param  {string}   channelUrl Channel url.
 * @param  {string}   uuid       Component id.
 * @return {Function} The actions creator's thunk.
 */
const setChannelUrl = ({ channelUrl, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            channelUrl,
            uuid,
        },
        type: SET_CHANNEL_URL,
    });
};

/**
 * Set photosRequested array.
 *
 * @param  {Array}    photosRequested Array of photos requested.
 * @param  {string}   uuid            Component id.
 * @return {Function} The actions creator's thunk.
 */
const setPhotosRequested = ({ photosRequested, uuid }) => (dispatch) => {
    dispatch({
        payload: {
            photosRequested,
            uuid,
        },
        type: SET_PHOTO_REQUESTED,
    });
};

export {
    FETCH_GROUPS_ERROR,
    FETCH_GROUPS_LOADING,
    FETCH_GROUPS_SUCCESS,
    FETCH_JOINED_GROUPS_ERROR,
    FETCH_JOINED_GROUPS_LOADING,
    FETCH_JOINED_GROUPS_SUCCESS,
    FETCH_CHANNELS_FOR_GROUP_ERROR,
    FETCH_CHANNELS_FOR_GROUP_LOADING,
    FETCH_CHANNELS_FOR_GROUP_SUCCESS,
    FETCH_MESSAGES_FOR_CHANNEL_ERROR,
    FETCH_MESSAGES_FOR_CHANNEL_LOADING,
    FETCH_MESSAGES_FOR_CHANNEL_SUCCESS,
    CREATE_MESSAGE_FOR_CHANNEL_ERROR,
    CREATE_MESSAGE_FOR_CHANNEL_LOADING,
    CREATE_MESSAGE_FOR_CHANNEL_SUCCESS,
    FETCH_USER_PHOTO_ERROR,
    FETCH_USER_PHOTO_LOADING,
    FETCH_USER_PHOTO_SUCCESS,
    FETCH_PROVIDERS_ERROR,
    FETCH_PROVIDERS_LOADING,
    FETCH_PROVIDERS_SUCCESS,
    GET_CHANNEL_BY_NAME_ERROR,
    GET_CHANNEL_BY_NAME_LOADING,
    GET_CHANNEL_BY_NAME_SUCCESS,
    GET_CHANNEL_BY_ID_ERROR,
    GET_CHANNEL_BY_ID_LOADING,
    GET_CHANNEL_BY_ID_SUCCESS,
    GET_GROUP_BY_ID_ERROR,
    GET_GROUP_BY_ID_LOADING,
    GET_GROUP_BY_ID_SUCCESS,
    HAS_USER_JOINED_CHANNEL_ERROR,
    HAS_USER_JOINED_CHANNEL_LOADING,
    HAS_USER_JOINED_CHANNEL_SUCCESS,
    SET_LOADING,
    SET_PAGINATION_LOADING,
    SET_EMOJI_OPEN,
    SET_SIZE,
    SET_OPEN,
    SET_SELECTED_THREAD,
    SET_MODEL,
    SET_SHOW,
    SET_LAST_CLOSED,
    SET_CREATE_MESSAGE_LOADING,
    SET_NOTIFICATION_COUNT,
    SET_CHANNEL_URL,
    SET_PHOTO_REQUESTED,
    fetchUserSpaces,
    fetchJoinedUserSpaces,
    fetchChannelsForUserSpace,
    fetchMessagesForChannel,
    getChannelById,
    getChannelByName,
    getUserSpaceById,
    hasUserJoinedChannel,
    createMessageForChannel,
    fetchUserPhoto,
    fetchProviders,
    setLoading,
    setPaginationLoading,
    setSize,
    setOpen,
    setSelectedThread,
    setModel,
    setShow,
    setLastClosed,
    setCreateMessageLoading,
    setNotificationCount,
    setEmojiEditorOpened,
    setChannelUrl,
    setPhotosRequested,
};
