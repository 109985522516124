import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const adminMobileNavigationRoutes: Route = {
    path: '/admin/mobile/navigation',
    appId: AppId.backOffice,
    legacyId: 'app.admin.mobile-navigation',
};

export const adminMobileNavigation = () => adminMobileNavigationRoutes;
