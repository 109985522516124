import { useMemo } from 'react';

function getStickyHeaders() {
    return document.querySelectorAll('header .sticky');
}

export function getFixedHeaderHeight() {
    const headers = getStickyHeaders();
    if (!headers.length) {
        return 0;
    }
    // Combine height of all children
    let height = 0;
    for (const child of Array.from(headers)) {
        height += child.getBoundingClientRect().height;
    }
    return height;
}

/**
 * Utility hook returning the current fixed header height in pixel.
 */
export function useHeaderFixedHeight() {
    const count = getStickyHeaders().length;

    return useMemo(getFixedHeaderHeight, [count]);
}
