import { channelNameIsValid } from "../../../../../../../../components/components/chat/utils/chat_utils";

function ChatService(ChatFactory, LumsitesBaseService) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(ChatFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Returns the list of available providers.
     *
     * @return {Promise} The promise of loading conversations.
     */
    function listProviders() {
        return ChatFactory.listProviders().$promise;
    }

    /**
     * Retrieve the list of user spaces.
     *
     * @param  {string}  provider        The provider of chats.
     * @param  {number}  [maxResults=30] The number of conversations to retrieve.
     * @return {Promise} The promise of loading conversations.
     */
    function listUserSpaces(provider, maxResults) {
        return ChatFactory.listUserSpaces(maxResults, provider).$promise;
    }

    /**
     * Retrieve the list of channels.
     *
     * @param  {string}  provider        The provider of chats.
     * @param  {string}  spaceId         The user space of chats.
     * @param  {number}  [maxResults=30] The number of conversations to retrieve.
     * @return {Promise} The promise of loading conversations.
     */
    function listChannelsForUserSpace(provider, spaceId, maxResults) {
        return ChatFactory.listChannelsForUserSpace(maxResults, provider, spaceId).$promise;
    }

    /**
     * Returns the corresponding channel.
     *
     * @param  {string}  provider  The provider of chats.
     * @param  {string}  spaceId   The user space of chats.
     * @param  {string}  channelId The user space of chats.
     * @return {Promise} The promise of loading conversations.
     */
    function getChannelById(provider, spaceId, channelId) {
        return ChatFactory.getChannelById({ provider, spaceId, channelId }).$promise;
    }

    /**
     * Returns the corresponding channel if exists.
     *
     * @param  {string}  provider    The provider of chats.
     * @param  {string}  spaceId     The user space of chats.
     * @param  {string}  channelName The user space of chats.
     * @return {Promise} The promise of loading conversations.
     */
    function getChannelByName(provider, spaceId, channelName) {
        return ChatFactory.getChannelByName({ provider, spaceId, channelName }).$promise;
    }

    /////////////////////////////

    service.getChannelById = getChannelById;
    service.getChannelByName = getChannelByName;
    service.listProviders = listProviders;
    service.listUserSpaces = listUserSpaces;
    service.listChannelsForUserSpace = listChannelsForUserSpace;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Chat', ChatService);

/////////////////////////////

export { ChatService };
