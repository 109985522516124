/* eslint-disable react/no-danger */
import React from 'react';

import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { getNotificationMeta } from '../notification-meta';
import { Notification, NotificationSenderDetails } from '../types';

const allowedTags = ['strong', 'b'];
const useDescription = (notification: Notification, senderDetails?: NotificationSenderDetails | null) => {
    const translation = useTranslate();
    const currentLanguage = useSelector(currentLanguageSelector);

    return React.useMemo(() => {
        const { type, description } = notification;
        const meta = getNotificationMeta(type);

        if (meta) {
            // get the title
            const title = meta.getTitle
                ? meta.getTitle({
                      notification,
                      count: notification.actionCount,
                      senderDetails,
                      translation,
                      currentLanguage,
                  })
                : null;

            const metaDescription =
                meta.getDescription({
                    notification,
                    count: notification.actionCount,
                    senderDetails,
                    translation,
                    currentLanguage,
                }) || '';

            return (
                <>
                    {title && (
                        <>
                            <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(title, { allowedTags }) }} /> <br />
                        </>
                    )}
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(metaDescription, { allowedTags }),
                        }}
                    />
                </>
            );
        }

        return (
            <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(translation.translateObject(description || {}) || '', { allowedTags }),
                }}
            />
        );
    }, [currentLanguage, notification, senderDetails, translation]);
};

export { useDescription };
