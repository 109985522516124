import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Icon, Placement, Size, Text, Theme, Tooltip } from '@lumapps/lumx/react';
import { useOverflowTooltipLabel } from '@lumapps/utils/hooks/useOverflowTooltipLabel';

import { ViewMode } from '../../constants';

import './index.scss';

export interface NavigationItemProps {
    /** Classname that will be used for the nav wrapping element */
    className?: string;
    /** the element used to create data attributes */
    element?: string;
    /** Icon (SVG path). */
    icon?: string;
    /** Whether the component is active or not. */
    isSelected?: boolean;
    /** Label content. */
    label: string | React.ReactNode;
    /** Custom react component for the link (can be used to inject react router Link). */
    linkAs?: 'a' | React.ElementType;
    /** Props to pass to the link (minus those already set by the SideNavigationItem props). */
    linkProps?:
        | React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
        | React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
        | Record<string, any>;
    /** On click callback. */
    onClick?(evt: React.MouseEvent): void;
    /** the scope used to create data attributes */
    scope?: string;
    /** Theme that will be applied to the element, either Theme.dark or Theme.light */
    theme?: Theme;
    /** The view mode that will be used to display the nav, either 'horizontal' or 'vertical', defaults to 'vertical' */
    viewMode?: ViewMode;
}

export const CLASSNAME = 'navigation-item';

const NavigationItem = React.forwardRef<HTMLLIElement, NavigationItemProps>((props, ref) => {
    const {
        className,
        element,
        icon,
        isSelected,
        label,
        linkAs,
        linkProps,
        onClick,
        scope,
        theme,
        viewMode,
        ...forwardedProps
    } = props;
    const { block, element: elementClassname } = useClassnames(CLASSNAME);
    // Compute a tooltip label based on label content if text is overflowing
    const { labelRef, tooltipLabel } = useOverflowTooltipLabel();

    /* istanbul ignore next */
    const { get } = useDataAttributes(scope ?? '');
    const dataAttributes = scope && element ? get({ element }) : {};

    const isHorizontalViewMode = viewMode === ViewMode.horizontal;

    const isLink = (linkProps && 'href' in linkProps && linkProps?.href) || linkAs;

    const Element: 'a' | 'button' | React.ElementType = isLink ? linkAs || 'a' : 'button';

    return (
        <li
            className={block({ horizontal: isHorizontalViewMode }, [className])}
            ref={ref}
            {...dataAttributes}
            {...forwardedProps}
        >
            <Tooltip label={tooltipLabel} placement={Placement.TOP}>
                <Element
                    className={elementClassname('link', {
                        'is-selected': Boolean(isSelected),
                        dark: theme === Theme.dark,
                    })}
                    {...linkProps}
                    onClick={onClick}
                    tabIndex={0}
                >
                    {icon ? (
                        <Icon className={elementClassname('icon')} icon={icon} size={Size.xs} theme={theme} />
                    ) : null}

                    <Text as="span" className={elementClassname('label')} ref={labelRef}>
                        {label}
                    </Text>
                </Element>
            </Tooltip>
        </li>
    );
});

NavigationItem.displayName = 'NavigationItem';

export { NavigationItem };
