import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { mdiArrowDownThick, mdiArrowUpThick } from '@lumapps/lumx/icons';
import { Orientation, FlexBox, Alignment, Tooltip, IconButton, Emphasis, Theme, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { POSTS } from '../../../keys';
import { IdeaVote } from '../../../types';
import { usePostVote } from './usePostVote';

import './index.scss';

interface PostVoteProps {
    theme?: Theme;
    initialVotes: {
        userVote?: string | IdeaVote;
        upVotes: number;
        downVotes: number;
    };
    postId: string;
    isPreviewOnly?: boolean;
}

const CLASSNAME = 'block-post-vote';

const PostVote: React.FC<PostVoteProps> = ({ initialVotes, theme, postId, isPreviewOnly }) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);
    const {
        isVoteLoading,
        onVote,
        votes: { downVotes, upVotes, userVote },
    } = usePostVote({
        postId,
        initialVotes,
    });
    const totalVotes = upVotes - downVotes;

    return (
        <FlexBox
            theme={theme}
            orientation={Orientation.vertical}
            vAlign={Alignment.center}
            className={block([margin('right', 'big')])}
        >
            <IconButton
                theme={theme}
                emphasis={Emphasis.low}
                icon={mdiArrowUpThick}
                onClick={() => onVote(IdeaVote.UP)}
                disabled={isVoteLoading || userVote?.toLowerCase() === IdeaVote.UP}
                isSelected={userVote?.toLowerCase() === IdeaVote.UP}
                label={`${upVotes} ${translateKey(upVotes === 1 ? POSTS.VOTE : POSTS.VOTES)}`}
                className={element('up-btn', {
                    'is-preview-only': Boolean(isPreviewOnly),
                })}
            />
            <Tooltip theme={theme} label={totalVotes.toString()}>
                <Text as="span">{totalVotes >= 1000 ? '999+' : totalVotes}</Text>
            </Tooltip>
            <IconButton
                theme={theme}
                emphasis={Emphasis.low}
                icon={mdiArrowDownThick}
                onClick={() => onVote(IdeaVote.DOWN)}
                disabled={isVoteLoading || userVote?.toLowerCase() === IdeaVote.DOWN}
                isSelected={userVote?.toLowerCase() === IdeaVote.DOWN}
                label={`${downVotes} ${translateKey(downVotes === 1 ? POSTS.VOTE : POSTS.VOTES)}`}
                className={element('down-btn', {
                    'is-preview-only': Boolean(isPreviewOnly),
                })}
            />
        </FlexBox>
    );
};

export { PostVote };
