import React from 'react';

import { previewContentList } from '@lumapps/content-lists/routes';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { MetadataV2 } from '../types';
import { useMetadataContentListResolver } from './useMetadataContentListResolver';

export const useMetadataLink = (metadata: MetadataV2, contentTypeId: string) => {
    const { id: instanceId } = useSelector(getCurrentInstance);
    const { translateObject } = useTranslate();

    const defaultContentList = useMetadataContentListResolver({
        contentTypeId,
        currentSiteId: instanceId,
        metadataId: metadata.id,
    });

    const route = React.useMemo(() => {
        if (defaultContentList) {
            const filters = `metadata_${metadata.id}`;
            return previewContentList(translateObject(defaultContentList.slug) || '', { filters });
        }

        return null;
    }, [defaultContentList, metadata, translateObject]);

    return route;
};
