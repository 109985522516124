import React, { useCallback } from 'react';

import { mdiMagnify } from '@lumapps/lumx/icons';
import { IconButton, Emphasis } from '@lumapps/lumx/react';
import { connect } from '@lumapps/redux/react';
import { Dispatch } from '@lumapps/redux/types';
import { useRouter } from '@lumapps/router';
import { actions } from '@lumapps/search/ducks/slice';
import { useSearch } from '@lumapps/search/hooks/useSearch';
import { mobileSearchRoute } from '@lumapps/search/routes';
import { useTopStyles } from '@lumapps/style/hooks/useTopStyles';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface SearchBoxMobileProps {
    /** callback to reset the search results */
    resetResults: () => void;
    /** callback to reset the search query */
    resetQuery: () => void;
}

const SearchBoxMobile: React.FC<SearchBoxMobileProps> = ({ resetResults, resetQuery }) => {
    const { redirect } = useRouter();
    const { routes, focusOnSearchInput } = useSearch({ keepFiltersOnSearch: true });
    const { themeColor } = useTopStyles();
    const { translateKey } = useTranslate();

    /**
     * When we want to show the search icon, we want that when the icon is clicked, that the page is fully refreshed before navigating
     * to it. So we reset the results and the query, and then we redirect the user to the route with the search box displayed.
     */
    const onSearchIconClick = useCallback(() => {
        /**
         * We scroll to the top of the page since the user could be at the bottom of the page and
         * if search icon is clicked, they wont be able to see the searchbox, they will need to
         * manually scroll to the top.
         */
        window.scrollTo(0, 0);

        if (document && document.activeElement) {
            (document.activeElement as any).blur();
        }

        if (!routes.searchRoute) {
            resetResults();
            resetQuery();
            redirect(mobileSearchRoute);
        } else {
            focusOnSearchInput();
        }
    }, [redirect, resetResults, resetQuery, routes, focusOnSearchInput]);

    return (
        <IconButton
            icon={mdiMagnify}
            emphasis={Emphasis.low}
            onClick={onSearchIconClick}
            className="search-mobile-icon"
            color={themeColor as any}
            label={translateKey(GLOBAL.SEARCH)}
        />
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        resetResults: () => {
            dispatch(actions.resetResults());
        },
        resetQuery: () => {
            dispatch(actions.resetQuery());
        },
    };
};

const ConnectedSearchBox = connect(mapStateToProps, mapDispatchToProps)(SearchBoxMobile);

export { ConnectedSearchBox as SearchBoxMobile };
