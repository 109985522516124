import { CommunityPrivacy, RenderingType } from '@lumapps/communities/types';
import { Block, BlockImage, BlockUser, SiteReference } from '@lumapps/widget-base/types';

export enum BlockCommunityPreviewVariants {
    horizontal = 'HORIZONTAL',
    horizontalStretch = 'HORIZONTAL_STRETCH',
    vertical = 'VERTICAL',
    verticalStretch = 'VERTICAL_STRETCH',
}

export enum OrderElement {
    description = 'description',
    follow_button = 'follow_button',
    image = 'image',
    members = 'members',
    site_name = 'site_name',
    title = 'title',
    visibility = 'visibility', // only used for the block inside Feed
}

export enum ThumbnailDisplayType {
    cover = 'cover',
    small = 'small',
}

export interface BlockCommunityPreview extends Block {
    type: 'BlockCommunityPreview';
    /** The community's id. */
    communityId: string;
    /** The site's reference. */
    site?: SiteReference;
    /** The community rendering type */
    renderingType?: RenderingType;
    /** The site's id. */
    siteId?: string;
    /** The community's slug. */
    slug: string;
    /** The community's title. */
    title?: string;
    /** The community's description. */
    description?: string;
    /** The community's thumbnail */
    image?: BlockImage;
    /** The variant to display */
    variant?: BlockCommunityPreviewVariants;
    /** Whether the follow button and the notifications button must be displayed. */
    areSocialButtonsVisible: boolean;
    /** Whether the current user follows the community. */
    isFollowed?: boolean;
    /** Whether the current user has enabled notifications for the community. */
    areNotificationsEnabled?: boolean;
    /** Whether the notifications are activated along with the subscription */
    areNotificationsEnabledOnSubscription?: boolean;
    /** The first five members of the community, ordered by email ascending. */
    members?: BlockUser[];
    /** The list of displayed properties, if a prop is not in this array, then it should not be displayed */
    order: OrderElement[];
    /** Community visibility. */
    visibility?: CommunityPrivacy;
    /** Community or Space thumbnail display type. Only defined when in Personal Feed */
    thumbnailDisplayType?: ThumbnailDisplayType;
}
