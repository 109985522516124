export enum WREX_IMAGE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40494619
     */
    INSERT_IMAGE = 'FRONT.TEXT_EDITOR.INSERT_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355125532
     */
    IMAGE_BUTTON_LABEL = 'WREX_IMAGE.BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49149921
     */
    FROM_COMPUTER = 'FRONT.POST_DIALOG.ADD_ATTACHMENTS.FROM_COMPUTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49149927
     */
    FROM_MEDIA_LIBRARY = 'FRONT.POST_DIALOG.ADD_ATTACHMENTS.FROM_MEDIA_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95469138
     */
    IMAGE_CAPTION_PLACEHOLDER = 'FRONT.CONTRIBUTION.IMAGE.CAPTION.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=358735997
     */
    INSERT_ALT_TEXT_ADD = 'WREX_IMAGE.INSERT_ALT_TEXT_ADD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=358735997
     */
    INSERT_ALT_TEXT_EDIT = 'WREX_IMAGE.INSERT_ALT_TEXT_EDIT',
}
