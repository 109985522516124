import { GLOBAL } from '@lumapps/translations';

import { SortersType } from './types';

export enum SORT_ORDER {
    DECREASING = 'decreasing',
    INCREASING = 'increasing',
}

export enum FILTERS_MODE {
    EXPOSED_FACETS = 'EXPOSED_FACETS',
    GROUPED = 'GROUPED',
}

/**
 * For compatibility issues, some legacy APIs use this naming convention instead.
 */
export type Order = 'asc' | 'desc';

/**
 * Default sorts
 */
export const SORT_FIELDS: SortersType[] = [
    { label: GLOBAL.MOST_RELEVANT },
    { label: GLOBAL.SORT_ALPHABETICALLY_ASC, id: 'title' },
    { label: GLOBAL.SORT_ALPHABETICALLY_DESC, id: '-title' },
    { label: GLOBAL.SORT_NEWEST, id: '-createdAt' },
    { label: GLOBAL.SORT_OLDEST, id: 'createdAt' },
];
