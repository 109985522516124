import React from 'react';

import { Image } from '@lumapps/lumx-images/types';
import { mdiImageFilter } from '@lumapps/lumx/icons';
import { useMediaPickerDropdown } from '@lumapps/medias/hooks/useMediaPickerDropdown';
import { useResponsive } from '@lumapps/responsive';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';
import { useFilePicker } from '@lumapps/utils/hooks/useFilePicker';
import { UseInsertEnhancedImageButtonProps } from '@lumapps/wrex-enhanced-image/components/buttons/InsertEnhancedImageButton/useInsertEnhancedImageButton';
import { ImageEditor } from '@lumapps/wrex-enhanced-image/types';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { WREX_IMAGE_GALLERY } from '../../../keys';
import { formatFilesToImageStructure } from '../../../utils/formatFilesToImageStructure';
import { ImageGalleryDialog } from '../../ImageGalleryDialog';

type UseInsertImageGalleryButtonProps = Omit<UseInsertEnhancedImageButtonProps, 'onQuickUpload'>;

export const useInsertImageGalleryButton = ({
    currentFiles,
    isDriveAllowed,
    isLumdriveAllowed,
    isInCommunityContext,
    mediaPickerParams,
    onMediaChange,
    otherProps,
}: UseInsertImageGalleryButtonProps) => {
    const { translateKey } = useTranslate();
    const isInWebview = useDetectMobileAppWebView();
    const { isSmall } = useResponsive();
    const editor = useSlateStatic() as ReactEditor & ImageEditor;
    const [isImageGalleryDialogOpen, openImageGalleryDialog, closeImageGalleryDialog] = useBooleanState(false);
    const [pickedImages, setPickedImages] = React.useState<Image[]>([]);
    const shouldHideButton = isInWebview || isSmall;
    const { hiddenInput, openPicker } = useFilePicker({
        onSelectFiles: (files) => {
            if (files) {
                const images = formatFilesToImageStructure(files, true);
                setPickedImages(images);
                openImageGalleryDialog();
            }
        },
        accept: 'image/*',
        multiple: true,
        inputOrigin: 'image-gallery-insertion-button',
    });

    const handleOnSave = async (images: Image[]) => {
        await editor.insertImageGallery(images);
        closeImageGalleryDialog();
    };

    const { mediaPickerDropdownProps } = useMediaPickerDropdown({
        onUploadFromComputer: openPicker,
        currentFiles,
        isDriveAllowed,
        isInCommunityContext,
        isLumdriveAllowed,
        mediaPickerParams,
        onMediaChange,
        subMenuProps: {
            itemKey: 'image-gallery',
            icon: mdiImageFilter,
            otherProps,
            verticalModeProps: {
                label: translateKey(WREX_IMAGE_GALLERY.BUTTON_LABEL),
                tooltipLabel: undefined,
            },
            helper: translateKey(WREX_IMAGE_GALLERY.BUTTON_HELPER),
        },
    });

    const insertImageGalleryButtonProps: ToolbarItem = mediaPickerDropdownProps || {
        type: 'option',
        itemKey: 'image-gallery',
        icon: mdiImageFilter,
        onClick: openPicker,
        otherProps,
        tooltipLabel: translateKey(WREX_IMAGE_GALLERY.BUTTON_TOOLTIP),
        verticalModeProps: {
            label: translateKey(WREX_IMAGE_GALLERY.BUTTON_LABEL),
            helper: translateKey(WREX_IMAGE_GALLERY.BUTTON_HELPER),
            tooltipLabel: undefined,
        },
    };

    const insertImageGalleryDialog = (
        <ImageGalleryDialog
            isOpen={isImageGalleryDialogOpen}
            images={pickedImages}
            onClose={closeImageGalleryDialog}
            onSave={handleOnSave}
            isNewImageGallery
        />
    );

    return shouldHideButton
        ? undefined
        : {
              hiddenInput,
              insertImageGalleryButtonProps,
              insertImageGalleryDialog,
          };
};
