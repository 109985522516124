export enum USER_PROFILE {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124779777
     */
    MY_PROFILE = 'USER_PROFILE.MY_PROFILE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23169577
     */
    MORE_INFO = 'FRONT.USER.PROFILE.MORE_INFO.LINK',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453755
     */
    ORGANIZATION_CHART = 'USER_DIRECTORY_ORG_CHART',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124600704
     */
    CONTACT_BUTTONS_ERROR = 'USER_PROFILE.CONTACT_BUTTONS_ERROR',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=128462201
     */
    ORG_CHART_MORE_USERS_WARNING = 'USER_PROFILE.ORG_CHART_MORE_USERS_WARNING',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130081774
     */
    STATUS_PLACEHOLDER = 'USER_PROFILE.STATUS_PLACEHOLDER',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136972938
     */
    FIELDS_NOT_EDITABLE = 'USER_PROFILE.FIELDS_NOT_EDITABLE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136972945
     */
    FIELDS_NOT_SUPPORTED = 'USER_PROFILE.FIELDS_NOT_SUPPORTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142443075
     */
    PROFILE_UPDATED = 'USER_PROFILE.PROFILE_UPDATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162646227
     */
    ACTIVITY_IS_PRIVATE = 'USER_PROFILE.ACTIVITY_IS_PRIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230197544
     */
    NO_DEFAULT_USER_DIRECTORY = 'USER_PROFILE.NO_DEFAULT_USER_DIRECTORY',
}
