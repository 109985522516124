import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Heading } from '@lumapps/lumx/react';
import { PlaylistFieldOrder } from '@lumapps/play-playlists/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { GenericSwitchList } from '@lumapps/widget-base/components/WidgetSettings/GenericSwitchList';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { Setting } from '@lumapps/widget-base/types/setting';

import { WIDGET_PLAYLIST } from '../../../keys';
import { WidgetPlaylistProperties } from '../../../types';
import './index.scss';

export type PlaylistMetadata = Pick<WidgetPlaylistProperties, 'playlistFieldOrder'>;

export interface PlaylistMetadataSelectionProps {
    className?: string;
    properties: PlaylistMetadata;
    isLoading?: boolean;
    onFieldChange: <K extends keyof WidgetPlaylistProperties>(fieldName: K) => (nextValue: PlaylistFieldOrder) => void;
}

const CLASSNAME = 'playlist-metadata-selection';

/**
 *
 * @param PlaylistMetadataSelectionProps
 * @returns PlaylistMetadataSelection
 */
export const PlaylistMetadataSelection = ({
    className,
    properties,
    isLoading,
    onFieldChange,
}: PlaylistMetadataSelectionProps) => {
    const { translateKey } = useTranslate();
    const settings: Setting<PlaylistFieldOrder>[] = [
        {
            label: translateKey(GLOBAL.THUMBNAIL),
            name: 'thumbnail',
        },
        {
            label: translateKey(GLOBAL.TITLE),
            name: 'title',
        },
        {
            label: translateKey(WIDGET_PLAYLIST.NUMBER_OF_VIDEOS),
            name: 'video_count',
        },
        {
            label: translateKey(GLOBAL.DATE),
            name: 'date',
        },
    ];

    return (
        <div className={classnames(className, CLASSNAME)}>
            <Heading as="h5" className={`${CLASSNAME}__subtitle`}>
                {translateKey(WIDGET_BASE.SETTINGS_INFORMATION_DISPLAYED_TITLE)}:&nbsp;
                {translateKey(WIDGET_PLAYLIST.PLAYLISTS)}
            </Heading>
            <GenericSwitchList<PlaylistFieldOrder>
                className={`${CLASSNAME}__switch-list`}
                items={settings}
                order={properties.playlistFieldOrder}
                onChange={onFieldChange('playlistFieldOrder')}
                isLoading={isLoading}
            />
        </div>
    );
};
