import React, { Children, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import first from 'lodash/fp/first';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import property from 'lodash/fp/property';
import throttle from 'lodash/fp/throttle';

import { useClassnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { FilterId } from '../../../types';
import { PostListFilterItem, CLASSNAME as ITEM_CLASSNAME } from '../PostListFilterItem';
import './index.scss';

export const CLASSNAME = 'post-list-filters';
const MORE_INDEX = 'more';
const { FILTERS, MORE_FILTERS } = GLOBAL;

const computeLastChipLabelKey = (itemWidth: number, availableWidth: number, isLastChipAMoreButton: boolean) => {
    if (isLastChipAMoreButton) {
        return MORE_FILTERS;
    }

    return itemWidth <= availableWidth ? FILTERS : '';
};

interface PostListFiltersResponsiveProps {
    theme: Theme;
    onApply: (filterIds: FilterId[]) => void;
    onCancel: (filterIds: FilterId[]) => void;
    onReset: (filterIds: FilterId[]) => void;
}

export const PostListFiltersResponsive: React.FC<PostListFiltersResponsiveProps> = ({
    children,
    theme,
    onApply,
    onCancel,
    onReset,
}) => {
    const childrenArray = Children.toArray(children);
    const { translateKey } = useTranslate();
    const { element } = useClassnames(ITEM_CLASSNAME);
    const [ulElement, setUlElement] = useState<HTMLUListElement | null>(null);
    const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>({});
    const [lastChipLabelKey, setLastChipLabelKey] = useState<string>(MORE_FILTERS);
    const [currentFilterModalOpen, setCurrentFilterModalOpen] = useState<{
        id: FilterId | typeof MORE_INDEX;
        filterIds: FilterId[];
    } | null>(null);

    const widthMap = useMemo((): Record<string, number> => {
        if (!ulElement) {
            return {};
        }

        const items = Array.from(ulElement.children) as HTMLElement[];

        return items.reduce((acc, item) => ({ ...acc, [item.dataset.itemIndex as string]: item.offsetWidth }), {});
    }, [ulElement]);

    const handleResize = useMemo(() => {
        if (!ulElement) {
            return null;
        }

        let prevWidth = 0;

        return throttle(100, () => {
            let availableWidth = ulElement.clientWidth;

            // we don't want to execute the code if the width has not changed
            /* istanbul ignore next */
            if (availableWidth === prevWidth) {
                return;
            }
            prevWidth = availableWidth;

            const { columnGap } = getComputedStyle(ulElement);

            const columnGapWidth = parseInt(columnGap, 10) || 0;

            const newVisibilityMap: Record<string, boolean> = {};

            Object.keys(widthMap)
                .filter((key) => key !== MORE_INDEX)
                .map((key) => parseInt(key, 10))
                .sort((a, b) => a - b)
                .forEach((key, index) => {
                    const itemsCount = Object.keys(widthMap).length - 1;

                    const itemWidth = widthMap[key];
                    const moreWidth = widthMap[MORE_INDEX];

                    const isItemVisible =
                        index < itemsCount - 1
                            ? availableWidth - (itemWidth + columnGapWidth + moreWidth) >= 0
                            : availableWidth - itemWidth >= 0;

                    newVisibilityMap[key] = isItemVisible;
                    newVisibilityMap[MORE_INDEX] = !isItemVisible;

                    availableWidth = isItemVisible ? availableWidth - (itemWidth + columnGapWidth * 2) : 0;
                });

            if (!isEqual(visibilityMap, newVisibilityMap)) {
                setVisibilityMap(newVisibilityMap);
            }

            // If one boolean of the visibilityMap except the last one is true, then the last chip is a more button
            const isLastChipAMoreButton = Object.values(newVisibilityMap).slice(0, -1).some(Boolean);

            setLastChipLabelKey(computeLastChipLabelKey(widthMap[MORE_INDEX], prevWidth, isLastChipAMoreButton));
        });
    }, [ulElement, visibilityMap, widthMap]);

    useEffect(() => {
        if (!handleResize || !ulElement) {
            return undefined;
        }

        handleResize();

        const observer = new ResizeObserver(handleResize);

        observer.observe(ulElement);

        return () => observer.unobserve(ulElement);
    }, [handleResize, ulElement]);

    // when the user applies the filter(s)
    const handleApply = useCallback(
        (filterIds: FilterId[]) => {
            onApply(filterIds);
            setCurrentFilterModalOpen(null);
        },
        [setCurrentFilterModalOpen, onApply],
    );

    // when the user closes the modal without applying or clearing
    const handleClose = useCallback(
        (filterIds: FilterId[]) => {
            onCancel(filterIds);
            setCurrentFilterModalOpen(null);
        },
        [onCancel, setCurrentFilterModalOpen],
    );

    // when the user clears the filter(s)
    const handleReset = useCallback(
        (filterIds: FilterId[]) => {
            onReset(filterIds);
            setCurrentFilterModalOpen(null);
        },
        [onReset, setCurrentFilterModalOpen],
    );

    // when the user clicks on the filter(s) chip
    const handleToggle = useCallback(
        (filterIds: FilterId[]) => {
            const id = filterIds.length > 1 ? MORE_INDEX : (first(filterIds) as FilterId);

            if (currentFilterModalOpen) {
                onCancel(currentFilterModalOpen.filterIds);
            }

            // only close the modal if the clicked filter is the opened one, else open a new modal
            if (currentFilterModalOpen?.id === id) {
                setCurrentFilterModalOpen(null);
            } else {
                setCurrentFilterModalOpen({ id, filterIds });
            }
        },
        [currentFilterModalOpen, onCancel, setCurrentFilterModalOpen],
    );

    return (
        <ul className={CLASSNAME} ref={setUlElement}>
            {childrenArray.map((child, index) =>
                /* istanbul ignore next */
                React.isValidElement(child) ? (
                    <PostListFilterItem
                        key={child.props.label}
                        data-item-index={index}
                        data-testid={`post-list-filters-chip-${index}`}
                        onApply={handleApply}
                        onClose={handleClose}
                        onReset={handleReset}
                        onToggle={handleToggle}
                        theme={theme}
                        label={child.props.label}
                        name={child.props.name}
                        isLoading={Boolean(child.props.isLoading)}
                        className={element(
                            'item',
                            {
                                hidden: !isEmpty(visibilityMap) && !visibilityMap[index],
                            },
                            [child?.props?.className],
                        )}
                        isFilterOpen={currentFilterModalOpen?.id === property(['props', 'filterId'], child)}
                    >
                        {React.cloneElement(child as ReactElement, { theme, label: null })}
                    </PostListFilterItem>
                ) : null,
            )}
            <PostListFilterItem
                label={translateKey(lastChipLabelKey)}
                hasFilterIcon={lastChipLabelKey !== MORE_FILTERS}
                hasArrowDownIcon={Boolean(lastChipLabelKey)}
                data-item-index={MORE_INDEX}
                data-testid="post-list-filters-chip-more"
                onApply={handleApply}
                onClose={handleClose}
                onReset={handleReset}
                onToggle={handleToggle}
                theme={theme}
                className={element('more', {
                    hidden: !visibilityMap[MORE_INDEX],
                })}
                isFilterOpen={currentFilterModalOpen?.id === MORE_INDEX}
            >
                {childrenArray.map((child, index) =>
                    React.isValidElement(child) && !visibilityMap[index]
                        ? React.cloneElement(child as ReactElement, { theme })
                        : null,
                )}
            </PostListFilterItem>
        </ul>
    );
};
