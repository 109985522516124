import without from 'lodash/without';

import { BaseApiError } from '@lumapps/base-api';
import { getLegacyServerError } from '@lumapps/base-api/utils/getServerError';
import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { isEventFeatureEnabled } from '@lumapps/events/ducks/selectors';
import { actions as eventActions } from '@lumapps/events/ducks/slice';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { error as notifyError } from '@lumapps/notifications';
import { actions as postActions } from '@lumapps/posts/ducks/slice';
import { PostType } from '@lumapps/posts/types';
import { Dispatch } from '@lumapps/redux/types';

import * as api from '../../api';
import { GetCommunityParams, RenderingType } from '../../types';
import { getPostType } from '../../utils/getPostType';
import { actions as foActions, initialState as foInitialState } from '../frontOfficeSlice';

/**
 * Retrieve the currently selected community, initialize a new post
 * and open the post creation dialog.
 */
export const createNewPost =
    ({
        params,
        postType,
        renderingType,
        openEventDialog,
        openPostDialog,
    }: {
        params: Omit<GetCommunityParams, 'lang' | 'customerId' | 'instance'>;
        postType?: PostType;
        renderingType?: RenderingType;
        openEventDialog?: () => void;
        openPostDialog: () => void;
    }) =>
    async (dispatch: Dispatch, getState: () => any) => {
        try {
            dispatch(foActions.setIsLoadingForPostDialog(true));
            const currentState = getState();
            const instanceId = instanceIdSelector(currentState);
            const lang = currentLanguageSelector(currentState);
            const customer = customerSelector(currentState);

            const response = await api.get({ ...params, instance: instanceId, lang, customerId: customer?.id });

            const { data = {} } = response;

            let postTypes = data?.postTypes;

            let availablePostTypesOverride = postTypes.includes(PostType.DEFAULT)
                ? postTypes
                : [...postTypes, PostType.DEFAULT];

            const postTypeToUse =
                postType === PostType.DEFAULT
                    ? getPostType(availablePostTypesOverride, postType)
                    : getPostType(postTypes, postType);

            if (isEventFeatureEnabled(getState()) && data?.hasEventsEnabled) {
                // Remove the Old post event type if the New event feature
                postTypes = without(postTypes, PostType.EVENT);
                availablePostTypesOverride = without(availablePostTypesOverride, PostType.EVENT);
            }

            /**
             * Set the community that is target for the post creation in store.
             * */
            dispatch(foActions.setSelectedCommunity({ ...data }));

            if (
                postTypeToUse === 'EVENT' &&
                isEventFeatureEnabled(getState()) &&
                data?.hasEventsEnabled &&
                openEventDialog
            ) {
                openEventDialog();
                dispatch(
                    eventActions.setEditedEvent({
                        shares: [{ eventId: '', container: { containerType: 'COMMUNITY', containerId: params.uid } }],
                    }),
                );
            } else {
                dispatch(
                    postActions.setEditedPost({
                        postType: postTypeToUse,
                        externalKey: data?.id,
                        instance: data?.instance,
                        availableCategories: data?.tagsDetails,
                        parentContentDetails: {
                            /** List of all available post types for the given community. */
                            postTypes: postTypeToUse === PostType.DEFAULT ? availablePostTypesOverride : postTypes,
                            /** List of available post statuses for the given community. */
                            postStatuses: data?.postStatuses,
                            /** The slug of the parent content. */
                            slug: data?.slug,
                            /** Type of content */
                            type: data?.type,
                            /** Canonical url of the community */
                            canonicalUrl: data?.canonicalUrl
                                ? data.canonicalUrl[lang]
                                : foInitialState.selectedCommunity?.canonicalUrl,
                            /** @deprecated Indicates the option 'Security at community level' has been activated (we should use the FF "security at community level" instead) */
                            securedRepository: false,
                            renderingType: renderingType || RenderingType.community,
                        },
                    }),
                );
                openPostDialog();
            }
            dispatch(foActions.setIsLoadingForPostDialog(false));
        } catch (error) {
            if (error instanceof BaseApiError) {
                const { response } = error;

                dispatch(foActions.setIsLoadingForPostDialog(false));
                dispatch(notifyError({ translate: getLegacyServerError(response) }));
            }
        }
    };
