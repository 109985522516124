import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    ErrorPageState as BaseErrorPage,
    ErrorPageStateProps as BaseErrorPageProps,
} from '@lumapps/lumx-states/components/ErrorPageState';
import { mdiImageBrokenVariant } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { Page } from '../Page';

import './index.scss';

const CLASSNAME = 'front-office-error-page';

export interface ErrorPageProps extends BaseErrorPageProps {
    /** Forwarded className */
    className?: string;
}

/**
 * Page that the user will be redirected when there is an unexpected error in a navigation on the
 * front office page. This should not be used as a reusable component.
 * @family Front office
 * @param ErrorPageProps
 * @returns ErrorPage
 */
export const ErrorPage: React.FC<ErrorPageProps> = ({
    className: propClassName,
    code,
    shouldShowIcon,
    shouldShowAction,
    icon = mdiImageBrokenVariant,
    buttonProps = {},
    title,
    description,
}) => {
    const { block } = useClassnames(CLASSNAME);
    const className = block(`${code}`, [propClassName]);
    const { translateKey } = useTranslate();
    const pageTitle = translateKey(title || `${code}_TITLE`);

    return (
        <Page title={pageTitle} id={`error-page-${code}`} className={className} hasBackground={false}>
            <BaseErrorPage
                code={code}
                shouldShowIcon={shouldShowIcon}
                shouldShowAction={shouldShowAction}
                icon={icon}
                buttonProps={buttonProps}
                title={title}
                description={description}
            />
        </Page>
    );
};
