/* istanbul ignore file */
import { isFeatureEnabled } from '@lumapps/features';

import {
    COVEO_INDEXING_FEATURE_TOKEN,
    GCS_INDEXING_FEATURE_TOKEN,
    SEARCH_ANALYTICS_FEATURE_TOKEN,
    SEARCH_ANALYTICS_V2_FEATURE_TOKEN,
} from '../constants';

export const isSearchAnalyticsEnabled = isFeatureEnabled(SEARCH_ANALYTICS_FEATURE_TOKEN);
// Technical flag to be able to split api versions
export const isSearchAnalyticsV2Enabled = isFeatureEnabled(SEARCH_ANALYTICS_V2_FEATURE_TOKEN);

export const hasGCSIndexingEnabled = isFeatureEnabled(GCS_INDEXING_FEATURE_TOKEN);
export const hasCoveoIndexingEnabled = isFeatureEnabled(COVEO_INDEXING_FEATURE_TOKEN);
