import React from 'react';

import { isArticleFeatureEnabled } from '@lumapps/articles/ducks/selectors';
import { useClassnames } from '@lumapps/classnames';
import { isCommunitiesFeatureEnabled, isSpacesFeatureEnabled } from '@lumapps/communities/ducks/selectors';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { CustomLink } from '@lumapps/lumx-preview/components/DefaultPreview';
import { contentPage, learningHatImage, lumappsMicroApp } from '@lumapps/lumx/custom-icons';
import {
    mdiGoogleCirclesExtended,
    mdiLink,
    mdiMessageText,
    mdiNewspaperVariantOutline,
    mdiPlay,
    mdiPlayBoxMultiple,
} from '@lumapps/lumx/icons';
import { AspectRatio, LinkPreview as LumxLinkPreview, Size } from '@lumapps/lumx/react';
import { isMicroAppsEnabled } from '@lumapps/micro-apps/ducks/selectors';
import { isPlayVideoFeatureTokenEnabled } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { isPlatformExtensionsEnabled } from '@lumapps/reseller-console/ducks/selectors';
import { GLOBAL } from '@lumapps/translations';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import type { ElementRender } from '@lumapps/wrex/types';

import { LINK_PREVIEW, LINK_PREVIEW_RESOURCE_TYPES } from '../../../constants';
import type { LinkPreviewElement } from '../../../types';
import { useResourceLinkProps } from './useResourceLinkProps';

import './index.scss';

/**
 * Configuration to be used for displaying resources with their associated
 * icons and labels.
 * Do not move this const to constants file:
 * https://lumapps.slack.com/archives/C05GNBHQ3GE/p1700494364390539?thread_ts=1700493252.352249&cid=C05GNBHQ3GE
 */
export const RESOURCES_CONFIG: {
    icons: Record<LINK_PREVIEW_RESOURCE_TYPES, string>;
    labels: Record<LINK_PREVIEW_RESOURCE_TYPES, string>;
} = {
    icons: {
        [LINK_PREVIEW_RESOURCE_TYPES.ARTICLE]: mdiNewspaperVariantOutline,
        [LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY]: mdiGoogleCirclesExtended,
        [LINK_PREVIEW_RESOURCE_TYPES.CONTENT]: contentPage,
        [LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH]: learningHatImage,
        [LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP]: lumappsMicroApp,
        [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST]: mdiPlayBoxMultiple,
        [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO]: mdiPlay,
        [LINK_PREVIEW_RESOURCE_TYPES.POST]: mdiMessageText,
        [LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE]: learningHatImage,
    },
    labels: {
        [LINK_PREVIEW_RESOURCE_TYPES.ARTICLE]: GLOBAL.ARTICLE,
        [LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY]: GLOBAL.COMMUNITY,
        [LINK_PREVIEW_RESOURCE_TYPES.CONTENT]: GLOBAL.CONTENTS,
        [LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH]: GLOBAL.LEARNING,
        [LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP]: GLOBAL.MICRO_APP,
        [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST]: GLOBAL.PLAYLISTS,
        [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO]: GLOBAL.VIDEO,
        [LINK_PREVIEW_RESOURCE_TYPES.POST]: GLOBAL.POST,
        [LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE]: GLOBAL.LEARNING,
    },
};

export const CLASSNAME = 'wrex-link-preview';

export const LinkPreview: ElementRender<LinkPreviewElement, HTMLDivElement> = ({
    element,
    elementRef,
    children,
    className,
    ...forwardedProps
}) => {
    const theme = useThemeContext();
    const { url, title, description, thumbnailUrl, resource } = element ?? {};
    const { block } = useClassnames(CLASSNAME);
    const customLink = useResourceLinkProps(resource, url);
    const { linkAs, ...linkProps } = (customLink as CustomLink) || {};
    // we don't display the url sent by the api if it's an internal resource since we need to parse the url
    const [link, setLink] = React.useState(resource ? '' : url);

    const hasArticle = useSelector(isArticleFeatureEnabled);
    const hasCommunity = useSelector(isCommunitiesFeatureEnabled);
    const hasLearning = useSelector(isLearningEnabled);
    const hasPlatformExtensions = useSelector(isPlatformExtensionsEnabled);
    const hasMicroAppsFF = useSelector(isMicroAppsEnabled);
    const hasMicroApp = hasMicroAppsFF && hasPlatformExtensions;
    const hasVideo = useSelector(isPlayVideoFeatureTokenEnabled);
    const hasSpaceFF = useSelector(isSpacesFeatureEnabled);
    const hasPost = hasCommunity || hasSpaceFF;

    /**
     * Whether an internal lumapps resource is allowed
     * @param type - internal resource type
     * @returns boolean
     */
    const isLinkPreviewResourceAllowed = (type: LINK_PREVIEW_RESOURCE_TYPES): boolean => {
        return (
            type === LINK_PREVIEW_RESOURCE_TYPES.CONTENT ||
            (hasArticle && type === LINK_PREVIEW_RESOURCE_TYPES.ARTICLE) ||
            (hasCommunity && type === LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY) ||
            (hasLearning &&
                (type === LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH ||
                    type === LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE)) ||
            (hasMicroApp && type === LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP) ||
            (hasVideo && type === LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO) ||
            (hasVideo && type === LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST) ||
            (hasPost && type === LINK_PREVIEW_RESOURCE_TYPES.POST)
        );
    };

    // Dynamic link: if link is an internal lumapps resource, we copy the actual HTML href into the LinkPreview `link` prop
    const linkRef = React.useCallback(
        (linkElement: HTMLAnchorElement) => {
            if (!linkElement || link) {
                return;
            }
            setLink(linkElement.href);
        },
        [link],
    );

    const getFallbackImage = React.useMemo(() => {
        if (resource?.type) {
            return RESOURCES_CONFIG.icons[resource.type];
        }

        /** if there is no resource type, we possibly have an external link */
        return mdiLink;
    }, [resource]);

    return (
        <div className={block(`theme-${theme}`, [className])} ref={elementRef} {...forwardedProps}>
            {children}
            {/* we display the linkPreview:
            - if there's no resource (meaning that it is an external link)
            - or when the resource is an internal lumapps resource allowed */}
            {(!resource || isLinkPreviewResourceAllowed(resource.type)) && (
                <LumxLinkPreview
                    contentEditable={false}
                    theme={theme}
                    title={title || resource?.title}
                    description={description || resource?.description}
                    link={link || ''}
                    linkAs={linkAs}
                    linkProps={{ ...linkProps, ref: linkRef }}
                    size={Size.regular}
                    thumbnailProps={{
                        aspectRatio: AspectRatio.wide,
                        image: thumbnailUrl || resource?.thumbnailUrl || '',
                        alt: description || resource?.description || '',
                        fallback: getFallbackImage,
                    }}
                />
            )}
        </div>
    );
};
LinkPreview.displayName = LINK_PREVIEW;
