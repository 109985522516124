import React, { useMemo } from 'react';

import { ServerListResponse } from '@lumapps/base-api';
import { useClassnames } from '@lumapps/classnames';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { useDataAttributes } from '@lumapps/data-attributes';
import { getFavoriteEntries } from '@lumapps/directory-entries/api';
import { DirectoryEntry, DirectoryEndpointQuery } from '@lumapps/directory-entries/types';
import { AsyncMenu } from '@lumapps/lumx-menu/components/AsyncMenu';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiArrowRight } from '@lumapps/lumx/icons';
import { Placement, Offset, Icon, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { FAVORTIES } from '../../keys';
import { FavoriteDropdownItem } from './FavoriteDropdownItem';

import './index.scss';

export type FavoriteDropdownProps = {
    /** Id of the directory */
    id: string;
    /** The directory content id */
    contentId: string;
    /** Icon of the directory */
    icon: string;
    /** Name translated in many languages */
    name: Record<string, string>;
    /** The directory slug */
    slug: Record<string, string>;
    /** The user lang */
    language: string;
    /** Action to fetch directory's favorites */
    getFavorites?(params: DirectoryEndpointQuery): Promise<ServerListResponse<DirectoryEntry>>;
    /** The dropdown placement */
    placement?: Placement;
    /** The dropdown offset */
    offset?: Offset;
    /** custom styles passed down to the icon */
    styles?: Record<string, any>;
    /** the instance slug to redirect to */
    instanceSlug?: string;
};

const CLASSNAME = 'directory-favorite';
const SCOPE = 'favorites';

/**
 * Display an IconButton with a dropdown containing all entries
 * @param FavoriteDropdownProps
 */
export const FavoriteDropdown: React.FC<FavoriteDropdownProps> = ({
    id,
    contentId,
    icon,
    name,
    slug,
    language,
    getFavorites = getFavoriteEntries,
    offset = {},
    styles = {},
    placement = Placement.AUTO_START,
    instanceSlug,
}) => {
    const { translateKey, translateObject } = useTranslate();
    const { iconPath } = useDynamicIcon({ icon });
    const { get } = useDataAttributes(SCOPE);
    const { block, element } = useClassnames(CLASSNAME);

    const params = useMemo(() => ({ id, language }), [id, language]);

    return (
        <AsyncMenu
            id={id}
            className={block()}
            icon={iconPath}
            name={translateObject(name) as string}
            get={getFavorites}
            offset={offset}
            placement={placement}
            width={{ minWidth: REAL_SIZE_FOR_LUMX_SIZE.xxl }}
            styles={styles}
            params={params}
            scope={SCOPE}
            emptyMessage={translateKey(FAVORTIES.NO_ENTRIES)}
            renderAfter={() => (
                <Menu.Item
                    as={ContentLink}
                    to={{
                        id: contentId,
                        slug: translateObject(slug) as string,
                        instance: { slug: instanceSlug },
                        externalKey: id,
                        type: 'directory',
                    }}
                    target="_self"
                    after={<Icon icon={mdiArrowRight} size="s" />}
                    {...get({ element: 'button', action: 'show-all' })}
                >
                    <Text as="span" className={element('show-all-link')}>
                        {translateKey(GLOBAL.SHOW_ALL)}
                    </Text>
                </Menu.Item>
            )}
        >
            {({ items }) => {
                return items?.map((item: DirectoryEntry) => (
                    <FavoriteDropdownItem
                        key={item.id}
                        item={item}
                        className={element('item')}
                        instanceSlug={instanceSlug}
                    />
                ));
            }}
        </AsyncMenu>
    );
};
