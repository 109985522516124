export enum WREX_ENHANCED_IMAGE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=371741296
     */
    FULL_WIDTH = 'WREX_IMAGE.FULL_WIDTH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355125532
     */
    IMAGE_BUTTON_LABEL = 'WREX_IMAGE.BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40494619
     */
    INSERT_IMAGE = 'FRONT.TEXT_EDITOR.INSERT_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375784836
     */
    TOOLBAR = 'WREX_IMAGE.TOOLBAR',
}
