import React from 'react';

import { RadioButton, RadioButtonProps } from '@lumapps/lumx/react';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export type FormRadioButtonProps = Omit<RadioButtonProps, 'onChange' | 'isChecked'> & BaseInput;

/**
 * Component that displays a radio button and manages it inside a form.
 * @family Forms
 * @param FormRadioButtonProps
 * @returns FormRadioButton
 */
export const FormRadioButton: React.FC<FormRadioButtonProps> = ({ name, controllerProps, label, ...props }) => {
    const { valueToUse, field, getDataAttributes } = useFormField<string>({
        name,
        controllerProps,
        label,
    });
    return (
        <RadioButton
            {...getDataAttributes({ element: 'radio', action: name })}
            {...props}
            isChecked={valueToUse === props.value}
            name={name}
            label={label}
            onChange={field.onChange}
        />
    );
};
