import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiAccountPlus } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { CUSTOMER } from '../../keys';
import { globalAdmins } from '../../routes';

export interface GlobalAdministratorsSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

export const GlobalAdministratorsSideNavigation = (props: Partial<GlobalAdministratorsSideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={globalAdmins()}
            label={translateKey(CUSTOMER.GLOBAL_ADMINS)}
            icon={mdiAccountPlus}
            {...restOfProps}
        />
    );
};

GlobalAdministratorsSideNavigation.displayName = 'SideNavigationItem';
