import React from 'react';

import { Orientation } from '@lumapps/lumx/react';
import { MovingFocusOptions } from '@lumapps/moving-focus/types';
import { useResponsive } from '@lumapps/responsive';
import { TreeOpenStateContextOptions, useTreeOpenState } from '@lumapps/utils/components/TreeOpenStateContext';
import { focusNext } from '@lumapps/utils/elements/focus/focusNext';
import { focusPrevious } from '@lumapps/utils/elements/focus/focusPrevious';

import { FLYOUT_DELAY_ENTER, FLYOUT_DELAY_LEAVE } from '../constants';
import { MenuContextOptions } from '../context';
import { MenuOpenState } from '../state';

interface Output {
    /** Defines if and what item to focus on the submenu (none, first or last) */
    autofocus: MovingFocusOptions['autofocus'];
    /** Event handler props to apply to the menu trigger element. */
    triggerEventProps: {
        onBlur: React.FocusEventHandler;
        onClick: React.MouseEventHandler;
        onKeyDown: React.KeyboardEventHandler;
    };
    /** Event handler props to apply to the menu element. */
    menuEventProps: {
        onBlur: React.FocusEventHandler;
        onKeyDown: React.KeyboardEventHandler;
        onMouseOver?: React.MouseEventHandler;
        onMouseLeave?: React.MouseEventHandler;
    };
    /** Open/close state for the submenu. */
    submenuOpenState: TreeOpenStateContextOptions;
}

/**
 * Implement all required behavior (mouse & keyboard) for menu and menu trigger element.
 *
 * (excluding the keyboard nav that is handled at the menu item level).
 */
export function useMenuTrigger(
    /** Menu identifier (used as key to know which menu is open in a context) */
    menuId: string,
    /** Menu trigger element ref (Menu button or Submenu item) */
    triggerRef: React.RefObject<HTMLElement>,
    /** Menu trigger props */
    triggerProps: any,
    /** Menu props */
    menuRef: React.RefObject<HTMLElement>,
    /** Current menu open/close state */
    openState: MenuOpenState,
    /** Parent menu context */
    parentContext: MenuContextOptions | undefined,
    /** Sub-menu context */
    subContext: MenuContextOptions,
): Output {
    const { orientation = Orientation.horizontal } = parentContext || {};
    const { isRoot, closeRoot } = subContext;
    // Autofocus state for the submenu
    const [autofocus, setAutofocus] = React.useState<MovingFocusOptions['autofocus']>();

    const { isSmall } = useResponsive();

    // Init open state for the submenu
    const submenuOpenState = useTreeOpenState();

    // Reset autofocus on close
    React.useEffect(() => {
        if (!openState.isOpen) {
            setAutofocus(undefined);
        }
    }, [openState.isOpen]);

    // On Escape => close and focus trigger
    const onKeyDownEscapeClose = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (event.isPropagationStopped() || event.key !== 'Escape') {
                return;
            }
            let wasOpen: boolean | undefined;
            openState.setOpenIf((prevId) => {
                wasOpen = prevId === menuId;
                return false;
            });
            if (wasOpen) {
                triggerRef.current?.focus();
                event.stopPropagation();
            }
        },
        [menuId, openState, triggerRef],
    );

    // On blur outside the root menu => close
    const onBlur = React.useCallback(
        (event: React.FocusEvent) => {
            // Event already intercepted
            if (event.isPropagationStopped()) {
                return;
            }

            // Get previously focused menu or menubar element
            const fromMenu = event.target.closest('[role=menu], [role=menubar] [role^=menu]');
            const blurTo = event.relatedTarget;
            // Get target menu or menubar element
            const toMenu =
                blurTo instanceof HTMLElement ? blurTo.closest('[role=menu], [role=menubar] [role^=menu]') : null;

            // Not focusing outside (targeting another menu or menu element)
            if (!fromMenu || toMenu) {
                return;
            }

            // Close the root menu on focus outside
            closeRoot?.(false);
            event.stopPropagation();
        },
        [closeRoot],
    );

    // On Tab pressed, restore focus to the element after or before the trigger element
    const onKeyDownTabRestoreFocus = React.useCallback(
        (event: React.KeyboardEvent) => {
            const { activeElement } = document;

            if (
                // Not in the root menu
                !isRoot ||
                // Event already intercepted
                event.isPropagationStopped() ||
                // Not Tab pressed
                event.key !== 'Tab' ||
                // Skip if focus is not on a menu item
                !(activeElement instanceof HTMLElement) ||
                !activeElement.matches('[role^=menuitem]')
            ) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();
            closeRoot?.(true);

            if (event.shiftKey) {
                focusPrevious(document.body, triggerRef.current);
            } else {
                focusNext(document.body, triggerRef.current);
            }
        },
        [closeRoot, isRoot, triggerRef],
    );

    // Handle events on the trigger element (menu button or menu item submenu)
    const triggerEventProps = React.useMemo(() => {
        // On click => toggle open and set focus on first item
        const onClick = (event: React.MouseEvent) => {
            if (triggerRef.current?.contains(event.target as HTMLElement)) {
                openState.setOpenIf((prevId) => {
                    const wasOpen = prevId === menuId;
                    // Was open but not focus => keep open
                    if (wasOpen && !autofocus) {
                        return true;
                    }
                    // Toggle open
                    return !wasOpen;
                });
                event.stopPropagation();
                setAutofocus('first');
            }
            triggerProps?.onClick?.(event);
        };

        // Handle Arrow keys to open/close submenus (dependent on menu orientation)
        const onKeyDownArrowOpenClose = (event: React.KeyboardEvent) => {
            const target = event.target as Element;
            const isTriggerElement = target.hasAttribute('aria-haspopup');
            if (event.isPropagationStopped() || !isTriggerElement) {
                return;
            }
            if (target.getAttribute('aria-disabled') === 'true') {
                return;
            }

            // Arrow key to open the current menu
            const isArrowDown = event.key === 'ArrowDown';
            const isArrowUp = event.key === 'ArrowUp';
            const isArrowRight = event.key === 'ArrowRight';

            let shouldOpen: boolean;
            let newAutofocus: MovingFocusOptions['autofocus'];
            if (orientation === 'horizontal') {
                shouldOpen = isArrowUp || isArrowDown;
                newAutofocus = isArrowDown ? 'first' : 'last';
            } else {
                shouldOpen = isArrowRight;
                newAutofocus = 'first';
            }

            if (shouldOpen) {
                openState.open();
                setAutofocus(newAutofocus);
                event.stopPropagation();
                event.preventDefault();
            }
        };

        const onKeyDown = (event: React.KeyboardEvent) => {
            onKeyDownEscapeClose(event);
            onKeyDownTabRestoreFocus(event);
            onKeyDownArrowOpenClose(event);
        };

        return { onClick, onKeyDown, onBlur };
    }, [
        onBlur,
        triggerRef,
        openState,
        triggerProps,
        menuId,
        autofocus,
        orientation,
        onKeyDownEscapeClose,
        onKeyDownTabRestoreFocus,
    ]);

    // Handle event on menu
    const menuEventProps = React.useMemo(() => {
        // ArrowLeft on submenu => close parent menu
        const onKeyDownVerticalArrowLeftClose = (event: React.KeyboardEvent) => {
            if (isRoot || event.isPropagationStopped() || event.key !== 'ArrowLeft' || orientation !== 'vertical') {
                return;
            }
            openState.close();
            triggerRef.current?.focus();
            event.stopPropagation();
        };

        const onKeyDown = (event: React.KeyboardEvent) => {
            onKeyDownEscapeClose(event);
            onKeyDownTabRestoreFocus(event);
            onKeyDownVerticalArrowLeftClose(event);
        };

        // Wrap `setOpenedSubMenu` to call it with a delay (only one call possible at a time).
        let timeoutId: ReturnType<typeof setTimeout> | undefined;
        function delayedSetOpenedSubMenu(delay: number, menuId: string | undefined) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                submenuOpenState.setOpenedSubMenu(menuId);
            }, delay);
        }

        // On mouse overing menuitem => update the opened submenu
        const onMouseOver = !isSmall
            ? (evt: React.MouseEvent) => {
                  const target = evt.target as any;
                  if (!target.role?.startsWith('menuitem')) {
                      return;
                  }
                  if (target.getAttribute('aria-disabled') === 'true') {
                      return;
                  }
                  const { menuId } = target.dataset;

                  // Open the submenu if id available, else close the previous submenu
                  delayedSetOpenedSubMenu(FLYOUT_DELAY_ENTER, menuId);

                  evt.stopPropagation();
              }
            : undefined;

        // On mouse leave => close the submenu
        const onMouseLeave = !isSmall
            ? () => {
                  delayedSetOpenedSubMenu(FLYOUT_DELAY_LEAVE, undefined);
              }
            : undefined;

        return { onKeyDown, onBlur, onMouseOver, onMouseLeave };
    }, [
        isSmall,
        isRoot,
        onBlur,
        triggerRef,
        orientation,
        openState,
        onKeyDownEscapeClose,
        onKeyDownTabRestoreFocus,
        submenuOpenState,
    ]);

    return {
        autofocus,
        triggerEventProps,
        menuEventProps,
        submenuOpenState,
    };
}
