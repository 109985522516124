import React from 'react';

import { mdiImageBrokenVariant } from '@lumapps/lumx/icons';
import { Emphasis, ButtonProps, ColorVariant, Theme } from '@lumapps/lumx/react';
import { getBaseUrl } from '@lumapps/router';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { BaseState } from '../BaseState';

import './index.scss';

export interface ErrorPageStateProps {
    /** error code */
    code: number;
    /** whether an icon should be displayed */
    shouldShowIcon?: boolean;
    /** whether an action (normally a retry) should be displayed */
    shouldShowAction?: boolean;
    /** icon to display */
    icon?: string;
    /** additional button props */
    buttonProps?: Partial<ButtonProps>;
    /** title to be displayed */
    title?: string;
    /** description to be displayed below the title */
    description?: string;
    theme?: Theme;
}

const CLASSNAME = 'error-page';
/**
 * Page that the user will be redirected when there is an unexpected error in a navigation.
 *
 * @family States
 * @param ErrorPageStateProps
 * @returns ErrorPageState
 */
export const ErrorPageState: React.FC<ErrorPageStateProps> = ({
    code,
    shouldShowIcon,
    shouldShowAction,
    icon = mdiImageBrokenVariant,
    buttonProps = {},
    title,
    description,
    theme,
}) => {
    const { translateKey } = useTranslate();
    const pageTitle = translateKey(title || `${code}_TITLE`);
    const buttonHref = buttonProps.href ? buttonProps.href : getBaseUrl(window.location.pathname);

    return (
        <BaseState
            className={CLASSNAME}
            theme={theme}
            iconProps={shouldShowIcon ? { icon } : undefined}
            buttonProps={
                shouldShowAction
                    ? {
                          emphasis: Emphasis.medium,
                          children: translateKey(GLOBAL.GO_TO_HOMEPAGE),
                          href: buttonHref,
                          ...buttonProps,
                      }
                    : undefined
            }
            title={pageTitle}
            titleAs="h1"
            message={translateKey(description || `${code}_DESCRIPTION`)}
            messageProps={{ colorVariant: ColorVariant.N }}
        />
    );
};
