/* istanbul ignore file */
import { User } from '@lumapps/user/types';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { SEGMENT } from './keys';

export interface LocalizedString {
    value?: string;
    lang?: string;
    translations?: { [lang: string]: string };
}

export enum SegmentStatus {
    live = 'live',
    archived = 'archived',
}

/**
 * Mapped from definition
 * https://github.com/lumapps/core/blob/master/docs/segment/segment_query_definition.md
 */
export enum SegmentOperator {
    AND = 'and',
    OR = 'or',
    NOT = 'not',
    IN = 'in',
    EQ = 'eq',
    LT = 'lt',
    GT = 'gt',
    GTE = 'gte',
    LTE = 'lte',
    STARTS_WITH = 'starts_with',
    NOT_IN = 'not_in',
    NOT_ENDS_WITH = 'not_ends_with',
    EMPTY = 'empty',
    NOT_EMPTY = 'not_empty',
    CONTAINS = 'contains',
    NOT_CONTAINS = 'not_contains',
    ENDS_WITH = 'ends_with',
    IN_CSV = 'in_file',
    NOT_IN_CSV = 'not_in_file',
    UNKNOWN = 'unknown',
    IS = 'is',
    IS_NOT = 'is_not',
    IS_ON = 'is_on',
    IS_BEFORE = 'is_before',
    IS_AFTER = 'is_after',
    IS_NEVER = 'is_never',
    IS_THIS = 'is_this',
    IS_IN_THE_LAST = 'is_within_last',
    IS_NOT_IN_THE_LAST = 'is_not_within_last',
    IS_IN_THE_NEXT = 'is_within_next',
    IS_NOT_IN_THE_NEXT = 'is_not_within_next',
}

export const SUPPORTED_OPERATORS = [
    SegmentOperator.IN,
    SegmentOperator.NOT_IN,
    SegmentOperator.EMPTY,
    SegmentOperator.NOT_EMPTY,
    SegmentOperator.CONTAINS,
    SegmentOperator.NOT_CONTAINS,
    SegmentOperator.ENDS_WITH,
    SegmentOperator.NOT_ENDS_WITH,
    SegmentOperator.STARTS_WITH,
    SegmentOperator.IN_CSV,
    SegmentOperator.NOT_IN_CSV,
    SegmentOperator.UNKNOWN,
    SegmentOperator.IS,
    SegmentOperator.IS_NOT,
    SegmentOperator.IS_ON,
    SegmentOperator.IS_AFTER,
    SegmentOperator.IS_BEFORE,
    SegmentOperator.IS_NEVER,
    SegmentOperator.IS_THIS,
    SegmentOperator.IS_IN_THE_LAST,
    SegmentOperator.IS_NOT_IN_THE_LAST,
    SegmentOperator.IS_IN_THE_NEXT,
    SegmentOperator.IS_NOT_IN_THE_NEXT,
] as const;
export type SupportedOperators = (typeof SUPPORTED_OPERATORS)[number];

export type AbsoluteDateOperators =
    | SegmentOperator.IS_ON
    | SegmentOperator.IS_BEFORE
    | SegmentOperator.IS_AFTER
    | SegmentOperator.IS_NEVER;

export type RelativeDateOperators =
    | SegmentOperator.IS_THIS
    | SegmentOperator.IS_IN_THE_LAST
    | SegmentOperator.IS_NOT_IN_THE_LAST
    | SegmentOperator.IS_IN_THE_NEXT
    | SegmentOperator.IS_NOT_IN_THE_NEXT;

export enum SegmentFieldId {
    USER_GROUPS = 'user.groups',
    USER_USERS_IDS = 'user.user_ids',
    USER_PROFILE_TEXT_FIELD = 'user.profile',
    USER_ACCOUNT_CREATION = 'user.account_creation',
    USER_FIRST_SESSION = 'user.first_session',
    USER_LAST_SESSION = 'user.last_session',
}

export type SegmentFieldDataType = 'text' | 'metadata' | 'date';
export const SUPPORTED_FIELD_DATA_TYPES: SegmentFieldDataType[] = ['text', 'metadata', 'date'];

export enum TimescaleUnits {
    DAY = 'days',
    MONTH = 'months',
    YEAR = 'years',
}
export interface RelativeDate {
    quantity: number | null;
    period: TimescaleUnits;
}

export type SegmentField = { [key: string]: string | null | undefined };
export type SegmentFieldList = { [key: string]: string[] | undefined };
export type RelativeDateField = { [key: string]: RelativeDate | undefined };

type SegmentFieldOperator =
    | SegmentOperator.EQ
    | SegmentOperator.LT
    | SegmentOperator.GT
    | SegmentOperator.GTE
    | SegmentOperator.LTE;

export type SegmentFieldQuery = {
    [key in SegmentFieldOperator]?: SegmentField;
};

export type SegmentFieldListQuery = {
    [SegmentOperator.IN]: SegmentFieldList;
    [SegmentOperator.NOT]: SegmentField;
};

export type SegmentQueryBlock =
    | {
          [SegmentOperator.IN]?: SegmentFieldList;
          [SegmentOperator.NOT_IN]?: SegmentFieldList;
          [SegmentOperator.STARTS_WITH]?: SegmentField;
          [SegmentOperator.EMPTY]?: SegmentField;
          [SegmentOperator.NOT_EMPTY]?: SegmentField;
          [SegmentOperator.CONTAINS]?: SegmentField;
          [SegmentOperator.NOT_CONTAINS]?: SegmentField;
          [SegmentOperator.ENDS_WITH]?: SegmentField;
          [SegmentOperator.NOT_ENDS_WITH]?: SegmentField;
          [SegmentOperator.IN_CSV]?: SegmentField;
          [SegmentOperator.NOT_IN_CSV]?: SegmentField;
          [SegmentOperator.UNKNOWN]?: null;
          [SegmentOperator.IS]?: SegmentFieldList;
          [SegmentOperator.IS_NOT]?: SegmentFieldList;
          [SegmentOperator.IS_ON]?: SegmentFieldList;
          [SegmentOperator.IS_AFTER]?: SegmentFieldList;
          [SegmentOperator.IS_BEFORE]?: SegmentFieldList;
          [SegmentOperator.IS_NEVER]?: SegmentFieldList;
          [SegmentOperator.IS_THIS]?: RelativeDateField;
          [SegmentOperator.IS_IN_THE_LAST]?: RelativeDateField;
          [SegmentOperator.IS_NOT_IN_THE_LAST]?: RelativeDateField;
          [SegmentOperator.IS_IN_THE_NEXT]?: RelativeDateField;
          [SegmentOperator.IS_NOT_IN_THE_NEXT]?: RelativeDateField;
      }
    | undefined;

export type SegmentQueryOrBlock = SegmentQueryBlock[] | undefined[];

export type SegmentQueryAndBlock = {
    [SegmentOperator.OR]: SegmentQueryOrBlock;
};

interface SegmentUserProfileField {
    id: string;
    name: LocalizedString;
}
export interface SegmentQueryContext {
    groups: { id: string; name: string }[];
    users: User[];
    userProfileDefinitions: SegmentUserProfileField[];
    fileImports: { id: string; fileName: string }[];
    metadatas: SegmentUserProfileField[];
}

/**
 * Example:
 * {
 *   "and": [
 *      {
 *           "or": [
 *               {
 *                   "lte": {
 *                       "user.seniority": "1 month"
 *                   }
 *               },
 *               {
 *                   "eq": {
 *                       "user.profile.1234": "LumApps"
 *                   }
 *               }
 *           ]
 *       },
 *       {
 *           "or": [
 *               {
 *                   "lte": {
 *                       "user.seniority": "1 month"
 *                   }
 *               },
 *               {
 *                   "eq": {
 *                       "user.profile.1234": "LumApps"
 *                   }
 *               }
 *           ]
 *       }
 *   ]
 * }
 */
export type SegmentQuery = {
    [SegmentOperator.AND]?: Array<SegmentQueryAndBlock>;
    [SegmentOperator.IN]?: SegmentFieldList;
};

export interface UserReference {
    id: string;
    email: string;
    fullName: string;
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    locationName?: string;
    departmentName?: string;
}

export interface SegmentPreview {
    approxCount: number;
    computedAt?: string;
}

export interface SegmentDescription {
    value?: string;
    lang?: string;
    translations: Record<string, string>;
}

export enum Properties {
    PROMOTION = 'promotion',
    ANALYTICS = 'analytics',
}

export interface PropertiesList {
    promotion?: boolean;
    analytics?: boolean;
}

export interface PropertyDetails {
    name: string;
    label: string;
    helper: string;
    description: string;
    warning?: string;
    dataId: string;
}

export interface Segment {
    id?: string;
    title?: string;
    /** description of the segment - can be transleted in all supported languages of the customer */
    description?: SegmentDescription;
    /** properties that can be applied to the segment (can be used for highlight for instance) */
    isUsableFor?: PropertiesList;
    query?: SegmentQuery;

    status: SegmentStatus;

    createdById?: string;
    createdByReference?: UserReference;
    createdAt?: string;

    updatedById?: string;
    updatedByReference?: UserReference;
    updatedAt?: string;

    preview?: SegmentPreview;
}

export interface SegmentWithContext extends Segment {
    queryContext: SegmentQueryContext;
}

/**
 * Interface for the save segment callback
 */
export interface SaveSegmentParams {
    mode: 'create' | 'edit' | 'archive' | 'unarchive';
    /** Segment to create */
    segmentToSave: Segment;
    /** Site id of the segment */
    siteId: string;
    /** Callback to update the status of the fetch */
    isSavingSegment?: (status: BaseLoadingStatus) => void;
    /** Callback on create segment sucess */
    onSaved?: (createdSegment: Segment) => void;
    /** Callback on error on save segment */
    onError?: () => void;
    /** Wether to notify that the action has been successfull */
    notifySuccess?: boolean;
}

/**
 * Segment fields for selector
 */

export type FieldCode = 'user.groups' | 'user.user_ids' | string;

export enum FieldType {
    group = 'group',
    user = 'user',
    userProfile = 'user-profile',
    systemDate = 'system-date',
}

export enum FieldValueInputType {
    GROUP_PICKER = 'group-picker',
    USER_PICKER = 'user-picker',
    USER_PROFILE_TEXT_PICKER = 'user-profile-text-field',
    METADATA_PICKER = 'metadata-picker',
    DATE_PICKER = 'date-picker',
    EMPTY_DATE_PICKER = 'empty-date-picker',
    RELATIVE_DATE_PICKER = 'relative-date-picker',
    FREE_TEXT_FIELD = 'free-text-field',
    EMPTY_PICKER = 'empty-picker',
    CSV_IMPORT = 'file-picker',
    UNKNOWN = 'UNKNOWN',
}

export enum QueryContextKey {
    USER_PROFILE_DEFINITIONS = 'userProfileDefinitions',
    USERS = 'users',
    METADATAS = 'metadatas',
    DATE = 'date',
    GROUPS = 'groups',
}

export interface FieldName {
    name?: string;
    isI18n?: boolean;
}

export interface FieldOperator {
    operator: SupportedOperators;
    inputType: FieldValueInputType;
}

export interface Field {
    code: FieldCode;
    type: FieldType;
    name?: LocalizedString;
    availableOperators: FieldOperator[];
    dataType: SegmentFieldDataType;
    metadataFamilyId?: string;
    icon?: string;
}

export interface RelativeDateFieldValue {
    id: RelativeDate;
    value: RelativeDate;
}

export interface FieldValue {
    id: string;
    value: string;
    data?: User;
    metadataData?: SegmentUserProfileField;
}

export type FieldValueType = RelativeDateFieldValue | FieldValue;

export interface FieldValues {
    code: FieldCode;
    availableValues: FieldValue[];
}

export enum SegmentNotification {
    create = SEGMENT.SEGMENT_CREATED_NOTIF,
    edit = SEGMENT.SEGMENT_EDITED_NOTIF,
    archive = SEGMENT.SEGMENT_ARCHIVED_NOTIF,
    unarchive = SEGMENT.SEGMENT_UNARCHIVED_NOTIF,
}

export enum SegmentExportStatus {
    LOADING = 'loading',
    FINISHED = 'finished',
    FAILED = 'failed',
    INITIAL = 'initial',
}

export interface SegmentCsvUploadError {
    line: number;
    message: string;
}

export interface Criteria {
    id: string;
    values?: FieldValueType[];
    attribute?: Field;
    operator?: FieldOperator;
}

export interface OrGroup {
    id: string;
    orBlock?: SegmentQueryOrBlock;
}

export type OperatorsWithSubheader = SegmentOperator.IS_ON | SegmentOperator.IS_THIS;

// Use typeguards to check fieldValue types
export const isRelativeDateFieldValue = (
    field: FieldValue | RelativeDateFieldValue,
): field is RelativeDateFieldValue => {
    return (field as RelativeDateFieldValue).id.period !== undefined;
};

export const isRelativeDate = (field: string | string[] | RelativeDate | User | undefined): field is RelativeDate => {
    return (field as RelativeDate)?.period !== undefined;
};

export const isTextFieldValue = (field: FieldValue | RelativeDateFieldValue): field is FieldValue => {
    return typeof (field as FieldValue).id === 'string';
};
