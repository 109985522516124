/* global angular,_APPLICATION_HOST_PROCESS */
/* eslint-disable no-underscore-dangle */
import noop from 'lodash/noop';

import { CUSTOMER_LICENSE_TYPES, CUSTOMER_STATUS } from '@lumapps/customer/constants';
import { DEFAULT_API_PROFILE_FIELD_PATTERNS } from '@lumapps/user/constants';
import { DEFAULT_FROALA_HTML_TOOLBAR } from '@lumapps/wrex-legacy-froala/constants';

export const applicationHost = `${window.location.protocol}//${window.location.host}`;

export const ConfigConstant = {
    ACCOUNT_TYPES: [
        {
            help: 'ADMIN.ACCOUNT_TYPE.EXTERNAL_HELP',
            name: 'ADMIN.ACCOUNT_TYPE.EXTERNAL',
            value: 'external',
        },
        {
            name: undefined,
            value: 'google',
        },
    ],
    APPLICATION_HOST: applicationHost,
    HAUSSMANN_HOST: window.IS_DIRECT_HAUSSMANN_CELL_CALL_ENABLED === 'True' ? window.HAUSSMANN_CELL : applicationHost,
    APPLICATION_HOST_PROCESS: window._APPLICATION_HOST_PROCESS,
    ATTACHMENT_TYPE: {
        FILE: 'file',
        IMAGE: 'image',
        LINK: 'link',
    },
    AVAILABLE_API_PROFILE_FIELD: [
        {
            name: 'API_PROFILE_FIELD_TITLE',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.title,
        },
        {
            name: 'API_PROFILE_FIELD_PRIMARY_ORGANIZATIONS_NAME',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.primaryOrganizationsName,
        },
        {
            name: 'API_PROFILE_FIELD_PRIMARY_ORGANIZATIONS_LOCATION',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.primaryOrganizationsLocation,
        },
        {
            name: 'API_PROFILE_FIELD_PHONES_WORK',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.phonesWork,
        },
        {
            name: 'API_PROFILE_FIELD_PHONES_PERSONAL',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.phonesPersonal,
        },
        {
            name: 'API_PROFILE_FIELD_EMAILS_PRIMARY',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.emailsPrimary,
        },
        {
            name: 'API_PROFILE_FIELD_RELATIONS_MANAGER',
            text: DEFAULT_API_PROFILE_FIELD_PATTERNS.relationsManager,
        },
    ],
    AVAILABLE_CONTENT_TYPES: undefined,
    CHAT_FIELDS: ['provider', 'group', 'channel'],
    CHART_FIELDS: ['fileId, graphType'],
    COMMUNITY_FIELDS: ['members', 'title', 'content', 'follow', 'thumbnail'],
    COMMUNITY_FILTERS: ['query', 'instance'],
    CONTENT_DEFAULT_STATUS: 'DRAFT',
    CONTENT_FIELDS: ['author', 'publication-date', 'tags', 'title', 'excerpt', 'metadata', 'social'],
    CONTENT_FILTERS: [
        'query',
        'instance',
        'custom-content-type',
        'tags',
        'metadata',
        'author',
        'dates',
        'feature-feeds',
    ],
    CONTENT_STATUS: {
        ARCHIVE: {
            condition: {
                isWorkflowEnabled: true,
            },
            level: 4,
            name: 'CONTENT_STATUS_ARCHIVE',
            value: 'ARCHIVE',
        },
        DRAFT: {
            condition: {
                isWorkflowEnabled: true,
            },
            level: 1,
            name: 'CONTENT_STATUS_DRAFT',
            value: 'DRAFT',
        },
        LIVE: {
            condition: {
                isWorkflowEnabled: true,
                userIsWorkflowManager: true,
            },
            level: 3,
            name: 'CONTENT_STATUS_LIVE',
            value: 'LIVE',
        },
        TO_VALIDATE: {
            condition: {
                isWorkflowEnabled: true,
            },
            level: 2,
            name: 'CONTENT_STATUS_TO_VALIDATE',
            value: 'TO_VALIDATE',
        },
        UNARCHIVE: {
            condition: {
                isWorkflowEnabled: true,
            },
            level: 4,
            name: 'CONTENT_STATUS_UNARCHIVE',
            value: 'UNARCHIVE',
        },
    },
    CUSTOMER_LICENSE_TYPES,
    CUSTOMER_STATUS,
    DEFAULT_FROALA_HTML_TOOLBAR,
    DEFAULT_LOGO: '/assets/img/common/logo.svg',
    DELVE_FIELDS: ['contentType', 'displayConnectedUser', 'provider', 'user'],
    DEVELOPER_KEY: 'AIzaSyDoaJ3V2HvYATFHs-wBjJAmt4XyFoaaHUk',
    DIRECTORY_FILTERS: ['query', 'directory-tags', 'metadata'],
    DRIVE: {
        clientId: '707106531947-5b60tirb7tb1pabet4r3onmhaaij0jdo.apps.googleusercontent.com',
        scope: ['https://www.googleapis.com/auth/drive.file'],
    },
    FEED_PARAMS: ['LUMAPPS_ALL', 'LUMAPPS_PUBLIC'],
    FEEDTYPE_ICONS: [
        'account',
        'home',
        'briefcase',
        'chart-bar',
        'folder',
        'desktop-mac',
        'heart',
        'star',
        'settings',
        'printer',
        'view-module',
    ],
    FILE_FIELDS: ['thumbnail', 'name', 'tags', 'description'],
    FILE_TYPES: {
        DOCUMENT: ['document', 'doc', 'file', 'application/pdf'],
        DRIVE: ['drive'],
        IMAGE: ['image'],
        OTHER: ['other'],
        VIDEO: ['video'],
        XML: ['text/xml'],
    },
    G_RECAPTCHA_KEY: '6LfSkhsTAAAAAK9gMWpqAHGkLfCwyRTVTBpMe87C',
    IMAGE_GALLERY_FIELDS: ['name', 'description'],
    INSTANCE_FIELDS: ['title', 'name', 'subtitle', 'metadata'],
    INSTANCE_FILTERS: ['query', 'metadata'],
    INSTANCE_PROPERTIES: {
        AVAILABLE_LANGS: {
            key: 'AVAILABLE_LANGS',
        },
        BROWSER_TRANSLATION: {
            key: 'BROWSER_TRANSLATION',
        },
        CHART_FIELDS: {
            key: 'CHART_FIELDS',
        },
        CHAT_FIELDS: {
            key: 'CHAT_FIELDS',
        },
        COMMUNITY_FILTERS: {
            key: 'COMMUNITY_FILTERS',
        },
        CONTENT_FIELDS: {
            key: 'CONTENT_FIELDS',
        },
        CONTENT_FILTERS: {
            key: 'CONTENT_FILTERS',
        },
        CONTENT_LIST_EXCERPT: {
            key: 'CONTENT_LIST_EXCERPT',
        },
        CONTENT_LIST_LINK_OPEN_NEW_TAB: {
            key: 'CONTENT_LIST_LINK_OPEN_NEW_TAB',
        },
        BLOCK_XSS_HTML_TAGS: {
            key: 'BLOCK_XSS_HTML_TAGS',
        },
        DELVE_FIELDS: {
            key: 'DELVE_FIELDS',
        },
        DIRECTORY_FILTERS: {
            key: 'DIRECTORY_FILTERS',
        },
        DISPLAY_LANGUAGE_SELECTOR: {
            key: 'DISPLAY_LANGUAGE_SELECTOR',
        },
        FALLBACK_LANGS: {
            key: 'FALLBACK_LANGS',
        },
        FILE_FIELDS: {
            key: 'FILE_FIELDS',
        },
        IMAGE_GALLERY_FIELDS: {
            key: 'IMAGE_GALLERY_FIELDS',
        },
        INSTANCE_FIELDS: {
            key: 'INSTANCE_FIELDS',
        },
        INSTANCE_FILTERS: {
            key: 'INSTANCE_FILTERS',
        },
        META_FIELDS: {
            key: 'META_FIELDS',
        },
        META_SOCIAL_FIELDS: {
            key: 'META_SOCIAL_FIELDS',
        },
        POST_FIELDS: {
            key: 'POST_FIELDS',
        },
        POST_FILTERS: {
            key: 'POST_FILTERS',
        },
        SHAREPOINT_SITE_FIELDS: {
            key: 'SHAREPOINT_SITE_FIELDS',
        },
        SOCIAL_SETTINGS: {
            key: 'SOCIAL_SETTINGS',
        },
        TASKS_FIELDS: {
            key: 'TASKS_FIELDS',
        },
        USER_BLOCK_DEFAULT_FIELD: {
            key: 'USER_BLOCK_DEFAULT_FIELD',
        },
        USER_BLOCK_USER_DIRECTORY_FIELD: {
            key: 'USER_BLOCK_USER_DIRECTORY_FIELD',
        },
        WALL_FIELDS: {
            key: 'WALL_FIELDS',
        },
        WYSIWYG_PASTEPLAIN: {
            key: 'WYSIWYG_PASTEPLAIN',
        },
        WYSIWYG_TOOLBAR: {
            key: 'WYSIWYG_TOOLBAR',
        },
    },
    META_FIELDS: ['author', 'publication-date', 'tags', 'metadata', 'social'],
    META_SOCIAL_FIELDS: ['like', 'comment', 'follow'],
    POST_FIELDS: ['sharedInCommunities', 'metadata', 'title', 'dates', 'content', 'attachments', 'comments'],
    POST_FILTERS: ['query', 'instance', 'post-type', 'community-tags', 'post-status', 'relevant-comment', 'author'],
    QUEST_STATUS: ['DRAFT', 'PENDING', 'LIVE', 'ARCHIVE', 'DELETED'],
    REPORT_OPEN_ANSWER_LIMIT: 20,
    SHAREPOINT_SITE_FIELDS: ['thumbnail', 'name', 'description'],
    SOCIAL_SETTINGS: ['multiposting_allowed'],
    TAG_TYPE: {
        COMMUNITY: 'community',
        MEDIA: 'media',
    },
    TASKS_FIELDS: ['provider', 'isTeamScoped', 'alreadyDoneDay', 'community', 'plan', 'bucket'],
    USER_CREATE_DEFAULT_ACCOUNT_TYPE: 'external',
    USER_DIRECTORY_HIDDEN_FIELD: [],
    USER_DIRECTORY_IGNORED_FIELD: ['primaryEmail', 'emails[primary=true]/address'],
    USER_DIRECTORY_INIT_FEED: 'LUMAPPS_ALL',
    USER_DIRECTORY_INIT_FIELD: [
        {
            icon: 'bank',
            listDisplay: false,
            name: 'API_PROFILE_FIELD_TITLE',
        },
        {
            icon: 'clipboard-account',
            listDisplay: true,
            name: 'API_PROFILE_FIELD_PRIMARY_ORGANIZATIONS_NAME',
        },
        {
            icon: 'city',
            listDisplay: false,
            name: 'API_PROFILE_FIELD_PRIMARY_ORGANIZATIONS_LOCATION',
        },
        {
            icon: 'phone',
            listDisplay: true,
            name: 'API_PROFILE_FIELD_PHONES_WORK',
        },
        {
            icon: 'phone',
            listDisplay: false,
            name: 'API_PROFILE_FIELD_PHONES_PERSONAL',
        },
        {
            icon: 'email',
            listDisplay: true,
            name: 'API_PROFILE_FIELD_EMAILS_PRIMARY',
        },
    ],
    USER_DIRECTORY_REGEX_DEFAULT: [
        {
            name: 'DEFAULT_PATTERN_EMAIL',
            // eslint-disable-next-line max-len, no-useless-escape
            pattern:
                "/^[-a-z0-9~!$%^&*_=+}{'?]+(.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(.[-a-z0-9_]+)*.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,5})?$/i",
        },
    ],
    USER_STATUS: [
        {
            label: 'USER_STATUS_ENABLED',
            value: 'enabled',
        },
        {
            label: 'DISABLED',
            value: 'disabled',
        },
    ],
    WALL_FIELDS: ['contentType', 'displayConnectedUser', 'provider', 'user'],
    WIDGET_DRIVE_DEFAULT: {
        copyName: 'WIDGET_TYPE_FILE_MANAGEMENT_COPY_OF',
        newFolderName: 'WIDGET_TYPE_FILE_MANAGEMENT_NEW_FOLDER_NAME',
        spaceName: 'WIDGET_TYPE_FILE_MANAGEMENT_DEFAULT_SPACE_NAME',
    },
    WIDGET_DRIVE_FILE_CREATION: [
        {
            icon: 'document',
            mimeType: 'application/vnd.google-apps.document',
            title: 'WIDGET_TYPE_FILE_MANAGEMENT_GOOGLE_DOCUMENT',
        },
        {
            icon: 'spreadsheet',
            mimeType: 'application/vnd.google-apps.spreadsheet',
            title: 'WIDGET_TYPE_FILE_MANAGEMENT_GOOGLE_SPREADSHEET',
        },
        {
            icon: 'presentation',
            mimeType: 'application/vnd.google-apps.presentation',
            title: 'WIDGET_TYPE_FILE_MANAGEMENT_GOOGLE_PRESENTATION',
        },
    ],
    WIDGET_DRIVE_PERMISSION: {
        read: ['commenter', 'organizer', 'owner', 'reader', 'writer'],
        write: ['organizer', 'owner', 'writer'],
    },
};

try {
    if (angular) {
        angular.module('Constants').constant('Config', ConfigConstant);
    }
} catch (e) {
    noop(e);
}
