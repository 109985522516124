import React from 'react';

import { adminContentList } from '@lumapps/content-lists/routes';
import { adminContentTag } from '@lumapps/content-tags/routes';
import { adminContentTemplates } from '@lumapps/content-templates/routes';
import { adminContent } from '@lumapps/contents/routes';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useTranslate } from '@lumapps/translations';

import { CONTENT_TYPES } from '../../keys';
import { CustomContentType } from '../../types';
import { LoadingCustomContentTypes } from '../LoadingCustomContentTypes';

export interface CustomContentTypesSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** Determines whether we are currently fetching the list or not */
    isLoading?: boolean;
    /** the list of parent custom content types to display */
    parentCustomContentTypes?: CustomContentType[];
    /** True if the current user is super admin */
    isSuperAdmin: boolean;
    /** True if the user is allowed to see the custom content types */
    isAllowed: boolean;
    /** Key/value object where the key is the id of the custom content type and the value is true/false */
    allowedCustomContentTypes: Record<string, boolean>;
}

const CustomContentTypesSideNavigation = (props: CustomContentTypesSideNavigationProps) => {
    const {
        isLoading = false,
        parentCustomContentTypes,
        isSuperAdmin = false,
        allowedCustomContentTypes = {},
        isAllowed,
    } = props;
    const { translateKey, translateObject } = useTranslate();
    const hasAccess = isAllowed || isSuperAdmin;

    if (!hasAccess) {
        return null;
    }

    if (isLoading || !parentCustomContentTypes) {
        return <LoadingCustomContentTypes />;
    }

    /**
     * If the user is super admin, we want to allow them to access all the different sections of this
     * menu. There is no restriction on which custom content type they are available to see, so we
     * render all of them with no restrictions
     */
    if (isSuperAdmin) {
        return (
            <>
                {parentCustomContentTypes.map((parentCustomContentType) => (
                    <SideNavigationItem
                        label={translateObject(parentCustomContentType.name) || ''}
                        key={parentCustomContentType.id}
                        dynamicIcon={parentCustomContentType.icon}
                        id={`custom-content-type-${parentCustomContentType.id}`}
                    >
                        <SideNavigationItem
                            label={translateKey(CONTENT_TYPES.CONTENTS)}
                            route={adminContent(parentCustomContentType.id)}
                        />
                        <SideNavigationItem
                            label={translateKey(CONTENT_TYPES.TAGS)}
                            route={adminContentTag(parentCustomContentType.id)}
                        />
                        <SideNavigationItem
                            label={translateKey(CONTENT_TYPES.TEMPLATES)}
                            route={adminContentTemplates(parentCustomContentType.id)}
                        />
                        <SideNavigationItem
                            label={translateKey(CONTENT_TYPES.LISTS)}
                            route={adminContentList(parentCustomContentType.id)}
                        />
                    </SideNavigationItem>
                ))}
            </>
        );
    }

    /**
     * If the user is not super admin, we need to check the different acceses and roles that they have. If they
     * have the authority to manage a specific type of custom content, we will render the section, but we will
     * just render the Content view, and not the other links, those are just reserved for the admin.
     */
    return (
        <>
            {parentCustomContentTypes.map((parentCustomContentType) => {
                if (allowedCustomContentTypes[parentCustomContentType.id]) {
                    return (
                        <SideNavigationItem
                            label={translateObject(parentCustomContentType.name) || ''}
                            key={parentCustomContentType.id}
                            dynamicIcon={parentCustomContentType.icon}
                            route={adminContent(parentCustomContentType.id)}
                        />
                    );
                }

                return null;
            })}
        </>
    );
};

CustomContentTypesSideNavigation.displayName = 'SideNavigationItem';

export { CustomContentTypesSideNavigation };
