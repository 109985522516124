import React, { useMemo } from 'react';

import get from 'lodash/fp/get';
import uniqueId from 'lodash/uniqueId';

import { classnames } from '@lumapps/classnames';
import { useTranslate } from '@lumapps/translations';
import { CustomRadioList } from '@lumapps/widget-base/components/WidgetSettings/CustomRadioList';
import { ViewModeBlock } from '@lumapps/widget-base/components/WidgetSettings/ViewModeBlock';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { VIEW_MODE_LIST_VARIANTS } from '@lumapps/widget-settings/constants';
import { ViewModeType } from '@lumapps/widget-settings/types';

import { WidgetPlaylistProperties, PlaylistViewModeVariant } from '../../../types';

export type PlaylistVariant = Pick<WidgetPlaylistProperties, 'viewModeVariant'>;

export interface PlaylistVariantsProps {
    className?: string;
    properties: PlaylistVariant;
    onChange: (properties: PlaylistVariant) => void;
}

const CLASSNAME = 'playlist-variants';

/**
 *
 * @param PlaylistVariantsProps
 * @returns PlaylistVariants
 */
export const PlaylistVariants = ({ className, properties, onChange }: PlaylistVariantsProps) => {
    const { translateKey } = useTranslate();
    const viewVariantLabelId = useMemo(() => uniqueId(`${CLASSNAME}-view-variant-label-`), []);
    const selectedViewModeVariant =
        VIEW_MODE_LIST_VARIANTS.find((view) => view.value === properties.viewModeVariant) || VIEW_MODE_LIST_VARIANTS[0];

    return (
        <div className={classnames(className, CLASSNAME)}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <h4 className="lumx-typography-subtitle1 lumx-spacing-margin-bottom-huge">
                {translateKey(WIDGET_BASE.SETTINGS_VARIANTS)}
            </h4>
            <CustomRadioList
                aria-labelledby={viewVariantLabelId}
                items={VIEW_MODE_LIST_VARIANTS.map((viewMode) => {
                    return { viewMode };
                })}
                dataScope={CLASSNAME}
                itemRenderer={ViewModeBlock}
                itemKeySelector={get('viewMode.value')}
                selectedItem={{ viewMode: selectedViewModeVariant }}
                setSelectedItem={(item: { viewMode: ViewModeType<PlaylistViewModeVariant> }) =>
                    onChange({
                        ...properties,
                        viewModeVariant: item.viewMode.value,
                    })
                }
            />
        </div>
    );
};
