import React from 'react';

import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import ResizeObserver from 'resize-observer-polyfill';

import { classnames, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiDotsHorizontal } from '@lumapps/lumx/icons';
import { Placement, Popover, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { ViewMode } from '../../constants';
import { CLASSNAME as ITEM_CLASSNAME, NavigationItem } from '../NavigationItem';
import './index.scss';

export interface NavigationProps {
    /** Content of the navigation. These components should be of type NavigationItem to be rendered */
    children?: React.ReactNode;
    /** Classname that will be used for the nav wrapping element */
    className?: string;
    /** the scope used to create data attributes */
    scope?: string;
    /** Theme that will be applied to the element, either Theme.dark or Theme.light */
    theme?: Theme;
    /** The view mode that will be used to display the nav, either 'horizontal' or 'vertical', defaults to 'vertical' */
    viewMode?: ViewMode;
}

const CLASSNAME = 'navigation';
const MORE_INDEX = 'more';

/**
 * Reusable component for displaying a non-editable navigation.
 *
 * @family Layouts
 * @param NavigationProps
 * @returns Navigation
 */
export const Navigation: React.FunctionComponent<NavigationProps> = ({
    children,
    className,
    scope,
    theme,
    viewMode,
    ...navigationForwardedProps
}) => {
    const childrenArray = React.Children.toArray(children);
    const { get } = useDataAttributes(scope || CLASSNAME);
    const { block } = useClassnames(CLASSNAME);
    const { element } = useClassnames(ITEM_CLASSNAME);
    const isHorizontalViewMode = viewMode === ViewMode.horizontal;
    const isDarkTheme = theme === Theme.dark;

    const { translate } = useTranslate();

    const [visibilityMap, setVisibilityMap] = React.useState<Record<string, boolean>>({});
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [ulElement, setUlElement] = React.useState<HTMLUListElement | null>(null);

    const moreRef = React.useRef<HTMLLIElement>(null);

    const widthMap = React.useMemo((): Record<string, number> => {
        if (!isHorizontalViewMode || !ulElement) {
            return {};
        }

        const items = Array.from(ulElement.children) as HTMLElement[];

        return items.reduce((acc, item) => ({ ...acc, [item.dataset.itemIndex as string]: item.offsetWidth }), {});
        // moreRef.current is in the deps to make sure widthMap gets recomputed after switching from vertical to horizontal
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moreRef.current, isHorizontalViewMode, ulElement]);

    const handleResize = React.useMemo(() => {
        if (!isHorizontalViewMode || !ulElement) {
            return null;
        }

        let prevWidth = 0;

        return throttle(() => {
            let availableWidth = ulElement.clientWidth;

            // we don't want to execute the code if the width has not changed
            if (availableWidth === prevWidth) {
                return;
            }
            prevWidth = availableWidth;

            const { columnGap } = getComputedStyle(ulElement);
            const columnGapWidth = parseInt(columnGap, 10) || 0;

            const newVisibilityMap: Record<string, boolean> = {};

            Object.keys(widthMap)
                .filter((key) => key !== MORE_INDEX)
                .map((key) => parseInt(key, 10))
                .sort((a, b) => a - b)
                .forEach((key, index) => {
                    const itemsCount = Object.keys(widthMap).length - 1;

                    const itemWidth = widthMap[key];
                    const moreWidth = widthMap[MORE_INDEX];

                    const isItemVisible =
                        index < itemsCount - 1
                            ? availableWidth - (itemWidth + columnGapWidth + moreWidth) >= 0
                            : availableWidth - itemWidth >= 0;

                    newVisibilityMap[key] = isItemVisible;
                    newVisibilityMap[MORE_INDEX] = !isItemVisible;

                    availableWidth = isItemVisible ? availableWidth - (itemWidth + columnGapWidth) : 0;
                });

            setVisibilityMap((prevVisibilityMap) =>
                isEqual(prevVisibilityMap, newVisibilityMap) ? prevVisibilityMap : newVisibilityMap,
            );
            setIsPopoverOpen(false);
        }, 100);
    }, [isHorizontalViewMode, ulElement, widthMap]);

    React.useEffect(() => {
        if (!isHorizontalViewMode || !handleResize || !ulElement) {
            return undefined;
        }

        handleResize();

        // window as any is here because our current version of TS doesn't support ResizeObserver yet
        const observer = new ResizeObserver(handleResize);

        observer.observe(ulElement);

        return () => observer.unobserve(ulElement);
    }, [isHorizontalViewMode, handleResize, ulElement]);

    return (
        <nav className={className} {...navigationForwardedProps}>
            <ul
                className={block({
                    dark: isDarkTheme,
                    horizontal: isHorizontalViewMode,
                })}
                ref={setUlElement}
            >
                {childrenArray.map((child, index) =>
                    // istanbul ignore next
                    React.isValidElement(child)
                        ? React.cloneElement(child as React.ReactElement, {
                              className: classnames(child?.props?.className, {
                                  [`${ITEM_CLASSNAME}--hidden`]: isHorizontalViewMode && !visibilityMap[index],
                              }),
                              'data-item-index': index,
                              scope,
                              theme,
                              viewMode,
                          })
                        : null,
                )}
                {isHorizontalViewMode ? (
                    <NavigationItem
                        aria-controls="more-popover"
                        aria-expanded={isPopoverOpen}
                        scope={scope}
                        element="item-more"
                        label={translate(GLOBAL.MORE)}
                        icon={mdiDotsHorizontal}
                        className={element('more', {
                            hidden: !visibilityMap[MORE_INDEX],
                        })}
                        data-item-index={MORE_INDEX}
                        ref={moreRef}
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        theme={theme}
                    />
                ) : null}
            </ul>
            {isHorizontalViewMode ? (
                <Popover
                    id="more-popover"
                    anchorRef={moreRef}
                    isOpen={isPopoverOpen}
                    placement={Placement.BOTTOM_START}
                    usePortal={false}
                    closeOnClickAway
                    closeOnEscape
                    onClick={() => setIsPopoverOpen(false)}
                    onClose={() => setIsPopoverOpen(false)}
                    onKeyUp={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                            setIsPopoverOpen(false);
                        }
                    }}
                    zIndex={996}
                    {...get({ element: 'more-popover' })}
                >
                    <ul className={block('popover')}>
                        {childrenArray.map((child, index) =>
                            !visibilityMap[index] && React.isValidElement(child)
                                ? React.cloneElement(child as React.ReactElement, {
                                      scope,
                                      theme: Theme.light,
                                      viewMode: ViewMode.vertical,
                                  })
                                : null,
                        )}
                    </ul>
                </Popover>
            ) : null}
        </nav>
    );
};
Navigation.displayName = 'Navigation';
