import debounce from 'lodash/debounce';

import { lumappsAnalyticsPlugin } from '@lumapps/analytics-tracking/tracking';
import {
    LumappsTrackEventPayload,
    SearchDocumentType,
    TargetType,
    TrackingEventType,
} from '@lumapps/analytics-tracking/types';
import BaseApi from '@lumapps/base-api';
import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { queueEvent, Event, TOPICS } from '@lumapps/metric/analytics';
import { sendGA4SearchEvent } from '@lumapps/metric/hooks';

import { getAnalyticsCause } from '../constants/analytics';
import {
    TrackSearchData,
    TrackDocumentData,
    TrackCustomEventData,
    SearchEvent,
    SearchClickEvent,
    BackendSearchSort,
} from '../types';
import { convertDocumentTypeForAnalytics } from '../utils/convertDocumentTypeForAnalytics';
import { getDefaultSort } from '../utils/getDefaultSort';
import { setSearchType } from '../utils/setSearchType';

export const analyticsApi = new BaseApi({ path: 'search/analytics', version: BaseApi.versions.v2 });

const trackEvent = (events: TrackSearchData[]) => {
    try {
        return analyticsApi.post(
            '/events',
            { events },
            {
                validateStatus: (status: number) => status !== 400,
            },
        );
    } catch (err) {
        // eslint-disable-next-line no-console
        return console.error('An error occurred while sending tracking event', { events, err });
    }
};

/**
 * Postpone the request to GA4 endpoint
 * @param data A search event
 */
export const queueGA4SearchEvent = (data: TrackSearchData) => {
    const payload = { ...data, actionCause: getAnalyticsCause(data.actionCause) };
    queueEvent({ payload, topic: TOPICS.GOOGLE_ANALYTICS }, (events: Event[]) => {
        for (const event of events) {
            const data = event.payload as TrackSearchData;
            const searchTerm = data.queryText;
            sendGA4SearchEvent(searchTerm);
        }
    });
};

/**
 * Postpone the request to search analytics endpoint
 * @param data A search event
 */
export const queueTrackSearch = (data: TrackSearchData) => {
    const payload = { ...data, actionCause: getAnalyticsCause(data.actionCause) };

    queueEvent({ payload, topic: TOPICS.SEARCH_ANALYTICS }, (events: Event[]) =>
        trackEvent(events.map((event) => event.payload)),
    );
};

/**
 * Postpone the request to search analytics endpoint
 * Used to track document click
 * @param data A search event
 */
export const queueTrackDocument = (data: TrackDocumentData) => {
    queueEvent({ payload: data, topic: TOPICS.SEARCH_ANALYTICS }, (events: Event[]) =>
        trackEvent(events.map((event) => event.payload)),
    );
};

/**
 * Track custom events such as facetSearch or things not directly linked to a search
 * @param data TrackCustomEventData
 */
export const queueTrackCustomEvent = (data: TrackCustomEventData) => {
    queueEvent({ payload: data, topic: TOPICS.SEARCH_ANALYTICS }, (events: Event[]) =>
        trackEvent(events.map((event) => event.payload)),
    );
};

export const sendTrackingEvent = (
    {
        currentFacets,
        engine,
        promotedIds,
        query,
        resultCountExact,
        filter,
        sort,
        actionCause,
        extensionId,
    }: Pick<
        SearchEvent,
        | 'engine'
        | 'promotedIds'
        | 'resultCountExact'
        | 'query'
        | 'sort'
        | 'currentFacets'
        | 'filter'
        | 'actionCause'
        | 'extensionId'
    >,
    sortOrders?: BackendSearchSort[] | null,
) => {
    lumappsAnalyticsPlugin.track({
        payload: {
            action: {
                type: setSearchType(actionCause),
                query,
                filters: currentFacets,
                resultCount: resultCountExact,
                sortedBy: sort || getDefaultSort(sortOrders),
                tabId: filter,
                searchEngine: engine,
                promotedIds,
                extensionId,
            },
        },
    });
};

/**
 * From a given payload send all the events related to search actions
 */
export const searchSendEvents = (
    {
        query,
        resultCountExact,
        actionCause,
        actionsHistory,
        context,
        currentFacets,
        currentLanguage,
        currentPage,
        customData,
        responseTime,
        searchQueryUid,
        splitTestRunName,
        splitTestRunVersion,
        engine,
        extensionId,
        sort,
        filter,
        promotedIds,
    }: SearchEvent,
    isCoveoAnalytics: boolean,
    isCoveoEnabled: boolean,
    sortOrders?: BackendSearchSort[],
) => {
    const genericEventPayload: TrackSearchData = {
        splitTestRunName,
        splitTestRunVersion,
        language: currentLanguage,
        actionCause,
        numberOfResults: resultCountExact || 0,
        queryText: query,
        pageNumber: currentPage,
        responseTime,
        searchQueryUid,
        context,
        actionsHistory,
        customData,
    };
    queueGA4SearchEvent(genericEventPayload);
    lumappsAnalyticsPlugin.track({
        payload: {
            action: {
                type: setSearchType(actionCause),
                query,
                filters: isCoveoEnabled ? { ...currentFacets, pageNumber: currentPage.toString() } : currentFacets,
                resultCount: resultCountExact,
                sortedBy: sort || getDefaultSort(sortOrders),
                tabId: filter,
                searchEngine: engine,
                extensionId,
                promotedIds,
            },
        },
    });
    if (isCoveoAnalytics) {
        queueTrackSearch(genericEventPayload);
    }
};

/**
 * From a given payload send all the events related to search click actions
 */

export const searchClickSendEvents = (
    {
        actionsHistory,
        additionalInformation,
        context,
        documentTitle,
        language,
        query,
        searchQueryUid,
        targetType,
        collectionName,
        contentIDKey,
        contentIDValue,
        documentUri,
        documentUriHash,
        isPromoted,
        position,
        rankingModifier,
        resultCount = 0,
        sourceName,
        tabId,
        targetId,
        actionCause,
        type,
        thumbnail,
        documentUrl,
        extensionId,
        searchEngine,
    }: SearchClickEvent,
    isCoveoAnalytics: boolean,
) => {
    lumappsAnalyticsPlugin.track({
        payload: {
            action: {
                type,
                position,
                isPromoted,
                query,
                targetType: convertDocumentTypeForAnalytics(targetType),
                targetId,
                resultCount,
                tabId,
                url: documentUrl,
                title: documentTitle,
                thumbnail,
                extensionId,
                searchEngine,
            },
        },
    });
    if (isCoveoAnalytics) {
        queueTrackDocument({
            ...additionalInformation,
            actionCause,
            collectionName,
            documentPosition: position,
            documentTitle,
            documentUri,
            documentUriHash,
            documentUrl: documentUri,
            language,
            sourceName,
            searchQueryUid,
            rankingModifier,
            customData: {
                contentIDKey,
                contentIDValue,
                documentTitle,
                documentUrl: documentUri,
            },
            context,
            actionsHistory,
        });
    }
};

interface SearchClickEvents {
    searchClick?: { event: SearchClickEvent; isCoveoAnalytics: boolean };
    clickAction?: {
        targetType: SearchDocumentType;
        targetId: string;
        url: string;
        title: string;
        thumbnail?: string;
    };
}

export const sendSearchClickBatchedEvents = (clickEvents: SearchClickEvents) => {
    // We need a function which handle search_click events and click_action events (such as directory entries clicks in search)
    // We dont want to call the tracking endpoint multiple times so we need to send batched events

    const payload: LumappsTrackEventPayload[] = [];

    const { searchClick, clickAction } = clickEvents;

    if (searchClick) {
        const { isCoveoAnalytics, event } = searchClick;

        const {
            type,
            position,
            isPromoted,
            query,
            targetId,
            resultCount = 0,
            tabId,
            thumbnail,
            targetType,
            documentUrl,
            documentTitle,
            additionalInformation,
            actionCause,
            collectionName,
            documentUri,
            documentUriHash,
            language,
            sourceName,
            searchQueryUid,
            rankingModifier,
            contentIDKey,
            contentIDValue,
            context,
            actionsHistory,
            extensionId,
            searchEngine,
        } = event;

        const searchClickEventPayload: LumappsTrackEventPayload = {
            action: {
                type,
                position,
                isPromoted,
                query,
                targetId,
                resultCount,
                tabId,
                thumbnail,
                targetType: convertDocumentTypeForAnalytics(targetType),
                url: documentUrl,
                title: documentTitle,
                extensionId,
                searchEngine,
            },
        };

        if (isCoveoAnalytics) {
            queueTrackDocument({
                ...additionalInformation,
                actionCause,
                collectionName,
                documentPosition: position,
                documentTitle,
                documentUri,
                documentUriHash,
                documentUrl: documentUri,
                language,
                sourceName,
                searchQueryUid,
                rankingModifier,
                customData: {
                    contentIDKey,
                    contentIDValue,
                    documentTitle,
                    documentUrl: documentUri,
                },
                context,
                actionsHistory,
            });
        }

        payload.push(searchClickEventPayload);
    }

    if (clickAction) {
        const targetType = convertDocumentTypeForAnalytics(clickAction?.targetType);

        if (targetType === TargetType.DIRECTORY_ENTRY) {
            const clickActionEventPayload = {
                action: {
                    ...clickAction,
                    type: TrackingEventType.CLICK_ACTION,
                    targetType,
                },
            };

            payload.push(clickActionEventPayload);
        }
    }

    lumappsAnalyticsPlugin.track({ payload });
};

/**
 * Used for events linked to user input. Like facetSearch
 */
export const debouncedQueueTrackCustomEvent = debounce(queueTrackCustomEvent, DEBOUNCE_DELAY);
