import type { AutocompleteValue } from '@lumapps/combobox/components/AutocompleteMultiple/type';
import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

import { EndUserJourneyItemsListFilters, END_USER_JOURNEY_ITEMS_SORTING } from './types';

export const endUserJourneysBaseRoute: Route = {
    path: '/journeys',
    legacyId: 'app.journeys',
    appId: AppId.frontOffice,
};

// LEGACY Journeys List (for retrocompatibility reasons)
export const legacyEndUserJourneysListRoute: Route = {
    ...endUserJourneysBaseRoute,
    legacyId: 'app.journeys.list',
};

// Journeys List
export const endUserJourneysListRoute: Route = {
    ...endUserJourneysBaseRoute,
    path: `${endUserJourneysBaseRoute.path}/list`,
    legacyId: 'app.journeys.list',
};

export const endUserJourneysList = (): Route => ({
    ...endUserJourneysListRoute,
    params: {},
});

// Journeys as stakeholder
export const endUserJourneysAsStakeholderRoute: Route = {
    ...endUserJourneysBaseRoute,
    path: `${endUserJourneysBaseRoute.path}/items-as-stakeholder`,
    legacyId: 'app.journeys.items-as-stakeholder',
};

export interface EndUserJourneysAsStakeholderQueryParams {
    isDone?: boolean;
    title?: string;
    sorting: END_USER_JOURNEY_ITEMS_SORTING;
    acknowledgeItemId?: string;
    journeys?: AutocompleteValue[];
}

export const endUserJourneysAsStakeholder = (query?: EndUserJourneysAsStakeholderQueryParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }
    if (query?.isDone != null) {
        queryParams.isDone = String(query.isDone);
    }
    if (query?.sorting) {
        queryParams.sorting = query.sorting;
    }
    if (query?.journeys?.length) {
        queryParams.journeyIds = query.journeys.map(({ id }) => id).join(',');
    }

    // not an actual filter but the param to mark possible acknowledgment from url/email
    if (query?.acknowledgeItemId) {
        queryParams.acknowledgeItemId = query.acknowledgeItemId;
    }

    return {
        ...endUserJourneysAsStakeholderRoute,
        query: queryParams,
        params: {},
    };
};

// Enrollment View (= 1 enrollment + its items)
export const endUserJourneysViewRoute: Route = {
    path: `${endUserJourneysBaseRoute.path}/:enrollmentId`,
    legacyId: 'app.journeys.view',
    appId: AppId.frontOffice,
};

export interface EndUserJourneysViewParams {
    enrollmentId: string;
}
export interface EndUserJourneysViewQueryParams extends EndUserJourneyItemsListFilters {
    sorting: END_USER_JOURNEY_ITEMS_SORTING;
    acknowledgeItemId?: string;
}

export const endUserJourneysView = (
    params: EndUserJourneysViewParams,
    query?: EndUserJourneysViewQueryParams,
): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }
    if (query?.isDone != null) {
        queryParams.isDone = String(query.isDone);
    }
    if (query?.isAcknowledgeRequired != null) {
        queryParams.isAcknowledgeRequired = String(query.isAcknowledgeRequired);
    }
    if (query?.sorting) {
        queryParams.sorting = query.sorting;
    }

    // not an actual filter but the param to mark possible acknowledgment from url/email
    if (query?.acknowledgeItemId) {
        queryParams.acknowledgeItemId = query.acknowledgeItemId;
    }

    return {
        ...endUserJourneysViewRoute,
        query: queryParams,
        params: {
            enrollmentId: params.enrollmentId,
        },
    };
};

// Journey message with rich text
export const endUserJourneyItemViewRoute: Route = {
    path: `${endUserJourneysViewRoute.path}/items/:enrollmentItemId`,
    legacyId: 'app.journeys.item',
    appId: AppId.frontOffice,
};

export interface EndUserJourneyItemViewParams extends EndUserJourneysViewParams {
    enrollmentItemId: string;
}

export const endUserJourneyItemView = (params: EndUserJourneyItemViewParams) => {
    return {
        ...endUserJourneyItemViewRoute,
        params: {
            enrollmentItemId: params.enrollmentItemId,
            enrollmentId: params.enrollmentId,
        },
    };
};
