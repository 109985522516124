import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockTitle as BlockTitleProps } from '../../type';

import './index.scss';

const CLASSNAME = 'block-title';

export const BlockTitle: BlockComponent<BlockTitleProps> = ({ text, theme = Theme.light }) => (
    // eslint-disable-next-line react/forbid-elements
    <h1 className={classnames('block-title', `${CLASSNAME}--theme-${theme}`)}>{text}</h1>
);

BlockTitle.displayName = 'BlockTitle';
