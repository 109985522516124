import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const userManagementRoute: Route = {
    path: '/admin/new/users-management',
    legacyId: 'app.admin.new-users-management',
    appId: AppId.backOffice,
};

const legacyUserManagementRoute: Route = {
    path: '/admin/users-management',
    legacyId: 'app.admin.users-management',
    appId: AppId.legacy,
};

const userManagement = (): Route => {
    return userManagementRoute;
};

export { userManagement, userManagementRoute, legacyUserManagementRoute };
