/* istanbul ignore file */

/**
 * This module lists custom icons not available in `@lumapps/lumx/icons`.
 * These icons are previewable & searchable on Storybook: https://storybook.lumapps.net/?path=/story/lumx-core-icons--icons
 *
 * How to optimize SVG path:
 * 1. Go to https://yqnn.github.io/svg-path-editor/
 * 2. Paste your SVG path
 * 3. Activate "Minify output checkbox"
 * 4. Optimization 1: click round with a "Number of decimals" set to `2` (lower precision can break the icon)
 * 5. Optimization 2: convert between "relative" and "absolute" and keep the smallest (usually it's "relative")
 * 6. Copy the update SVG path and use it !
 */

/**
 * Magnifying glass over three lines.
 */
export const explore =
    'M12.89 5c2.97 0 5.38 2.41 5.38 5.39 0 1.33-.49 2.56-1.29 3.5l.22.22h.66l4.14 4.15-1.24 1.24-4.15-4.14v-.66l-.22-.22c-.94.8-2.17 1.29-3.5 1.29-1.53 0-2.91-.63-3.89-1.65 0-.01-.01-.01-.01-.02-.27-.28-.51-.6-.72-.93-.49-.82-.77-1.77-.77-2.78 0-.93.23-1.8.64-2.56 0 0 .01-.01.01-.01.23-.43.52-.82.85-1.16.98-1.02 2.36-1.66 3.89-1.66zm0 1.66c-2.06 0-3.73 1.67-3.73 3.73 0 2.06 1.67 3.72 3.73 3.72 2.06 0 3.72-1.66 3.72-3.72 0-2.06-1.66-3.73-3.72-3.73zm-5.75 6.51c.23.48.52.93.86 1.33h-6v-1.33h5.14zm-5.14-6.67h5.82c-.32.41-.58.86-.79 1.33h-5.03v-1.33zm0 3.33h4.52c-.01.19-.02.37-.02.56 0 .26.02.52.05.78h-4.55v-1.34z';

/**
 * Content page with header, text and image to the left.
 */
export const contentPage =
    'M4 4h16.84v1.68h-16.84v-1.68zm15.16 7.58h-13.48v-2.53h13.48v2.53zm0 3.37h-5.9v-1.69h5.9v1.69zm0 3.37h-5.9v-1.69h5.9v1.69zm-7.58 0h-5.9v-5.06h5.9v5.06zm-7.58-10.95v10.95c0 .93.75 1.68 1.68 1.68h13.48c.93 0 1.68-.75 1.68-1.68v-10.95h-16.84z';

/**
 * Crossed out check for Mark as unrelevant comment .
 */
export const checkOffCircleOutline =
    'M19.96 18.04c1.28-1.68 2.04-3.78 2.04-6.04 0-5.5-4.5-10-10-10-2.26 0-4.36.76-6.04 2.04l1.42 1.43c1.31-.93 2.9-1.47 4.62-1.47 4.41 0 8 3.59 8 8 0 1.72-.54 3.31-1.47 4.62l1.43 1.42zm-5.5-5.5 3.54-3.54-1.41-1.42-3.55 3.55 1.42 1.41zm-11.92-9.08-1.25 1.25 2.15 2.14c-.91 1.51-1.44 3.27-1.44 5.15 0 5.5 4.5 10 10 10 1.88 0 3.64-.53 5.15-1.44l2.14 2.15 1.25-1.25-18-18zm1.46 8.54c0-1.33.33-2.58.9-3.68l5.48 5.47-.38.38-2.59-2.58-1.41 1.41 4 4 1.79-1.79 3.9 3.89c-1.11.57-2.36.9-3.69.9-4.41 0-8-3.59-8-8z';

/**
 * A speech bubble with three dots inside, used by the mobile to illustrate the digital assistant.
 */
export const digitalAssistant =
    'M10.22 10.89c0 .67-.57 1.21-1.26 1.21-.7 0-1.26-.54-1.26-1.21 0-.66.56-1.2 1.26-1.2.69 0 1.26.54 1.26 1.2zm3.2 0c0 .67-.56 1.21-1.26 1.21-.69 0-1.26-.54-1.26-1.21 0-.66.57-1.2 1.26-1.2.7 0 1.26.54 1.26 1.2zm1.94 1.21c.7 0 1.26-.54 1.26-1.21 0-.66-.56-1.2-1.26-1.2-.69 0-1.25.54-1.25 1.2 0 .67.56 1.21 1.25 1.21zm-.61-8.1c3.98 0 7.2 3.09 7.25 6.87 0 2.48-1.35 4.64-3.41 5.87-.02.04-.07.06-.09.09l-4.53 3.08c-.32.22-.73 0-.73-.35v-1.77h-4.01c-.73 0-1.42-.09-2.08-.29-3.01-.88-5.15-3.59-5.15-6.61 0-3.74 3.29-6.89 7.21-6.89h5.54zm.18 10.94c2.33 0 4.23-1.81 4.23-4.05 0-2.23-1.9-4.04-4.23-4.04h-5.95c-2.3 0-4.23 1.84-4.23 4.04 0 2.22 1.91 4.05 4.23 4.05z';

/**
 * A generic person bust with a plus in the bottom right corner.
 */
export const membersOnlyContribution =
    'M8.5 2.5c-2.21 0-4 1.79-4 4 0 2.21 1.79 4 4 4 2.21 0 4-1.79 4-4 0-2.21-1.79-4-4-4zm0 10c-2.67 0-8 1.33-8 4v2h11.08c-.05-.33-.08-.66-.08-1 0-1.52.5-2.99 1.41-4.2-1.53-.52-3.23-.8-4.41-.8zm10-.5c-3.04 0-5.5 2.46-5.5 5.5 0 3.04 2.46 5.5 5.5 5.5 3.04 0 5.5-2.46 5.5-5.5 0-3.04-2.46-5.5-5.5-5.5zm2.75 6.05h-2.2v2.2h-1.1v-2.2h-2.2v-1.1h2.2v-2.2h1.1v2.2h2.2v1.1z';

/**
 * An email with marks on left to mark the movement.
 */
export const emailFast =
    'M22 5.5h-13c-1.1 0-2 .9-2 2v9c0 1.11.9 2 2 2h13c1.11 0 2-.89 2-2v-9c0-1.1-.89-2-2-2m0 3.67-6.5 3.33-6.5-3.33v-1.67l6.5 3.31 6.5-3.31v1.67m-17 7.33c0 .17.03.33.05.5h-4.05c-.55 0-1-.45-1-1s.45-1 1-1h4v1.5m-2-9.5h2.05c-.02.17-.05.33-.05.5v1.5h-2c-.55 0-1-.45-1-1s.45-1 1-1m-2 5c0-.55.45-1 1-1h3v2h-3c-.55 0-1-.45-1-1z';

/**
 * A skeleton visualization of the positionning of a thumbnail, on the upper left corner
 */
export const thumbnailDisplaySmall =
    'M5 21.67h7s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-7s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m12 0h4s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-4s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m10 0h6s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-6s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m-10-9h16s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-16s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m41 0h2s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-2s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m-52.5-2h6s2 0 2 2v3s0 2-2 2h-6s-2 0-2-2v-3s0-2 2-2';

/**
 * A skeleton visualization of the positionning of a thumbnail, on the whole header width
 */
export const thumbnailDisplayCover =
    'M5 26.67h7s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-7s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m12 0h4s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-4s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m10 0h6s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-6s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m-22-7h16s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-16s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m53 0h2s1.5 0 1.5 1.5v0s0 1.5-1.5 1.5h-2s-1.5 0-1.5-1.5v0s0-1.5 1.5-1.5m-52.5-14h54s2 0 2 2v6s0 2-2 2h-54s-2 0-2-2v-6s0-2 2-2';

/**
 * View mode grid: 4 cards in a grid
 */
export const viewModeGrid =
    'M13.9 19.5v-6.5h-1.1v6.5zm0 0m6.6 0v-6.5h-1.1v6.5zm0 0m0 1.5h-7.7v1h7.7zm0 0m0-2.5h-7.7v1h7.7zm0 0m0-5.5h-7.7v1h7.7zm0 0m-15.9 6.5v-6.5h-1.1v6.5zm0 0m6.6 0v-6.5h-1.1v6.5zm0 0m0 1.5h-7.7v1h7.7zm0 0m0-2.5h-7.7v1h7.7zm0 0m0-5.5h-7.7v1h7.7zm0 0m2.7-4.5v-6.5h-1.1v6.5zm0 0m6.6 0v-6.5h-1.1v6.5zm0 0m0 1.5h-7.7v1h7.7zm0 0m0-2.5h-7.7v1h7.7zm0 0m0-5.5h-7.7v1h7.7zm0 0m-15.9 6.5v-6.5h-1.1v6.5zm0 0m6.6 0v-6.5h-1.1v6.5zm0 0m0 1.5h-7.7v1h7.7zm0 0m0-2.5h-7.7v1h7.7zm0 0m0-5.5h-7.7v1h7.7zm0 0';

/**
 * View mode grid group: 4 cards in a grid framed in a box
 */
export const viewModeGridGroup =
    'M4.32 3.79h6.72v5.47h-6.72zm.96.84v3.79h4.8v-3.79zm0 0m-.96 5.48h6.72v.84h-6.72zm0 0m8.64-6.32h6.72v5.47h-6.72zm.96.84v3.79h4.8v-3.79zm0 0m-.96 5.48h6.72v.84h-6.72zm0 0m-8.64 2.94h6.72v5.48h-6.72zm.96.84v3.79h4.8v-3.79zm0 0m-.96 5.48h6.72v.84h-6.72zm0 0m8.64-6.32h6.72v5.48h-6.72zm.96.84v3.79h4.8v-3.79zm0 0m-.96 5.48h6.72v.84h-6.72zm0 0m-11.04-17.69h20.16v20.64h-20.16zm.96.85v18.94h18.24v-18.94zm0 0';

/**
 * View mode grid ungroup: 4 cards individually framed in a box in a grid
 */
export const viewModeGridUngroup =
    'M4.14 3.31h5.38v4.55h-5.38zm.82.83v2.9h3.73v-2.9zm0 0m-.82 4.55h5.38v.83h-5.38zm0 0m-1.66-7.03h8.69v9.51h-8.69zm.83.82v7.86h7.03v-7.86zm0 0m11.17.83h5.38v4.55h-5.38zm.83.83v2.9h3.73v-2.9zm0 0m-.83 4.55h5.38v.83h-5.38zm0 0m-1.65-7.03h8.69v9.51h-8.69zm.83.82v7.86h7.03v-7.86zm0 0m-9.52 12h5.38v4.56h-5.38zm.82.83v2.9h3.73v-2.9zm0 0m-.82 4.55h5.38v.83h-5.38zm0 0m-1.66-7.03h8.69v9.51h-8.69zm.83.83v7.86h7.03v-7.86zm11.17.82h5.38v4.56h-5.38zm.83.83v2.9h3.73v-2.9zm0 0m-.83 4.55h5.38v.83h-5.38zm0 0m-1.65-7.03h8.69v9.51h-8.69zm.83.83v7.86h7.03v-7.86zm0 0';

/**
 * View mode list: 2 cards listed vertically
 */
export const viewModeList =
    'M19 19.5h-6v-1h6zm0 0m0-11.5h-6v-1h6zm0 0m3 9h-9v-1h9zm0 0m0-11.5h-9v-1h9zm0 0m-20 8h9v8.5h-9zm1 1v6.5h7v-6.5zm0 0m-1-12.5h9v8.5h-9zm1 1v6.5h7v-6.5zm0 0';

/**
 * View mode list group: 2 cards listed vertically in a box
 */
export const viewModeListGroup =
    'M16.8 17.31h-4.8v-.79h4.8zm0 0m0-7.87h-4.8v-.78h4.8zm0 0m2.4 5.9h-7.2v-.78h7.2zm0 0m0-7.86h-7.2v-.79h7.2zm0 0m-16 5.11h7.2v6.69h-7.2zm.8.79v5.11h5.6v-5.11zm0 0m-.8-8.66h7.2v6.69h-7.2zm.8.79v5.11h5.6v-5.11zm0 0m-2.8-2.76h21.6v18.5h-21.6zm.8.79v16.92h20v-16.92zm0 0';

/**
 * View mode list: 2 cards individually framed in a box in avertical list
 */
export const viewModeListUngroup =
    'M1.13 2.63h20.25v8.25h-20.25zm.75.75v6.75h18.75v-6.75zm0 0m15.75 3h-6.75v-.75h6.75zm0 0m-14.63-1.88h5.25v4.5h-5.25zm.75.75v3h3.75v-3zm0 0m-2.62 6.75h20.25v8.25h-20.25zm.75.75v6.75h18.75v-6.75zm0 0m13.5 4.88h-4.5v-.75h4.5zm0 0m2.25-1.88h-6.75v-.75h6.75zm0 0m-14.63-1.87h5.25v4.5h-5.25zm.75.75v3h3.75v-3zm0 0';

/**
 * View mode slideshow: 1 main card with card pages listed below
 */
export const viewModeSlideshow =
    'M6.26 21h-3.13v-3h3.13zm-4.17-4v5h5.21v-5zm11.48 4h-3.14v-3h3.14zm-4.18-4v5h5.22v-5zm11.48 4h-3.13v-3h3.13zm-4.17-4v5h5.21v-5zm4.17-3h-17.74v-11h17.74zm-18.78-12v13h19.82v-13zm0 0';

/**
 * LumApps Broadcast Icon
 */
export const lumappsBroadcast =
    'M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m6 2c0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2.2 1.2 4.1 3 5.2l1-1.7c-1.2-.7-2-2-2-3.4 0-2.2 1.8-4 4-4s4 1.8 4 4c0 1.5-.8 2.8-2 3.4l1 1.7c1.8-1 3-3 3-5.2m-6-10c-5.5 0-10 4.5-10 10 0 3.7 2 6.9 5 8.6l1-1.7c-2.4-1.4-4-4-4-6.9 0-4.4 3.6-8 8-8s8 3.6 8 8c0 3-1.6 5.5-4 6.9l1 1.7c3-1.7 5-4.9 5-8.6 0-5.5-4.5-10-10-10z';

/**
 * LumApps Campaign Icon
 */
export const lumappsCampaign =
    'M11.5 3c0 0 0 0 0 0l0 3.1c0 0 .3 0 .5 0 3.7 0 6.6 3.4 5.8 7.3-.5 2.2-2.2 3.9-4.3 4.4-2.9.7-5.5-.7-6.7-3l-2.8 1.4c1.7 3.3 5.4 5.4 9.5 4.7 3.7-.6 6.8-3.6 7.4-7.3 1-5.9-3.6-10.9-9.4-10.6zm-2 9a2.5 2.5 0 105 0 2.5 2.5 0 10-5 0';

/**
 * LumApps Journey Icon
 */
export const lumappsJourney =
    'M9.8 5.5h11.2c1 0 1.9.8 1.9 1.9 0 1-.8 1.9-1.9 1.9h-11.2c-1-.1-1.8-.9-1.8-1.9 0-1.1.8-1.9 1.8-1.9zm-5.4 4.7h14.6c1 0 1.9.8 1.9 1.9 0 1-.8 1.9-1.9 1.9h-14.6a1.9 1.9 90 01-1.9-1.9c0-1.1.8-1.9 1.9-1.9zm-1.4 4.6h9.9c1 0 1.9.8 1.9 1.9 0 1-.8 1.9-1.9 1.9h-9.9a1.9 1.9 90 01-1.9-1.9c.1-1.1.9-1.9 1.9-1.9z';

/**
 * LumApps MicroApp Icon
 */
export const lumappsMicroApp =
    'M11.9154 2V9.627H19.8478V2H11.9154ZM2 19.1608H9.9323V11.5338H2V19.1608ZM2 2V9.627H9.9323V2H2ZM17.4757 11.2182C16.7496 10.5201 15.5725 10.5201 14.8465 11.2182L11.8667 14.0833C11.1406 14.7814 11.1406 15.9132 11.8667 16.6113L14.8465 19.4764C15.5725 20.1745 16.7496 20.1745 17.4757 19.4764L20.4555 16.6113C21.1815 15.9132 21.1815 14.7814 20.4555 14.0833L17.4757 11.2182Z';

/**
 * Image centered alignment
 */
export const imageAlignmentCenter =
    'M21 6c0-.55-.45-1-1-1h-16c-.55 0-1 .45-1 1 0 .55.45 1 1 1h16c.55 0 1-.45 1-1zm-13 4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1v-4zm-5 8c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .55-.45 1-1 1h-16c-.55 0-1-.45-1-1z';

/**
 * Image full width alignment
 */
export const imageAlignmentFull =
    'M21 4c0-.55-.45-1-1-1h-16c-.55 0-1 .45-1 1 0 .55.45 1 1 1h16c.55 0 1-.45 1-1zm-18 4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1h-16c-.55 0-1-.45-1-1v-8zm0 12c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .55-.45 1-1 1h-16c-.55 0-1-.45-1-1z';

/**
 * Image left alignment
 */
export const imageAlignmentLeft =
    'M21 6c0-.55-.45-1-1-1h-16c-.55 0-1 .45-1 1 0 .55.45 1 1 1h16c.55 0 1-.45 1-1zm-18 4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1v-4zm0 8c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .55-.45 1-1 1h-16c-.55 0-1-.45-1-1zm18-4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1 0 .55.45 1 1 1h6c.55 0 1-.45 1-1zm-8-4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1 0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1z';

/**
 * Image right alignment
 */
export const imageAlignmentRight =
    'M21 6c0-.55-.45-1-1-1h-16c-.55 0-1 .45-1 1 0 .55.45 1 1 1h16c.55 0 1-.45 1-1zm-8 4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1v-4zm-10 8c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .55-.45 1-1 1h-16c-.55 0-1-.45-1-1zm8-4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1 0 .55.45 1 1 1h6c.55 0 1-.45 1-1zm-8-4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1 0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1z';

/**
 * Icon for Learning
 */
export const learningHatImage =
    'm11.91 16.6c-.47 0-.94-.13-1.35-.39l-6.4-4.07c-.71-.45-1.14-1.21-1.16-2.04-.02-.83.37-1.62 1.05-2.1l6.41-4.54c.86-.61 2.02-.61 2.89-.02l6.57 4.54c.7.48 1.1 1.27 1.08 2.12-.02.84-.47 1.61-1.19 2.06l-6.57 4.07c-.4.25-.86.37-1.33.37zm-.19-11.37-6.4 4.53c-.13.09-.14.22-.14.28 0 .07.02.19.15.28l6.4 4.07c.11.06.25.06.36 0l6.57-4.07c.14-.09.16-.21.16-.28 0-.06-.01-.19-.14-.28l-6.57-4.53c-.12-.08-.28-.08-.39 0zm.22 14.87c-.42 0-.85-.11-1.23-.32l-5.99-3.34c-.53-.3-.71-.96-.42-1.48.3-.52.96-.71 1.49-.41l5.99 3.34c.1.06.22.06.33 0l5.99-3.34c.52-.3 1.19-.11 1.48.41.3.52.11 1.18-.42 1.48l-5.99 3.34c-.38.21-.8.32-1.23.32z';

/**
 * Filled icon for Learning
 */
export const learningFilledImage =
    'M19.9207 8.0064 13.348 3.4488C12.4811 2.8469 11.3171 2.8549 10.4581 3.4673L4.0551 8.025C3.3737 8.5102 2.9786 9.2976 2.9998 10.1355 3.021 10.9733 3.4532 11.7395 4.1585 12.1903L10.5588 16.2813C10.9698 16.5438 11.4417 16.6737 11.9137 16.6737 12.3856 16.6737 12.8363 16.5491 13.242 16.2972L19.8147 12.2062C20.5359 11.7581 20.9786 10.9839 20.9998 10.1355 21.021 9.287 20.618 8.4916 19.9181 8.0064H19.9207ZM11.9431 20.1859C11.5189 20.1859 11.092 20.0799 10.7129 19.8678L4.7208 16.5059C4.1958 16.2116 4.0103 15.5487 4.3046 15.0238 4.5989 14.4988 5.2617 14.3105 5.7866 14.6075L11.7787 17.9694C11.8795 18.0251 12.0041 18.0251 12.1075 17.9694L18.0995 14.6075C18.6245 14.3132 19.2873 14.4988 19.5816 15.0238 19.8759 15.5487 19.6903 16.2116 19.1653 16.5059L13.1733 19.8678C12.7942 20.0799 12.3699 20.1859 11.9431 20.1859Z';

/**
 * Code block
 */
export const codeBlock =
    'M5.59 3.41L7 4.82L3.82 8L7 11.18L5.59 12.6L1 8L5.59 3.41ZM11.41 3.41L16 8L11.41 12.6L10 11.18L13.18 8L10 4.82L11.41 3.41ZM22 6V18C22 19.11 21.11 20 20 20H4C2.9 20 2 19.11 2 18V14H4V18H20V6H17.03V4H20C21.11 4 22 4.89 22 6Z';

/**
 * !FIXME: DELETE WHEN lumx-icons is up to date
 */
export const mdiCheckDecagramOutline =
    'M23 12L20.6 9.2L20.9 5.5L17.3 4.7L15.4 1.5L12 3L8.6 1.5L6.7 4.7L3.1 5.5L3.4 9.2L1 12L3.4 14.8L3.1 18.5L6.7 19.3L8.6 22.5L12 21L15.4 22.5L17.3 19.3L20.9 18.5L20.6 14.8L23 12M18.7 16.9L16 17.5L14.6 19.9L12 18.8L9.4 19.9L8 17.5L5.3 16.9L5.5 14.1L3.7 12L5.5 9.9L5.3 7.1L8 6.5L9.4 4.1L12 5.2L14.6 4.1L16 6.5L18.7 7.1L18.5 9.9L20.3 12L18.5 14.1L18.7 16.9M16.6 7.6L18 9L10 17L6 13L7.4 11.6L10 14.2L16.6 7.6Z';
