export enum CONTENT_STREAMS_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11503482
     */
    CONTENT_STREAM = 'ADMIN.NAV.MOBILE_SETTINGS_CONTENT_STREAMS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452641
     */
    CONTENT_FILTER_CATEGORIES = 'CONTENT_FILTER_CATEGORIES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452766
     */
    SETTINGS_VISIBLE_BY = 'CONTENT_SETTINGS_VISIBLE_BY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452813
     */
    CUSTOM_CONTENT_TYPE = 'CUSTOM_CONTENT_TYPE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453218
     */
    MEDIA_PICKER_INSTANCES = 'MEDIA_PICKER_INSTANCES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454203
     */
    LIST_FEATURED_ONLY = 'WIDGET_TYPE_CONTENT-LIST_FEATURED_ONLY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454204
     */
    LIST_FEATURED_ONLY_HELP = 'WIDGET_TYPE_CONTENT-LIST_FEATURED_ONLY_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454207
     */
    LIST_HIGHLIGHTED = 'WIDGET_TYPE_CONTENT-LIST_HIGHLIGHTED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454208
     */
    LIST_HIGHLIGHTED_HELP = 'WIDGET_TYPE_CONTENT-LIST_HIGHLIGHTED_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454227
     */
    LIST_MAX_NUMBER = 'WIDGET_TYPE_CONTENT-LIST_MAX_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244442
     */
    CONTENTS = 'ADMIN.CONTENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441674572
     */
    VIEW_MODE_HELPER = 'ADMIN.CUSTOMER.STREAMS.VIEW_MODE_HELPER',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11275159
     */
    CONFIRM_DELETE_DESCRIPTION = 'ADMIN.CUSTOMER.STREAMS.CONFIRM_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11276621
     */
    CONFIRM_DELETE = 'ADMIN.CUSTOMER.STREAMS.CONFIRM_DELETE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11277094
     */
    DELETED = 'ADMIN.CUSTOMER.STREAMS.DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11277190
     */
    SAVED = 'ADMIN.CUSTOMER.STREAMS.SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11279429
     */
    STREAM_HEADER_TITLE = 'ADMIN.CUSTOMER.STREAMS.STREAM_HEADER_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45070395
     */
    SELECT_SITES = 'ADMIN.CUSTOMER.STREAMS.SELECT_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45081172
     */
    USE_DEFAULT_SITE = 'ADMIN.CUSTOMER.STREAMS.USE_DEFAULT_SITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45081194
     */
    FAVORITE_SITE_ONLY = 'ADMIN.CUSTOMER.STREAMS.FAVORITE_SITE_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45089337
     */
    CONTENT_SETTINGS = 'ADMIN.CUSTOMER.STREAMS.CONTENT_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11191291
     */
    INSTANCE_LABEL = 'ADMIN.CUSTOMER.STREAMS.INSTANCE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12101218
     */
    INSTANCE_EMPTY_OR_DELETED = 'ADMIN.CUSTOMER.STREAMS.INSTANCE_EMPTY_OR_DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45193249
     */
    INSTANCE_DEFAULT = 'ADMIN.CUSTOMER.STREAMS.INSTANCE_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45193249
     */
    INSTANCE_REQUIRED = 'ADMIN.CUSTOMER.STREAMS.INSTANCE_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11888945
     */
    MIN_MAX_CONTENT_DISPLAY_ERROR = 'ADMIN.CUSTOMER.STREAMS.MIN_MAX_CONTENT_DISPLAY_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11627576
     */
    NAME_REQUIRED = 'ADMIN.CUSTOMER.STREAMS.NAME_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11195183
     */
    NEW_STREAM = 'ADMIN.CUSTOMER.STREAMS.NEW_STREAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45250107
     */
    HERITABLE_CONTENT_TYPE = 'ADMIN.CUSTOMER.STREAMS.HERITABLE_CONTENT_TYPE',
}
