import { ServerListResponse } from '@lumapps/base-api';
import { ChatProviderId } from '@lumapps/chat/types';
import { CustomizationComponentProps } from '@lumapps/customizations/types';
import { ListItemProps, Theme, UserBlockSize } from '@lumapps/lumx/react';
import { Route } from '@lumapps/router';
import { TranslatableObject } from '@lumapps/translations/types';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { DEFAULT_API_PROFILE_FIELD_PATTERNS } from './constants';

export enum UserAccountType {
    EXTERNAL = 'external',
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    OKTA = 'okta',
}

export enum LoginProvider {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    // email is for external users
    EMAIL = 'email',
}

export interface Subscription {
    feed: string;
    uid?: string;
    level?: string;
    status?: string;
}

/** Additional informations on the user */
export interface ApiProfile {
    /** user's profile picture url, please use `getUserProfilePictureUrl` for callbacks */
    profilePicture?: string;
    /** user's profile picture url, please use `getUserProfilePictureUrl` for callbacks */
    thumbnailPhotoUrl?: string;
    /** user's profile picture object, please use `getUserProfilePictureUrl` for callbacks */
    thumbnail?: {
        /** The data of the profile picture. */
        photoData?: string;
        /** The mimeType of the profile picture. */
        mimeType?: string;
        etag?: string;
    };
    organizations?: {
        department: string;
        title: string;
        customType: string;
        primary: boolean;
        location: string;
    }[];
    /** Whether the author is admin of the current community. */
    isAdmin?: boolean;
    /** Additional informations on the user name. */
    name?: {
        familyName?: string;
        fullName?: string;
        givenName?: string;
    };
    /** other details TBD when needed */
    [key: string]: any;
}

/** adding both upper and lower case constants for retrocompatibility purposes */
export type Status = 'ENABLED' | 'DISABLED' | 'enabled' | 'disabled';

export interface User {
    /** user's id */
    id: string;
    uid?: string;
    /**
     * @deprecated please use `getUserFullName` function instead.
     */
    fullName: string;
    /** user account type (eg. Google, Microsoft...) */
    accountType: UserAccountType;
    /** user's last name */
    lastName: string;
    /** user's first name */
    firstName: string;
    /** user's primary email */
    email: string;
    password?: string;
    rePassword?: string;
    /** user's job title */
    jobTitle?: string;
    updatedAt?: string;
    createdAt?: string;
    /** user's profile picture url, please use `getUserProfilePictureUrl` for callbacks */
    profilePictureUrl?: string;
    /** user's profile picture url, please use `getUserProfilePictureUrl` for callbacks */
    profilePicture?: string;
    /** The customer id. */
    customer?: string;
    /** user identification token */
    token?: string;
    /** languages for the current user */
    langs?: string[];
    lang?: string;
    /** user's details */
    apiProfile?: ApiProfile;
    customProfile?: any;
    isGuest?: boolean;
    subscriptions?: Subscription[];
    /** User settings and preferences */
    settings?: {
        hasAcceptedCookies?: boolean;
        hasAcceptedTerms?: boolean;
        cookieBannerReadAt: string;
        notifications?: any;
        notificationPreferences?: any;
        social?: {
            isActivityVisible?: boolean;
        };
    };
    /** Whether the user has a god-like status. */
    isGod?: boolean;
    /** Whether the user has flagged as a designer or not */
    isDesigner?: boolean;
    /** Whether the user is a platform admin. */
    isSuperAdmin?: boolean;
    /** whether the user is hidden on the search and user directory */
    isHidden?: boolean;
    /** The list of instances for which the user is admin. */
    instancesSuperAdmin?: string[];
    /** Whether the user is connected using the `as` feature. */
    isConnectedAs?: boolean;
    /** The login service provider of the user */
    loginProvider?: LoginProvider;
    /** status of whether the user is enabeld or not */
    status?: Status;
    /**
     * list of tutorials "seen" timestamps grouped by instance id.
     * When we are updating the tutorials, this becomes another entirely different object
     * #blameTheBackend.
     */
    tutorials?:
        | Record<string, Record<string, string>>
        | {
              instance: string;
              tutorialId: string;
          };
}

export interface UserSlice {
    /** Currently connected user */
    connectedUser?: User;
    /**
     * `initialized` is here to avoid mapReduxToAngular to be triggered when another action is
     * dispatch while the user has not been set yet by the angular state,
     * the first __angular__/user/update will set this to `true`.
     */
    initialized: boolean;
    /** List of user by id */
    entities: Record<string, User>;
    userList: {
        isLoading: boolean;
        cursor?: string;
        ids: any[];
        hasMore: boolean;
    };
}

export interface UserListSuccessPayload extends Partial<ServerListResponse<User>> {
    items: User[];
    more: boolean;
    add?: boolean;
}

export interface UserListFiltersParams {
    query?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    feeds?: string | string[];
    types?: string[];
    status?: Status | Status[];
    sortOrder?: string;
}

export interface UserListRequestParams extends UserListFiltersParams {
    more?: boolean;
    searchTerm?: string;
    queryFields?: string[];
    retrieveAllFields?: boolean;
    add?: boolean;
    cursor?: string;
    instance?: string;
    maxResults?: number;
    showHidden?: boolean;
    lang?: string;
    fields?: string;
    reactedEntityKey?: string;
    reactedEntityKind?: string;
    /** used to search specifics user ids */
    ids?: string[];
}

export interface UserType {
    /** url of the user's badge picture */
    badgePictureUrl: string;
    /** name of the department that the user is part of */
    departmentName: string;
    /** user's email */
    email: string;
    /** user's first name */
    firstName: string;
    /** user's full name */
    fullName: string;
    /** user's unique identifier */
    id: string;
    /** user's job title */
    jobTitle: string;
    /** user's last name */
    lastName: string;
    /**  user's location */
    locationName: string;
    /** id of the organization that the user is part of */
    organizationId: string;
    /** url of the user's profile picture */
    profilePictureUrl: string;
}

/** The different types profile fields can have. */
export enum ProfileFieldType {
    text = 'text',
    user = 'user',
    metadata = 'metadata',
    float = 'float',
    date = 'date',
}

/** Fields shared between all profile field types. */
export interface CommonProfileField {
    /** whether the contact field is editable or not */
    editable: boolean;
    /** class of the icon to be used for this profile field */
    iconClass: string;
    /** url of the icon to be used for this profile field */
    iconUrl: string;
    /** id of the profile field */
    id: string;
    /** id of the organization the user is part of */
    organizationId: string;
    /** whether one can use this field as a search criteria */
    searchable: boolean;
    /** title of this profile field */
    title: TranslatableObject;
    /** The user linked to the profile field. */
    user?: UserType | null;
    /** this field's user's id */
    userId: string;
    /** value of the field as a translatable object */
    value?: TranslatableObject;
    /** value of the field as an array of translatable objects, if value is multiple */
    values?: Array<TranslatableObject>;
    /** The order to display the field in. */
    order?: number;
}

/**
 * The "User" type profile field specific fields.
 */
export interface UserProfileField extends CommonProfileField {
    type: ProfileFieldType.user;
    user: UserType;
}

/**
 * The "text" types profile field specific fields.
 */
export interface TextProfileField extends CommonProfileField {
    type: ProfileFieldType.text;
    user?: null;
}

/**
 * The "metadata" types profile field specific fields.
 */
export interface MetadataProfileField extends CommonProfileField {
    type: ProfileFieldType.metadata;
    user?: null;
}

/** The different type contact fields can have. */
export enum ContactFieldType {
    email = 'email',
    phone = 'phone',
    mobilePhone = 'mobile_phone',
    chat = 'chat',
}

export interface CommonContactFields extends CommonProfileField {
    contactId: string;
    /** whether this field is the preferred contact method or not */
    preferredContact: boolean;
    /** link to the user using this contact field e.g. slack url or phone number */
    url: string;
    /** deep link to the contact application, if available */
    nativeUrl?: string;
}
export interface DefaultContactField {
    /** The field type. */
    type: ContactFieldType | string;
    providerType: undefined;
}

/** Chat contact fields have an additional provider */
export interface ChatContactField {
    type: ContactFieldType.chat;
    providerType: ChatProviderId;
}

export type ContactField = CommonContactFields &
    (
        | {
              /** The field type. */
              type: ContactFieldType | string;
              providerType: undefined;
          }
        | {
              type: ContactFieldType.chat;
              providerType: ChatProviderId;
          }
    );

export type ProfileField = UserProfileField | TextProfileField;

export interface UserProfile extends UserType {
    /** url of the user's badge picture */
    coverPictureUrl: string;
    /** user's job title */
    profileFields?: Array<ProfileField>;
    /** user's job title */
    contactFields?: Array<ContactField>;
    /** whether the user can edit their profile */
    canEditProfile?: boolean;
    /**
     * The directory id used to retrieves the user data.
     * If for some reason the backend can't find a directoryId, the value will be null.
     * In that case, the user shouldn't be allowed to edit their profile.
     * */
    directoryId: string | null;
}

export type FieldValue = string | boolean | number | Record<string, any> | null;

export type Selector = {
    key: string;
    filterKey?: string;
    filterValue?: FieldValue;
};

export enum USER_MENU_LINK_TYPE {
    LINK = 'LINK',
    BUTTON = 'BUTTON',
    DIVIDER = 'DIVIDER',
    CUSTOMIZATION = 'CUSTOMIZATION',
}

export type UserMenuLink = {
    key: string;
    icon?: string;
    label?: string;
    url?: string;
    target?: string;
    type?: USER_MENU_LINK_TYPE;
    onClick?: (evt?: any) => void;
    route?: Route;
    isLoading?: boolean;
    tooltipLabel?: string;
    isDisabled?: boolean;
    customizationProps?: CustomizationComponentProps;
};

export type UserProfileListItemProps = ListItemProps & {
    /** The avatar of the user */
    avatarImageSrc?: string;
    /** The name */
    name?: string;
    /** The url to the userProfile */
    userProfileUrl?: string;
    /** Custom className */
    className?: string;
    /** The list item size */
    size?: ListItemProps['size'];
    /** The block size */
    userBlockSize?: UserBlockSize;
    /** The theme */
    theme?: Theme;
    /** Custom? style */
    style?: Record<string, string>;
    /** callback when the view user profile link is clicked */
    onClick?: () => void;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
};

export type AvailableApiProfileFieldPatterns = ObjectValues<typeof DEFAULT_API_PROFILE_FIELD_PATTERNS>;
