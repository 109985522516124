/* istanbul ignore file */
import React from 'react';

import { useCustomContentTypes } from '../../hooks/useCustomContentTypes';
import { ContentContributionMenu } from './ContentContributionMenu';

const ConnectedContentContributionMenu: React.FC = () => {
    const { allowedCustomContentTypes, parentCustomContentTypes, isAllowed, isLoading, isSuperAdmin } =
        useCustomContentTypes({});

    return (
        <ContentContributionMenu
            allowedCustomContentTypes={allowedCustomContentTypes}
            isAllowed={isAllowed}
            isSuperAdmin={isSuperAdmin}
            parentCustomContentTypes={parentCustomContentTypes}
            isLoadingCustomTypes={isLoading}
        />
    );
};

export { ConnectedContentContributionMenu as ContentContributionMenu };
