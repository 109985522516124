import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { shape, string, object } from 'prop-types';

import get from 'lodash/get';
import { hasProtocol } from '@lumapps/utils/string/isUrl';

import { areaStyle } from 'components/components/styles/types';
import { Icon } from 'components/components/ui/icons/Icon';
import { translate as t, getInputLanguage } from 'components/translations';
import { adjustFooterStyle } from 'components/services/styles';
import { handleTaggingMap } from 'components/services/analytics';
import { isDesignerMode, redirectTo } from 'components/utils';

/**
 * Display the widget footer.
 */
export class WidgetFooter extends PureComponent {
    static propTypes = {
        /** The current widget. */
        component: shape().isRequired,
        /** The icon name. */
        icon: string,
        /** The footer link. */
        more: shape({
            label: object,
            link: object,
        }).isRequired,
        /** The state of the widget. Could be either 'default' or 'hover'. */
        state: string.isRequired,
        /** The widget footer style. */
        style: areaStyle.isRequired,
    };

    static defaultProps = {
        icon: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            isHover: false,
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.handleFooterLink = this.handleFooterLink.bind(this);
    }

    /**
     * Trigger when the mouse enter the footer.
     */
    onMouseEnter() {
        this.setState({ isHover: true });
    }

    /**
     * Trigger when the mouse leave the footer.
     */
    onMouseLeave() {
        this.setState({ isHover: false });
    }

    /**
     * Trigger when user click on footer link.
     */
    handleFooterLink() {
        const { component, more } = this.props;
        const translatedLink = t(more.link);

        if (!translatedLink) {
            return;
        }

        handleTaggingMap('widget-footer_click', 'button', {
            widget: component,
        });

        if (!hasProtocol(translatedLink)) {
            /**
             * Regex to find url params after a '?' or '&'.
             * 1st match will be the param name and the 2nd its value.
             */
            const regex = new RegExp('/[?&]([^=#]+)=([^&#]*)/g');
            const stateParams = {};

            let match = regex.exec(translatedLink);
            while (match !== null) {
                stateParams[match[1]] = match[2];
                match = regex.exec(translatedLink);
            }

            stateParams.slug = translatedLink.split('?')[0];
            redirectTo('app.front.content-get', stateParams);
        }
    }

    render() {
        const { more, icon, style, state } = this.props;
        const { isHover } = this.state;
        const flexDirection = get(style.wrapper, 'flexDirection') || 'row';

        return (
            <div className="widget-footer" style={adjustFooterStyle(style, isHover || state === 'hover', 'main', true)}>
                <div
                    className={classNames('widget-wrapper widget-footer__wrapper', {
                        'widget-footer__wrapper--layout-bottom': flexDirection === 'column-reverse',
                        'widget-footer__wrapper--layout-left': flexDirection === 'row',
                        'widget-footer__wrapper--layout-right': flexDirection === 'row-reverse',
                        'widget-footer__wrapper--layout-top': flexDirection === 'column',
                    })}
                    style={adjustFooterStyle(style, isHover, 'wrapper', true)}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    {icon !== undefined && (
                        <Icon
                            className="widget-footer__icon"
                            style={adjustFooterStyle(style, isHover, 'icon', false)}
                            value={icon}
                        />
                    )}

                    <a
                        className="widget-footer__link widget-footer__label"
                        href={t(more.link)}
                        style={adjustFooterStyle(style, isHover, 'label', false)}
                        target={hasProtocol(t(more.link)) ? '_blank' : more.target}
                        onClick={this.handleFooterLink}
                    >
                        <span>
                            {isDesignerMode() ? t(more.label, undefined, getInputLanguage(), true) : t(more.label)}
                        </span>
                    </a>
                </div>
            </div>
        );
    }
}
