import React, { useCallback, useEffect } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { currentLanguageSelector } from '@lumapps/languages';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Button, Emphasis, FlexBox, GridColumn, ProgressCircular, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getCurrentTrainings, getRecommendedTrainings } from '../../api';
import {
    MAX_COLUMNS_TRAINING_LIST,
    MAX_TRAINING_ENTRIES_PER_QUERY,
    MIN_WIDTH_TRAINING_LIST_ITEMS,
} from '../../constants';
import { Training as TrainingType, TrainingCoursesViewModeVariantType, DisplayTrainingType } from '../../types';
import { TrainingCardLoadingSkeleton } from '../TrainingCardLoadingSkeleton';
import { TrainingList } from '../TrainingList';
import './index.scss';

export interface DisplayTrainingsProps {
    displayTrainingType: DisplayTrainingType;
    maxColumns?: number;
    minWidth?: number;
    viewModeVariant?: TrainingCoursesViewModeVariantType;
    onLoad?: (data: TrainingType[] | undefined) => void;
    theme?: Theme;
}

const CLASSNAME = 'display-trainings';

/**
 * Grid List of user's trainings
 * @param DisplayTrainingsProps
 * @returns DisplayTrainings
 */

export const DisplayTrainings: React.FC<DisplayTrainingsProps> = ({
    displayTrainingType,
    maxColumns = MAX_COLUMNS_TRAINING_LIST,
    minWidth = MIN_WIDTH_TRAINING_LIST_ITEMS,
    viewModeVariant,
    onLoad,
    theme = Theme.light,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const [trainings, setTrainings] = React.useState<TrainingType[]>([]);
    const [status, setStatus] = React.useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const language = useSelector(currentLanguageSelector);
    const [offset, setOffset] = React.useState<number>(0);
    const [showLoadMoreButton, setShowLoadMoreButton] = React.useState<boolean>(false);
    const { translateKey } = useTranslate();
    const learningAlphaEnabled = useSelector(isLearningAlphaEnabled);

    const getTrainings = useCallback(async () => {
        try {
            if (!trainings.length) {
                setStatus(BaseLoadingStatus.loading);
            } else {
                setStatus(BaseLoadingStatus.loadingMore);
            }
            let response;
            if (displayTrainingType === DisplayTrainingType.in_progress) {
                response = await getCurrentTrainings({
                    language,
                    offset,
                    limit: MAX_TRAINING_ENTRIES_PER_QUERY + 1,
                    learningAlphaEnabled,
                });
            } else if (displayTrainingType === DisplayTrainingType.recommended) {
                response = await getRecommendedTrainings({
                    language,
                    offset,
                    limit: MAX_TRAINING_ENTRIES_PER_QUERY + 1,
                    learningAlphaEnabled,
                });
            }
            const fetchedTrainings = response?.data?.items || [];
            if (fetchedTrainings.length > MAX_TRAINING_ENTRIES_PER_QUERY) {
                fetchedTrainings.pop();
                setShowLoadMoreButton(true);
            } else {
                setShowLoadMoreButton(false);
            }
            setTrainings((prev) => [...prev, ...fetchedTrainings]);
            setOffset(() => offset + MAX_TRAINING_ENTRIES_PER_QUERY);
            if (onLoad) {
                onLoad(response?.data?.items);
            }
            setStatus(BaseLoadingStatus.idle);
        } catch (error) {
            setStatus(BaseLoadingStatus.error);
        }
    }, [trainings, displayTrainingType, onLoad, language, offset, learningAlphaEnabled]);

    useEffect(() => {
        if (status === BaseLoadingStatus.initial) {
            getTrainings();
        }
    }, [getTrainings, viewModeVariant, status]);

    function renderTrainingCardSkeleton() {
        const array = [];
        for (let i = 0; i < MAX_COLUMNS_TRAINING_LIST; i++) {
            array.push(<TrainingCardLoadingSkeleton viewModeVariant={viewModeVariant} key={i} />);
        }
        return array;
    }

    return (
        <>
            {status === BaseLoadingStatus.loading && (
                <FlexBox gap="big" orientation="vertical">
                    <GridColumn
                        itemMinWidth={MIN_WIDTH_TRAINING_LIST_ITEMS}
                        maxColumns={MAX_COLUMNS_TRAINING_LIST}
                        gap="huge"
                    >
                        {renderTrainingCardSkeleton()}
                    </GridColumn>
                </FlexBox>
            )}
            {status === BaseLoadingStatus.error && <ServiceNotAvailableState onRetry={getTrainings} />}
            {(status === BaseLoadingStatus.idle || status === BaseLoadingStatus.loadingMore) && (
                <div className={element('list')}>
                    <TrainingList
                        scope={displayTrainingType}
                        viewModeVariant={viewModeVariant}
                        trainings={trainings}
                        maxColumns={maxColumns}
                        minWidth={minWidth}
                        theme={theme}
                    />
                    {showLoadMoreButton &&
                        (status === BaseLoadingStatus.idle ? (
                            <Button
                                onClick={getTrainings}
                                emphasis={Emphasis.medium}
                                hasBackground
                                fullWidth
                                className={element('button')}
                            >
                                {translateKey(GLOBAL.SHOW_MORE)}
                            </Button>
                        ) : (
                            <ProgressCircular className={element('progress')} />
                        ))}
                </div>
            )}
        </>
    );
};
