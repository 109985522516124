import loget from 'lodash/get';

import { translate as t } from 'components/translations';
import { CHAT_PROVIDER_NAMES, REACTION_TYPE_VALUES } from 'components/components/widgets/chat/chat_constants';

import { channelNameIsValid, makeChannelNameValid } from '@lumapps/chat/utils/channels';

/**
 * Get likes action label.
 *
 * @param  {Object} message Message.
 * @return {string} Number of likes string.
 */
const getLikesCount = (message) => {
    if (message.reactions) {
        const likes = message.reactions.filter((reaction) => reaction.reactionType === REACTION_TYPE_VALUES.LIKE)
            .length;

        return likes > 1 ? `${likes} ${t('LIKES')}` : `${likes} ${t('LIKE')}`;
    }

    return `0 ${t('LIKE')}`;
};

/**
 * Get reactions number.
 *
 * @param  {Object} message Message.
 * @return {integer} Number of likes.
 */
const getReactionsCount = (message) => {
    let nbReactions = 0;

    const reactions = loget(message, 'reactions', []);

    reactions.map((reaction) => {
        let number = loget(reaction, 'number', -1);
        if (number === -1) {
            number = 1;
        }

        nbReactions += number;
        return reaction;
    });

    return nbReactions;
};

/**
 * Get reactions action label.
 *
 * @param  {Object} message Message.
 * @return {string} Number of likes string.
 */
const getReactionsCountLabel = (message) => {
    return `${getReactionsCount(message)} ${t('REACTIONS')}`;
};

/**
 * Select user space depending on provider.
 */
function getProviderNameFromId(providerId) {
    if (loget(CHAT_PROVIDER_NAMES, providerId)) {
        return CHAT_PROVIDER_NAMES[providerId].name;
    }

    return providerId;
}

export {
    channelNameIsValid,
    getLikesCount,
    getProviderNameFromId,
    getReactionsCount,
    getReactionsCountLabel,
    makeChannelNameValid,
};
