import React from 'react';

import { CustomComponent, Store, PLACEMENT, CustomizationSubscription, BaseSubscription, Targets } from '../types';

const store: Store = {
    customComponents: {},
    observers: {},
    onNavigationSubscribers: [],
    onWidgetRenderedSubscribers: [],
    initialized: false,
    disabledComponents: window.lumapps ? window.lumapps.disabledComponents : {},
    disabledObservers: {},
    textsOverrides: {},
    functionalOverrides: {},
};

/**
 * Unfortunately, when the legacy application creates its bundles, it separates
 * the Angular JS files in one bundle and the React files in another. Since this file
 * is used on both sides, this Singleton gets created TWICE (yes seriously), since the
 * file is two times in our bundles, once on the legacy side and once on the migrated code.
 *
 * Therefore, stores have different values, depending where they are executed. In order to have
 * only just one store, we use a global variable that will allow us to really have only one store.
 *
 * Fortunately, this came in helpful since we needed to have a way to debug and evaluate the customizations
 * that were added to the store, so this is the perfect excuse to expose the store.
 */
if (window.lumapps) {
    window.lumapps.store = store;
}

const getStore = (): Store => {
    return window.lumapps?.store;
};

/**
 * Returns true if the store was initialized
 */
const wasStoreInitialized = () => {
    return getStore().initialized;
};

/**
 * Sets the customizations store as initialized
 */
const setStoreAsInitialized = () => {
    getStore().initialized = true;
};

/**
 * Retrieves the customization available for the provided component and placement
 * @param target - string
 * @param placement - PLACEMENT
 */
const getCustomComponent = ({ target, placement }: { target: string; placement: PLACEMENT }) => {
    const customizationStore = getStore();

    return customizationStore.customComponents[target] ? customizationStore.customComponents[target][placement] : null;
};

/**
 * Returns true if there is a customization for the provided component. If placement is passed in, it will
 * return true if there is a customization for the provided component and placement as well.
 * @param target - string
 * @param placement - PLACEMENT
 */
const doesCustomizationExist = ({ target, placement }: { target: string; placement?: PLACEMENT }) => {
    const customizationStore = getStore();

    if (!placement) {
        return Boolean(customizationStore.customComponents[target]);
    }

    return customizationStore.customComponents[target]
        ? Boolean(customizationStore.customComponents[target][placement])
        : false;
};

/**
 * Adds a customization to the store and if there is already an observer for that
 * customization, it will trigger it allowing the update to occur.
 * @param customization - CustomComponen
 */
const setCustomComponent = (customization: CustomComponent) => {
    const customizationStore = getStore();
    const { target, placement, targets = [] } = customization;

    const customizableTargets = targets.length > 0 ? targets : [target];

    customizableTargets.forEach((customizableTarget) => {
        if (!customizationStore.customComponents[customizableTarget]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customizationStore.customComponents[customizableTarget] = {
                [placement]: customization,
            };
        } else {
            customizationStore.customComponents[customizableTarget][placement] = customization;
        }

        if (!customizationStore.observers[customizableTarget]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customizationStore.observers[customizableTarget] = {
                [placement]: null,
            };
        }

        if (customizationStore.observers[customizableTarget][placement]) {
            customizationStore.observers[customizableTarget][placement].forEach((callback: any) => {
                try {
                    callback(customization);
                } catch (excp) {
                    // eslint-disable-next-line no-console
                    console.warn(excp);
                }
            });
        }
    });
};

/**
 * Subscribe to a customization change
 * @param component - string
 * @param placement - PLACEMENT
 * @param callback - action to be executed when there is an update.
 */
const subscribeToCustomization = ({ target, placement, callback }: CustomizationSubscription) => {
    const customizationStore = getStore();

    if (!customizationStore.observers[target]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customizationStore.observers[target] = {};
    }

    if (!customizationStore.observers[target][placement]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customizationStore.observers[target][placement] = [];
    }

    customizationStore.observers[target][placement].push(callback);
};

/**
 * Adds the given callback to the navigation subscriptions
 * @param subscription - Subscription
 */
const subscribeToNavigation = ({ callback }: BaseSubscription) => {
    const customizationStore = getStore();

    customizationStore.onNavigationSubscribers.push({ callback });
};

/**
 * Notifies all subscribers when there is a navigation
 * @param props - information passed on each navigation callback
 */
const notifyNavigationSubscribers = (props: any) => {
    const customizationStore = getStore();

    customizationStore?.onNavigationSubscribers.forEach(({ callback }) => {
        try {
            callback(props);
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.warn(excp);
        }
    });
};

/**
 * Adds the given callback to the widget subscriptions
 * @param subscription - Subscription
 */
const subscribeToWidgetRenders = ({ callback }: BaseSubscription) => {
    const customizationStore = getStore();

    customizationStore.onWidgetRenderedSubscribers.push({ callback });
};

/**
 * Notifies all subscribers when a widget has been rendered
 * @param props - information passed on each navigation callback
 */
const notifyWidgetRenderSubscribers = (props: any) => {
    const customizationStore = getStore();

    customizationStore.onWidgetRenderedSubscribers.forEach(({ callback }) => {
        try {
            callback(props);
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.warn(excp);
        }
    });
};

const areThereAnyWidgetRenderSubscribers = () => {
    const customizationStore = getStore();

    return customizationStore.onWidgetRenderedSubscribers.length > 0;
};

/** Returns whether a component is disabled or not */
const isComponentDisabled = (target: Targets): boolean => {
    const { disabledComponents } = window.lumapps.store || getStore() || {};

    return disabledComponents ? disabledComponents[target as string] : false;
};

const subscribeToDisable = (target: Targets, callback: any) => {
    const customizationStore = getStore();

    if (!customizationStore.disabledObservers[target]) {
        customizationStore.disabledObservers[target] = [];
    }

    customizationStore.disabledObservers[target].push(callback);
};

const disableComponent = (target: Targets) => {
    const customizationStore = window.lumapps.store || getStore() || {};

    if (!customizationStore.disabledComponents) {
        customizationStore.disabledComponents = {};
    }

    customizationStore.disabledComponents[target] = true;

    if (customizationStore.disabledObservers[target]) {
        customizationStore.disabledObservers[target].forEach((callback: any) => callback());
    }
};

const useIsComponentDisabled = (target: Targets): boolean => {
    const { disabledComponents } = window.lumapps.store || getStore() || {};
    const isDisabled = disabledComponents ? disabledComponents[target as string] : false;
    const [, setDisabled] = React.useState(isDisabled);

    React.useEffect(() => {
        subscribeToDisable(target, setDisabled);
    }, [target]);

    return isDisabled;
};

/** Returns a text override for a given target */
const getTextOverride = (target: Targets): Record<string, string> => {
    const { textsOverrides } = window.lumapps || getStore() || {};

    return textsOverrides ? textsOverrides[target as string] : {};
};

/** Returns a functional override for a given target */
const getFunctionalOverride = (target: Targets): Record<string, any> => {
    const { functionalOverrides } = window.lumapps || getStore() || {};

    return functionalOverrides ? functionalOverrides[target as string] : {};
};

const setFunctionalOverride = (target: Targets, override: Record<string, any>) => {
    const customizationStore = window.lumapps || getStore() || {};

    if (!customizationStore.functionalOverrides) {
        customizationStore.functionalOverrides = {};
    }

    customizationStore.functionalOverrides[target] = override;
};

const reset = () => {
    Object.assign(window.lumapps.store, {
        customComponents: {},
        observers: {},
        subscribers: [],
        onNavigationSubscribers: [],
        disabledObservers: {},
        disableComponent: {},
        textsOverrides: {},
        functionalOverrides: {},
    });
};

export default {
    wasStoreInitialized,
    notifyNavigationSubscribers,
    subscribeToNavigation,
    subscribeToCustomization,
    setCustomComponent,
    doesCustomizationExist,
    getCustomComponent,
    setStoreAsInitialized,
    isComponentDisabled,
    reset,
    getTextOverride,
    disableComponent,
    useIsComponentDisabled,
    getFunctionalOverride,
    setFunctionalOverride,
    subscribeToWidgetRenders,
    notifyWidgetRenderSubscribers,
    areThereAnyWidgetRenderSubscribers,
};
