/* istanbul ignore file */

import BaseApi from '@lumapps/base-api';
import { ServerListResponse, PRIORITY, API_VERSION } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import {
    SearchSortOrder,
    CommunitiesListRequestParams,
    CommunitiesMembersApproveOrRejectRequestParams,
    CommunitiesMembersListRequestParams,
    CommunitiesRequestParams,
    CommunitiesSearchForPostingRequestParams,
    CommunitiesSearchForSharingRequestParams,
    Community,
    CommunityRequest,
    CommunitySavePayload,
    GetCommunityParams,
    CommunitiesMembershipRequestParams,
    CommunitiesSearchForMovingRequestParams,
} from '../types';

const MAX_RESULTS = '5';
const MAX_RESULTS_ADMIN_PAGE = '30';
const DEFAULT_LIST_FIELDS =
    'items(customer,feedKeys,id,communityType,instance,privacy,slug,thumbnail,title,uid,renderingType,updatedAt,updatedBy,updatedByDetails(id, firstName, lastName, fullName, customer),canEdit,canDelete),more,callId,cursor';
const DEFAULT_SEARCH_FIELDS =
    'items(customer,description,id,instance,privacy,slug,thumbnail,title,uid),more,callId,cursor';
const ADMIN_SEARCH_FIELDS =
    'items(customer,description,id,instance,privacy,slug,thumbnail,title,uid,updatedAt,updatedBy,updatedByDetails(id, firstName, lastName, fullName, customer),canEdit,canDelete),more,callId,cursor';

const communitiesApi = new BaseApi({ path: 'search' });

const communityApi = new BaseApi({ path: 'community' });

const communityV2Api = new BaseApi({
    path: 'communities',
    version: API_VERSION.v2,
});

const get = (params: GetCommunityParams, getCacheFirst = true, headers = {}) => {
    if (getCacheFirst) {
        return communityApi.getCacheFirst('get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params, headers });
    }

    return communityApi.get<Community>('get', { params, headers });
};

const save = (community: CommunitySavePayload) => communityApi.post<Community>('save', community);

const search = ({
    cursor,
    fields = ADMIN_SEARCH_FIELDS,
    lang,
    maxResults = MAX_RESULTS_ADMIN_PAGE,
    more,
    query,
    sortOrder = SearchSortOrder.reverseUpdatedAt,
    instance,
}: CommunitiesRequestParams) => {
    return communitiesApi.post<ServerListResponse<Community>>('community', {
        cursor,
        fields,
        lang,
        maxResults,
        more,
        query,
        sortOrder,
        instance,
    });
};

const searchForPosting = ({
    cursor,
    fields = DEFAULT_SEARCH_FIELDS,
    lang,
    maxResults = MAX_RESULTS,
    query,
    instanceId,
    followingOnly,
    privacy,
    renderingTypes,
    sortOrder,
}: Partial<CommunitiesSearchForPostingRequestParams>) => {
    return communityApi.post<ServerListResponse<Community>>('searchForPosting', {
        cursor,
        maxResults,
        instanceId,
        query,
        lang,
        followingOnly,
        fields,
        privacy,
        renderingTypes,
        sortOrder,
    });
};

const searchForSharing = ({
    cursor,
    fields = DEFAULT_SEARCH_FIELDS,
    lang,
    maxResults = MAX_RESULTS,
    query,
    postId,
    followingOnly,
    renderingTypes,
    sortOrder,
}: Partial<CommunitiesSearchForSharingRequestParams>) => {
    return communityApi.post<ServerListResponse<Community>>('searchForSharing', {
        cursor,
        maxResults,
        postId,
        query,
        lang,
        followingOnly,
        fields,
        renderingTypes,
        sortOrder,
    });
};

const searchForMoving = ({
    cursor,
    fields = DEFAULT_SEARCH_FIELDS,
    lang,
    maxResults = MAX_RESULTS,
    query,
    postId,
    followingOnly,
    renderingTypes,
    instanceId,
    sortOrder,
}: Partial<CommunitiesSearchForMovingRequestParams>) => {
    return communityApi.post<ServerListResponse<Community>>('searchForPostMoving', {
        cursor,
        maxResults,
        postId,
        query,
        lang,
        followingOnly,
        fields,
        renderingTypes,
        instanceId,
        sortOrder,
    });
};

// There is a default projection (default value of fields) for list to avoid very long loading time (SUP-956)
const list = ({ fields = DEFAULT_LIST_FIELDS, ...params }: Partial<CommunitiesListRequestParams>) =>
    communityApi.post<ServerListResponse<Community>>('list', { fields, ...params });

const deleteMultiple = (communitiesIds: string[]) =>
    communityApi.delete('deleteMulti', { params: { uid: communitiesIds } });

/** Members Requests management */
// As a member make a membership request
const requestMembership = (params: CommunitiesMembershipRequestParams) =>
    communityApi.post<Omit<CommunityRequest, 'adminMessage' | 'userMessage'>>('request/save', params);

// List the members requests of a community given a communityId
const listRequests = (params: CommunitiesMembersListRequestParams) =>
    communityApi.get<ServerListResponse<CommunityRequest>>('request/list', { params });

// Approve an user into the community
const approveRequest = (params: CommunitiesMembersApproveOrRejectRequestParams) =>
    communityApi.post('request/approve', params);

// Reject an user to join the community
const rejectRequest = (params: CommunitiesMembersApproveOrRejectRequestParams) =>
    communityApi.post('request/reject', params);

// Migrate a community into a space
const migrateCommunityToSpace = (communityId: string) => communityV2Api.post(`/${communityId}/migrate-to-space`, {});

export {
    get,
    search,
    searchForPosting,
    searchForSharing,
    searchForMoving,
    list,
    deleteMultiple,
    save,
    requestMembership,
    listRequests,
    approveRequest,
    rejectRequest,
    migrateCommunityToSpace,
    communityApi,
    communitiesApi,
};
