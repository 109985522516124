import difference from 'lodash/difference';
import loFind from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';

// ///////////////////////////

function UserAccessService(
    $state,
    Community,
    Config,
    ConfigInstance,
    Content,
    Features,
    InitialSettings,
    SocialAdvocacy,
    User,
) {
    'ngInject';

    /* eslint-disable consistent-this */
    const service = {};

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The list of allowed metadata per content type for the connected user.
     * This is used as a cache to avoid recomputing allowed metadata each time the function is called.
     *
     * @type {Object}
     */
    const _allowedMetadata = {};

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Get the metadata the connected user is allowed to use/see.
     *
     * @param  {string} contentType The content type we want the metadata of.
     * @return {Array}  The list of allowed metadata for the connected user.
     */
    function _getAllowedMetadata(contentType) {
        if (angular.isDefined(_allowedMetadata[contentType])) {
            return _allowedMetadata[contentType];
        }

        _allowedMetadata[contentType] = [];

        const roles = service.getRoles();

        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i];

            if (angular.isUndefinedOrEmpty(role)) {
                continue;
            }

            for (let j = 0, lenJ = role.authorizations.length; j < lenJ; j++) {
                const authorization = role.authorizations[j];

                if (angular.isUndefinedOrEmpty(authorization)) {
                    continue;
                }

                let isCurrentContentType = false;

                for (let k = 0, lenK = authorization.actions.length; k < lenK; k++) {
                    const action = authorization.actions[k];

                    if (get(action, 'customContentType') === contentType) {
                        isCurrentContentType = true;

                        break;
                    }
                }

                if (isCurrentContentType) {
                    _allowedMetadata[contentType] = _allowedMetadata[contentType].concat(authorization.metadata);

                    // If the user has a role with no metadata, authorize them all!
                    // TODO: check if there is at least one EDIT action.
                    if (angular.isUndefinedOrEmpty(authorization.metadata)) {
                        _allowedMetadata[contentType] = [];

                        return _allowedMetadata[contentType];
                    }
                }
            }
        }

        return _allowedMetadata[contentType];
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Check if the connected user is allowed to delete one or several content.
     *
     * @param  {Array}   [contents=Array(<Current content>)] The list of content to delete.
     * @return {boolean} If the connected user can delete the content.
     */
    function canDeleteContents(contents) {
        const currentContent = Content.getCurrent();

        if (!User.isConnected() || angular.isUndefinedOrEmpty([contents, currentContent], 'every')) {
            return false;
        }

        if (User.getConnected().isSuperAdmin || User.isInstanceAdmin()) {
            return true;
        }

        contents = contents || [currentContent];

        let canDelete = true;

        angular.forEach(contents, (content) => {
            canDelete =
                canDelete &&
                service.isUserAllowed('CUSTOM_CONTENT_DELETE', {
                    checkContent: true,
                    content,
                    customContentTypeId: content.customContentType,
                });
        });

        return canDelete;
    }

    /**
     * Check if the connected user is allowed to edit the content.
     *
     * @param  {Object}  [content=<Current content>] The content we want to check the right of.
     * @return {boolean} If the connected user can edit the content.
     */
    function canEditContent(content) {
        content = content || Content.getCurrent();
        if (angular.isUndefinedOrEmpty(content) || !User.isConnected()) {
            return false;
        }

        const contentType = get(content, 'type', '').toUpperCase();

        return (
            User.getConnected().isSuperAdmin ||
            User.isInstanceAdmin() ||
            service.isEditor(content) ||
            (contentType !== InitialSettings.CONTENT_TYPES.COMMUNITY && service.isUserAllowed(`${contentType}_EDIT`))
        );
    }

    /**
     * Check if the connected user is allowed manage the instance settings.
     *
     * @return {boolean} If the connected user can manage the instance settings.
     */
    function canManageInstanceSettings() {
        return User.isConnected() && (get(User.getConnected(), 'isSuperAdmin', false) || User.isInstanceAdmin());
    }

    /**
     * Check if the current user can access community analytics.
     *
     * @return {boolean} Whether the current user can display the dashboard analytics of the community.
     */
    function canViewCommunityAnalytics() {
        return (
            // Only if the feature flag "extended-lumapps-analytics" is enabled.
            Features.hasFeature('extended-lumapps-analytics') &&
            // Only if we display a community.
            $state.is('app.front.community') &&
            // Only for community contributeur / (super)admin and user with the analytics reader role.
            (Community.isEditableBy(User.getConnected()) || service.isUserAllowed('ANALYTICS_READ'))
        );
    }

    /**
     * Build a filter to check if a given metadata is allowed for the connected user in the given content type.
     *
     * @param  {string}   contentType The content type we want to check the metadata allowed for the connected user
     *                                in.
     * @return {Function} The filter function which will receive the metadata to check if allowed for the connected
     *                    user in the content type.
     */
    function checkIfMetadataIsAllowed(contentType) {
        return (metadata) => {
            const allowedMetadata = _getAllowedMetadata(contentType);

            return angular.isDefinedAndFilled(contentType) && !service.canManageInstanceSettings()
                ? angular.isUndefinedOrEmpty(allowedMetadata) || includes(allowedMetadata, metadata.id)
                : true;
        };
    }

    /**
     * Get the feeds associated to an action for the connected user.
     * Note that this will return `undefined` if the user is super or instance admin or if the role is applied to
     * all feeds.
     *
     * @param  {string} requestedAction The name of the action to check or an array of action names to check.
     * @param  {Object} [params]        The params for the function. Available params are:
     * @return {Array}  The feeds associated to the action for the connected user.
     */
    function getFeedsForAction(requestedAction, params) {
        if (!User.isConnected() || angular.isUndefinedOrEmpty(requestedAction)) {
            return [];
        }
        const connectedUser = User.getConnected();

        if (connectedUser.isSuperAdmin || User.isInstanceAdmin()) {
            return undefined;
        }

        const roles = service.getRoles();
        const feeds = [];

        // eslint-disable-next-line lumapps/angular-foreach
        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i];

            // eslint-disable-next-line lumapps/angular-foreach
            for (let j = 0, lenJ = role.authorizations.length; j < lenJ; j++) {
                const authorization = role.authorizations[j];

                // eslint-disable-next-line lumapps/angular-foreach
                for (let k = 0, lenK = authorization.actions.length; k < lenK; k++) {
                    const action = authorization.actions[k];
                    const fullActionStr = `${action.name}_${action.type}`;
                    const customContentTypeId = get(params, 'customContentTypeId', action.customContentType);

                    if (fullActionStr === requestedAction && action.customContentType === customContentTypeId) {
                        // eslint-disable-next-line max-depth
                        if (angular.isDefinedAndFilled(authorization.feeds)) {
                            feeds.push(...authorization.feeds);
                        } else {
                            // The role is applied to all feeds, do not filter them.
                            return undefined;
                        }
                    }
                }
            }
        }

        return feeds;
    }

    /**
     * Get the roles of the connected user.
     *
     * @return {Array} A list of roles the connected has.
     */
    function getRoles() {
        return InitialSettings.USER_ROLES || [];
    }

    /**
     * Check if the user has right to manage modules in admin.
     *
     * @return {boolean} If the user has right to manage at least one type of modules.
     */
    function hasRightToManageModules() {
        return (
            service.isUserAllowed('DIRECTORY_EDIT') ||
            service.isUserAllowed('TUTORIAL_EDIT') ||
            service.isUserAllowed('USER_DIRECTORY_EDIT')
        );
    }

    /**
     * Check if the user has right to manage communities in admin.
     *
     * @return {boolean} Whether the user has right to manage commuities in admin.
     */
    function hasRightToManageCommunities() {
        return (
            includes(ConfigInstance.AVAILABLE_MODULES, 'community') &&
            Features.hasFeature('community') &&
            (service.isUserAllowed('COMMUNITY_EDIT') ||
                service.isUserAllowed('COMMUNITY_CREATE') ||
                service.isUserAllowed('COMMUNITY_DELETE'))
        );
    }

    /**
     * Check if the connected user has editor right to a content.
     *
     * @param  {Object}  [content=<Current content>] The content we want to check the right of.
     * @return {boolean} If the connected user has the editor right to the content.
     */
    function isEditor(content) {
        content = content || Content.getCurrent();

        if (angular.isUndefinedOrEmpty(get(content, 'editors')) || !User.isConnected()) {
            return false;
        }

        const feedInEditor = loFind(User.getConnected().subscriptions, (subscription) =>
            includes(content.editors, subscription),
        );

        return angular.isDefinedAndFilled(feedInEditor);
    }

    // eslint-disable-next-line lumapps/jsdoc-format
    /**
     * Check if the connected user is allowed to perform an action.
     *
     * @param  {string|Array} requestedAction          The name of the action to check or an array of action names
     *                                                 to check.
     * @param  {Object}       [params]                 The params for the function. Available params are:
     *         {string}       [arrayMethodName='some'] The name of the method being applied to the loop on the array
     *                                                 of actions.
     *                                                 Possible values are: 'some' or 'every'.
     *         {boolean}      [checkContent=false]     Indicates if we should check authorizations on a specific
     *                                                 content on top of global authorizations.
     *         {Object}       [content]                A specific content object to check extra authorizations on.
     *         {string}       [customContentTypeId]    A specific custom content type to check extra rights on.
     *         {string}       [uuid]                   A specific navigation uuid to check for MENU_DROP rights.
     *         {boolean}      [ignoreAdmins=true]      Indicates if we should check permission for admin users as
     *                                                 well or not.
     * @return {boolean}      Whether or not the connected user can perform the action or not.
     */
    function isUserAllowed(requestedAction, params) {
        if (
            angular.isString(requestedAction) &&
            ((includes(requestedAction, 'ANALYTICS_READ') &&
                !Features.hasOneOfFeatures(['analytics', 'extended-lumapps-analytics'])) ||
                (includes(requestedAction, 'SOCIAL_ADVOCACY') && !Features.hasFeature('social-advocacy')))
        ) {
            return false;
        }

        if (!User.isConnected()) {
            return false;
        }

        const connectedUser = User.getConnected();

        params = params || {};

        params.checkContent = params.checkContent || false;
        params.ignoreAdmins = angular.isUndefined(params.ignoreAdmins) ? true : params.ignoreAdmins;

        // Check if the user is super admin or instance admin.
        if (params.ignoreAdmins && (connectedUser.isSuperAdmin || User.isInstanceAdmin())) {
            return true;
        }

        // We passed a list of actions rather than a single one.
        if (angular.isArray(requestedAction)) {
            params.arrayMethodName = params.arrayMethodName || 'some';

            if (
                includes(['every', 'some'], params.arrayMethodName) &&
                angular.isFunction(requestedAction[params.arrayMethodName])
            ) {
                return requestedAction[params.arrayMethodName]((actionItem) =>
                    service.isUserAllowed(actionItem, params),
                );
            }
        }

        let content;

        // Check for content authorizations.
        if (params.checkContent) {
            content = angular.isDefinedAndFilled(params.content) ? params.content : Content.getCurrent();

            if (angular.isDefinedAndFilled(content)) {
                let authType = '';

                if (includes(requestedAction, 'READ')) {
                    authType = 'feedKeys';
                } else if (includes(requestedAction, 'EDIT')) {
                    authType = 'editors';
                } else if (includes(requestedAction, 'PUBLISH')) {
                    authType = 'publishers';
                } else if (includes(requestedAction, 'ARCHIVE')) {
                    authType = 'archivers';
                } else if (includes(requestedAction, 'DELETE')) {
                    authType = 'deleters';
                }

                if (angular.isDefinedAndFilled(content[authType])) {
                    const hasSubscriptionOfAuthType = loFind(connectedUser.subscriptions, (subscription) =>
                        includes(content[authType], subscription.feed),
                    );

                    if (angular.isDefinedAndFilled(hasSubscriptionOfAuthType)) {
                        return true;
                    }
                }
            }
        }

        const allAuthorizations = flattenDeep(map(service.getRoles(), 'authorizations'));
        const isMenuDrop = requestedAction === 'MENU_DROP';

        // Check for Roles matching (content type authorizations).
        for (let i = 0, lenI = allAuthorizations.length; i < lenI; i++) {
            const authorization = allAuthorizations[i];

            /*
             * If the requested action is about a "CUSTOM CONTENT",
             * we must check for authorization action AND custom content type.
             */
            for (let j = 0, lenJ = authorization.actions.length; j < lenJ; j++) {
                const action = authorization.actions[j];
                const fullActionStr = `${action.name}_${action.type}`.toUpperCase();

                if (fullActionStr !== requestedAction) {
                    continue;
                }

                if (isMenuDrop) {
                    if (!angular.isDefinedAndFilled(params.uuid)) {
                        return true;
                    }

                    // Check if the nav items in the authorization match the param uuid.
                    if (includes(authorization.navigationUuids, params.uuid)) {
                        return true;
                    }
                    continue;
                }

                if (!includes(requestedAction, 'CUSTOM_CONTENT')) {
                    return true;
                }

                // If the custom content type param matches the action CCT.
                const isCctParamOk =
                    angular.isDefinedAndFilled(params.customContentTypeId) &&
                    action.customContentType === params.customContentTypeId;

                // If the current content CCT matches the action CCT.
                const isCurrentContentCctOk =
                    params.checkContent &&
                    angular.isDefinedAndFilled(get(content, 'customContentType')) &&
                    action.customContentType === content.customContentType;

                if (isCctParamOk || isCurrentContentCctOk) {
                    /**
                     * Feeds does not match with the current authorization
                     */
                    if (
                        angular.isDefinedAndFilled(authorization.feeds) &&
                        angular.isDefinedAndFilled(difference(get(content, 'feedKeys'), authorization.feeds))
                    ) {
                        continue;
                    }

                    // Check the id of the author first before checking his email in case of email change
                    const isUserAuthor = !!get(content, 'authorDetails')
                        ? connectedUser.id === get(content, 'authorDetails').id
                        : connectedUser.email === get(content, 'author');

                    /*
                     * The authorization is only for editing the content created by the current user.
                     * If so, we will check if the user owns the content.
                     * If the property author of content is undefined, we consider that the author
                     * is the connected user.
                     */
                    const userCanEditHisOwnContent =
                        authorization.canEditOnlyOwnContent === true &&
                        (isUserAuthor || angular.isUndefined(get(content, 'author')));
                    const noOnlyContentOwnerRule = !authorization.canEditOnlyOwnContent || angular.isUndefined(content);

                    if (userCanEditHisOwnContent || noOnlyContentOwnerRule) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    /**
     * Check if a change to the given status is allowed to the connected user for the given content.
     *
     * @param  {Object}  content                 The content to check.
     * @param  {string}  newStatus               The status we want to set the content to.
     * @param  {boolean} [currentRevision=false] Indicates if we want to use the current revision instead of the
     *                                           last one available.
     * @return {boolean} If the connected user can switch the given content's to the given status.
     */
    function statusActionAvailable(content, newStatus, currentRevision) {
        currentRevision = Boolean(currentRevision);

        if (!User.isConnected() || angular.isUndefinedOrEmpty(content) || angular.isUndefinedOrEmpty(newStatus)) {
            return false;
        }

        const connectedUser = User.getConnected();
        const isUserAdmin = connectedUser.isSuperAdmin || User.isInstanceAdmin();
        const isWorkflowEnabled = Content.isWorkflowEnabled(content);
        const currentContentStatus = currentRevision
            ? content.status
            : get(content, 'lastRevision.status', content.status);
        const nonStandardContentTypes = [
            InitialSettings.CONTENT_TYPES.COMMUNITY,
            InitialSettings.CONTENT_TYPES.CUSTOM_LIST,
            InitialSettings.CONTENT_TYPES.DIRECTORY,
        ];
        const isStandardContent = !includes(nonStandardContentTypes, content.type);

        /*
         * If the content is not a standard content, no action other than the default "Save & Publish" one should be
         * allowed.
         */
        if (!isStandardContent) {
            // If the content is a community, simply check if the connected user can edit the community.
            if (
                content.type === InitialSettings.CONTENT_TYPES.COMMUNITY &&
                newStatus === Config.CONTENT_STATUS.LIVE.value
            ) {
                return Community.isEditableBy(connectedUser, content);
            }

            // For content list and directory, only allow to go from draft or live states to live state.
            return (
                (currentContentStatus === Config.CONTENT_STATUS.DRAFT.value ||
                    currentContentStatus === Config.CONTENT_STATUS.LIVE.value) &&
                newStatus === Config.CONTENT_STATUS.LIVE.value
            );
        }

        /*
         * If the content is new (unsaved), no action other than the default "Save" and "Save & Publish" ones should
         * be allowed.
         */
        if (angular.isUndefinedOrEmpty(content.id)) {
            return (
                newStatus === Config.CONTENT_STATUS.DRAFT.value ||
                (newStatus === Config.CONTENT_STATUS.LIVE.value && !isWorkflowEnabled)
            );
        }

        const params = {
            checkContent: true,
            content,
            customContentTypeId: content.customContentType,
        };

        switch (newStatus) {
            // When the connected user wants to set the content to 'DRAFT' (basically, "Save" it or "Unpublish" it).
            case Config.CONTENT_STATUS.DRAFT.value:
                switch (currentContentStatus) {
                    // When the content was in validation ("Refuse" action).
                    case Config.CONTENT_STATUS.TO_VALIDATE.value:
                        // If workflow is not enabled, it shouldn't be possible to refuse a content.
                        if (!isWorkflowEnabled) {
                            return false;
                        }

                        // If workflow is enabled, only admins or publishers can refuse a content.
                        return isUserAdmin || service.isUserAllowed('CUSTOM_CONTENT_PUBLISH', params);

                    // When the content was live ("Unpublish" action).
                    case Config.CONTENT_STATUS.LIVE.value:
                        // Admin can always unpublish a content.
                        if (isUserAdmin) {
                            return true;
                        }

                        // If workflow is enabled, only publishers can unpublish a content.
                        if (isWorkflowEnabled) {
                            return service.isUserAllowed('CUSTOM_CONTENT_PUBLISH', params);
                        }

                        // If workflow is not enabled, only editors can unpublish a content.
                        return service.isUserAllowed('CUSTOM_CONTENT_EDIT', params);

                    // No other previous state should lead to the draft state.
                    default:
                        return false;
                }

            // When the connected user wants to set the content to 'TO_VALIDATE' (basically, "Submit" it).
            case Config.CONTENT_STATUS.TO_VALIDATE.value:
                // If workflow is not enabled, it's not possible to submit a content.
                if (!isWorkflowEnabled) {
                    return false;
                }

                // Don't allow to set in validation a content that has been previously refused and not saved again.
                if (get(content, 'lastRevision.newStatus') === 'REFUSED') {
                    return false;
                }

                switch (currentContentStatus) {
                    // When the content was draft ("Submit" action).
                    case Config.CONTENT_STATUS.DRAFT.value:
                        // Only admins or editors can submit a content.
                        return isUserAdmin || service.isUserAllowed('CUSTOM_CONTENT_EDIT', params);

                    // No other previous state should lead to the in validation state.
                    default:
                        return false;
                }

            // When the connected user wants to set the content to 'LIVE' (basically, publish it).
            case Config.CONTENT_STATUS.LIVE.value:
                // If workflow is enabled on the content.
                if (isWorkflowEnabled) {
                    switch (currentContentStatus) {
                        // When the content was draft ("Save & Publish" action).
                        case Config.CONTENT_STATUS.DRAFT.value:
                            // User need to go through validation workflow to publish the content.
                            return false;

                        // When the content was in validation ("Validate" action).
                        case Config.CONTENT_STATUS.TO_VALIDATE.value:
                            // Only publishers can validate a content.
                            return isUserAdmin || service.isUserAllowed('CUSTOM_CONTENT_PUBLISH', params);

                        // When the content was live ("Save" action).
                        case Config.CONTENT_STATUS.LIVE.value:
                            // Only admin can save an already live content without going through the workflow again.
                            return isUserAdmin;

                        // No other previous state should lead to the live state.
                        default:
                            return false;
                    }
                } else {
                    // If the workflow is not enabled on the content.
                    switch (currentContentStatus) {
                        // When the content was draft ("Save & Publish" action).
                        case Config.CONTENT_STATUS.DRAFT.value:
                            // Only admins or editors can save a publish a content.
                            return isUserAdmin || service.isUserAllowed('CUSTOM_CONTENT_EDIT', params);

                        // When the content was live ("Save" action).
                        case Config.CONTENT_STATUS.LIVE.value:
                            // Only admins or editors can save a live content.
                            return isUserAdmin || service.isUserAllowed('CUSTOM_CONTENT_EDIT', params);

                        // No other previous state should lead to the live state.
                        default:
                            return false;
                    }
                }

            // When the connected user wants to set the content to 'ARCHIVE' (basically, "Archive" it).
            case Config.CONTENT_STATUS.ARCHIVE.value:
                switch (currentContentStatus) {
                    // Don't allow to archive content that is already archived.
                    case Config.CONTENT_STATUS.ARCHIVE.value:
                        return false;

                    // Any other previous state can lead to the archived state.
                    default:
                        // Admin can always archive a content.
                        if (isUserAdmin) {
                            return true;
                        }

                        // Only archivers can archive a content.
                        return service.isUserAllowed('CUSTOM_CONTENT_ARCHIVE', params);
                }

            // When the connected user wants to set the content to 'UNARCHIVE' (basically, unarchive it).
            case Config.CONTENT_STATUS.UNARCHIVE.value:
                switch (currentContentStatus) {
                    // Only archived content can be unarchoved.
                    case Config.CONTENT_STATUS.ARCHIVE.value:
                        // Admin can always unarchive a content.
                        if (isUserAdmin) {
                            return true;
                        }

                        // Only archivers can unarchive a content.
                        return service.isUserAllowed('CUSTOM_CONTENT_ARCHIVE', params);

                    // Any other previous state cannot be unarchived.
                    default:
                        return false;
                }

            // There is no other case of new status.
            default:
                // So don't display the action button.
                return false;
        }
    }

    // ///////////////////////////

    service.canDeleteContents = canDeleteContents;
    service.canEditContent = canEditContent;
    service.canManageInstanceSettings = canManageInstanceSettings;
    service.canViewCommunityAnalytics = canViewCommunityAnalytics;
    service.checkIfMetadataIsAllowed = checkIfMetadataIsAllowed;
    service.getFeedsForAction = getFeedsForAction;
    service.getRoles = getRoles;
    service.hasRightToManageModules = hasRightToManageModules;
    service.hasRightToManageCommunities = hasRightToManageCommunities;
    service.isEditor = isEditor;
    service.isUserAllowed = isUserAllowed;
    service.SocialAdvocacy = SocialAdvocacy;
    service.statusActionAvailable = statusActionAvailable;

    // ///////////////////////////

    return service;
}

// ///////////////////////////

angular.module('Services').service('UserAccess', UserAccessService);

// ///////////////////////////

export { UserAccessService };
