/* istanbul ignore file */
// Ignoring coverage for this file since this will be reworked in Q2 2024
import React from 'react';

import { get } from '@lumapps/constants';
import { Text } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { NotificationsAPI } from '@lumapps/notifications/types';
import { getDifferenceInDays } from '@lumapps/utils/date/getDifferenceInDays';

const constants = get();

export interface UseWrongDeploymentWarningProps {
    isEnabled?: boolean;
    buildTime?: Date;
    baseDate?: Date;
    currentHost: string;
    currentFrontendVersion?: string;
    currentBackendVersion?: string;
    currentFrontendVersionNumber?: string;
    isFrontendOnlyUrl?: boolean;
}

const FRONTEND_VERSION_THRESHOLDS: Record<string, number> = {
    WARNING: 5,
    ERROR: 10,
};

/**
 * Displays a notification if the currently deployed frontend version is not the correct one.
 *
 * @param UseWrongDeploymentWarningProps
 */
export const useWrongDeploymentWarning = ({
    isEnabled = constants.isSandbox && !constants.isDev,
    buildTime = new Date(constants.buildTime),
    baseDate = new Date(),
    currentHost,
    currentFrontendVersion = constants.frontendVersion,
    currentBackendVersion = constants.backendVersion,
    currentFrontendVersionNumber = constants.frontendVersionNumber,
    isFrontendOnlyUrl,
}: UseWrongDeploymentWarningProps) => {
    const notifications = useNotification();

    React.useEffect(() => {
        /**
         * Just in case, always validate that we are not in production.
         */
        if (isEnabled) {
            const diffDays = getDifferenceInDays(buildTime, baseDate);

            let type: 'warning' | 'error' = 'warning';
            let notification: NotificationsAPI | undefined;

            /**
             * If the current version is older than the warning threshold, display a warning that goes away
             * in one minute.
             */
            if (diffDays >= FRONTEND_VERSION_THRESHOLDS.WARNING && diffDays < FRONTEND_VERSION_THRESHOLDS.ERROR) {
                notification = {
                    content: (
                        <Text as="p">
                            Frontend version dates from more than <b>{FRONTEND_VERSION_THRESHOLDS.WARNING} days ago</b>
                        </Text>
                    ),
                    timeout: 60000, // 1 minute in ms
                };
            }

            /**
             * If the current version is older than the error threshold, display a warning that goes away
             * in one hour (practically never).
             */
            if (diffDays >= FRONTEND_VERSION_THRESHOLDS.ERROR) {
                notification = {
                    content: (
                        <Text as="p">
                            Frontend version dates from more than <b>{FRONTEND_VERSION_THRESHOLDS.ERROR} days ago</b>
                        </Text>
                    ),
                    timeout: 60000 * 60, // 1 hour in ms
                };

                type = 'error';
            }

            /**
             * We warn the developer as well if the backend version is not master.
             */
            if (currentBackendVersion.indexOf('legacy:master') < 0) {
                notification = {
                    content: (
                        <Text as="p">
                            Current backend version is not <b>master nightly</b>
                        </Text>
                    ),
                };
            }

            if (notification) {
                notifications[type](notification);
            }
        }
    }, [
        isEnabled,
        buildTime,
        baseDate,
        currentFrontendVersion,
        currentHost,
        isFrontendOnlyUrl,
        notifications,
        currentFrontendVersionNumber,
        currentBackendVersion,
    ]);
};
