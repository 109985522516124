import type { PendoExtraProperties } from '@lumapps/metric/types';

import { AppId } from './app';
import { ICONS_CDN_PATH, ICON_PICKER_LIST_FILE } from './icons-cdn';
import { LoginProviderSyn } from './types';

const ENVS = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    TEST: 'test',
};

interface ConfigOptions {
    /**
     * The current used protocol.
     */
    protocol: string;
    /**
     * The current host.
     */
    host: string;
    /** when the frontend version was built */
    buildTime: string;
    /** The current application identifier */
    applicationId: AppId;
    /** The current application path */
    applicationHost: string;
    /** Path where our static  files are hosted */
    publicPath: string;
    baseUrl?: string;
    /**
     * The default langs that we allow for the main UI.
     * Note: this means that the product is translated in those languages.
     */
    defaultSupportedLanguages: string[];
    /**
     * The default langs that we allow for contribution.
     * Note: this does NOT mean that the product is translated in those languages. Just that people c an create content in
     * those languages.
     */
    defaultContributionLanguages: string[];
    /** default logo to be used if  the platform does not have one  */
    defaultLogo: string;
    defautlLogoUrl: string;
    /**
     * Current frontend version
     */
    frontendVersion: string;
    /**
     * Current default frontend version
     */
    defaultFrontendVersion?: string;
    /**
     * Deployed backend version
     */
    backendVersion: string;
    /**
     * deployed version number
     */
    frontendVersionNumber: string;
    frontendVersionName: string;
    /**
     * The default language for the app
     * It is also used to init moment's locale
     */
    defaultLanguage: string;
    /** Path of the icons on the CDN */
    iconsCDNPath: string;
    /** Path of the IconPicker icon list on the CDN */
    iconPickerListCDNPath: string;
    /** list of default platform colors */
    defaultColors: string[];
    /** URLs for the different lumapps microservices that do not go throught the monolith */
    services: Record<string, string | undefined>;
    /** default user avatar url to use when the user does not have an avatar set */
    defaultUserAvatar: string;
    /** whether we are in a production environment or not */
    isProd: boolean;
    /** whether it is a development environment or not */
    isDev: boolean;
    /** CDN host (does not take into account local environment) */
    cdnHost: string;
    /** current environment */
    environment?: string;
    /** id of the first rendered page. Comes from the backend and it is injected into the page */
    initialRenderedPage?: string;
    /** id of the first rendered page's skeleton. Comes from the backend and it is injected into the page */
    initialSkeletonPage?: string;
    /** URL of the logo the be used for this instance. Useful when we need to render the top bar before React has loaded */
    instanceLogo?: string;
    /** URL of the instance's logo, taking into account fallbacks */
    logo?: string;
    /** Name of the current instance. Useful for rendering the title of the current browser tab */
    instanceName: string;
    /** ID of the current instance. */
    instanceId: string;
    /** current instance. Only used for bootstrapping the application, it should not be used for any other reason */
    instance: any;
    /** CSS color for the nav bar. Useful when we need to render the top bar before React has loaded */
    instanceNavBackgroundColor?: string;
    /** CSS color for the top bar. Useful when we need to render the top bar before React has loaded */
    instanceTopBackgroundColor?: string;
    /** Determines whether we are in an Angular JS env or Full React env */
    isLegacyContext: boolean;
    /** ID of the current customer */
    customerId: string;
    /** Content id of the initial rendered page. If the page is not a content, this will be empty */
    initialContentId?: string;
    /** legacy application name, useful for interacting with the legacy application */
    legacyApplicationName?: string;
    /** whether the current browser is considered as legacy or not */
    isLegacyBrowser?: boolean;
    /** whether the client is running in integration test */
    isIntegrationTest?: boolean;
    /** entire top styles object sent from the backend. Useful when the init response does not have the styles for the current instance */
    topStyles?: Record<string, any>;
    /** entire nav styles object sent from the backend. Useful when the init response does not have the styles for the current instance */
    navStyles?: Record<string, any>;
    /** current lang for the logged in user */
    userLang: string;
    /** preferred languages used for contribution of the current connected user */
    userContributionLangs: string[];
    /** list of alternative langs for the user */
    userAlernativeLangs: string[];
    /** DANGER DANGER => access to global angular variable, if you are using this, you really know what you are doing */
    angular?: any;
    /** API host for the current instance */
    apiHost: string;
    useDynamicOverrides?: boolean;
    /** backend cell associated to the frontend */
    haussmannCell?: string;
    /** shared cell */
    sharedCell?: string;
    /** whether calling the haussmann cells directly is enabled or not */
    isDirectHaussmannCellCallEnabled?: boolean;
    /** Whether to use analytics haussmann api */
    isLumappsAnalyticsV2Enabled?: boolean;
    /** list of applied modes as query params */
    modes: string[];
    /** whether we are currently on sandbox or not */
    isSandbox: boolean;
    /**
     * Prevent automatically redirecting to the Page Not Found route as a default route when there is no matching path.
     * Only used for cypress back-office integration test script in `packages.json` to test redirections
     * to a front office page.
     */
    noDefaultPageNotFoundRedirection?: boolean;
    /**
     * user access token retrieved from the backend
     */
    accessToken?: string;
    /**
     * genesis template variables
     */
    synUrl: string;
    genesisNextUrl?: string;
    idpUrls: LoginProviderSyn[];
    pendoExtraProperties?: PendoExtraProperties;
    /** potential errors during the embedded user session initialization */
    embeddedSessionError?: string;
    /** contains the value of the lumapps domain i.e https://org-{orgId}.{env}.app.lumapps.com */
    lumappsDomain?: string;
}

/**
 * Configuration singleton
 */
let config: ConfigOptions | null = null;

declare global {
    interface Window {
        /** the current user information */
        CONNECTED_USER: {
            token: string;
        };
        /** the id of the current customer */
        CUSTOMER_ID: string;
        /** when the application was built */
        BUILD_TIME: string;
        /** the default version for the frontend web app */
        BUILD_DEFAULT_FRONTEND_VERSION: string;
        /** the backend version */
        BUILD_BACKEND_VERSION: string;
        /** the frontend version */
        BUILD_FRONTEND_VERSION: string;
        /** frontend version name */
        BUILD_FRONTEND_VERSION_NAME: string;
        /** the design system version */
        DESIGN_SYSTEM_VERSION: string;
        /** 'True' if the current browser is considered legacy */
        IS_LEGACY_BROWSER: string;
        /** Boolean flag in integration test env */
        IS_INTEGRATION_TEST?: boolean;
        /** the frontend application identifier */
        FRONT_APP_ID: string;
        APPLICATION: {
            HOST?: string;
            PROTOCOL?: string;
        };
        /** Haussmann cell url associated to the organization */
        HAUSSMANN_CELL?: string;
        /** shared cell url */
        SHARED_CELL?: string;
        /** Whether to use analytics haussmann api. This is a temporary variable (during analytics migration to hm) and to NOT USE. */
        IS_LUMAPPS_ANALYTICS_V2_ENABLED?: string;
        /** URL of the logo the be used for this instance. Useful when we need to render the top bar before React has loaded */
        INSTANCE_LOGO: string;
        /** Name of the current instance. Useful for rendering the title of the current browser tab */
        INSTANCE_NAME: string;
        /** ID of the current instance. */
        INSTANCE_ID: string;
        /** CSS color for the nav bar. Useful when we need to render the top bar before React has loaded */
        INSTANCE_NAV_BACKGROUND_COLOR?: string;
        /** CSS color for the top bar. Useful when we need to render the top bar before React has loaded */
        INSTANCE_TOP_BACKGROUND_COLOR?: string;
        /** entire top styles object sent from the backend. Useful when the init response does not have the styles for the current instance */
        TOP_STYLES?: Record<string, any>;
        /** entire nav styles object sent from the backend. Useful when the init response does not have the styles for the current instance */
        NAV_STYLES?: Record<string, any>;
        /** id of the first rendered page. Comes from the backend and it is injected into the page */
        PAGE?: string;
        /** id of the first rendered page's skeleton. Comes from the backend and it is injected into the page */
        SKELETON?: string;
        /** custom colors to be used on the page. this is usually a css text */
        CUSTOM_COLORS: string;
        /** HTML to be inserted to the page that was configured by the customer */
        INSTANCE_HEAD?: string;
        /** Instance object */
        INSTANCE: any;
        /** list of stylesheets to be injected into the page. They contain custom css code for the instance */
        STYLESHEETS?: string;
        /** lang of the current connected user */
        USER_LANG?: string;
        /** preferred languages used for contribution of the current connected user */
        USER_CONTRIBUTION_LANGS?: string;
        /** alternative langs separated by a comma */
        USER_ALTERNATIVES_LANGS?: string;
        /** access token */
        USER_ACCESS_TOKEN?: string;
        /** Content id of the initial rendered page. If the page is not a content, this will be empty */
        CONTENT_ID?: string;
        APPLICATION_NAME?: string;
        APP_ID: string;
        ERROR_REPORT_API_KEY: string;
        BUILD_VERSION_NUMBER: string;
        PUBLIC_PATH: string;
        API_HOST: string;
        _API_HOST: string;
        BASE_PATH: string;
        BASE_URL?: string;
        /** variable used for storybook in order to print alerts upon redirection */
        ALERT_RATHER_THAN_REDIRECT: boolean;
        /** modes added by query param to the application and sent over via the backend templating */
        MODES: string;
        /** Direct access to the angular application. During the migration, we will need to send events to the angular application to trigger a non migrated functionality */
        angular?: any;
        REDIRECTION_ORIGIN?: string;
        USE_DYNAMIC_OVERRIDES?: boolean;
        /**
         * Prevent automatically redirecting to the Page Not Found route as a default route when there is no matching path.
         * Only used for cypress back-office integration test script in `packages.json` to test redirections
         * to a front office page.
         */
        NO_DEFAULT_PAGE_NOT_FOUND_REDIRECTION?: boolean;
        PLATFORM_ANALYTICS_ENDPOINT?: string;
        FB?: string;
        /** The google analytics 3 function object */
        ga?: any;
        /** Google analytics 4 function object */
        ga4?: any;
        /** Internal tag */
        ANALYTICS_TAG?: string;
        /** Whether internal GA is enabled on the current platform */
        IS_LUMAPPS_INTERNAL_GA_ENABLED?: string;
        /** Variables used by Google Tag Manager (see @lumapps/metric packages for usage)
         * dataLayer is used for internal GTM
         * dataLayerCustom is used for client GTM (configured at site level)
         */
        dataLayer: any;
        dataLayerCustom: any;
        /** Variables used by the Login Application
         *  theses parameters are rendered by the Genesis templating
         */
        SYN_URL: string;
        GENESIS_NEXT_URL?: string;
        IDP_URLS: LoginProviderSyn[];
        PENDO_EXTRA_PROPERTIES?: PendoExtraProperties;
        EMBEDDED_SESSION_ERROR?: string;
        LUMAPPS_DOMAIN?: string;
    }

    interface Process {
        env: {
            PUBLIC_PATH: string;
            NODE_ENV: string;
        };
    }

    /**
     * Global variable passed at runtime by webpack (new apps only)
     * @see apps/configuration/environment/runtime.js
     * */
    const env: {
        isUsingMSWMocks?: boolean;
    };
}

// Try to get runtime env (only works on new app, crashes on legacy app)
const runtimeEnv = (() => {
    try {
        return env;
    } catch (err) {
        return undefined;
    }
})();

/**
 * Retrieves the current configuration if it was initialized. If it wasn't, it will
 * create it and assign it to the global variable
 */
const get = (): ConfigOptions => {
    if (config === null) {
        const host = window.APPLICATION?.HOST ?? window.location.host;
        const protocol = window.APPLICATION?.PROTOCOL ?? window.location.protocol;
        const noDefaultPageNotFoundRedirection = window.NO_DEFAULT_PAGE_NOT_FOUND_REDIRECTION;
        const publicPath = process.env.PUBLIC_PATH || '';
        const environment = process.env.NODE_ENV;
        const defaultLogo = 'assets/img/common/logo.svg';
        const defaultLanguage = 'en';
        const defautlLogoUrl = `${publicPath}${defaultLogo}`;
        const isProd = environment === ENVS.PRODUCTION;
        const isDev = environment === ENVS.DEVELOPMENT;

        config = {
            host,
            protocol,
            applicationId: window.FRONT_APP_ID as AppId,
            applicationHost: `${protocol}//${host}`,
            publicPath,
            buildTime: window.BUILD_TIME,
            defaultFrontendVersion:
                window.BUILD_DEFAULT_FRONTEND_VERSION && window.BUILD_DEFAULT_FRONTEND_VERSION !== ''
                    ? window.BUILD_DEFAULT_FRONTEND_VERSION
                    : undefined,
            defaultLogo,
            defautlLogoUrl,
            defaultLanguage,
            baseUrl: window.BASE_URL && window.BASE_URL !== '' ? window.BASE_URL : undefined,
            frontendVersion: window.BUILD_FRONTEND_VERSION,
            backendVersion: window.BUILD_BACKEND_VERSION,
            frontendVersionNumber: window.BUILD_VERSION_NUMBER,
            frontendVersionName: window.BUILD_FRONTEND_VERSION_NAME,
            isProd,
            isDev,
            cdnHost: isProd ? 'https://prod.cdn.lumapps.com/' : 'https://dev.cdn.lumapps.com/',
            environment,
            noDefaultPageNotFoundRedirection,
            /** This variable could come from the legacy side, that is why it is evaluated with and without the "_" */
            // eslint-disable-next-line no-underscore-dangle
            apiHost: window.API_HOST || window._API_HOST,
            accessToken: window.USER_ACCESS_TOKEN,
            initialRenderedPage: window.PAGE !== 'None' ? window.PAGE : undefined,
            initialSkeletonPage: window.SKELETON !== 'None' ? window.SKELETON : undefined,
            isLegacyBrowser: window.IS_LEGACY_BROWSER === 'True',
            isIntegrationTest: Boolean(window.IS_INTEGRATION_TEST),
            legacyApplicationName: window.APPLICATION_NAME,
            modes: window.MODES && window.MODES.split ? window.MODES.split(',') : [],
            instance: window.INSTANCE,
            instanceId: window.INSTANCE_ID,
            instanceNavBackgroundColor:
                window.INSTANCE_NAV_BACKGROUND_COLOR !== 'None' ? window.INSTANCE_NAV_BACKGROUND_COLOR : undefined,
            instanceTopBackgroundColor:
                window.INSTANCE_TOP_BACKGROUND_COLOR !== 'None' ? window.INSTANCE_TOP_BACKGROUND_COLOR : undefined,
            topStyles: window.TOP_STYLES || {},
            navStyles: window.NAV_STYLES || {},
            instanceLogo: window.INSTANCE_LOGO,
            instanceName: window.INSTANCE_NAME,
            isLegacyContext: !window.FRONT_APP_ID || window.FRONT_APP_ID === AppId.legacy,
            useDynamicOverrides: runtimeEnv?.isUsingMSWMocks || window.USE_DYNAMIC_OVERRIDES,
            initialContentId: window.CONTENT_ID,
            haussmannCell: window.HAUSSMANN_CELL,
            sharedCell: window.SHARED_CELL,
            pendoExtraProperties: window.PENDO_EXTRA_PROPERTIES || {},
            isLumappsAnalyticsV2Enabled: true,
            logo:
                Boolean(window.INSTANCE_LOGO) && defaultLogo === window.INSTANCE_LOGO
                    ? defautlLogoUrl
                    : window.INSTANCE_LOGO,
            angular: window.angular,
            userLang: window.USER_LANG || defaultLanguage,
            userContributionLangs: window.USER_CONTRIBUTION_LANGS
                ? window.USER_CONTRIBUTION_LANGS.split(',')
                : [defaultLanguage],
            userAlernativeLangs: window.USER_ALTERNATIVES_LANGS ? window.USER_ALTERNATIVES_LANGS.split(',') : [],
            defaultUserAvatar: `${publicPath}frontoffice/assets/img/common/default-avatar-128.jpg`,
            iconsCDNPath: ICONS_CDN_PATH,
            iconPickerListCDNPath: `${ICONS_CDN_PATH}/${ICON_PICKER_LIST_FILE}`,
            customerId: window.CUSTOMER_ID,
            isSandbox:
                window.location.href.indexOf('lumsites-sandbox.appspot.com') >= 0 ||
                window.location.href.indexOf('.dev.app.lumapps.com') >= 0,
            services: {
                analytics: window.PLATFORM_ANALYTICS_ENDPOINT,
            },
            defaultColors: [
                '#F44336',
                '#E91E63',
                '#9C27B0',
                '#673AB7',
                '#3F51B5',
                '#2196F3',
                '#03A9F4',
                '#00BCD4',
                '#009688',
                '#4CAF50',
                '#8BC34A',
                '#CDDC39',
                '#FFEB3B',
                '#FFC107',
                '#FF9800',
                '#FF5722',
                '#795548',
                '#9E9E9E',
                '#607D8B',
                '#000000',
                '#212121',
                '#757575',
                '#BDBDBD',
                '#E0E0E0',
                '#FFFFFF',
            ],
            defaultContributionLanguages: [
                'af',
                'am',
                'ar',
                'az',
                'be',
                'bg',
                'bn',
                'bs',
                'ca',
                'ceb',
                'cs',
                'cy',
                'da',
                'de',
                'el',
                'en',
                'es',
                'et',
                'fa',
                'fi',
                'fr',
                'fr_ca',
                'fy',
                'ga',
                'gl',
                'gu',
                'ha',
                'haw',
                'hi',
                'hmn',
                'hr',
                'hu',
                'hy',
                'id',
                'ig',
                'is',
                'it',
                'in',
                'iw',
                'ja',
                'jv',
                'ka',
                'kk',
                'km',
                'kn',
                'ko',
                'ku',
                'ky',
                'lo',
                'lt',
                'lv',
                'mg',
                'mi',
                'mk',
                'ml',
                'mn',
                'mr',
                'ms',
                'mt',
                'my',
                'ne',
                'nl',
                'no',
                'ny',
                'or',
                'pa',
                'pl',
                'ps',
                'pt',
                'pt_br',
                'ro',
                'ru',
                'rw',
                'sd',
                'si',
                'sk',
                'sl',
                'sm',
                'sn',
                'so',
                'sq',
                'sr',
                'st',
                'su',
                'sv',
                'sw',
                'ta',
                'te',
                'tg',
                'th',
                'tk',
                'tl',
                'tr',
                'tt',
                'ug',
                'uk',
                'ur',
                'uz',
                'vi',
                'xh',
                'yi',
                'yo',
                'zh',
                'zh_hk',
                'zh_tw',
                'zu',
            ],
            defaultSupportedLanguages: [
                'ar',
                'az',
                'bg',
                'cs',
                'de',
                'el',
                'en',
                'es',
                'fr',
                'fr_ca',
                'hi',
                'hr',
                'hu',
                'it',
                'in',
                'ja',
                'ko',
                'ku',
                'nl',
                'no',
                'pl',
                'pt',
                'pt_br',
                'ro',
                'ru',
                'sk',
                'sl',
                'sr',
                'sv',
                'th',
                'tl',
                'tr',
                'uk',
                'vi',
                'zh',
                'zh_hk',
                'zh_tw',
            ],
            synUrl: window.SYN_URL,
            genesisNextUrl: window.GENESIS_NEXT_URL,
            idpUrls: window.IDP_URLS,
            embeddedSessionError: window.EMBEDDED_SESSION_ERROR,
            lumappsDomain: window.LUMAPPS_DOMAIN,
        };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return config;
};

/** Pendo API Key */
const PENDO_API_KEY = '9ba8cd34-9fca-468b-472f-83937559a913';

/** Delay used for debounced api calls */
const DEBOUNCE_DELAY = 300;

/** Google recaptcha site key */
const G_RECAPTCHA_KEY = '6LfSkhsTAAAAAK9gMWpqAHGkLfCwyRTVTBpMe87C';

const MAX_IMAGE_SIZE = 1600;

export { get, G_RECAPTCHA_KEY, PENDO_API_KEY, DEBOUNCE_DELAY, ENVS, MAX_IMAGE_SIZE };
