import React, { useState } from 'react';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

import { track } from '@lumapps/metric/hooks/usePendo/track';

import { Targets, CustomComponent, CustomizationComponentProps, PLACEMENT } from '../../types';
import store from '../store';
import { CustomizationApp } from './CustomizationApp';
import { ReactComponents } from './index';

interface RenderComponentOptions {
    /** component to render */
    component: CustomComponent;
    /** props that will be passed on to the component */
    props?: any;
}

export const renderChildren = (children: any, renderComponent: (options: RenderComponentOptions) => any) => {
    let childrenToRender: any[] = [];

    if (isArray(children)) {
        childrenToRender = children.map(renderComponent);
    } else if (children.component) {
        childrenToRender = renderComponent(children);
    } else {
        childrenToRender = children;
    }

    return childrenToRender;
};

/**
 * From a given component and a list of props, this function generates a
 * React element from the given options. If there are React.ReactNode props, this will
 * iterate each of those keys and generate the React element tree for it.
 * @param options - render component options
 */
export const renderComponent = (options: RenderComponentOptions) => {
    try {
        const { component, props = {} } = options;
        const childrenToRenderByProp: Record<string, any[] | undefined> = {};

        /**
         * If the props children is defined, we need to determine the type
         * of that children and from that determine if we need to execute this
         * function recursively or we can just go ahead and render a simple component.
         *
         * Same thing for the before and after props.
         */
        const reactProps = ['children', 'before', 'after', 'label', 'header', 'body', 'footer'];

        reactProps.forEach((prop) => {
            if (props[prop]) {
                const childrenToRender = renderChildren(props[prop], renderComponent);

                childrenToRenderByProp[prop] = childrenToRender;
            }
        });

        // eslint-disable-next-line react/no-children-prop
        return React.createElement(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ReactComponents[component],
            {
                ...props,
                ...childrenToRenderByProp,
            },
        );
    } catch (excp) {
        // eslint-disable-next-line no-console
        console.warn(excp);
    }

    return null;
};

/**
 * Component that renders a custom component.
 * @param props - customization component props
 */
export const CustomizationComponent: React.FC<CustomizationComponentProps> = (props) => {
    /**
     * This component retrieves if there are any customizations for the given props and renders
     * the customization if needed. It will retrieve the customization from the store, and then
     * it will determine whether it needs to render something right away or wait.
     *
     * Why would that happen?
     * A customization can be added to page at any time, meaning that the code that adds the customization
     * could be on the HTML while the page is rendering, or it could be executed on the fly from the developer
     * tools. That is why the source of truth of the customization is the store, rather than the internal
     * state of this component. That is why we do not use the result of the `useState` hook, but rather always
     * take a look at the customizations store.
     */
    const { target, placement, context = {} } = props;
    const customization = store.getCustomComponent({ target, placement }) || {
        toRender: {},
        toRenderWithContext: undefined,
    };
    const [, setToRender] = useState(customization.toRender);
    /**
     * We determine if a customization is valid on whether it has something and either the customization has a toRender prop,
     * or the toRenderWithContext is a function.
     */
    const isCustomized =
        Boolean(customization) && (!isEmpty(customization.toRender) || isFunction(customization.toRenderWithContext));

    React.useEffect(() => {
        /**
         * When this component is mounted, we need to:
         * - send a track so that we can know which customizations are used on the platform.
         */
        if (isCustomized) {
            track({
                name: `Customizations | ${target}`,
                props: {
                    placement,
                },
            });
        }

        /**
         * We also create a subscription for this customization on the customizations store side. If there
         * is a change to that customization (an API response, a timer, etc), we will need to rerender this component
         * and in order to do so, provide as a callback to the subscription the useState set function. That way,
         * if there is a change on the store, this component rerender will trigger and the change will be displayed.
         */
        store.subscribeToCustomization({ target, placement, callback: setToRender });
    }, [target, placement, isCustomized]);

    /**
     * If we have already determined that there is a customization available for this component, meaning that
     * the customization was already available on first render, we use that one directly.
     */
    let componentToRender = null;

    /**
     * There are 2 types of custom renders for components. One with context and the other ones without. In the end,
     * it means that either the customization returns the custom component directly (it uses the toRender prop) or
     * it is a function that will return the component (it uses toRenderWithContext). Here we determine which type of component
     * we need to use and execute either the toRenderWithContext and validate that it returns a component, or use the toRender directly
     */
    if (isCustomized && customization.toRenderWithContext) {
        try {
            const componentWithContext = customization.toRenderWithContext(context);

            /**
             * If the returned value from executing toRenderWithContext is a valid component, we use it for rendering the component.
             * In the eventual scenario where the developer that created this customization made a mistake, we will return null and
             * avoid displaying anything. If there is an error while executing the custom renderWithContext, we catch it and log it.
             */
            if (componentWithContext && componentWithContext.component) {
                componentToRender = componentWithContext;
            } else {
                return null;
            }
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.warn('Error while rendering custom component', excp, props);
            return null;
        }
    } else if (isCustomized && customization.toRender && customization.toRender.component) {
        componentToRender = customization.toRender;
    }

    const toRender = componentToRender ? renderComponent(componentToRender) : null;

    /**
     * If the customized component is the APP, we need to use a specific wrapper in order to display this component
     * with a specific class, so we can place it correctly
     */
    if (target === Targets.APP && [PLACEMENT.LEFT, PLACEMENT.RIGHT].includes(placement) && isCustomized) {
        return <CustomizationApp placement={placement}>{toRender}</CustomizationApp>;
    }

    return toRender;
};
