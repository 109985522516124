import { CSSProperties } from 'react';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { MAX_IMAGE_SIZE } from '@lumapps/constants';
import { isImageUrlResized, makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { resizeImage } from '@lumapps/medias/utils/resizeImage';

import {
    FontSizeStyle,
    FontColorStyle,
    FontWeightStyle,
    HeightStyle,
    FlexItemStyle,
    FlexContainerStyle,
    BackgroundStyles,
    BorderStyles,
    SpacingStyles,
    ShadowStyles,
} from './types';

const DEFAULT_OPACITY = 14;

/** Styles supported by the computeStyle function. */
export type AllStyles = FontSizeStyle &
    FontWeightStyle &
    FontColorStyle &
    HeightStyle &
    FlexItemStyle &
    FlexContainerStyle &
    BackgroundStyles &
    BorderStyles &
    SpacingStyles &
    ShadowStyles;

export const MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES = {
    borderRadius: ['borderBottomLeftRadius', 'borderBottomRightRadius', 'borderTopLeftRadius', 'borderTopRightRadius'],
    borderColor: ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
    borderWidth: ['borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth'],
    margin: ['marginLeft', 'marginRight', 'marginTop', 'marginBottom'],
    padding: ['paddingLeft', 'paddingRight', 'paddingBottom', 'paddingTop'],
} as const;

export type ShorthandProperties = keyof typeof MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES;

/**
 * Compute content styles into CSSProperties.
 */
export const computeStyles = (
    styles: AllStyles | string | undefined,
): Omit<CSSProperties, ShorthandProperties> | undefined => {
    if (!styles || typeof styles === 'string') {
        return undefined;
    }

    const {
        // Non valid css properties:
        shadowElevation,
        defaultShadowElevation,
        shadowOpacity = DEFAULT_OPACITY,
        backgroundImage: backgroundUrl,
        borderColor,
        borderRadius,
        borderWidth,
        padding,
        margin,
        // Valid css properties:
        ...otherStyles
    } = styles;

    const cssProperties: Omit<CSSProperties, ShorthandProperties> = omitBy(otherStyles, isUndefined);

    const shorthandProperties = {
        borderColor,
        borderRadius,
        borderWidth,
        padding,
        margin,
    };

    for (const [key, value] of Object.entries(shorthandProperties)) {
        const properties = MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES[key as ShorthandProperties];

        if (value !== undefined && properties) {
            for (const property of properties) {
                (cssProperties[property] as any) = value;
            }
        }
    }

    const shadowElevationComputed = shadowElevation ?? defaultShadowElevation;

    if (shadowElevationComputed === 0 || shadowOpacity === 0) {
        cssProperties.boxShadow = 'none';
    } else if (shadowElevationComputed) {
        const opacity = (shadowOpacity / 100).toFixed(2);
        cssProperties.boxShadow = `0 ${2 ** shadowElevationComputed}px ${
            2 ** (shadowElevationComputed + 1)
        }px rgba(0,0,0,${opacity})`;
    }

    if (backgroundUrl) {
        cssProperties.backgroundImage = `url(${makeSecuredMediaURLRelative(
            isImageUrlResized(backgroundUrl) ? backgroundUrl : resizeImage(backgroundUrl, MAX_IMAGE_SIZE),
        )})`;
    }

    return isEmpty(cssProperties) ? undefined : cssProperties;
};
