import React, { useState } from 'react';

import { margin } from '@lumapps/classnames';
import { LanguageSwitcher } from '@lumapps/languages/components/LanguageSwitcher';
import { Emphasis } from '@lumapps/lumx/react';
import { CodeMirrorTextArea, MODES } from '@lumapps/style/components/CodeMirrorTextArea';

/**
 * So, CodeMirror is a particular library. When a value has changed on the code mirror text area, the callback
 * setFooterForLanguage will be executed, BUT, language will be the first used language on this component. Why?
 * CodeMirror events cannot be changed once the CodeMirror instance was already defined. That means that the
 * function that we pass into code mirror that updates the language for the footer always has the first language
 * that the function was originally created with. The only way around this was to save in memory the currently
 * selected language and use that one.
 */
let currentSelectedLanguage = '';

export interface FooterEditorOptions {
    /** current user language */
    currentLanguage: string;
    /** values for the footer */
    footer?: Record<string, string>;
    /** callback executed once the footer has changed */
    onChange: (values: Record<string, string>) => void;
    /** whether syntax highlighting should be applied or not */
    shouldApplyHighlightSyntax?: boolean;
}

/**
 * Component used on Instance Style in order to edit a footer. This component should only be used ONCE, this was
 * not meant to be reused.
 * @param FooterEditorOptions
 * @returns FooterEditor
 */
const FooterEditor: React.FC<FooterEditorOptions> = ({
    currentLanguage,
    footer,
    onChange,
    shouldApplyHighlightSyntax = false,
}) => {
    const [language, setLanguage] = useState(currentLanguage);

    currentSelectedLanguage = language || currentLanguage;

    const setSelectedLanguage = (lang: string) => {
        setLanguage(lang);
        currentSelectedLanguage = language;
    };

    const setFooterForLanguage = (footerForCurrentLanguage: string) => {
        onChange({
            ...footer,
            [currentSelectedLanguage]: footerForCurrentLanguage,
        });
    };

    return (
        <>
            <LanguageSwitcher
                onChange={setSelectedLanguage}
                defaultLanguage={language}
                shouldShowLeftIcon={false}
                shouldShowRightIcon={false}
                emphasis={Emphasis.medium}
                isVisible
            />
            <CodeMirrorTextArea
                id="footer-editor"
                mode={MODES.html}
                hasFinishedLoading
                shouldApplyHighlightSyntax={shouldApplyHighlightSyntax}
                value={footer ? footer[language] : ''}
                setValue={setFooterForLanguage}
                wrapperClassName={margin('top', 'big')}
            />
        </>
    );
};

export { FooterEditor };
