/* eslint-disable no-lone-blocks */
import cloneDeep from 'lodash/cloneDeep';
import lodashGet from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import lodashSet from 'lodash/set';

import { get } from '@lumapps/constants';
import { filterValues } from '@lumapps/utils/object/filterValues';
import { ObjectPaths } from '@lumapps/utils/types/ObjectPaths';

import { TranslateKey, TranslateObject, TranslateAndReplaceReplacements, TranslatableObject } from './types';

declare const window: any;

const Config = get();

export const isTranslateKey = (variableToCheck: any): variableToCheck is TranslateKey =>
    (variableToCheck as TranslateKey) !== undefined && typeof variableToCheck === 'string';

export const isTranslateObject = (variableToCheck: any): variableToCheck is TranslateObject => {
    if (!variableToCheck) {
        return false;
    }
    const variable = variableToCheck as TranslateObject;
    const keys = Object.keys(variable);

    return (
        variable !== undefined && keys.length > 0 && variable.key === undefined && variable.replacements === undefined
    );
};

/**
 * Check that the object is a TranslatableObject (v2 translations)
 */
export const isTranslatableObject = (obj: any): obj is TranslatableObject => {
    return obj !== null && typeof obj === 'object' && typeof obj.translations === 'object';
};

export const isTranslateAndReplace = (variableToCheck: any): variableToCheck is TranslateAndReplaceReplacements => {
    const theVar = variableToCheck as TranslateAndReplaceReplacements;

    return Boolean(theVar && theVar.key && theVar.replacements);
};

export const isAvailableInSitesLanguages = (locale: string, instanceLanguages: string[] | undefined) =>
    instanceLanguages && instanceLanguages.includes(locale);

/**
 * Returns the language to use for translations.
 */
export const getTranslationLanguage = (preferredLanguage: string | undefined) => {
    return preferredLanguage ?? Config.userLang;
};

export const getDefaultLanguage = () => {
    return Config.defaultLanguage;
};

/** Returns the first possible alternative language */
export const getFirstAlternativeLanguage = () => {
    const userAlternativeLangs = window.USER_ALTERNATIVES_LANGS
        ? window.USER_ALTERNATIVES_LANGS.split(',')
        : [Config.userLang];

    return userAlternativeLangs[0];
};

/**
 * This function receives a TranslatableObject and makes sure that all the required fields
 * for a TranslatableObject are present. Some APIs do not return all fields, like `translations`,
 * so using this function will ensure that all the fields are there.
 * @param translateObject
 * @returns TranslatableObject with complete info
 */
export const normalizeV2Format = (translateObject?: TranslatableObject): TranslatableObject => {
    if (!translateObject) {
        return { translations: {} };
    }

    const { lang, value, translations } = translateObject || {};

    if (!lang || !value) {
        return translateObject;
    }

    return {
        lang,
        value,
        translations: translations
            ? {
                  ...translations,
                  [lang]: value,
              }
            : {
                  [lang]: value,
              },
    };
};

/**
 * Format a translateObject into a TranslatableObject (API v1 -> v2)
 * Default language is based on currentLanguage, fallbackLanguage (availableLanguage) or the first available language
 * @param translateObject API v1 translations object
 * @param currentLanguage Current language to retrieve from translateObject
 * @param availableLanguages List of all available language. Used as fallback for currentLanguage, filter the list of translations, to remove unsupported ones.
 */
export function translateToApiV2Format<T = string>(
    translateObject: TranslateObject<T>,
    currentLanguage: string,
    availableLanguages?: string[],
): TranslatableObject<T> {
    const firstTranslatedLang =
        Object.keys(translateObject).find((lang) => Boolean(translateObject[lang])) || currentLanguage;

    const fallBackLang =
        availableLanguages &&
        availableLanguages.find(
            (lang) => Object.keys(translateObject).includes(lang) && Boolean(translateObject[lang]),
        );

    const defaultLanguage = translateObject[currentLanguage] ? currentLanguage : fallBackLang || firstTranslatedLang;

    return {
        lang: defaultLanguage,
        value: translateObject[defaultLanguage],
        translations: availableLanguages
            ? filterValues(
                  (translation, key) => [...availableLanguages, defaultLanguage].includes(key) && Boolean(translation),
                  translateObject,
              )
            : translateObject,
    };
}

export const translateFromApiV2Format = <T>(translateObject: TranslatableObject<T>): TranslateObject => {
    return translateObject.translations || {};
};

export const determineTranslationsCompleteness = (languages: string[], values: TranslateObject<string>) => {
    const languagesTranslationCompleteness: Record<string, { isComplete: boolean; isPartial: boolean }> = {};

    languages.forEach((lang) => {
        if (!languagesTranslationCompleteness[lang]) {
            languagesTranslationCompleteness[lang] = {
                isComplete: Boolean(values[lang]),
                isPartial: Boolean(values[lang]),
            };
        }

        languagesTranslationCompleteness[lang] = {
            isComplete: languagesTranslationCompleteness[lang].isComplete && Boolean(values[lang]),
            isPartial: languagesTranslationCompleteness[lang].isPartial || Boolean(values[lang]),
        };
    });

    return languagesTranslationCompleteness;
};

/**
 * Transform an api v2 language string to an api v1 language string
 *
 * `pt-BR` will be transformed into `pt_br`
 */
export function toCompatibleLanguage(language: string) {
    return language.replace(/^([a-z]{2,3})-([A-Z]{2})$/g, (match, country, region) =>
        `${country}_${region}`.toLowerCase(),
    );
}

/**
 * Transform an api v1 language string to an api v2 language string
 *
 * `pt_br will be transformed into `pt-BR`
 */
export function toApiV2Language(language: string) {
    return language.replace(
        /^([a-z]{2,3})_([a-z]{2})$/g,
        (match, country, region) => `${country}-${region.toUpperCase()}`,
    );
}

/**
 * Checks whether a given translation object is empty or not, meaning that if it has translations
 * for at least one language. If a language is provided, it will check the translations for that
 * specific language only. Translations that include only spaces are considered as empty.
 *
 * @param obj TranslateObject
 * @param lang specific language
 * @returns boolean
 */
export const isTranslatableObjectEmpty = (obj: TranslatableObject | TranslateObject, lang?: string) => {
    const values = isTranslatableObject(obj) ? obj.translations : obj;

    if (lang) {
        return !values[lang];
    }

    const translations = Object.values(values).filter((value) => {
        const trimmedValue = value?.trim() || '';
        return trimmedValue.length > 0;
    });

    return translations.length === 0;
};

/**
 * Deep maps an object's / array's keys and values.
 *
 * We are using it to transform responses sent by the Play API and formatted in Api v2 format into
 * Api v1 format, compatible with the application (readable by the `<TranslatableTextField />` component for example).
 *
 * If a key is in the new language format (eg: `pt-BR`):
 * it will be transformed to api v1 format (eg: `pt_br`)
 *
 * If a value has `language` or `lang` as key and it is in the new language format (eg: `pt-BR`):
 * it will be transformed to api v1 format (eg: `pt_br`)
 */
export function withApiv1Languages<T>(obj: T): T {
    if (Array.isArray(obj)) {
        return obj.map(withApiv1Languages) as unknown as T;
    }

    if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [toCompatibleLanguage(key)]:
                    // eslint-disable-next-line no-nested-ternary
                    typeof value === 'object' && value !== null
                        ? withApiv1Languages(value)
                        : typeof value === 'string' && ['language', 'lang'].includes(key)
                        ? toCompatibleLanguage(value)
                        : value,
            }),
            {} as T,
        );
    }

    return obj;
}

/**
 * Deep maps an object's / array's keys and values.
 *
 * We are using it to transform interface data and formatted in Api v1 format into
 * Api v2 format, compatible with the back end
 *
 * If a key is in the legacy language format (eg: `pt_br`):
 * it will be transformed to api v2 format (eg: `pt-BR`)
 *
 * If a value has `language` or `lang` as key and it is in the legacy language format (eg: `pt_br`):
 * it will be transformed to api v2 format (eg: `pt-BR`)
 */
export function withApiv2Languages<T>(obj: T): T {
    if (Array.isArray(obj)) {
        return obj.map(withApiv2Languages) as unknown as T;
    }

    if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [toApiV2Language(key)]:
                    // eslint-disable-next-line no-nested-ternary
                    typeof value === 'object' && value !== null
                        ? withApiv2Languages(value)
                        : typeof value === 'string' && ['language', 'lang'].includes(key)
                        ? toApiV2Language(value)
                        : value,
            }),
            {} as T,
        );
    }

    return obj;
}

/**
 * The library we use to pluralize words (make-plural) uses the same language code as Lumapps,
 * except for Portugese (pt_pt in Lumapps): pt-PT
 * and for Brazilian Portuguese (pt_br in Lumapps): pt
 *
 * we need to transform these values to pluralize words in these languages with make-plural
 */
export const translateToMakePluralFormat = (lang: string): string => {
    const compatibleLanguage = toCompatibleLanguage(lang);

    const ptLanguages: Record<string, string> = {
        pt_pt: 'pt-PT',
        pt: 'pt-PT',
        pt_br: 'pt',
    };

    if (Object.keys(ptLanguages).includes(compatibleLanguage)) {
        return ptLanguages[compatibleLanguage];
    }

    return lang;
};

interface CleanTranslatableObjectOptions {
    /** Whether empty strings should be removed from translations */
    removeEmptyStrings?: boolean;
}
/**
 * Returns a clean translatable object
 * * Will return undefined if given object is not a translatable object
 * * Will return undefined if given object has an empty translations object
 * * With removeEmptyStrings option, will first remove all empty strings from translations.
 */
export const cleanTranslatableObject = <T extends TranslatableObject>(
    translatableObject?: T,
    options: CleanTranslatableObjectOptions = {},
) => {
    if (!isTranslatableObject(translatableObject)) {
        return undefined;
    }

    const { translations } = translatableObject;

    if (options.removeEmptyStrings) {
        // Remove all empty translations
        const onlyDefinedTranslations = omitBy(translations, (translation) => !translation);

        /** Build the new translatable object */
        const newTranslatableObject: T | undefined =
            translations && isEmpty(onlyDefinedTranslations)
                ? /** If no translation is left, completely remove the translation object */
                  undefined
                : /** if some translation remains, return them with the rest of the object */
                  { ...translatableObject, translations: onlyDefinedTranslations };

        return newTranslatableObject;
    }

    if (isEmpty(translations)) {
        return undefined;
    }

    return translatableObject;
};

/**
 * Cleanup translations of the given resource.
 * By default only removes all translations that are set with an empty object.
 * Options can be passed to also remove empty strings.
 */
export const removeEmptyTranslationsFromResource = <T extends object>(
    /** The resource to clean */
    resource: T,
    /** The paths of nodes to clean */
    paths: Array<ObjectPaths<T>>,
    /** Options for node cleanup */
    cleanOptions?: CleanTranslatableObjectOptions,
): T => {
    if (!paths || paths.length === 0) {
        return resource;
    }

    /** Clone the resource to modify it */
    const clonedResource = cloneDeep(resource);

    /** Update each path given, if possible. */
    paths.forEach((path) => {
        // Get the translation object for given path.
        const translatableObject = lodashGet(clonedResource, path);
        // Remove all empty translations
        const newTranslation = cleanTranslatableObject(translatableObject, cleanOptions);

        // Set the value on the given path
        lodashSet(clonedResource, path, newTranslation);
    });
    return clonedResource;
};

/**
 * Standardize TranslateObject to be used with both Legacy and React lang format.
 * This is required for some specific languages keys that are hyphenated: 'pt-BR', 'zh-HK', ...
 * In the legacy, translations are stored using the V1 format (pt_br, zh_hk).
 * However, within the NGI context, the language key are in V2 format (pt-BR, zh-HK).
 * With this helper, we get both versions of the translation.
 * @example
 * ```
 * standardizeTranslateObject({ 'en': 'Title', 'fr': 'Titre', 'pt_br': 'Título' })
 * // return { 'en': 'Title', 'fr': 'Titre', 'pt-BR': 'Título', 'pt_br': 'Título' }
 *
 * standardizeTranslateObject({ 'en': 'Title', 'fr': 'Titre', 'pt-BR': 'Título' })
 * // return { 'en': 'Title', 'fr': 'Titre', 'pt-BR': 'Título', 'pt_br': 'Título' }
 *
 * standardizeTranslateObject({ 'en': 'Title' })
 * // return { 'en': 'Title' }
 * ```
 * */
export const standardizeTranslateObject = (obj: TranslateObject | undefined): TranslateObject | undefined =>
    isTranslateObject(obj)
        ? (Object.keys(obj).reduce(
              (acc, cur) => ({
                  ...acc,
                  [cur]: obj[cur],
                  [toApiV2Language(cur)]: obj[cur],
                  [toCompatibleLanguage(cur)]: obj[cur],
              }),
              {},
          ) as TranslateObject)
        : undefined;

/**
 * Convert all values of a translatable object to
 * another format using a converter function.
 * */
export const convertApiV2Translations = <T = string, V = unknown>(
    /** The translatable object to convert */
    source: TranslatableObject<T>,
    /** The converting function to apply to each node */
    converter: (value: T | undefined) => V,
) => {
    const mainLang = source.lang;

    /**
     * Convert the main value so that we can reuse it in the translations and only do it once.
     */
    const mainValue = converter(source.value);

    return {
        lang: mainLang,
        value: mainValue,
        translations: source.translations
            ? mapValues(source.translations, (value, key) => {
                  if (key === mainLang && mainValue) {
                      return mainValue;
                  }

                  if (value) {
                      return converter(value);
                  }

                  return '';
              })
            : {},
    };
};
