(function IIFE() {
    // ///////////////////////////

    function WidgetUserListAdvancedSettingsController(
        $rootScope,
        $timeout,
        Config,
        InitialSettings,
        Instance,
        Utils,
        Widget,
    ) {
        'ngInject';

        const advancedSettings = this;

        // ///////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        // ///////////////////////////

        /**
         * Contains the selected user directory in the settings.
         *
         * @type {Object}
         */
        advancedSettings.selectedUserDirectories = undefined;

        /**
         * Contains the list of user directories received by the back.
         *
         * @type {Array}
         */
        advancedSettings.userDirectories = InitialSettings.USER_DIRECTORIES;

        /**
         * Contains the list of enabled user fields.
         *
         * @type {Array}
         */
        advancedSettings.userFields = undefined;

        // ///////////////////////////

        /**
         * Services and utilities.
         */
        advancedSettings.Utils = Utils;

        // ///////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        // ///////////////////////////

        /**
         * Switch user field state.
         *
         * @param {Object} field The field to toggle.
         */
        function switchFieldState(field) {
            const { properties } = Widget.getCurrent();

            // Get index for remove or check if not already in.
            const index = _.findIndex(properties.displayFields, {
                uuid: field.uuid,
            });

            // If enable and not in list then push else remove.
            if (field.$enable && index === -1) {
                properties.displayFields.push(field);
            } else {
                properties.displayFields.splice(index, 1);
            }

            advancedSettings.updateWidget();
        }

        /**
         * Update the field list, from the selected user directory.
         *
         * @param {boolean} [fromInit=false] Indicates if we are initializing the list for the first time.
         */
        function updateFieldList(fromInit) {
            const { properties } = Widget.getCurrent();

            if (angular.isUndefinedOrEmpty(properties.displayFields)) {
                properties.displayFields = [];
            }

            // Set the default fields when no configuration from widget or when switching directories.
            const setDefaultFields = !fromInit || angular.isUndefined(properties.displayFields);

            // Reset the list of fields first.
            if (fromInit) {
                advancedSettings.userFields = undefined;
            }

            if (angular.isDefinedAndFilled(properties.userDirectory)) {
                const componentEnabled = _.map(properties.displayFields, 'uuid');

                let secondaryField = Instance.getProperty(Config.INSTANCE_PROPERTIES.USER_BLOCK_DEFAULT_FIELD);
                secondaryField = secondaryField || 'primaryEmail';

                // List the fields that are configured in the UD and pre-enable some accordingly to rules below
                advancedSettings.userFields = _.chain(properties.userDirectory.template.components)
                    .filter(function filterUserFields(component) {
                        component.properties = component.properties || {};

                        // Do not list the secondary field
                        if (_.get(component, 'properties.boundMap.text') === secondaryField) {
                            return false;
                        }

                        // If setting the default fields, enable the component if the component's "displayInList" is true 
                        // Else enable the component if it is already included in the widget's properties
                        if (setDefaultFields && component.properties.displayInList) {
                            properties.displayFields.push(component);
                            component.$enable = true;
                        } else {
                            component.$enable = _.includes(componentEnabled, component.uuid);
                        }

                        return component.widgetType !== InitialSettings.WIDGET_TYPES.TIP;
                    })
                    .value();
            } else {
                Utils.empty(properties.displayFields);
            }

            advancedSettings.updateWidget();
        }

        /**
         * Update the widget on settings change.
         */
        function updateWidget() {
            $timeout(function delayBroadcast() {
                $rootScope.$broadcast('widget-user-list-settings', Widget.getCurrent().uuid);
            });
        }

        function updateUserDirectory(value) {
            Widget.getCurrent().properties.userDirectory = value;
            Widget.getCurrent().properties.displayFields = [];
            updateFieldList();
        }

        // ///////////////////////////

        advancedSettings.switchFieldState = switchFieldState;
        advancedSettings.updateFieldList = updateFieldList;
        advancedSettings.updateWidget = updateWidget;
        advancedSettings.updateUserDirectory = updateUserDirectory;

        // ///////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            const currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};

            advancedSettings.updateFieldList(true);
        }

        init();
    }

    // ///////////////////////////

    angular
        .module('Controllers')
        .controller('WidgetUserListAdvancedSettingsController', WidgetUserListAdvancedSettingsController);
})();
