import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiBullhorn } from '@lumapps/lumx/icons';
import { Placement, Offset } from '@lumapps/lumx/react';
import { useRouteMatching } from '@lumapps/router';
import { UserProgramsStatuses } from '@lumapps/sa-programs/ducks/userPrograms';
import { programDashboardRoute } from '@lumapps/sa-programs/routes';
import { Program } from '@lumapps/sa-programs/types';
import { useTranslate } from '@lumapps/translations';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { SOCIAL_ADVOCACY_NAVIGATION_SCOPE } from '../../constant';
import { SA_NAVIGATION } from '../../keys';
import { SAMenuPrograms } from './SAMenuPrograms';

export interface SAMenuProps {
    programs: Program[];
    // Whether SA is enabled on this site
    isSAEnabled: boolean;
    // Whether the current user infos should be loaded
    shouldGetUser: boolean;
    // Whether the current user has access to SA
    canAccessSa: boolean;
    fetchProgramsStatus: UserProgramsStatuses;
    placement?: Placement;
    getUser(): void;
    fetchUserPrograms(): void;
    offset?: Offset;
    /** custom styles passed down to the SA icon */
    styles?: Record<string, any>;
}

const CLASSNAME = 'header-dropdown';
const SAMenu: React.FC<SAMenuProps> = ({
    programs,
    shouldGetUser,
    isSAEnabled,
    canAccessSa,
    fetchProgramsStatus,
    getUser,
    fetchUserPrograms,
    styles = {},
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(SOCIAL_ADVOCACY_NAVIGATION_SCOPE);
    const isSADashboardPage = useRouteMatching(programDashboardRoute);

    React.useEffect(() => {
        if (isSAEnabled && shouldGetUser) {
            getUser();
        }
    }, [isSAEnabled, shouldGetUser, getUser]);

    const onToggle = (isExpanded: boolean) => {
        if (isExpanded && fetchProgramsStatus === UserProgramsStatuses.initial) {
            fetchUserPrograms();
        }
    };

    if (!isSAEnabled || !canAccessSa) {
        return null;
    }

    return (
        <ActionMenu
            label={translateKey(SA_NAVIGATION.DASHBOARD_ICON_TOOLTIP)}
            className={element('toggle')}
            icon={mdiBullhorn}
            // in order for 'isSelected' to work, we must set 'color' to undefined
            color={isSADashboardPage ? undefined : styles.themeColor}
            isSelected={isSADashboardPage}
            triggerProps={{
                onToggle,
                popoverWidth: {
                    width: REAL_SIZE_FOR_LUMX_SIZE.xxl,
                },
            }}
            {...get({ element: 'header', action: 'button' })}
        >
            <ErrorBoundary>
                <SAMenuPrograms
                    fetchProgramsStatus={fetchProgramsStatus}
                    programs={programs}
                    fetchUserPrograms={fetchUserPrograms}
                />
            </ErrorBoundary>
        </ActionMenu>
    );
};

export { SAMenu };
