import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiChevronDown, mdiChevronUp } from '@lumapps/lumx/icons';
import { Icon, Placement, Popover, Size, Text, Theme, Tooltip } from '@lumapps/lumx/react';
import { useOverflowTooltipLabel } from '@lumapps/utils/hooks/useOverflowTooltipLabel';

import { ViewMode } from '../../constants';
import { CLASSNAME as ITEM_CLASSNAME } from '../NavigationItem';

import './index.scss';

export interface NavigationSectionProps {
    /** Classname that will be used for the nav wrapping element */
    className?: string;
    /** Items inside the section */
    children: React.ReactNode;
    /** the element used to create data attributes */
    element?: string;
    /** Icon (SVG path). */
    icon?: string;
    /** Label content. */
    label: string | React.ReactNode;
    /** the scope used to create data attributes */
    scope?: string;
    /** Theme that will be applied to the element, either Theme.dark or Theme.light */
    theme?: Theme;
    /** The view mode that will be used to display the nav, either 'horizontal' or 'vertical', defaults to 'vertical' */
    viewMode?: ViewMode;
    /** Whether the section should be opened on component mount. Default to false */
    openOnMount?: boolean;
}

const CLASSNAME = 'navigation-section';

const NavigationSection = React.forwardRef<HTMLLIElement, NavigationSectionProps>((props, ref) => {
    const {
        children,
        className,
        element,
        label,
        theme,
        scope,
        viewMode,
        icon,
        openOnMount = false,
        ...forwardedProps
    } = props;

    const isHorizontalViewMode = viewMode === ViewMode.horizontal;
    const { block, element: elementClassname } = useClassnames(CLASSNAME);
    const { element: elementItemClassname } = useClassnames(ITEM_CLASSNAME);
    const [isOpen, setIsOpen] = React.useState(openOnMount);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    // Compute a tooltip label based on label content if text is overflowing
    const { labelRef, tooltipLabel } = useOverflowTooltipLabel();

    const { get } = useDataAttributes(scope ?? '');
    const dataAttributes = scope && element ? get({ element }) : {};

    const childrenArray = React.Children.toArray(children);

    if (!childrenArray.length) {
        return null;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        if (!openOnMount) {
            setIsOpen(false);
        }
    }, [openOnMount, viewMode]);

    return (
        <li
            className={block(
                {
                    horizontal: isHorizontalViewMode,
                },
                [className, ITEM_CLASSNAME],
            )}
            ref={ref}
            {...forwardedProps}
        >
            <Tooltip label={tooltipLabel} placement={Placement.TOP}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <button
                    aria-controls={`section-${label}`}
                    aria-expanded={isOpen}
                    className={elementItemClassname('link', {
                        dark: theme === Theme.dark,
                    })}
                    ref={buttonRef}
                    {...dataAttributes}
                    onClick={(event) => {
                        setIsOpen(!isOpen);
                        event.stopPropagation();
                    }}
                    onKeyUp={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                            event.stopPropagation();
                        }
                    }}
                    type="button"
                >
                    {icon ? (
                        <Icon className={elementItemClassname('icon')} icon={icon} size={Size.xs} theme={theme} />
                    ) : null}
                    <Text as="span" className={elementItemClassname('label')} ref={labelRef}>
                        {label}
                    </Text>
                    <Icon
                        className={elementItemClassname('icon', elementClassname('chevron'))}
                        icon={isOpen ? mdiChevronUp : mdiChevronDown}
                    />
                </button>
            </Tooltip>
            {isOpen &&
                (isHorizontalViewMode ? (
                    <Popover
                        anchorRef={buttonRef}
                        isOpen={isOpen}
                        placement={Placement.BOTTOM_START}
                        usePortal={false}
                        closeOnClickAway
                        closeOnEscape
                        onClick={() => setIsOpen(false)}
                        onClose={() => setIsOpen(false)}
                        onKeyUp={(event: React.KeyboardEvent) => {
                            if (event.key === 'Enter') {
                                setIsOpen(false);
                            }
                        }}
                        zIndex={996}
                    >
                        <ul className={elementClassname('drawer-popover')} id={`section-${label}`}>
                            {childrenArray.map((child) => React.cloneElement(child as React.ReactElement, { scope }))}
                        </ul>
                    </Popover>
                ) : (
                    <ul className={elementClassname('drawer')} id={`section-${label}`}>
                        {childrenArray.map((child) =>
                            React.cloneElement(child as React.ReactElement, { theme, scope }),
                        )}
                    </ul>
                ))}
        </li>
    );
});

NavigationSection.displayName = 'NavigationSection';

export { NavigationSection };
