import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import loFind from 'lodash/find';
import map from 'lodash/map';
import union from 'lodash/union';
import without from 'lodash/without';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { channelNameIsValid, getProviderNameFromId } from 'components/components/chat/utils/chat_utils';

const CHAT_SELECT_CHANNEL_MODE = {
    CREATE: {
        id: 'chatSelectModeCreate',
        label: 'ADMIN.COMMUNITY.SETUP.FIELD.CHAT.CREATE_CHANNEL',
    },
    SELECT: {
        id: 'chatSelectModeSelect',
        label: 'ADMIN.COMMUNITY.SETUP.FIELD.CHAT.SELECT_CHANNEL',
    },
};

/////////////////////////////

function CommunityBuilderController(
    $q,
    $rootScope,
    $scope,
    $timeout,
    AbstractPicker,
    Calendar,
    Chat,
    Community,
    CommunityForm,
    CommunityRequest,
    CommunityTemplate,
    CommunityWizardConfiguration,
    Config,
    Document,
    DrivePicker,
    Features,
    Feed,
    FormValidation,
    IdentityProvidersConstant,
    InitialSettings,
    Instance,
    LxDialogService,
    LxNotificationService,
    MediaConstant,
    ModuleAdmin,
    Translation,
    UploaderAction,
    User,
    UserPicker,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line lumapps/file-format
    const vm = $scope;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Debounce delay for search requests.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEBOUNCE_DELAY = 300;

    /**
     * The maximum number of calendars to get for each request.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_REQUEST_RESULTS = 50;

    /**
     * The maximum number of chats list elements for each request.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_CHATS_REQUEST_RESULTS = 30;

    /**
     * The current community object being used in the dialog.
     *
     * @type {Object}
     */
    let _community;
    /**
     * The community calendar identifier defined.
     *
     * @type {number}
     */
    let _communityCalendarId;

    /**
     * The list of community managers and contributors defined in the community.
     *
     * @type {Array}
     */
    let _communityUsers = [];

    /**
     * The list of community editors and publishers groups identifier defined in the community.
     *
     * @type {Array}
     */
    let _communityGroupIds = [];

    /**
     * The privacy defined on the community before editing it.
     *
     * @type {string}
     */
    let _communityPrivacy;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list of available providers.
     * Only Micorsoft OneDrive provider is allowed for the moment.
     *
     * TODO [Greg] : need to be rework when Google users will use the new Media picker.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.ALLOWED_PROVIDERS = [MediaConstant.PROVIDERS.microsoft, MediaConstant.PROVIDERS.google];

    /**
     * Identifier of the drive folder picker.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.FOLDER_PICKER_ID = 'community-folder-picker';

    /**
     * Whether to display the 'Advanced' area in the users tab or not.
     *
     * @type {boolean}
     */
    vm.allowAdvancedMode = false;

    /**
     * Allow Google options (calendar & drive) - depends on the Features of the customer and the connected User.
     *
     * @type {boolean}
     */
    vm.allowGoogleOptions = false;

    /**
     * Allow Microsoft options (drive) - depends on the Features of the customer and the connected user.
     *
     * @type {boolean}
     */
    vm.allowMicrosoftOptions = false;

    /**
     * Allow almost every users except Microsoft users (i.e. : Google, OKTA, external).
     *
     * @type {boolean}
     */
    vm.allowEveryOneButMicrosoft = false;

    /**
     * The list of calendars that can be selected in the options area.
     *
     * @type {Array}
     */
    vm.availableCalendars = [];

    /**
     * Calendar search text.
     *
     * @type {string}
     */
    vm.calendarSearchText = '';

    /**
     * The identifier of the selected calendar.
     *
     * @type {string}
     */
    vm.calendarId = undefined;

    /**
     * The type of google drive being used.
     *
     * @type {string}
     */
    vm.driveType = undefined;

    /**
     * The drive types choices in the radio button group.
     *
     * @type {Object}
     */
    vm.driveTypeChoices = {
        createDrive: 'create-drive',
        createTeamDrive: 'create-team-drive',
        selectDrive: 'select-drive',
    };

    /**
     * The list of chat providers that can be selected in the options area.
     *
     * @type {Array}
     */
    vm.availableChatProviders = [];

    /**
     * The name of the selected chat provider.
     *
     * @type {string}
     */
    vm.chatProvider = undefined;

    /**
     * The list of chat user spaces that can be selected in the options area.
     *
     * @type {Array}
     */
    vm.availableChatUserSpaces = [];

    /**
     * User spaces more state.
     *
     * @type {boolean}
     */
    vm.chatUserSpaceHasMore = false;

    /**
     * User spaces cursor.
     *
     * @type {boolean}
     */
    vm.chatUserSpaceCursor = undefined;

    /**
     * User spaces more page.
     *
     * @type {integer}
     */
    vm.chatUserSpacePage = 1;

    /**
     * User spaces more loading state.
     *
     * @type {boolean}
     */
    vm.userSpacesMoreLoading = false;

    /**
     * The name of the selected chat user space.
     *
     * @type {string}
     */
    vm.chatUserSpace = undefined;

    /**
     * Chat channel modes.
     *
     * @type {Array}
     */
    vm.chatChannelModes = CHAT_SELECT_CHANNEL_MODE;

    /**
     * Chat channel mode selected.
     *
     * @type {Object}
     */
    vm.chatChannelMode = undefined;

    /**
     * Chat channel name.
     *
     * @type {string}
     */
    vm.chatChannelName = undefined;

    /**
     * If the Chat channel name is valid.
     *
     * @type {boolean}
     */
    vm.chatChannelNameValid = undefined;

    /**
     * Error message if the Chat channel name is not valid.
     *
     * @type {string}
     */
    vm.chatChannelNameError = undefined;

    /**
     * If the Chat channel name is checking on remote.
     *
     * @type {boolean}
     */
    vm.chatChannelNameChecking = false;

    /**
     * If the Chat channel name is valid.
     *
     * @type {boolean}
     */
    vm.chatChannelTimeout = undefined;

    /**
     * The list of feeds to exclude from the feed selectors.
     *
     * @type {Object}
     */
    vm.exceptList = {
        editors: Feed.FEED_ALL_AND_PUBLIC,
        publishers: [],
    };

    /**
     * Whether the advanced mode is currently being displayed or not.
     *
     * @type {boolean}
     */
    vm.isAdvancedModeDisplayed = false;

    /**
     * Indicates if the calendar list is currently loading.
     *
     * @type {boolean}
     */
    vm.isCalendarListLoading = false;

    /**
     * Inicates if the community if build to use Microsoft One Drive.
     *
     * @type {boolean}
     */
    vm.isOneDriveCommunity = false;

    /**
     * The maximum number of users to display in the user lists.
     *
     * @type {number}
     */
    vm.maxUsersToDisplay = 5;

    /**
     * The "Other" calendar choice.
     *
     * @type {Object}
     */
    vm.otherCalendar = {
        id: generateUUID(),
        summary: Translation.translate('FRONT.CALENDAR.OTHER_CALENDAR'),
    };

    /**
     * Contains the list of feedkeys to add to the list of auto subscribed feedkey.
     * This list will be merged with the `subscribedFeedKeys` list of the community on save.
     *
     * @type {Array}
     */
    vm.subscribedFeedKeys = [];

    /**
     * The types of user selection for admins / users etc...
     *
     * @type {Object}
     */
    vm.selectionTypes = {
        feed: 'feed-selector',
        user: 'user-picker',
    };

    /**
     * The identifier of the community thumbnail uploader.
     *
     * @type {string}
     */
    vm.thumbnailUploaderId = 'community-image-uploader';

    /**
     * The widget types object.
     *
     * @type {Object}
     */
    vm.WIDGET_TYPES = InitialSettings.WIDGET_TYPES;

    /**
     * A map of options and whether they should de displayed or not.
     *
     * @type {Object}
     */
    vm.showOptions = {
        calendar: false,
        dashboard: false,
        media: false,
        post: false,
        useChat: false,
    };

    /**
     * Creation status of the community.
     *
     * @type {boolean}
     */
    vm.isNew = false;

    /**
     * Whether the folder picker is open or not.
     *
     * @type {Boolean}
     */
    vm.driveFolderPickerIsOpen = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Calendar = Calendar;
    vm.Community = Community;
    vm.CommunityForm = CommunityForm;
    vm.CommunityRequest = CommunityRequest;
    vm.CommunityWizardConfiguration = CommunityWizardConfiguration;
    vm.Config = Config;
    vm.DrivePicker = DrivePicker;
    vm.Features = Features;
    vm.Feed = Feed;
    vm.FormValidation = FormValidation;
    vm.Instance = Instance;
    vm.ModuleAdmin = ModuleAdmin;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;
    vm.contains = includes;
    vm.Chat = Chat;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Remove properties based on the Google options selected.
     */
    function _cleanGoogleOptions() {
        // Clear the calendar if it exists.
        if (!_community.showOptions.calendar) {
            _community.createCalendar = false;
            _community.calendarId = '';
        }

        // Clear the drive if it exists.
        if (_community.showOptions.media) {
            _community.createDrive = vm.driveType === vm.driveTypeChoices.createDrive;
            _community.createTeamDrive = vm.driveType === vm.driveTypeChoices.createTeamDrive;

            if (_community.createDrive || _community.createTeamDrive) {
                vm.clearSelectedDriveFolder();
            }
        } else {
            _community.createDrive = false;
            _community.createTeamDrive = false;
            vm.clearSelectedDriveFolder();
        }

        delete _community.showOptions;
    }

    /**
     * Remove the postStatuses if post idea is not set on the community.
     */
    function _cleanPostStatuses() {
        if (
            includes(_community.postTypes, Community.availablePostTypes.IDEA.value) ||
            angular.isUndefined(_community.postStatuses)
        ) {
            return;
        }

        delete _community.postStatuses;
    }

    /**
     * Clean admins and users properties depending on user selection type.
     */
    function _cleanUsers() {
        if (vm.adminSelectionType === vm.selectionTypes.user) {
            _community.editors = [];
        } else if (vm.adminSelectionType === vm.selectionTypes.feed) {
            _community.adminKeys = [];
        }
    }

    /**
     * Clean chat values depending on user selection type.
     */
    function _cleanChat() {
        if (!vm.showOptions.useChat) {
            _community.hasChat = false;
            vm.chatUserSpace = undefined;
            _community.chatUserSpace = undefined;
            vm.chatProvider = undefined;
            _community.chatProvider = undefined;
        }
    }

    /**
     * Clean the community object before calling the save endpoint.
     *
     * @return {Object} The community object without the unneeded properties.
     */
    function _cleanCommunityBeforeSave() {
        // Make sure we don't define a privacy if feature is disabled.
        if (!Features.hasFeature('advanced_community')) {
            _community.privacy = undefined;
        }

        _cleanUsers();
        _cleanChat();
        _cleanGoogleOptions();
        _cleanPostStatuses();

        // We only really care about tagsDetails.
        delete _community.tags;

        return _community;
    }

    /**
     * Clear the selected calendar.
     *
     * @param {boolean} keepCreateCalendar Indicates if the createCalendar attribute should be reset or not.
     */
    function _clearSelectedCalendar(keepCreateCalendar) {
        const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());

        _community.calendarId = '';
        currentCommunity.calendarId = '';

        _community.createCalendar = keepCreateCalendar ? _community.createCalendar : false;
        currentCommunity.createCalendar = keepCreateCalendar ? currentCommunity.createCalendar : false;

        _community.hasCalendarId = false;
        currentCommunity.hasCalendarId = false;

        vm.calendarId = '';
    }

    /**
     * Clear the selected chat.
     *
     * @param {boolean} keepUseChat Indicates if the useChat attribute should be reset or not.
     */
    function _clearSelectedChat(keepUseChat) {
        const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());

        _community.chatProvider = undefined;
        currentCommunity.chatProvider = undefined;

        _community.useChat = keepUseChat ? _community.useChat : false;
        currentCommunity.useChat = keepUseChat ? currentCommunity.useChat : false;

        _community.hasChat = false;
        currentCommunity.hasChat = false;

        vm.chatProvider = undefined;
        vm.chatUserSpace = undefined;
        vm.chatChannelName = undefined;
        vm.chatChannelNameValid = undefined;
        vm.chatChannelNameError = undefined;
    }

    /**
     * Get a unique list of groups of editors and publishers in the community.
     *
     * @param  {Object} community The current community.
     * @return {Array}  The list of groups.
     */
    function _getAllUniqueGroups(community) {
        const groupIds = angular.fastCopy(community.editors || []);

        angular.forEach(community.publishers, function forEachgroup(group) {
            if (!includes(groupIds, group)) {
                groupIds.push(group);
            }
        });

        return groupIds;
    }

    /**
     * Get a unique list of administrators and contributors of the community.
     *
     * @param  { Object } community The current community.
     * @return { Array }  The list of users.
     */
    function _getAllUniqueUsers(community) {
        const users = angular.fastCopy(community.adminsDetails || []);
        const usersId = map(users, 'id');

        angular.forEach(community.usersDetails, function forEachUser(user) {
            if (!includes(usersId, user.id)) {
                users.push(user);
            }
        });

        return users;
    }

    /**
     * Get the list of added users during the edition of the community.
     *
     * @param  {Object} community The community we need to compare users.
     * @return {Array}  The list of added users during the edition.
     */
    function _getAddedUsers(community) {
        const users = _getAllUniqueUsers(community);
        const communityUsersId = map(_communityUsers, 'id');

        return filter(users, function findUser(user) {
            return !includes(communityUsersId, user.id);
        });
    }

    /**
     * Get the list of added groups during the edition of the community.
     *
     * @param  {Object} community The current community.
     * @return {Array}  The list of groups.
     */
    function _getAddedGroups(community) {
        const groupIds = _getAllUniqueGroups(community);

        return filter(groupIds, function findUser(groupId) {
            return !includes(_communityGroupIds, groupId);
        });
    }

    /**
     * Get the list of removed users during the edition of the community.
     *
     * @param  {Object} community The community we need to compare users.
     * @return {Array}  The list of removed users during the edition.
     */
    function _getDeletedUsers(community) {
        const users = _getAllUniqueUsers(community);
        const usersId = map(users, 'id');

        return filter(_communityUsers, function filterUser(user) {
            return !includes(usersId, user.id);
        });
    }

    /**
     *  Get the list of removed groups during the edition of the community.
     *
     * @param  {Object} community The current community.
     * @return {Array}  The list of removed groups.
     */
    function _getDeletedGroups(community) {
        const groupIds = _getAllUniqueGroups(community);

        return filter(_communityGroupIds, function filterUser(groupId) {
            return !includes(groupIds, groupId);
        });
    }

    /**
     * Get a particular calendar in the list of available calendars from its id.
     *
     * @param  {string} id The identifier of the calendar to find in the list.
     * @return {Object} The calendar object we're after.
     */
    function _getCalendar(id) {
        return loFind(vm.availableCalendars, {
            id,
        });
    }

    /**
     * Get the first page of calendar list available to the current user.
     */
    function _getCalendarList() {
        if (!Features.hasFeature('advanced_community')) {
            return;
        }

        vm.availableCalendars.length = 0;
        vm.isCalendarListLoading = true;

        const queryParams = {
            maxResults: _MAX_REQUEST_RESULTS,
        };

        if (angular.isDefinedAndFilled(vm.calendarSearchText)) {
            queryParams.query = vm.calendarSearchText;
        }

        Calendar.filterize(queryParams, (calendars) => {
            if (angular.isArray(calendars)) {
                vm.availableCalendars.push(...calendars);
            }

            vm.isCalendarListLoading = false;
        });
    }

    const _debouncedGetCalendarList = debounce(_getCalendarList, _DEBOUNCE_DELAY);

    /**
     * Get next page of calendar list.
     *
     * @return {Promise} Next page of calendar list.
     */
    function getNextCalendars() {
        const defer = $q.defer();

        if (!Calendar.hasMore()) {
            defer.resolve([]);

            return defer.promise;
        }

        vm.isCalendarListLoading = true;

        Calendar.nextPage(
            (calendars) => {
                if (angular.isArray(calendars)) {
                    const newCalendars = filter(calendars, (calendar) => {
                        const { id } = calendar;

                        return !loFind(vm.availableCalendars, { id });
                    });
                    defer.resolve(newCalendars);
                }

                vm.isCalendarListLoading = false;
            },
            () => {
                defer.resolve([]);
                vm.isCalendarListLoading = false;
            },
        );

        return defer.promise;
    }

    /**
     * Check if a document has a title in the current lang.
     *
     * @param  {Object}  doc The document to check for a title.
     * @return {boolean} If the document has title in the current lang or not.
     */
    function _hasFolderTitle(doc) {
        const mediaContent = Document.getMediaContentByLang(doc, true);

        return angular.isDefinedAndFilled(get(mediaContent, 'title'));
    }

    /**
     * Return the title of the folder it's name if title is not defined.
     *
     * @param  {Object} folder The folder we want to retrieve the title or name.
     * @return {string} The displayable name of the folder;
     */
    function _getFolderName(folder) {
        const mediaContent = Document.getMediaContentByLang(folder, true);

        return _hasFolderTitle(folder) ? get(mediaContent, 'title') : get(mediaContent, 'name');
    }

    /**
     * Retrieve a list of the pending access requests for the community.
     */
    function _getPendingAccessRequests() {
        if (!Features.hasFeature('advanced_community') || !Community.isReadOnly(_community)) {
            return;
        }

        CommunityRequest.filterize(
            {
                communityId: _community.id,
                maxResults: 10,
                requestStatus: CommunityRequest.STATUS_TYPES.PENDING,
            },
            undefined,
            undefined,
            vm.listKeyCommunityRequest,
        );
    }

    /**
     * Get the first page of chat provider list available to the current user.
     */
    function getChatProviderList() {
        if (!Features.hasFeature('advanced_community')) {
            return;
        }

        vm.availableChatProviders.length = 0;

        Chat.listProviders().then((res) => {
            if (!angular.isDefinedAndFilled(res, 'providers')) {
                return;
            }

            if (angular.isArray(res.providers)) {
                angular.forEach(res.providers, function selectAvailableProvider(provider) {
                    if (angular.isDefinedAndFilled(provider, 'name')) {
                        vm.availableChatProviders.push({
                            id: provider.name,
                            name: getProviderNameFromId(provider.name),
                        });
                    }
                });
            }
        }, Utils.displayServerError);
    }

    /**
     * Get the chat user spaces list available to the current chat provider.
     */
    function getChatUserSpaceList() {
        if (!Features.hasFeature('advanced_community')) {
            return;
        }

        if (
            !angular.isDefinedAndFilled(_community, 'chat') ||
            !angular.isDefinedAndFilled(_community.chat, 'chatProvider')
        ) {
            return;
        }

        vm.availableChatUserSpaces.length = 0;

        Chat.listUserSpaces({
            maxResults: _MAX_CHATS_REQUEST_RESULTS,
            provider: _community.chat.chatProvider,
        }).then((res) => {
            if (angular.isDefinedAndFilled(res, 'page')) {
                vm.chatUserSpacePage = res.page;
            } else {
                vm.chatUserSpacePage = 1;
            }

            if (angular.isDefinedAndFilled(res, 'more')) {
                vm.chatUserSpaceHasMore = res.more;
            } else {
                vm.chatUserSpaceHasMore = false;
            }

            if (angular.isDefinedAndFilled(res, 'cursor')) {
                vm.chatUserSpaceCursor = res.cursor;
            } else {
                vm.chatUserSpaceCursor = undefined;
            }

            if (!angular.isDefinedAndFilled(res, 'items')) {
                return;
            }

            if (angular.isArray(res.items)) {
                angular.forEach(res.items, function selectAvailableProvider(userSpace) {
                    if (angular.isDefinedAndFilled(userSpace, 'name')) {
                        vm.availableChatUserSpaces.push(userSpace);
                    }
                });
            }
        }, Utils.displayServerError);
    }

    /**
     * Load the next page of user spaces.
     *
     * @return {Promise} The call promise.
     */
    function getNextChatUserSpacesList() {
        // eslint-disable-next-line angular/deferred
        const defer = $q.defer();

        if (!vm.chatUserSpaceHasMore) {
            defer.resolve([]);

            return defer.promise;
        }

        if (
            !angular.isDefinedAndFilled(_community, 'chat') ||
            !angular.isDefinedAndFilled(_community.chat, 'chatProvider')
        ) {
            return defer.promise;
        }

        vm.userSpacesMoreLoading = true;

        Chat.listUserSpaces({
            maxResults: _MAX_CHATS_REQUEST_RESULTS,
            page: vm.chatUserSpacePage + 1,
            provider: _community.chat.chatProvider,
        })
            .then((res) => {
                if (angular.isDefinedAndFilled(res, 'page')) {
                    vm.chatUserSpacePage = res.page;
                } else {
                    vm.chatUserSpacePage = 1;
                }

                if (angular.isDefinedAndFilled(res, 'more')) {
                    vm.chatUserSpaceHasMore = res.more;
                } else {
                    vm.chatUserSpaceHasMore = false;
                }

                if (angular.isDefinedAndFilled(res, 'cursor')) {
                    vm.chatUserSpaceCursor = res.cursor;
                } else {
                    vm.chatUserSpaceCursor = undefined;
                }

                if (!angular.isDefinedAndFilled(res, 'items')) {
                    return defer.promise;
                }

                defer.resolve(get(res, 'items', []));
            }, Utils.displayServerError)
            .finally(() => {
                vm.userSpacesMoreLoading = false;
            });

        return defer.promise;
    }

    /**
     * Initialize the Google options (calendar and drive).
     */
    function _initGoogleOptions() {
        if (Features.hasFeature('advanced_community')) {
            const user = User.getConnected();

            vm.allowGoogleOptions = User.isGoogle(user) || User.isLoggedWithGoogle(user);
            vm.allowMicrosoftOptions = User.isMicrosoft(user) || User.isLoggedWithMicrosoft(user);
            vm.allowEveryOneButMicrosoft = !User.isMicrosoft(user);
            vm.showOptions.media = _community.hasDriveFolder;
            vm.showOptions.securedMedia = _community.securedRepository;
            vm.showOptions.calendar = _community.hasCalendarId;

            if (vm.showOptions.calendar) {
                _community.createCalendar = false;
            }

            if (_community.hasDriveFolder) {
                _community.createDrive = false;
                _community.createTeamDrive = false;

                vm.driveType = vm.driveTypeChoices.selectDrive;
            } else {
                vm.driveType = vm.driveTypeChoices.createDrive;
            }
        } else {
            vm.allowGoogleOptions = false;
            vm.allowMicrosoftOptions = false;
            vm.showOptions.securedMedia = false;
            vm.showOptions.media = false;
            vm.showOptions.calendar = false;
        }
    }

    /**
     * Control if privacy is not OPEN, so its RESTRICTED or READONLY.
     *
     * @param  {Object}  [community] The current community
     * @return {boolean} Is privacy not OPEN so RESTRICTED or READONLY.
     */
    function _isPrivacyNotOpen(community) {
        community = community || _community;

        return community.privacy !== Community.privacyTypes.open;
    }

    /**
     * Determines if the community privacy setting has changed during the edition, if the privacy is now open or
     * if it's not open anymore.
     *
     * @param  {Object}  community The current community.
     * @return {boolean} Whether the community privacy has changed from or to Open community.
     */
    function _isCommunityPrivacyEdited(community) {
        const openPrivacy = vm.Community.privacyTypes.open;

        return (
            (_communityPrivacy !== openPrivacy && community.privacy === openPrivacy) ||
            (_communityPrivacy === openPrivacy && community.privacy !== openPrivacy)
        );
    }

    /**
     * Set controller calendar id.
     */
    function _setCalendarId() {
        // Get calendar info, if no calendar set, use the 'other' calendar value.
        if (angular.isDefinedAndFilled(get(_community, 'calendarId'))) {
            const calendar = _getCalendar(_community.calendarId);

            vm.calendarId = angular.isDefinedAndFilled(calendar) ? _community.calendarId : vm.otherCalendar.id;
        }
    }

    /**
     * Set controller chat provider name.
     */
    function _setChatProvider() {
        getChatProviderList();
        if (angular.isDefinedAndFilled(get(_community, 'chat'))) {
            if (angular.isDefinedAndFilled(get(_community.chat, 'chatProvider'))) {
                vm.chatProvider = _community.chat.chatProvider;
            }
        }
    }

    /**
     * Set controller chat user space name.
     */
    function _setChatUserSpace() {
        getChatUserSpaceList();
        if (angular.isDefinedAndFilled(get(_community, 'chat'))) {
            if (angular.isDefinedAndFilled(get(_community.chat, 'chatId'))) {
                vm.chatUserSpace = _community.chat.chatId;
            }
        }
    }

    /**
     * Set channel name from chan Id
     */
    function getChannelNameFromId(chanId) {
        Chat.getChannelById(vm.chatProvider, vm.chatUserSpace, chanId).then((result) => {
            if (result && result.channel && result.channel.name) {
                vm.chatChannelName = result.channel.name;
                vm.chatChannelNameValid = true;
                vm.chatChannelNameError = undefined;
            }
        });
    }

    /**
     * Set controller chat channel mode and name.
     */
    function _setChatChannel() {
        if (angular.isDefinedAndFilled(get(_community, 'chat'))) {
            if (angular.isDefinedAndFilled(get(_community.chat, 'chanId'))) {
                vm.chatChannelMode = CHAT_SELECT_CHANNEL_MODE.SELECT.id
                getChannelNameFromId(_community.chat.chanId);
            }
            else {
                vm.chatChannelMode = CHAT_SELECT_CHANNEL_MODE.CREATE.id
                vm.chatChannelName = undefined;
                vm.chatChannelNameValid = false;
                vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            }
        }
        else {
            vm.chatChannelMode = CHAT_SELECT_CHANNEL_MODE.CREATE.id
            vm.chatChannelName = undefined;
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
        }
    }

    /**
     * Set lists of allowed feeds in feed selectors depending on the visibility of the community and whether it has
     * any Google feature.
     */
    function _setFeedSelectors() {
        vm.exceptList.publishers = [];
        vm.restrictEnableFollowList = undefined;

        if (_isPrivacyNotOpen()) {
            vm.exceptList.publishers = Feed.FEED_ALL_AND_PUBLIC;
            vm.restrictEnableFollowList = _community.publishers || [];
        }
    }

    /**
     * Determine if the share calendar dialog should be displayed after saving community.
     *
     * @param  {Object}  community The current community.
     * @return {boolean} Whether the dialog should be displayed.
     */
    function _displayShareDialog(community) {
        const addedGroups = _getAddedGroups(community);
        const deletedGroups = _getDeletedGroups(community);

        return (
            Community.isOutlookCommunity(community) &&
            (_communityCalendarId !== community.calendarId ||
                _isCommunityPrivacyEdited(community) ||
                angular.isDefinedAndFilled(_getAddedUsers(community)) ||
                angular.isDefinedAndFilled(_getDeletedUsers(community)) ||
                angular.isDefinedAndFilled(addedGroups) ||
                angular.isDefinedAndFilled(deletedGroups))
        );
    }

    /**
     * Control if feed groups contain ALL or PUBLIC feeds.
     *
     * @param  {Array}   feedGroups The feed groups to control.
     * @return {boolean} Is feedGroups with ALL or PUBLIC feed.
     */
    function _isFeedGroupsWithAllOrPublic(feedGroups) {
        if (angular.isUndefinedOrEmpty(feedGroups)) {
            return false;
        }

        return angular.isDefinedAndFilled(intersection(Feed.FEED_ALL_AND_PUBLIC, feedGroups));
    }

    /**
     * Clean publishers depending on user selection community privacy type.
     */
    function _clearPublishersAndEditors() {
        const hasGoogleFeature = vm.showOptions.calendar || vm.showOptions.media;

        if (!_isPrivacyNotOpen() && !hasGoogleFeature) {
            return;
        }

        if (_isFeedGroupsWithAllOrPublic(union(_community.publishers, _community.editors))) {
            _community.publishers = without(_community.publishers, Feed.ALL.id, Feed.PUBLIC.id);
            _community.editors = without(_community.editors, Feed.ALL.id, Feed.PUBLIC.id);
        }
    }

    /**
     * Setting some default values on the community object in case they're missing.
     */
    function _setDefaultCommunityValues() {
        _community.properties = _community.properties || {};
        _community.properties.categories = _community.properties.categories || [];
        _community.groups = _community.groups || [];

        if (!angular.isArray(_community.tagsDetails)) {
            _community.tagsDetails = [];
        }

        // Only set a default privacy if the feature is on.
        if (Features.hasFeature('advanced_community')) {
            _community.privacy = _community.privacy || Community.privacyTypes.restricted;
        }
    }

    /**
     * Create the default pages and widgets.
     *
     * The community have a templates list that are sub template for the community.
     */
    function _buildDefaultPages() {
        const community = Community.getCurrent(ModuleAdmin.getListKey());
        const templatesNames = map(Community.availableViews, 'id');

        if (angular.isUndefinedOrEmpty(community.templates)) {
            community.templates = [];
        }

        angular.forEach(templatesNames, function buildSubTemplate(target) {
            const existing = loFind(community.templates, {
                functionalInnerId: target,
            });
            if (angular.isDefinedAndFilled(existing)) {
                return;
            }

            const tpl = CommunityTemplate.getDefaultPageTemplate(target);

            tpl.functionalInnerId = target;
            community.templates.push(tpl);
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Validate the options steps.
     *
     * @return {string|boolean} The error message if there is an error, true if not.
     */
    function optionsStepValidator() {
        if (!angular.isArray(_community.postTypes) || _community.postTypes.length === 0) {
            return Translation.translate('FRONT.COMMUNITY.POST_TYPES_EMPTY');
        }

        // Check the chat option
        if (
            vm.showOptions.useChat &&
            (!_community.chat.chatProvider || !_community.chat.chatId || !vm.chatChannelNameValid)
        ) {
            return Translation.translate('FRONT.COMMUNITY.CHAT_OPTIONS_NOT_VALID');
        }

        // Clear Publishers and Editors in case google options has changed.
        _clearPublishersAndEditors();

        return true;
    }

    /**
     * Validate the permissions steps.
     *
     * @return {string|boolean} The error message if there is an error, true if not.
     */
    function permissionsStepValidator() {
        const isEditorFeedGroupOption = vm.adminSelectionType === vm.selectionTypes.feed;

        if (isEditorFeedGroupOption && angular.isUndefinedOrEmpty(_community.editors)) {
            return Translation.translate('FRONT.COMMUNITY.ERROR_EDITOR_FEED');
        }

        if (_isPrivacyNotOpen() && _isFeedGroupsWithAllOrPublic(union(_community.publishers, _community.editors))) {
            return Translation.translate('FRONT.COMMUNITY.ERROR_ALL_PUBLIC_FEED');
        }

        return true;
    }

    /**
     * Validate the information steps.
     *
     * @return {string|boolean} The error message if there is an error, true if not.
     */
    function informationStepValidator() {
        if (_isPrivacyNotOpen() && _isFeedGroupsWithAllOrPublic(vm.subscribedFeedKeys)) {
            return Translation.translate('FRONT.COMMUNITY.ERROR_ALL_PUBLIC_FEED');
        }

        return true;
    }

    /**
     * Initialize the feed groups.
     */
    function initFeedGroups() {
        _setFeedSelectors();

        _clearPublishersAndEditors();
    }

    /**
     * Adds a new Google group to the list in the user management area.
     */
    function addGoogleGroup() {
        if (angular.isDefinedAndFilled(vm.tempGoogleGroup) && !includes(_community.groups, vm.tempGoogleGroup)) {
            _community.groups.push(vm.tempGoogleGroup);
        }

        vm.tempGoogleGroup = undefined;
    }

    /**
     * Search for data in available calendars, if we cannot find it, use the 'other' calendar as a fallback.
     *
     * @param {string}   calendarId The identifier of the calendar to be selected.
     * @param {Function} [cb]       A callback function to execute with the calendar object.
     */
    function calendarModelToSelection(calendarId, cb) {
        if (!angular.isDefinedAndFilled(calendarId)) {
            return;
        }

        cb = cb || angular.noop;

        let calendar;
        if (calendarId === vm.otherCalendar.id) {
            calendar = angular.fastCopy(vm.otherCalendar);
        } else {
            calendar = _getCalendar(calendarId);
        }

        // If model is set and calendar is not found, then use the 'other' calendar.
        if (angular.isUndefined(calendar) && angular.isDefinedAndFilled(_community.calendarId)) {
            calendar = angular.fastCopy(vm.otherCalendar);
        }

        cb(calendar);
    }

    /**
     * Return selected element identifier and set calendarId.
     *
     * @param {Object}   calendar The selected calendar object.
     * @param {Function} [cb]     A callback function to execute with the calendar id.
     */
    function calendarSelectionToModel(calendar, cb) {
        if (!angular.isDefinedAndFilled(get(calendar, 'id'))) {
            return;
        }

        cb = cb || angular.noop;
        _community.calendarId = calendar.id === vm.otherCalendar.id ? undefined : calendar.id;

        cb(calendar.id);
    }

    /**
     * Search for data in available chat providers, if we cannot find it.
     *
     * @param {string}   id   The name of the chat provider to be selected.
     * @param {Function} [cb] A callback function to execute with the chat provider object.
     */
    function chatProviderModelToSelection(id, cb) {
        if (!angular.isDefinedAndFilled(id)) {
            return;
        }

        cb = cb || angular.noop;

        const provider = loFind(vm.availableChatProviders, { id });

        cb(provider);
    }

    /**
     * Return selected element identifier and set chatProvider.
     *
     * @param {Object}   chatProvider The selected chatProvider object.
     * @param {Function} [cb]         A callback function to execute with the chatProvider.
     */
    function chatProviderSelectionToModel(chatProvider, cb) {
        if (!angular.isDefinedAndFilled(chatProvider)) {
            return;
        }

        cb = cb || angular.noop;
        _community.chatProvider = chatProvider.id;

        cb(chatProvider.id);
    }

    /**
     * Search for data in available chat user spaces, if we cannot find it.
     *
     * @param {string}   id   The identifier of the chat user space to be selected.
     * @param {Function} [cb] A callback function to execute with the chat user space object.
     */
    function chatUserSpaceModelToSelection(id, cb) {
        if (!angular.isDefinedAndFilled(id)) {
            return;
        }

        cb = cb || angular.noop;

        const userSpace = loFind(vm.availableChatUserSpaces, { id });

        cb(userSpace);
    }

    /**
     * Return selected element identifier and set chatUserSpace.
     *
     * @param {Object}   space The selected chat user space object.
     * @param {Function} [cb]  A callback function to execute with the chatUserSpace.
     */
    function chatUserSpaceSelectionToModel(space, cb) {
        if (!angular.isDefinedAndFilled(get(space, 'id'))) {
            _community.chatUserSpace = undefined;
            return;
        }

        cb = cb || angular.noop;
        _community.chatUserSpace = space.id;

        cb(space.id);
    }

    /**
     * Check if user is logged with Google or is a Google user.
     *
     * @return {boolean} Whether or not the user is Google.
     */
    const isGoogleUser = () => User.isGoogle() || User.isLoggedWithGoogle();

    /**
     * Check if current user can edit the advanced options of the community.
     *
     * @return {boolean} Whether the user can edit the advanced options of the community.
     */
    function canEditAdvancedOptions() {
        return (
            angular.isUndefinedOrEmpty(_community.communityType) ||
            _community.communityType === 'email' ||
            (_community.communityType === 'google' && isGoogleUser())
        );
    }

    /**
     * Check if current user can edit the Drive folder of the community.
     * If the community has been created with another provider than the user's provider,
     * the modification is impossible.
     *
     * @return {boolean} Whether the user can edit the drive folder and calendar of the community.
     */
    function canEditCalendar() {
        return isGoogleUser();
    }

    /**
     * Check if current user can edit the calendar of the community.
     * If the community has been created with another provider than the user's provider,
     * or the secure media option has already been activated,
     * the modification is impossible.
     *
     * @return {boolean} Whether the user can edit the drive folder and calendar of the community.
     */
    function canEditDrive() {
        return isGoogleUser() && !vm.showOptions.securedMedia;
    }

    /**
     * Check if current user can edit the secured media option.
     * Should be a restricted community.
     * If the community has been already created, or Drive has been activated, or option has already ben activated,
     * the modification is impossible.
     *
     * @return {boolean} Whether the user can edit the drive folder and calendar of the community.
     */
    function canEditSecuredMedia() {
        return !vm.showOptions.media && vm.isNew && _community.privacy === Community.privacyTypes.restricted;
    }

    /**
     * Check if current user can add a chat.
     *
     * @return {boolean} Whether the user can edit the chat of the community.
     */
    function canEditChat() {
        // Only Slack is supported.
        return Features.hasFeature('slack') || Features.hasFeature('workplace');
    }

    /**
     * Clears the selected Google Drive folder which will trigger the 'select drive folder' btn to show up again.
     */
    function clearSelectedDriveFolder() {
        const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());

        _community.driveFolder = {};
        currentCommunity.driveFolder = {};

        _community.hasDriveFolder = false;
        currentCommunity.hasDriveFolder = false;

        vm.driveFolder = undefined;
    }

    /**
     * When the "Create a new calendar" option is toggled, empty the selected calendar.
     */
    function createCalendarToggled() {
        const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());

        if (currentCommunity.createCalendar) {
            _clearSelectedCalendar(true);
        }
    }

    /**
     * Remove the thumbnail.
     */
    function deleteThumbnail() {
        _community.thumbnail = undefined;
    }

    /**
     * Redirect user to the Content (template) edition mode.
     *
     * @param {string} template The template to edit.
     */
    function editTemplate(template) {
        Community.editTemplate(Community.getCurrent().id, template);
    }

    /**
     * Launch the input file picker to update the content thumbnail.
     */
    function editThumbnail() {
        UploaderAction.openSelection(vm.thumbnailUploaderId);
    }

    /**
     * Filters the available calendars to match the search filter.
     *
     * @param {string} [search=''] The search filter.
     */
    function filterCalendars(search = '') {
        vm.calendarSearchText = search;
        _debouncedGetCalendarList();
    }

    /**
     * Get the media security option label depending on the context of the community.
     */
    function getTooltipLabel() {
        // Community is open.
        if (_community.privacy === Community.privacyTypes.open) {
            return 'ADMIN.COMMUNITY.SECURE_STORAGE.TOOLTIP_OPEN_COMMUNITY';
        }

        // Community is restricted and in edition mode.
        if (_community.privacy === Community.privacyTypes.restricted && !vm.isNew) {
            return 'ADMIN.COMMUNITY.SECURE_STORAGE.TOOLTIP_EDITION_MODE';
        }

        // Community is restricted, is not in edition mode and drive is enabled.
        if (_community.privacy === Community.privacyTypes.restricted && vm.isNew && vm.showOptions.media) {
            return 'ADMIN.COMMUNITY.SECURE_STORAGE.TOOLTIP';
        }

        // No need to display tooltip.
        return undefined;
    }

    /**
     * Get the visibility option tooltip label depending on the context of the community.
     */
    function getTooltipLabelForVisibilityOptions() {
        if (vm.showOptions.securedMedia) {
            if (vm.isNew) {
                return 'ADMIN.COMMUNITY.SECURE_STORAGE.TOOLTIP_FOR_GOOGLE_VISIBILITY_OPTIONS_DURING_CREATION';
            }

            return 'ADMIN.COMMUNITY.SECURE_STORAGE.VISIBILITY_OPTIONS_LABEL_DURING_EDITION';
        }

        // No need to display tooltip.
        return undefined;
    }

    /**
     * Get the media secured feature status.
     * Display the option when:
     *  - the feature has been activated.
     *  - it has already been enabled on a community but the feature has been disabled afterward.
     *
     * @return {boolean} Whether media secured is enabled or not.
     */
    function hasMediaSecuredFeature() {
        return vm.hasSecurityAtCommunityLevelFeature() || (!vm.isNew && vm.showOptions.securedMedia);
    }

    /**
     * Check if security-at-community-level feature is enabled.
     *
     * @return {boolean} Whether the feature is enabled or not.
     */
    function hasSecurityAtCommunityLevelFeature() {
        return vm.Features.hasFeature('security-at-community-level');
    }

    /**
     * Check if the form is completely or partially displayed.
     *
     * @return {boolean} Whether the form is displayed in full or not.
     */
    function isCompleteMode() {
        return CommunityForm.getDisplayMode(ModuleAdmin.getListKey()) === CommunityForm.DISPLAY_MODE.complete;
    }

    /**
     * Open the media picker.
     */
    function openMediaPicker() {
        /**
         * Set the drive picker at true before opening it to
         * load the component.
         *
         * It will be set to false once closed.
         * */
        vm.driveFolderPickerIsOpen = true;
        Utils.waitForAndExecute(`#${vm.FOLDER_PICKER_ID}`, AbstractPicker);
    }

    /**
     * Open a user picker.
     *
     * @param {string} pickerId The identifier of the user picker to open.
     */
    function openUserPicker(pickerId) {
        if (angular.isUndefinedOrEmpty(pickerId)) {
            return;
        }

        Utils.waitForAndExecute(`#${pickerId}`, UserPicker);
    }

    /**
     * Remove a group from the list of google groups linked to this community.
     *
     * @param {string} groupEmail The email of the group to be removed from the list of google groups.
     */
    function removeGoogleGroup(groupEmail) {
        if (angular.isUndefinedOrEmpty(groupEmail)) {
            return;
        }

        _community.groups = without(_community.groups, groupEmail);
    }

    function chatChannelNameIsValid() {
        return vm.chatChannelNameValid === true;
    }

    function chatChannelNameHasError() {
        return vm.chatChannelNameValid === false;
    }

    /**
     * Check and save the community.
     *
     * @return {Promise} The promise that resolves or reject according to server response.
     */
    function save() {
        return $q(function resolveOrReject(resolve, reject) {
            const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());
            const subscriptions = currentCommunity.subscribedFeedKeys || [];

            if (angular.isDefinedAndFilled(vm.subscribedFeedKeys)) {
                // Don't change the reference of the subscriptions array, so push all new subscriptions (don't concat).
                subscriptions.push(...vm.subscribedFeedKeys);

                if (!Utils.equals(subscriptions, currentCommunity.subscribedFeedKeys)) {
                    currentCommunity.subscribedFeedKeys = subscriptions;
                }
            }

            // Build template/widgets.
            _buildDefaultPages();

            const adminsDetails = angular.fastCopy(_community.adminsDetails);
            const usersDetails = angular.fastCopy(_community.usersDetails);
            _community.adminsDetails = [];
            _community.usersDetails = [];
            _community.showOptions = vm.showOptions;
            _community.tagz = vm.tagz;

            CommunityForm.saveContent(
                function onCommunitySaveSuccess(response) {
                    vm.activeTabIndex = 0;

                    // Updates admin datatable.
                    ModuleAdmin.updateList(response);

                    LxNotificationService.success(Translation.translate('ADMIN.COMMUNITIES.SAVED'));
                    LxDialogService.close(vm.dialogId);

                    if (_displayShareDialog(response)) {
                        let addedUsers = _getAddedUsers(response);
                        let removedUsers = _getDeletedUsers(response);
                        let addedGroupIds = _getAddedGroups(response);
                        let removedGroupIds = _getDeletedGroups(response);

                        if (_communityCalendarId !== response.calendarId || _isCommunityPrivacyEdited(response)) {
                            addedUsers = _getAllUniqueUsers(response);
                            addedGroupIds = _getAllUniqueGroups(response);
                            removedUsers = [];
                            removedGroupIds = [];
                        }

                        LxDialogService.open(vm.dialogIdMsShare, {
                            addedGroupIds,
                            addedUsers,
                            calendar: _getCalendar(response.calendarId) || {
                                summary: Translation.translate(response.title),
                            },
                            privacy: _isCommunityPrivacyEdited(response) ? response.privacy : undefined,
                            removedGroupIds,
                            removedUsers,
                        });
                    }

                    $rootScope.$broadcast('admin-community-save-success', response.id);
                    vm.subscribedFeedKeys = [];

                    resolve();
                },
                function onCommunitySaveError(err) {
                    if (
                        angular.isDefinedAndFilled(subscriptions) &&
                        angular.isDefinedAndFilled(vm.subscribedFeedKeys)
                    ) {
                        // Don't change the ref of the subscriptions array, so only remove the lastly added elements.
                        subscriptions.splice(
                            subscriptions.length - vm.subscribedFeedKeys.length,
                            vm.subscribedFeedKeys.length,
                        );

                        if (!Utils.equals(subscriptions, currentCommunity.subscribedFeedKeys)) {
                            currentCommunity.subscribedFeedKeys = subscriptions;
                        }
                    }

                    $timeout(function timedOutSetFormDirty() {
                        if (angular.isDefinedAndFilled(vm.form.moduleCommunity)) {
                            FormValidation.setFormDirty(vm.form.moduleCommunity);
                        }
                    });

                    reject(Utils.getServerError(err));
                },
                ModuleAdmin.getListKey(),
            );

            // Data has been sent to the API, restore the values to have the correct UI.
            $timeout(function delayRestoreCommunity() {
                _community.adminsDetails = adminsDetails;
                _community.usersDetails = usersDetails;
            });
        });
    }

    /**
     * Set community thumbnail.
     *
     * @param {Object} file The file that has been selected in the uploader directive.
     */
    function setThumbnail(file) {
        _community.thumbnail = file.blobKey;
    }

    /**
     * Set the correct values for admins radio buttons based on the community properties.
     */
    function setUserRadioButtons() {
        vm.adminSelectionType = angular.isDefinedAndFilled(_community.editors)
            ? vm.selectionTypes.feed
            : vm.selectionTypes.user;
    }

    /**
     * Set the dashboard option.
     *
     * The function is also called in the wizard builder.
     * @param {boolean} value The value to set.
     */
    function setDashboard(value) {
        if (value) {
            vm.showOptions.dashboard = value;
        }

        _community.properties.hasDashboard = vm.showOptions.dashboard;
    }

    /**
     * Set the secured media option.
     *
     */
    function setMediaSecured() {
        // Onchange is called after the variable is modified. So we check the inverse here.
        if (!vm.hasSecurityAtCommunityLevelFeature() || !vm.showOptions.securedMedia) {
            _community.securedRepository = false;
            return;
        }

        LxNotificationService.confirm(
            Translation.translate('ADMIN.COMMUNITY.SECURE_STORAGE.MODAL.TITLE'),
            Translation.translate('ADMIN.COMMUNITY.SECURE_STORAGE.MODAL.DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('ADMIN.COMMUNITY.SECURE_STORAGE.MODAL.BTN_ENABLE'),
            },
            (answer) => {
                vm.showOptions.securedMedia = !!answer;
                _community.securedRepository = !!answer;

                if (answer) {
                    // Disable drive options (incompatible with secure storage)
                    vm.showOptions.media = false;
                    // So we call the toggle method
                    vm.toggleGoogleFeature('createDrive');

                    _community.privacy = Community.privacyTypes.restricted;
                }
            },
        );
    }

    /**
     * Set the chat option.
     *
     * The function is also called in the wizard builder.
     * @param {boolean} value The value to set.
     */
    function setChat() {
        _community.useChat = vm.showOptions.useChat;
        _community.chat = {
            chanId: undefined,
            chatName: undefined,
            chatId: undefined,
            chatProvider: undefined,
        };
        _clearSelectedChat(true);
        _setChatProvider();
    }

    /**
     * The chatProvider value was updated.
     */
    function chatProviderChange() {
        _community.chat = {
            chanId: undefined,
            chatName: undefined,
            chatId: undefined,
            chatProvider: vm.chatProvider,
        };
        vm.chatUserSpace = undefined;
        vm.chatChannelName = undefined;
        vm.chatChannelNameValid = false;
        vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
        _community.chatProvider = vm.chatProvider;
        _setChatUserSpace();
    }

    /**
     * The chatUserSpace value was updated.
     */
    function chatUserSpaceChange() {
        if (angular.isDefinedAndFilled(vm.chatUserSpace)) {
            _community.chat = {
                chatName: undefined,
                chanId: undefined,
                chatId: vm.chatUserSpace,
                chatProvider: vm.chatProvider,
            };
        } else {
            _community.chat = {
                chatName: undefined,
                chanId: undefined,
                chatId: undefined,
                chatProvider: vm.chatProvider,
            };
        }
    }

    const channelNameAlreadyExist = async (name) => {
        if (!name) {
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        let isValid = false;
        vm.chatChannelNameError = undefined;
        _community.chat.chatName = undefined;
        _community.chat.chanId = undefined;
        vm.chatChannelNameChecking = true;

        Chat.getChannelByName(vm.chatProvider, vm.chatUserSpace, name)
            .then((result) => {
                if (result && result.channel && result.channel.id) {
                    isValid = true;
                    _community.chat.chanId = result.channel.id;
                    _community.chat.chatName = undefined;
                } else {
                    vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NOTFOUND';
                }
            })
            .catch((error) => {
                console.log(error);
                vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NOTFOUND';
            })
            .finally(() => {
                vm.chatChannelNameValid = isValid;
                vm.chatChannelNameChecking = false;
            });
    };

    const channelNameNotExist = async (name) => {
        if (!name) {
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        let isValid = false;
        _community.chat.chatName = undefined;
        _community.chat.chanId = undefined;
        vm.chatChannelNameChecking = true;
        vm.chatChannelNameError = undefined;

        Chat.getChannelByName(vm.chatProvider, vm.chatUserSpace, name)
            .then((result) => {
                if (!result || !result.channel || !result.channel.id) {
                    _community.chat.chatName = name;
                    isValid = true;
                } else {
                    vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.ALREADY.EXISTS';
                }
            })
            .catch((error) => {
                _community.chat.chatName = name;
                isValid = true;
            })
            .finally(() => {
                vm.chatChannelNameValid = isValid;
                vm.chatChannelNameChecking = false;
            });
    };

    /**
     * Start timer to know if the chat channel is valid
     * @param {integer} time The time to wait.
     */
    function resetTimerCheckChat() {
        if (vm.chatChannelTimeout) {
            clearTimeout(vm.chatChannelTimeout);
            vm.chatChannelTimeout = undefined;
        }
    }

    /**
     * Start timer to know if the chat channel is valid
     * @param {integer} time The time to wait.
     */
    function startTimerCheckChat(time) {
        if (!vm.chatChannelName) {
            /* If no name, not valid */
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        vm.chatChannelNameValid = undefined;
        vm.chatChannelNameError = undefined;

        resetTimerCheckChat();

        vm.chatChannelTimeout = setTimeout(() => {
            vm.chatChannelTimeout = undefined;
            if (vm.chatChannelMode === CHAT_SELECT_CHANNEL_MODE.SELECT.id) {
                channelNameAlreadyExist(vm.chatChannelName);
            } else {
                channelNameNotExist(vm.chatChannelName);
            }
        }, time);
    }

    /**
     * The chat channel mode value was updated.
     */
    function chatChannelModeChange() {
        if (!vm.chatChannelName) {
            /* If no name, not valid */
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        startTimerCheckChat(0);
    }

    /**
     * The chat channel name value was updated.
     */
    function chatChannelNameChange() {
        if (!vm.chatChannelName) {
            /* If no name, not valid */
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }
        resetTimerCheckChat();

        const isValid = channelNameIsValid(vm.chatProvider, vm.chatChannelName);
        if (!isValid) {
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NAME.INVALID';
        } else {
            startTimerCheckChat(1500);
        }
    }
    /**
     * Need to verify the chat channel name
     */
    function chatChannelNameLeaved() {
        resetTimerCheckChat();

        if (!vm.chatChannelName) {
            /* If no name, not valid */
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        if (channelNameIsValid(vm.chatProvider, vm.chatChannelName)) {
            /* Start timer 0 */
            startTimerCheckChat(0);
        } else {
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NAME.INVALID';
        }
    }

    /**
     * Toggle Google features on or off for the community.
     *
     * @param {string} propertyName The name of the property to toggle.
     */
    function toggleGoogleFeature(propertyName) {
        _community[propertyName] = !_community[propertyName];
        vm.allowAdvancedMode = !vm.isOneDriveCommunity && (_community.createDrive || _community.createCalendar);

        if (propertyName === 'createCalendar') {
            if (vm.showOptions.calendar) {
                _getCalendarList();
            } else {
                _clearSelectedCalendar();
            }
        } else if (propertyName === 'createDrive' && !vm.showOptions.media) {
            vm.clearSelectedDriveFolder();

            const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());
            _community.createDrive = false;
            currentCommunity.createDrive = false;

            _community.createTeamDrive = false;
            currentCommunity.createTeamDrive = false;
        }

        _setFeedSelectors();
    }

    /**
     * Called whenever tagz are change in the selector.
     *
     * @param {Object} tagList The selected item in tagz selection component.
     */
    function handleTagzChange(tagList) {
        if (angular.isDefined(tagList)) {
            vm.tagz = [...tagList];
        }
    }

    /**
     *
     * Format a folder selected from the media picker to an object useable
     * by the community.
     *
     * @param {Object} folder The drive folder to format
     * @returns {Object} An object containing the document path, document kind and name.
     */
    function formatDriveFolder(folder) {
        if (angular.isUndefinedOrEmpty(folder)) {
            return {};
        }

        const { docPath, properties } = folder;
        const driverKind = properties && properties.type === 'GOOGLE_TEAMDRIVE' ? 'drive#teamDrive' : 'drive#folder';

        return {
            docPath,
            kind: driverKind,
            name: _getFolderName(folder),
        };
    }

    /**
     * Initialize the Chat options (provider, user space).
     */
    function _initChatOption() {
        if (Features.hasFeature('advanced_community') && _community.hasChat) {
            vm.showOptions.useChat = true;
            _community.useChat = vm.showOptions.useChat;
            if (angular.isDefinedAndFilled(_community.chat)) {
                _setChatProvider();
                _setChatUserSpace();
                _setChatChannel();
            }
        } else {
            vm.showOptions.useChat = false;
            vm.chatProvider = undefined;
            vm.chatUserSpace = undefined;
            vm.chatChannelMode = CHAT_SELECT_CHANNEL_MODE.CREATE.id;
            vm.chatChannelName = undefined;
            vm.chatChannelNameValid = false;
            vm.chatChannelNameError = undefined;
        }
    }

    /////////////////////////////

    vm.addGoogleGroup = addGoogleGroup;
    vm.calendarModelToSelection = calendarModelToSelection;
    vm.calendarSelectionToModel = calendarSelectionToModel;
    vm.canEditAdvancedOptions = canEditAdvancedOptions;
    vm.canEditCalendar = canEditCalendar;
    vm.canEditDrive = canEditDrive;
    vm.canEditSecuredMedia = canEditSecuredMedia;
    vm.canEditChat = canEditChat;
    vm.clearSelectedDriveFolder = clearSelectedDriveFolder;
    vm.createCalendarToggled = createCalendarToggled;
    vm.deleteThumbnail = deleteThumbnail;
    vm.editTemplate = editTemplate;
    vm.editThumbnail = editThumbnail;
    vm.filterCalendars = filterCalendars;
    vm.getNextCalendars = getNextCalendars;
    vm.getTooltipLabel = getTooltipLabel;
    vm.getTooltipLabelForVisibilityOptions = getTooltipLabelForVisibilityOptions;
    vm.handleTagzChange = handleTagzChange;
    vm.hasMediaSecuredFeature = hasMediaSecuredFeature;
    vm.hasSecurityAtCommunityLevelFeature = hasSecurityAtCommunityLevelFeature;
    vm.informationStepValidator = informationStepValidator;
    vm.initFeedGroups = initFeedGroups;
    vm.isCompleteMode = isCompleteMode;
    vm.isGoogleUser = isGoogleUser;
    vm.openMediaPicker = openMediaPicker;
    vm.openUserPicker = openUserPicker;
    vm.optionsStepValidator = optionsStepValidator;
    vm.permissionsStepValidator = permissionsStepValidator;
    vm.removeGoogleGroup = removeGoogleGroup;
    vm.save = save;
    vm.setDashboard = setDashboard;
    vm.setChat = setChat;
    vm.setMediaSecured = setMediaSecured;
    vm.setThumbnail = setThumbnail;
    vm.setUserRadioButtons = setUserRadioButtons;
    vm.toggleGoogleFeature = toggleGoogleFeature;
    vm.chatProviderModelToSelection = chatProviderModelToSelection;
    vm.chatProviderSelectionToModel = chatProviderSelectionToModel;
    vm.chatProviderChange = chatProviderChange;
    vm.chatUserSpaceModelToSelection = chatUserSpaceModelToSelection;
    vm.chatUserSpaceSelectionToModel = chatUserSpaceSelectionToModel;
    vm.chatUserSpaceChange = chatUserSpaceChange;
    vm.getNextChatUserSpacesList = getNextChatUserSpacesList;
    vm.chatChannelModeChange = chatChannelModeChange;
    vm.chatChannelNameChange = chatChannelNameChange;
    vm.chatChannelNameLeaved = chatChannelNameLeaved;
    vm.chatChannelNameIsValid = chatChannelNameIsValid;
    vm.chatChannelNameHasError = chatChannelNameHasError;
    vm.formatDriveFolder = formatDriveFolder;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    vm.registerWatchers = function registerWatchers(childrenScope) {
        /**
         * Reset the community when we close the dialog.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering the close start event.
         */
        childrenScope.$on('lx-dialog__close-start', function onCommunityDialogClose(evt, dialogId) {
            if (dialogId === vm.dialogId) {
                _community = undefined;
                vm.calendarId = undefined;
                vm.driveFolder = undefined;
                vm.chatProvider = undefined;
                vm.chatUserSpace = undefined;
                vm.chatChannelName = undefined;
                vm.chatChannelNameValid = false;
                vm.chatChannelNameError = undefined;
            }
        });

        /**
         * Reset the community when we close the dialog.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering the close end event.
         */
        childrenScope.$on('lx-dialog__close-end', function onCommunityDialogClose(evt, dialogId) {
            if (dialogId === vm.dialogId) {
                // Force activeTabIndex to reset to general tab.
                vm.activeTabIndex = 0;
                // Remove selected feed keys.
                vm.subscribedFeedKeys = [];
            }
        });

        /**
         * When the user picker closes, update the user details.
         *
         * @param {Event}  evt           The user picker close event.
         * @param {string} pickerId      The id of the user picker that closes.
         * @param {Array}  [pickedUsers] The list of full users that have been picked.
         */
        childrenScope.$on('user-picker__close-end', function onUserPickerCloseEnd(evt, pickerId, pickedUsers) {
            const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());

            // The user canceled the user pick.
            if (angular.isUndefined(pickedUsers)) {
                return;
            }

            if (pickerId === vm.userPickerId) {
                currentCommunity.usersDetails = pickedUsers.slice(0, vm.maxUsersToDisplay);
            } else if (pickerId === vm.adminUserPickerId) {
                currentCommunity.adminsDetails = pickedUsers.slice(0, vm.maxUsersToDisplay);
            }
        });

        /**
         * When the media picker closes, update the drive folder of the community.
         *
         * @param {Event}  evt      The media picker close event.
         * @param {string} pickerId The identifier of the media picker.
         */
        childrenScope.$on('abstract-picker__close-end', function onMediaPickerCloseStart(evt, pickerId) {
            if (pickerId !== vm.FOLDER_PICKER_ID || angular.isUndefinedOrEmpty(vm.driveFolder)) {
                return;
            }

            _community.driveFolder = formatDriveFolder(vm.driveFolder);
        });

        /**
         * Unregister the preSave method when the scope is destroyed.
         */
        childrenScope.$on('$destroy', Community.unregisterPreSaveMethod.bind(this, _cleanCommunityBeforeSave));
    };

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        const listKey = ModuleAdmin.getListKey();
        _community = Community.getCurrent(listKey);
        _community.properties = _community.properties || {};

        _communityUsers = _getAllUniqueUsers(_community);
        _communityGroupIds = _getAllUniqueGroups(_community);
        _communityCalendarId = _community.calendarId;
        _communityPrivacy = _community.privacy;

        vm.calendarId = _community.calendarId;
        vm.tagz = _community.tagz || [];
        if (angular.isDefinedAndFilled(get(_community, 'chat'))) {
            vm.chatProvider = _community.chat.chatProvider;
            vm.chatUserSpace = _community.chat.chatId;
        }

        vm.showOptions.dashboard = Boolean(_community.properties.hasDashboard);
        vm.hasDashboardInitially = vm.showOptions.dashboard;
        vm.showOptions.calendar = angular.isDefinedAndFilled(_community.calendarId);
        vm.showOptions.useChat = Boolean(_community.useChat);
        vm.isNew = !_community.uid;

        // Backward for the available post types.
        if (angular.isUndefined(_community.postTypes)) {
            _community.postTypes = Community.defaultPostTypes;
        }
        _community.publishers = without(_community.publishers, _community.communityFeedKey);

        _setDefaultCommunityValues();

        _setFeedSelectors();

        vm.isOneDriveCommunity = Community.isOneDriveCommunity(_community);
        vm.allowAdvancedMode =
            Features.hasFeature('advanced_community') &&
            (_community.hasCalendarId || _community.hasDriveFolder) &&
            !vm.isOneDriveCommunity;
        vm.isAdvancedModeDisplayed = angular.isDefinedAndFilled(_community.groups);

        vm.adminUserPickerId = `module-community-admin-user-picker-${_community.id}`;
        vm.listKeyCommunityRequest = `community-requests-${_community.id}`;
        vm.userPickerId = `module-community-user-picker-${_community.id}`;
        _getPendingAccessRequests();

        if (vm.showOptions.calendar) {
            _getCalendarList();
            _setCalendarId();
        }

        _initGoogleOptions(listKey);
        _initChatOption();

        vm.setUserRadioButtons();

        Community.registerPreSaveMethod(_cleanCommunityBeforeSave);
    };
}

/////////////////////////////

angular.module('Controllers').controller('CommunityBuilderController', CommunityBuilderController);

/////////////////////////////

export { CommunityBuilderController };
