import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { TextField, TextFieldProps } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_CONTENT_FILTER } from '../../keys';
import { ContentFilterItemProps, GenericListFilterType, NGIFilterId } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

const DEFAULT_SCOPE = 'text-field';

/**
 * The keyword filter displayed in the content filter widget to filter the linked widget by keywoard.
 */
export const KeywordContentFilter: React.FC<ContentFilterItemProps<GenericListFilterType[NGIFilterId.query]>> = ({
    onChange,
    value,
    theme,
    hideSubheader,
    scope = DEFAULT_SCOPE,
}) => {
    const { get } = useDataAttributes(scope);
    const { translateKey } = useTranslate();

    const handleChange = React.useCallback<TextFieldProps['onChange']>(
        (value) => {
            onChange(value);
        },
        [onChange],
    );

    return (
        <>
            {!hideSubheader && (
                <FilterSubheader label={translateKey(WIDGET_CONTENT_FILTER.KEYWORD_SUBHEADER)} theme={theme} />
            )}
            <TextField
                label={translateKey(WIDGET_CONTENT_FILTER.BY_KEYWORD_LABEL)}
                onChange={handleChange}
                value={value}
                theme={theme}
                {...get({ element: 'keyword', action: 'filter' })}
            />
        </>
    );
};
