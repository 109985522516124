// eslint-disable-next-line lumapps/do-not-import-axios
import {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosPromise,
    AxiosResponse,
    type AxiosProgressEvent as BaseProgressEvent,
} from 'axios';

import { OneOrMany } from '@lumapps/utils/types/OneOrMany';

export type BaseApiProgressEvent = BaseProgressEvent;

/**
 * Base Api Instance type
 */
export type BaseApiInstance = AxiosInstance;

/**
 * Base Api Request configuration.
 */
export type BaseApiRequestConfig = AxiosRequestConfig;

/**
 * Base Api Request headers.
 */
export type BaseApiRequestHeaders = AxiosRequestConfig['headers'];

/**
 * Base Api Promise
 */
export type BaseApiPromise<T = any> = AxiosPromise<T>;

/**
 * Base Api Response
 */
export type BaseApiResponse<T = any> = AxiosResponse<T>;

/**
 * Authentication Bearer token, retrieved from the backend and used across our application in order to
 * send requests to our servers
 */
export interface JWT {
    /** a unique ID, that can be used to implement an invalidation mechanism and thus avoid replay attacks. */
    jti: string;
    /** the token issuer, always https://login.lumapps.com/v1 */
    iss: string;
    /** the time in seconds at which the token was issued */
    iat: number;
    /** the expiration time of the token. */
    exp: number;
    /** the token audience, i.e. the LumApps cell this token is valid for, e.g. https://cell-name.api.lumapps.com. */
    aud: string;
    /** the token subject, i.e. the LumApps user ID. */
    sub: string;
    /** the LumApps organization ID for this user. */
    organizationId: string;
    /** the LumApps user email. */
    email: string;
    /** whether the user is an organization administrator. */
    isOrgAdmin: boolean;
    /** whether the user is a LumApps administrator, authorized to perform maintenance and impersonation operations. */
    isLumAppsAdmin: boolean;
}

/**
 * Generic type for a request sent to the backend for retrieving a list.
 */
export interface ServerListRequest {
    /** max results expected from the API */
    maxResults?: string;
    /** cursor for pagination purposes */
    cursor?: string;
    /** fields desired, usually separated by a comma */
    fields?: string;
    /**
     * The sort order defined by the property name used to sort.
     * Descending sort order is denoted by a hyphen (-) preceding the property name;
     * omitting the hyphen specifies ascending order by default.
     * */
    sortOrder?: OneOrMany<string>;
    /** search query */
    query?: string;
}

/**
 * Generic type for a response sent from the backend for retrieving a list.
 */
export interface ServerListResponse<T = any> {
    /** call id */
    callId?: string;
    /** cursor for pagination purposes */
    cursor: string;
    /** list of items */
    items: T[];
    /** whether there are more results or not */
    more: boolean;
}

/**
 * Response type of api legacy errors
 */
export interface LegacyServerError {
    data?: {
        error?: {
            message?: string;
            code?: number;
        };
    };
}

/**
 * Response type of new HM microservices errors
 */
export interface ServiceServerError {
    data?: {
        errors?: {
            status: string;
            title: string;
            detail?: string;
            code?: string;
            meta?: any;
        }[];
    };
}

/**
 * Enum used for figuring out the priority of a request.
 */
export enum PRIORITY {
    /**
     * request will be executed with high priority,
     * meaning as quickly as possible.
     */
    HIGH = 'high',
    /**
     * request will be executed with low priority,
     * meaning as late as possible.
     */
    LOW = 'low',
}

/**
 * Types of stale cache revalidation
 */
export enum REVALIDATED_ON_TYPE {
    /** revalidate each time the API is executed */
    ALWAYS = 'always',
    /** revalidate only once */
    ONCE = 'once',
}

/**
 * API backend versions
 */
export enum API_VERSION {
    v1 = 'v1',
    v2 = 'v2',
}

export interface BaseApiOptions {
    /**
     * The url base path.
     */
    path?: string;
    /**
     * @deprecated Please use the version parameter if you are using v2 APIs
     */
    apiVersion?: string;
    /**
     * The full base url to use.
     * If it is defined, apiVersion and path will be ignored.
     * Can be useful when endpoints do not follow the standard api naming convention.
     */
    baseURL?: string;
    /**
     * Parameter to be used in order to define the API version to be used.
     */
    version?: API_VERSION;
    /**
     * Whether this API should call directly the Haussmann services or not
     */
    bypassMonolith?: boolean;
    /**
     * Organization id to use when using api v2 endpoints. usually there is no need
     * set this variable since it defaults to the current organization
     */
    organizationId?: string;
    /**
     * Haussmann cell to use. Usually there is no need to set this variable since it
     * defaults to the current cell
     */
    cell?: string;
    /**
     * We can specify a base request config to apply to all requests of this API
     */
    requestConfig?: BaseApiRequestConfig;
    /**
     * Specifies whether to use the shared cell URL for the request.
     * This option will override the baseURL option.
     * Warning: Ensure that the backend provides the shareCell URL to the applciation before enabling this option.
     */
    useShared?: boolean;
}

/**
 * Interceptor used for defining actions before and after a request is executed.
 */
export type Interceptor = {
    onFulfilled?: ((value: BaseApiResponse<any>) => BaseApiResponse<any> | Promise<BaseApiResponse<any>>) | undefined;
    onRejected?: ((error: any) => any) | undefined;
};
