import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Kind, Message, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_SETTINGS } from '../../keys';

const CLASSNAME = 'editable-widget-double-click-message';

export const EditableWidgetDoubleClickMessage: React.FC = () => {
    const { translateKey } = useTranslate();

    return (
        <Message className={classnames(CLASSNAME, 'lumx-spacing-margin-top-big')} kind={Kind.info} hasBackground>
            <Text as="p">{translateKey(WIDGET_SETTINGS.DOUBLE_CLICK_INFO)}</Text>
        </Message>
    );
};
