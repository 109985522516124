import { useMemo } from 'react';

import compact from 'lodash/compact';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';
import sortBy from 'lodash/fp/sortBy';
import take from 'lodash/fp/take';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { ServerListResponse } from '@lumapps/base-api';
import { useQueries } from '@lumapps/base-api/react-query';
import { CalendarViews } from '@lumapps/lumx-calendar/types';

import { fetchCalendarEvents } from '../api';
import { QUERY_STALE_TIME } from '../constants';
import { LegacyCalendar, LegacyCalendarEvent, ListEventParams } from '../types';
import { concatEvents } from '../utils/concatEvents';

export const useQueryCalendarEvents = (
    fetchParams: ListEventParams[],
    selectedCalendars: LegacyCalendar[],
    view: CalendarViews,
    userCalendarColor: string,
    maxResults: number,
    hideConferenceLink: boolean = false,
) => {
    /**
     * Implement `useQueries` hook, to fetch events from each available calendar's.
     * The hook is configured with the `fetchParams` list.
     * */
    const results = useQueries({
        queries: fetchParams.map((params) => {
            return {
                queryKey: compact(['widget-calendar', params.calendarId, params.startDate, params?.endDate]),
                queryFn: () => fetchCalendarEvents(params),
                staleTime: QUERY_STALE_TIME,
                /**
                 * Apply a transformation on the raw data returned by the legacy endpoint.
                 * Return the list of legacy events mapped in the CalendarEvent format.
                 * */
                select: (data: ServerListResponse<LegacyCalendarEvent>) =>
                    concatEvents(data.items, selectedCalendars, userCalendarColor, hideConferenceLink),
            };
        }),
    });

    /**
     * Combine the results from the `useQueries` into one combineQueries object that will :
     * - Define the global `isLoading` status
     * - Extract, concat and sort all the events.
     * - Apply the max results in schedule view
     * This method should be moved to the `combine` property of useQueries, once we've updated to tanstack query v5
     * */
    return useMemo(() => {
        const data = flow(map('data'), compact, flatten, sortBy('start'))(results);

        return {
            isLoading: !isEmpty(results) && some('isLoading', results),
            data: !isUndefined(maxResults) && view === CalendarViews.schedule ? take(maxResults, data) : data,
        };
    }, [maxResults, results, view]);
};
