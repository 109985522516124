import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const coveoSearchSettingsRoute: Route = {
    path: '/admin/search-settings/coveo',
    legacyId: 'app.admin.coveo-search-settings',
    appId: AppId.backOffice,
};

export const searchSettingsRoute: Route = {
    path: '/admin/search-settings/results-page',
    legacyId: 'app.admin.search-settings-results-page',
    appId: AppId.backOffice,
};

export const searchSettingsConfigRoute: Route = {
    path: '/admin/search-settings/results-page/:id',
    legacyId: 'app.admin.search-settings-results-page-details',
    appId: AppId.backOffice,
};

export const gcssearchSettingsRoute: Route = {
    path: '/admin/search-settings/gcs',
    legacyId: 'app.admin.search-settings-gcs',
    appId: AppId.legacy,
};

export const coveoSearchSettings = (): Route => coveoSearchSettingsRoute;
export const searchSettings = (): Route => searchSettingsRoute;
export const gcssearchSettings = (): Route => gcssearchSettingsRoute;
export const searchSettingsConfig = (id: string): Route => {
    return {
        ...searchSettingsConfigRoute,
        params: {
            id,
        },
    };
};
