/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const adminMobileConfigurationRoute: Route = {
    path: '/admin/mobile-configuration',
    appId: AppId.backOffice,
    legacyId: 'app.admin.mobile-configuration',
};

export const adminMobileConfiguration = () => adminMobileConfigurationRoute;
