function WidgetChatController(Features) {
    'ngInject';

    const vm = this;

    const init = function init() {
        const chatFeatures = [];
        if (Features.hasFeature('slack')) {
            chatFeatures.push('slack');
        }
        if (Features.hasFeature('yammer')) {
            chatFeatures.push('yammer');
        }
        if (Features.hasFeature('workplace')) {
            chatFeatures.push('workplace');
        }
        vm.widget.features = chatFeatures;
    };

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };

    init();
}

/////////////////////////////

function WidgetChatDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetChatController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetChat', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-chat/views/widget-chat.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetChat', WidgetChatDirective);

/////////////////////////////

export { WidgetChatController, WidgetChatDirective };
