import { useState } from 'react';

import { BaseApiError } from '@lumapps/base-api';
import { useQuery, UseQueryOptions } from '@lumapps/base-api/react-query';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useDialogOpenState } from '@lumapps/utils/hooks/useDialogOpenState';

import { getCategories, learningCatalogQueryKeys } from '../api';
import { CategoryData } from '../types';

export const useLearningCatalogSelectField = (
    locale: string,
    options?: UseQueryOptions<
        CategoryData[],
        BaseApiError,
        CategoryData[],
        ReturnType<typeof learningCatalogQueryKeys>
    >,
) => {
    /**
     * Deal with the open/close state of the select
     * */
    const [isOpen, setOpen] = useDialogOpenState();
    const LearningAlphaEnabled = useSelector(isLearningAlphaEnabled);
    /**
     * Fetch the learning available catalogs
     * based on the user current language
     * */
    const {
        data = [],
        isLoading,
        ...useQueryResults
    } = useQuery({
        queryKey: learningCatalogQueryKeys(locale, LearningAlphaEnabled),
        queryFn: () => getCategories(locale, LearningAlphaEnabled),
        cacheTime: Infinity,
        staleTime: Infinity,
        ...options,
    });

    /**
     * Search field control
     * */
    const [searchText, onSearch] = useState('');

    return {
        selectFieldSimpleProps: {
            isOpen,
            setOpen,
            choices: data,
            isLoading,
            searchText,
            onSearch,
        },
        useQueryResults,
    };
};
