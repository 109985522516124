import first from 'lodash/first';
import get from 'lodash/get';
import set from 'lodash/set';
import isUndefined from 'lodash/isUndefined';

import { CHAT_PROVIDER_VALUES } from 'components/components/widgets/chat/chat_constants';

import { listProviders, listChannelsForUserSpace, listJoinedUserSpaces } from '@lumapps/chat/api/legacyApi';
import { getProviderNameFromId, channelNameIsValid } from 'components/components/chat/utils/chat_utils';
import { logException } from '@lumapps/utils/log/logException';
// eslint-disable-next-line id-blacklist
import { error } from 'components/services/notification';
import { translate as t } from 'components/translations';

function WidgetChatSettingsController($log, $q, $rootScope, $timeout, Widget, Chat) {
    'ngInject';

    const widgetChatSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current Chat widget.
     *
     * @type {Object}
     */
    let _currentWidget = {};

    /**
     * The user spaces list call response data (the current page and the HasMOre attribute).
     *
     * @type {Object}
     */
    const _userSpaceListOptions = {
        cursor: undefined,
        hasMore: false,
        page: undefined,
    };

    /**
     * The channels list call response data (the current page and the HasMOre attribute).
     *
     * @type {Object}
     */
    const _channelListOptions = {
        cursor: undefined,
        hasMore: false,
        page: undefined,
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /** Widget. */
    widgetChatSettings.Widget = Widget;

    /** List of fetched providers. */
    widgetChatSettings.providers = [];
    /** List of fetched user spaces. */
    widgetChatSettings.userSpaces = [];
    /** List of fetched channels. */
    widgetChatSettings.channels = [];

    /** Providers loading state. */
    widgetChatSettings.providersLoading = false;
    /** User spaces loading state. */
    widgetChatSettings.userSpacesLoading = false;
    /** User spaces more loading state. */
    widgetChatSettings.userSpacesMoreLoading = false;
    /** Channels loading state. */
    widgetChatSettings.channelsLoading = false;
    /** Channels more loading state. */
    widgetChatSettings.channelsMoreLoading = false;
    /** Current widget */
    widgetChatSettings.currentWidget = _currentWidget;

    widgetChatSettings.chatSlackChannelNameValid = false;
    widgetChatSettings.chatSlackChannelNameError = undefined;
    widgetChatSettings.chatChannelNameChecking = undefined;
    widgetChatSettings.selectedProvider = undefined;
    widgetChatSettings.selectedUserSpace = undefined;
    widgetChatSettings.chatChannelTimeout = undefined;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Set the newly updated properties to the current widget.
     *
     * @param {Object} value        The updated property value.
     * @param {Object} propertyName The updated property name.
     */
    function onChange(value, propertyName) {
        let properties = { ...get(_currentWidget, 'properties', {}) };

        // If changing view mode, reset all default properties.
        if (propertyName.length === 1 && first(propertyName) === 'viewMode' && properties.viewMode !== value) {
            properties = { ...properties, ...widgetChatSettings.defaultProps.properties };
        }

        set(properties, propertyName, value);

        _currentWidget.properties = properties;
    }

    /////////////////////////////

    /**
     * Get user spaces depending on selected provider;
     */
    function getUserSpaces() {
        _currentWidget = Widget.getCurrent();
        widgetChatSettings.userSpacesLoading = true;
        const provider = get(_currentWidget, 'properties.provider', null);

        listJoinedUserSpaces({ provider })
            .then((response) => {
                const { data } = response;
                _userSpaceListOptions.cursor = data.cursor;
                _userSpaceListOptions.hasMore = data.more;
                _userSpaceListOptions.page = data.page;

                widgetChatSettings.userSpaces = get(data, 'items', []);
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetChatSettings.userSpacesLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });
    }

    /**
     * Load the next page of user spaces.
     *
     * @return {Promise} The call promise.
     */
    function loadNextUserSpaces() {
        // eslint-disable-next-line angular/deferred
        const defer = $q.defer();

        if (!_userSpaceListOptions.hasMore) {
            defer.resolve([]);

            return defer.promise;
        }

        const provider = get(_currentWidget, 'properties.provider', null);

        widgetChatSettings.userSpacesMoreLoading = true;

        listJoinedUserSpaces({
            cursor: _userSpaceListOptions.cursor,
            page: _userSpaceListOptions.page + 1,
            provider,
        })
            .then((response) => {
                const { data } = response;
                _userSpaceListOptions.cursor = data.cursor;
                _userSpaceListOptions.hasMore = data.more;
                _userSpaceListOptions.page = data.page;

                defer.resolve(get(data, 'items', []));
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
                widgetChatSettings.userSpacesMoreLoading = false;
            })
            .finally(() => {
                widgetChatSettings.userSpacesMoreLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });

        return defer.promise;
    }

    /////////////////////////////

    /**
     * Handle provider selection.
     * @param {string} value Selected provider.
     */
    function selectProvider(value) {
        _currentWidget = Widget.getCurrent();
        widgetChatSettings.channels = [];
        widgetChatSettings.userSpaces = [];
        widgetChatSettings.selectedProvider = value;

        _currentWidget.properties.channel = null;
        _currentWidget.properties.userSpace = null;
        _currentWidget.properties.provider = value;

        getUserSpaces();
    }

    /////////////////////////////

    /**
     * Get channels on user space selection.
     */
    function getChannels() {
        _currentWidget = Widget.getCurrent();
        widgetChatSettings.channelsLoading = true;
        const provider = get(_currentWidget, 'properties.provider', null);
        const spaceId = get(_currentWidget, 'properties.userSpace.id', null);
        listChannelsForUserSpace({
            provider,
            spaceId,
        })
            .then((response) => {
                const { data } = response;
                _channelListOptions.cursor = data.cursor;
                _channelListOptions.hasMore = data.more;
                _channelListOptions.page = data.page;

                widgetChatSettings.channels = get(data, 'items', []);
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_CHANNELS_FOR_GROUP_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetChatSettings.channelsLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });
    }

    /**
     * Load the next page of channels.
     *
     * @return {Promise} The call promise.
     */
    function loadNextChannels() {
        // eslint-disable-next-line angular/deferred
        const defer = $q.defer();

        if (!_channelListOptions.hasMore) {
            defer.resolve([]);

            return defer.promise;
        }

        const provider = get(_currentWidget, 'properties.provider', null);
        const spaceId = get(_currentWidget, 'properties.userSpace.id', null);

        widgetChatSettings.channelsMoreLoading = true;

        listChannelsForUserSpace({
            cursor: _channelListOptions.cursor,
            page: _channelListOptions.page + 1,
            provider,
            spaceId,
        })
            .then((response) => {
                const { data } = response;
                _channelListOptions.cursor = data.cursor;
                _channelListOptions.hasMore = data.more;
                _channelListOptions.page = data.page;

                defer.resolve(get(data, 'items', []));
            })
            .catch((exception) => {
                error(t('FRONT.FETCH_GROUPS_ERROR'));
                $log.err(exception);
                widgetChatSettings.channelsMoreLoading = false;
            })
            .finally(() => {
                widgetChatSettings.channelsMoreLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });

        return defer.promise;
    }

    const channelNameAlreadyExist = async (name) => {
        if (!name) {
            /* If no name, not valid */
            widgetChatSettings.chatSlackChannelNameValid = false;
            widgetChatSettings.chatSlackChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        let isValid = false;
        widgetChatSettings.chatChannelNameChecking = true;
        const getChannel = widgetChatSettings.selectedProvider === "slack" ? Chat.getChannelById : Chat.getChannelByName;

        getChannel(widgetChatSettings.selectedProvider, widgetChatSettings.selectedUserSpace, name)
            .then((result) => {
                if (result && result.channel && result.channel.id) {
                    isValid = true;
                    _currentWidget.properties.channel = result.channel;
                } else {
                    widgetChatSettings.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NOTFOUND';
                }
            })
            .catch((error) => {
                logException(error);
                widgetChatSettings.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NOTFOUND';
            })
            .finally(() => {
                widgetChatSettings.chatChannelNameValid = isValid;
                widgetChatSettings.chatChannelNameChecking = false;
            });
    };

    /**
     * Start timer to know if the chat channel is valid
     * @param {integer} time The time to wait.
     */
    function resetTimerCheckChat() {
        if (widgetChatSettings.chatChannelTimeout) {
            clearTimeout(widgetChatSettings.chatChannelTimeout);
            widgetChatSettings.chatChannelTimeout = undefined;
        }
    }

    /**
     * Start timer to know if the chat channel is valid
     * @param {integer} time The time to wait.
     */
    function startTimerCheckChat(time) {
        _currentWidget = Widget.getCurrent();

        if (!_currentWidget.properties.channel.name) {
            /* If no name, not valid */
            widgetChatSettings.chatSlackChannelNameValid = false;
            widgetChatSettings.chatSlackChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }

        widgetChatSettings.chatSlackChannelNameValid = undefined;
        widgetChatSettings.chatSlackChannelNameError = undefined;

        resetTimerCheckChat();

        widgetChatSettings.chatChannelTimeout = setTimeout(() => {
            widgetChatSettings.chatChannelTimeout = undefined;
            channelNameAlreadyExist(_currentWidget.properties.channel.name);
        }, time);
    }

    /**
     * The chat channel name value was updated.
     */
    function chatSlackChannelNameChange() {
        _currentWidget = Widget.getCurrent();

        if (!_currentWidget.properties.channel.name) {
            /* If no name, not valid */
            widgetChatSettings.chatSlackChannelNameValid = false;
            widgetChatSettings.chatSlackChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.EMPTY';
            return;
        }
        resetTimerCheckChat();

        const isValid = channelNameIsValid(widgetChatSettings.selectedProvider, _currentWidget.properties.channel.name);
        if (!isValid) {
            widgetChatSettings.chatChannelNameValid = false;
            widgetChatSettings.chatChannelNameError = 'ADMIN.COMMUNITY.CHAT.CHANNEL.NAME.INVALID';
        } else {
            startTimerCheckChat(1500);
        }
    }

    function chatChannelNameHasError() {
        return widgetChatSettings.chatChannelNameValid === false;
    }

    /**
     * Select user space depending on provider.
     */
    function selectUserSpace() {
        _currentWidget = Widget.getCurrent();
        widgetChatSettings.selectedUserSpace = _currentWidget.properties.userSpace.id;
        widgetChatSettings.channels = [];
        _currentWidget.properties.channel = null;

        getChannels();
    }

    /////////////////////////////

    widgetChatSettings.getProviderName = getProviderNameFromId;
    widgetChatSettings.loadNextUserSpaces = loadNextUserSpaces;
    widgetChatSettings.loadNextChannels = loadNextChannels;
    widgetChatSettings.onChange = onChange;
    widgetChatSettings.selectUserSpace = selectUserSpace;
    widgetChatSettings.selectProvider = selectProvider;
    widgetChatSettings.chatSlackChannelNameChange = chatSlackChannelNameChange;
    widgetChatSettings.chatChannelNameHasError = chatChannelNameHasError;

    /////////////////////////////

    /**
     * Init controller.
     */
    function init() {
        _currentWidget = Widget.getCurrent();
        _currentWidget.properties = _currentWidget.properties || {};
        _currentWidget.properties.position = 'default';
        _currentWidget.properties.hasActions = true;

        if (isUndefined(_currentWidget.properties.provider)) {
            _currentWidget.properties.provider = CHAT_PROVIDER_VALUES.TEAMS.id;
        }

        widgetChatSettings.providersLoading = true;
        widgetChatSettings.selectedProvider = _currentWidget.properties.provider;
        widgetChatSettings.selectedUserSpace = get(_currentWidget.properties, 'channel.spaceId');

        listProviders()
            .then((response) => {
                widgetChatSettings.providers = get(response, 'data.providers', []);
            })
            .catch((exception) => {
                error(t('FRONT.CHAT.FETCH_PROVIDERS_ERROR'));
                $log.err(exception);
            })
            .finally(() => {
                widgetChatSettings.providersLoading = false;
                $timeout(function timeoutBroadcast() {
                    $rootScope.$broadcast('widget-chat-settings', _currentWidget.uuid);
                });
            });

        if (_currentWidget.properties.provider) {
            getUserSpaces();

            if (_currentWidget.properties.userSpace) {
                getChannels();
            }
        }
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetChatSettingsController', WidgetChatSettingsController);

/////////////////////////////

export { WidgetChatSettingsController };
