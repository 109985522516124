import React from 'react';

import { PLACEMENT } from '../../types';

interface CustomizationAppProps {
    /** where the customization will be placed on the application */
    placement: PLACEMENT;
    /** children to display */
    children: React.ReactNode;
}

/**
 * Component that wraps the Customization on the application, in order to place these customizations
 * using CSS on their corresponding places. This avoids having to change the layout of the page if
 * there are some customers that have customizations on their applications.
 * @param props - CustomizationAppProps
 */
export const CustomizationApp: React.FC<CustomizationAppProps> = ({ placement, children }) => {
    return <div className={`customization-app customization-app__${placement}`}>{children}</div>;
};
