/* istanbul ignore file */
import {
    isAnalyticsAllowed as isAnalyticsAllowedSelector,
    isExtendedAnalyticsEnabled as isExtendedAnalyticsEnabledSelector,
} from '@lumapps/analytics/ducks/selectors';
import {
    isInstanceSuperAdmin as isInstanceSuperAdminSelector,
    isSuperAdmin as isSuperAdminSelector,
} from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';
import { hasCoveoIndexingEnabled, isSearchAnalyticsEnabled } from '@lumapps/search-analytics/ducks/selectors';

import { AnalyticsSideNavigation } from './AnalyticsSideNavigation';

const mapStateToProps = (state: BaseStore) => {
    const isSuperAdmin = isSuperAdminSelector(state);
    const isInstanceSuperAdmin = isInstanceSuperAdminSelector(state);
    const isAnalyticsReadAllowed = isAnalyticsAllowedSelector(state);
    const isExtendedAnalyticsFeatureEnabled = isExtendedAnalyticsEnabledSelector(state);
    const isSearchAnalyticsFeatureEnabled = isSearchAnalyticsEnabled(state);
    const hasCoveo = hasCoveoIndexingEnabled(state);

    return {
        hasSearchAnalyticsAccess:
            (isInstanceSuperAdmin || (isAnalyticsReadAllowed && !hasCoveo)) && isSearchAnalyticsFeatureEnabled,
        hasSiteAnalyticsAccess: isAnalyticsReadAllowed && isExtendedAnalyticsFeatureEnabled,
        hasExtendedAnalyticsAccess: isSuperAdmin && isExtendedAnalyticsFeatureEnabled,
        hasMobileAnalyticsAccess: isSuperAdmin,
    };
};

const ConnectedSideNavigation = connect(mapStateToProps)(AnalyticsSideNavigation);

export { ConnectedSideNavigation as AnalyticsSideNavigation };
