import { BroadcastMetadata, ComponentTypes, ExtensionCategories, ShareToMetadata } from 'lumapps-sdk-js';

import { ServerListResponse } from '@lumapps/base-api';
import { Group } from '@lumapps/groups/types';
import { mdiCheckboxMarkedCircleOutline, mdiCloseCircleOutline, mdiPuzzleRemove } from '@lumapps/lumx/icons';
import { ColorPalette, FlagProps } from '@lumapps/lumx/react';
import { TranslateObject } from '@lumapps/translations';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { ExtensionStatus, ExtensionTopics, VersionStatus } from './constants';
import { MARKETPLACE } from './keys';

export {
    ComponentTypes,
    ExtensionCategories,
    ExtensionSearchInput,
    ExtensionSearchOutput,
    ExtensionSearchResult,
    FilterOptions,
    RESULT_STATUS,
    SEARCH_SDK_SUPPORTED_VERSIONS,
    SearchExtension,
} from 'lumapps-sdk-js';

/**
 * Enum representing possible errors related to component loading.
 */
export enum ComponentErrors {
    NETWORK = 'NETWORK_ERROR',
    NOT_INSTALLED = 'NOT_INSTALLED',
    UNKNOWN_EXTENSION = 'UNKNOWN_EXTENSION',
}

/**
 * Represents links associated with an extension.
 */
export type LinksParameter = {
    /**
     * Documentation's URL of the extension.
     */
    readonly documentation?: string | null;
    /**
     * Privacy policy's URL of the extension.
     */
    readonly privacyPolicy?: string | null;
    /**
     * Term of service's URL of the extension.
     */
    readonly termsOfService?: string | null;
};

/**
 * Represents a specific version of an extension.
 * This object contains detailed information about the version, such as its number,
 * creation and update date, and authors, compatibility with mobile applications, and status.
 * It provides the URL to the bundle file for this version, as well as details
 * about which component types are available in this version and provider types supported by the extension.
 */
export interface ExtensionVersion {
    /** URL of the bundle for this version of the extension */
    readonly bundle: string | null;
    /** List of available component types for this extension */
    readonly components: ComponentTypes[];
    /** The version's creation date */
    readonly createdAt: string;
    /** The version's creation author */
    readonly createdBy: string;
    /** The version's deletion date */
    readonly deletedAt?: string | null;
    /** Identifier of the extension */
    readonly extensionId: string;
    /** Identifier of the version */
    readonly id: string;
    /** Is compatible with mobile application */
    readonly isMobileCompatible: boolean;
    /** The version's number following X.Y.Z */
    readonly number: string;
    /** List of provider types. */
    readonly providerTypes: string[];
    /** Version status. */
    readonly status: keyof typeof VersionStatus;
    /** The extension's update date. */
    readonly updatedAt: string;
    /** The extension's update author. */
    readonly updatedBy: string;
}

/**
 * Represents an extension in LumApps.
 * This object contains detailed information about the extension,
 * such as its name, description, category, and availability.
 * It provides a link to the documentation, terms of service, privacy policy, and associated media.
 * The interface includes details about supported component types,
 * provider types, metadata for share_to or broadcast extensions,
 * topics related to the extension, and whether it should use a connector selector in widget settings.
 */
export interface Extension {
    /**
     * The category of the extension.
     */
    readonly category: ExtensionCategories;
    /**
     * Description of the extension.
     */
    readonly description: TranslateObject;
    /**
     * URL of extension's icon.
     */
    readonly icon: TranslateObject;
    /**
     * Identifier of a Marketplace extension.
     */
    readonly id: string;
    readonly links: LinksParameter;
    /**
     * Medias associated with the extension.
     */
    readonly medias?: {
        /**
         * URL of the stored extension preview.
         */
        readonly previewImage?: string;
    };
    /**
     * Metadata associated with the extension.
     */
    readonly metadata?: ShareToMetadata | BroadcastMetadata;
    /**
     * Name of the extension.
     */
    readonly name: TranslateObject;
    /**
     * The partner's ID of the extension.
     */
    readonly partnerId: string;
    /**
     * Whether the extension is available for all organizations.
     */
    readonly public: boolean;
    /**
     * List of extension topics.
     */
    readonly topics: ObjectValues<typeof ExtensionTopics>[];
    /**
     * Whether we should use a selector in the widget settings.
     * The goal is to display the selector ONLY if the version has a provider type AND this field is true.
     */
    readonly useConnectorSelector: boolean;
}

/**
 * Represents an installed extension in LumApps.
 * This object contains detailed information about the installed extension,
 * including configuration settings and its associated version.
 * It tells whether the installed extension is up-to-date with the latest version of the extension,
 * as well as update and upgrade author and date.
 */
export interface InstalledExtension {
    /**
     * Configuration of an installed extension.
     */
    configuration: {
        global?: Record<string, unknown>;
    };
    /**
     * The mapping of connector IDs per provider type associated with the installed extension.
     *
     * e.g.:
     * {
     *   google: "051a8b2b-637c-4bb9-87d3-c3cb03cbd6a1",
     *   microsoft: "2d223b09-370f-403a-8e13-8a57bf50b49b",
     *   adobe: null
     * }
     */
    connectorIds?: Record<string, string | null | undefined>;
    /**
     * The extension's creation date.
     */
    readonly createdAt: string;
    /**
     * The extension's author.
     */
    readonly createdBy: string;
    /**
     * The extension by itself.
     */
    readonly extension: Extension;
    /**
     * Identifier of the extension.
     */
    readonly id: string;
    /**
     * Whether the installed extension is up to date with the latest version of the extension.
     */
    readonly isUpToDate: boolean;
    /**
     * The current status of the installed extension.
     */
    status: ExtensionStatus;
    /**
     * The extension's update date.
     */
    readonly updatedAt: string;
    /**
     * The extension's update author.
     */
    readonly updatedBy: string;
    /**
     * The extension's upgrade date.
     */
    readonly upgradedAt: string;
    /**
     * The extension version.
     *
     * Can be null if the version has been deleted, or the customer is not part of the whitelist anymore
     */
    readonly version?: ExtensionVersion;
    /**
     * List of group ids by which the installed extension of micro-app type should be viewed
     */
    visibleByGroupIds?: string[];
    /**
     * List of group reference that can view the installed extension
     */
    readonly visibleByGroupReferences?: Group[];
}

/**
 * Micro-app's topics type
 * Specs might change, thus type too
 */
export type ExtensionTopic = string;

export type InstalledExtensionTopicsAPIProps = ServerListResponse<ExtensionTopic>;

export const ACTIVATION_STATUS: Record<ExtensionStatus, FlagProps> = {
    disabled: {
        label: MARKETPLACE.DEACTIVATED,
        color: ColorPalette.red,
        icon: mdiCloseCircleOutline,
    },
    live: {
        label: MARKETPLACE.ACTIVATED,
        color: ColorPalette.green,
        icon: mdiCheckboxMarkedCircleOutline,
    },
    unavailable: {
        label: MARKETPLACE.UNAVAILABLE,
        color: ColorPalette.grey,
        icon: mdiPuzzleRemove,
    },
};
