export enum PLAY_LIBRARY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517447793
     */
    ALWAYS_AVAILABLE = 'PLAY.ALWAYS_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517449346
     */
    AVAILABLE_UNTIL_STATUS_CHANGE = 'PLAY.AVAILABLE_UNTIL_STATUS_CHANGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517447398
     */
    CHOOSE_END_OF_PUBLICATION = 'PLAY.CHOOSE_END_OF_PUBLICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517449438
     */
    CHOOSE_UNPUBLISH_DATE = 'PLAY.CHOOSE_UNPUBLISH_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109851021
     */
    DELETE_DIALOG_TITLE = 'PLAY.DELETE_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109851059
     */
    DELETE_DIALOG_KEEP_CTA = 'PLAY.DELETE_DIALOG_KEEP_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109956486
     */
    DELETE_DIALOG_DELETE_CTA = 'PLAY.DELETE_DIALOG_DELETE_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109851084
     */
    DELETE_DIALOG_MESSAGE = 'PLAY.DELETE_DIALOG_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=528865150
     */
    DELETE_DIALOG_VIDEO_UNAVAILABLE = 'PLAY.DELETE_DIALOG_VIDEO_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=528872432
     */
    DELETE_DIALOG_IRREVERSIBLE = 'PLAY.DELETE_DIALOG_IRREVERSIBLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502311230
     */
    DESCRIBE_VIDEO = 'PLAY.DESCRIBE_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164112256
     * */
    DOWNLOAD_VIDEO = 'PLAY.DOWNLOAD_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=429497295
     */
    EMBED_CODE = 'PLAY.EMBED_CODE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=429497295
     */
    EMBED_CODE_COPIED = 'PLAY.EMBED_CODE_COPIED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184340298
     */
    ENTER_VIDEO_TITLE = 'PLAY.ENTER_VIDEO_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184340335
     */
    ENTER_VIDEO_DESCRIPTION = 'PLAY.ENTER_VIDEO_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=521863914
     */
    INCORRECT_SCHEDULE_DATE_IN_THE_PAST = 'PLAY.INCORRECT_SCHEDULE_DATE_IN_THE_PAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144362752
     */
    INTERACTION_TOOLS = 'PLAY.INTERACTION_TOOLS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119722998
     */
    MOST_VIEWED = 'PLAY.MOST_VIEWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517442917
     */
    PICK_SCHEDULED_DATE = 'PLAY.PICK_SCHEDULED_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517411663
     */
    PUBLISH_NOW = 'PLAY.PUBLISH_NOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517425829
     */
    PUBLISH_NOW_OR_SCHEDULE = 'PLAY.PUBLISH_NOW_OR_SCHEDULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504663618
     */
    SAVE_AS_DRAFT = 'PLAY.SAVE_AS_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517411705
     */
    SCHEDULE_PUBLICATION = 'PLAY.SCHEDULE_PUBLICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517444054
     */
    SCHEDULE_END_OF_PUBLICATION = 'PLAY.SCHEDULE_END_OF_PUBLICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165294252
     */
    SELECT_VIDEO = 'PLAY.SELECT_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13586913
     */
    STARRED = 'FRONT.WIDGET_FILE_LIST.SETTINGS.FOLDER.DRIVE.STARRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=137396983
     */
    TABS_LIST_ACCESSIBILITY_LABEL = 'PLAY.VIDEO_TABS_LIST_ACCESSIBILITY_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164648946
     */
    TAKE_SNAPSHOT = 'PLAY.TAKE_SNAPSHOT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=146246210
     */
    THUMBNAIL = 'PLAY.THUMBNAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172612345
     */
    TRANSCRIPT = 'PLAY.TRANSCRIPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172625218
     */
    TRANSCRIPT_DESCRIPTION = 'PLAY.TRANSCRIPT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172623877
     */
    TRANSCRIPT_GENERATE = 'PLAY.TRANSCRIPT_GENERATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172613924
     */
    TRANSCRIPT_LANGUAGE_LABEL = 'PLAY.TRANSCRIPT_LANGUAGE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172669380
     */
    TRANSCRIPT_RESULT = 'PLAY.TRANSCRIPT_RESULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172625229
     */
    TRANSCRIPT_START_DESCRIPTION = 'PLAY.TRANSCRIPT_START_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111207464
     */
    UPLOAD_DIALOG_TITLE = 'PLAY.UPLOAD_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111207498
     */
    UPLOAD_DIALOG_CTA = 'PLAY.UPLOAD_DIALOG_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111207521
     */
    UPLOAD_DIALOG_SUBTITLE = 'PLAY.UPLOAD_DIALOG_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111208793
     */
    UPLOAD_DIALOG_DESCRIPTION = 'PLAY.UPLOAD_DIALOG_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326094667
     */
    UPLOAD_FROM_COMPUTER = 'PLAY.UPLOAD_FROM_COMPUTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326094683
     */
    UPLOAD_FROM_DRIVE = 'PLAY.UPLOAD_FROM_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830721
     */
    UPLOAD_THUMBNAIL = 'PLAY.UPLOAD_THUMBNAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130839976
     */
    VIDEO_DIALOG_ANALYTICS_TAB_TITLE = 'PLAY.ANALYTICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130839688
     */
    VIDEO_DIALOG_CHAPTERS_TAB_TITLE = 'PLAY.CHAPTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124733100
     */
    VIDEO_DIALOG_DETAILS_TAB_TITLE = 'PLAY.DETAILS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389362
     */
    VIDEO_DIALOG_EDIT_THUMBNAIL_ALT = 'PLAY.VIDEO_DIALOG_EDIT_THUMBNAIL_ALT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389377
     */
    VIDEO_DIALOG_EDIT_THUMBNAIL_ALT_PLACEHOLDER = 'PLAY.VIDEO_DIALOG_EDIT_THUMBNAIL_ALT_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124733944
     */
    VIDEO_DIALOG_SUBTITLES_TAB_TITLE = 'PLAY.SUBTITLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389320
     */
    VIDEO_DIALOG_TITLE_NEW_VIDEO = 'PLAY.VIDEO_DIALOG_TITLE_NEW_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119071587
     */
    VIDEO_ENCODING = 'PLAY.VIDEO_ENCODING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109482722
     */
    VIDEO_LIBRARY_DELETE_SELECTED_CTA = 'PLAY.VIDEO_LIBRARY_DELETE_SELECTED_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109470882
     */
    VIDEO_LIBRARY_UPLOAD_CTA = 'PLAY.VIDEO_LIBRARY_UPLOAD_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=125339248
     */
    VIDEO_LINK = 'PLAY.VIDEO_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830748
     */
    VIDEO_LIST_EMPTY = 'PLAY.VIDEO_LIST_EMPTY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108837458
     */
    VIDEO_LIST_EMPTY_TIPS = 'PLAY.VIDEO_LIST_EMPTY_TIPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109484536
     */
    VIDEO_LIST_ITEM_DELETE_LABEL = 'PLAY.VIDEO_LIST_ITEM_DELETE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109484561
     */
    VIDEO_LIST_ITEM_EDIT_LABEL = 'PLAY.VIDEO_LIST_ITEM_EDIT_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109483594
     */
    VIDEO_LIST_ITEM_UPDATED_AT = 'PLAY.VIDEO_LIST_ITEM_UPDATED_AT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=483151693
     */
    VIDEO_TITLE_ERROR = 'PLAY.VIDEO_TITLE_ERROR_DESCRIPTION_WITHOUT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108826514
     */
    VIDEO_UPLOAD_NEW_VERSION_CTA = 'PLAY.VIDEO_UPLOAD_NEW_VERSION_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205875386
     */
    VIDEO_UPLOAD_PROGRESS_ENCODING = 'PLAY.VIDEO_UPLOAD_PROGRESS_ENCODING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205876213
     */
    VIDEO_UPLOAD_PROGRESS_ENCODING_MESSAGE = 'PLAY.VIDEO_UPLOAD_PROGRESS_ENCODING_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389289
     */
    VIDEO_UPLOAD_PROGRESS_UPLOADING = 'PLAY.VIDEO_UPLOAD_PROGRESS_UPLOADING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389310
     */
    VIDEO_UPLOAD_PROGRESS_UPLOADED = 'PLAY.VIDEO_UPLOAD_PROGRESS_UPLOADED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205873891
     */
    VIDEO_UPLOAD_PROGRESS_WARNING = 'PLAY.VIDEO_UPLOAD_PROGRESS_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205874028
     */
    VIDEO_UPLOAD_PROGRESS_WARNING_MESSAGE = 'PLAY.VIDEO_UPLOAD_PROGRESS_WARNING_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119948680
     */
    VIDEOS_UNPLAYABLE_WHILE_PROCESSING = 'PLAY.VIDEOS_UNPLAYABLE_WHILE_PROCESSING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=125336861
     */
    VISIBILITY_PUBLIC_DESCRIPTION = 'PLAY.VISIBILITY_PUBLIC_DESCRIPTION',
}
