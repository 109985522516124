import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import { CalendarEvent, EventStatus } from '@lumapps/lumx-calendar/types';
import { fixBackendMissingUTC } from '@lumapps/utils/date/fixBackendMissingUTC';
import { getMoment } from '@lumapps/utils/time/getMoment';

import { LegacyCalendar, LegacyCalendarAttendee, LegacyCalendarEvent } from '../types';

const MAP_LEGACY_EVENT_STATUS: Record<LegacyCalendarAttendee['responseStatus'], EventStatus> = {
    tentative: 'tentative',
    tentativelyAccepted: 'tentative',
    accepted: 'accepted',
    declined: 'declined',
    needsAction: 'needsAction',
};

/**
 * Import moment to reformat the Date string returned by the backend
 * This is required to deal with specific format that are not well-supported by the javascript Date object.
 * */
const moment = getMoment();

/**
 * Map the date string provided by the v1 API to a Javascript Date object
 * */
const getDateFromLegacy = (date: string): Date => {
    /**
     * If the date is formated with `YYYY-MM-DD` format.
     * We do not need to use `fixBackendMissingUTC` since the timezone is not specified.
     * */
    if (/^\d{4}-\d{2}-\d{2}$/.exec(date)) {
        return moment(date).toDate();
    }

    return moment(fixBackendMissingUTC(date)).toDate();
};

/**
 * Convert legacy event, returned by the API into Calendar compatible Event format
 * */
export const mapLegacyEvent =
    (calendarsSelected?: LegacyCalendar[], userCalendarColor?: string, hideConferenceLink?: boolean) =>
    ({
        htmlLink: link,
        hangoutLink: meetingLink,
        start,
        end,
        id,
        calendarId,
        summary,
        description,
        attendees,
    }: LegacyCalendarEvent): CalendarEvent =>
        omitBy<CalendarEvent>(
            {
                id,
                calendarId,
                link,
                meetingLink: hideConferenceLink ? undefined : meetingLink,
                summary,
                description,
                start: getDateFromLegacy(start),
                end: getDateFromLegacy(end),
                status: MAP_LEGACY_EVENT_STATUS[
                    attendees.find((attendee) => attendee.self)
                        ?.responseStatus as LegacyCalendarAttendee['responseStatus']
                ],
                color:
                    calendarId === 'primary'
                        ? userCalendarColor
                        : calendarsSelected?.find(({ id }) => id === calendarId)?.color,
            } as CalendarEvent,
            isNull,
        ) as CalendarEvent;
