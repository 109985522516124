export enum ASK_AI {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460423028
     */
    NO_RESOURCES_TITLE = 'ASK_LUMAPPS.NO_RESOURCES_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460423356
     */
    ERROR_DESC = 'ASK_LUMAPPS.ERROR_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460428052
     */
    ERROR_TITLE = 'ASK_LUMAPPS.ERROR_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460866431
     */
    BAD_RESPONSE = 'ASK_LUMAPPS.BAD_RESPONSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=465396681
     */
    SHOW_RELATED_RESOURCES = 'ASK_LUMAPPS.SHOW_RELATED_RESOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=465396682
     */
    HIDE_RELATED_RESOURCES = 'ASK_LUMAPPS.HIDE_RELATED_RESOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484534932
     */
    VISIBLE_TO_ADMINISTRATORS = 'ASK_LUMAPPS.VISIBLE_TO_ADMINISTRATORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=489936308
     */
    RULE_BASED_RESULT = 'ASK_LUMAPPS.RULE_BASED_RESULT',
}
