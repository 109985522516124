import React from 'react';

import { GROUPS } from '@lumapps/groups/keys';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiAccountMultiple } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { PlatformGroups } from '../../routes';

export interface PlatformGroupsSideNavigationProps {
    isAllowed: boolean;
}

const PlatformGroupsSideNavigation = ({ isAllowed }: PlatformGroupsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(GROUPS.CUSTOMER_FEEDS)}
            icon={mdiAccountMultiple}
            route={PlatformGroups()}
        />
    );
};

PlatformGroupsSideNavigation.displayName = 'SideNavigationItem';

export { PlatformGroupsSideNavigation };
