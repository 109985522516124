export enum NANNY_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39895451
     */
    MAX_RULES_REACHED = 'ADMIN.NANNY.MAX_RULES_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37563490
     */
    ADD_RULE = 'ADMIN.NANNY.ADD_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461009
     */
    SENSITIVE_DATA_FLAGGING = 'ADMIN.NANNY.SENSITIVE_DATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461778
     */
    DELETE_TITLE = 'ADMIN.NANNY.DELETE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461788
     */
    DELETE_EXPLANATION = 'ADMIN.NANNY.DELETE_EXPLANATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461030
     */
    FORM_RULE_DEFINITION = 'ADMIN.NANNY.FORM_RULE_DEFINITION_SECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38920666
     */
    FORM_LANGUAGE_CONCERNED = 'ADMIN.NANNY.FORM_LANGUAGE_CONCERNED_SECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461029
     */
    FORM_RULE_EXPLANATION_HELPER = 'ADMIN.NANNY.FORM_RULE_EXPLANATION_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461020
     */
    FORM_RULE_EXPLANATION = 'ADMIN.NANNY.FORM_RULE_EXPLANATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37565256
     */
    FORM_RULE_TITLE = 'ADMIN.NANNY.FORM_RULE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461033
     */
    FORM_RULE_REGEXP_PLACEHOLDER = 'ADMIN.NANNY.FORM_RULE_REGEXP_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461034
     */
    FORM_RULE_REGEXP_HELPER = 'ADMIN.NANNY.FORM_RULE_REGEXP_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461031
     */
    FORM_RULE_REGEXP = 'ADMIN.NANNY.FORM_RULE_REGEXP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38047780
     */
    FORM_RULE_FILE = 'ADMIN.NANNY.FORM_RULE_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38049096
     */
    UPLOAD_FILE_DESC = 'ADMIN.NANNY.UPLOAD_FILE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461103
     */
    FORM_NEW_TITLE = 'ADMIN.NANNY.FORM_NEW_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461107
     */
    FORM_UPDATE_TITLE = 'ADMIN.NANNY.FORM_UPDATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37461019
     */
    TITLE = 'ADMIN.NANNY.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37565264
     */
    CONTENT = 'ADMIN.NANNY.CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38209355
     */
    LANGUAGE = 'ADMIN.NANNY.LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37565268
     */
    LAST_UPDATE = 'ADMIN.NANNY.LAST_UPDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37565365
     */
    VIOLATION_COUNT = 'ADMIN.NANNY.VIOLATION_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37563448
     */
    FORM_RULE_INFORMATION_SECTION = 'ADMIN.NANNY.FORM_RULE_INFORMATION_SECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=433037418
     */
    COMPATIBILITY_MESSAGE = 'NANNY_ADMIN.COMPATIBILITY_MESSAGE',
}
