import { getAskAiAdminPath } from '@lumapps/ask-ai/utils';
import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

import { AskAiSource } from './types';

/**
 * Ask Ai Sources Routes
 */

/** List */
export const askAiBaseSourcesAdminRoute: Route = {
    path: getAskAiAdminPath('sources'),
    legacyId: 'app.admin.ai-search-sources',
    appId: AppId.backOffice,
};
export const askAiSourcesAdmin = (): Route => askAiBaseSourcesAdminRoute;

/** Edit */
export const askAiEditSourceRoute: Route = {
    ...askAiBaseSourcesAdminRoute,
    path: `${getAskAiAdminPath('sources')}/edit/:id`,
};
export const askAiEditSource = (rule: Pick<AskAiSource, 'id'>): Route => ({
    ...askAiEditSourceRoute,
    params: {
        id: rule.id,
    },
});

export interface SourceParams {
    id: string;
}

/** Create */
export const askAiCreateSourceRoute: Route = {
    ...askAiBaseSourcesAdminRoute,
    path: `${getAskAiAdminPath('sources')}/create`,
};
export const askAiCreateSource = (): Route => ({
    ...askAiCreateSourceRoute,
});
