import get from 'lodash/get';

import { first } from '@lumapps/utils/iterable/first';
import { Editor, NodeEntry, Point, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isSelectionEmpty } from '@lumapps/wrex/slate/utils/isSelectionEmpty';

import { ImageEditor, ImageEditorOptions, ImageElement, ImageGroupElement } from '../types';
import { createImageGroup } from './createImageGroup';
import { isImageGroup } from './isImageGroup';
import { uploadImage } from './uploadImage';

/**
 * Insert image after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertImage(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    attributes: Partial<ImageGroupElement>,
    files?: File[],
    point?: Point | undefined,
) {
    const at = point || editor.selection?.focus;
    const after = getSiblingPath([get(at, ['path', 0]) as number], 'after');
    const canInsertOnSelection = at && isSelectionEmpty(editor, at.path);

    const tempImages = files?.map((file) => ({ alt: '', tempSrc: URL.createObjectURL(file) })) as ImageElement[];

    const node = createImageGroup({ images: tempImages, isLoading: true, ...attributes });

    Transforms.insertNodes(editor, node, {
        at: canInsertOnSelection ? [at.path[0]] : after ?? [editor.children.length],
        select: true,
    });

    const [, firstPath] = first(
        Editor.nodes(editor, { at: editor.selection as Range, match: isImageGroup }),
    ) as NodeEntry;

    const path = canInsertOnSelection ? [at.path[0]] : firstPath;

    focusAt(editor, path);

    if (files) {
        const pathRef = Editor.pathRef(editor, path);
        uploadImage(options, editor, files, pathRef).finally(() => {
            pathRef.unref();
        });
    }
    return path;
}
