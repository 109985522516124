import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    SideNavigationItem as NavigationItem,
    SideNavigationItemProps as NavigationProps,
    Emphasis,
} from '@lumapps/lumx/react';
import { useRouteMatching, Route } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { useSideBarNavigationDataAttributes } from '../../../hooks/useSideBarNavigationDataAttributes';

export interface SideNavigationMenuProps extends Omit<NavigationProps, 'toggleButtonProps'> {
    label: string;
    baseClassName: string;
    dynamicIcon?: string;
    id?: string;
}

/**
 * Side Navigation Menu that displays a section with multiple links
 */
const SideNavigationMenu: React.FC<SideNavigationMenuProps> = ({
    baseClassName,
    children,
    isOpen = false,
    dynamicIcon = '',
    id = '',
    icon,
    ...props
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(baseClassName);
    /**
     * In order to determine whether the menu should be open or not, we need to check whether
     * any of the children routes is the current one.
     */
    const routes = React.useMemo(() => {
        return (
            React.Children.map(children, (elem) => {
                if (React.isValidElement(elem)) {
                    const { route: childRoute } = elem.props as any;

                    return childRoute;
                }

                return null;
            }) || []
        );
    }, [children]);

    const dataAttributes = useSideBarNavigationDataAttributes({ alternativeId: id });
    const { iconPath } = useDynamicIcon({ icon: dynamicIcon });
    const iconToUse = dynamicIcon ? iconPath : icon || undefined;

    const doesMatchAnyChildRoute = routes.reduce((matches: boolean, route: Route) => {
        /**
         * Since the size of the routes is always fixed (since they are defined statically in a config file)
         * we can iterate and execute a hook. Normally we would not do it, but in this case it is helpful to
         * determine if there is a child route selected.
         */
        /* eslint-disable react-hooks/rules-of-hooks */
        return route ? useRouteMatching(route) || matches : false;
    }, false);

    const [isNavigationOpen, setNavigationIsOpen] = React.useState(isOpen || doesMatchAnyChildRoute);

    /**
     * Callback that will be executed if the navigation item has children, which means that we need
     * to open and close it rather than redirect
     */
    const onOpenClose = (event: React.MouseEvent) => {
        event.preventDefault();
        setNavigationIsOpen(!isNavigationOpen);
    };

    const className = element('subitem', [baseClassName]);

    return (
        <NavigationItem
            {...props}
            className={className}
            emphasis={Emphasis.medium}
            isOpen={isNavigationOpen}
            onClick={onOpenClose}
            icon={iconToUse}
            toggleButtonProps={{ label: translateKey(GLOBAL.TOGGLE) }}
            {...dataAttributes}
        >
            {children}
        </NavigationItem>
    );
};

SideNavigationMenu.displayName = 'SideNavigationItem';

export { SideNavigationMenu };
