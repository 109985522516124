import { getImageForThumbnail } from '@lumapps/lumx-images/utils';

function WidgetFeaturedImageSettingsController(
    $rootScope,
    $scope,
    $timeout,
    Config,
    Content,
    Document,
    FeaturedImage,
    LxNotificationService,
    Media,
    MediaConstant,
    Translation,
    Utils,
    Upload,
    UploaderAction,
) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const widgetFeaturedImageSettings = this;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The content id.
     *
     * @type {string}
     */
    widgetFeaturedImageSettings.CONTENT_ID = Content.getCurrent().id;

    /**
     * The image uploader can be locked if the content has to be validated.
     *
     * @type {boolean}
     */
    widgetFeaturedImageSettings.readonlyFields = false;

    /**
     * The thumbnail to display.
     *
     * @type {boolean}
     */
    widgetFeaturedImageSettings.featuredThumbnail = null;

    /**
     * Content from mediaThumbnail to crop and edit
     *
     * @type {Object}
     */
    widgetFeaturedImageSettings.contextContent = null;

    /**
     * Cropped content from mediaThumbnail
     *
     * @type {Object}
     */
    widgetFeaturedImageSettings.contextCroppedContent = null;

    /**
     * Should show modal cropper
     *
     * @type {boolean}
     */
    widgetFeaturedImageSettings.cropperIsOpened = false;

    /**
     * Contains the possible values for the `background-position` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetFeaturedImageSettings.AVAILABLE_BACKGROUND_POSITIONS = [
    'left top',
    'left center',
    'left bottom',
    'right top',
    'right center',
    'right bottom',
    'center top',
    'center center',
    'center bottom',
];
    
    /**
     * Contains the possible values for the `background-size` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetFeaturedImageSettings.AVAILABLE_BACKGROUND_SIZES = ['auto', 'cover', 'contain'];

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    widgetFeaturedImageSettings.Config = Config;
    widgetFeaturedImageSettings.Content = Content;
    widgetFeaturedImageSettings.FeaturedImage = FeaturedImage;
    widgetFeaturedImageSettings.MediaConstant = MediaConstant;
    widgetFeaturedImageSettings.Utils = Utils;

    // ///////////////////////////
    //                         //
    //     Private functions    //
    //                         //
    // ///////////////////////////

    function isFocalPointFilled() {
        return (
            widgetFeaturedImageSettings.contextCroppedContent &&
            widgetFeaturedImageSettings.contextCroppedContent.focalPoint
        );
    }

    function getTranslation(key) {
        return Translation.translate(key);
    }

    /**
     * Get the cropped thumbnail if exists in the good language.
     * If not, get the original thumbnail.
     */
    function getFeaturedImageURL() {
        const rootContent = widgetFeaturedImageSettings.Content.getCurrent();
        return Document.getCroppedThumbnailFromRootContent(rootContent, true, 610, Translation.inputLanguage);
    }

    /**
     * Get the cropped content if exists.
     */

    function getContentCrop(type) {
        const currentContent = widgetFeaturedImageSettings.Content.getCurrent();
        if (currentContent.mediaThumbnail) {
            const content = Document.getMediaContentByLang(
                currentContent.mediaThumbnail,
                true,
                Translation.inputLanguage,
                false,
                type,
            );
            return content;
        }
        return undefined;
    }

    /**
     * Upload thumbnail src, crop information and focal point information
     */
    function refreshThumbnail() {
        $timeout(() => {
            widgetFeaturedImageSettings.featuredThumbnail = getFeaturedImageURL();
            widgetFeaturedImageSettings.featuredThumbnailBlob = getImageForThumbnail(widgetFeaturedImageSettings.featuredThumbnail);
            widgetFeaturedImageSettings.contextContent = widgetFeaturedImageSettings.getContentCrop('content');
            widgetFeaturedImageSettings.isCropDisabled =
                !widgetFeaturedImageSettings.contextContent || !widgetFeaturedImageSettings.contextContent.servingUrl;
            widgetFeaturedImageSettings.contextCroppedContent = widgetFeaturedImageSettings.getContentCrop(
                'croppedContent',
            );
        });
    }

    async function updateCurrentContent(focalPointInfo, cropInfo, canvasCroppedImage) {
        const currentContent = widgetFeaturedImageSettings.Content.getCurrent();
        // eslint-disable-next-line no-undef
        const copySelectedMedia = angular.fastCopy(currentContent.mediaThumbnail);
        const lang = Translation.inputLanguage;

        if (canvasCroppedImage) {
            const mediaCropped = await widgetFeaturedImageSettings.uploadCropContent(canvasCroppedImage);
            copySelectedMedia.croppedContent = [];
            Document.removeCrop(copySelectedMedia, lang);
            const formatedMedia = Media.formatUploadedFile(mediaCropped, lang);
            const firstContent = (formatedMedia.content && formatedMedia.content[0]) || {};
            firstContent.focalPoint = focalPointInfo;
            firstContent.cropInfo = cropInfo;
            copySelectedMedia.croppedContent.push(firstContent);
            copySelectedMedia.hasCroppedContent = true;
        } else {
            Document.removeCrop(copySelectedMedia, lang);
        }

        copySelectedMedia.content = copySelectedMedia.content || [{}];
        copySelectedMedia.content[0].focalPoint = focalPointInfo;
        copySelectedMedia.content[0].cropInfo = cropInfo;

        const newContentCurrent = {
            ...currentContent,
            mediaThumbnail: copySelectedMedia,
        };

        return newContentCurrent;
    }

    /**
     * When the community dialog closes, reset the services, stop the watchers and unselect all selected modules.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('abstract-picker__close-end', function onDialogCloseEnd(evt, dialogId) {
        if (dialogId === 'media-picker-content-thumbnail') {
            $scope.$apply(() => {
                refreshThumbnail();
            });
        }
    });

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Closes the cropper.
     */
    function onCropperClose() {
        $timeout(() => {
            widgetFeaturedImageSettings.cropperIsOpened = false;
        }, 1000);
    }

    /**
     * Opens the cropper dialog.
     */
    function openCropper() {
        widgetFeaturedImageSettings.cropperIsOpened = true;
    }

    /**
     * Upload cropped image and return media.
     *
     * @param {Object} canvas The `Cropper` canvas.
     */
    function uploadCropContent(canvas) {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                Upload.uploadMultiple([blob], undefined, (response) => {
                    resolve({
                        ...response,
                        name:
                            (widgetFeaturedImageSettings.contextContent &&
                                widgetFeaturedImageSettings.contextContent.name) ||
                            response.name,
                    });
                });
            });
        });
    }

    /**
     * Saves crop and focal point info and attach them to
     * the original media.content and media.croppedContent if exists.
     *
     * @param {Object} focalPointInfo Information about the Focal Point.
     * @param {Object} cropInfo Infomation about the crop.
     * @param {Object} canvas The `Cropper` canvas (optionnal, if image has been cropped)
     */
    async function onCropperConfirm(focalPointInfo, cropInfo, canvasCroppedImage) {
        const newContentCurrent = await updateCurrentContent(focalPointInfo, cropInfo, canvasCroppedImage);
        Content.setCurrent(newContentCurrent);
        refreshThumbnail();
        $rootScope.$broadcast('update-featured-image-thumbnail');
        onCropperClose();
    }

    widgetFeaturedImageSettings.isFocalPointFilled = isFocalPointFilled;
    widgetFeaturedImageSettings.getContentCrop = getContentCrop;
    widgetFeaturedImageSettings.getTranslation = getTranslation;
    widgetFeaturedImageSettings.onCropperClose = onCropperClose;
    widgetFeaturedImageSettings.openCropper = openCropper;
    widgetFeaturedImageSettings.onCropperConfirm = onCropperConfirm;
    widgetFeaturedImageSettings.uploadCropContent = uploadCropContent;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    $rootScope.$on('delete-content-thumbnail', (evt, contentId) => {
        if (Content.getCurrent().id !== contentId) {
            return;
        }

        $timeout(() => {
            widgetFeaturedImageSettings.featuredThumbnail = null;
            UploaderAction.clear(vm.widget.uuid);
        });
    });

    /**
     * Initialize the controller.
     */
    function init() {
        widgetFeaturedImageSettings.readonlyFields = Content.getViewMode() === 'locked';
        refreshThumbnail();
    }

    init();
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular
    .module('Controllers')
    .controller('WidgetFeaturedImageSettingsController', WidgetFeaturedImageSettingsController);
