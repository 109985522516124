import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiDelete } from '@lumapps/lumx/icons';
import {
    Message,
    Kind,
    SkeletonRectangle,
    AspectRatio,
    FlexBox,
    Orientation,
    SkeletonTypography,
    Typography,
    Size,
    Theme,
    SkeletonRectangleVariant,
    Text,
} from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { Event, LayoutEvent } from '../../api/types';
import { EVENTS } from '../../keys';
import { EventPreview } from '../EventPreview';

import './index.scss';

interface EventPreviewWrapperProps {
    id: string;
    hasImage: boolean;
    hasDescription: boolean;
    isLoading: boolean;
    theme: Theme;
    event: Event | LayoutEvent | null | undefined;
    pushBatchIdEvent(eventId: string): void;
    fetchEvents(): void;
    isLegacy?: boolean;
    shouldDisplayAttendButton?: boolean;
}
const CLASSNAME = 'event-preview-wrapper';

export const EventPreviewWrapper: React.FC<EventPreviewWrapperProps> = ({
    id,
    hasImage,
    hasDescription,
    pushBatchIdEvent,
    fetchEvents,
    isLoading = true,
    event,
    theme = Theme.light,
    shouldDisplayAttendButton = true,
    isLegacy,
}) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    useMemo(() => {
        if (isLegacy) {
            pushBatchIdEvent(id);
            fetchEvents();
        }
    }, [fetchEvents, id, isLegacy, pushBatchIdEvent]);

    if (isLoading) {
        return (
            <div className={block(`theme-${theme}`)}>
                {hasImage && (
                    <div className={element('thumbnail')}>
                        <SkeletonRectangle theme={theme} aspectRatio={AspectRatio.wide} />
                    </div>
                )}

                <FlexBox orientation={Orientation.vertical} className={element('container')} gap={Size.regular}>
                    <FlexBox orientation={Orientation.horizontal} className={element('top-informations')}>
                        <div className={element('date-title')}>
                            <SkeletonTypography theme={theme} typography={Typography.body2} width="54%" />
                            <SkeletonTypography theme={theme} typography={Typography.subtitle2} width="84%" />
                        </div>
                        <FlexBox gap={Size.regular} orientation={Orientation.vertical}>
                            <SkeletonTypography theme={theme} typography={Typography.caption} width="85%" />
                            <SkeletonRectangle
                                height={Size.s}
                                width={Size.xl}
                                theme={theme}
                                variant={SkeletonRectangleVariant.pill}
                            />
                        </FlexBox>
                    </FlexBox>

                    {hasDescription && <SkeletonTypography theme={theme} typography={Typography.body1} />}
                </FlexBox>
            </div>
        );
    }
    if (!event || (event && event.status === 'deleted')) {
        return (
            <Message kind={Kind.info} hasBackground icon={mdiDelete} className={element('delete-info', theme)}>
                <Text as="p">{translateKey(EVENTS.DELETED)}</Text>
            </Message>
        );
    }

    return (
        <EventPreview
            event={event}
            theme={theme}
            isLegacy={isLegacy}
            shouldDisplayAttendButton={shouldDisplayAttendButton}
        />
    );
};

EventPreviewWrapper.displayName = 'EventPreviewWrapper';
