import React from 'react';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { margin } from '@lumapps/classnames';
import { AppId } from '@lumapps/constants/app';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ToggleTipButton } from '@lumapps/lumx-buttons/components/ToggleTipButton';
import { DOCUMENTATION_LINKS } from '@lumapps/lumx-texts/components/ReferToDocumentation';
import { mdiHelpCircleOutline, mdiOpenInNew } from '@lumapps/lumx/icons';
import { Button, ColorPalette, Emphasis, FlexBox, Link, Theme, Typography, Text } from '@lumapps/lumx/react';
import { useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFollowInterest } from '../../hooks/useFollowInterest';
import { WIDGET_CONTENT_FILTER } from '../../keys';
import { GenericListFilterType } from '../../types';

interface FollowThisInterestProps {
    filtersValues?: GenericListFilterType;
    scope: string;
    theme: Theme;
}

export const FollowThisInterest = ({ filtersValues, scope, theme }: FollowThisInterestProps) => {
    const { translateKey, translateAndReplaceWithComponents } = useTranslate();
    const { getUrl } = useRouter();
    const { get } = useDataAttributes(scope);

    /** Selected fitlers (not necessarily applied) are taking precedence over the pre-filters from properties */
    const { contentTypes, tags, metadata } = filtersValues || {};
    const tagIds = map(tags, 'id');
    const metadataIds = map(metadata, 'id');

    const handleInterest = useFollowInterest({
        contentTypes: map(contentTypes, 'id'),
        tags: tagIds,
        metadata: metadataIds,
    });

    const subscriptionsUrl = getUrl({
        appId: AppId.frontOffice,
        path: '/ls/subscriptions',
        query: { filter: 'interests' },
    });

    return (
        <FlexBox>
            <Button
                emphasis={Emphasis.medium}
                onClick={handleInterest}
                theme={theme}
                /** No interests to follow if no tags and metadata */
                isDisabled={isEmpty(tagIds) && isEmpty(metadataIds)}
                {...get({ element: 'button', action: 'save-interest' })}
            >
                {translateKey(WIDGET_CONTENT_FILTER.SAVE_AS_INTEREST)}
            </Button>
            <ToggleTipButton
                className={margin('left', 'tiny')}
                iconColor={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                icon={mdiHelpCircleOutline}
                content={
                    <Text
                        as="p"
                        color={ColorPalette.light}
                        typography={Typography.body1}
                        style={{ whiteSpace: 'break-spaces' }}
                    >
                        {/* TODO: Split into two translations */}
                        {translateAndReplaceWithComponents(WIDGET_CONTENT_FILTER.INTEREST_INFORMATION, {
                            SUBSCRIPTION_LINK: (
                                <Link typography={Typography.subtitle1} color="light" href={subscriptionsUrl}>
                                    {translateKey(GLOBAL.SUBSCRIPTIONS)}
                                </Link>
                            ),
                            DOCUMENTATION_LINK: (
                                <Link
                                    color="light"
                                    typography={Typography.subtitle1}
                                    rightIcon={mdiOpenInNew}
                                    href={DOCUMENTATION_LINKS.notifications.interests}
                                    target="_blank"
                                >
                                    {translateKey(GLOBAL.DOCUMENTATION)}
                                </Link>
                            ),
                        })}
                    </Text>
                }
            />
        </FlexBox>
    );
};
