import React, { useCallback, useMemo, useRef } from 'react';

import isEqual from 'lodash/isEqual';

import { padding, useClassnames } from '@lumapps/classnames';
import { Placement, Popover } from '@lumapps/lumx/react';
import { Editor, Element, ReactEditor, useSlate } from '@lumapps/wrex/slate';

import type { LinkEditor } from '../../../types';
import { Link } from '../../blocks/Link';
import { LinkEdit } from './LinkEdit';
import { LinkPreview } from './LinkPreview';

import './index.scss';

const isOnlyNodeSelected = (editor: Editor, node: Element) => {
    if (!editor.selection) {
        return false;
    }
    const nodeAtStart = Editor.parent(editor, editor.selection.anchor);
    const nodeAtEnd = Editor.parent(editor, editor.selection.focus);
    const singleNodeSelected = isEqual(nodeAtStart, nodeAtEnd);
    if (!singleNodeSelected) {
        return false;
    }
    const nodePath = ReactEditor.findPath(editor as ReactEditor, node);
    return isEqual(nodeAtStart[1], nodePath);
};

/**
 * Watch link element selected state.
 */
function useSelectedElement(editor: ReactEditor, element?: Element) {
    const { selection } = editor;

    // Update element selected state.
    return useMemo(() => {
        if (selection && element) {
            return isOnlyNodeSelected(editor, element);
        }
        return false;
    }, [selection, element, editor]);
}

const CLASSNAME = 'wrex-link-editable';
export const EditableLink: typeof Link = (props) => {
    const { children, elementRef, element, onClick, readOnly: isReadOnly, ...forwardedProps } = props;
    const { element: elementClassname } = useClassnames(CLASSNAME);
    const editor = useSlate() as ReactEditor & LinkEditor;
    const elementPath = ReactEditor.findPath(editor, element as Element);
    const deleteLink = useCallback(() => {
        editor.unwrapLink(editor, elementPath);
    }, [editor, elementPath]);

    // Edit mode.
    const isInEditMode = element?.isInEditMode;
    const setIsInEditMode = useCallback(
        (value) => editor.setLinkEditionStatus(elementPath, value),
        [editor, elementPath],
    );
    const closeEditMode = useCallback(() => setIsInEditMode(false), [setIsInEditMode]);
    const openEditMode = useCallback(() => setIsInEditMode(true), [setIsInEditMode]);

    const isSelected = useSelectedElement(editor, element);

    // Close popover.
    const close = useCallback(() => {
        closeEditMode();
    }, [closeEditMode]);

    // Open popover on link selection or editing the link.
    const isOpen = useMemo(() => {
        return isSelected || isInEditMode;
    }, [isInEditMode, isSelected]);

    const linkRef = useRef(null);
    const popoverRef = useRef(null);

    return (
        <>
            {element && isOpen && (
                <Popover
                    className={elementClassname(isInEditMode ? 'edit-popover' : 'preview-popover', [
                        padding('all', 'tiny'),
                    ])}
                    anchorRef={linkRef}
                    ref={popoverRef}
                    placement={Placement.BOTTOM_START}
                    isOpen={isOpen}
                    offset={{ away: 2 }}
                    onClose={close}
                    usePortal={isInEditMode}
                    contentEditable={false}
                    closeOnClickAway
                >
                    {!isInEditMode && <LinkPreview linkElement={element} onEdit={openEditMode} onDelete={deleteLink} />}
                    {isInEditMode && <LinkEdit editor={editor} link={element} close={close} className={CLASSNAME} />}
                </Popover>
            )}
            <span ref={linkRef}>
                <Link
                    {...forwardedProps}
                    className={CLASSNAME}
                    element={element}
                    elementRef={elementRef}
                    onClick={(event) => (isReadOnly || !onClick ? event.preventDefault() : onClick(event))}
                >
                    {children}
                </Link>
            </span>
        </>
    );
};
EditableLink.displayName = Link.displayName;
