import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';

import {
    PLAY_ALPHA_FEATURE_TOKEN,
    PLAY_CHINA_FEATURE_TOKEN,
    PLAY_VIDEO_DOWNLOAD_TOKEN,
    PLAY_HEADER_BUTTON_FEATURE_TOKEN,
    PLAY_VIDEO_FEATURE_TOKEN,
    PLAY_BETA_FEATURE_TOKEN,
    LOGIN_V2_ALPHA_FEATURE_TOKEN,
    LOGIN_V2_BETA_FEATURE_TOKEN,
    PLAY_NEW_PUBLICATION_WORKFLOW,
} from '../constants';

export const isPlayAlphaFeatureEnabled = isFeatureEnabled(PLAY_ALPHA_FEATURE_TOKEN);
export const isPlayBetaFeatureEnabled = isFeatureEnabled(PLAY_BETA_FEATURE_TOKEN);
export const isPlayChinaFeatureEnabled = isFeatureEnabled(PLAY_CHINA_FEATURE_TOKEN);
export const isPlayHeaderButtonFeatureTokenEnabled = isFeatureEnabled(PLAY_HEADER_BUTTON_FEATURE_TOKEN);
export const isPlayVideoFeatureTokenEnabled = isFeatureEnabled(PLAY_VIDEO_FEATURE_TOKEN);
export const isPlayVideoDownloadFeatureTokenEnabled = isFeatureEnabled(PLAY_VIDEO_DOWNLOAD_TOKEN);
export const isPlayNewPublicationWorkflow = isFeatureEnabled(PLAY_NEW_PUBLICATION_WORKFLOW);

export const isLoginV2AlphaFeatureTokenEnabled = isFeatureEnabled(LOGIN_V2_ALPHA_FEATURE_TOKEN);
export const isLoginV2BetaFeatureTokenEnabled = isFeatureEnabled(LOGIN_V2_BETA_FEATURE_TOKEN);

export const isPlayVideoEnabled = createSelector(isPlayVideoFeatureTokenEnabled, (isVideoEnabled) => isVideoEnabled);
export const isPlayAlphaEnabled = createSelector(isPlayAlphaFeatureEnabled, (isPlayAlphaEnabled) => isPlayAlphaEnabled);

export const canDisplayPlayHeaderButton = createSelector(
    isPlayVideoFeatureTokenEnabled,
    isPlayHeaderButtonFeatureTokenEnabled,
    (isPlayEnabled, isPlayheaderButtonEnabled) => isPlayEnabled && isPlayheaderButtonEnabled,
);
