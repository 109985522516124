export enum WIDGET_MANDATORY_READ {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62880610
     */
    ACKNOWLEDGED_DESCRIPTION_DEFAULT = 'FRONT.MANDATORY_READ.ACKNOWLEDGED_DESCRIPTION_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62880614
     */
    UNACKNOWLEDGED_DESCRIPTION_DEFAULT = 'FRONT.MANDATORY_READ.UNACKNOWLEDGED_DESCRIPTION_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62880622
     */
    CONFIRM_LABEL_DEFAULT = 'FRONT.MANDATORY_READ.CONFIRM_LABEL_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62880630
     */
    READ_ON_LABEL = 'FRONT.MANDATORY_READ.READ_ON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62880645
     */
    BLOCK_TITLE = 'FRONT.MANDATORY_READ.BLOCK_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62880645
     */
    ALREADY_ACKNOWLEDGED_ERROR = 'FRONT.WIDGET_MANDATORY_READ.ALREADY_ACKNOWLEDGED_ERROR',
}
