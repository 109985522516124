import React, { useCallback, useEffect, useRef, useState } from 'react';

import scrollIntoView from 'scroll-into-view-if-needed';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { InsertAltTextDialog } from '@lumapps/lumx-dialogs/components/AltTextDialog';
import { LinkDialog } from '@lumapps/lumx-dialogs/components/LinkDialog';
import { LUMX_DIALOGS } from '@lumapps/lumx-dialogs/keys';
import {
    imageAlignmentCenter,
    imageAlignmentFull,
    imageAlignmentLeft,
    imageAlignmentRight,
} from '@lumapps/lumx/custom-icons';
import { mdiClose, mdiLinkVariant } from '@lumapps/lumx/icons';
import { FlexBox, ProgressCircular } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { WREX_IMAGE } from '@lumapps/wrex-image/keys';
import { ElementToolbar } from '@lumapps/wrex/components/ElementToolbar';
import { WREX_CORE } from '@lumapps/wrex/keys';
import { Editor, Path, ReactEditor, useSelected, useSlateStatic } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import { ElementRender, ToolbarItem as ToolbarItemType } from '@lumapps/wrex/types';

import { CLASSNAME, IMAGE_ALIGNMENTS, IMAGE_FEATURES, IMAGE_GROUP, IMAGE_WIDTHS } from '../../../constants';
import { WREX_ENHANCED_IMAGE } from '../../../keys';
import { ImageEditor, ImageGroupElement } from '../../../types';
import { changeImageWidth } from '../../../utils/changeImageWidth';
import { isAlignedImageWrapper } from '../../../utils/isAlignedImageWrapper';
import { ImageGroup } from '../../blocks/ImageGroup';
import { useAutoWidth } from './useAutoWidth';

import './index.scss';

/**
 * Wraps the Image content to add edition-related behaviors.
 */
export const EditableEnhancedImage: ElementRender<ImageGroupElement, HTMLDivElement> = (props) => {
    const { children, element = {} as ImageGroupElement, elementRef, readOnly: isReadOnly, ...forwardedProps } = props;

    const { translateKey } = useTranslate();
    const { element: elementClass, block: blockClass } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(CLASSNAME);
    const editor = useSlateStatic() as ReactEditor & ImageEditor;
    const selected = useSelected();
    const { onDelete } = useInlineVoid(editor, element);

    // Focus caption input on mount only on new images (having blob URL).
    const inputRef = useRef<HTMLInputElement>(null);

    const [captionValue, setCaptionValue] = useState(element?.title);
    const [isAltTextDialogOpen, , closeAltTextDialog, openAltTextDialog] = useBooleanState(false);
    const [isLinkDialogOpen, , closeLinkDialog, openLinkDialog] = useBooleanState(false);
    // Used for toolbar responsive, 350 being the width in which the toolbar overflow the image in hungarian
    const { currentBreakpoint, ref: dimRef } = useDimensions({ breakpoints: { reduced: 0, full: 350 } });

    const path = ReactEditor.findPath(editor, element) as Path;
    const isAltPresent = !!element?.images[0].alt;
    const isLinkPresent = !!element?.images[0].link;

    const wrapper = findParent(editor, path, isAlignedImageWrapper);
    const isAlignedImage = !!wrapper;

    const imgRef = useAutoWidth({ editor, element, path, isAlignedImage });

    const staticOptions: ToolbarItemType[] = [
        {
            type: 'option',
            icon: mdiClose,
            itemKey: 'delete-image',
            tooltipLabel: translateKey(GLOBAL.DELETE),
            onClick: onDelete,
            otherProps: { ...get({ element: 'button', action: 'delete-image' }) },
        },
    ];

    const section: ToolbarItemType = {
        type: 'section',
        label: '',
        itemKey: 'image-options-section',
        childrenOptions: [],
    };

    const hasAlignment = editor.isImageFeatureEnabled(IMAGE_FEATURES.alignment);
    const hasWidthFeature = editor.isImageFeatureEnabled(IMAGE_FEATURES.width);
    const hasLinkFeature = editor.isImageFeatureEnabled(IMAGE_FEATURES.link);
    if (hasAlignment || hasWidthFeature) {
        const alignment = wrapper?.[0].alignment;
        if (hasAlignment) {
            section.childrenOptions.push({
                type: 'toggle-option',
                icon: imageAlignmentLeft,
                itemKey: 'alignment-left',
                tooltipLabel: translateKey(WREX_CORE.ALIGN_TO_LEFT),
                verticalModeProps: {
                    label: translateKey(WREX_CORE.ALIGN_TO_LEFT),
                    tooltipLabel: undefined,
                },
                isChecked: alignment === IMAGE_ALIGNMENTS.left,
                onClick: () => editor.changeImageAlignment(path, IMAGE_ALIGNMENTS.left),
                otherProps: { ...get({ element: 'button', action: 'alignment-left' }) },
            });
        }
        if (hasWidthFeature) {
            section.childrenOptions.push(
                {
                    type: 'toggle-option',
                    icon: imageAlignmentCenter,
                    itemKey: 'alignment-center',
                    tooltipLabel: translateKey(WREX_CORE.ALIGN_CENTER),
                    verticalModeProps: {
                        label: translateKey(WREX_CORE.ALIGN_CENTER),
                        tooltipLabel: undefined,
                    },
                    isChecked: !alignment && element?.width === IMAGE_WIDTHS.halfWidth,
                    onClick: () => changeImageWidth(editor, path, IMAGE_WIDTHS.halfWidth),
                    otherProps: { ...get({ element: 'button', action: 'alignment-center' }) },
                },
                {
                    type: 'toggle-option',
                    icon: imageAlignmentFull,
                    itemKey: 'alignment-full',
                    tooltipLabel: translateKey(WREX_ENHANCED_IMAGE.FULL_WIDTH),
                    verticalModeProps: {
                        label: translateKey(WREX_ENHANCED_IMAGE.FULL_WIDTH),
                        tooltipLabel: undefined,
                    },
                    isChecked: !alignment && element?.width === IMAGE_WIDTHS.fullWidth,
                    onClick: () => changeImageWidth(editor, path, IMAGE_WIDTHS.fullWidth),
                    otherProps: { ...get({ element: 'button', action: 'alignment-full' }) },
                },
            );
        }

        if (hasAlignment) {
            section.childrenOptions.push({
                type: 'toggle-option',
                icon: imageAlignmentRight,
                itemKey: 'alignment-right',
                tooltipLabel: translateKey(WREX_CORE.ALIGN_TO_RIGHT),
                verticalModeProps: {
                    label: translateKey(WREX_CORE.ALIGN_TO_RIGHT),
                    tooltipLabel: undefined,
                },
                isChecked: alignment === IMAGE_ALIGNMENTS.right,
                onClick: () => editor.changeImageAlignment(path, IMAGE_ALIGNMENTS.right),
                otherProps: { ...get({ element: 'button', action: 'alignment-right' }) },
            });
        }

        section.childrenOptions.push({ type: 'divider', itemKey: 'divider1' });
    }

    if (hasLinkFeature) {
        section.childrenOptions.push({
            type: 'option',
            icon: mdiLinkVariant,
            itemKey: 'image-link',
            tooltipLabel: translateKey(
                isLinkPresent ? LUMX_DIALOGS.LINK_DIALOG_EDIT_TITLE : LUMX_DIALOGS.LINK_DIALOG_ADD_TITLE,
            ),
            verticalModeProps: {
                label: translateKey(
                    isLinkPresent ? LUMX_DIALOGS.LINK_DIALOG_EDIT_TITLE : LUMX_DIALOGS.LINK_DIALOG_ADD_TITLE,
                ),
                tooltipLabel: undefined,
            },
            onClick: openLinkDialog,
            otherProps: {
                ...get({
                    element: 'button',
                    action: isLinkPresent ? 'edit-link' : 'add-link',
                }),
            },
        });
        section.childrenOptions.push({ type: 'divider', itemKey: 'divider2' });
    }

    section.childrenOptions.push({
        type: 'option',
        itemKey: 'image-alt',
        label: translateKey(isAltPresent ? WREX_IMAGE.INSERT_ALT_TEXT_EDIT : WREX_IMAGE.INSERT_ALT_TEXT_ADD),
        onClick: openAltTextDialog,
        otherProps: {
            ...get({
                element: 'button',
                action: isAltPresent ? 'open-edit-alt-dialog' : 'open-add-alt-dialog',
            }),
        },
    });

    const onCaptionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const pathRef = Editor.pathRef(editor, path);
            editor.updateImageNode(
                {
                    title: event.target.value,
                },
                pathRef,
            );
            setCaptionValue(event.target.value);
        },
        [editor, path],
    );

    const onCaptionKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (!element) {
                return;
            }
            let focusLocation: Path | null = null;
            if (isHotKey('Enter', event as any) || isHotKey('ArrowDown', event as any)) {
                // Enter OR ArrowDown => Focus previous element
                focusLocation = getSiblingPath(ReactEditor.findPath(editor, element), 'after');
            } else if (isHotKey('ArrowUp', event as any)) {
                // ArrowUp => Focus previous element
                focusLocation = getSiblingPath(ReactEditor.findPath(editor, element), 'before');
            }
            if (focusLocation) {
                event.preventDefault();
                focusAt(editor, focusLocation);
            }
        },
        [editor, element],
    );

    const insertAltText = useCallback(
        (altText: string) => {
            editor.updateImageNode(
                {
                    images: [
                        {
                            ...element.images[0],
                            alt: altText,
                        },
                    ],
                },
                path,
            );
            closeAltTextDialog();
        },
        [closeAltTextDialog, editor, element, path],
    );

    const insertLink = useCallback(
        (link: string) => {
            editor.updateImageNode(
                {
                    images: [
                        {
                            ...element.images[0],
                            link,
                        },
                    ],
                },
                path,
            );
            closeLinkDialog();
        },
        [closeLinkDialog, editor, element, path],
    );

    useEffect(() => {
        const { current } = inputRef;

        if (!element.shouldFocusCaptionOnMount || !current) {
            return;
        }

        current.focus();
        // Use of a setTimeout to handle the little
        // delay between the set of the image height.
        setTimeout(() => {
            scrollIntoView(current);
        }, 100);
    }, [element]);

    return (
        <div
            className={elementClass('main-wrapper', { editable: true, selected })}
            ref={elementRef}
            {...forwardedProps}
        >
            <ImageGroup
                isInEditionMode
                element={element}
                className={blockClass({ editable: true, selected })}
                elementRef={dimRef}
                imgRef={imgRef}
            >
                {children}

                {!element?.isLoading && (
                    <ElementToolbar
                        className={elementClass('actions')}
                        toolbarOptions={[section]}
                        staticOptions={staticOptions}
                        dataScope={CLASSNAME}
                        toolbarAriaLabel={translateKey(WREX_ENHANCED_IMAGE.TOOLBAR)}
                        currentBreakpoint={currentBreakpoint}
                    />
                )}
            </ImageGroup>
            {element?.isLoading && (
                <FlexBox fillSpace hAlign="center" vAlign="center" className={elementClass('loader-wrapper')}>
                    <ProgressCircular />
                </FlexBox>
            )}
            {!element?.isLoading && (
                <input
                    ref={inputRef}
                    contentEditable={false}
                    value={captionValue}
                    onChange={onCaptionChange}
                    onKeyDown={onCaptionKeyDown}
                    placeholder={translateKey(WREX_IMAGE.IMAGE_CAPTION_PLACEHOLDER)}
                    className={elementClass('caption-input', {
                        'align-center': element?.width === IMAGE_WIDTHS.halfWidth,
                    })}
                    readOnly={isReadOnly}
                />
            )}
            <InsertAltTextDialog
                isOpen={isAltTextDialogOpen}
                size="tiny"
                currentAlt={element?.images[0].alt || ''}
                image={element.images[0].tempSrc || element.images[0].src}
                onChange={insertAltText}
                onClose={closeAltTextDialog}
                scope="wrex--alt-text-dialog"
            />
            <LinkDialog
                inputPlaceholder={translateKey(LUMX_DIALOGS.LINK_DIALOG_INPUT_PLACEHOLDER)}
                isOpen={isLinkDialogOpen}
                currentLink={element?.images[0].link || ''}
                onChange={insertLink}
                onClose={closeLinkDialog}
                scope="wrex--link-dialog"
            />
        </div>
    );
};
EditableEnhancedImage.displayName = IMAGE_GROUP;
