import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const promotedResultsAdminRoute: Route = {
    path: '/admin/promoted-results',
    legacyId: 'app.admin.promoted-content',
    appId: AppId.backOffice,
};

export const promotedResultsAdmin = (): Route => promotedResultsAdminRoute;
