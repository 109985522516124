import React from 'react';
import classNames from 'classnames';
import { array, bool, func, oneOfType, string, shape } from 'prop-types';

import first from 'lodash/first';

import { Button } from '../Button';
import { Icon } from '../../icons';
import { Select } from '../../menus';

import { getLabel } from '../../utils';

/**
 * Renders a dropdown selection menu, similar to `<Select />` but uses a Flat Button to display the current selection.
 * An optional Button allows to revert the direction of the sort order.
 *
 * The following params: `hasClearChoice`, `value` are props of the <Select /> component.
 * They're destructured to help build the other components props.
 *
 * @return {Component} The SortBy component.
 */
// eslint-disable-next-line no-shadow
const SortBy = ({ className, name, onChange, sortOrder, ...otherProps }) => {
    const selectProps = {
        ...otherProps,
        hasClearChoice: false,
        name,
        onChange,
        value: sortOrder.value,
    };
    const label = getLabel({
        choices: selectProps.choices,
        placeholder: selectProps.placeholder || (selectProps.choices && first(selectProps.choices).label),
        value: sortOrder.value,
    });

    /**
     * Change the direction of the current sort order.
     *
     * @param {Event} evt The click event triggering this method.
     */
    const changeDirection = (evt) => {
        if (!sortOrder.value) {
            return;
        }

        onChange(sortOrder.value, name, evt, !sortOrder.isAscending);
    };

    return (
        <div className={classNames('sort-by', className)}>
            <Select
                ToggleComponent={
                    <Button color="grey" variant="flat">
                        {label.text}
                    </Button>
                }
                className="sort-by__select"
                {...selectProps}
            />

            {/* eslint-disable-next-line react/jsx-no-bind */}
            <Button
                className="sort-by__direction-button"
                color="grey"
                disabled={sortOrder.value === null}
                variant="icon"
                onClick={changeDirection}
            >
                <Icon value={sortOrder.isAscending ? 'arrow-up' : 'arrow-down'} />
            </Button>
        </div>
    );
};

SortBy.propTypes = {
    /** A custom css selector to apply to the wrapper of the component. */
    className: string,
    /** The name of the select input. */
    name: string.isRequired,
    /** Called when `value` changes with `(value, name, occurence)`. */
    onChange: func.isRequired,
    /** The value and direction of the current sort. */
    sortOrder: shape({
        isAscending: bool,
        value: oneOfType([array, string]),
    }),
};

SortBy.defaultProps = {
    className: null,
    sortOrder: {
        isAscending: false,
        value: null,
    },
};

export { SortBy };
