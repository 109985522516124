import BaseApi, { BaseApiPromise } from '@lumapps/base-api';
import { GetBaseUrl } from '@lumapps/learning-utils/getBaseUrl';

import { LearnerStatsType } from '../types';

/**
 * learner API.
 */
const learnerStatsApi = (baseURL: string) =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL,
    });

/**
 * learner kpis parametrized APIs
 * - `learner/stats allows to retrieve current user's stats
 */
const paths = {
    identity: 'identity/v2/users/profile/summary',
};

export const getLearnerStats = (learningAlphaEnabled: boolean): BaseApiPromise<LearnerStatsType> => {
    const baseURL = GetBaseUrl(learningAlphaEnabled);

    return learnerStatsApi(baseURL).get<LearnerStatsType>(paths.identity);
};

export { learnerStatsApi, paths };
