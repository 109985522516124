export enum FEATURES_MANAGEMENT {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260754359
     */
    FEATURE_LOCK = 'ADMIN.FEATURE_MANAGEMENT.FEATURE_LOCK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=252226883
     */
    MESSAGE_DOC_LINK = 'ADMIN.FEATURE_MANAGEMENT.MESSAGE_DOC_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=264812186
     */
    ONLY_SHOW_AVAILABLE_VALUE = 'ADMIN.FEATURE_MANAGEMENT.ONLY_SHOW_AVAILABLE_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260373124
     */
    PACKAGES_DOC_LINK = 'ADMIN.FEATURE_MANAGEMENT.PACKAGES_DOC_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=252214028
     */
    PACKAGE_MESSAGE = 'ADMIN.FEATURE_MANAGEMENT.PACKAGE_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90781173
     */
    SAVE_ERROR_UNKOWN = 'ADMIN.FEATURE_MANAGEMENT.SAVE_ERROR_UNKOWN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90781173
     */
    SHOW_AVAILABLE_FEATURES = 'ADMIN.FEATURE_MANAGEMENT.SHOW_AVAILABLE_FEATURES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=85434970
     */
    TITLE = 'ADMIN.FEATURES_MANAGEMENT.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=285117156
     */
    TRY_NEW_SEARCH_TERM = 'ADMIN.FEATURES_MANAGEMENT.TRY_NEW_SEARCH_TERM',
}
