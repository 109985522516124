import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { isInDesignerMode } from '@lumapps/contents/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { TranslateObject } from '@lumapps/translations';
import { inputLocaleSelector } from '@lumapps/translations/selectors';
import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { WidgetContentProps } from '@lumapps/widget-base/components/WidgetContent';
import { LegacyWidget } from '@lumapps/widget-base/types';

import { LegacyWidgetSummaryProps } from '../../types';
import { EditableWidgetSummary, EditableWidgetSummaryProps } from '../EditableWidgetSummary';

import './index.scss';

export interface WidgetSummaryWrapperProps extends Partial<WidgetContentProps> {
    /** the legacy widget to be rendered. */
    legacyWidget: LegacyWidget<LegacyWidgetSummaryProps>;
    /** the callback to switch to reading mode on click outside the editor (legacy) */
    switchToReadMode: () => void;
    /** the callback to save the content in widget properties (legacy) */
    saveTextInWidgetProperties: (text?: { translations: TranslateObject<string> }) => void;
    /** the status of the widget: edition or reading mode */
    isEditingContent?: boolean;
    /** whether the widget is empty or not (no content) */
    isWidgetEmpty?: () => boolean;
    /** The current created / edited content title */
    contentTitle?: TranslateObject<string>;
}

export interface EditableWidgetBlockProperties {
    text?: string;
}

export const WidgetSummaryWrapper: React.FC<WidgetSummaryWrapperProps> = ({
    legacyWidget: initLegacyWidget,
    switchToReadMode,
    saveTextInWidgetProperties,
    isEditingContent,
    isWidgetEmpty,
    contentTitle,
    ...props
}: WidgetSummaryWrapperProps) => {
    const currentInputLang = useSelector(inputLocaleSelector);
    const isDesignerMode = useSelector(isInDesignerMode);
    const wrapperRef = React.useRef(null);
    const childrenRefs = React.useRef([wrapperRef]);
    /**
     *  If we in a designer mode we shouldn't use lang fallback
     *  to avoid issue with the content preview
     */
    const canUseLangFallback = isDesignerMode && false;

    /**
     * Since <react-element> don't watch the object entierly, but watch it's 'properties' propertie
     * (to avoid rerendering and make first double click to edit work), we need to destructure the object
     * (since only the 'properties' propertie is watched).
     */
    const legacyWidget = { ...initLegacyWidget };

    const initialContent = React.useMemo(() => {
        if (legacyWidget.properties?.text && !isEmpty(legacyWidget.properties?.text)) {
            return legacyWidget.properties?.text.translations[currentInputLang];
        }
        return '';
    }, [currentInputLang, legacyWidget.properties?.text]);

    const [content, onChange] = React.useState(initialContent);

    const onExit = React.useCallback(() => {
        saveTextInWidgetProperties({
            translations: { ...legacyWidget.properties?.text?.translations, [currentInputLang]: content },
        });
    }, [content, currentInputLang, legacyWidget.properties?.text?.translations, saveTextInWidgetProperties]);

    /** on click outside the editor, we call onExit callback and switch to read mode */
    const exitEditionMode = React.useCallback(() => {
        onExit();

        switchToReadMode();
    }, [switchToReadMode, onExit]);

    /** manage language switch */
    React.useEffect(() => {
        if (legacyWidget.properties?.text && !isEmpty(legacyWidget.properties?.text)) {
            /** if the content for selected language has already been set use it */
            const textContent = legacyWidget.properties?.text.translations[currentInputLang];
            if (!isEmpty(textContent)) {
                onChange(textContent);
            } else {
                onChange('');
            }
        }
    }, [currentInputLang, legacyWidget.properties?.text, onChange]);

    const theme = legacyWidget.properties?.style?.content?.theme;

    return (
        <BackendRecomputedWidget<LegacyWidgetSummaryProps, EditableWidgetBlockProperties, EditableWidgetSummaryProps>
            legacyWidget={legacyWidget}
            editableWidgetRenderer={EditableWidgetSummary}
            editableWidgetProps={{
                wrapperRef,
                childrenRefs,
                exitEditionMode,
                content,
                onChange,
                theme,
                contentTitle,
            }}
            isWidgetEmpty={isWidgetEmpty}
            blockProperties={{ text: content }}
            editingContent={isEditingContent}
            canUseLangFallback={canUseLangFallback}
            {...props}
        />
    );
};
