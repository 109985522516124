export enum CONTENTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68593154
     */
    CONTEXTUAL_ACTIONS_TRANSLATION_ON_ACTION = 'FRONT.CONTEXTUAL_ACTIONS.TRANSLATION_ON_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68593157
     */
    CONTEXTUAL_ACTIONS_TRANSLATION_OFF_ACTION = 'FRONT.CONTEXTUAL_ACTIONS.TRANSLATION_OFF_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92200905
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_PUBLISH = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.PUBLISH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92200991
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_CHANGE_SETTINGS = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.CHANGE_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92201143
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_SINGLE_FEED = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.SINGLE_FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92201150
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_FEED_LIST_MESSAGE = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.FEED_LIST_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92201156
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_TITLE = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93565729
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_ALL_FEED = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.ALL_FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493733631
     */
    CONTENT_PUBLISHED = 'CONTENTS.CONTENT_PUBLISHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493733904
     */
    CONTENT_PUBLISHED_NOTIFICATION_DESCRIPTION = 'CONTENTS.CONTENT_PUBLISHED_NOTIFICATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510233070
     */
    DISTRIBUTE_CONTENT = 'ADMIN.FEATURES.BROADCAST_DISTRIBUTE_CONTENT.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=496630687
     */
    DISTRIBUTED = 'CONTENTS.DISTRIBUTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=496630703
     */
    DISTRIBUTION_SCHEDULED = 'CONTENTS.DISTRIBUTION_SCHEDULED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158106895
     */
    NO_CONTENT = 'CONTENTS.NO_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=340580608
     */
    DISPLAY_PUBLICATION = 'CONTENTS.DISPLAY_PUBLICATION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452766
     */
    SETTINGS_VISIBLE_BY = 'CONTENT_SETTINGS_VISIBLE_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27106794
     */
    SETTINGS_VISIBLE_BY_HELP = 'ADMIN.TEMPLATE.CONTENT_SETTINGS_VISIBLE_BY_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452723
     */
    SETTINGS_HIGHLIGHTED_FEEDS = 'CONTENT_SETTINGS_HIGHLIGHTED_FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27106798
     */
    SETTINGS_HIGHLIGHTED_FEEDS_HELP = 'ADMIN.TEMPLATE.CONTENT_SETTINGS_HIGHLIGHTED_FOR_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452947
     */
    FEED_REQUIRED = 'FEED_REQUIRED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452767
     */
    WRITE_ON_BEHALF_OF = 'CONTENT_SETTINGS_WRITE_INSTEAD_OF',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452719
     */
    EDITORS = 'CONTENT_SETTINGS_EDITORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28364221
     */
    DRAFT_IN_PROGRESS = 'CONTENT.LAST_REVISION.DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28364227
     */
    VERSION_REFUSED = 'CONTENT.LAST_REVISION.REFUSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28366240
     */
    VERSION_TO_VALIDATE = 'CONTENT.LAST_REVISION.TO_VALIDATE',
}
