import React, { ReactNode } from 'react';

import { useIsComponentDisabled, Targets } from '@lumapps/customizations/api';
import {
    instanceIdSelector,
    isFavoriteSitesEnabled,
    getCurrentInstanceName,
    getCurrentInstanceParentId,
} from '@lumapps/instance/ducks/selectors';
import { isLanguageSwitcherEnabled, currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { isMainNavInheritanceEnabled } from '@lumapps/navigation/ducks/selectors';
import { canDisplayPlayHeaderButton } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useRouteMatching } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';
import { isSAEnabled } from '@lumapps/sa-connected-user/ducks/selectors';
import { useComputedTopStyles } from '@lumapps/style/hooks/useComputedTopStyles';
import { isConnected } from '@lumapps/user/ducks/selectors';

import { getCurrentHeader, doesHaveAContentHeader } from '../../ducks/selectors';
import { Header as HeaderUi } from './Header';

export type HeaderProps = {
    /** children? */
    children?: ReactNode;
    /** whether the header is loading or not */
    isLoading?: boolean;
    /** list of routes where the content header will not be visible */
    hideContentHeaderRoutes?: string[];
};

const Header: React.FC<HeaderProps> = ({ children, isLoading = false, hideContentHeaderRoutes = [] }) => {
    const isUserConnected = useSelector(isConnected);
    const instanceId = useSelector(instanceIdSelector);
    const instanceName = useSelector(getCurrentInstanceName);
    const favoriteSites = useSelector(isFavoriteSitesEnabled);
    const learningAlpha = useSelector(isLearningAlphaEnabled);
    const play = useSelector(canDisplayPlayHeaderButton);
    const hasContentHeader = useSelector(doesHaveAContentHeader);
    const socialAdvocacy = useSelector(isSAEnabled);
    const languageSwitcher = useSelector(isLanguageSwitcherEnabled);
    const headerSettings = useSelector(getCurrentHeader);
    const mainNavInheritance = useSelector(isMainNavInheritanceEnabled);
    const parentId = useSelector(getCurrentInstanceParentId);
    const currentLanguage = useSelector(currentLanguageSelector);
    const isStickyHeaderDisabled = useIsComponentDisabled(Targets.STICKY_HEADER);
    const shouldHideContentHeaderInCurrentPage = useRouteMatching({
        path: hideContentHeaderRoutes,
        appId: AppId.frontOffice,
    });

    const topStyles = useComputedTopStyles({
        parentInstance: parentId,
        isMainNavInheritanceEnabled: mainNavInheritance,
    });

    return (
        <HeaderUi
            isLoading={isLoading}
            topStyles={topStyles}
            shouldShowContentHeaderInCurrentPage={!shouldHideContentHeaderInCurrentPage}
            isConnected={isUserConnected}
            instanceId={`${instanceId}`}
            instanceName={instanceName}
            parentInstanceId={parentId}
            contentPosition={headerSettings?.properties?.layoutPosition}
            hasContentHeader={hasContentHeader}
            currentLanguage={currentLanguage}
            isStickyEnabled={!isStickyHeaderDisabled}
            features={{
                learningAlpha,
                favoriteSites,
                socialAdvocacy,
                play,
                languageSwitcher,
                mainNavInheritance,
            }}
        >
            {children}
        </HeaderUi>
    );
};

export { Header };
