/* eslint-disable react/no-array-index-key */
import React from 'react';

import identity from 'lodash/identity';
import uniqueId from 'lodash/uniqueId';

import { classnames } from '@lumapps/classnames';
import { mdiMenuDown } from '@lumapps/lumx/icons';
import {
    Button,
    ButtonProps,
    List,
    ListItem,
    Placement,
    Popover,
    PopoverProps,
    Size,
    Tooltip,
} from '@lumapps/lumx/react';
import { MovingFocusProvider, useVirtualFocus, useVirtualFocusParent } from '@lumapps/moving-focus';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

export interface ButtonSelectProps<O> extends Omit<ButtonProps, 'onClick' | 'children'> {
    /** ref to forward to the button */
    buttonRef?: React.Ref<HTMLButtonElement>;
    /** CSS class name. */
    className?: string;
    /** Label displayed in a tooltip. */
    label: string;
    /** Selectable options. */
    options: O[];
    /** Selected value. */
    value?: O;
    /** Custom dropdown props. Like data-id */
    dropdownProps?: Partial<PopoverProps>;
    /** Whether the button is inside a dialog, used to handle the list close on Enter */
    isInsideDialog?: boolean;
    /** Selectable options. */
    onChange?(option: O, shouldTrigerClose?: boolean): void;
    /** Value renderer (defaults to the identity function). */
    valueRenderer?(value: O): React.ReactNode;
    /** Option renderer (otherwise use the ValueRenderer). */
    optionRenderer?(value: O): React.ReactNode;
    /** if passed in, the ButtonSelect will not display the selected value but always the actionLabel */
    actionLabel?: React.ReactNode;
    /** DataAttribute renderer */
    dropdownItemProps?: (value: O, index: number) => void;
}

interface ButtonSelectTriggerProps extends ButtonProps {
    /** CSS class name. */
    className?: string;
    isOpen?: boolean;
    toggleOpen(): void;
    onChange(option: any, shouldTrigerClose?: boolean): void;
    /** Selectable options. */
    options: any[];
}

interface ButtonSelectItemProps<O> {
    children: React.ReactNode;
    onItemSelected: (option: O) => void;
    isDisabled?: boolean;
    option: O;
    value?: O;
    optionId: string;
}

const CLASSNAME = 'button-select';

const ButtonSelectTrigger = React.forwardRef<HTMLButtonElement, ButtonSelectTriggerProps>(
    ({ className, children, options, isOpen, toggleOpen, onChange, rightIcon, ...forwardedProps }, ref) => {
        const buttonRef = React.useRef<HTMLButtonElement>(null);
        const activeDescendant = useVirtualFocusParent(buttonRef);

        const selectedOption = React.useMemo(() => {
            if (activeDescendant) {
                // Get the option index with his id value
                const regexId = activeDescendant.match(/button-select-option-([0-9]*)-*/m);
                const optionIndex = regexId ? parseInt(regexId[1], 10) : undefined;

                return optionIndex !== undefined ? options[optionIndex] : undefined;
            }
            return undefined;
        }, [activeDescendant, options]);

        const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(
            (event) => {
                if (
                    (event.key === 'ArrowDown' ||
                        event.key === 'ArrowUp' ||
                        event.key === 'End' ||
                        event.key === 'Home') &&
                    !isOpen
                ) {
                    toggleOpen();
                }
                if (isOpen) {
                    if (event.key === 'Enter' || event.key === ' ') {
                        onChange(selectedOption, false);
                    }

                    if (event.key === 'Tab') {
                        // Force the popup to close on Tab
                        onChange(selectedOption, true);
                    }
                }
            },
            [selectedOption, isOpen, onChange, toggleOpen],
        );

        return (
            <Button
                {...forwardedProps}
                role="combobox"
                onKeyDown={handleKeyDown}
                className={classnames(className, CLASSNAME)}
                ref={mergeRefs([buttonRef, ref])}
                onClick={toggleOpen}
                rightIcon={rightIcon}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-autocomplete="none"
                aria-activedescendant={isOpen ? activeDescendant : ''}
            >
                {children}
            </Button>
        );
    },
);

const ButtonSelectItem = <O,>({
    onItemSelected,
    children,
    option,
    optionId,
    isDisabled = false,
    value,
    ...otherProps
}: ButtonSelectItemProps<O>) => {
    const ref = React.useRef<HTMLLIElement>(null);
    const isHighlighted = useVirtualFocus(optionId, ref, isDisabled);
    const isSelected = option === value;

    return (
        <ListItem
            role="none"
            ref={ref}
            size={Size.tiny}
            isSelected={isSelected}
            isDisabled={isDisabled}
            isHighlighted={isHighlighted}
            onItemSelected={() => onItemSelected(option)}
            linkProps={{
                role: 'option',
                id: optionId,
                'aria-selected': isSelected,
                tabIndex: -1,
                ...(isHighlighted && { 'data-focus-visible-added': true }),
            }}
            {...otherProps}
        >
            {children}
        </ListItem>
    );
};

/**
 * A button with dropdown used to select a value from a list of options.
 *
 * @family Combobox
 * @param ButtonSelectProps
 * @returns ButtonSelect
 */
export const ButtonSelect = <O,>(props: ButtonSelectProps<O>) => {
    const {
        buttonRef,
        className,
        label,
        options,
        value,
        onChange,
        valueRenderer = identity as any,
        optionRenderer = valueRenderer,
        dropdownProps = {},
        dropdownItemProps,
        rightIcon = mdiMenuDown,
        isInsideDialog = false,
        actionLabel,
        ...forwardedProps
    } = props;

    const [isOpen, toggleOpen, close] = useBooleanState(false);

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const listId = React.useMemo(() => uniqueId(`${CLASSNAME}-list-`), []);

    const optionsId = React.useMemo(() => {
        return options.map((_, index) => uniqueId(`${CLASSNAME}-option-${index}-`));
    }, [options]);

    const selectedOptionId = React.useMemo(() => {
        const selectedOptionIndex = options.findIndex((option) => option === value);

        return optionsId[selectedOptionIndex];
    }, [value, options, optionsId]);

    const handleOnChange = (option: O, shouldTriggerClose = true) => {
        // Should force the popup to close
        if (shouldTriggerClose) {
            close();
        } else if (isInsideDialog) {
            toggleOpen();
        }

        if (onChange) {
            onChange(option, shouldTriggerClose);
        }
    };

    const optionItem = (option: O, otherProps: void | Record<string, never>, index: number) => {
        return (
            <ButtonSelectItem<O>
                key={index}
                isDisabled={(option as any).isDisabled}
                value={value}
                option={option}
                optionId={optionsId[index]}
                onItemSelected={handleOnChange}
                {...otherProps}
            >
                {optionRenderer(option)}
            </ButtonSelectItem>
        );
    };

    return (
        <MovingFocusProvider
            options={{
                direction: 'vertical',
                loopAround: false,
                defaultSelectedId: selectedOptionId,
                allowFocusing: true,
            }}
        >
            <Tooltip label={!isOpen && label} placement={Placement.TOP}>
                <ButtonSelectTrigger
                    role="combobox"
                    className={classnames(className, CLASSNAME)}
                    isOpen={isOpen}
                    onChange={handleOnChange}
                    rightIcon={rightIcon}
                    aria-label={label}
                    aria-controls={isOpen ? listId : undefined}
                    toggleOpen={toggleOpen}
                    options={options}
                    {...forwardedProps}
                    ref={mergeRefs([anchorRef, buttonRef])}
                >
                    {actionLabel || valueRenderer(value)}
                </ButtonSelectTrigger>
            </Tooltip>

            <Popover
                role="none"
                isOpen={isOpen}
                onClose={close}
                anchorRef={anchorRef}
                fitToAnchorWidth
                closeOnClickAway
                closeOnEscape
                placement={Placement.BOTTOM}
                {...dropdownProps}
            >
                <List role="listbox" id={listId} aria-label={label} itemPadding="big">
                    {options.map((option, index) => {
                        const otherProps = dropdownItemProps ? dropdownItemProps(option, index) : {};

                        if ((option as any).tooltipProps) {
                            return (
                                <Tooltip {...(option as any).tooltipProps} key={index}>
                                    {optionItem(option, otherProps, index)}
                                </Tooltip>
                            );
                        }

                        return optionItem(option, otherProps, index);
                    })}
                </List>
            </Popover>
        </MovingFocusProvider>
    );
};
ButtonSelect.displayName = 'ButtonSelect';
