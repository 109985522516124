import React from 'react';

import { classnames } from '@lumapps/classnames';
import { ColorPalette, Theme } from '@lumapps/lumx/react';
import { UserLink } from '@lumapps/user/components/UserLink';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import type { ElementRender } from '@lumapps/wrex/types';

import { USER_ID_ATTRIBUTE, USER_MENTION } from '../../../constants';
import type { UserMentionElement } from '../../../types';

import './index.scss';

const CLASSNAME = 'wrex-user-mention';

/** Wrex user mention */
export const UserMention: ElementRender<UserMentionElement, HTMLButtonElement> = (props) => {
    const { children, className, element, isEditing, isSelected, elementRef, ...forwardedProps } = props;
    const theme = useThemeContext();

    return (
        <UserLink
            ref={elementRef as any}
            {...forwardedProps}
            className={classnames(
                // The class `notranslate` add the element to the ignore list for Google Translate API.
                'notranslate',
                className,
                CLASSNAME,
                isEditing && `${CLASSNAME}--is-editing`,
                isSelected && `${CLASSNAME}--is-selected`,
            )}
            isDisabled={isEditing}
            color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
            showIcon
            showCardOnHover={!isEditing}
            user={element?.user as UserMentionElement['user']}
            {...{ [USER_ID_ATTRIBUTE]: element?.user.id }}
        >
            {element?.user.fullName}
            {isEditing ? children : undefined}
        </UserLink>
    );
};
UserMention.displayName = USER_MENTION;
