import React, { useCallback, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { AppId } from '@lumapps/constants/app';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import {
    SideNavigationSection as Section,
    SideNavigationItem,
} from '@lumapps/lumx-back-office/components/SideNavigation';
import { learningHatImage } from '@lumapps/lumx/custom-icons';
import { useSelector } from '@lumapps/redux/react';
import { Route } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getLearningRoles, getMCLaunchUrl } from '../../api';
import { LEARNING } from '../../keys';
import { MissionCenterPermitionRoles } from '../../types';

export const LearningAdminSideNavigation: React.FC = () => {
    const { translateKey } = useTranslate();
    const [status, setStatus] = useState(BaseLoadingStatus.initial);
    const [route, setRoute] = useState<Route | undefined>(undefined);
    const [isLearningAdmin, setIsLearningAdmin] = useState(false);
    const learningAlphaEnabled = useSelector(isLearningAlphaEnabled);
    const getLearningRoute = useCallback(async () => {
        try {
            setStatus(BaseLoadingStatus.loading);
            const response = await getMCLaunchUrl(learningAlphaEnabled);
            setRoute({ appId: AppId.external, path: response.launchUrl });
            setStatus(BaseLoadingStatus.idle);
        } catch (e) {
            setStatus(BaseLoadingStatus.error);
        }
    }, [learningAlphaEnabled]);

    useEffect(() => {
        const fetchLearningRolesData = async () => {
            try {
                setStatus(BaseLoadingStatus.loading);
                const response = await getLearningRoles(learningAlphaEnabled);
                if (isEmpty(response)) {
                    setStatus(BaseLoadingStatus.empty);
                } else {
                    if (response.includes(MissionCenterPermitionRoles.Manager)) {
                        setIsLearningAdmin(true);
                        await getLearningRoute();
                    } else {
                        setIsLearningAdmin(false);
                    }
                    setStatus(BaseLoadingStatus.idle);
                }
            } catch (e) {
                setStatus(BaseLoadingStatus.error);
            }
        };
        if (status === BaseLoadingStatus.initial) {
            fetchLearningRolesData();
        }
    }, [getLearningRoute, learningAlphaEnabled, status]);
    return isLearningAdmin && route ? (
        <Section header={translateKey(LEARNING.LEARNING)} id="learning">
            <SideNavigationItem
                label={translateKey(LEARNING.MISSION_CENTER)}
                icon={learningHatImage}
                route={route}
                openInNewTab
                onClick={getLearningRoute}
            />
        </Section>
    ) : null;
};

LearningAdminSideNavigation.displayName = 'SideNavigationItem';
