import { CHAT_PROVIDER_VALUES, CHAT_PROVIDER_NAMES } from '@lumapps/chat/constants';

/**
 * Defines the values for each chat sizes.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const CHAT_SIZES_VALUES = {
    FULLSCREEN: 'fullscreen',
    LARGE: 'large',
    SMALL: 'small',
};

/**
 * Defines the values for each chat location.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const CHAT_POSITION_VALUES = {
    DEFAULT: 'default',
    STICKY: 'sticky',
};

/**
 * Defines the possibles content type values
 * @type {Object}
 * @constant
 * @readonly
 */
const BODY_CONTENT_TYPE_VALUES = {
    HTML: 'html',
    MARKDOWN: 'markdown',
};

/**
 * Defines the possibles content type values for avatar
 * @type {Object}
 * @constant
 * @readonly
 */
const AVATAR_CONTENT_TYPE_VALUES = {
    BASE64: 'data/base64',
    URL: 'url',
};

/**
 * Defines the possibles reaction type values
 * @type {Object}
 * @constant
 * @readonly
 */
const REACTION_TYPE_VALUES = {
    LIKE: 'like',
};

/**
 * Defines the max page size to load for replies
 * @type {number}
 * @constant
 * @readonly
 */
const MAX_RESULTS = 100;

/**
 * Defines the polling interval depending on provider threshold
 * @type {Object}
 * @constant
 * @readonly
 */
const PROVIDER_POLL_INTERVAL = {
    fbworkplace: 60000,
    slack: 3000,
    teams: 2000,
    yammer: 60000,
};

export {
    AVATAR_CONTENT_TYPE_VALUES,
    CHAT_PROVIDER_NAMES,
    CHAT_PROVIDER_VALUES,
    CHAT_SIZES_VALUES,
    CHAT_POSITION_VALUES,
    BODY_CONTENT_TYPE_VALUES,
    MAX_RESULTS,
    PROVIDER_POLL_INTERVAL,
    REACTION_TYPE_VALUES,
};
