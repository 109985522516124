import { CSSProperties } from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { AllStyles, computeStyles } from '@lumapps/content-style/computeStyles';

import * as types from '../../types';

/** Styles that should be applied to the blocks in an ungrounded container. */
const contentStyleNames: Array<keyof CSSProperties> = [
    'backgroundColor',
    'backgroundImage',
    'backgroundPosition',
    'backgroundSize',
    'boxShadow',
    'borderColor',
    'borderRadius',
    'borderWidth',
    'borderBottomColor',
    'borderBottomWidth',
    'borderLeftColor',
    'borderLeftWidth',
    'borderRightColor',
    'borderRightWidth',
    'borderTopColor',
    'borderTopWidth',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'padding',
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
];

const DEFAULT_UNGROUPED_CONTENT_STYLE: AllStyles = {
    shadowElevation: 1,
    backgroundColor: 'white',
};

/** Compute widget content styles. */
export function computeWidgetContentStyles(style?: types.WidgetContentStyles, hasUngroupedChildren?: boolean) {
    const styles = computeStyles({
        // Use default ungrouped content styles if applicable.
        ...(hasUngroupedChildren ? DEFAULT_UNGROUPED_CONTENT_STYLE : undefined),
        // Override with custom styles.
        ...style,
    });

    // Widget content with fixed height should be scrollable.
    if (styles?.height) {
        styles.overflow = 'auto';
    }

    if (styles && hasUngroupedChildren) {
        const contentStyles = pick(styles, contentStyleNames);
        const filteredStyles = omit(styles, contentStyleNames);
        return {
            contentStyles: isEmpty(contentStyles) ? undefined : contentStyles,
            styles: isEmpty(filteredStyles) ? undefined : filteredStyles,
        };
    }
    return { styles };
}
