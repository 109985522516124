import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const analyticsSettingsRoutePath = '/admin/analytics-settings';
const analyticsSettingsRoute: Route = {
    path: analyticsSettingsRoutePath,
    appId: AppId.backOffice,
};
const getAnalyticsSettingsRoute = (): Route => analyticsSettingsRoute;

export { getAnalyticsSettingsRoute, analyticsSettingsRoute };
