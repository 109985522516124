import { get as getConstants } from '@lumapps/constants';
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import { contentView } from '../../routes';
import { ContentLinkRef } from '../../types';

const { isLegacyContext } = getConstants();

/**
 * Generate link props for content link.
 */
export function getContentLinkProps(
    to: ContentLinkRef,
    options: { isLayoutOn: boolean; getUrl: (route: Route) => string },
) {
    // Generate content link URL.
    const contentRoute = contentView({ isLayoutEnabled: options.isLayoutOn, to });
    const href = options.getUrl(contentRoute);

    let target;

    /**
     * If the content is `external` and used as a redirection, open with _blank
     * else If link is on another instance or we're leaving the Legacy app, open with _self to force reload
     * */
    if (contentRoute.appId === AppId.external) {
        target = '_blank';
    } else if ((contentRoute.appId !== AppId.legacy && isLegacyContext) || contentRoute.instanceSlug) {
        target = '_self';
    }

    return { href, target };
}
