import BaseApi, { BaseApiResponse, dangerousAxiosInstanceUseOnlyIfNeeded } from '@lumapps/base-api';
import { fileReader } from '@lumapps/base-api/utils/files';
import { redundantFetchApi } from '@lumapps/base-api/utils/redundantFetchApi';
import { Community } from '@lumapps/communities/types';
import { UploadURL, Media } from '@lumapps/medias/types';
import { addQueryParamsToUrl } from '@lumapps/router/utils';

export const documentApiHaussmann = new BaseApi({
    path: 'document',
});

export const documentApi = new BaseApi({
    path: 'document',
    bypassMonolith: false,
});

const MAX_TRY = 10;
const DELAY_RETRY = 1000;

/** API parameterized paths. */
export const paths = {
    documents: () => 'documents',
    documentById: (docId: string) => `documents/${docId}`,
    documentsByIds: () => 'documents/get-by-ids',
};

export interface GetDocumentParams {
    docPath: string;
}
/**
 * API /POST on 'document/get' to get document data
 * @param params give docPath params
 */
export const getDocumentData = (params: GetDocumentParams): Promise<BaseApiResponse<Media>> =>
    documentApiHaussmann.post('get', params);

/**
 * API /POST on 'document/getMulti' to get multiple document data
 * @param params give docPath list params
 */
export const getMultipleDocumentData = (params: { docPaths: string[] }): Promise<BaseApiResponse<{ items: Media[] }>> =>
    documentApiHaussmann.post('getMulti', params);

export interface ListDocumentsParams {
    docPath: string;
    searchText: string;
    sortOrder: string[] | string;
    lang: string;
    searchTags: string[];
    maxResults: number;
    searchParameters: { shared: boolean; trashed: boolean; author?: string };
    searchInFolder: boolean;
    cursor?: string;
}
/**
 * API /POST on 'document/list' to get all document from folder
 */
export const listDocuments = (
    params: ListDocumentsParams,
): Promise<BaseApiResponse<{ items?: Media[]; more: boolean; cursor?: string }>> =>
    documentApiHaussmann.post('list', { ...params, sortOrder: ['-isFolder', params.sortOrder] });

export interface UploadDocumentBaseOptions {
    lang: string;
    parentPath: string;
    shared: boolean;
    success?: string;
    abortController?: AbortController;
}

export interface UploadDocumentOptions extends UploadDocumentBaseOptions {
    file: File;
    fileName?: string;
}

export const uploadDocument = ({ file, abortController, ...params }: UploadDocumentOptions) => {
    const apiParams = {
        fileName: file.name,
        ...params,
    };

    return documentApi
        .post('uploadUrl/get', apiParams, {
            signal: abortController?.signal,
        })
        .then((response) => {
            const formData = new FormData();
            formData.append('file', file);
            const baseConfig = BaseApi.getBaseConfig();

            return dangerousAxiosInstanceUseOnlyIfNeeded.post(response.data.uploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...baseConfig.headers,
                },
                signal: abortController?.signal,
            });
        });
};

export const uploadDocuments = (config: UploadDocumentOptions[]) => {
    const uploadPromises = config.map((params) => {
        return uploadDocument(params);
    });

    return Promise.all(uploadPromises);
};

/**
 * IMPORTANT: ONLY USE THIS METHOD IF YOU KNOW WHAT YOU ARE DOING, WE SHOULD NEVER DO POLLING
 * ONLY IF NEEDED. THIS METHOD IS FOR NOW ONLY USED ON THE LEGACY SIDE.
 * @param params UploadDocumentOptions
 * @returns Promise
 */
export const uploadDocumentWithValidation = (params: UploadDocumentOptions) => {
    return uploadDocument(params).then((response) => {
        const { data } = response;
        const { items } = data;

        return redundantFetchApi(
            () =>
                dangerousAxiosInstanceUseOnlyIfNeeded({
                    method: 'GET',
                    url: addQueryParamsToUrl(items[0].content[0].url, { canUsePlaceholder: 'false' }),
                }).then(() => {
                    return {
                        data: items[0],
                    };
                }),
            MAX_TRY,
            DELAY_RETRY,
        );
    });
};

/**
 * IMPORTANT: ONLY USE THIS METHOD IF YOU KNOW WHAT YOU ARE DOING, WE SHOULD NEVER DO POLLING
 * ONLY IF NEEDED. THIS METHOD IS FOR NOW ONLY USED ON THE LEGACY SIDE.
 * @param config UploadDocumentOptions
 * @returns Promise
 */
export const uploadDocumentsWithValidation = (config: UploadDocumentOptions[]) => {
    const uploadPromises = config.map((params) => {
        return uploadDocumentWithValidation(params);
    });

    return Promise.all(uploadPromises);
};

export interface UploadSecuredDocumentForCommunityOptions extends UploadDocumentOptions {
    community: Pick<Community, 'uid'>;
}

export const uploadSecuredDocumentForCommunity = ({
    community,
    file,
    abortController,
    ...params
}: UploadSecuredDocumentForCommunityOptions) => {
    let uploadUrlResponse: UploadURL;

    const apiParams = {
        fileName: file.name,
        ...params,
    };

    return documentApi
        .post('uploadUrl/get', apiParams, {
            signal: abortController?.signal,
        })
        .then((response) => {
            uploadUrlResponse = response.data;
            return fileReader(file);
        })
        .then((result) =>
            dangerousAxiosInstanceUseOnlyIfNeeded({
                headers: {
                    'Content-Type': file.type,
                    ...uploadUrlResponse.headers,
                },
                method: (uploadUrlResponse.httpMethod as any) || 'POST',
                url: uploadUrlResponse.uploadUrl,
                data: result,
            }),
        )
        .then(() =>
            redundantFetchApi(
                () =>
                    getDocumentData({
                        docPath: `provider=haussmann_media/drive=${community.uid}/resource=${uploadUrlResponse.mediaId}`,
                    }),
                MAX_TRY,
                DELAY_RETRY,
            ),
        );
};
