import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const customerAdvancedSettingsRoute: Route = {
    path: '/admin/platform-settings/advanced-settings',
    legacyId: 'app.admin.platform-advanced-settings',
    appId: AppId.backOffice,
};

export const adminAdvancedCustomerSettings = (): Route => {
    return customerAdvancedSettingsRoute;
};

export const termsAndConditionsRoute: Route = {
    path: '/admin/platform-settings/terms-and-conditions',
    legacyId: 'app.admin.terms-and-conditions',
    appId: AppId.legacy,
};

export const adminTermsAndConditionsRoute = (): Route => {
    return termsAndConditionsRoute;
};

export const globalAdminsRoute: Route = {
    path: '/admin/super-admin',
    legacyId: 'app.admin.super-admin',
    appId: AppId.backOffice,
};

/**
 * Retrieves the route for displaying the global admins page.
 * @returns Route
 */
export const globalAdmins = (): Route => {
    return globalAdminsRoute;
};
