import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const mobileGeneralSettingsRoutes: Route = {
    path: '/admin/mobile-general-settings',
    legacyId: 'app.admin.mobile-general-settings',
    appId: AppId.backOffice,
};

export const adminGeneralSettings = (): Route => {
    return mobileGeneralSettingsRoutes;
};
