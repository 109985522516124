import { AppId } from '@lumapps/constants/app';

export { AppId };

export const URL_PREFIX = 'ls';

export const MIGRATION_URL_PREFIX = 'new';

/**
 * Angular event to broadcast when the linear loading should be displayed
 */
export const ANGULAR_SHOW_LINEAR_LOADING_EVENT = 'show-linear-loading';

/**
 * IMPORTANT: THIS IS ONLY A DEVELOPMENT VARIABLE, PLEASE DO NOT USE IS FOR ANY OTHER PURPOSES.
 * If you are working with multiple applications locally, these are the ports that you need to use.
 */
export const LOCAL_PORT_CONFIGURATION: Record<AppId, string> = {
    [AppId.external]: '3000',
    [AppId.legacy]: '8888',
    [AppId.frontOffice]: '3006',
    [AppId.backOffice]: '3008',
    [AppId.webview]: '3009',
    [AppId.login]: '3010',
    [AppId.digitalAssistant]: '3011',
    [AppId.support]: '3012',
    [AppId.embedded]: '3013',
    [AppId.devPortal]: '3014',
    [AppId.designer]: '3015',
};

/** List of url parameters we can ignore in isCurrentUrl */
export const IGNORABLE_QUERY_PARAMS = ['as', 'layout', 'interface', 'override', 'safe'];

/**
 * ID to use for loading pages fallbacks.
 * This allows other parts of the application to know that a page is currently being loaded.
 */
export const LOADING_PAGE_ID = 'loading-page';
