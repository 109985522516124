import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiOpenInNew } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    Button,
    ButtonProps,
    FlexBox,
    FlexBoxProps,
    Heading,
    HeadingElement,
    HeadingProps,
    Icon,
    IconProps,
    ImageBlock,
    ImageBlockProps,
    Link,
    LinkProps,
    Orientation,
    Size,
    Text,
    TextProps,
    Theme,
    ThumbnailVariant,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface BaseStateProps extends FlexBoxProps {
    actions?: React.ReactNode;
    /** Component to be displayed before the title */
    beforeTitle?: React.ReactNode;
    /** Call to action button props */
    buttonProps?: ButtonProps;
    /** Class name forwarded to the root element of the component. */
    className?: string;
    /** Icon props */
    iconProps?: IconProps;
    /** Image block props */
    imageProps?: ImageBlockProps;
    /** Link props for the More infos link */
    linkProps?: LinkProps;
    /** Text to be displayed after the title */
    message?: ReactNode;
    /** Text component props */
    messageProps?: Partial<TextProps>;
    /** Theme adapting the component to light or dark background. */
    theme?: Theme;
    /** Text for the title */
    title?: string;
    /** Heading props */
    titleProps?: Partial<HeadingProps>;
    /** Display a specific heading level for the title (default h2) */
    titleAs?: HeadingElement;
}

const CLASSNAME = 'lumx-base-state';

/**
 * Component used for displaying a warning message pattern without context to the user. This should only be used
 * if the message is not one of the standard ones.
 *
 * @family States
 * @param BaseStateProps
 * @returns BaseState
 */
export const BaseState: React.FC<BaseStateProps> = ({
    actions,
    beforeTitle,
    className,
    buttonProps,
    gap = Size.big,
    iconProps,
    imageProps,
    linkProps,
    message,
    messageProps,
    theme = Theme.light,
    title,
    titleProps,
    titleAs = 'h2',
    fillSpace = true,
    wrapperProps,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    return (
        <FlexBox
            orientation={Orientation.vertical}
            vAlign="center"
            gap={gap}
            hAlign="center"
            marginAuto={['top', 'bottom']}
            fillSpace={fillSpace}
            {...wrapperProps}
            className={block([className || wrapperProps?.className])}
        >
            {!!imageProps && (
                <ImageBlock
                    className={element('image')}
                    theme={theme}
                    size={Size.xxl}
                    thumbnailProps={{ aspectRatio: AspectRatio.square, variant: ThumbnailVariant.rounded }}
                    {...imageProps}
                />
            )}
            {!!iconProps && <Icon className={element('icon')} hasShape size={Size.xxl} theme={theme} {...iconProps} />}

            <FlexBox
                className={element('content', ['lumx-base-text-center'])}
                vAlign="center"
                hAlign="center"
                orientation={Orientation.vertical}
            >
                {beforeTitle}
                {!!title && (
                    <Heading
                        as={titleAs}
                        className={element('title')}
                        typography={Typography.subtitle1}
                        color={theme === Theme.light ? Theme.dark : Theme.light}
                        {...titleProps}
                    >
                        {title}
                    </Heading>
                )}
                {(!!message || !!messageProps) && (
                    <Text
                        as="p"
                        className={element('message')}
                        typography={Typography.body1}
                        color={theme === Theme.light ? Theme.dark : Theme.light}
                        colorVariant="L2"
                        {...messageProps}
                    >
                        {message}
                    </Text>
                )}
                {!!linkProps && (
                    <Link
                        className={element('link')}
                        typography={Typography.body1}
                        rightIcon={mdiOpenInNew}
                        {...linkProps}
                    >
                        {translateKey(GLOBAL.MORE_INFO)}
                    </Link>
                )}
            </FlexBox>

            {!!actions && actions}
            {!!buttonProps && <Button className={element('button')} theme={theme} {...buttonProps} />}
        </FlexBox>
    );
};
