import React, { RefObject, useState } from 'react';

import { Connector, useExportProps } from 'lumapps-sdk-js';

import { Theme } from '@lumapps/lumx/react';

import { useLumdrian } from '../../utils/hooks';
import { ConnectorSelect } from '../ExtensionNConnectorPicker/ConnectorSelect';
import { ExtensionComponent } from '../ExtensionsComponent/ExtensionComponent';
import { BuiltInPropertiesProps } from '../ExtensionsComponent/types';

export interface LegacyAppBlockRemoteProps {
    componentType: 'content' | 'settings' | 'global_settings';
    extensionId: string;
    extensionProperties: Record<string, unknown> & { builtInProperties?: BuiltInPropertiesProps };
    globalProperties: Record<string, unknown>;
    useConnectorSelector?: boolean;
    theme?: Theme;
    uuid: string;
    widgetRef?: RefObject<HTMLElement>;
    exportProp?(props: Record<string, unknown>): void;
}
export const LegacyAppBlockRemote: React.FC<LegacyAppBlockRemoteProps> = ({
    componentType,
    extensionId,
    extensionProperties,
    globalProperties,
    useConnectorSelector,
    theme = Theme.light,
    uuid,
    widgetRef,
    exportProp,
}) => {
    const { component, error, load, loading } = useLumdrian({ componentType, extensionId });

    const [selectedConnector, setSelectedConnector] = useState<Connector>();

    const [builtInProperties, setBuiltInProperties] = useState<{ connectorId?: string }>({
        connectorId: extensionProperties.builtInProperties?.connectorId,
    });

    useExportProps(builtInProperties, 'builtInProperties', extensionProperties, exportProp);

    const onSetSelectedConnector = (newConnector?: Connector) => {
        setBuiltInProperties({ connectorId: newConnector?.id });
        setSelectedConnector(newConnector);
    };

    React.useEffect(() => {
        setSelectedConnector(undefined);
    }, [uuid]);

    return (
        <>
            <div className="block-remote">
                <ExtensionComponent
                    component={component}
                    error={error}
                    exportProp={exportProp}
                    extensionId={extensionId}
                    globalProperties={globalProperties}
                    isComponentLoading={loading}
                    legacyWidgetId={uuid}
                    loadComponent={load}
                    properties={extensionProperties}
                    theme={theme}
                    type={componentType}
                    uuid={uuid}
                    widgetRef={widgetRef}
                />
            </div>

            {useConnectorSelector && componentType === 'settings' && (
                <ConnectorSelect
                    extensionId={extensionId}
                    initConnectorId={extensionProperties?.builtInProperties?.connectorId as string}
                    initialize={false}
                    value={selectedConnector}
                    onChange={onSetSelectedConnector}
                />
            )}
        </>
    );
};
export default LegacyAppBlockRemote;
