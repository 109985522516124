import React, { useMemo } from 'react';

import { RenderingType } from '@lumapps/communities/types';
import { CommunityNavigation } from '@lumapps/community-navigation';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { ViewMode } from '@lumapps/lumx-navigation/constants';
import { useSelector } from '@lumapps/redux/react';
import { useParams } from '@lumapps/router';

import { SPACE_SERVICES_RECORD } from '../../constants';
import { isSpaceEditableByUser } from '../../ducks/selectors';
import { spaceView } from '../../routes';
import { SpaceRouteParameters, SpaceServices, SpaceViews } from '../../types';

export interface SpaceNavigationProps {
    enabledServices: SpaceServices[];
}

export const SpaceNavigation: React.FC<SpaceNavigationProps> = ({ enabledServices }) => {
    const { id, slug, view } = useParams<SpaceRouteParameters>();
    const isManager = useSelector(isSpaceEditableByUser);
    const instanceId = useSelector(instanceIdSelector);

    const staticItems = useMemo(
        () =>
            Object.entries(SPACE_SERVICES_RECORD)
                .filter(([service]) => enabledServices.includes(service as SpaceServices))
                .map(([, data]) => {
                    return {
                        ...data,
                        to: spaceView({ params: { id, slug, view: data.view } }),
                    };
                }),
        [enabledServices, id, slug],
    );

    const communityNavigationQuery = useMemo(
        () => ({
            siteId: instanceId,
            communityId: id,
        }),
        [id, instanceId],
    );

    return (
        <CommunityNavigation
            currentView={view as SpaceViews}
            staticItems={staticItems}
            theme="light"
            viewMode={ViewMode.horizontal}
            scope={RenderingType.space}
            isManager={isManager}
            communityNavigationQuery={communityNavigationQuery}
        />
    );
};
