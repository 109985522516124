import React from 'react';

import { Size, Emphasis, IconButton } from '@lumapps/lumx/react';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { mdiArrowExpand, mdiChevronDown, mdiChevronUp, mdiArrowCollapse, mdiArrowLeft } from '@lumapps/lumx/icons';

import { translate as t } from 'components/translations';

import { CHAT_SIZES_VALUES, CHAT_POSITION_VALUES } from './chat_constants';

/**
 * Displays the widget chat header component
 * @return {Element} The chat header react element.
 */
const ChatHeader = ({
    channelUrl,
    currentSize,
    notificationCount,
    openState,
    position,
    uuid,
    selectedThread,
    setCurrentSize,
    setOpen,
    setSelectedThread,
    theme,
    titleName,
}) => {
    channelUrl = channelUrl && channelUrl.replace('+', '');

    return (
        <div className="widget-chat__header widget-chat-header">
            <div className="widget-chat-header__title">
                {(!selectedThread || !openState) && (
                    <a className="widget-chat-header__link" href={channelUrl} rel="noopener noreferrer" target="_blank">
                        <div className="widget-chat-header__icon" />
                    </a>
                )}
                {openState && !isUndefined(get(selectedThread, 'id')) && (
                    <div className="widget-chat-header__return-button">
                        <a href={`#${selectedThread.id}`}>
                            <IconButton
                                color={theme}
                                emphasis={Emphasis.low}
                                icon={mdiArrowLeft}
                                size={Size.m}
                                onClick={() => setSelectedThread({ selectedThread: null, uuid })}
                                label=""
                            />
                        </a>
                    </div>
                )}
                <div className="widget-chat-header__title">
                    <span>{t(titleName)}</span>
                    {!openState && notificationCount > 0 && (
                        <div className="widget-chat-header__notifications">{notificationCount}</div>
                    )}
                </div>
            </div>

            {position === CHAT_POSITION_VALUES.STICKY && (
                <div className="widget-chat-header__actions">
                    {openState && currentSize === CHAT_SIZES_VALUES.SMALL && (
                        <>
                            <IconButton
                                color={theme}
                                emphasis={Emphasis.low}
                                icon={mdiArrowExpand}
                                size={Size.m}
                                onClick={() => setCurrentSize({ size: CHAT_SIZES_VALUES.LARGE, uuid })}
                                label=""
                            />
                            <IconButton
                                color={theme}
                                emphasis={Emphasis.low}
                                icon={mdiChevronDown}
                                size={Size.m}
                                onClick={() => setOpen({ isOpen: false, uuid })}
                                label=""
                            />
                        </>
                    )}
                    {!openState && (
                        <IconButton
                            color={theme}
                            emphasis={Emphasis.low}
                            icon={mdiChevronUp}
                            size={Size.m}
                            onClick={() => setOpen({ isOpen: true, uuid })}
                            label=""
                        />
                    )}
                    {openState && currentSize === CHAT_SIZES_VALUES.LARGE && (
                        <>
                            <IconButton
                                color={theme}
                                emphasis={Emphasis.low}
                                icon={mdiArrowCollapse}
                                size={Size.m}
                                onClick={() => setCurrentSize({ size: CHAT_SIZES_VALUES.SMALL, uuid })}
                                label=""
                            />

                            <IconButton
                                color={theme}
                                emphasis={Emphasis.low}
                                icon={mdiChevronDown}
                                size={Size.m}
                                onClick={() => setOpen({ isOpen: false, uuid })}
                                label=""
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export { ChatHeader };
