export enum WREX_CORE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=354784685
     */
    EDITOR_TOOLBAR_LABEL = 'WREX_CORE.EDITOR_TOOLBAR_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=371741190
     */
    ALIGN_CENTER = 'WREX_CORE.ALIGN_CENTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=371427407
     */
    ALIGN_TO_LEFT = 'WREX_CORE.ALIGN_TO_LEFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=371585212
     */
    ALIGN_TO_RIGHT = 'WREX_CORE.ALIGN_TO_RIGHT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232979986
     */
    THUMBNAIL_VISIBILITY = 'JOURNEYS_CORE.THUMBNAIL_VISIBILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=429006016
     */
    EDITOR_WITH_MENTION_PLACEHOLDER = 'WREX.EDITOR_WITH_MENTION_PLACEHOLDER',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488770273
     */
    WARNING_DELETE = 'WREX_CORE.WARNING_DELETE',
}
