/* istanbul ignore file */

import { isDigitalAssistantEnabled } from '@lumapps/digital-assistant/ducks/selectors';
import { isLearningAlphaEnabled } from '@lumapps/learning/ducks/selectors';
import { getBackofficeNavigationHeader } from '@lumapps/navigation/ducks/selectors';
import { isSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';

import { SideNavigation } from './SideNavigation';

const mapStateToProps = (state: any) => {
    return {
        navHeaderProps: getBackofficeNavigationHeader(state),
        isSuperAdmin: isSuperAdmin(state),
        enabledFeatures: {
            digitalAssistant: isDigitalAssistantEnabled(state),
            learningAlpha: isLearningAlphaEnabled(state),
        },
    };
};

const ConnectedSideNavigation = connect(mapStateToProps)(SideNavigation);

export default ConnectedSideNavigation;
