import React from 'react';

import { isDigitalAssistantEnabled } from '@lumapps/digital-assistant/ducks/selectors';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { digitalAssistant } from '@lumapps/lumx/custom-icons';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { DIGITAL_ASSISTANT_ADMIN } from '../../keys';
import { digitalAssistantAdminRoute } from '../../routes';

export interface DigitalAssistantSideNavigationProps {
    /** has the user access to the digital assistant section */
    isAllowed?: boolean;
}

const DigitalAssistantSideNavigation: React.FC<Partial<DigitalAssistantSideNavigationProps>> = (isAllowed) => {
    const { translateKey } = useTranslate();
    if (!useSelector(isDigitalAssistantEnabled) || !isAllowed) {
        return null;
    }
    return (
        <SideNavigationItem
            route={digitalAssistantAdminRoute}
            label={translateKey(DIGITAL_ASSISTANT_ADMIN.DIGITAL_ASSISTANT)}
            icon={digitalAssistant}
        />
    );
};

DigitalAssistantSideNavigation.displayName = 'SideNavigationItem';

export { DigitalAssistantSideNavigation };
