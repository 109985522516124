import React, { useRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Message, Tooltip } from '@lumapps/lumx/react';
import { upperSnakeCase } from '@lumapps/utils/string/upperSnakeCase';

import { translate as t } from '../../translations';

const CLASSNAME = 'deprecation-warning';

import './DeprecationWarning.scss';

const DeprecationWarning = ({ isLarge = false, widgetType = '' }) => {
    const anchorRefTooltip = useRef(null);
    const deprecatedWidgetList = t('FRONT.DEPRECATION_WARNING.WIDGET_LIST', undefined, 'en').split(',');
    const isDeprecated = deprecatedWidgetList.includes(widgetType);
    const widgetTypeUpperSnakeCase = upperSnakeCase(widgetType);
    const {block} = useClassnames(CLASSNAME);

    return (
        isDeprecated && (
            <Tooltip label={!isLarge && t(`FRONT.DEPRECATION_WARNING.SELECTION.TOOLTIP_MESSAGE_${widgetTypeUpperSnakeCase}`)}>
                <Message className={block({
                    'is-large': isLarge,
                })} ref={anchorRefTooltip} hasBackground kind='warning'>
                    <p
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: isLarge
                                ? t(`FRONT.DEPRECATION_WARNING.SETTINGS.MESSAGE_${widgetTypeUpperSnakeCase}`)
                                : t('FRONT.DEPRECATION_WARNING.SELECTION.MESSAGE'),
                        }}
                    />                
                </Message>
            </Tooltip>
        )
    );
};

export { DeprecationWarning };
