import { combineReducers } from '@lumapps/redux';

import { reducer as articles } from '@lumapps/articles/ducks/slice';
import { reducer as articlePreview } from '@lumapps/articles/ducks/articlePreviewSlice';
import { reducer as mobileContentStreamAdmin } from '@lumapps/content-streams-admin/ducks/contentStreamAdmin/slice';
import { reducer as content } from '@lumapps/contents/ducks/slice';
import { reducer as contentTypes } from '@lumapps/content-types/ducks/slice';
import { reducer as chatReducer } from '@lumapps/chat/ducks/slice';
import { reducer as bookmarks } from '@lumapps/bookmarks/ducks/slice';
import { reducer as feed } from '@lumapps/groups/ducks/slice';
import { reducer as folksonomy } from '@lumapps/folksonomy/ducks/slice';
import { reducer as instanceReducer } from '@lumapps/instance/ducks/reducer';
import { reducer as metadata } from '@lumapps/metadata/ducks/slice';
import { reducer as directories } from '@lumapps/directories/ducks/slice';
import { reducer as events } from '@lumapps/events/ducks/slice';
import { reducer as eventPreview } from '@lumapps/events/ducks/eventPreviewSlice';
import { reducer as commentReducer } from '@lumapps/comment/ducks/slice';
import { reducer as nanny } from '@lumapps/nanny-details/ducks/slice';
import { reducer as notificationCenterReducer } from '@lumapps/notification-center/ducks/slice';
import { reducer as notificationsReducer } from '@lumapps/notifications';
import { reducer as permissions } from '@lumapps/permissions/ducks/slice';
import { reducer as post } from '@lumapps/posts/ducks/slice';
import { reducer as saMemberReducer } from '@lumapps/sa-members/ducks/slice';
import { reducer as socialNetworksReducer } from '@lumapps/sa-social-networks/ducks/reducer';
import { reducer as user } from '@lumapps/user/ducks/slice';
import { reducer as userDirectory } from '@lumapps/user-directory/ducks/slice';
import { reducer as saConnectedUserReducer } from '@lumapps/sa-connected-user/ducks/slice';
import { reducer as saProgramsReducer } from '@lumapps/sa-programs/ducks/slice';
import { reducer as saTopicsReducer } from '@lumapps/sa-topics/ducks/slice';
import { reducer as saShareableContentsReducer } from '@lumapps/sa-shareable-contents/ducks/slice';
import { reducer as share } from '@lumapps/share/ducks/slice';
import { reducer as search } from '@lumapps/search/ducks/slice';
import { reducers as communitiesReducers } from '@lumapps/communities/ducks/slice';
import { reducer as router } from '@lumapps/router/ducks/slice';
import { reducer as widgetReducers } from '@lumapps/widget-base/ducks/slice';
import { reducer as header } from '@lumapps/header/ducks/slice';

import chatsReducer from './chat';
import currentCommunity from './communities/ducks/reducers';
import customContentType from './custom-content-type/ducks';
import documentReducer from './document/ducks';
import locale from './locale/ducks';
import socialAdvocacy from './social-advocacy/ducks';
import termsAndConditions from './terms-and-conditions/ducks';
import topLevelReduces from './ducks';
import users from './users/_ducks';
import widget from './widget/ducks';
import widgetTranslation from './widget-translation/_ducks';
import widgets from './widgets/ducks';
import workspaces from './workspaces/_ducks';
import { posts } from './post/ducks/post_reducer';

// This reducer is useful for checking optimization of the digest cycle on Angular side (see redux-store-service).
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const lastActionType = (state = null, action) => action.type;

const rootReducer = {
    SAMembers: saMemberReducer,
    articles,
    articlePreview,
    bookmarks,
    chat: chatReducer,
    chats: chatsReducer,
    comment: commentReducer,
    communities: combineReducers(communitiesReducers),
    contentTypes,
    content,
    currentCommunity,
    customContentType,
    document: documentReducer,
    events,
    eventPreview,
    feed,
    folksonomy,
    instance: instanceReducer,
    lastActionType,
    locale,
    metadata,
    contentStreamAdmin: mobileContentStreamAdmin,
    directories,
    nanny,
    header,
    notificationCenter: notificationCenterReducer,
    notifications: notificationsReducer,
    permissions,
    post,
    posts,
    saConnectedUser: saConnectedUserReducer,
    saPrograms: saProgramsReducer,
    saShareableContent: saShareableContentsReducer,
    saTopics: saTopicsReducer,
    socialNetworks: socialNetworksReducer,
    search,
    share,
    socialAdvocacy,
    termsAndConditions,
    user,
    users,
    userDirectory,
    legacyWidget: widget,
    widget: widgetReducers,
    widgetTranslation,
    widgets,
    workspaces,
    router,
    ...topLevelReduces,
};

export default rootReducer;
